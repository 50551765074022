import { createException } from "../exceptions"
import codes from "../constants/codes"

export const convertArrayOfObjectsToHashtable = ({
  objects,
  idKey = null,
  keyGetter = null,
}) => {
  if (keyGetter === null && idKey === null)
    throw createException({
      code: codes.error.TYPE_ERROR,
      message: "Either 'idKey' or 'keyGetter' must be provided.",
    })

  if (keyGetter === null)
    keyGetter = (object) => object[idKey]

  const hashtable = {}
  for (const object of objects) {
    const key = keyGetter(object)
    if ([undefined, null].includes(key))
      continue
    hashtable[key] = object
  }

  return hashtable
}