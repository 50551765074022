import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import { styles } from "./styles";

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

const BindDialog = ({ open, onClose: handleClose, onBind }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      style={styles.dialog}
      maxWidth="xs"
      onClose={handleClose}
      keepMounted
    >
      <DialogTitle>Atenção!</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText style={styles.dialogContentText}>
          Deseja prosseguir com a associação deste motorista a um dispositivo ?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions style={styles.dialogActions}>
        <Button
          style={styles.proceedButton}
          variant="contained"
          autoFocus
          color="primary"
          onClick={onBind}
        >
          Prosseguir
        </Button>
        <Button
          type="submit"
          autoFocus
          style={styles.cancelButton}
          variant="outlined"
          onClick={handleClose}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BindDialog;
