import React, { memo, useEffect, useState } from "react";
import isEqual from "lodash.isequal";

import { PublicEntity } from "../PublicEntity";
import { getPublicEntities } from "../../../services/areasApi/public-entity/get-public-entities";
import { toast } from "material-react-toastify";

const PublicEntitiesComponent = ({
  publicEntityTypes,
  radius,
  coordinate,
  linestring,
  onMarkerClick: handleMarkerClick,
}) => {
  const [publicEntities, setPublicEntities] = useState([]);

  useEffect(() => {
    (async () => {
      const typeIds = publicEntityTypes.map(({ id }) => id);

      if (typeIds.length === 0 || radius <= 0 || (!coordinate && !linestring)) {
        setPublicEntities([]);

        return;
      }

      const params = {
        typeIds,
        nearby: undefined,
        intersects: undefined,
      };

      if (linestring) {
        params.intersects = {
          linestring,
          radius,
        };
      } else if (coordinate) {
        params.nearby = {
          ...coordinate,
          radius,
        };
      }

      try {
        setPublicEntities(await getPublicEntities(params));
      } catch (error) {
        toast.error("Falha ao tentar obter as entidades publicas.");
      }
    })();
  }, [publicEntityTypes, radius, coordinate, linestring]);

  return (
    <>
      {publicEntityTypes.length > 0 &&
        publicEntities.map((publicEntity) => {
          return (
            <PublicEntity
              key={`public-entity-${publicEntity.id}`}
              publicEntity={publicEntity}
              onMarkerClick={handleMarkerClick}
            />
          );
        })}
    </>
  );
};

export const PublicEntities = memo(
  PublicEntitiesComponent,
  (prevProps, nextProps) => {
    const prevTypeIds = prevProps.publicEntityTypes.map(({ id }) => id);
    const nextTypeIds = nextProps.publicEntityTypes.map(({ id }) => id);

    return (
      isEqual(prevTypeIds, nextTypeIds) &&
      isEqual(prevProps.coordinate, nextProps.coordinate) &&
      prevProps.radius === nextProps.radius &&
      prevProps.linestring === nextProps.linestring &&
      prevProps.onMarkerClick === nextProps.onMarkerClick
    );
  }
);
