import React, { useState } from "react";

import {
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  Dialog,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import { handlePostPassenger } from "../../../services/peopleManagement/passenger";
import { toast } from "material-react-toastify";

const styles = (theme) => ({
  root: {
    width: 600,
    fullWidth: true,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CreateDialog({ open, close, reload }) {
  const [name, setName] = useState();
  const [birthDate, setBirthDate] = useState();
  const [address, setAdress] = useState();
  const [phone, setPhone] = useState();
  const [rfid, setRfid] = useState();
  const [disableButton, setDisableButton] = useState(false);

  const handleSetName = (name) => {
    setName(name);
  };

  const handleSetBirthDate = (birthdate) => {
    setBirthDate(birthdate);
  };

  const handleSetAddress = (address) => {
    setAdress(address);
  };

  const handleSetPhone = (phone) => {
    setPhone(phone);
  };

  const handleSetRfid = (rfid) => {
    setRfid(rfid);
  };

  const handleSave = async () => {
    setDisableButton((previous) => !previous);
    const data = {
      name,
      birthDate,
      address,
      phone,
      rfid,
    };

    if (!name) {
      return toast.error("Digite o nome do passageiro");
    }

    try {
      await handlePostPassenger(data);
      await reload();
      setDisableButton((previous) => previous);
      close();
    } catch (error) {
      toast.error("Erro ao tentar cadastrar");
    } finally {
      setDisableButton((previous) => !previous);
    }
  };

  return (
    <Dialog
      open={open}
      style={{ zIndex: 2, marginLeft: "50px" }}
      fullWidth={true}
      maxWidth={"md"}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title">
        Cadastro de Passageiro
      </DialogTitle>
      <DialogContent dividers>
        <>
          <Grid container spacing={3} style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: "100%" }} size="small">
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Nome do Passageiro
                </Typography>
                <TextField
                  onChange={(e) => handleSetName(e.target.value)}
                  variant="outlined"
                  id="nome"
                  type="text"
                  placeholder="Digite nome do Passageiro"
                  style={{ width: "100%", height: 40 }}
                  size="small"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Data de Nascimento
              </Typography>
              <TextField
                onChange={(e) => handleSetBirthDate(e.target.value)}
                variant="outlined"
                id="scheduled_date"
                type="date"
                style={{ width: "100%", height: 40 }}
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Endereço
              </Typography>
              <TextField
                onChange={(e) => handleSetAddress(e.target.value)}
                variant="outlined"
                id="address"
                type="text"
                placeholder="Digite o endereço"
                style={{ width: "100%", height: 40 }}
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: "100%" }} size="small">
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Telefone
                </Typography>
                <TextField
                  onChange={(e) => handleSetPhone(e.target.value)}
                  variant="outlined"
                  id="tel"
                  type="tel"
                  placeholder="Digite um número de telefone"
                  style={{ width: "100%", height: 40 }}
                  size="small"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: "100%" }} size="small">
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  RFID
                </Typography>
                <TextField
                  onChange={(e) => handleSetRfid(e.target.value)}
                  variant="outlined"
                  id="tel"
                  type="text"
                  placeholder="Digite o RFID"
                  style={{ width: "100%", height: 40 }}
                  size="small"
                />
              </FormControl>
            </Grid>
          </Grid>

          <center>
            <Button
              type="submit"
              autoFocus
              style={{
                margin: "0 15px",
                color: "#06B0CF",
                borderRadius: 8,
                border: "none",
                boxShadow: "0px 3px 6px #00000029",
              }}
              variant="outlined"
              color="primary"
              disabled={disableButton}
              onClick={close}
            >
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: "#06B0CF",
                color: "#ffffff",
                borderRadius: 8,
                boxShadow: "0px 3px 6px #00000029",
              }}
              variant="contained"
              autoFocus
              color="primary"
              disabled={disableButton}
              onClick={() => handleSave()}
            >
              Salvar
            </Button>
          </center>
        </>
      </DialogContent>
    </Dialog>
  );
}
