import React, { useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import AllOutIcon from "@material-ui/icons/AllOut";
import MicIcon from "@material-ui/icons/Mic";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BlurOffIcon from "@material-ui/icons/BlurOff";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import IconButton from "@material-ui/core/IconButton";

import { useStyles } from "../styles";
import { changeDevicesVirtualFenceVisibility } from "../../../../store/ducks/monitoringMap";

export default function IconSelectors({ device, startCall }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const visibilityRoutes = useSelector(
    (store) => store.monitoringMap.visibilityRoute
  );

  const virtualFenceVisibility = useSelector(
    (store) => store.monitoringMap.virtualFenceVisibility
  );

  const visibilityValidation = _.findIndex(
    visibilityRoutes,
    (o) => o.id === device.device.id
  );

  const DeviceVirtualFenceIsEnable = (deviceId) => {
    return virtualFenceVisibility.some(({ id }) => id === deviceId);
  };

  function addZoomInAction(zoom) {
    return { type: "ADD_ZOOM_IN", zoom };
  }

  const addZoomIn = (zoom) => {
    dispatch(addZoomInAction(zoom));
  };

  const addCenterMap = (centerMap) => {
    dispatch(addCenterMapAction(centerMap));
  };

  function addCenterMapAction(centerMap) {
    return { type: "ADD_CENTER_MAP", centerMap };
  }

  const addvisibilityRoute = (visibilityRoute) => {
    dispatch(addvisibilityRouteAction(visibilityRoute));
  };

  function addvisibilityRouteAction(visibilityRoute) {
    return { type: "ADD_VISIBILITY_ROUTE", visibilityRoute };
  }

  const rmvVisibilityRoute = (visibilityRoute) => {
    const visibility = _.filter(
      visibilityRoutes,
      (o) => o.id !== visibilityRoute
    );
    dispatch(rmvVisibilityRouteAction(visibility));
  };

  function rmvVisibilityRouteAction(visibilityRoute) {
    return { type: "RMV_VISIBILITY_ROUTE", visibilityRoute };
  }

  const handleVirtualFenceVisibilityBtn = (deviceId) => {
    return () => {
      let devices = virtualFenceVisibility;

      if (DeviceVirtualFenceIsEnable(deviceId)) {
        devices = devices.filter(({ id }) => id !== deviceId);
      } else {
        devices.push({ id: deviceId });
      }

      dispatch(changeDevicesVirtualFenceVisibility(devices));
    };
  };

  return (
    <div className={classes.icons}>
      <IconButton
        size="small"
        onClick={() => addZoomIn(10)}
        className={classes.iconButton}
        disabled={device.device.last_position === null}
      >
        <AllOutIcon fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          addZoomIn(17);
          addCenterMap({
            lat: Number(device.device.last_position.coordinates[0]),
            lng: Number(device.device.last_position.coordinates[1]),
          });
        }}
        disabled={device.device.last_position === null}
        className={classes.iconButton}
      >
        <FullscreenExitIcon fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          visibilityValidation >= 0
            ? rmvVisibilityRoute(device.device.id)
            : addvisibilityRoute({ id: device.device.id });
        }}
        disabled={device.transfer?.scheduled_date !== undefined ? false : true}
        className={classes.iconButton}
      >
        {visibilityValidation < 0 ? (
          <VisibilityOffIcon fontSize="small" />
        ) : (
          <VisibilityIcon fontSize="small" />
        )}
      </IconButton>
      <IconButton
        size="small"
        disabled={!device.device?.device_setting?.virtual_fence}
        onClick={handleVirtualFenceVisibilityBtn(device.device.id)}
        className={classes.iconButton}
      >
        {DeviceVirtualFenceIsEnable(device.device.id) ? (
          <BlurOnIcon fontSize="small" />
        ) : (
          <BlurOffIcon fontSize="small" />
        )}
      </IconButton>
      <IconButton
        size="small"
        onClick={() => startCall(device)}
        className={classes.iconButton}
        disabled={
          device.device.last_position === null ||
          device.device.device_type_id != 1
        }
      >
        <MicIcon fontSize="small" />
      </IconButton>
    </div>
  );
}
