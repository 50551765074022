import axios from "axios";
import { addUserAuthorization } from "../middlewares";

const areasApi = axios.create({
  baseURL: process.env.REACT_APP_AREAS_SERVICE_API,
});

addUserAuthorization(areasApi);

export { areasApi };
