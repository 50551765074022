import devicesAPI from "./devices";

export const getCustomerPointsOfInterest = async (customerId) => {
  const response = await devicesAPI.get(
    `/customer/${customerId}/customer-points-of-interest`
  );
  return response.data;
};

export const getCustomerPointsOfInterestTypes = async () => {
  const response = await devicesAPI.get(`/customer-points-of-interest-types`);
  return response.data;
};
