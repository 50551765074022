import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon, IconButton, Typography } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

import LinkedPOIs from "./LinkedPOIs";
import ReportTextField from "../../components/ReportForm/ReportTextField";
import MenuSideBar from "../../components/Sidebar/sidebar";

import { ModalRegisterPoi } from "./ModalRegisterPoi";
import { ModalEditPoi } from "./ModalEditPoi";
import { ModalDeletePoi } from "./ModalDeletePoi";

import { Div, Container } from "./styles";

import { handleOpenModalRegisterPoi, setSearch } from "../../store/ducks/poi";

const tabsClasses = {
  root: "tabsContainer",
  indicator: "tabsBorderBottomColor",
};

export default function POIManagement() {
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    dispatch(setSearch(e.target.value));
  };

  return (
    <Div>
      <MenuSideBar />
      <Container>
        <div className="report-form">
          <Typography variant="h4" className="pageTitle">
            Gestão de Pontos de Interesses
          </Typography>
        </div>

        <div className="devicesSearchBar">
          <div className="div-reportTextField">
            <ReportTextField
              name="event"
              placeholder="Buscar por pontos de interesses"
              type="text"
              onChange={handleSearch}
            />
          </div>

          <IconButton
            style={{ marginRight: "50px", backgroundColor: "#06B0CF" }}
            onClick={() => dispatch(handleOpenModalRegisterPoi())}
          >
            <Icon>
              <AddIcon color="inherit" />
            </Icon>
          </IconButton>
        </div>

        <LinkedPOIs />
        <ModalRegisterPoi />
        <ModalEditPoi />
        <ModalDeletePoi />
      </Container>
    </Div>
  );
}
