export const fromPOITypeApi = (data) => {
  return {
    id: data.id,
    type: data.type,
    typeEn: data.type_en,
    icon: data.icon,
    color: data.color,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    deletedAt: data.deleted_at,
  };
};
