export const types = {
  GET_OCCURENCES_REPORT: "GET_OCCURENCES_REPORT",
  GET_OCCURENCES_REPORT_SUCCESS: "GET_OCCURENCES_REPORT_SUCCESS",
  GET_OCCURENCES_REPORT_FAILURE: "GET_OCCURENCES_REPORT_FAILURE",
  GET_DEVICE_API_RESULT: "GET_DEVICE_API_RESULT",
  GET_DEVICE_API_RESULT_SUCCESS: "GET_DEVICE_API_RESULT_SUCCESS",
  GET_DEVICE_API_RESULT_FAILURE: "GET_DEVICE_API_RESULT_FAILURE",
  GET_TRANSFERS_WITH_DEVICES: "GET_TRANSFERS_WITH_DEVICES",
  GET_TRANSFERS_WITH_DEVICES_SUCCESS: "GET_TRANSFERS_WITH_DEVICES_SUCCESS",
  GET_TRANSFERS_WITH_DEVICES_FAILURE: "GET_TRANSFERS_WITH_DEVICES_FAILURE",
  TRANSFERS_WITH_DEVICES_MONITORED: "TRANSFERS_WITH_DEVICES_MONITORED",
  EMISSIONS_CO2_TREES_FOR_REPLANTING: "EMISSIONS_CO2_TREES_FOR_REPLANTING",
  LOADING_GASES_REPORT: "LOADING_GASES_REPORT",
};

const INITIAL_VALUES = {
  occurenceLoading: false,
  occurence: [],
  emissionsCo2TreesForReplanting: {},
  loadingGasesReport: false,
  businessUnity: {},
  businessUnityLoading: false,
  deviceApiResponse: [],
  deviceApiResponseLoading: false,
  transfersWithDevicesResponse: {},
  transfersWithDevicesResponseLoading: false,
  transfersWithDevicesMonitored: false,
  reportingDataUnity: {},
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.GET_OCCURENCES_REPORT:
      return {
        ...state,
        occurenceLoading: true,
      };
    case types.GET_OCCURENCES_REPORT_SUCCESS:
      return {
        ...state,
        occurenceLoading: false,
        occurence: action.payload,
      };
    case types.GET_OCCURENCES_REPORT_FAILURE:
      return {
        ...state,
        occurenceLoading: false,
        occurence: [],
      };
    case types.GET_DEVICE_API_RESULT:
      return {
        ...state,
        deviceApiResponseLoading: true,
      };
    case types.GET_DEVICE_API_RESULT_SUCCESS:
      return {
        ...state,
        deviceApiResponseLoading: false,
        deviceApiResponse: action.payload,
      };
    case types.GET_DEVICE_API_RESULT_FAILURE:
      return {
        ...state,
        deviceApiResponseLoading: false,
        deviceApiResponse: [],
      };
    case types.GET_TRANSFERS_WITH_DEVICES:
      return {
        ...state,
        transfersWithDevicesResponseLoading: true,
      };
    case types.GET_TRANSFERS_WITH_DEVICES_SUCCESS:
      return {
        ...state,
        transfersWithDevicesResponseLoading: false,
        transfersWithDevicesResponse: action.payload,
      };
    case types.GET_TRANSFERS_WITH_DEVICES_FAILURE:
      return {
        ...state,
        transfersWithDevicesResponseLoading: false,
        transfersWithDevicesResponse: {},
      };
    case types.TRANSFERS_WITH_DEVICES_MONITORED:
      return {
        ...state,
        transfersWithDevicesMonitored: action.payload,
      };
    case types.EMISSIONS_CO2_TREES_FOR_REPLANTING:
      return {
        ...state,
        emissionsCo2TreesForReplanting: action.payload,
      };
    case types.LOADING_GASES_REPORT:
      return {
        ...state,
        loadingGasesReport: action.payload,
      };
    default:
      return { ...state };
  }
};

export const getOccurenceData = (data) => ({
  type: types.GET_OCCURENCES_REPORT,
  payload: data,
});

export const getDataEmissionsCo2 = (data) => ({
  type: types.EMISSIONS_CO2_TREES_FOR_REPLANTING,
  payload: data,
});

export const getDeviceApiData = (data) => ({
  type: types.GET_DEVICE_API_RESULT,
  payload: data,
});

export const getTransfersWithDevicesData = (data) => ({
  type: types.GET_TRANSFERS_WITH_DEVICES,
  payload: data,
});

export const getIsMonitored = (data) => ({
  type: types.TRANSFERS_WITH_DEVICES_MONITORED,
  payload: data,
});

export const activeLoadingGasesReport = (value) => ({
  type: types.LOADING_GASES_REPORT,
  payload: value,
});
