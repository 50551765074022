import { makeStyles } from '@material-ui/styles';

export const useScriptingStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	drawer: { /* [theme.breakpoints.up('sm')]: { width: 350 } */
		width: 350
	},
	drawerPaper: {
		width: 350
	}
}));