import React, { useCallback, useState, useEffect, useRef } from "react";

import EditDeviceTab from "./components/editDevice";
import AlertsDeviceTab from "./components/alertsDevice";
import LimitsDeviceTab from "./components/limitsDevice";
import CarDeviceTab from "./components/carDevice";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { Edit, Tune } from "@material-ui/icons";
import { Tab, Tabs, DialogTitle } from "@material-ui/core/";
import {
  postDeviceSettings,
  getDeviceSettingsConfiguration,
} from "../../../../infra/http/devicesProvider";
import { toast } from "material-react-toastify";

import { useSelector, useDispatch } from "react-redux";
import {
  resetForm,
  loadingDataApi,
} from "../../../../store/ducks/deviceManagement";

import { Container, Footer } from "./styles";

import transferConstants from "../../../../domain/constants/transfers";
import { multiPolygon } from "@turf/helpers";

const tabsClasses = {
  root: "tabsContainer",
  indicator: "tabsBorderBottomColor",
};

const styles = {
  root: {
    margin: 0,
    display: "flex",
    flexDirection: "row",
  },
  closeButton: {
    position: "absolute",
  },
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function MainModalDeviceManagement({
  transferDataId,
  iconTheme,
  screenClick,
  deviceId,
  updateTable,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [screenSetting, setScreenSetting] = useState("deviceData");
  const [deviceSettingConfig, setDeviceSettingConfig] = useState({});
  let { events, alias, deviceIconId, polygons, deviceSettingsId, carId } =
    useSelector((store) => store.deviceMangement);

  const dispatch = useDispatch();

  useEffect(() => {
    setScreenSetting(screenClick);
    return () => {
      setScreenSetting();
    };
  }, [setScreenSetting]);

  useEffect(() => {
    if (openModal == true) {
      async function getDeviceConfig() {
        const response = await getDeviceSettingsConfiguration(transferDataId);

        dispatch(loadingDataApi(response));
      }
      transferDataId && getDeviceConfig();
    }
  }, [openModal]);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    dispatch(resetForm());
  };

  const submitDeviceSettings = async () => {
    const polygonsArray = Object.values(polygons);
    let virtualFence = {};

    if (polygonsArray.length === 0) {
      virtualFence = null;
    } else {
      virtualFence = multiPolygon(
        polygonsArray.map(({ coordinates }) => coordinates)
      ).geometry;
    }

    if (deviceIconId == 0) {
      deviceIconId = null;
    }

    const data = {
      events,
      alias,
      deviceIconId,
      virtualFence,
      deviceSettingsId,
      carId,
    };

    try {
      const response = await postDeviceSettings(data, deviceId);
    } catch (e) {
      toast.error(
        "Algo inesperado aconteceu, verifique os dados e tente novamente"
      );
    }

    updateTable();
    handleClose();
  };

  const tabsOptions = () => {
    return (
      <Container>
        <Button onClick={handleClose}>
          <ChevronLeftIcon fontSize="large" style={{ color: "#06B0CF" }} />
        </Button>
        <Tabs value={screenSetting} classes={tabsClasses}>
          <Tab
            className="tabTitle"
            label="Dados do dispositivo"
            value="deviceData"
            onClick={() => setScreenSetting("deviceData")}
          />
          <Tab
            className="tabTitle"
            label="Limites do dispositivo"
            value="deviceLimits"
            onClick={() => setScreenSetting("deviceLimits")}
          />
          <Tab
            className="tabTitle"
            label="Alertas e notificações"
            value="deviceAlerts"
            onClick={() => setScreenSetting("deviceAlerts")}
          />
          <Tab
            className="tabTitle"
            label="Dados do veiculo"
            value="deviceCar"
            onClick={() => setScreenSetting("deviceCar")}
          />
        </Tabs>
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={handleClose}
        >
          <CloseIcon style={{ color: "#06B0CF" }} />
        </IconButton>
      </Container>
    );
  };

  return (
    <Badge>
      <Button
        onClick={handleClickOpen}
        style={{
          borderRadius: 5,
          backgroundColor: "#DCDCDC",
          boxShadow: "none",
          border: "none",
        }}
      >
        <IconButton> {iconTheme === "edit" ? <Edit /> : <Tune />} </IconButton>
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        style={{ zIndex: 2 }}
        onClose={handleClose}
        aria-labelledby="devices-management"
        open={openModal}
      >
        <DialogTitle
          disableTypography
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
          }}
          id="devices-management"
          onClose={handleClose}
        >
          {tabsOptions()}
        </DialogTitle>
        <DialogContent>
          {screenSetting == "deviceData" && <EditDeviceTab />}

          {screenSetting == "deviceLimits" && <LimitsDeviceTab />}

          {screenSetting == "deviceAlerts" && <AlertsDeviceTab />}

          {screenSetting == "deviceCar" && <CarDeviceTab />}
        </DialogContent>
        <DialogActions>
          <br />
        </DialogActions>
        <Footer>
          <Button
            style={{
              borderRadius: 5,
              color: "#FFFF",
              boxShadow: "none",
              border: "none",
              fontWeight: "bold",
              fontSize: 16,
            }}
            onClick={submitDeviceSettings}
          >
            Salvar Configurações
          </Button>
        </Footer>
      </Dialog>
    </Badge>
  );
}

export default MainModalDeviceManagement;
