import transferConstants from "../../../../domain/constants/transfers"

export const getTransferStatus = (transferStatus) => {
    switch (transferStatus) {
      case 'not-scripted':
        return transferConstants.status.NOT_SCRIPTED 
      case 'scripted':
        return transferConstants.status.SCRIPTED
      case 'canceled':
        return transferConstants.status.CANCELED
      case 'not-ended':
        return transferConstants.status.NOT_ENDED
      case 'ongoing':
        return transferConstants.status.ONGOING
      case 'ended':
        return transferConstants.status.ENDED
      default:
        break;
    }
  }

  