import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

import * as occurrencesService from '../../../services/occurrences'
import {
  setDisplayedOccurrences,
  setOccurrencesCategoriesAction
} from '../../../store/ducks/scripting'



const toLowerCaseLabels = (string = '') => {
  if (string.length === 0)
    return ''

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

const DRAWER_WIDTH = 480;

const useStyles = makeStyles(() => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0 8px 0'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    marginLeft: '12px',
    fontWeight: 500
  },
  listDefaults: {
    width: '100%'
  },
  listItemDefaults: {
    paddingLeft: 0,
    paddingRight: 0
  },
  drawerList: {
    padding: '8px'
  },
  drawerNestedList: {
    marginLeft: '20px'
  }
}))



const getCategoryCheckboxState = (displayedOccurrences, categoryId) => {
  let numberSubCategoriesChecked = 0
  const subCategories = displayedOccurrences.categories[categoryId].subCategories

  for (const subCategoryId of subCategories) {
    if (displayedOccurrences.subCategories[subCategoryId])
      numberSubCategoriesChecked++
  }

  if (numberSubCategoriesChecked === 0) {
    return 'unchecked'
  } else if (numberSubCategoriesChecked === subCategories.length) {
    return 'checked'
  } else {
    return 'indeterminate'
  }
}



const OccurrencesOptions = () => {
  const [open, setOpen] = useState(false)
  
  const classes = useStyles()
  
  const occurrencesCategories = useSelector(state => state.scripting.occurrencesCategories)
  const displayedOccurrences = useSelector(state => state.scripting.displayedOccurrences)
  const dispatch = useDispatch()

  useEffect(() => {
    const configureOccurrencesCategories = async () => {
      let occurrencesCategories = null
      try {
        occurrencesCategories = await occurrencesService.getOccurrencesNatures()

      } catch (error) {
        throw new Error('Failed to fetch occurrences list.')
      }

      dispatch(setOccurrencesCategoriesAction(occurrencesCategories))

      if (displayedOccurrences !== null)
        return

      const newDisplayedOccurrences = {
        categories: {},
        subCategories: {},
      }

      for (const occurrenceCategory of occurrencesCategories) {
        newDisplayedOccurrences.categories[occurrenceCategory.id] = {
          allItemsSelected: null,
          subCategories: []
        }
        const categoryData = newDisplayedOccurrences.categories[occurrenceCategory.id]
        
        for (const subCategory of occurrenceCategory.types) {
          categoryData.subCategories.push(subCategory.id)
          newDisplayedOccurrences.subCategories[subCategory.id] = subCategory.selected
        }

        categoryData.allItemsSelected = getCategoryCheckboxState(newDisplayedOccurrences, occurrenceCategory.id)
      }
      
      try {
        dispatch(setDisplayedOccurrences(newDisplayedOccurrences))
      }
      catch (error) {
        console.log(error)
      }
    }

    try {
      configureOccurrencesCategories()
    } catch (error) {
      console.log(error)
    }
  }, [])


  const handleAdvancedOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return displayedOccurrences
    ? (
      <Fragment>
        <List className={classes.listDefaults}>
          {occurrencesCategories.map(occurrenceCategory => (
            <OccurrenceCategoryCheckboxItemSolo
              occurrenceCategory={occurrenceCategory}
            />
          ))}
          <div>
            <Button
              onClick={handleAdvancedOpen}
              style={{
                fontWeight: '400'
              }}>
              AVANÇADO
            </Button>
          </div>
        </List>
        <div style={{ position: 'absolute' }}>
          <Drawer
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
            className={classes.drawer}>
            <div className={classes.drawerHeader}>
              <div className={classes.headerTitle}>
                <GroupWorkIcon
                  fontSize="large"
                  style={{
                    paddingRight: 10,
                    paddingBottom: 2
                  }}
                />
                <div>Clipping de Segurança Pública</div>
              </div>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <Grid
              container
              direction="column"
              justify="flex-start"
              className={classes.drawerList}
            >
              {occurrencesCategories.map(occurrenceCategory => (
                <OccurrenceCategoryCheckboxItemWithSubItems
                  occurrenceCategory={occurrenceCategory}
                  checked={true}
                  onClick={() => { }}
                />
              ))}
            </Grid>
          </Drawer>
        </div>
      </Fragment >
    ) : (
      'Loading'
    )
}



const OccurrenceCategoryCheckboxItemSolo = ({ occurrenceCategory, onClickItem = null }) => {
  const classes = useStyles()

  const rightSideComponent = (
    <ProportionalImageIcon
      icon={occurrenceCategory.icon}
    />
  )

  return (
    <OccurrenceCategoryCheckboxItem
      id={occurrenceCategory.id}
      label={occurrenceCategory.category}
      rightSideComponent={rightSideComponent}
      onClickItem={onClickItem}
      listItemClass={classes.listItemDefaults}
    />
  )
}

const OccurrenceCategoryCheckboxItemWithSubItems = ({ occurrenceCategory }) => {
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  return (
    <Fragment>
      <OccurrenceCategoryCheckboxItem
        id={occurrenceCategory.id}
        label={occurrenceCategory.category}
        rightSideComponent={open ? <ExpandLess /> : <ExpandMore />}
        onClickItem={() => setOpen(!open)}
        labelStyle={{ fontWeight: '500' }}
      />

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={classes.drawerNestedList}
        >
          {occurrenceCategory.types.map(subOccurrences => {
            const rightSideComponent = (
              <ProportionalImageIcon
                icon={subOccurrences.icon}
              />
            )
            
            return (
              <OccurrenceSubCategoryCheckboxItem
                categoryId={occurrenceCategory.id}
                id={subOccurrences.id}
                label={subOccurrences.type}
                rightSideComponent={rightSideComponent}
              />
            )
          })}
        </List>
      </Collapse>
    </Fragment>
  )
}

const ProportionalImageIcon = ({ icon }) => (
  <div style={{
    display: 'flex',
    alignItems: 'center'
  }}>
    <img
      src={icon}
      alt=""
      style={{
        height: 'auto',
        width: 'auto'
      }}
    />
  </div>
)

const OccurrenceCategoryCheckboxItem = ({
  id,
  label,
  rightSideComponent = null,
  labelStyle = {},
  onClickItem = null,
  ...otherProps
}) => {
  const displayedOccurrences = useSelector(state => state.scripting.displayedOccurrences)
  const dispatch = useDispatch()

  const handleOccurrenceCategoryCheckboxClick = () => {
    const displayedOccurrence = displayedOccurrences.categories[id]

    if (displayedOccurrence.allItemsSelected === 'checked')
      displayedOccurrence.allItemsSelected = 'unchecked'
    else
      displayedOccurrence.allItemsSelected = 'checked'

    switch (displayedOccurrence.allItemsSelected) {
      case 'checked':
        for (const id of displayedOccurrence.subCategories)
          displayedOccurrences.subCategories[id] = true
        break;
      case 'unchecked':
        for (const id of displayedOccurrence.subCategories)
          displayedOccurrences.subCategories[id] = false
        break;
      default:
        break;
    }

    dispatch(setDisplayedOccurrences(displayedOccurrences))
  }

  return (
    <OccurrenceCheckbox
      label={label}
      rightSideComponent={rightSideComponent}
      checked={displayedOccurrences.categories[id].allItemsSelected === 'checked'}
      indeterminate={displayedOccurrences.categories[id].allItemsSelected === 'indeterminate'}
      onClickCheckbox={onClickItem ? handleOccurrenceCategoryCheckboxClick : null}
      onClickItem={onClickItem ? onClickItem : handleOccurrenceCategoryCheckboxClick}
      labelStyle={labelStyle}
      {...otherProps}
    />
  )
}



const OccurrenceSubCategoryCheckboxItem = ({
  id,
  label,
  categoryId,
  rightSideComponent = null,
  onClickItem = null,
  ...otherProps
}) => {
  const displayedOccurrences = useSelector(state => state.scripting.displayedOccurrences)
  const dispatch = useDispatch()

  const handleClick = () => {
    displayedOccurrences.subCategories[id] = !displayedOccurrences.subCategories[id]

    displayedOccurrences.categories[categoryId].allItemsSelected = getCategoryCheckboxState(displayedOccurrences, categoryId)

    dispatch(setDisplayedOccurrences(displayedOccurrences))
  }

  return (
    <OccurrenceCheckbox
      label={label}
      rightSideComponent={rightSideComponent}
      checked={displayedOccurrences.subCategories[id]}
      onClickCheckbox={onClickItem ? handleClick : null}
      onClickItem={onClickItem ? onClickItem : handleClick}
      {...otherProps}
    />
  )
}



const OccurrenceCheckbox = ({
  label,
  rightSideComponent,
  checked,
  onClickCheckbox=null,
  onClickItem=null,
  listItemClass = '',
  labelStyle = {},
  indeterminate = false
}) => {
  return (
    <ListItem
      dense
      button
      onClick={onClickItem}
      className={listItemClass}
    >
      <ListItemIcon>
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onClick={(e) => {
            if (onClickCheckbox === null)
              return
            e.stopPropagation()
            onClickCheckbox()
          }}
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
          name={label}
          color="primary"
        />
      </ListItemIcon>

      <span
        style={{
          flex: 1,
          ...labelStyle,
        }}
      >{toLowerCaseLabels(label)}</span>

      {rightSideComponent}
    </ListItem >
  )

}



export default OccurrencesOptions
