import React from 'react';
import { makeStyles, Slide, Avatar } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContentText, DialogContent, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  dialog: {
    position: 'absolute',
    right: 10,
    width: 450
  },
  large: {
    width: 45,
    height: 45,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 20,
  paddingRight: 20
}

export const OccurrencesAlertDialog = ({ isOpen, setIsOpen, currentOccurrence }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={() => setIsOpen(false)}
      classes={{ paper: classes.dialog }}
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={() => setIsOpen(false)}>
        <Close />
      </IconButton>

      <DialogTitle>
        <strong>Clipping de segurança pública: </strong> {currentOccurrence.title} <br />
      </DialogTitle>
      <DialogContent>
        <div style={rowStyle}>
          <Avatar className={classes.large} src={currentOccurrence.type.icon} />
        </div>
        <DialogContentText style={{ color: '#333' }}>
          <div style={{ ...rowStyle, marginTop: 10, marginBottom: 10, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>Registrado em:</strong>
              <span>
                {new Date(currentOccurrence.occurredAt).toLocaleString('pt-br')}
              </span>
              {/* <span>{currentOccurrence.occurredAt}</span> */}
            </div>
          </div>
        </DialogContentText>
        <DialogContentText style={{ color: '#333' }}>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingRight: 20 }}>
            <strong>Evento:</strong>
            <span>{currentOccurrence.description}</span>
          </div>
        </DialogContentText>
        <DialogContentText style={{ color: '#333' }}>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingRight: 20 }}>
            {currentOccurrence.links.length > 0 && <strong>Link(s):</strong>}
            {currentOccurrence.links.map((link, index) => (
              <div>
                <a href={link.url} target="_blank">Link {index + 1}</a>
              </div>
            ))}
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}