const styles = {
  square: ({ color, size }) => ({
    width: size,
    height: size,
    display: 'block',
    backgroundColor: color,
    marginRight: 12,
    borderRadius: 5
  })
};

export default styles;
