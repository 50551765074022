import React from "react";
import { useDispatch } from "react-redux";

import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import "date-fns";

function FilterMonitoring() {
  const dispatch = useDispatch();

  function addIdentifierAction(identifierSearch) {
    return { type: "ADD_IDENTIFIER_SEARCH", identifierSearch };
  }

  const addIdentifier = (event) => {
    const value = event.target.value;
    dispatch(addIdentifierAction(value));
  };

  return (
    <>
      <TextField
        className="search-identifier"
        style={{ width: 241 }}
        onChange={addIdentifier}
        label="Nome"
        InputLabelProps={{
          style: {
            marginTop: -3,
          },
        }}
        type="search"
        variant="outlined"
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
          style: {
            height: 32,
          },
        }}
      />
    </>
  );
}

export default FilterMonitoring;
