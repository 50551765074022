import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, matchPath } from "react-router-dom";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  TrackChanges as TrackChangesIcon,
  History as HistoryIcon,
  DriveEta as DriveEtaIcon,
  EmojiTransportation as EmojiTransportationIcon,
  ExitToApp as ExitToAppIcon,
  Settings as SettingsIcon,
  Description as DescriptionIcon,
  Watch as WatchIcon,
  SupervisorAccount as SupervisorAccountIcon,
} from "@material-ui/icons";

import { Div, Bg, ListItemIconStyled } from "./styles";
import logomap from "../../assets/logo-utransfermap-50x50.png";

export default function MenuSideBar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  function addLogoutAction() {
    return { type: "ADD_LOGOUT" };
  }

  const addLogout = () => {
    dispatch(addLogoutAction());
  };

  function handleLogout() {
    try {
      localStorage.clear();
      addLogout();
      history.push("/");
    } catch (err) {
      alert("Erro ao realizar logout");
    } finally {
      window.location.reload(true);
    }
  }
  let permissionsValidation = [];
  const permissions = useSelector((store) => store.auth.permissions);

  if (permissions.permissions !== undefined) {
    permissionsValidation = permissions.permissions.data;
  } else {
    history.push("/");
  }

  return (
    <>
      <Div>
        <Bg>
          <Link className="linkMonitoringMap" to="/monitoringmap">
            <ListItemIcon title="Mapa de monitoramento">
              <img src={logomap} alt="Utransfer Web" />
            </ListItemIcon>
          </Link>
        </Bg>
        {permissionsValidation.includes("can-view-routes") && (
          <List component="li">
            <ListItem button className="btnHome">
              <Link className="linkHome" to="/transfers">
                <ListItemIconStyled
                  title="Transfers"
                  active={Boolean(matchPath("/transfers", location.pathname))}
                >
                  <DriveEtaIcon />
                </ListItemIconStyled>
              </Link>
              <ListItemText />
            </ListItem>
          </List>
        )}
        {permissionsValidation.includes("can-view-preconfigured-transfers") && (
          <List component="li">
            <ListItem button className="btnPreConfigured">
              <Link className="linkPreConfigured" to="/preconfigured">
                <ListItemIconStyled
                  title="Transfers Pré-configurados"
                  active={Boolean(
                    matchPath("/preconfigured", location.pathname)
                  )}
                >
                  <EmojiTransportationIcon />
                </ListItemIconStyled>
              </Link>
              <ListItemText />
            </ListItem>
          </List>
        )}
        {permissionsValidation.includes("can-monitoring-devices") && (
          <List component="li">
            <ListItem button className="btnMonitoring">
              <Link
                className="linkMonitoring"
                to={
                  JSON.parse(
                    localStorage.getItem("@UTransfer/identifiersSelected")
                  ) === null
                    ? "/monitoring"
                    : "/monitoringmap"
                }
              >
                <ListItemIconStyled
                  title="Monitoramento"
                  active={Boolean(
                    matchPath(
                      JSON.parse(
                        localStorage.getItem("@UTransfer/identifiersSelected")
                      ) === null
                        ? "/monitoring"
                        : "/monitoringmap",
                      location.pathname
                    )
                  )}
                >
                  <TrackChangesIcon />
                </ListItemIconStyled>
              </Link>
              <ListItemText />
            </ListItem>
          </List>
        )}
        {permissionsValidation.includes("can-view-transfer-history") && (
          <List component="li">
            <ListItem button className="btnReports">
              <Link className="linkMonitoring" to="/reports">
                <ListItemIconStyled
                  title="Relatórios"
                  active={Boolean(matchPath("/reports", location.pathname))}
                >
                  <HistoryIcon />
                </ListItemIconStyled>
              </Link>
              <ListItemText />
            </ListItem>
          </List>
        )}
        {permissionsValidation.includes("can-edit-fields") && (
          <List component="li">
            <ListItem button className="btnAreaManagement">
              <Link className="linkMonitoring" to="/fieldmanagement">
                <ListItemIconStyled
                  title="Gestão de Campos"
                  active={Boolean(
                    matchPath("/fieldmanagement", location.pathname)
                  )}
                >
                  <DescriptionIcon />
                </ListItemIconStyled>
              </Link>
              <ListItemText />
            </ListItem>
          </List>
        )}
        {permissionsValidation.includes("can-manage-people") && (
          <List component="li">
            <ListItem button className="btnDeviceManagement">
              <Link className="linkMonitoring" to="/managementpeople">
                <ListItemIconStyled
                  title="Gestão de Pessoas"
                  active={Boolean(
                    matchPath("/managementpeople", location.pathname)
                  )}
                >
                  <SupervisorAccountIcon />
                </ListItemIconStyled>
              </Link>
              <ListItemText />
            </ListItem>
          </List>
        )}
        {permissionsValidation.includes("can-manage-devices") && (
          <List component="li">
            <ListItem button className="btnDeviceManagement">
              <Link className="linkMonitoring" to="/devicemanagement">
                <ListItemIconStyled
                  title="Gestão de Dispositivos"
                  active={Boolean(
                    matchPath("/devicemanagement", location.pathname)
                  )}
                >
                  <SettingsIcon />
                </ListItemIconStyled>
              </Link>
              <ListItemText />
            </ListItem>
          </List>
        )}
        {permissionsValidation.includes("can-manage-poi") && (
          <List component="li">
            <ListItem button className="btnDeviceManagement">
              <Link className="linkMonitoring" to="/poimanagement">
                <ListItemIconStyled
                  title="Gestão de Pontos de Interesses"
                  active={Boolean(
                    matchPath("/poimanagement", location.pathname)
                  )}
                >
                  <WatchIcon />
                </ListItemIconStyled>
              </Link>
              <ListItemText />
            </ListItem>
          </List>
        )}
        <br />
        <br />
        <br />
        <br />

        <List component="li">
          <ListItem button className="btnReports" onClick={handleLogout}>
            <ListItemIcon title="Logout">
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText />
          </ListItem>
        </List>
      </Div>
    </>
  );
}
