import React from 'react'

import { DialogContent } from '@material-ui/core'

import useStyles from './styles'

const CustomDialogContent = ({
  content,
}) => {
  const classes = useStyles()

  return (
    <DialogContent
      className={classes.dialogContent}
    >
      {
        content.map((item, index) => (
          <div
            key={index}
            className={classes.dialogInfo}
          >
            <div
              className={classes.title}
            >
              {item.title}
            </div>
            <div
              className={classes.text}
            >
              {item.text}
            </div>
          </div>
        ))
      }
    </DialogContent>
  )
}

export default CustomDialogContent