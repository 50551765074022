import React from "react";
import { ToastContainer } from "material-react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";

import { store, persistor } from "./store";
import Routes from "./routes";
import "material-react-toastify/dist/ReactToastify.css";
import "./global.css";

require("dotenv").config();

function App() {
  const theme = createTheme();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ToastContainer position="top-right" />
          <Routes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
