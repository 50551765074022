/**
 * App wide constants module.
 */

const constants = {
  tables: {
    DEFAULT_NO_ROWS_MESSAGE: 'Não há dados para o período.',
    DEFAULT_ROWS_PER_PAGE: 5,
    FIRST_PAGE: 0,
  },
}

export default constants