import React from 'react';
import Grid from '@material-ui/core/Grid';

import { FieldArray } from 'redux-form';
import { Div } from './../styles'
import CheckboxFields from './CheckboxFields';

const WeekDays = () => (
  <Div>
    <Grid
      item
      xs={12}
    >
      <FieldArray
        name="custom_days"
        component={CheckboxFields}
        props={{
          legend: 'Definir dias da semana',
          labels: ['Domingos', 'Segundas', 'Terças', 'Quartas', 'Quintas', 'Sextas', 'Sábados']
        }}
      />
    </Grid>
  </Div>
);

export default WeekDays;
