import React from "react";
import { useSelector } from "react-redux";
import SharedTable from "../../../components/SharedTable";
import constants from "../../../commons/constants";
import { Box, Typography } from "@material-ui/core";
import useStyles from "../EventAnaliticsDashboard/styles";
import { ReportLoader } from "../loader";
import { EmissionInfoCard } from "./EmissionInfoCard";
import { getGasReport } from "../../../infra/http/carProvider";
import { toast } from "material-react-toastify";

const GasEmission = ({ filters }) => {
  const customer = useSelector((state) => state.auth.customer);
  const { loadingGasesReport, emissionsCo2TreesForReplanting } = useSelector(
    (store) => store.reportData
  );
  const customerId = useSelector((state) => state.auth.customer.customer);
  const dateFrom = useSelector((state) => state.reporting.fromDate);
  const dateTo = useSelector((state) => state.reporting.toDate);

  const classes = useStyles();
  const treesForReplanting = emissionsCo2TreesForReplanting.treesForReplanting;
  const totalEmissions = emissionsCo2TreesForReplanting.totalEmissions;

  const tableHeaders = [
    "Identificador",
    "Carro",
    "Distancia Percorrida",
    "Combustivel Consumido",
    "Emissões Totais",
    "Árvores para Replantio",
  ];

  const fetchListData = async ({ currentPage, rowsPerPage }) => {
    try {
      const { data } = await getGasReport({
        customerId,
        from: dateFrom,
        to: dateTo,
        page: currentPage,
        perPage: rowsPerPage,
      });

      const rowsData = data.map((gas) => {
        const emissionValue =
          gas.co2 > 1000
            ? `${(gas.co2 / 1000).toFixed(2)} Ton`
            : `${gas.co2} Kg CO2-eq`;
        const treesForReplanting = Math.round((gas.co2 / 1000) * 6);
        return [
          customer,
          gas.identifier,
          gas.car,
          `${gas.travelled} Km`,
          `${gas.consumption} L`,
          emissionValue,
          treesForReplanting,
        ];
      });

      return {
        rowsData,
        totalRows: data.length,
      };
    } catch {
      toast.error("Falha ao tentar atualizar tabela");
    }
  };

  const renderGasEmission = () => {
    if (totalEmissions > 0) {
      return (
        <>
          <EmissionInfoCard
            totalEmissions={totalEmissions}
            treesForReplanting={treesForReplanting}
          />
          <SharedTable
            cellHeaders={tableHeaders}
            noRowsDataMessage={constants.tables.DEFAULT_NO_ROWS_MESSAGE}
            defaultRowsPerPage={5}
            withPagination={true}
            rowsDataFetcher={fetchListData}
          />
        </>
      );
    } else {
      return (
        <Box className={classes.noticeContainer}>
          <Typography> Selecione o período e aperte Buscar </Typography>
        </Box>
      );
    }
  };

  return <>{loadingGasesReport ? <ReportLoader /> : renderGasEmission()}</>;
};

export default GasEmission;
