import utransferApi from './transferApi'

import { store } from '../store'

export const getOccurrencesNatures = async () => {
  const state = store.getState()

  const token = state.auth?.token?.accessToken

  try {
    const response = await utransferApi.get(
      '/occurrences/natures',
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response.data

  } catch (error) {
    throw error
  }
}

export const getOccurrences = async ({ afterData, from, radius, to, linestring }) => {
  const state = store.getState()

  const token = state.auth?.token?.accessToken

  try {
    const response = await utransferApi.post(
      '/occurrences',
      {
        after_data: afterData,
        from,
        radius,
        to,
        linestring
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response.data

  } catch (error) {
    throw error
  }
}
