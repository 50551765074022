import { meteumApi } from "../../services/meteumApi";
import { createException } from "../../commons/exceptions";

export async function getTempByLocation(lat, lng) {
  try {
    const { data } = await meteumApi.post("/graphql/query", {
      query: `{weatherByPoint(request: { lat: ${lat}, lon: ${lng} }) { now { temperature, condition }}}`,
    });

    return data;
  } catch (error) {
    throw createException({
      code: "failed-to-post-event-error",
    });
  }
}
