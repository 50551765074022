import React, { useEffect, useState } from "react";
import { Polyline, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import decodePolyline from "decode-google-map-polyline";
import _ from "lodash";
import To from "../../assets/IconTo06B0CF.png";
import From from "../../assets/IconFrom06B0CF.png";

export default function TransferRoute() {
  const [route, setRoute] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const devices = useSelector((store) => store.monitoringMap.devices);
  const transfersActive = useSelector(
    (store) => store.monitoringMap.transferActive
  );
  const visibilityRoutes = useSelector(
    (store) => store.monitoringMap.visibilityRoute
  );
  const routePolyline = useSelector((store) => store.monitoringMap.stepRoute);

  const optionsPolyline = {
    strokeColor: "#43A59B",
    strokeOpacity: 0.6,
    strokeWeight: 4,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  useEffect(() => {
    let transfers = [];
    devices.forEach((device) => {
      const transferValidation = _.findIndex(
        transfersActive,
        (o) => o.id === device.transfer?.id
      );
      const visibilityValidation = _.findIndex(
        visibilityRoutes,
        (o) => o.id === device.device.id
      );
      if (
        device.transfer?.id !== null &&
        visibilityValidation >= 0 &&
        transferValidation >= 0
      ) {
        transfers = [...transfers, device];
      }
    });

    if (transfers.length > 0) {
      let from = [];
      transfers.forEach((transfer) => {
        from = [
          ...from,
          {
            lat: transfer.transfer.start_position.coordinates[1],
            lng: transfer.transfer.start_position.coordinates[0],
          },
        ];
      });
      let to = [];
      transfers.forEach((transfer) => {
        to = [
          ...to,
          {
            lat: transfer.transfer.end_position.coordinates[1],
            lng: transfer.transfer.end_position.coordinates[0],
          },
        ];
      });

      setTo(to);
      setFrom(from);
      setRoute(routePolyline);
    } else {
      setTo([]);
      setFrom([]);
      setRoute("");
    }
  }, [transfersActive, devices, visibilityRoutes, routePolyline]);

  return (
    <>
      {route !== ""
        ? route.map((route, index) => {
            return (
              <li key={index}>
                <Polyline
                  path={decodePolyline(route)}
                  options={optionsPolyline}
                />
              </li>
            );
          })
        : null}

      {from.map((from, index) => {
        return (
          <li key={index}>
            <Marker title={"Ponto de Partida"} position={from} icon={From} />
          </li>
        );
      })}
      {to.map((to, index) => {
        return (
          <li key={index}>
            <Marker title={"Ponto de Destino"} position={to} icon={To} />
          </li>
        );
      })}
    </>
  );
}
