import { polygon as polygonFunction } from "@turf/helpers";
import React from "react";
import centerOfMass from "@turf/center-of-mass";
import { MainContainer } from "../alertsDevice/styles";
import {
  GoogleMap,
  DrawingManager,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import {
  addPolygonVirtualFence,
  toggleInfoWindowVisibility,
  removePolygonVirtualFence,
} from "../../../../../../store/ducks/deviceManagement";
import { mapsOptions } from "./mapOptions";
import {
  convertGeoJsonToLatLng,
  getCenterOfCoordinates,
} from "../../../../../../commons/polygonHelper";
import Googleinfowindow from "./googleInfoWindow";
import { useGoogleMapsKey } from "../../../../../../hooks/useGoogleMapsKey";

const libraries = ["places", "drawing", "geometry"];

function LimitsDeviceTab() {
  const googleMapsKey = useGoogleMapsKey();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  const dispatch = useDispatch();

  const zoom = useSelector((store) => store.monitoringMap.zoom);

  const centerMap = useSelector((store) => store.monitoringMap.centerMap);

  const polygons = useSelector((store) => store.deviceMangement.polygons);

  const onPolygonComplete = (draw) => {
    const path = draw.getPath();

    const timestampId = new Date().getTime();

    const mapCoords = [];

    for (let i = 0; i < path.length; i++) {
      mapCoords.push([path.getAt(i).lng(), path.getAt(i).lat()]);
    }

    mapCoords.push(mapCoords[0]);

    const poly = polygonFunction([mapCoords]);
    const center = centerOfMass(poly);
    const [lng, lat] = center.geometry.coordinates;

    const polygon = {
      id: timestampId,
      infoWindow: {
        visible: false,
        position: {
          lat,
          lng,
        },
      },
      coordinates: [mapCoords],
    };

    dispatch(addPolygonVirtualFence(timestampId, polygon));

    draw.setMap(null);
  };

  const handlePolygonClick = (polygonId) => {
    return () => {
      dispatch(toggleInfoWindowVisibility(polygonId));
    };
  };

  const handleRemovePolygon = (polygonId) => {
    dispatch(removePolygonVirtualFence(polygonId));
  };

  if (!isLoaded) {
    return <></>;
  }

  return (
    <MainContainer>
      <GoogleMap
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={mapsOptions}
        zoom={zoom}
        center={
          Object.values(polygons).length > 0
            ? getCenterOfCoordinates(
                Object.values(polygons).map(({ coordinates }) => coordinates)
              )
            : centerMap
        }
      >
        <>
          <DrawingManager
            options={{
              drawingControl: true,
              drawingControlOptions: {
                drawingControl: true,
                drawingModes: ["polygon"],
              },
            }}
            onPolygonComplete={onPolygonComplete}
          />
        </>

        <>
          {Object.values(polygons).map((polygon) => (
            <Polygon
              key={`polygon-${polygon.id}`}
              paths={convertGeoJsonToLatLng(polygon.coordinates)}
              onClick={handlePolygonClick(polygon.id)}
            />
          ))}
        </>

        <>
          {Object.values(polygons)
            .filter((polygon) => polygon.infoWindow.visible)
            .map((polygon) => (
              <Googleinfowindow
                key={`infoWindow-${polygon.id}`}
                polygonId={polygon.id}
                position={polygon.infoWindow.position}
                onRemovePolygon={handleRemovePolygon}
              />
            ))}
        </>
      </GoogleMap>
    </MainContainer>
  );
}

export default LimitsDeviceTab;
