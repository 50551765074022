import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TextField } from "@material-ui/core";
import styles from "../styles";

const SearchAutoCompleteDevicesEdit = ({
  placeholder,
  disable,
  unbindDialog,
  defaultValue = "",
  options = [],
  getOptionLabel = (option) => option.alias || option.identifier,
  onChange = () => {},
}) => {
  return (
    <Autocomplete
      disabled={disable}
      inputValue={defaultValue}
      options={options}
      style={styles.autocompleteField}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <IconButton style={{ height: "41px" }}>
                <CloseIcon onClick={unbindDialog} />
              </IconButton>
            ),
          }}
          placeholder={placeholder}
          variant="outlined"
        />
      )}
    />
  );
};

export default SearchAutoCompleteDevicesEdit;
