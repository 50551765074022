import React from "react";
import { View, Image } from "@react-pdf/renderer";

import utransferLogo from "../../../../assets/utransfer-logo-pdf.png";
import portalUmaisLogo from "../../../../assets/portal-umais-logo-pdf.png";

import { styles } from "./styles";

export const Header = () => {
  return (
    <View fixed style={styles.header}>
      <Image source={utransferLogo} style={styles.logo} />
      <Image source={portalUmaisLogo} style={styles.logo} />
    </View>
  );
};
