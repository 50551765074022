import React, { useState } from 'react'
import { Input } from '@material-ui/core'
import {
  Close as CloseIcon,
} from '@material-ui/icons'

import useStyles from './styles'
import { callAsyncOrNot } from '../../commons/utils'

const InlineInput = ({
  placeholder,
  defaultValue,
  isHovering,
  turnEditionModeOff,
  submitHandler: externalSubmitHandler,
  closeButtonClickHandler,
  submittingText = 'Submitting...',
  editButtonBackgroundColor = null,
}) => {
  const [isLoadingRequest, setIsLoadingRequest] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const classes = useStyles({
    visible: isHovering,
    editButtonBackgroundColor,
  })

  const onChangeValue = (event) => {
    setValue(event.target.value)
  }

  const submitHandler = async () => {
    if (isLoadingRequest)
      return

    setIsLoadingRequest(true)
    await callAsyncOrNot(externalSubmitHandler, value)
    setIsLoadingRequest(false)
  }

  const onKeyDown = (event) => {
    switch (event.key) {
      case 'Enter':
        submitHandler()
        break
      case 'Escape':
        turnEditionModeOff()
        break
      default:
        break
    }
  }

  if (isLoadingRequest)
    return (
      <Input
        disabled
        disableUnderline
        placeholder={placeholder}
        value={submittingText}
        classes={{
          root: classes.inputField,
          input: classes.inputField,
        }}
        className={classes.inputField}
      />
    )

  return (
    <Input
      autoFocus
      placeholder={placeholder}
      value={value}
      onChange={onChangeValue}
      onKeyDown={onKeyDown}
      disableUnderline
      classes={{
        root: classes.inputField,
        input: classes.inputField,
      }}
      className={classes.inputField}
      endAdornment={(
        <CloseIcon
          onClick={closeButtonClickHandler}
          classes={{
            root: classes.editButton,
          }}
        />
      )}
    />
  )
}

export default InlineInput