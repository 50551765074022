import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Switch,
  Typography,
} from "@material-ui/core";

import { parseISO, format } from "date-fns";

import fileDownload from "js-file-download";

import { toast } from "material-react-toastify";

import { exportExcel } from "../../../../../services/excel";

const ExcelDialog = (props) => {
  const [date, setDate] = useState();
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const flespiId = props.flespiId.toString();

  const handleSetBirthDate = (date) => {
    setDate(date);
  };

  const handleExportExcel = async () => {
    const data = {
      date,
      status,
      flespiId,
    };

    if (!date) {
      return toast.error("Selecione uma data");
    }

    try {
      setLoading((previous) => !previous);
      const fileBlob = await exportExcel(data);

      fileDownload(
        fileBlob,
        `${props.deviceName}_${format(parseISO(date), "dd_MM_yyyy")}.xlsx`
      );

      props.close();
    } catch (error) {
      toast.success("Falha ao tentar gerar o excel");
    } finally {
      setLoading((previous) => !previous);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p>Selecione uma data para a exportação.</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => handleSetBirthDate(e.target.value)}
                variant="outlined"
                id="scheduled_date"
                type="date"
                style={{ width: "100%", height: 40 }}
                size="small"
              />
            </Grid>
            <div>
              <span style={{ marginRight: 16 }}>
                Somente posições com ignição ligada
              </span>
              <Switch
                color="primary"
                checked={status}
                onChange={(event) => setStatus(event.target.checked)}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => handleExportExcel()}
            disabled={loading}
          >
            Exportar
          </Button>
          <Button color="primary" autoFocus onClick={() => props.close()}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExcelDialog;
