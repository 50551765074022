import React, { useMemo, useCallback } from "react";
import {
  Slide,
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";

import { formatDateReadable } from "../../../../domain/format/date";
import Logger from "../../../../commons/utils/logger";

import useStyles from "./styles";

const moduleLogger = Logger.createInitialLogger("SecurityAlertDialog");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const SecurityAlertDialog = ({ isOpen, setIsOpen, currentAlert }) => {
  const classes = useStyles();

  const formatDateValue = useCallback((dateString, errorMessage) => {
    if ([undefined, null].includes(dateString)) return "-";

    try {
      return formatDateReadable(dateString);
    } catch (error) {
      moduleLogger.error(errorMessage);
      return "-";
    }
  }, []);

  const dateStarted = useMemo(() => {
    return formatDateValue(
      currentAlert.startedAt,
      `Invalid date string "startedAt" of value ${currentAlert.startedAt}.`
    );
  }, []);

  const dateEnded = useMemo(() => {
    return formatDateValue(
      currentAlert.endedAt,
      `Invalid date string "endedAt" of value ${currentAlert.endedAt}.`
    );
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const renderSource = () => {
    return (
      <DialogContentText className={classes.dialogContent}>
        <div className={classes.description}>
          <strong>Fonte(s):</strong>
          {currentAlert.links.map((alert, index) => (
            <a key={index} href={alert.url} target="_blank">
              Link {index + 1}
            </a>
          ))}
        </div>
      </DialogContentText>
    );
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{ paper: classes.dialog }}
    >
      <header className={classes.header}>
        <DialogTitle className={classes.title}>
          <strong>Alerta: </strong> {currentAlert.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </header>
      <DialogContent>
        <div className={classes.rowStyle}>
          <DialogContentText className={classes.dialogContent}>
            <strong>Credibilidade: </strong>
            <Box component="fieldset" borderColor="transparent">
              <Rating
                name="read-only"
                value={currentAlert.credibility}
                readOnly
              />
            </Box>
          </DialogContentText>
          <Avatar className={classes.large} src={currentAlert.type.icon} />
        </div>
        <DialogContentText className={classes.dialogContent}>
          <div className={classes.datesRow}>
            <div className={classes.datesRowItem}>
              <strong>Início:</strong>
              <span>{dateStarted}</span>
            </div>
            <div className={classes.datesRowItem}>
              <strong>Fim:</strong>
              <span>{dateEnded}</span>
            </div>
          </div>
        </DialogContentText>
        <DialogContentText className={classes.dialogContent}>
          <div className={classes.description}>
            <strong>Evento:</strong>
            <span>{currentAlert.description}</span>
          </div>
        </DialogContentText>
        {currentAlert?.links?.length > 0 ? renderSource() : null}
      </DialogContent>
    </Dialog>
  );
};
