import {
  Button,
  IconButton,
  Paper,
  styled as styledMUI,
  TableCell,
  tableCellClasses,
  TableHead,
  TextField,
} from "@mui/material";

export const CustomTextField = styledMUI(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "5px",
    },
  },
  input: {
    fontSize: "15px",
    fontWeight: 400,
  },
  backgroundColor: "#F8F8F8",
  margin: "5px 0px 5px 0px",
  minWidth: "200px",
  flex: 1,
}));

export const PaperStyled = styledMUI(Paper)(() => ({
  position: "absolute",
  zIndex: 1,
  backgroundColor: "#fff",
  padding: "5px",
  borderRadius: "5px",
  marginTop: "10px",
  marginLeft: "20px",
}));

export const ButtonTableHead = styledMUI(Button)(() => ({
  color: "#2A93A7",
  backgroundColor: "white",
  width: "85px",
  height: "24px",
}));

export const IconButtonTable = styledMUI(IconButton)(() => ({
  backgroundColor: "white",
  borderRadius: "10px",
  width: "70px",
  boxShadow: " 0px 0px 10px 0px #00000026",
  height: "36px",
}));

export const StyledTableHead = styledMUI(TableHead)(() => ({
  "& .MuiTableCell-root": {
    position: "relative",
    top: 0,
  },
  "& .MuiTableCell-root::after": {
    content: '""',
    height: "14px",
    width: "2px",
    backgroundColor: "#E0E0E0",
    position: "absolute",
    right: 0,
  },
  "& .MuiTableRow-root .MuiTableCell-root:last-child::after": {
    display: "none",
  },
}));

export const StyledTableCell = styledMUI(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f1f1f1",
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
