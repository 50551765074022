import { areasApi } from "..";
import { appId } from "../../../commons/constants/app";
import { fromAreaApi } from "../mappers/area.mapper";

export const getRiskAreas = async ({ typeIds, nearby, intersects }) => {
  const response = await areasApi.post("/areas/search", {
    application_id: appId,
    types: ["risk_zones"],
    filters: {
      riskZones: {
        typeId: {
          in: typeIds,
        },
      },
    },
    nearby,
    intersects,
  });

  return response.data.map(fromAreaApi);
};
