import React, { useState, useCallback } from "react";
import { Div, Container } from "./styles";
import { Typography, Tabs, Tab } from "@material-ui/core";
import Sidebar from "../../components/Sidebar/sidebar";
import DriverTable from "./Driver/driverTable";
import PassengerTable from "./Passenger/passengerTable";

const tabsClasses = {
  root: "tabsContainer",
  indicator: "tabsBorderBottomColor",
};

export default function Home() {
  const [reportOnScreen, setReportOnScreen] = useState("passenger");

  const onChangeTabs = useCallback(
    (_, newValue) => {
      setReportOnScreen(newValue);
    },
    [setReportOnScreen]
  );

  return (
    <>
      <Div>
        <Sidebar />
        <Container>
          <div className="report-form">
            <Typography gutterBottom variant="h4" className="pageTitle">
              Gestão de Pessoas
            </Typography>
          </div>

          <div className="padding-tabs">
            <Tabs
              value={reportOnScreen}
              onChange={onChangeTabs}
              classes={tabsClasses}
            >
              <Tab label="Passageiros" value="passenger" className="tabTitle" />

              <Tab label="Motoristas" value="driver" className="tabTitle" />
            </Tabs>
          </div>
          <div className="reportTableContainer">
            {reportOnScreen === "passenger" && <PassengerTable />}
            {reportOnScreen === "driver" && <DriverTable />}
          </div>
        </Container>
      </Div>
    </>
  );
}
