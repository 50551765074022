import { makeStyles } from '@material-ui/core/styles'

const styles = {}

styles.dialogContent = {
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
}

styles.dialogInfo = {
  display: 'flex',
  flexDirection: 'column',
  gap: 4,

  paddingTop: 10,
  paddingBottom: 10,
}

styles.title = {
  fontWeight: '600',
}

styles.text = {
  
}

const useStyles = makeStyles(styles)

export default useStyles