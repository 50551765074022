import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  Checkbox,
  DialogActions,
  Dialog,
  Button,
} from "@material-ui/core";

import {
  handlePostSetting,
  handleGetSettings,
} from "../../../services/customerAreaManagement/setting";

const rows = [
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Descrição",
  },
  { id: "use", numeric: true, disablePadding: false, label: "Usar" },
  {
    id: "Required",
    numeric: true,
    disablePadding: false,
    label: "Obrigatório",
  },
];

class EnhancedTableHead extends React.Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            return (
              <TableCell key={row.id} numeric={row.numeric}>
                {row.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
};

const ManageFields = ({ open, close }) => {
  const customerId = useSelector((store) => store.auth.customer.customer);
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState([
    {
      description: "Unidade de Negócio",
      field: 1,
      required: false,
      checked: false,
    },
    {
      description: "Categoria",
      field: 2,
      required: false,
      checked: false,
    },
    {
      description: "Produto",
      field: 3,
      required: false,
      checked: false,
    },
    {
      description: "Atividade",
      field: 4,
      required: false,
      checked: false,
    },
  ]);

  useEffect(() => {
    const getDataTable = async () => {
      const response = await handleGetSettings(customerId);

      if (!response?.length || response.length === 0) {
        return;
      }

      response.forEach((res) => {
        const newData = [...data];

        const dataIndex = newData.findIndex(
          (element) => element.field == res.field
        );

        newData[dataIndex]["checked"] = true;
        newData[dataIndex]["required"] = res.required;

        return setData(newData);
      });
    };

    getDataTable();
  }, []);

  const handleSave = async () => {
    const newMapData = data
      .filter(({ checked }) => checked)
      .map(({ field, required }) => ({ field, required }));

    setSaving(true);
    await handlePostSetting(customerId, newMapData);
    setSaving(false);
    close();
  };

  const handleChecked = (id, value, fieldName) => {
    const newData = [...data];

    const dataIndex = newData.findIndex((element) => element.field == id);

    newData[dataIndex][fieldName] = value;

    if (fieldName == "required" && value == true) {
      newData[dataIndex]["checked"] = true;
    }

    if (fieldName == "checked" && value == false) {
      newData[dataIndex]["required"] = false;
    }

    setData(newData);
  };

  const renderRow = (n) => {
    return (
      <TableRow key={n.field}>
        <TableCell numeric>{n.description}</TableCell>
        <TableCell>
          <Checkbox
            checked={n.checked}
            onClick={() => handleChecked(n.field, !n.checked, "checked")}
            color="primary"
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={n.required}
            onClick={() => handleChecked(n.field, !n.required, "required")}
            color="primary"
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody className={classes.tableBody} padding="15px">
              {data.map(renderRow)}
            </TableBody>
          </Table>
        </div>
      </Paper>
      <DialogActions>
        <div className={classes.divButton}>
          <Button
            onClick={handleSave}
            disabled={saving}
            className={classes.styleButton}
            color="primary"
          >
            Salvar
          </Button>
          <Button
            onClick={close}
            disabled={saving}
            className={classes.styleButton}
            color="primary"
          >
            Cancelar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ManageFields;
