import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { keyBy } from "lodash";

import { PublicEntities } from "../PublicEntities";
import { POIs } from "../POIs";
import { getPublicEntityTypes } from "../../../services/areasApi/public-entity/get-public-entity-types";
import { getRistAreaTypes } from "../../../services/areasApi/risk-areas/get-rist-area-types";
import { getPOITypes } from "../../../services/areasApi/poi/get-poi-types";
import { RiskAreas } from "../RiskAreas";
import { point } from "@turf/helpers";
import { distance } from "@turf/turf";
import { setDeviceLocation } from "../../../store/ducks/advancedMonitoring";
import {
  searchRadius,
  allowedPoiTypeIds,
  allowedPublicEntityTypeIds,
  allowedRiskZoneTypeIds,
  maxDistanceToSearch,
  allowedPermissions,
} from "../../../commons/constants/advanced-monitoring";
import { getAreaInformationTypes } from "../../../services/areasApi/area-information/get-area-information-types";
import {
  POIToDynamicDrawer,
  publicEntityToDynamicDrawer,
  riskAreaToDynamicDrawer,
} from "../../../commons/utils/advanced-monitoring";
import { toggleDynamicDrawer } from "../../../store/ducks/monitoringMap";
import { toast } from "material-react-toastify";

export const AdvancedMonitoring = ({ map }) => {
  const dispatch = useDispatch();

  const deviceId = useSelector((store) => store.advancedMonitoring.deviceId);
  const deviceLocation = useSelector(
    (store) => store.advancedMonitoring.deviceLocation
  );
  const messagesFlespi = useSelector(
    (store) => store.monitoringMap.messagesFlespi
  );
  const permissions =
    useSelector((store) => store.auth.permissions?.permissions.data) ?? [];

  const hasAllPermissions = useMemo(
    () =>
      allowedPermissions.every((permission) =>
        permissions.includes(permission)
      ),
    [permissions]
  );

  const deviceFlespiMessages = useMemo(() => {
    if (!deviceId) {
      return [];
    }

    return messagesFlespi[deviceId] ?? [];
  }, [messagesFlespi, deviceId]);

  const userGroups = useSelector((store) => store.auth.user?.groups_users);
  const groupIds = useMemo(() => {
    return userGroups?.map(({ id }) => id) ?? [];
  }, [userGroups]);

  const [types, setTypes] = useState({
    POITypes: [],
    publicEntityTypes: [],
    riskZoneTypes: [],
    areaInformationTypesIndexed: {},
  });

  useEffect(() => {
    (async () => {
      if (groupIds.length === 0 || !hasAllPermissions) {
        setTypes({
          POITypes: [],
          publicEntityTypes: [],
          riskZoneTypes: [],
          areaInformationTypesIndexed: {},
        });

        return;
      }

      try {
        let [riskZoneTypes, publicEntityTypes, POITypes, areaInformationTypes] =
          await Promise.all([
            getRistAreaTypes({ groupIds }),
            getPublicEntityTypes({ groupIds }),
            getPOITypes({ groupIds }),
            getAreaInformationTypes(),
          ]);

        riskZoneTypes = riskZoneTypes.filter((type) =>
          allowedRiskZoneTypeIds.includes(type.id)
        );

        publicEntityTypes = publicEntityTypes.filter((type) =>
          allowedPublicEntityTypeIds.includes(type.id)
        );

        POITypes = POITypes.filter((type) =>
          allowedPoiTypeIds.includes(type.id)
        );

        const areaInformationTypesIndexed = keyBy(areaInformationTypes, "id");

        setTypes({
          POITypes,
          publicEntityTypes,
          riskZoneTypes,
          areaInformationTypesIndexed,
        });
      } catch {
        toast.error("Falha ao tentar carregar os tipos de areas.");
      }
    })();
  }, [groupIds, permissions, hasAllPermissions]);

  useEffect(() => {
    if (
      deviceLocation &&
      deviceFlespiMessages.length > 0 &&
      map &&
      hasAllPermissions
    ) {
      const { latitude, longitude } =
        deviceFlespiMessages[deviceFlespiMessages.length - 1];
      const currenteDevicePoint = point([longitude, latitude]);

      const advancedMonitoringPoint = point([
        deviceLocation.lng,
        deviceLocation.lat,
      ]);

      const distanceBetweenPoints = distance(
        currenteDevicePoint,
        advancedMonitoringPoint,
        {
          units: "meters",
        }
      );

      if (distanceBetweenPoints > maxDistanceToSearch) {
        dispatch(
          setDeviceLocation({
            lat: latitude,
            lng: longitude,
          })
        );
      }

      map.panTo({
        lat: latitude,
        lng: longitude,
      });
      map.setZoom(15);
    }
  }, [deviceFlespiMessages, deviceLocation, map, dispatch, hasAllPermissions]);

  const handleRiskZoneMarkerClick = useCallback(
    (riskArea, riskZoneType) => {
      const dynamicDrawer = riskAreaToDynamicDrawer(
        riskArea,
        riskZoneType,
        types.areaInformationTypesIndexed
      );

      dispatch(toggleDynamicDrawer(dynamicDrawer));
    },
    [types.areaInformationTypesIndexed, dispatch]
  );

  const handlePublicEntityMarkerClick = useCallback(
    (publicEntity) => {
      const dynamicDrawer = publicEntityToDynamicDrawer(
        publicEntity,
        types.areaInformationTypesIndexed
      );

      dispatch(toggleDynamicDrawer(dynamicDrawer));
    },
    [types.areaInformationTypesIndexed, dispatch]
  );

  const handlePOIMarkerClick = useCallback(
    (poi) => {
      const dynamicDrawer = POIToDynamicDrawer(
        poi,
        types.areaInformationTypesIndexed
      );

      dispatch(toggleDynamicDrawer(dynamicDrawer));
    },
    [types.areaInformationTypesIndexed, dispatch]
  );

  if (!deviceId || !deviceLocation || !map) {
    return <></>;
  }

  return (
    <>
      <RiskAreas
        riskZoneTypes={types.riskZoneTypes}
        coordinate={deviceLocation}
        radius={searchRadius}
        onMarkerClick={handleRiskZoneMarkerClick}
      />

      <PublicEntities
        coordinate={deviceLocation}
        radius={searchRadius}
        publicEntityTypes={types.publicEntityTypes}
        onMarkerClick={handlePublicEntityMarkerClick}
      />

      <POIs
        coordinate={deviceLocation}
        radius={searchRadius}
        POITypes={types.POITypes}
        onMarkerClick={handlePOIMarkerClick}
      />
    </>
  );
};
