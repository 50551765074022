import { areasApi } from "..";
import { appId } from "../../../commons/constants/app";
import { fromPublicEntityApi } from "../mappers/public-entity.mapper";

export const getPublicEntities = async ({ typeIds, nearby, intersects }) => {
  const response = await areasApi.post("/public-entity-points", {
    application_id: appId,
    publicEntityId: typeIds,
    nearby,
    intersects,
  });

  return response.data.map(fromPublicEntityApi);
};
