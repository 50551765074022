export const fromPublicEntityTypeApi = (data) => {
  return {
    id: data.id,
    entity: data.entity,
    entityEn: data.entity_en,
    icon: data.icon,
    color: data.color,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    deletedAt: data.deleted_at,
  };
};
