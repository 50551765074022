import React, { useState, useEffect } from "react";
import { InfoWindow } from "@react-google-maps/api";
import { Typography, Button } from "@material-ui/core";
import { deleteVirtualFence, toggleInfoWindowVisibility } from "../../../../../../store/ducks/deviceManagement";
import { useDispatch } from "react-redux";

import { polygonPoint } from "../../../../../../commons/polygonHelper";

const Googleinfowindow = ({ polygonId, position, onRemovePolygon }) => {
  const dispatch = useDispatch();

  const handleCloseBtnClick = () => {
    dispatch(toggleInfoWindowVisibility(polygonId));
  }

  return (
    <InfoWindow position={position} onCloseClick={handleCloseBtnClick}>
      <>
        <Typography variant="h6">
          Deseja excluir a sua cerca virtual?
        </Typography>
        <Button
          onClick={() => onRemovePolygon(polygonId)}
          variant="contained"
          color="primary"
          style={{
            margin: "16px 120px",
          }}
        >
          Sim
        </Button>
      </>
    </InfoWindow>
  );
};

export default Googleinfowindow;
