import React from "react";
import { useDispatch } from "react-redux";

import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

import "date-fns";
import { searchByAliasAction } from "../../store/ducks/monitoring";

function FilterMonitoring() {
  const dispatch = useDispatch();

  const addIdentifier = (event) => {
    const value = event.target.value;
    dispatch(searchByAliasAction(value));
  };

  return (
    <>
      <Grid item>
        <TextField
          className="search-identifier"
          style={{ width: 630 }}
          onChange={addIdentifier}
          label="Busca por apelido ou identificador"
          type="search"
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </>
  );
}

export default FilterMonitoring;
