import {
  Autocomplete,
  Chip,
  styled as styledMUI,
  TextField,
} from "@mui/material";
import style from "styled-components";

export const CustomTextField = styledMUI(TextField)((text) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "5px",
      border: "none",
    },
  },
  input: {
    fontSize: "15px",
    fontWeight: 400,
  },
  backgroundColor: "#F8F8F8",
  margin: "5px 0px 5px 0px",
  minWidth: "200px",
  flex: 1,
}));

export const CustomChip = styledMUI(Chip)(() => ({
  margin: "2px",
  borderRadius: "2px",
  border: "none",
  boxShadow: " 1px 1px 5px grey",
  height: "25px",
}));

export const CustomComplete = styledMUI(Autocomplete)(() => ({
  overflow: "hidden",
  height: "60px",
  backgroundColor: "#F8F8F8",
}));

export default style;
