import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import mqtt from "mqtt";

import AlertsSidebar from "../../components/AlertsSidebar";
import VoiceSidebar from "../../components/VoiceSidebar";
import NotificationsSidebar from "../../components/NotificationsSidebar";
import TableMonitoring from "../../components/TableMonitoringComponents/TableMonitoringMap";
import Map from "../../components/Map/map";
import Sidebar from "../../components/Sidebar/sidebar";
import { Container, Div } from "./styles";
import { getCustomerPointsOfInterest } from "../../services/areasService";
import { getDevices, getFlespiMessages } from "../../services/flespiApiService";
import { addCustomerPointsOfInterest } from "../../store/ducks/areas";
import CustomerPOIS from "./CustomerInterestPoints";
import { validate } from "../../commons/utils/positionValidation";
import { VoiceModal } from "../../components/VoiceModal";
import io from "socket.io-client";
import {
  setCalls,
  setNewNotification,
  setSendVoice,
  setVoices,
  changeShowModalVoice,
  setCall,
} from "../../store/ducks/voice";
import { addLastPositons } from "../../store/ducks/monitoringMap";
import ModalVehicle from "../../components/ModalVehicle";
import ModalIncidentsActive from "../../components/ModalIncidentsActive";
import { DynamicSidebar } from "../../components/DynamicSidebar";

const socket = io(process.env.REACT_APP_VOICE_API);

export default function Home() {
  const dispatch = useDispatch();

  const [isConnected, setIsConnected] = useState(socket.connected);

  const user = useSelector((store) => store.auth.user);
  const token = useSelector((store) => store.auth.token.accessToken);

  const renderAlertsSideBar = useSelector(
    (store) => store.monitoringMap.render
  );

  const call = useSelector((store) => store.voice.call);
  const calls = useSelector((store) => store.voice.calls);
  const showModalVoice = useSelector((store) => store.voice.showModalVoice);

  const devices = useSelector((store) => store.monitoringMap.devices);
  const customerId = useSelector((store) => store.auth.customer.customer);
  const customerPointsOfInterest = useSelector(
    (store) => store.areas.customerPointsOfInterest
  );
  const POIs = useSelector((store) => store.monitoringMap.POIs);
  const messagesFlespi = useSelector(
    (store) => store.monitoringMap.messagesFlespi
  );

  const [selectedDevicesFlespiIds, setSelectedDevicesFlespiIds] = useState([]);

  const now = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const pastTimestamp = Math.floor(new Date(now) / 1000) - 86400;

  const addRenderAction = (render) => {
    dispatch({ type: "ADD_RENDER", render });
  };

  const addPointsDevicesActives = (pointsDevicesActives) => {
    dispatch({ type: "ADD_POINTS_DEVICES_ACTIVES", pointsDevicesActives });
  };

  const addIdsDevicesActives = (idsDevicesActives) => {
    dispatch({ type: "ADD_IDS_DEVICES_ACTIVES", idsDevicesActives });
  };

  const addMessagesFlespi = (newMessagesFlespi) => {
    dispatch({ type: "ADD_MESSAGES_FLESPI", newMessages: newMessagesFlespi });
  };

  const customerGoogleMapsKey = useSelector(
    (store) => store.auth?.user?.customer?.google_maps_key
  );

  useEffect(() => {
    return () => {
      dispatch(addLastPositons([]));
    };
  }, []);

  useEffect(() => {
    const scriptIdKey = document.getElementById("customerKey");

    if (scriptIdKey) {
      scriptIdKey.src = `https://maps.googleapis.com/maps/api/js?key=${
        customerGoogleMapsKey || process.env.REACT_APP_MAPS_KEY
      }&libraries=places,drawing,geometry&v=weekly`;
    }
  }, []);

  useEffect(() => {
    if (devices.length == 0) return;
    const fetchData = async () => {
      const devicesWithTranferStarted = devices
        .filter((device) => {
          return (
            device.transfer && device.transfer.id && device.transfer.start_date
          );
        })
        .map((device) => {
          return {
            flespiId: device.device.flespi_id,
            deviceId: device.device.id,
            startTransfer: Math.floor(
              new Date(device.transfer.start_date) / 1000
            ),
          };
        });

      const flespiIds = devicesWithTranferStarted.map((device) => {
        return device.flespiId;
      });

      setSelectedDevicesFlespiIds(
        devices
          .filter((device) => device.device.flespi_id)
          .map((device) => device.device.flespi_id)
      );

      if (flespiIds.length === 0) {
        return;
      }

      const flespiDevices = await getDevices(flespiIds);

      const flespiIdsValidated = flespiDevices.map((flespiDevice) => {
        return flespiDevice.id;
      });

      const devicesWithTranferStartedValidated =
        devicesWithTranferStarted.filter((device) => {
          return flespiIdsValidated.includes(device.flespiId);
        });

      if (devicesWithTranferStartedValidated.length === 0) {
        return;
      }

      const flespiMessages = await getFlespiMessages(flespiIdsValidated, {
        from: pastTimestamp,
      });

      let lists = flespiMessages.filter(
        (message) =>
          message.last_status.latitude && message.last_status.longitude
      );

      if (lists.length > 0) {
        lists = lists.reduce((list, valor) => {
          if (!list[valor.ident]) {
            list[valor.ident] = [];
          }

          list[valor.ident].push(valor);
          return list;
        }, {});

        for (const list in lists) {
          lists[list] = lists[list]
            .sort((a, b) => {
              return new Date(a.taken_at) - new Date(b.taken_at);
            })
            .reduce((acc, valor) => {
              if (acc.length === 0) {
                acc.push(valor);

                return acc;
              }

              const lastPosition = acc[acc.length - 1];
              const lastStatusPosition = lastPosition.last_status;
              const inicialStatusPosition = valor.last_status;

              if (validate(lastStatusPosition, inicialStatusPosition)) {
                acc.push(valor);
              }

              return acc;
            }, []);
        }

        addPointsDevicesActives(
          Object.values(lists).reduce((acc, valor) => {
            acc.push(...valor);
            return acc;
          })
        );
      } else {
        addPointsDevicesActives([]);
      }

      addIdsDevicesActives(devicesWithTranferStartedValidated);
      addRenderAction(false);
    };

    fetchData();
  }, [devices, renderAlertsSideBar]);

  useEffect(() => {
    const searchCustomerPointsOfInterest = async () => {
      const customerPointsOfInterest = await getCustomerPointsOfInterest(
        customerId
      );
      dispatch(addCustomerPointsOfInterest(customerPointsOfInterest));
    };
    searchCustomerPointsOfInterest();
  }, []);

  useEffect(() => {
    const client = mqtt.connect("wss://mqtt.flespi.io:443", {
      clientId: `utransfer-${now}`,
      username: `FlespiToken ${process.env.REACT_APP_API_FLESPI_TOKEN}`,
      protocolVersion: 5,
    });

    client.on("connect", () => {
      console.log("ok connect!");

      client.subscribe(
        `flespi/state/gw/devices/${selectedDevicesFlespiIds}/telemetry/last_status`,
        { qos: 1 },
        (err) => {
          if (err) console.log("Erro ao se inscrever no Tópico");
        }
      );
    });

    client.on("message", (topic, msg) => {
      const message = JSON.parse(msg.toString());

      if (!message["latitude"] || !message["longitude"]) {
        return;
      }

      let lastMessageFlespi = [];

      const deviceMessages = messagesFlespi[message.device_id];
      if (deviceMessages) {
        lastMessageFlespi = deviceMessages[deviceMessages.length - 1];
      }

      if (lastMessageFlespi.taken_at === message.taken_at) {
        return;
      }

      if (validate(lastMessageFlespi, message)) {
        addMessagesFlespi(message);
      }
    });

    client.on("error", (err) => {
      console.log("Error: ", err);
      client.end(true);
    });

    return () => {
      client.end(true);
    };
  }, [selectedDevicesFlespiIds]);

  const getPreviousVoices = ({ id, customerId }) => {
    socket.emit("chatConnect", { id, customerId });

    socket.on("previousVoices", (data) => {
      dispatch(setVoices(data));
    });
  };

  const getPreviousCalls = (params) => {
    if (!params) {
      socket.emit("userConnect", { id: user.id });
    }
    socket.on(`previousCalls${user.id}`, (data) => {
      dispatch(setCalls(data));
    });
  };

  const recivedVoicesCall = (data) => {
    if (call.call_id == data.callId) {
      dispatch(setSendVoice(data));
    }

    dispatch(setNewNotification({ callId: data.callId, voiceId: data.id }));
  };

  const sendVoices = (data) => {
    socket.emit("sendMessage", data);
  };

  const startCall = async ({ device, transfer }) => {
    const check = calls.find((call) => {
      if (transfer && transfer.start_date) {
        return call;
      }

      if (device.external_id == call.call_user_id && !transfer) {
        return call;
      }
    });

    if (check) {
      dispatch(setCall(check));
      return dispatch(changeShowModalVoice());
    }

    const data = {
      customerId: device.customer_id,
      transferId: transfer ? transfer.id : undefined,
      userId: transfer ? undefined : device.external_id,
      devicesIds: transfer ?? [device.external_id],
      token,
    };

    socket.emit("startCall", data, user.id);
  };

  const finishCall = async (transferId) => {
    socket.emit("finishCall", transferId);
  };

  useEffect(() => {
    socket.on("connect", (data) => {
      setIsConnected(true);
    });

    getPreviousCalls();

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on(`recivedVoicesCall${user.id}`, (data) => {
      recivedVoicesCall(data);
    });

    socket.on(`recivedCall${user.id}`, ({ call: data, startBy }) => {
      dispatch(setCalls([...calls, data]));
      dispatch(setCall(data));
      if (startBy == user.id) {
        return dispatch(changeShowModalVoice());
      }
    });
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [call]);

  return (
    <Div>
      {showModalVoice && (
        <VoiceModal
          getPreviousVoices={getPreviousVoices}
          sendVoices={sendVoices}
        />
      )}
      <Sidebar />
      <Container>
        <Map>
          <NotificationsSidebar />
          <AlertsSidebar />
          <VoiceSidebar />
          <ModalVehicle />
          <ModalIncidentsActive />
          <DynamicSidebar />

          {POIs == "on" && (
            <CustomerPOIS customerPointsOfInterest={customerPointsOfInterest} />
          )}
        </Map>
        <TableMonitoring startCall={startCall} finishCall={finishCall} />
      </Container>
    </Div>
  );
}
