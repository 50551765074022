import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Geocode from "react-geocode";
import { toast } from "material-react-toastify";
import {
  addWaypointAction,
  startLoadingDirectionsAction,
} from "../../../../store/ducks/scripting";
import { useGoogleMapsKey } from "../../../../hooks/useGoogleMapsKey";

export const useMapClick = () => {
  const googleMapsKey = useGoogleMapsKey();
  const waypoints = useSelector((state) => state.scripting.waypoints);
  const mapClickAddStopOrDetour = useSelector(
    (state) => state.scripting.mapClickAddStopOrDetour
  );
  const sidebarContentOpen = useSelector(
    (state) => state.scripting.sidebarContentOpen
  );
  const [currentInfoWindow, setCurrentInfoWindow] = useState({});
  const dispatch = useDispatch();

  Geocode.setApiKey(googleMapsKey);
  Geocode.setLanguage("pt-BR");
  Geocode.setRegion("br");

  const handleMapClick = useCallback(
    async (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      let isStop;
      let errorMessage;

      if (sidebarContentOpen === "waypoints") {
        if (mapClickAddStopOrDetour === "stop") isStop = true;
        else if (mapClickAddStopOrDetour === "detour") isStop = false;
        else errorMessage = "Selecione um modo: Desvio ou Parada.";
      } else {
        return;
      }

      if (errorMessage) return toast.error(errorMessage);

      const response = await Geocode.fromLatLng(
        parseFloat(lat),
        parseFloat(lng)
      );
      const { formatted_address, place_id } = response.results[0];
      const waypointAlreadyExist = waypoints.find(
        (waypoint) => waypoint.id === place_id
      );
      if (waypointAlreadyExist)
        return toast.error("Essa endereço já foi adicionado.");

      dispatch(
        addWaypointAction({
          id: place_id,
          location: formatted_address,
          stopover: isStop,
          title: "",
          duration: "",
          position: {
            latitude: parseFloat(lat),
            longitude: parseFloat(lng),
          },
        })
      );
      dispatch(startLoadingDirectionsAction());
    },
    [waypoints, mapClickAddStopOrDetour, sidebarContentOpen, dispatch]
  );

  const handleMapRightClick = useCallback(
    async (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      const response = await Geocode.fromLatLng(lat, lng);
      const { formatted_address } = response.results[0];
      setCurrentInfoWindow({ body: formatted_address, lat: lat, lng: lng });
    },
    [setCurrentInfoWindow]
  );

  return {
    handleMapClick,
    handleMapRightClick,
    currentInfoWindow,
    setCurrentInfoWindow,
  };
};
