import React from "react";
import { Redirect } from "react-router-dom";
import { reduxForm } from "redux-form";
import {
  Container,
  FormContainer,
  Footer,
  Vectors,
  LogoPUmais,
} from "./styles";
import CustomSnackbar from "../../components/CustomSnackbar";
import * as authService from "../../services/auth";
import vectorLeft from "../../assets/vector-left.svg";
import logoPUmais from "../../assets/logoPUmais.svg";
import { useState } from "react";
import LoginForm from "./loginForm";
import ForgotPasswordForm from "./forgotForm";

const Login = ({ handleSubmit }) => {
  const year = new Date().getFullYear();
  const [forgotPassword, setForgotPassword] = useState(false);

  const openForgotPasswordForm = () => {
    setForgotPassword((state) => !state);
  };

  if (authService.isAuthenticated()) {
    return <Redirect to={authService.getLoggedUserMainRoute()} />;
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />

      <Container>
        <FormContainer>
          {forgotPassword ? (
            <ForgotPasswordForm
              openForgotPasswordForm={openForgotPasswordForm}
            />
          ) : (
            <LoginForm
              handleSubmit={handleSubmit}
              openForgotPasswordForm={openForgotPasswordForm}
            />
          )}
        </FormContainer>
        <LogoPUmais src={logoPUmais} />
      </Container>
      <Vectors>
        <img src={vectorLeft} />
      </Vectors>
      <Footer>
        <p>
          Desenvolvido por UNICADTEC <strong>{year}©</strong>. Todos os direitos
          reservados.
        </p>
      </Footer>
      <CustomSnackbar />
    </>
  );
};

export default reduxForm({
  form: "login",
})(Login);
