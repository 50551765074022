import React from "react";
import { Redirect, Route } from "react-router-dom";
import { can, isAuthenticated } from "../../services/auth";

export const PrivateRoute = ({
  can: permission,
  component: Component,
  ...rest
}) => {
  if (!isAuthenticated || (permission && !can(permission))) {
    return <Redirect {...rest} to={{ pathname: "/" }} />;
  }

  return <Route {...rest} component={Component} />;
};
