export const prepareSettingValues = (customDays, customDates, frequency) => {

    let { custom_days } = customDays
    let { custom_dates } = customDates

    switch (frequency) {
      case 'daily':
      case 'weekday':
        custom_days = null
        custom_dates = null
        break;
      case 'custom_days':
        custom_days = customDays
        custom_dates = null
        break;
      case 'custom_dates':
        custom_dates = customDates
        custom_days = null
        break;
      default:
        break;
    }
      return {
        frequency,
        custom_days,
        custom_dates,
      };
  }

export const reverseStr = function(str){
  var array = str.replace('[','').replace(']','').split(",").map(Number)
    return array
}
