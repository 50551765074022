import React from "react";
import TextField from "@material-ui/core/TextField";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const LoginField = ({
  input,
  type,
  label,
  handleClickShowPassword,
  showPassword,
}) => (
  <>
    <TextField
      {...input}
      fullWidth
      label={label}
      variant="outlined"
      type={type}
      required
      style={{ marginBottom: 15 }}
      InputProps={
        handleClickShowPassword && {
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }
      }
    />
  </>
);

export default LoginField;
