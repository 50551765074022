import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ButtonRegister from "./Register/ButtonRegisterNew";
import DateTime from "./dateTimeNew";
import { Container, Div } from "./stylesNew";
import { FilterFields } from "./FilterFieldsNew";
import { CustomTextField } from "../TableTransferNew/style";
import { useDispatch } from "react-redux";

function Filter() {
  const dispatch = useDispatch();

  const addEventAct = (eventFetch) => {
    return { type: "ADD_EVENT_FETCH", eventFetch };
  };

  const handleChangeSearchTransfersByEvent = (e) => {
    const value = e.target.value;
    dispatch(addEventAct(value));
  };
  return (
    <>
      <Container>
        <ButtonRegister />
        <Div>
          <DateTime />
          <CustomTextField
            onChange={handleChangeSearchTransfersByEvent}
            label="Pesquisar por Evento"
            size="small"
            variant="outlined"
            type="text"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FilterFields />
        </Div>
      </Container>
    </>
  );
}

export default Filter;
