import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 430;

const bodyInfo = {
  color: "#000000",
  fontSize: "14px",
  fontWeight: "400",
  marginLeft: "0px",
  overflowWrap: "break-word",
  display: "flex",
  hyphens: "manual",
  gap: "5px",
}

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontSize: 18,
  },
  box: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    height: "100%",
  },
  boxHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
    padding: "10px 0px",
    margin: "10px",
  },
  incidentIcon: {
    marginRight: 5,
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: 10,
    height: "60vh",
  },
  drawerIncident: {
    width: 790,
  },
  drawerPaperIncident: {
    width: 790,
    padding: 10,
    height: "60vh",
  },
  eventListContainer: {
    width: 400,
    overflow: "auto",
    paddingLeft: 10,
    paddingRight: 10,
  },
  IncidentName: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "bold",
    width: "300px",
  },
  ReasoningTitle: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "bold",
  },
  ReasoningText: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "400",
    marginLeft: "0px",
    overflowWrap: "break-word",
    display: "flex",
    hyphens: "manual",
    gap: "5px",
  },
  ReasoningTextHidden: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "400",
    marginLeft: "0px",
    overflowWrap: "break-word",
    display: "flex",
    hyphens: "manual",
    gap: "5px",
    cursor: "pointer",
  },
  ReasoningLineClamp: {
    color: "#000000",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
    marginLeft: "0px",
    overflowWrap: "break-word",
    hyphens: "manual",
    gap: "5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  TextCredibility: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "bold",
  },
  DivActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "50px",
    borderRadius: "50px",
    background: "#228b22",
  },
  DeviceOn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "50px",
    borderRadius: "50px",
    background: "#228b22",
    color: "#ffffff",
    fontsize: "15px",
    fontWeight: "bold",
    textAlign: "center",
  },
  DeviceOff: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "50px",
    borderRadius: "50px",
    background: "#ff0000",
    color: "#ffffff",
    fontsize: "15px",
    fontWeight: "bold",
    textAlign: "center",
  },
  Body: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
    padding: "10px 0px",
    margin: "10px",
  },
  BodyCredibility: {
    display: "flex",
    justifyContent: "Center",
    alignItems: "center",
    gap: "3px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
    padding: "10px 0px",
    margin: "10px",
  },
  BodyInfo: bodyInfo,
  CentralizedBodyInformation: {
    ...bodyInfo,
    alignItems: 'center',
  },
  Footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "250px",
    flexWrap: "wrap",
    gap: "10px",
  },
  Flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    width: "300px",
  },
  Link: {
    textDecoration: "none",
    color: "#000000",
  },
  Button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    border: "none",
    width: "150px",
    height: "50px",
    backgroundColor: "#3bc9d9",
    fontSize: "15px",
    color: "black",
    cursor: "pointer",
    fontWeight: "bold",
  },
  ButtonOff: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    border: "none",
    width: "150px",
    height: "50px",
    backgroundColor: "#d9d9d9",
    fontSize: "15px",
    fontWeight: "bold",
    color: "#ffffff",
  },
}));
