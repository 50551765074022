import React from 'react'

import { Button, DialogActions, CircularProgress } from '@material-ui/core'

import useStyles from './styles'
import colors from '../../../commons/colors'

const CustomDialogActions = ({
  handleSubmit = () => { },
  handleClose = () => { },

  confirmBackgroundColor,
  confirmText,
  cancelColor,
  cancelText,
  loading = false,
}) => {
  const classes = useStyles({ confirmBackgroundColor, cancelColor })

  const finalConfirmButtonContent = loading
    ? <CircularProgress size={20} color={colors.fullWhite} />
    : <span>{confirmText}</span>

  return (
    <DialogActions
      classes={{
        root: classes.dialogActions,
      }}
    >
      <Button
        variant='text'
        onClick={handleClose}
        TouchRippleProps={{
          classes: {
            child: classes.ripple,
          },
        }}
        classes={{
          root: classes.cancelButton,
          label: classes.cancelButtonLabel,
        }}
      >
        {cancelText}
      </Button>
      <Button
        variant='contained'
        onClick={handleSubmit}
        TouchRippleProps={{
          classes: {
            child: classes.ripple,
          },
        }}
        classes={{
          root: classes.confirmButton,
          label: classes.buttonLabel,
        }}
      >
        {finalConfirmButtonContent}
      </Button>
    </DialogActions>
  )
}

export default CustomDialogActions