import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TablePagination,
} from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import { getEventsReportDevice } from "../../../services/eventAnalyticsApi/eventService";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import Row from "./rowReport";
import { getDevices } from "../../../infra/http/devicesProvider";
import { toast } from "material-react-toastify";

export default function CollapsibleTable() {
  const [fields, setFields] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [devices, setDevices] = useState();
  const [loading, setLoading] = useState(false);
  const customerId = useSelector((state) => state.auth.customer.customer);
  const dateFrom = useSelector((state) => state.reporting.fromDate);
  const dateTo = useSelector((state) => state.reporting.toDate);
  const requestData = useSelector((state) => state.reporting.handleRequest);
  const firstHandle = useRef(true);
  const [count, setCount] = useState(0);
  const classes = useStyles();

  const requestRows = async () => {
    try {
      setLoading(true);

      const { paginatedArray, totalRows } = await getEventsReportDevice({
        customerId,
        startedAt: dateFrom,
        endedAt: dateTo,
        page: currentPage + 1,
        perPage: rowsPerPage,
      });
      setCount(totalRows);
      setFields(paginatedArray);
    } catch (error) {
      toast.error("Não foi possivel carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  const handleDevices = async () => {
    const devices = await getDevices({ customerId: customerId });
    setDevices(devices);
  };

  useEffect(() => {
    if (firstHandle.current) {
      firstHandle.current = false;
      return;
    }
    requestRows();
    handleDevices();
  }, [requestData, currentPage, rowsPerPage]);

  const headerCells = [
    "Id",
    "Dispositivos",
    "Tratados",
    "Não Tratados",
    "Total",
  ];

  return (
    <>
      {!loading && fields.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                {headerCells.map((cellName) => (
                  <TableCell key={cellName} align="center">
                    {cellName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map(([id, chieldrens], index) => (
                <Row
                  key={Number(index)}
                  row={{ ...chieldrens, id }}
                  fields={fields}
                  devices={devices}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value));
              setCurrentPage(0);
            }}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            labelRowsPerPage={"Linhas por página:"}
          />
        </TableContainer>
      ) : (
        <Box className={classes.noticeContainer}>
          {!loading ? (
            <Typography> Selecione o periodo e aperte buscar </Typography>
          ) : (
            <CircularProgress
              size={50}
              style={{ marginTop: 15, color: "#63a9ef" }}
            />
          )}
        </Box>
      )}
    </>
  );
}
