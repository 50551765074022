import React, { useState } from "react";

import {
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Div } from "./styles";
import { useSelector } from "react-redux";
import { getCustomerPointsOfInterest } from "../../../services/areasService";
import { useEffect } from "react";
import decodePolyline from "decode-google-map-polyline";
import { useGoogleMapsKey } from "../../../hooks/useGoogleMapsKey";

const libraries = ["places", "drawing", "geometry"];

export const MapPoi = () => {
  const googleMapsKey = useGoogleMapsKey();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  const [polygons, setPolygons] = useState([]);

  const customerId = useSelector((state) => state.auth.customer.customer);
  const { poi, centerMap, mapType, zoom } = useSelector((state) => state.poi);

  const mapsOptions = {
    mapTypeId: mapType,
    zoomControl: false,
    fullscreenControlOptions: "BOTTOM_LEFT",
    streetViewControl: false,
    draggableCursor: null,
    disableDefaultUI: true,
    styles: [
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit.station",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#c9c9c9",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
    ],
  };

  const handleFilterBeforeRenderPolygon = (polygons) => {
    return polygons
      .filter((item) => item.customerPointTypeId == poi.customer_poi_type_id)
      .map(({ area: { polygon, point } }, key) =>
        handleRenderPolygonOrMaker(polygon, point, key)
      );
  };

  const handleRenderPolygonOrMaker = (polygon, point, key) => {
    if (polygon) {
      if (polygon.type == "MultiPolygon") {
        const coordinates = [];

        polygon.coordinates.forEach((item) => {
          const response = decodePolyline(item[0]);

          coordinates.push(response);
        });

        return <Polygon key={key} paths={coordinates} />;
      }

      if (polygon.type == "Polygon") {
        return (
          <Polygon key={key} paths={decodePolyline(polygon.coordinates[0])} />
        );
      }
    }

    return (
      <Marker
        key={key}
        position={{
          lng: point.coordinates[0],
          lat: point.coordinates[1],
        }}
      />
    );
  };

  const handleExecuteGetPoligons = async () => {
    try {
      const response = await getCustomerPointsOfInterest(customerId);
      setPolygons(response);
    } catch (error) {}
  };

  useEffect(() => {
    handleExecuteGetPoligons();
  }, []);

  if (!isLoaded) {
    return <></>;
  }

  return (
    <Div>
      <GoogleMap
        id="modal-direction"
        mapContainerStyle={{
          height: "80vh",
          width: "600px",
        }}
        center={centerMap}
        options={mapsOptions}
        zoom={zoom}
      >
        {handleFilterBeforeRenderPolygon(polygons)}
      </GoogleMap>
    </Div>
  );
};
