import React from "react";
import { Marker } from "@react-google-maps/api";

export const Poi = ({ poi, onMarkerClick: handleMarkerClick }) => {
  if (!poi.area.point?.coordinates) {
    return <></>;
  }

  const [lng, lat] = poi.area.point.coordinates;

  return (
    <Marker
      position={{ lat, lng }}
      icon={poi.type.icon}
      onClick={() => {
        handleMarkerClick && handleMarkerClick(poi);
      }}
    />
  );
};
