import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import colors, { presetColors } from '../../../../../commons/dashboardColors';
import Indicator from '../Indicator';

import useStyles from "./styles";

const CustomerUnitSection = ({ id, label, indicators, graphics }) => {
  const classes = useStyles();

  const getPresetColors = (index) => {
    return presetColors[index] || colors;
  }

  const indicatorsComponents = indicators.map(indicator => (
    <Grid key={indicator.id} item xs={12} md={6} lg={4} xl={3}>
      <Indicator number={indicator.value} label={indicator.label} />
    </Grid>
  ));

  const graphicsComponents = graphics.map((graphic, index) => (
    <Grid key={graphic.id} item xs={12} md={4} xl={3} style={{ height: 350 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie data={graphic.data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={85} innerRadius={55} label>
            {
              getPresetColors(index).map(color => (
                <Cell key={`${id}-${color}`} fill={color} />
              ))
            }
          </Pie>
          <Legend verticalAlign="bottom" layout="vertical" height={36} />
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  ));

  return (
    <Grid key={id} container className={classes.containerUnitReport}>
      <Grid item xs className={classes.mainOverview}>
        <div className={classes.mainOverviewHeader}>
          <Typography variant="h5" align="center" className={classes.mainOverviewTitleText}>{label}</Typography>
          <Typography align="center">Estatísticas de desvio de rotas, aproximação e entrada em áreas de riscos</Typography>
        </div>
        <Grid container spacing={2} className={classes.mainOverviewIndicators}>{indicatorsComponents}</Grid>
        <div className={classes.mainOverviewHeader}>
          <Typography variant="h5" align="center" className={classes.mainOverviewTitleText}>Ocorrências</Typography>
          <Typography align="center">Estatísticas de desvio de rotas, aproximação e entrada em áreas de riscos</Typography>
        </div>
        <Grid container spacing={2} className={classes.mainOverviewIndicators}>{graphicsComponents}</Grid>
      </Grid>
    </Grid>
  )
};

export default CustomerUnitSection;
