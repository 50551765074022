import * as exceptions from "../../commons/exceptions";
import codes from "../../commons/constants/codes";
import utransferV2API from "../../services/utransferV2";

export const getAllCarsByCustomerId = async ({ customerId }) => {
  try {
    const { data } = await utransferV2API.get(`/customers/${customerId}/cars/`);

    return data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const getAllCarMakersByCustomerId = async ({ customerId }) => {
  try {
    const { data } = await utransferV2API.get(
      `/customers/${customerId}/cars/makers`
    );

    return data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const getAllCarModelsByCustomerId = async ({ customerId }) => {
  try {
    const { data } = await utransferV2API.get(
      `/customers/${customerId}/cars/models`
    );

    return data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const getAllCarFuelsByCustomerId = async ({ customerId }) => {
  try {
    const { data } = await utransferV2API.get(
      `/customers/${customerId}/cars/fuels`
    );

    return data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const getGasReport = async ({
  customerId,
  to,
  from,
  page = 0,
  perPage = 30,
}) => {
  try {
    return await utransferV2API.get(
      `reports/customers/${customerId}/emissions/co2`,
      {
        params: {
          from: Math.floor(from.getTime() / 1000),
          to: Math.floor(to.getTime() / 1000),
          page,
          perPage,
        },
      }
    );
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};
