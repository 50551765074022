const colors = [
  "#06B0CF",
  "#15172f",
  "#205e1b",
  "#429c3a",
  "#5ec755",
  "#25836d",
  "#33bb9c",
  "#0b4336",
  "#1f86a1",
  "#3ab0cf",
  "#5cd6f6",
  "#373486",
  "#6360bc",
  "#c7267d",
  "#d55d9f",
];

export const colorsTelemetryDashboard = [
  "#191B3A",
  "#975B00",
  "#3BB0D0",
  "#373486",
  "#918FE3",
  "#6360BC",
  "#B200F0",
  "#420258",
  "#D55D9F",
  "#C7267D",
  "#56002F",
  "#1E87A1",
  "#5FF2D0",
  "#32BB9D",
  "#41937F",
  "#0C4336",
  "#5EC855",
  "#429C3B",
  "#205E1B",
  "#4D6000",
  "#E49215",
  "#D17E01",
  "#431C0A",
  "#A5C916",
];

export const presetColors = [
  ["#918ee3", "#373486"],
  ["#5cd6f6", "#1f86a1"],
  ["#d055fb", "#8306af"],
  ["#373486", "#6360bc"],
  ["#3ab0cf", "#5cd6f6"],
  ["#205e1b", "#429c3a"],
  ["#c7267d", "#d55d9f"],
];

export default colors;
