import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Marker, Polyline } from "@react-google-maps/api";
import decodePolyline from "decode-google-map-polyline";
import Icon_Azul2 from "../../../../assets/newScripting/Icon_Azul2.svg";
import { toast } from "material-react-toastify";
import { getReportTransferData } from "../../../../services/utransferApiV2/report-transfer";

export const DirectionsReportTransfer = (props) => {
  const transferId = useSelector((state) => state.transfer.transferId);
  const [transfer, setTransfer] = useState({});
  const [allCoordinates, setAllCoordinates] = useState([]);

  useEffect(() => {
    const getTransfer = async () => {
      try {
        const transferData = await getReportTransferData(transferId);
        setTransfer(transferData);
      } catch (error) {
        toast.error("Erro ao requisitar dados do Transfer", error);
      }
    };

    getTransfer();
  }, []);

  useEffect(() => {
    const decodedCoordinates = decodePolyline(
      transfer?.transferData?.stepsPolyline ?? []
    );

    props.setMapCenter(decodedCoordinates[0]);

    setAllCoordinates(decodedCoordinates);
  }, [transfer]);

  const renderMarker = (coord) => {
    const firstCoord = coord[0];
    const lastCoord = coord[coord.length - 1];

    return (
      <>
        <Marker
          position={{
            lat: firstCoord?.lat,
            lng: firstCoord?.lng,
          }}
          icon={{
            url: Icon_Azul2,
            scaledSize: new window.google.maps.Size(28, 35),
            labelOrigin: { x: 14, y: 16 },
          }}
          label={{
            text: "A",
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: "lighter",
          }}
        />
        <Marker
          position={{
            lat: lastCoord?.lat,
            lng: lastCoord?.lng,
          }}
          icon={{
            url: Icon_Azul2,
            scaledSize: new window.google.maps.Size(28, 35),
            labelOrigin: { x: 14, y: 16 },
          }}
          label={{
            text: "B",
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: "lighter",
          }}
        />
      </>
    );
  };

  return (
    <>
      {renderMarker(allCoordinates)}

      <Polyline
        path={allCoordinates}
        options={{ strokeWeight: 4, strokeColor: "#06B0CF" }}
      />
    </>
  );
};
