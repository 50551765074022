import styled from "styled-components";

export const Div = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  // justify-content: space-evenly;

  .report-form {
    padding: 20px;
  }

  .pageTitle {
    font-weight: 600;
    color: #15172f;
  }

  .tabTitle {
    text-transform: none;
  }

  .devicesTabs {
    padding: 12px;
  }

  .devicesSearchBar {
    width: 100%;
    padding: 18px;
    display: flex;
    justify-content: space-between;
  }

  .tabsBorderBottomColor {
    background-color: #06b0cf;
  }

  .div-reportTextField {
    width: 30%;
  }
`;
