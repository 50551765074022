import React, { useState, useEffect } from "react";
import { parseISO, isBefore } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

import { useStyles } from "../../styles";

import ProgressMessage from "./ProgressMessage";
import ProgressButton from "./ProgressButton";
import LinearProgressUnderBar from "./LinearProgressUnderBar";
import * as exceptions from "../../../../../commons/exceptions";

import { transferStates } from "./constants";
import codes from "../../../../../commons/constants/codes";

export default function ProgressInfoButton({
  deviceTransfer,
  setStateOfSnackBarWarning,
  finishCall,
}) {
  const classes = useStyles();
  const [transferState, setTransferState] = useState(null);

  useEffect(() => {
    const newTransferState = utils.decideTransferStateFromDeviceData(
      deviceTransfer,
      transferState
    );
    setTransferState(newTransferState);
  }, [deviceTransfer]);

  const changeTransferStateHandler = (transferState) => {
    if (!transferStates.hasOwnProperty(transferState))
      throw exceptions.createException({ code: codes.error.VALUE_ERROR });

    setTransferState(transferState);
  };

  return (
    <>
      <div className={classes.progress}>
        <div style={{ flex: 1 }}>
          <ProgressMessage
            transferState={transferState}
            device={deviceTransfer}
          />
        </div>
        <div>
          <ProgressButton
            changeTransferStateHandler={changeTransferStateHandler}
            transferState={transferState}
            device={deviceTransfer}
            setStateOfSnackBarWarning={setStateOfSnackBarWarning}
            finishCall={finishCall}
          />
        </div>
      </div>
      <LinearProgressUnderBar
        device={deviceTransfer}
        transferState={transferState}
      />
    </>
  );
}

const utils = {
  decideTransferStateFromDeviceData: (
    deviceTransfer,
    currentTransferState = null
  ) => {
    if (
      [transferStates.STARTING, transferStates.ENDING].includes(
        currentTransferState
      )
    )
      return currentTransferState;

    if (deviceTransfer.transfer?.end_date) return transferStates.ENDED;

    if (deviceTransfer.transfer?.start_date) return transferStates.ONGOING;

    if (!deviceTransfer.transfer?.scheduled_date)
      return transferStates.NO_TRANSFER;

    if (utils.checkIsDateInThePast(deviceTransfer.transfer?.scheduled_date))
      return transferStates.LATE_TO_START;

    return transferStates.NOT_STARTED;
  },

  checkIsDateInThePast: (dateString) => {
    if (dateString === null) return null;

    const date = parseISO(dateString);
    const now = zonedTimeToUtc(new Date(), "America/Sao_Paulo");

    return isBefore(date, now);
  },
};
