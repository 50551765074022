import { makeStyles } from "@material-ui/core"

import colors from '../../commons/colors'

export default makeStyles({
  reportFormComponentRoot: {
    display: 'flex',
    gap: 12,
    flexWrap: 'wrap',
    justifyContent: 'stretch',
  },

  searchFieldsRow: {
    display: 'flex',
    gap: 12,
    justifyContent: 'stretch',
    flexWrap: 'wrap',
  },

  searchButtonsColumn: {
    display: 'flex',
    gap: 12,
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 4,
    flexShrink: 1,
  },
  
  buttonsContainer: {
    display: 'flex',
    gap: 12,
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1
  },

  submitButton: {
    backgroundColor: colors.lightBlue,
    color: colors.fullWhite,
    fontWeight: 600,
    textTransform: 'none',
    fontSize: '1rem',
    borderRadius: 10,
    flex: 1,
    '&:hover': {
      backgroundColor: '#068fa7'
    },
  },

  filterButton: {
    borderColor: colors.lightBlue,
    color: colors.lightBlue,
    fontWeight: 600,
    textTransform: 'none',
    fontSize: '1rem',
    borderRadius: 10,
    flex: 1,
  },
});
