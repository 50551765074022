import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxInput = ({ input, label }) => (
  <Grid
    item
    xs={6}
  >
    <FormControlLabel
      {...input}
      value={`${input.value.checked}`}
      control={
        <Checkbox
          color="primary"
          checked={input.value.checked}
        />
      }
      label={label}
    />
  </Grid>
);

export default CheckboxInput;
