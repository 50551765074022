import React from 'react';
import { Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

export default function ButtonFilter({ setShowFilterFields, showFilterFields }) {

  const [show, setShow] = React.useState(false);
  const container = React.useRef(null);

  const handleClick = () => {
    setShow(!show);
    setShowFilterFields(!showFilterFields);
  };

  return (
    <div>
      <Button
        style={{width: 113, height: 48, opacity: 1, borderRadius: 10 }}
        startIcon={<FilterListIcon />}
        variant="outlined"
        type="button"
        onClick={handleClick}>
          {show ? '+Filtros' : '+Filtros'}
      </Button>
      <div className="alert" ref={container} />
    </div>
  );
}
