import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import ButtonFilter from "../Filter/ButtonFilter";
import BtnPreConfiguredRegister from "../../components/ModalButton/ButtonRegisterPreConfig";
import "date-fns";

import { Container, Div } from "../Filter/styles";
import { FilterFields } from "../Filter/FilterFieldsPreConfigured";

const Filter = () => {
  const formName = useRef(null);
  const dispatch = useDispatch();
  const [showFilterFields, setShowFilterFields] = useState(false);

  const addEventAct = (eventFetch) => {
    return { type: "ADD_EVENT_FETCH", eventFetch };
  };
  const handleChangeSearchTransfersByEvent = (e) => {
    const value = e.target.value;
    dispatch(addEventAct(value));
  };

  return (
    <>
      <Container>
        <Div>
          <BtnPreConfiguredRegister />
          <form ref={formName}>
            <div>
              <Grid item>
                <TextField
                  onChange={handleChangeSearchTransfersByEvent}
                  className="search-transfers"
                  style={{ width: 376 }}
                  label="Pesquisar por Evento"
                  type="search"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </div>
          </form>

          <ButtonFilter
            setShowFilterFields={setShowFilterFields}
            showFilterFields={showFilterFields}
          />
        </Div>
        <Div>{showFilterFields && <FilterFields />}</Div>
      </Container>
    </>
  );
};

export default Filter;
