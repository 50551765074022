import React, { useEffect, useState } from "react";
import { Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import stopIcon from "../../assets/Icon_Parada_01.png";

export default function TransferRoutesPoints() {
  const [transferRoutesPoints, setTransferRoutesPoints] = useState([]);
  const devices = useSelector((store) => store.monitoringMap.devices);
  const transfersActive = useSelector(
    (store) => store.monitoringMap.transferActive
  );
  const visibilityRoutes = useSelector(
    (store) => store.monitoringMap.visibilityRoute
  );
  const stop_points = useSelector((store) => store.monitoringMap.stopPoint);

  useEffect(() => {
    setTransferRoutesPoints(stop_points);
  }, [transfersActive, devices, visibilityRoutes, stop_points]);

  const getIcon = (transferRoutePoint) => {
    return transferRoutePoint.stopping_time ? stopIcon : null;
  };

  return (
    <>
      {transferRoutesPoints.map((transferRoutePoint, index) => {
        if (getIcon(transferRoutePoint) === null) {
          return null;
        }

        const data = {
          lat: transferRoutePoint.positions.coordinates[1],
          lng: transferRoutePoint.positions.coordinates[0],
        };

        return (
          <li key={index}>
            <Marker
              title={transferRoutePoint.name}
              position={data}
              icon={getIcon(transferRoutePoint)}
            />
          </li>
        );
      })}
    </>
  );
}
