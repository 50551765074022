import { Grid, Stepper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Button, styled as styledMUI } from "@mui/material";

export const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  paper: {
    width: 258,
    height: 364,
    overflow: "auto",
    borderRadius: 5,
    padding: 10,
    gap: 10,
  },
  button: {
    width: 44,
    height: 46.75,
    padding: 10,
    borderRadius: 5,
    gap: 10,
    marginBottom: 2,
  },
  stepperCustom: {
    padding: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "5px",
    marginBottom: "5px",
  },
}));

export const GridMain = styledMUI(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StepperCustom = styledMUI(Stepper)(() => ({
  padding: 0,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "5px",
  marginBottom: "5px",
}));

export const GridCustom = styledMUI(Grid)(() => ({
  padding: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  alignSelf: "center",
}));
