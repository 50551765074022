export const representsInteger = (value) => {
  switch (typeof value) {
    case "string":
      return /^[+-]{0,1}\d+$/.test(value);
    case "number":
      return Math.trunc(value) === value;
    default:
      return false;
  }
};

export const callAsyncOrNot = async (callback, ...params) => {
  if (callback.constructor.name === "AsyncFunction")
    return await callback(...params);
  else return callback(...params);
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const areEqualArray = (prevArrayIds, nextArrayIds) => {
  if (prevArrayIds.length !== nextArrayIds.length) {
    return false;
  }
  return nextArrayIds.every((item) => prevArrayIds.includes(item));
};
