import utransferV2API from "../utransferV2";

export const handleGetAllDriver = async (page, perPage) => {
  const response = await utransferV2API.get(`/drivers`, {
    params: { page, perPage },
  });
  return response.data;
};

export const handlePostDriver = async (data) => {
  const response = await utransferV2API.post(`/drivers`, data);
  return response.data;
};

export const handleDeleteDriver = async (id) => {
  await utransferV2API.delete(`/drivers/${id}`);
};

export const handleUpdateDriver = async (id, data) => {
  const response = await utransferV2API.put(`/drivers/${id}`, data);
  return response.data;
};

export const handleGetDevicesNoBonds = async (customerId) => {
  const response = await utransferV2API.get(
    `/customers/${customerId}/devices-utrack`
  );
  return response.data;
};

export const handleGetDriversCurrent = async (customerId, deviceId) => {
  const response = await utransferV2API.get(
    `/customers/${customerId}/devices/${deviceId}/drivers`
  );
  return response.data;
};

export const handleGetDriversAvailable = async () => {
  const response = await utransferV2API.get(`/drivers-available`);
  return response.data;
};

export const unbindDriver = async (customerId, deviceId) => {
  const response = await utransferV2API.put(
    `/customers/${customerId}/devices/drivers/${deviceId}`
  );
  return response.data;
};
