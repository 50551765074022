import axios from "axios";
import * as middlewares from "./middlewares";

const geoanalyticsApi = axios.create({
  baseURL: process.env.REACT_APP_GEOANALYTICS_API,
});

middlewares.addUserAuthorization(geoanalyticsApi);

export const getGeoAnalyticsRoutes = async () => {
  const response = await geoanalyticsApi.get(`/routes`);
  return response.data;
};

export default geoanalyticsApi;
