import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Event as EventIcon } from "@mui/icons-material";
import { CopyIconButton } from "../../CopyIconButton";

export const DateItem = ({ label, value, copy }) => {
  return (
    <ListItem
      sx={{ py: 0 }}
      secondaryAction={copy && <CopyIconButton text={value} edge="end" />}
    >
      <ListItemIcon>
        <EventIcon />
      </ListItemIcon>
      <ListItemText
        primary={label}
        secondary={value}
        secondaryTypographyProps={{
          sx: {
            wordWrap: "break-word",
          },
        }}
      />
    </ListItem>
  );
};
