import { makeStyles } from '@material-ui/core'

const contextButton = ({ visible = true, editButtonBackgroundColor = null }) => ({
  fontSize: 20,
  opacity: visible ? '1' : '0',
  transition: 'opacity 0.3s',
  cursor: 'pointer',
  backgroundColor: editButtonBackgroundColor !== null
    ? editButtonBackgroundColor
    : 'inherit'
})

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },

  closeButton: ({ visible = true, editButtonBackgroundColor = null }) => ({
    ...contextButton({ visible, editButtonBackgroundColor }),
  }),

  editButton: ({ visible = true, editButtonBackgroundColor = null }) => ({
    ...contextButton({ visible, editButtonBackgroundColor }),
    paddingLeft: 4,
    alignSelf: 'center',
  }),

  contentContainer: {
    display: 'flex',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  contentField: {
    flex: 1,
  },

  inputField: {
    padding: 0,
    margin: 0,
    fontSize: 14,
    width: '100%',
  }
})

export default useStyles