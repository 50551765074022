import React from "react";
import { Typography, Card, CardContent, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useStyles } from "./style";

export const EmissionInfoCard = ({ totalEmissions, treesForReplanting }) => {
  const classes = useStyles();

  const formattedCO2 =
    totalEmissions > 1000
      ? `${(totalEmissions / 1000).toFixed(2)} Ton`
      : `${totalEmissions.toFixed(2)} Kg`;

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div
          className={classes.div}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Typography variant="h6" component="h2" style={{ marginLeft: 10 }}>
              Total de CO2 Emitido:{" "}
              {formattedCO2.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
              })}{" "}
              CO2-eq
              <br />
              Total de Árvores para Replantio: {treesForReplanting}
            </Typography>
          </div>
          <Tooltip
            title="O cálculo de árvores para compensação de emissões de carbono é uma estimativa baseada em uma média geral e pode variar dependendo de fatores adicionais. Alguns desses fatores incluem: Tipo e idade da árvore e condições ambientais"
            arrow
            placement="top"
          >
            <InfoIcon
              style={{
                color: "gray",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
      </CardContent>
    </Card>
  );
};
