import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Drawer, Paper } from "@material-ui/core";
import Iframe from "react-iframe";
import * as dateFns from "date-fns";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "./styles";
import Geocode from "react-geocode";
import { useGoogleMapsKey } from "../../hooks/useGoogleMapsKey";
import {
  addLastPositons,
  changeVehicleCam,
} from "../../store/ducks/monitoringMap";
import { getFlespiMessages } from "../../services/flespiApiService";
import { toast } from "material-react-toastify";
import { useHistory } from "react-router-dom";
import { getTempByLocation } from "../../infra/http/meteumServiceRepository";
import { WEATHER_ICON } from "../../commons/utils/weather-icons";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import TrackChangesOutlinedIcon from "@material-ui/icons/TrackChangesOutlined";
import MyLocationOutlinedIcon from "@material-ui/icons/MyLocationOutlined";
import InputSelectorDriver from "./ReportSelectorDriver";
import {
  handleGetDriversAvailable,
  handleGetDriversCurrent,
  unbindDriver,
} from "../../services/peopleManagement/driver";
import { createDeviceDriver } from "../../services/deviceDriver";
import UnbindDialog from "./ModalUnbind";
import BindDialog from "./ModalBind";
import { devicesTypeSmartphone } from "../../commons/enums/deviceType";
import {
  disableAdvancedMonitoring,
  enableAdvancedMonitoring,
} from "../../store/ducks/advancedMonitoring";
import { allowedPermissions } from "../../commons/constants/advanced-monitoring";

export default function ModalVehicle() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [address, setAddress] = useState("");
  const [temp, setTemp] = useState(undefined);
  const [unbindToggle, setUnbindToggle] = useState(false);
  const [bindToggle, setBindToggle] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [driverId, setDriverId] = useState();
  const [driverCurrent, setDriverCurrent] = useState("");
  const [vehicleSpeed, setVehicleSpeed] = useState();
  const [odometer, setOdometer] = useState();
  const googleMapsKey = useGoogleMapsKey();
  const classes = useStyles();

  const deviceIdAdvancedMonitoring = useSelector(
    (store) => store.advancedMonitoring.deviceId
  );

  const permissions =
    useSelector((store) => store.auth.permissions?.permissions.data) ?? [];

  const customerId = useSelector((store) => store.auth.customer.customer);
  const showMVehicleModal = useSelector(
    (store) => store.monitoringMap.showVehicleModal
  );

  const deviceIdsWithCamEnabled = useSelector(
    (store) => store.monitoringMap.deviceIdsWithCamEnabled
  );
  const deviceInfo = useSelector(
    (store) => store.monitoringMap.deviceInfoModalVehicle
  );
  const messagesFlespi = useSelector(
    (store) => store.monitoringMap.messagesFlespi
  );
  const vehicleName = deviceInfo?.alias || deviceInfo?.identifier;
  const deviceId = deviceInfo?.id;
  const vehicleActive = deviceInfo.active;
  const createdAtDate = new Date(deviceInfo.taken_at);
  const formattedDate = dateFns.isValid(createdAtDate)
    ? dateFns.format(createdAtDate, "dd/MM/yyyy HH:mm:ss")
    : "-";
  const getVehicleInfo = () => {
    if (!messagesFlespi[deviceInfo.id]) return 0;

    const device = messagesFlespi[deviceInfo.id];

    setVehicleSpeed(device[device.length - 1].speed);
    setOdometer(device[device.length - 1].odometer?.toFixed(0));
  };
  Geocode.setApiKey(googleMapsKey);
  Geocode.setLanguage("pt");
  const now = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const pastTimestamp = Math.floor(new Date(now) / 1000) + 7200;

  const devicesTransfer = useSelector((store) => store.monitoringMap.devices);
  const lastPositionsHour = useSelector(
    (store) => store.monitoringMap.lastPositions
  );

  const deviceTransferStart = devicesTransfer.find((deviceTransfer) => {
    return deviceTransfer?.device?.id === deviceInfo?.id;
  });

  const addLatestPositions = (lastPositions) => {
    dispatch(addLastPositons(lastPositions));
  };

  const getDataTable = async () => {
    try {
      const response = await handleGetDriversAvailable();
      setDrivers(response);
    } catch (error) {
      toast.error("Não foi possível carregar os motoristas");
    }
  };

  const handleDrivers = async (event, value) => {
    const id = driverId;
    await createDeviceDriver(customerId, deviceId, id);
    setBindToggle(false);
    getDrivers();
  };

  const handleUnbindDriver = async () => {
    await unbindDriver(customerId, deviceId);
    setDriverCurrent("");
    setUnbindToggle(false);
  };

  const getLastPositions = async () => {
    if (lastPositionsHour.length > 0) {
      return addLatestPositions([]);
    }

    try {
      const lastHourPosition = await getFlespiMessages(deviceInfo.flespi_id, {
        from: pastTimestamp,
      });

      const listLatestPositions = lastHourPosition
        .filter(
          (lastPositons) =>
            lastPositons.last_status.latitude &&
            lastPositons.last_status.longitude
        )
        .map((positions) => ({
          lat: positions.last_status.latitude,
          lng: positions.last_status.longitude,
        }));

      addLatestPositions(listLatestPositions);
    } catch (err) {
      toast.error("Não foi possível renderizar ás últimas posições");
    }
  };

  const onToggleOpen = (id) => {
    if (deviceIdsWithCamEnabled.includes(id)) {
      return dispatch(
        changeVehicleCam(
          deviceIdsWithCamEnabled.filter((deviceId) => deviceId !== id)
        )
      );
    }

    dispatch(changeVehicleCam([...deviceIdsWithCamEnabled, id]));
  };

  const unbindTogleOpen = () => {
    setUnbindToggle(true);
  };

  const unbindTogleClose = () => {
    setUnbindToggle(false);
  };

  const bindTogleOpen = (event, value) => {
    setDriverCurrent(value?.name);
    setDriverId(value?.id);
    setBindToggle(true);
  };

  const bindTogleClose = () => {
    setDriverCurrent("");
    setBindToggle(false);
  };

  const navigateReport = () => {
    history.push("/reports", {
      tab: "identifier",
      identifier: deviceInfo.identifier,
    });
  };

  const handleGetTemp = async () => {
    try {
      const [latitude, longitude] = deviceInfo.last_position.coordinates;
      const { data } = await getTempByLocation(latitude, longitude);
      setTemp({
        temperature: data.weatherByPoint.now.temperature,
        icon: WEATHER_ICON[data.weatherByPoint.now.condition],
      });
    } catch {
      toast.error("Falha ao tentar obter dados do tempo.");
    }
  };

  const getDrivers = async () => {
    if (!deviceId) return;
    const response = await handleGetDriversCurrent(customerId, deviceId);
    setDriverCurrent(response.name);
  };

  const ignitionType = () => {
    const deviceTypeId = deviceInfo.device_type_id;

    if (vehicleActive) {
      return (
        <div className={classes.DeviceOn}>
          {devicesTypeSmartphone.hasOwnProperty(deviceTypeId)
            ? "Rastreamento ligado"
            : "Ignição ligada"}
        </div>
      );
    } else {
      return (
        <div className={classes.DeviceOff}>
          {devicesTypeSmartphone.hasOwnProperty(deviceTypeId)
            ? "Rastreamento desligado"
            : "Ignição desligada"}
        </div>
      );
    }
  };

  useEffect(() => {
    const getAddress = async () => {
      const [latitude, longitude] = deviceInfo?.last_position?.coordinates;
      const response = await Geocode.fromLatLng(latitude, longitude);
      const address = response?.results[0]?.formatted_address;
      setAddress(address);
    };

    if (deviceId) {
      getDrivers();
    }
    getDataTable();
    getVehicleInfo();

    if (deviceInfo?.last_position?.coordinates) {
      getAddress();
      handleGetTemp();
    }
  }, [deviceInfo]);

  const handleAdvancedMonitoringClick = () => {
    const deviceId = deviceInfo?.id;
    const flespiMessages = messagesFlespi[deviceInfo.id];

    if (!deviceId || flespiMessages?.length === 0) {
      return;
    }

    const lastFlespiMessage = flespiMessages[flespiMessages.length - 1];

    if (!lastFlespiMessage) {
      return;
    }

    const deviceLocation = {
      lat: lastFlespiMessage.latitude,
      lng: lastFlespiMessage.longitude,
    };

    dispatch(
      enableAdvancedMonitoring({
        deviceId,
        deviceLocation,
      })
    );
  };

  const handleDisableAdvancedMonitoringClick = () => {
    dispatch(disableAdvancedMonitoring());
  };

  const renderAdvancedMonitoringButton = () => {
    const hasAllPermissions = allowedPermissions.every((permission) =>
      permissions.includes(permission)
    );

    if (!hasAllPermissions) {
      return <></>;
    }

    if (deviceIdAdvancedMonitoring === deviceInfo?.id) {
      return (
        <div
          className={classes.ButtonOff}
          onClick={handleDisableAdvancedMonitoringClick}
        >
          <AssignmentOutlinedIcon className={classes.Standard} />
          Desativar monitoramento avançado
        </div>
      );
    }

    return (
      <div className={classes.Button} onClick={handleAdvancedMonitoringClick}>
        <AssignmentOutlinedIcon className={classes.Standard} />
        Monitoramento avançado
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerVehicle]: false,
        })}
        variant="persistent"
        anchor="right"
        open={showMVehicleModal}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerPaperVehicle]: false,
          }),
        }}
      >
        <div className={classes.box}>
          <Paper className={classes.eventListContainer} elevation={2}>
            <div className={classes.boxHeader}>
              <div className={classes.VehicleName}>{vehicleName}</div>
              <div className={classes.DivActive}>{ignitionType()}</div>
            </div>

            <div className={classes.Body}>
              <div className={classes.BodyInfo}>
                <b>Data e hora:</b> {formattedDate}
              </div>

              {vehicleSpeed !== undefined && (
                <div className={classes.BodyInfo}>
                  <b>Velocidade:</b>
                  {vehicleSpeed} Km/h
                </div>
              )}

              {odometer && (
                <div className={classes.BodyInfo}>
                  <b>Odômetro:</b>
                  {odometer} Km
                </div>
              )}

              {temp !== undefined && (
                <div className={classes.BodyInfo}>
                  <b>Temperatura:</b>
                  {temp.temperature}°
                  {temp.icon && <img src={temp.icon} alt="Icone do tempo" />}
                </div>
              )}

              <div className={classes.BodyInfo}>
                <b>Lat/Long:</b> {deviceInfo?.last_position?.coordinates[0]},{" "}
                {deviceInfo?.last_position?.coordinates[1]}
              </div>

              <div className={classes.BodyInfo}>
                <b>Endereço:</b>
                {address}
              </div>
            </div>

            <div className={classes.Footer}>
              {/* {deviceInfo.device_type_id == 1 ? (
                <Button>Voice Call</Button>
              ) : (
                <ButtonOff>Voice Call</ButtonOff>
              )} */}

              <InputSelectorDriver
                options={drivers}
                placeholder="Selecione um motorista"
                defaultValue={driverCurrent}
                onChange={bindTogleOpen}
                unbindDialog={unbindTogleOpen}
                disable={Boolean(driverCurrent)}
              />

              <BindDialog
                open={bindToggle}
                onClose={bindTogleClose}
                onBind={handleDrivers}
              />

              <UnbindDialog
                open={unbindToggle}
                onClose={unbindTogleClose}
                onUnbind={handleUnbindDriver}
              />

              {deviceTransferStart?.transfer?.start_date ? (
                <div className={classes.ButtonOff}>
                  <MyLocationOutlinedIcon className={classes.Standard} />
                  Últimas posições
                </div>
              ) : (
                <div className={classes.Button} onClick={getLastPositions}>
                  <MyLocationOutlinedIcon className={classes.Standard} />
                  Últimas posições
                </div>
              )}

              <div className={classes.Button} onClick={navigateReport}>
                <AssignmentOutlinedIcon className={classes.Standard} />
                Relatório
              </div>

              {deviceInfo.metadata ? (
                <div
                  className={classes.Button}
                  onClick={() => onToggleOpen(deviceInfo.id)}
                >
                  <TrackChangesOutlinedIcon className={classes.Standard} />
                  Câmera embarcada
                </div>
              ) : (
                <div className={classes.ButtonOff}>
                  <TrackChangesOutlinedIcon className={classes.Standard} />
                  Câmera embarcada
                </div>
              )}

              {renderAdvancedMonitoringButton()}
            </div>
          </Paper>
        </div>
      </Drawer>
    </div>
  );
}
