import React from "react";
import styles from "./styles";

const Square = ({ color, size = 30 }) => {
  return (
    <span style={styles.square({color, size})}></span>
  )
}

export default Square;
