import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Divider } from "@material-ui/core";
import Stepper from "./Stepper";
import { DefaultSidebarContent } from "./DefaultSidebarContent";
import { WaypointsSidebarContent } from "./WaypointsSidebarContent";
import transferApi from "../../../services/transferApi";
import { setIncidentsNaturesAction } from "../../../store/ducks/scripting";
import { NaturesAdvancedOptionsSidebarContent } from "./NaturesAdvancedOptionsSidebarContent";
import { toast } from "material-react-toastify";

export const ScriptingSidebar = () => {
  const accessToken = useSelector((state) => state.auth.token.accessToken);
  const sidebarContentOpen = useSelector(
    (state) => state.scripting.sidebarContentOpen
  );
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchIncidentsNatures() {
      const options = { headers: { Authorization: `Bearer ${accessToken}` } };
      try {
        const response = await transferApi.get("/incidents/natures", options);
        dispatch(setIncidentsNaturesAction(response.data));
      } catch {
        toast.error("Erro ao carregar incidentes");
      }
    }

    fetchIncidentsNatures();
  }, [accessToken, dispatch]);

  return (
    <>
      <div>
        <Typography
          variant="h6"
          style={{ fontWeight: 400, fontSize: 18 }}
          gutterBottom
          align="center"
        >
          Novo Transfer | <strong>Roteirização</strong>
        </Typography>
      </div>

      <Stepper />

      <Divider />

      {sidebarContentOpen === "default" && <DefaultSidebarContent />}
      {sidebarContentOpen === "waypoints" && <WaypointsSidebarContent />}
      {sidebarContentOpen === "naturesAdvancedOptions" && (
        <NaturesAdvancedOptionsSidebarContent />
      )}
    </>
  );
};
