import React, { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'material-react-toastify';

import Button from '@material-ui/core/Button';

import { reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';

import { prepareSettingValues, reverseStr }from './utils/prepareTransferSettingValue'

import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper'
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step'
import { Diiv } from '../styles'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import colors from '../../../commons/colors'
import devicesApi from '../../../services/devices';
import * as transfersProvider from '../../../infra/http/transfersProvider'
import WeekDays from '../../StepperModalButton/WeekDays'
import MonthDays from '../../StepperModalButton/MonthDays'

const useStyles = makeStyles((theme) => ({
  root: {},
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  
}));

function getSteps() {
  return ['Configuração'];
}

const getCheckedCustomDays = customDays => ([
  { value: 'sundays', checked: customDays.includes('sundays') },
  { value: 'mondays', checked: customDays.includes('mondays') },
  { value: 'tuesdays', checked: customDays.includes('tuesdays') },
  { value: 'wednesdays', checked: customDays.includes('wednesdays') },
  { value: 'thursdays', checked: customDays.includes('thursdays') },
  { value: 'fridays', checked: customDays.includes('fridays') },
  { value: 'saturdays', checked: customDays.includes('saturdays') }
]);

export let EditForm = ({ 
  initialize,
  transfer, 
  close: HandlerExternal = () => { }, 
  onSuccess = () => {} }) => {

  const [activeStep, setActiveStep] = React.useState(0);
  const classes = useStyles();
  const steps = getSteps();

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [frequency, setFrequency] = useState("");
  const [scheduledTime, setScheduledTime] = useState("");

  const [success, setSuccess] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const customerId = useSelector(store => store.auth.customer.customer);
  const token = useSelector( store => store.auth.token.accessToken )
  const updatedCustomDays = useSelector(store => store.form.preConfiguredTransferForm.values.custom_days)
  const updatedCustomDates = useSelector(store => store.form.preConfiguredTransferForm.values.custom_dates);

  let defaultFrequence;

  useEffect(()=>{
    const loadData = async () => {
      setLoadingData(true);
      setFromDate( transfer.pre_configured_transfer_settings?.from_date );
      setToDate( transfer.pre_configured_transfer_settings?.to_date );
      setFrequency( transfer.pre_configured_transfer_settings?.frequency );
      setScheduledTime( transfer.pre_configured_transfer_settings?.scheduled_time);

      setLoadingData(false);
    }
    loadData();
  },[]);

  useEffect(() => {

    let { custom_days, custom_dates, frequency } = transfer.pre_configured_transfer_settings;

    switch (frequency) {
      case 'daily':
        case 'weekday':
          custom_days = [
            { value: 'sundays', checked: false },
            { value: 'mondays', checked: false },
            { value: 'tuesdays', checked: false },
            { value: 'wednesdays', checked: false },
            { value: 'thursdays', checked: false },
            { value: 'fridays', checked: false },
            { value: 'saturdays', checked: false }
          ]
          custom_dates = []
          break;
      case 'custom_days':
        custom_days =  getCheckedCustomDays(custom_days)
        custom_dates = []
        break;
      case 'custom_dates':
        custom_dates = reverseStr(custom_dates)
        custom_days = [
          { value: 'sundays', checked: false },
          { value: 'mondays', checked: false },
          { value: 'tuesdays', checked: false },
          { value: 'wednesdays', checked: false },
          { value: 'thursdays', checked: false },
          { value: 'fridays', checked: false },
          { value: 'saturdays', checked: false }
        ]
      break;
      default:
        break;
    }

    initialize({
      custom_dates,
      custom_days,
    });
  }, [initialize, transfer.pre_configured_transfer_settings]);

  if(transfer.pre_configured_transfer_settings?.frequency==="daily"){
    defaultFrequence = { title: 'daily', mask:'Diária'};
  }else if(transfer.pre_configured_transfer_settings?.frequency==="weekday"){
    defaultFrequence =  { title: 'weekday', mask:'Segunda a Sexta'};
  }else if(transfer.pre_configured_transfer_settings?.frequency==="custom_days"){
    defaultFrequence = { title: 'custom_days', mask:'Definir dias da semana'};
  }else if(transfer.pre_configured_transfer_settings?.frequency==="custom_dates"){
    defaultFrequence = { title: 'custom_dates', mask:'Definir dias do mês'};
  }


async function handleDeleteTransfer(){
  setSuccess(true);
  try {
      await transfersProvider.removePreConfiguredTransfers(transfer.id,transfer.transfer_setting_id, customerId)
      toast.success(`Transfer #${transfer.id} removido com sucesso.`)
      HandlerExternal()
      onSuccess()
  }catch( err ) {
    if (!transfer.id)
      toast.error('Não foi possível remover transfer.')
    else
      toast.error(`Não foi possível remover transfer #${transfer.id}.`)

    console.log(err)
  }
}

  const handleEditTransfer = async (e) => {
    e.preventDefault();
    setSuccess(true);

    const setting = prepareSettingValues(updatedCustomDays
      .filter(customDays => customDays.checked)
      .map(customDays => customDays.value),
    updatedCustomDates, frequency)
    const frequencySetting = setting.frequency
    const customDaySetting = setting.custom_days
    const customDateSetting = setting.custom_dates
    const data = {
      from_date:fromDate,
      to_date:toDate,
      frequency:frequencySetting,
      scheduled_time:scheduledTime,
      custom_days: customDaySetting,
      custom_dates: customDateSetting,
    }

    try {
      await devicesApi.request({
        url:`/transfers/pre-configured/${transfer.transfer_setting_id}`,
        method:"PUT",
        data:data,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success(`Transfer #${transfer.id} atualizado com sucesso.`)
      HandlerExternal()
      onSuccess()
    }catch( err ) {
      toast.error("Erro ao Editar");
      setSuccess(false);
      console.log(err);
    }
  }
  const frequencies = [
    { title: 'daily', mask:'Diária'},
    { title: 'weekday', mask:'Segunda a Sexta'},
    { title: 'custom_days', mask:'Definir dias da semana'},
    { title: 'custom_dates', mask:'Definir dias do mês'},
  ];

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
          <div className={classes.root}>
            <Typography className={classes.instructions}>
                <form>
                  {(success === false && loadingData === false) ?
                    <>
                      <center>
                        <Diiv>
                          <form noValidate>
                            <TextField
                              id="initial-date"
                              variant="outlined"
                              label="Início do Período"
                              type="date"
                              style={{ width: 260 }}
                              defaultValue={ transfer.pre_configured_transfer_settings?.from_date}
                              onChange={e => setFromDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </form>
      
                            <form noValidate>
                              <TextField
                                id="final-date"
                                variant="outlined"
                                label="Final do Período"
                                type="date"
                                style={{ width: 260 }}
                                defaultValue=  {transfer.pre_configured_transfer_settings?.to_date}
                                onChange={e => setToDate(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                />
                            </form>
                        </Diiv>
                        <br/>
                        <Diiv>
                          <form noValidate>
                            <TextField
                              id="time"
                              variant="outlined"
                              label="Horário do Transfer"
                              type="time"
                              defaultValue={transfer.pre_configured_transfer_settings?.scheduled_time}
                              onChange={e => setScheduledTime(e.target.value)}
                              style={{ width: 260 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                            />
                          </form>
      
                          <Autocomplete
                            id="combo-box-frequency"
                            options={frequencies}
                            getOptionSelected={(option) => option.mask === defaultFrequence.mask }
                            getOptionLabel={(option) => option.mask}
                            style={{ width: 260 }}
                            defaultValue={defaultFrequence}
                            onChange={(e,value) => setFrequency(value.title)}
                            renderInput={(params) => <TextField {...params} style={{ width: 260 }} InputLabelProps={{shrink: true}} label="Frequência" variant="outlined" />}
                          />
                        </Diiv>
                      </center>
                    </>
                  :
                  <>
                    <center>
                      <div>
                        <CircularProgress size={68} style={{marginTop:0,color: "#63a9ef"}}></CircularProgress>
                      </div>
                      <span >{loadingData ? 'Carregando dados' : 'Salvando'}</span>
                    </center>
                  </>
      
                  }
                </form>
              </Typography>
            </div>
          </>
        )
      default:
          break;
    }
  }

  return (
    <form onSubmit={handleEditTransfer}>
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}
              {activeStep == 0 ? (
                <div>
                  <form className={classes.instructions}>
                    {frequency === 'custom_days' && (
                          <WeekDays/>
                    )}

                    {frequency === 'custom_dates' && (
                        <center>
                          <MonthDays/>
                        </center>
                    )}
                  </form>
                </div>
              ):("")}
            </Typography>
          </div>
        </div>
      </div>
      <center>  
        <div>
          <Button
              onClick={handleDeleteTransfer}
              style={{margin: "0 15px", backgroundColor: colors.red, color:'white', borderRadius: 8, border:"none", boxShadow: '0px 3px 6px #00000029', align: "center"}}
              variant="outlined"
              autoFocus
          >
            Remover
          </Button>
          <Button
            type="submit"
            autoFocus
            style={{backgroundColor: "#06B0CF", borderRadius: 8, boxShadow: '0px 3px 6px #00000029'}}
            variant="contained"
            color="primary"
          >
            Salvar
          </Button>
        </div>
      </center>
    </form>
  )
}

EditForm = reduxForm({
  form: 'preConfiguredTransferForm',
  destroyOnUnmount: true,
  enableReinitialize : true,
  initialValues: {
    custom_dates: [],
    custom_days: [
      { value: 'sundays', checked: false },
      { value: 'mondays', checked: false },
      { value: 'tuesdays', checked: false },
      { value: 'wednesdays', checked: false },
      { value: 'thursdays', checked: false },
      { value: 'fridays', checked: false },
      { value: 'saturdays', checked: false }
    ],
  },
})(EditForm);

const mapDispatchToProps = { initialize };

export default connect(null, mapDispatchToProps)(EditForm);
