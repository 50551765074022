import {
  styled as styledMUI,
  Box,
  Card,
  Typography,
  Container,
  Button,
  TextField,
  Paper,
} from "@mui/material";

export const Div = styledMUI(Box)(({ open }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  maxHeight: "100vh",
  height: "100%",
  backgroundColor: "#F8F8F8",
  position: "absolute",
  maxWidth: "300px",
  transform: open ? `300px` : `translateX(-305px)`,
  transition: "all .3s",
}));

export const SidebarTitle = styledMUI(Card)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  height: "60px",
  width: "100%",
}));

export const Text = styledMUI(Typography, {
  shouldForwardProp: (prop) => prop !== "customInfo",
})((props) => ({
  fontSize: props.customInfo === "infoDescription" ? "16px" : "18px",
  fontWeight: props.customInfo === "infoDescription" ? 400 : 500,
  lineHeight: "16.34px",
}));

export const ContainerDescription = styledMUI(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "15px",
  marginTop: "20px",
}));

export const OrganizeButton = styledMUI(Button)(() => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
  backgroundColor: "transparent",
  color: "#2A93A7",
  textTransform: "none",
  fontSize: "16px",
  width: "50px",
  marginLeft: "10px",
}));

export const CustomTextField = styledMUI(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "5px",
    },
  },
  input: {
    fontSize: "10px",
    fontWeight: 400,
    height: "25px",
  },
  backgroundColor: "#F8F8F8",
  width: "400px",
  marginLeft: "20px",
  marginTop: "30px",
}));

export const CustomPaper = styledMUI(Paper)(() => ({
  width: "100%",
  maxHeight: "100vh",
  height: "100%",
  paddingBottom: "10px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#3AC4E0",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#2A93A7",
  },
}));
