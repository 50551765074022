export function descendingComparator(a, b, orderBy) {
  const aComparedValue = a?.[orderBy] ?? null
  const bComparedValue = b?.[orderBy] ?? null

  if (aComparedValue === null && bComparedValue === null)
    return 0
  else if (aComparedValue === null)
    return 1
  else if (bComparedValue === null)
    return -1
  
  if (bComparedValue < aComparedValue) {
    return -1;
  }
  if (aComparedValue < bComparedValue) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator, orderBy) {
  const stabilizedThis = array.map((element, index) => [element, index]);
  
  stabilizedThis.sort((a, b) => {
    let order
    
    if (orderBy === "route_progress" && a[0].transfer !== null)
      order = comparator(a[0].transfer, b[0].transfer)
    else
      order = comparator(a[0].device, b[0].device)

    if (order !== 0) return order;
    return a[1] - b[1];
  });
  
  return stabilizedThis.map((element) => element[0]);
}