import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import TransferStateButton from "./TransferStateButton";
import { transferStates } from "../constants";

export default function ProgressButton({
  device,
  setStateOfSnackBarWarning,
  changeTransferStateHandler,
  transferState,
  finishCall,
}) {
  if (!device.transfer?.id) return null;

  switch (transferState) {
    case transferStates.STARTING:
    case transferStates.ENDING:
      return (
        <CircularProgress
          size={20}
          style={{ marginTop: 0, color: "#63a9ef" }}
        />
      );
    case transferStates.NO_TRANSFER:
    case transferStates.ENDED:
    case transferStates.ONGOING_MISSING_DATA:
      return null;
    default:
      return (
        <TransferStateButton
          device={device}
          transferState={transferState}
          setStateOfSnackBarWarning={setStateOfSnackBarWarning}
          changeTransferStateHandler={changeTransferStateHandler}
          finishCall={finishCall}
        />
      );
  }
}
