import devicesApi from "../devices";

export const handleGetUnits = async (customerId) => {
  try {
    const response = await devicesApi.get(`/customer/${customerId}/units`);
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handlePostUnit = async (customerId, name) => {
  try {
    const response = await devicesApi.post(`/customer/${customerId}/units`, {
      name,
    });
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handleDeleteUnit = async (customerId, id) => {
  try {
    const response = await devicesApi.delete(
      `/customer/${customerId}/units/${id}`
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handleEditUnit = async (customerId, id, name) => {
  try {
    const response = await devicesApi.put(
      `/customer/${customerId}/units/${id}`,
      {
        name,
      }
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};
