import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { AutoCompleteInput } from "./AutocompleteInput";
import { setCurrentTransferAction } from "../../../store/ducks/transfer";
import { startLoadingDirectionsAction } from "../../../store/ducks/scripting";

export const EditFromOrToModal = ({
  isOpen,
  setIsOpen,
  locationValue,
  setLocationValue,
  locationType,
  setLocationType,
}) => {
  const { currentTransfer } = useSelector((state) => state.transfer);
  const dispatch = useDispatch();

  const handleClickEdit = () => {
    dispatch(
      setCurrentTransferAction({
        ...currentTransfer,
        [locationType]: locationValue,
      })
    );
    dispatch(startLoadingDirectionsAction());
    setLocationValue("");
    setLocationType("");
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>
        Endereço antigo: {currentTransfer[locationType]}
      </DialogTitle>
      <DialogTitle>Endereço novo:</DialogTitle>
      <DialogContent>
        <AutoCompleteInput value={locationValue} setValue={setLocationValue} />
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={() => {
            setLocationValue("");
            setLocationType("");
            setIsOpen(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            handleClickEdit();
          }}
        >
          Editar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
