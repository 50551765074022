import { format as dateFormat, subDays } from "date-fns";
import storage from "redux-persist/lib/storage";

export const Types = {
  SET_CURRENT_TRANSFER: "SET_CURRENT_TRANSFER",
  ADD_TRANSFER_FETCH: "ADD_TRANSFER_FETCH",
  ADD_UNITY_FETCH: "ADD_UNITY_FETCH",
  ADD_EVENT_FETCH: "ADD_EVENT_FETCH",
  ADD_CUSTOMER_UNIT_ID_FETCH: "ADD_CUSTOMER_UNIT_ID_FETCH",
  ADD_FROM_FETCH: "ADD_FROM_FETCH",
  ADD_TO_FETCH: "ADD_TO_FETCH",
  ADD_FROM_DATE_FETCH: "ADD_FROM_DATE_FETCH",
  ADD_TO_DATE_FETCH: "ADD_TO_DATE_FETCH",
  ADD_RESPONSIBLE_FETCH: "ADD_RESPONSIBLE_FETCH",
  ADD_SCHEDULED_DATE_FETCH: "ADD_SCHEDULED_DATE_FETCH",
  ADD_FORMFROM: "ADD_FORMFROM",
  ADD_FORMTO: "ADD_FORMTO",
  ADD_IDTOSCRIPTING: "ADD_IDTOSCRIPTING",
  ADD_SETTINGIDTOSCRIPTING: "ADD_SETTINGIDTOSCRIPTING",
  SET_CURRENT_TRANSFER_ID: "SET_CURRENT_TRANSFER_ID",

  ADD_TRANSFER_CONFIGURATION: "ADD_TRANSFER_CONFIGURATION",

  ADD_DEVICE_WAY: "ADD_DEVICE_WAY",
  ADD_EXPECTED_ROUTE: "ADD_EXPECTED_ROUTE",
  ADD_STOP_POINTS: "ADD_STOP_POINTS",
  ADD_RISK_LAYERS: "ADD_RISK_LAYERS",
  ADD_TRANSFER_EVENTS: "ADD_TRANSFER_EVENTS",
  ADD_TRANSFER_EVENT_ANALYTICS: "ADD_TRANSFER_EVENT_ANALYTICS",

  CHANGE_SHOW_COORDINATE_MARKERS: "CHANGE_SHOW_COORDINATE_MARKERS",
  CHANGE_DEFAULT_CENTER_COORDINATES: "CHANGE_DEFAULT_CENTER_COORDINATES",
  SET_STATUS_IN_PRE_CONFIGURED_TRANSFER_FILTERING:
    "SET_STATUS_IN_PRE_CONFIGURED_TRANSFER_FILTERING",

  ADD_TRANSFER_DEVICE_ALIAS_OR_IDENTIFIER:
    "ADD_TRANSFER_DEVICE_ALIAS_OR_IDENTIFIER",

  SET_EVENT_ID_TO_SHOW_MARKERS: "SET_EVENT_ID_TO_SHOW_MARKERS",
  HANDLE_TRANSFER_PRE_CONFIGURED_ID: "HANDLE_TRANSFER_PRE_CONFIGURED_ID",
  SET_MODAL_NAVIGATION_NEXT: "SET_MODAL_NAVIGATION_NEXT",
  SET_MODAL_NAVIGATION_PREVIOUS: "SET_MODAL_NAVIGATION_PREVIOUS",
  SET_MODAL_NAVIGATION_RESET: "SET_MODAL_NAVIGATION_RESET",
  FREQUENCY: "FREQUENCY",
  SET_MODAL_BOND: "SET_MODAL_BOND",
  UPDATE_FROM_CURRENT_TRANSFER: "UPDATE_FROM_CURRENT_TRANSFER",
  UPDATE_TO_CURRENT_TRANSFER: "UPDATE_TO_CURRENT_TRANSFER",
  SELECT_TRANSFER_ID_REPORT: "SELECT_TRANSFER_ID_REPORT",
};

const INITIAL_VALUES = {
  transferId: [],
  modalBond: false,
  currentFrequency: {},
  modalNavigationCount: 0,
  devices: [],
  currentTransfer: null,
  transferFetch: "",
  unityFetch: "",
  eventFetch: "",
  customerUnitIdFetch: "",
  fromFetch: "",
  toFetch: "",
  responsibleFetch: "",
  scheduledDateFetch: dateFormat(new Date(), "yyyy-MM-dd"),
  scheduledDateBeforeEqualDateFetch: subDays(new Date(), 7).setHours(
    0,
    0,
    0,
    0
  ),
  scheduledDateAfterEqualDateFetch: new Date().setHours(0, 0, 0, 0),
  from: "",
  to: "",
  id: "",

  fromDate: "",
  toDate: "",
  scheduleTime: "",
  frequency: "",
  transferSettingsId: "",
  active: true,

  deviceWay: "",
  expectedRoute: "",
  stopPoints: "",
  riskLayers: "",
  transferEvents: [],
  transferEventAnalytics: [],

  showCoordinateMarkers: false,
  defaultCenterCoordinates: null,

  deviceAliasOrIdentifier: {},

  eventIdToShowMarkers: null,

  currentTransferId: "",

  transferPreConfiguredId: [],
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.SELECT_TRANSFER_ID_REPORT:
      return { ...state, transferId: action.payload };
    case Types.SET_MODAL_BOND:
      return { ...state, modalBond: action.payload };
    case Types.FREQUENCY:
      return { ...state, currentFrequency: action.payload };

    case Types.SET_MODAL_NAVIGATION_RESET:
      return { ...state, modalNavigationCount: 0 };

    case Types.SET_MODAL_NAVIGATION_NEXT:
      return { ...state, modalNavigationCount: state.modalNavigationCount + 1 };

    case Types.SET_MODAL_NAVIGATION_PREVIOUS:
      return { ...state, modalNavigationCount: state.modalNavigationCount - 1 };

    case Types.HANDLE_TRANSFER_PRE_CONFIGURED_ID:
      return { ...state, transferPreConfiguredId: action.payload };

    case Types.SET_CURRENT_TRANSFER_ID:
      return { ...state, currentTransferId: action.payload }; //UPDATE_TO_CURRENT_TRANSFER

    case Types.SET_CURRENT_TRANSFER:
      return { ...state, currentTransfer: action.payload };
    case Types.UPDATE_FROM_CURRENT_TRANSFER:
      return {
        ...state,
        currentTransfer: {
          ...state.currentTransfer,
          from: action.payload,
        },
      };
    case Types.UPDATE_TO_CURRENT_TRANSFER:
      return {
        ...state,
        currentTransfer: {
          ...state.currentTransfer,
          to: action.payload,
        },
      };
    case Types.ADD_TRANSFER_FETCH:
      return { ...state, transferFetch: action.transferFetch };
    case Types.ADD_UNITY_FETCH:
      return { ...state, unityFetch: action.unityFetch };
    case Types.ADD_EVENT_FETCH:
      return { ...state, eventFetch: action.eventFetch };
    case Types.ADD_FROM_DATE_FETCH:
      return {
        ...state,
        scheduledDateBeforeEqualDateFetch: action.fromDateFetch,
      };
    case Types.ADD_CUSTOMER_UNIT_ID_FETCH:
      return { ...state, customerUnitIdFetch: action.customerUnitIdFetch };
    case Types.ADD_TO_DATE_FETCH:
      return { ...state, scheduledDateAfterEqualDateFetch: action.toDateFetch };
    case Types.ADD_FROM_FETCH:
      return { ...state, fromFetch: action.fromFetch };
    case Types.ADD_TO_FETCH:
      return { ...state, toFetch: action.toFetch };
    case Types.ADD_RESPONSIBLE_FETCH:
      return { ...state, responsibleFetch: action.responsibleFetch };
    case Types.ADD_SCHEDULED_DATE_FETCH:
      return { ...state, scheduledDateFetch: action.scheduledDateFetch };
    case Types.ADD_FORMFROM:
      return { ...state, from: action.from };
    case Types.ADD_TRANSFER_CONFIGURATION:
      return {
        ...state,
        fromDate: action.fromDate,
        toDate: action.toDate,
        scheduleTime: action.scheduleTime,
        frequency: action.frequency,
      };
    case Types.ADD_FORMTO:
      return { ...state, to: action.to };
    case Types.ADD_IDTOSCRIPTING:
      return { ...state, id: action.id };
    case Types.ADD_SETTINGIDTOSCRIPTING:
      return {
        ...state,
        id: action.id,
        transferSettingsId: action.transferSettingsId,
      };
    case Types.ADD_DEVICE_WAY:
      return { ...state, deviceWay: action.deviceWay };
    case Types.ADD_EXPECTED_ROUTE:
      return { ...state, expectedRoute: action.expectedRoute };
    case Types.ADD_STOP_POINTS:
      return { ...state, stopPoints: action.stopPoints };
    case Types.ADD_RISK_LAYERS:
      return { ...state, riskLayers: action.riskLayers };
    case Types.ADD_TRANSFER_EVENTS:
      return { ...state, transferEvents: action.transferEvents };
    case Types.CHANGE_SHOW_COORDINATE_MARKERS:
      return { ...state, showCoordinateMarkers: action.showCoordinateMarkers };
    case Types.CHANGE_DEFAULT_CENTER_COORDINATES:
      return {
        ...state,
        defaultCenterCoordinates: action.defaultCenterCoordinates,
      };
    case Types.ADD_TRANSFER_EVENT_ANALYTICS:
      return {
        ...state,
        transferEventAnalytics: action.payload,
      };
    case Types.ADD_TRANSFER_DEVICE_ALIAS_OR_IDENTIFIER:
      return {
        ...state,
        deviceAliasOrIdentifier: action.payload,
      };
    case Types.SET_EVENT_ID_TO_SHOW_MARKERS:
      return {
        ...state,
        eventIdToShowMarkers:
          state.eventIdToShowMarkers === action.payload ? null : action.payload,
      };

    case Types.SET_STATUS_IN_PRE_CONFIGURED_TRANSFER_FILTERING:
      return {
        ...state,
        active: action.active,
      };

    default:
      return state;
  }
};

// Actions
export const setStatusInPreConfiguredTransferFiltering = (active) => ({
  type: Types.SET_STATUS_IN_PRE_CONFIGURED_TRANSFER_FILTERING,
  active,
});

export const genericAction = (type, payload) => ({
  type,
  payload,
});

export const setCurrentTransferId = (transferId) => ({
  type: Types.SET_CURRENT_TRANSFER_ID,
  payload: transferId,
});

export const setCurrentTransferAction = (transfer) => ({
  type: Types.SET_CURRENT_TRANSFER,
  payload: transfer,
});

export const addUnityAct = (unityFetch) => ({
  type: Types.ADD_UNITY_FETCH,
  unityFetch,
});

export const addFromaction = (from) => ({
  type: Types.ADD_FORMFROM,
  from,
});

export const addToaction = (to) => ({
  type: Types.ADD_FORMTO,
  to,
});

export const addFromAct = (fromFetch) => ({
  type: Types.ADD_FROM_FETCH,
  fromFetch,
});

export const addToAct = (toFetch) => ({
  type: Types.ADD_TO_FETCH,
  toFetch,
});

export const addResponsibleAct = (responsibleFetch) => ({
  type: Types.ADD_RESPONSIBLE_FETCH,
  responsibleFetch,
});

export const addCustomerUnitAct = (customerUnitIdFetch) => ({
  type: Types.ADD_CUSTOMER_UNIT_ID_FETCH,
  customerUnitIdFetch,
});

export const addDeviceWayaction = (deviceWay) => ({
  type: Types.ADD_DEVICE_WAY,
  deviceWay,
});

export const addExpectedRoutection = (expectedRoute) => ({
  type: Types.ADD_EXPECTED_ROUTE,
  expectedRoute,
});

export const addStopPoints = (stopPoints) => ({
  type: Types.ADD_STOP_POINTS,
  stopPoints,
});

export const addRiskLayers = (riskLayers) => ({
  type: Types.ADD_RISK_LAYERS,
  riskLayers,
});

export const addTransferEvents = (transferEvents) => ({
  type: Types.ADD_TRANSFER_EVENTS,
  transferEvents,
});

export const changeShowCoordinateMarkers = (showCoordinateMarkers) => ({
  type: Types.CHANGE_SHOW_COORDINATE_MARKERS,
  showCoordinateMarkers,
});

export const changeDefaultCenterCoordinates = (defaultCenterCoordinates) => ({
  type: Types.CHANGE_DEFAULT_CENTER_COORDINATES,
  defaultCenterCoordinates,
});

export const addTransferEventAnalytics = (data) => ({
  type: Types.ADD_TRANSFER_EVENT_ANALYTICS,
  payload: data,
});

export const addTransferDeviceAliasOrIdentifier = (data) => ({
  type: Types.ADD_TRANSFER_DEVICE_ALIAS_OR_IDENTIFIER,
  payload: data,
});

export const setEventIdToShowMarkers = (eventId) => ({
  type: Types.SET_EVENT_ID_TO_SHOW_MARKERS,
  payload: eventId,
});

export const transferPersistConfig = {
  key: "transfer",
  storage,
  whiteList: ["transferId"],
  timeout: null,
};
