import React, { useState } from "react";
import { reduxForm, Field } from "redux-form";
import logo from "../../assets/logoUtransfer.svg";
import { FormSubmitBtn, Text, FormRow, Logo, FormInformation } from "./styles";
import LoginField from "./LoginField/index";
import { toast } from "material-react-toastify";
import { forgotPassword } from "../../services/forgotPassword";

const ForgotPasswordForm = ({ openForgotPasswordForm }) => {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);

  const sendEmail = async () => {
    if (!email) {
      return toast.error("Digite o e-mail");
    }

    setLoading((previous) => !previous);

    try {
      await forgotPassword(email);
      toast.success("E-mail enviado com sucesso!");
    } catch (error) {
      toast.error("Erro ao tentar recuperar e-mail");
    } finally {
      setLoading((previous) => !previous);
    }
  };

  return (
    <>
      <Logo src={logo} alt="Logo Utransfer" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          padding: "0px 25px",
          marginBottom: "20px",
        }}
      >
        <FormInformation padding={"0px"}>
          <strong>Esqueceu a senha?</strong>
        </FormInformation>
        <FormInformation padding={"0px"}>
          Não se preocupe, enviaremos um e-mail com as instruções para que você
          altere a sua senha
        </FormInformation>
      </div>

      <form style={{ width: "370px" }}>
        <Field
          name="username"
          className="Form-Field"
          component={LoginField}
          props={{ type: "email", label: "Digite aqui o seu e-mail" }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormSubmitBtn
          disabled={loading}
          onClick={sendEmail}
          type="submit"
          variant="contained"
          fullWidth
          size="large"
        >
          <Text cursor={"pointer"} color={"#000000"}>
            Alterar senha
          </Text>
        </FormSubmitBtn>
        <FormRow onClick={openForgotPasswordForm} margin={"10px 0px"}>
          <Text cursor={"pointer"} color={"#14454E"}>
            Voltar para o login
          </Text>
        </FormRow>
      </form>
    </>
  );
};

export default reduxForm({
  form: "login",
})(ForgotPasswordForm);
