import { areasApi } from "..";
import { appId } from "../../../commons/constants/app";
import { fromAreaInformationTypeApi } from "../mappers/area-information-type.mapper";

export const getAreaInformationTypes = async () => {
  const response = await areasApi.get("/area-information-types", {
    params: {
      application_id: appId,
    },
  });

  return response.data.map(fromAreaInformationTypeApi);
};
