import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 373;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontSize: 18,
  },

  appBar: {

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: -5,
    marginLeft: -25
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `calc(${drawerWidth}px - 98px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    backgroundColor: '#000',
    paddingLeft: 12,
    width: 60,
    height: 60,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: '60vh',
    paddingLeft: 10,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  alertBox: {
    boxShadow: '0px 0px 4px #00000029',
    width: 260,
    padding: 5,
    marginLeft: 75
  },
  alertItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  alertTime: {
    color: '#909090',
    fontSize: 12,
    marginBottom: 5
  },

  ignoreAllNotifications: {
    width: 160,
    height: 32,
    left: 75,
    color: '#fff',
    backgroundColor: '#1CB0CF',
    boxShadow: '10px 10px 10px #00000029',
    borderRadius: 16,
    opacity: 1,
    '&:hover': {
      backgroundColor: '#1CB0CF',
      opacity: 0.8,
    }
  },

  notificationIgnore: {
    width: 89,
    height: 32,
    color: '#fff',
    backgroundColor: '#1CB0CF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 16,
    opacity: 1,
    '&:hover': {
      backgroundColor: '#1CB0CF',
      opacity: 0.8,
    }
  },

  actionContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
  }
}));
