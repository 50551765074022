import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 300;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontSize: 18,
  },
  box: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  appBar: {
    width: "100%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    marginTop: -10,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  appBarAlert: {
    width: `calc(100% - 790px)`,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    marginRight: -25,
    backgroundColor: "#000",
    marginBottom: -121,
    paddingLeft: 3,
    width: 60,
    height: 60,
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: 10,
    height: "60vh",
  },
  drawerAlert: {
    width: 790,
  },
  drawerPaperAlert: {
    width: 790,
    padding: 10,
    height: "60vh",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 10,
    marginBottom: 10,
  },
  eventListContainer: {
    width: 280,
    overflow: "auto",
    paddingLeft: 10,
    paddingRight: 10,
  },
}));
