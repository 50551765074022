import React, { useState, useCallback } from "react";

import { Typography, Tabs, Tab, Button } from "@material-ui/core";
import Sidebar from "../../components/Sidebar/sidebar";
import { Div, Container } from "./styles";
import CustomerUnitTable from "./CustomerUnitTable/customerUnitTable";
import ActivityTable from "./ActivityTable/activityTable";
import CategorieTable from "./CategorieTable/categorieTable";
import ProductTable from "./ProductTable/productTable";
import { Settings as SettingsIcon } from "@material-ui/icons";
import ManageFields from "./ManageFields/index";

const tabsClasses = {
  root: "tabsContainer",
  indicator: "tabsBorderBottomColor",
};

export default function CustomerAreaManagement() {
  const [reportOnScreen, setReportOnScreen] = useState("unity");
  const [manageDialog, setManageDialog] = useState(false);

  const handleClickOpen = () => {
    setManageDialog(true);
  };

  const handleClose = () => {
    setManageDialog(false);
  };

  const onChangeTabs = useCallback(
    (_, newValue) => {
      setReportOnScreen(newValue);
    },
    [setReportOnScreen]
  );

  return (
    <>
      <Div>
        <Sidebar />
        <Container>
          <div className="report-form">
            <Typography gutterBottom variant="h4" className="pageTitle">
              Gestão de Campos
            </Typography>
            <Button onClick={() => handleClickOpen()}>
              <div className="iconConfig">
                <SettingsIcon style={{ fontSize: 15 }} /> Gerenciar Campos
              </div>
            </Button>
          </div>
          <ManageFields close={handleClose} open={manageDialog} />

          <div className="padding-tabs">
            <Tabs
              value={reportOnScreen}
              onChange={onChangeTabs}
              classes={tabsClasses}
            >
              <Tab
                label="Unidades de Negócio"
                value="unity"
                className="tabTitle"
              />

              <Tab label="Atividade" value="activity" className="tabTitle" />

              <Tab label="Categoria" value="category" className="tabTitle" />

              <Tab label="Produto" value="product" className="tabTitle" />
            </Tabs>
          </div>
          <div className="reportTableContainer">
            {reportOnScreen === "unity" && <CustomerUnitTable />}
            {reportOnScreen === "activity" && <ActivityTable />}
            {reportOnScreen === "category" && <CategorieTable />}
            {reportOnScreen === "product" && <ProductTable />}
          </div>
        </Container>
      </Div>
    </>
  );
}
