import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as dateFns from "date-fns";
import { handleGetDriversAvailable } from "../../../services/peopleManagement/driver";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import {
  Sync as SyncIcon,
  ExpandMore as ExpandMoreIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  ModeOutlined as ModeOutlinedIcon,
} from "@mui/icons-material";

import {
  SaveButton,
  Title,
  UpdateDataButton,
  ContainerButton,
  DetoursStopsButton,
  CustomTextField,
  CancelButton,
  Div,
  CustomPaper,
} from "./styles";

import { InputSelectorDriver } from "./AutocompleteDriver";
import { toast } from "material-react-toastify";
import {
  setIncidentsNaturesAction,
  setOccurrencesCategoriesAction,
  setOpenDetourStopSidebar,
} from "../../../store/ducks/scripting";
import { useGoogleMapsKey } from "../../../hooks/useGoogleMapsKey";
import { useLoadScript } from "@react-google-maps/api";
import { PlacesAutocompleteComponent } from "./AutoCompletePlaces";
import transferApi from "../../../services/transferApi";
import * as occurrencesService from "../../../services/occurrences";

import { Form, Formik } from "formik";
import { parseISO, format } from "date-fns";

const libraries = ["places", "drawing", "geometry"];

export default function Sidebar() {
  const currentTransfer = useSelector(
    (state) => state.transfer.currentTransfer
  );
  const open = useSelector((store) => store.scripting.openDetourStopSidebar);
  const accessToken = useSelector((state) => state.auth.token.accessToken);

  const [unity, setUnity] = useState(currentTransfer?.unity || "");
  const [responsible, setResponsible] = useState(
    currentTransfer?.responsible || ""
  );
  const [name, setName] = useState(currentTransfer?.name || "");
  const [scheduledDate, setScheduledDate] = useState(
    currentTransfer?.scheduled_date || ""
  );
  const DEFAULT_RADIUS = 500;
  const [drivers, setDrivers] = useState([]);
  const [driverCurrent, setDriverCurrent] = useState("");
  const [radius, setRadius] = useState(DEFAULT_RADIUS);

  const googleMapsKey = useGoogleMapsKey();

  const today = new Date();
  const twoMonthsAgo = today.setMonth(today.getMonth() - 2);
  const defaultAfterValueRef = useRef(
    dateFns.format(twoMonthsAgo, "yyyy-MM-dd")
  );
  const [afterDate, setAfterDate] = useState(defaultAfterValueRef.current);

  const dispatch = useDispatch();

  const getInitialFormValues = {
    unity: "",
    responsible: "",
    name: "",
    scheduled_date: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    originPlace: "",
    destination: "",
    radius: DEFAULT_RADIUS,
    afterDate: defaultAfterValueRef.current,
  };

  const getDataTable = async () => {
    try {
      const response = await handleGetDriversAvailable();
      setDrivers(response);
    } catch (error) {
      toast.error("Não foi possível carregar os motoristas");
    }
  };

  const bindTogleOpen = (event, value) => {
    setDriverCurrent(value?.name);
  };

  const openDetourStopSidebar = () => {
    dispatch(setOpenDetourStopSidebar());
  };

  let aux = 0;

  const handleScripting = (e) => {
    aux = 1;
    handleRegisterTransfer(e, aux);
  };

  const configureOccurrencesCategories = async () => {
    let occurrencesCategories = null;
    try {
      occurrencesCategories = await occurrencesService.getOccurrencesNatures();
    } catch (error) {
      throw new Error("Failed to fetch occurrences list.");
    }

    dispatch(setOccurrencesCategoriesAction(occurrencesCategories));
  };

  async function handleRegisterTransfer(e, aux) {
    const data = {
      unity: e.unity,
      responsible: e.responsible,
      name: e.name,
      scheduled_date: format(parseISO(e.scheduled_date), "yyyy-MM-dd HH:mm", {
        timeZone: "America/Sao_Paulo",
      }),
      originPlace: e.originPlace,
      destination: e.destination,
      radius: e.radius,
      afterDate: defaultAfterValueRef.current,
    };
  }

  useEffect(() => {
    async function fetchIncidentsNatures() {
      const options = { headers: { Authorization: `Bearer ${accessToken}` } };
      try {
        const response = await transferApi.get("/incidents/natures", options);
        dispatch(setIncidentsNaturesAction(response.data));
      } catch {
        toast.error("Erro ao carregar incidentes");
      }
    }

    fetchIncidentsNatures();
  }, [accessToken, dispatch]);

  useEffect(() => {
    configureOccurrencesCategories();
    getDataTable();
  }, []);

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  if (!isLoaded) {
    return <></>;
  }

  return (
    <Formik
      initialValues={getInitialFormValues}
      onSubmit={(e) => handleScripting(e)}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <>
            <Div open={open}>
              <CustomPaper elevation={5}>
                <Accordion
                  disableGutters
                  defaultExpanded
                  style={{
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="informacao-rota"
                  >
                    <Title>Informações de rota</Title>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomTextField
                      name="unity"
                      onBlur={handleBlur}
                      error={Boolean(errors.unity && touched.unity)}
                      fullWidth
                      label="Unidade"
                      size="small"
                      variant="outlined"
                      type="text"
                      value={values.unity}
                      onChange={handleChange}
                    />
                    <CustomTextField
                      name="responsible"
                      onBlur={handleBlur}
                      error={Boolean(errors.responsible && touched.responsible)}
                      fullWidth
                      label="Responsável"
                      size="small"
                      variant="outlined"
                      type="text"
                      value={values.responsible}
                      onChange={handleChange}
                    />
                    <CustomTextField
                      name="name"
                      onBlur={handleBlur}
                      error={Boolean(errors.name && touched.name)}
                      value={values.name}
                      fullWidth
                      label="Título do evento"
                      size="small"
                      variant="outlined"
                      type="text"
                      onChange={handleChange}
                    />
                    <CustomTextField
                      onBlur={handleBlur}
                      error={Boolean(
                        errors.scheduled_date && touched.scheduled_date
                      )}
                      fullWidth
                      label="Data e Hora"
                      size="small"
                      variant="outlined"
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      value={values.scheduled_date}
                      onChange={handleChange}
                    />
                    <PlacesAutocompleteComponent
                      onBlur={handleBlur}
                      error={Boolean(
                        errors.scheduled_date && touched.scheduled_date
                      )}
                      name="originPlace"
                      typeInput="Local de origem"
                      currentTransfer={currentTransfer?.from || ""}
                      onChange={handleChange}
                      from={"from"}
                    />
                    <PlacesAutocompleteComponent
                      onBlur={handleBlur}
                      error={Boolean(
                        errors.scheduled_date && touched.scheduled_date
                      )}
                      name="destination"
                      typeInput="Local de destino"
                      currentTransfer={currentTransfer?.to || ""}
                      onChange={handleChange}
                    />
                    <InputSelectorDriver
                      defaultValue={driverCurrent}
                      onChange={bindTogleOpen}
                      options={drivers}
                      placeholder="Selecione um motorista"
                    />
                    <DetoursStopsButton
                      size="medium"
                      startIcon={
                        <AddCircleOutlineIcon
                          style={{
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      }
                      onClick={openDetourStopSidebar}
                    >
                      Desvios e Paradas
                    </DetoursStopsButton>
                  </AccordionDetails>
                </Accordion>
                <Accordion disableGutters style={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="parametro-busca"
                  >
                    <Title>Parâmetro da Busca</Title>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <CustomTextField
                      name="radius"
                      onBlur={handleBlur}
                      error={Boolean(errors.radius && touched.radius)}
                      value={values.radius}
                      fullWidth
                      label="Raio de busca"
                      size="small"
                      variant="outlined"
                      type="search"
                      onChange={handleChange}
                    />
                    <CustomTextField
                      onBlur={handleBlur}
                      error={Boolean(errors.afterDate && touched.afterDate)}
                      value={values.afterDate}
                      name="afterDate"
                      fullWidth
                      label="A partir de"
                      size="small"
                      variant="outlined"
                      type="date"
                      onChange={handleChange}
                    />
                    <UpdateDataButton size="small" startIcon={<SyncIcon />}>
                      Atualizar dados
                    </UpdateDataButton>
                  </AccordionDetails>
                </Accordion>
                <ContainerButton>
                  <SaveButton fullWidth type="submit">
                    Salvar roteirização
                  </SaveButton>
                  <CancelButton fullWidth>Cancelar</CancelButton>
                </ContainerButton>
              </CustomPaper>
            </Div>
          </>
        </Form>
      )}
    </Formik>
  );
}
