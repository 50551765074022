const styles = {
    autocompleteField: {
      borderRadius: 50,
      padding: 0,
      flexGrow: 5,
      flexShrink: 0,
      flexBasis: 270,
    }
  }
  
  export default styles