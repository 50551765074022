import React, { useMemo, useState, useEffect, memo } from "react";
import { RiskArea } from "../RiskArea";
import { keyBy, isEqual } from "lodash";
import { getRiskAreas } from "../../../services/areasApi/risk-areas/get-risk-areas";
import { toast } from "material-react-toastify";

const RiskAreasComponent = ({
  riskZoneTypes,
  radius,
  coordinate,
  linestring,
  onMarkerClick: handleMarkerClick,
}) => {
  const [riskAreas, setRiskAreas] = useState([]);

  useEffect(() => {
    (async () => {
      const typeIds = riskZoneTypes.map(({ id }) => id);

      if (typeIds.length === 0 || radius <= 0 || (!coordinate && !linestring)) {
        setRiskAreas([]);

        return;
      }

      const params = {
        typeIds,
        nearby: undefined,
        intersects: undefined,
      };

      if (linestring) {
        params.intersects = {
          linestring,
          radius,
        };
      } else if (coordinate) {
        params.nearby = {
          ...coordinate,
          radius,
        };
      }

      try {
        setRiskAreas(await getRiskAreas(params));
      } catch {
        toast.error("Falha ao tentar obter as areas de risco.");
      }
    })();
  }, [riskZoneTypes, radius, coordinate, linestring]);

  const indexedRiskZoneTypes = useMemo(() => {
    return keyBy(riskZoneTypes, "id");
  }, [riskZoneTypes]);

  return (
    <>
      {riskZoneTypes.length > 0 &&
        riskAreas.map((riskArea) => {
          const type = indexedRiskZoneTypes[Number(riskArea.riskZone?.typeId)];

          return (
            <RiskArea
              key={`risk-area-${riskArea.id}`}
              type={type}
              area={riskArea}
              onMarkerClick={handleMarkerClick}
            />
          );
        })}
    </>
  );
};

export const RiskAreas = memo(RiskAreasComponent, (prevProps, nextProps) => {
  const prevTypeIds = prevProps.riskZoneTypes.map(({ id }) => id);
  const nextTypeIds = nextProps.riskZoneTypes.map(({ id }) => id);

  return (
    isEqual(prevTypeIds, nextTypeIds) &&
    isEqual(prevProps.coordinate, nextProps.coordinate) &&
    prevProps.radius === nextProps.radius &&
    prevProps.linestring === nextProps.linestring &&
    prevProps.onMarkerClick === nextProps.onMarkerClick
  );
});
