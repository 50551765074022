import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Popper from "@material-ui/core/Popper";

export default function App({ checked }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [address, setAddress] = useState("");

  function addFromaction(to) {
    return { type: "ADD_FORMTO", to };
  }

  const handleChange = (value) => {
    setAddress(value);
    dispatch(addFromaction(value));
  };

  const handleSelect = async (value) => {
    setAddress(value);
    //setAnchorEl(value)
    dispatch(addFromaction(value));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          {checked ? (
            <TextField
              {...getInputProps({ placeholder: "Digite o endereço" })}
              variant="outlined"
              id="origin-auto-complete"
              type="search"
              onClick={handleClick}
              onSelect={handleClick}
              style={{ width: "100%", height: 40 }}
              disabled={checked}
              value=""
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <TextField
              {...getInputProps({ placeholder: "Digite o endereço" })}
              variant="outlined"
              id="origin-auto-complete"
              type="search"
              onClick={handleClick}
              onSelect={handleClick}
              style={{ width: "100%", height: 40 }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}

          <Popper
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            style={{
              zIndex: 3,
              width: anchorEl ? anchorEl.clientWidth : 0,
              placement: "left-start",
            }}
            id={id}
            open={open}
            anchorEl={anchorEl}
          >
            {suggestions.slice(0, 3).map((suggestion, index) => {
              const style = {
                height: 25,
                backgroundColor: suggestion.active ? "#06B0CF" : "#fff",
              };

              return (
                <List style={{ width: "100%", marginTop: 0, padding: 0 }}>
                  <ListItem {...getSuggestionItemProps(suggestion, { style })}>
                    <Typography noWrap>{suggestion.description}</Typography>
                  </ListItem>
                </List>
              );
            })}
          </Popper>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
