import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";

export const LegendColor = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: ${(props) => props.color};
`;

const useStyles = makeStyles((theme) => {
  return {
    noticeContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 200,
    },
    containerOverview: {
      backgroundColor: "#efefef",
      padding: "40px 30px",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    mainOverview: {
      padding: "0px 50px",
    },
    mainOverviewTitleText: {
      fontWeight: 600,
      color: "#4C4C4C",
    },
    mainOverviewHeader: {
      width: "100%",
      marginBottom: 35,
      display: "flex",
      justifyContent: "space-between",
    },
    barChartGrid: {
      height: 350,
      background: "#F2F2F2",
      padding: "20px 0px",
    },
    barChartRankingGrid: {
      height: 450,
      background: "#F2F2F2",
      padding: "20px 0px",
      width: "100%",
      marginBottom: "30px",
    },
    barChartTitle: {
      fontWeight: 600,
      color: "#4C4C4C",
      margin: "0px 10px 30px 20px",
    },
    mainOverviewIndicators: {
      marginTop: "100px",
    },
    occurrencesTitleText: {
      fontWeight: 600,
      color: "#4C4C4C",
      marginTop: "50px",
    },
    collapseTitleText: {
      fontWeight: 600,
      color: "#4C4C4C",
      marginBottom: "50px",
    },
    pieChartGrid: {
      display: "flex",
      justifyContent: "space-around",
      padding: "20px 0px",
      flexWrap: "wrap",
    },
    customTooltip: {
      backgroundColor: "#FFFFFF",
      border: "1px solid black",
      borderRadius: 5,
      padding: 5,
    },
    renderActiveShape: {
      marginTop: 100,
    },
    divPieChart: {
      display: "flex",
      flexDirection: "column",
      margin: "20px 10px",
      width: "290px",
      alignItems: "center",
      height: "450px",
    },
    styledTableHead: {
      "& .MuiTableCell-root": {
        position: "relative",
        top: 0,
      },
      "& .MuiTableCell-root::after": {
        content: '""',
        height: "20px",
        width: "2px",
        backgroundColor: `#B1B9C0`,
        position: "absolute",
        right: 0,
      },
      "& .MuiTableRow-root .MuiTableCell-root:last-child::after": {
        display: "none",
      },
    },
    bodyTableCell: {
      fontFamily: "noto-sans-regular",
      fontSize: "0.85rem",
      color: "#000",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      maxWidth: 200,
    },
    tableCellStyled: {
      top: 57,
      fontWeight: 600,
    },
    loadDiv: {
      margin: "20px 0px",
    },
    legendDiv: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
    },
    agroupLegend: {
      display: "flex",
    },
  };
});

export default useStyles;
