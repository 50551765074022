import React from "react";
import { Drawer, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  ContentList,
  EmptyContent,
  Header,
  HeaderCloseIcon,
  HeaderIcon,
  HeaderTitle,
} from "./styles";
import { TextItem } from "./TextItem";
import { DateItem } from "./DateItem";
import { EmailItem } from "./EmailItem";
import { PhoneItem } from "./PhoneItem";
import { Close as CloseIcon } from "@material-ui/icons";
import { closeDynamicDrawer } from "../../store/ducks/monitoringMap";
import { LocationTextItem } from "./LocationItem";

const LIST_ITEM = {
  text: TextItem,
  date: DateItem,
  email: EmailItem,
  phone: PhoneItem,
  location: LocationTextItem,
};

export const DynamicSidebar = () => {
  const dispatch = useDispatch();

  const dynamicDrawer = useSelector(
    (store) => store.monitoringMap.dynamicDrawer
  );

  if (!dynamicDrawer) {
    return (
      <Drawer
        variant="persistent"
        anchor="right"
        open={false}
        PaperProps={{
          style: {
            width: 380,
            height: "60vh",
          },
        }}
      />
    );
  }

  const close = () => {
    dispatch(closeDynamicDrawer());
  };

  const { header, contents } = dynamicDrawer;

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={true}
      PaperProps={{
        style: {
          width: 380,
          height: "60vh",
        },
      }}
    >
      <Header>
        {header.iconUrl && <HeaderIcon src={header.iconUrl} />}
        <HeaderTitle>{header.title}</HeaderTitle>
        <HeaderCloseIcon size="small" onClick={close}>
          <CloseIcon />
        </HeaderCloseIcon>
      </Header>
      {contents.length > 0 ? (
        <ContentList sx={{ width: "100%" }} dense>
          {contents.map((content, index) => {
            const Item = LIST_ITEM[content.type] ?? TextItem;

            return (
              <Item
                key={index}
                label={content.label}
                value={content.value}
                copy={content.copy}
                metadata={content.metadata}
              />
            );
          })}
        </ContentList>
      ) : (
        <EmptyContent>
          <Typography fontSize={18}>Não há dados</Typography>
        </EmptyContent>
      )}
    </Drawer>
  );
};
