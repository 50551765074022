import React, { useRef, useEffect, useState } from "react";
import SharedTable from "../../../components/SharedTable";
import { useDispatch, useSelector } from "react-redux";
import { getPOIs } from "../../../infra/http/poiProvider";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Icon, IconButton } from "@material-ui/core";
import {
  handleOpenModalDeletePoi,
  handleOpenModalEditPoi,
  setPoi,
  setPois,
} from "../../../store/ducks/poi";

const LinkedPOIs = () => {
  const dispatch = useDispatch();

  const customerId = useSelector((store) => store.auth.customer.customer);
  const { pois, search } = useSelector((store) => store.poi);

  const [updateTable, setUpdateTable] = useState(false);

  const sharedTableRef = useRef(null);

  const handleUpdateTable = () => {
    setUpdateTable(!updateTable);
  };

  const executeHandleOpenModalEditPoi = (poi) => {
    dispatch(handleOpenModalEditPoi());
    dispatch(setPoi(poi));
  };

  const executeHandleOpenModalDeletePoi = (poi) => {
    dispatch(handleOpenModalDeletePoi());
    dispatch(setPoi(poi));
  };
  useEffect(() => {
    if (sharedTableRef?.current)
      sharedTableRef.current.fetchRowsData({
        waitTimeForAnotherCall: 1000,
        shouldGoBackToFirstPage: true,
      });
  }, [search, updateTable]);

  const executeHandleGetPois = async () => {
    const response = await getPOIs({ customerId });

    dispatch(setPois(response));
  };

  const fetchListData = async () => {
    const poisData = pois;

    const rowsData = poisData
      .filter((item) =>
        item.customer_poi_type_description
          .toLowerCase()
          .includes(search.toLowerCase())
      )
      .map((row) => {
        return [
          row.id,
          row.customer_poi_type_description,
          row.start_hour,
          row.end_hour,
          <IconButton onClick={() => executeHandleOpenModalEditPoi(row)}>
            <Icon>
              <EditIcon />
            </Icon>
          </IconButton>,
          <IconButton onClick={() => executeHandleOpenModalDeletePoi(row)}>
            <Icon>
              <DeleteIcon />
            </Icon>
          </IconButton>,
        ];
      });

    return {
      rowsData,
    };
  };

  const tableHeaders = [
    "Tipo",
    "Hora de Inicio",
    "Hora de Fim",
    "Editar",
    "Excluir",
  ];

  useEffect(() => {
    executeHandleGetPois();
  }, []);

  useEffect(() => {
    handleUpdateTable();
  }, [pois]);
  return (
    <SharedTable
      cellHeaders={tableHeaders}
      rowsDataFetcher={fetchListData}
      ref={sharedTableRef}
    />
  );
};

export default LinkedPOIs;
