import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  alertBox: {
    padding: 11,
    marginRight: 11,
    borderRight: '1px solid #00000029',
  },
  alertsDescriptions: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 20,
    width: 459,
    marginRight: 30,
    marginBottom: 20
  },
  treatmentText: {
    width: '100%',
  },
  treatmentTextInput: {
    padding: '18.5px 14px !important',
  },
  alertTratar: {
    width: 221,
    height: 32,
    color: '#fff',
    backgroundColor: '#EA6060',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 16,
    opacity: 1,
    marginTop: 10,
    '&:hover': {
      backgroundColor: '#EA6060',
      opacity: 0.8,
    }
  },
}));
