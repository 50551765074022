import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SharedTable from "../../../components/SharedTable";
import constants from "../../../commons/constants";
import * as humanReadableLists from "../../../domain/constants/humanReadableLists";
import { Box, Typography } from "@material-ui/core";
import useStyles from "../EventAnaliticsDashboard/styles";
import { ReportLoader } from "../loader";
import CustomSnackbar from "../../../components/CustomSnackbar";

const EventAnalytics = ({ filters }) => {
  const sharedTableRef = useRef(null);
  const customer = useSelector((state) => state.auth.customer);
  const customerUnit = useSelector((store) => store.customerUnits);
  const { occurence, occurenceLoading } = useSelector(
    (store) => store.reportData
  );
  const classes = useStyles();

  useEffect(() => {
    if (sharedTableRef.current?.fetchRowsData)
      sharedTableRef.current.fetchRowsData();
  }, [occurence]);

  const getEventsAnalyticsRows = (eventsAnalytics) => {
    const rowDataFetch = eventsAnalytics.map((event) => {
      return [
        event.customerUnitId,
        customerUnit[event.customerUnitId],
        humanReadableLists.eventNameObject[event.eventTypeId],
        event.treatmentNotRequired,
        event.treated,
        event.notTreated,
        event.total,
      ];
    });

    return rowDataFetch;
  };

  const tableHeaders = [
    "Unidade de Negócios",
    "Ocorrências",
    "Tratamento Não Exigido",
    "Tratados",
    "Não Tratados",
    "Total",
  ];

  const renderEventAnalytics = () => {
    if (occurence?.length > 0) {
      const formatedRows = getEventsAnalyticsRows(occurence);
      return (
        <SharedTable
          dataFromRedux={formatedRows}
          ref={sharedTableRef}
          cellHeaders={tableHeaders}
          noRowsDataMessage={constants.tables.DEFAULT_NO_ROWS_MESSAGE}
        />
      );
    } else {
      return (
        <Box className={classes.noticeContainer}>
          <Typography> Selecione os filtros e aperte buscar </Typography>
        </Box>
      );
    }
  };

  return <>{occurenceLoading ? <ReportLoader /> : renderEventAnalytics()}</>;
};

export default EventAnalytics;
