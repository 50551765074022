import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "material-react-toastify";
import { updateTransfers } from "../../../services/transferApi";
import { parseISO, format } from "date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import * as deviceApiService from "../../../services/devicesService";
import { handleGetSettings } from "../../../services/customerAreaManagement/setting";
import {
  Divider,
  FormControlLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Typography,
} from "@mui/material";
import { KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon } from "@mui/icons-material";
import { CustomTextField } from "../../TableTransferNew/style";
import {
  CustomButtonSave,
  CustomButtonSaveAndScript,
  CustomGrid,
  StepperFormStatus,
} from "../stylesNew";
import styles from "../../Filter/stylesNew";

function getSteps() {
  return ["Cadastro", "Roteirização", "Vinculação"];
}

export default function FormStatus({ transferData }) {
  const now = format(
    parseISO(transferData.scheduled_date),
    "yyyy-MM-dd'T'HH:mm"
  );
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const steps = getSteps();
  const [unity, setUnity] = useState("");
  const [name, setName] = useState("");
  const [customerActivities, setCustomerActivities] = useState([]);
  const [customerActivitySelected, setCustomerActivitySelected] = useState("");
  const [customerCategories, setCustomerCategories] = useState([]);
  const [customerCategorySelected, setCustomerCategorySelected] = useState("");
  const [customerUnits, setCustomerUnits] = useState([]);
  const [customerUnitSelected, setCustomerUnitSelected] = useState("");
  const [responsible, setResponsible] = useState("");
  const [scheduled_date, setScheduledDate] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [customerProductSelected, setCustomerProductSelected] = useState("");
  const [customerProducts, setCustomerProducts] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [autoStartChecked, setAutoStartChecked] = useState(false);
  const [autoEndChecked, setAutoEndChecked] = useState(false);
  const [configurations, setConfigurations] = useState([]);
  const customerId = useSelector((store) => store.auth.customer.customer);
  const dispatch = useDispatch();
  const history = useHistory();

  let aux = 0;

  useEffect(() => {
    setUnity(transferData.unity);
    setName(transferData.name);
    setResponsible(transferData.responsible);
    setScheduledDate(transferData.scheduled_date);
    setFrom(transferData.from);
    setTo(transferData.to);
    setAutoStartChecked(transferData.automatic_beginning);
    setAutoEndChecked(transferData.automatic_end);
    setCustomerProductSelected(transferData.transfer_product_id);

    const config = async () => {
      const response = await handleGetSettings(customerId);

      setConfigurations(response);
    };

    const loadFormData = async () => {
      setLoadingData(true);
      setCustomerActivities(
        await deviceApiService.getCustomerActivities(customerId)
      );
      setCustomerCategories(
        await deviceApiService.getCustomerCategories(customerId)
      );
      setCustomerUnits(await deviceApiService.getCustomerUnits(customerId));
      setCustomerProducts(
        await deviceApiService.getCustomerProducts(customerId)
      );
      setCustomerActivitySelected(transferData.transfer_activity_id || "");
      setCustomerCategorySelected(transferData.transfer_category_id || "");
      setCustomerUnitSelected(transferData.customer_unit_id || "");
      setCustomerProductSelected(transferData.transfer_product_id || "");
      setLoadingData(false);
    };

    config();
    loadFormData();
  }, []);

  const token = useSelector((store) => store.auth.token.accessToken);

  function addIdToScriptingaction(id) {
    return { type: "ADD_IDTOSCRIPTING", id };
  }

  const handleScripting = (e) => {
    aux = 1;
    handleEditTransfer(e, aux);
  };

  const fieldRequired = (field) => {
    const configuration = configurations.find((n) => n.field == field);

    if (!configuration) {
      return false;
    }

    return configuration.required;
  };

  async function handleEditTransfer(e, aux) {
    e.preventDefault();

    if (!customerUnitSelected && fieldRequired(1))
      return toast.error("Selecione uma Unidade sugerida");
    if (!customerCategorySelected && fieldRequired(2))
      return toast.error("Selecione uma Categoria sugerida");
    if (!customerProductSelected && fieldRequired(3))
      return toast.error("Selecione um Produto sugerido");
    if (!customerActivitySelected && fieldRequired(4))
      return toast.error("Selecione uma Atividade sugerida");

    setSuccess(true);
    const data = {
      unity,
      name,
      responsible,
      scheduled_date: format(parseISO(scheduled_date), "yyyy-MM-dd HH:mm", {
        timeZone: "America/Sao_Paulo",
      }),
      from,
      to,
      automatic_beginning: autoStartChecked,
      automatic_end: autoEndChecked,
      transfer_activity_id: customerActivitySelected || null,
      transfer_category_id: customerCategorySelected || null,
      customer_unit_id: customerUnitSelected || null,
      transfer_product_id: customerProductSelected.toString() || null,
    };

    try {
      await updateTransfers(data, token, transferData.id);
      dispatch(addIdToScriptingaction(transferData.id));

      if (aux === 1) {
        aux = 0;
        history.push("/scripting");
      } else {
        window.location.reload(true);
      }
    } catch (err) {
      alert("Erro ao Editar");
      setSuccess(false);
      console.log(err);
    }
  }

  return (
    <>
      <div id="stepper">
        <Divider style={{ marginTop: 0 }} />
        <StepperFormStatus
          activeStep={activeStep}
          connector={
            <KeyboardArrowRightOutlinedIcon style={{ color: "#C2C2C2" }} />
          }
        >
          {steps.map((label, index) => (
            <Step style={{ color: "#2D9DB2" }} key={label}>
              <StepLabel completed={completed[index]}>
                <Typography style={{ fontWeight: 700 }}>{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </StepperFormStatus>
        <Divider />
      </div>

      <form onSubmit={handleEditTransfer}>
        {!success && !loadingData ? (
          <>
            <Typography style={styles.dialogTitle}>
              Dados do Transfer
            </Typography>
            <Grid
              container
              spacing={1}
              style={{ marginBottom: "5px", flex: 1 }}
            >
              <CustomGrid>
                <FormControlLabel
                  style={{ marginLeft: 0, display: "flex" }}
                  control={
                    <Switch
                      checked={autoStartChecked}
                      onClick={() => setAutoStartChecked(!autoStartChecked)}
                      size="small"
                      color="primary"
                    />
                  }
                  label={
                    <Typography style={styles.automatic}>
                      Inicio Automático
                    </Typography>
                  }
                  labelPlacement="start"
                />
                <FormControlLabel
                  style={{ marginLeft: 0, marginRight: 10 }}
                  control={
                    <Switch
                      checked={autoEndChecked}
                      onClick={() => setAutoEndChecked(!autoEndChecked)}
                      size="small"
                      color="primary"
                    />
                  }
                  label={
                    <Typography style={styles.automatic}>
                      Fim Automático
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </CustomGrid>

              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Evento:
                </Typography>
                <CustomTextField
                  variant="outlined"
                  id="name"
                  type="search"
                  placeholder="Digite nome do Evento"
                  defaultValue={transferData.name}
                  style={{ width: "100%", height: 40 }}
                  size="small"
                  onChange={(e) => setName(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Responsável:
                </Typography>
                <CustomTextField
                  variant="outlined"
                  id="responsible"
                  type="search"
                  placeholder="Digite nome do Responsável"
                  defaultValue={transferData.responsible}
                  style={{ width: "100%", height: 40 }}
                  size="small"
                  onChange={(e) => setResponsible(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Data e Hora:
                </Typography>
                <CustomTextField
                  variant="outlined"
                  id="datetime-local"
                  type="datetime-local"
                  style={{ width: "100%", height: 40 }}
                  defaultValue={now}
                  size="small"
                  onChange={(e) => setScheduledDate(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                onClick={() =>
                  toast.warning(
                    'Para editar o endereço de partida, clique em "salvar e roteirizar".'
                  )
                }
              >
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Local de Partida:
                </Typography>
                <CustomTextField
                  disabled
                  variant="outlined"
                  id="from"
                  type="search"
                  placeholder="Digite local de Partida"
                  defaultValue={transferData.from}
                  style={{ width: "100%", height: 40 }}
                  size="small"
                  onChange={(e) => setFrom(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                onClick={() =>
                  toast.warning(
                    'Para editar o endereço de destino, clique em "salvar e roteirizar".'
                  )
                }
              >
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Local de Destino:
                </Typography>
                <CustomTextField
                  disabled
                  variant="outlined"
                  id="to"
                  placeholder="Digite local de Destino"
                  defaultValue={transferData.to}
                  type="search"
                  style={{ width: "100%", height: 40 }}
                  size="small"
                  onChange={(e) => setTo(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <section style={{ display: "flex", flexDirection: "column" }}>
                {configurations.length > 0 && (
                  <Typography style={styles.otherInformation}>
                    Outras Informações
                  </Typography>
                )}
                <Grid container spacing={1}>
                  {configurations.map((n) => n.field).includes(1) && (
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }} size="small">
                        <Typography
                          style={{ fontWeight: "bold" }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          Unidade:
                        </Typography>
                        <Select
                          style={{ backgroundColor: "#F8F8F8" }}
                          variant="outlined"
                          value={customerUnitSelected}
                          displayEmpty
                          onChange={(event) => {
                            setCustomerUnitSelected(event.target.value);
                          }}
                        >
                          <MenuItem key={"customerUnit"} value="">
                            <em>Selecione uma unidade</em>
                          </MenuItem>
                          {customerUnits.map((customerUnit, index) => (
                            <MenuItem
                              key={"customerUnit-" + customerUnit.id}
                              value={customerUnit.id}
                            >
                              {customerUnit.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {configurations.map((n) => n.field).includes(4) && (
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }} size="small">
                        <Typography
                          style={{ fontWeight: "bold" }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          Atividade:
                        </Typography>
                        <Select
                          style={{ backgroundColor: "#F8F8F8" }}
                          variant="outlined"
                          value={customerActivitySelected}
                          displayEmpty
                          onChange={(event) => {
                            setCustomerActivitySelected(event.target.value);
                          }}
                        >
                          <MenuItem key={"activity"} value="">
                            <em>Selecione uma atividade</em>
                          </MenuItem>
                          {customerActivities.map((activity, index) => (
                            <MenuItem
                              key={"activity-" + index}
                              value={activity.id}
                            >
                              {activity.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {configurations.map((n) => n.field).includes(2) && (
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }} size="small">
                        <Typography
                          style={{ fontWeight: "bold" }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          Categorias:
                        </Typography>
                        <Select
                          style={{ backgroundColor: "#F8F8F8" }}
                          variant="outlined"
                          value={customerCategorySelected}
                          displayEmpty
                          onChange={(event) => {
                            setCustomerCategorySelected(event.target.value);
                          }}
                        >
                          <MenuItem key={"category"} value="">
                            <em>Selecione uma categoria</em>
                          </MenuItem>
                          {customerCategories.map((category, index) => (
                            <MenuItem
                              key={"category-" + index}
                              value={category.id}
                            >
                              {category.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {configurations.map((n) => n.field).includes(3) && (
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }} size="small">
                        <Typography
                          style={{ fontWeight: "bold" }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          Produto:
                        </Typography>
                        <Select
                          style={{ backgroundColor: "#F8F8F8" }}
                          variant="outlined"
                          value={customerProductSelected}
                          displayEmpty
                          onChange={(event) => {
                            setCustomerProductSelected(event.target.value);
                          }}
                        >
                          <MenuItem key={"product"} value="">
                            <em>Selecione um produto</em>
                          </MenuItem>
                          {customerProducts.map((product, index) => (
                            <MenuItem
                              key={"product-" + index}
                              value={product.id}
                            >
                              {product.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </section>
            </Grid>

            <CustomButtonSave
              type="submit"
              autoFocus
              variant="outlined"
              color="primary"
            >
              <Typography fontSize={10}>Salvar</Typography>
            </CustomButtonSave>
            <CustomButtonSaveAndScript
              onClick={(e) => handleScripting(e)}
              variant="contained"
              autoFocus
              color="primary"
            >
              <Typography fontSize={10} style={styles.saveAndScript}>
                Salvar e Roteirizar
              </Typography>
            </CustomButtonSaveAndScript>
          </>
        ) : (
          <>
            <center>
              <div>
                <CircularProgress
                  size={68}
                  style={{ marginTop: 0, color: "#63a9ef" }}
                ></CircularProgress>
              </div>
              <span>{loadingData ? "Carregando dados" : "Salvando"}</span>
            </center>
          </>
        )}
      </form>
    </>
  );
}
