import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Item from "./styles";

const InputSelectorDriver = ({
  placeholder,
  options = [],
  defaultValue = "",
  getOptionLabel = (option) => option?.name,
  onChange = () => {},
  disable,
  unbindDialog,
}) => {
  return (
    <Autocomplete
      disabled={disable}
      options={options}
      inputValue={defaultValue}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      style={{ width: "350px" }}
      renderInput={(params) => (
        <Item
          fullWidth
          {...params}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <IconButton>
                <CloseIcon onClick={unbindDialog} />
              </IconButton>
            ),
          }}
        />
      )}
    />
  );
};

export default InputSelectorDriver;
