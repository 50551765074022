import devicesApi from "../devices";

export const handleGetSettings = async (customerId) => {
  try {
    const response = await devicesApi.get(
      `/customer/${customerId}/transfer/form/configurations`
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handlePostSetting = async (customerId, data) => {
  try {
    const response = await devicesApi.post(
      `/customer/${customerId}/transfer/form/configurations`,
      data
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};
