import React, { useEffect, useRef } from "react";
import woop from "../../assets/audio/woop-woop.mp3";

export default function AudioLoop() {
  const alertRef = useRef(null);

  useEffect(() => {
    const alert = new Audio(woop);
    alertRef.current = alert;

    const handleEnded = () => {
      alertRef.current.currentTime = 0;
      alertRef.current.play();
    };

    alert.addEventListener("ended", handleEnded);
    alert.play();

    return () => {
      alertRef.current.pause();
      alertRef.current.removeEventListener("ended", handleEnded);
    };
  }, []);

  return null;
}
