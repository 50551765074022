import devicesAPI from "./devices";

export const getCustomerDevices = async (customerId) => {
  const response = await devicesAPI.get(`/customer/${customerId}/devices`);
  return response.data;
};

export const getOneCustomerDevice = async (customerId, id) => {
  const response = await devicesAPI.get(
    `/customer/${customerId}/devices/${id}`
  );
  return response.data;
};

export const getCustomerActivities = async (customerId) => {
  const response = await devicesAPI.get(`customer/${customerId}/activities`);
  return response.data;
};

export const getCustomerCategories = async (customerId) => {
  const response = await devicesAPI.get(`customer/${customerId}/categories`);
  return response.data;
};

export const getCustomerUnits = async (customerId) => {
  const response = await devicesAPI.get(`customer/${customerId}/units`);
  return response.data;
};

export const getCustomerProducts = async (customerId) => {
  const response = await devicesAPI.get(`customer/${customerId}/products`);
  return response.data;
};

export const handleGetAllDevicesUtrack = async (customerId) => {
  const { data } = await devicesAPI.get(`/devices/${customerId}/utrack`);
  return data;
};
