export const types = {
  ENABLE_ADVANCED_MONITORING: "ENABLE_ADVANCED_MONITORING",
  DISABLE_ADVANCED_MONITORING: "DISABLE_ADVANCED_MONITORING",
  SET_DEVICE_LOCATION: "SET_DEVICE_LOCATION",
};

const INITIAL_VALUES = {
  deviceId: null,
  deviceLocation: null,
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.ENABLE_ADVANCED_MONITORING: {
      const { deviceId, deviceLocation } = action.payload;

      return {
        ...state,
        deviceId,
        deviceLocation,
      };
    }

    case types.DISABLE_ADVANCED_MONITORING: {
      return {
        ...state,
        deviceId: null,
        deviceLocation: null,
      };
    }

    case types.SET_DEVICE_LOCATION: {
      return {
        ...state,
        deviceLocation: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const enableAdvancedMonitoring = ({ deviceId, deviceLocation }) => ({
  type: types.ENABLE_ADVANCED_MONITORING,
  payload: {
    deviceId,
    deviceLocation,
  },
});

export const disableAdvancedMonitoring = () => ({
  type: types.DISABLE_ADVANCED_MONITORING,
});

export const setDeviceLocation = ({ lat, lng }) => ({
  type: types.SET_DEVICE_LOCATION,
  payload: {
    lat,
    lng,
  },
});
