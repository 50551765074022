import React, { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { List, ListItem, TextField, Popper } from '@material-ui/core';

export const AutoCompleteInput = ({ value, setValue, label }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <PlacesAutocomplete value={value} onChange={setValue}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ width: '95%' }}>
          <TextField {...getInputProps()}
            label={label || ""}
            variant="outlined"
            id="origin-auto-complete"
            type="search"
            placeholder="Endereço"
            onClick={handleClick}
            style={{ height: 40, width: '100%' }}
            size="small"
          />
          <Popper anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} style={{ zIndex: 1000000000000, placement: 'left-start' }} id={id} open={open} anchorEl={anchorEl} >
            {suggestions.map((suggestion, index) => {
              const style = {
                height: 25,
                backgroundColor: suggestion.active ? "#06B0CF" : "#fff"
              }
              if (index <= 4) {
                return (
                  <List style={{ marginTop: 0, padding: 0, zIndex: 10 }}>
                    <ListItem {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </ListItem>
                  </List>
                );
              } else {
                return null
              }
            })}
          </Popper>
        </div>
      )}
    </PlacesAutocomplete>
  );
}