import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import { useScriptingStyles } from "./styles";
import { ScriptingSidebar } from "./ScriptingSidebar";
import { ScriptingMap } from "./ScriptingMap";
import transferApi from "../../services/transferApi";
import { setCurrentTransferAction } from "../../store/ducks/transfer";
import { setWaypointsAction } from "../../store/ducks/scripting";

export const Scripting = () => {
  const classes = useScriptingStyles();
  const [loading, setLoading] = useState(true);
  const { id: transferId } = useSelector((state) => state.transfer);
  const { token } = useSelector((state) => state.auth);
  const scriptingWaypoints = useSelector((state) => state.scripting.waypoints);
  const history = useHistory();
  const dispatch = useDispatch();

  const mapToGoogleMapsWaypoint = (waypoint) => {
    return {
      id: waypoint.id,
      location: waypoint.address,
      stopover: waypoint.type === "STOP_POINT",
      title: waypoint.name,
      duration: waypoint.stopping_time,
      position: {
        latitude: waypoint.position.lat,
        longitude: waypoint.position.lng,
      },
    };
  };

  useEffect(() => {
    if (!transferId) return history.push("/transfers");

    const headers = { Authorization: `Bearer ${token.accessToken}` };
    const getTransferWaypoints = async () => {
      try {
        const { data } = await transferApi.get(`/transfers/${transferId}`, {
          headers,
        });
        const formattedScheduledDate = data.scheduled_date
          .trim()
          .replace(/\s/g, "T");
        const transferData = {
          ...data,
          scheduled_date: formattedScheduledDate,
        };

        dispatch(setCurrentTransferAction(transferData));

        if (scriptingWaypoints.length === 0) {
          const [route] = transferData.routes;
          let waypoints = [];

          if (route) {
            waypoints = route.waypoints_backup
              .sort((a, b) => {
                return a.order > b.order;
              })
              .map(mapToGoogleMapsWaypoint);
          }
          dispatch(setWaypointsAction(waypoints));
        }
        setLoading(false);
      } catch (error) {
        console.error("erro", error);
        setLoading(false);
        return history.push("/transfers");
      }
    };
    getTransferWaypoints();
  }, [transferId, token]);

  useEffect(() => {
    return () => {
      dispatch(setWaypointsAction([]));
    };
  }, [dispatch]);

  if (loading) return <h1>Carregando...</h1>;

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          <ScriptingSidebar />
        </Drawer>
      </nav>
      <ScriptingMap />
    </div>
  );
};
