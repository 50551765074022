import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  GoogleMap,
  TrafficLayer,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Div } from "./styles";
import ActiveIncidents from "./activeIncidents";
import RiskZones from "./riskZones";
import VirtualFence from "./virtualFence";
import DevicesWay from "./deviceWay";
import LastPositionsWay from "./lastPositionsWay";
import TransferRoutesPoints from "./transferRoutesPoints";
import TransferRoute from "./transferRoute";
import AlertPin from "./alertPin";
import ChangeMapType from "./changeMapType";
import { useDispatch } from "react-redux";
import {
  changeMapStylesAction,
  openMapStyleDrawerAction,
} from "../../store/ducks/monitoringMap";
import DeviceMarker from "./deviceMarker";
import { useGoogleMapsKey } from "../../hooks/useGoogleMapsKey";
import { AdvancedMonitoring } from "./AdvancedMonitoring";

const libraries = ["places", "drawing", "geometry"];

export default function Map({ children }) {
  const googleMapsKey = useGoogleMapsKey();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  const [map, setMap] = useState(null);

  const zoom = useSelector((store) => store.monitoringMap.zoom);
  const centerMap = useSelector((store) => store.monitoringMap.centerMap);
  const POIs = useSelector((store) => store.monitoringMap.POIs);
  const trafficLayer = useSelector((store) => store.monitoringMap.trafficLayer);
  const mapType = useSelector((store) => store.monitoringMap.mapType);
  const dispatch = useDispatch();
  const maxOpenMapMonitoring = useSelector(
    (state) => state.monitoringMap.maxOpenMapMonitoring
  );

  const deviceTransfers = useSelector((store) => store.monitoringMap.devices);
  const identifiersSelected = useSelector(
    (store) => store.monitoringMap.devicesIdsSelected
  );

  const deviceTransfersHashtable = useMemo(() => {
    const deviceTransfersHashtable = {};

    for (const deviceTransfer of deviceTransfers) {
      const key = deviceTransfer?.device?.id ?? null;
      if (key !== null) deviceTransfersHashtable[key] = deviceTransfer;
    }

    return deviceTransfersHashtable;
  }, [deviceTransfers]);

  const handleClickChange = (mapType) => {
    dispatch(changeMapStylesAction(mapType));
    dispatch(openMapStyleDrawerAction());
  };

  const mapsOptions = {
    mapTypeId: mapType,
    zoomControl: false,
    fullscreenControlOptions: "BOTTOM_LEFT",
    streetViewControl: false,
    draggableCursor: null,
    disableDefaultUI: true,
    styles: [
      {
        featureType: "poi",
        stylers: [
          {
            visibility: `${POIs}`,
          },
        ],
      },
      {
        featureType: "transit.station",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#c9c9c9",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
    ],
  };

  return (
    <Div>
      {isLoaded && (
        <GoogleMap
          id="direction"
          mapContainerStyle={{
            height: maxOpenMapMonitoring ? "93vh" : "60vh",
            transition: "all 0.3s",
            width: "100%",
          }}
          options={mapsOptions}
          zoom={zoom}
          center={centerMap}
          onLoad={(map) => setMap(map)}
        >
          {children}
          {trafficLayer ? <TrafficLayer /> : null}
          {identifiersSelected
            .filter((deviceId) => deviceTransfersHashtable[deviceId]?.device)
            .map((deviceId) => {
              return (
                <DeviceMarker
                  key={`DeviceMarker-${deviceId}`}
                  device={deviceTransfersHashtable[deviceId].device}
                />
              );
            })}
          <DevicesWay />
          <LastPositionsWay />
          <ActiveIncidents />
          <RiskZones />
          <VirtualFence />
          <TransferRoutesPoints />
          <TransferRoute />
          <AlertPin />
          <AdvancedMonitoring map={map} />
          <ChangeMapType onMapTypeChange={handleClickChange} />
        </GoogleMap>
      )}
    </Div>
  );
}
