import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  divButton: {
    display: "flex",
    marginTop: "15px",
    marginLeft: "20px",
    gap: "15px",
  },
  txtButton: {
    width: "150px",
    height: "45px",
    fontSize: "0.875rem",
    textTransform: "none",
    color: "white",
    backgroundColor: "#06b0cf",
    "&:hover": {
      backgroundColor: "#06b0cf",
    },
  },
  saveButtonCreateDialog: {
    backgroundColor: "#06B0CF!important",
    color: "#ffffff",
    borderRadius: 8,
    boxShadow: "0px 3px 6px #00000029!important",
  },
  cancelButtonCreateDialog: {
    color: "#06B0CF",
    borderRadius: 8,
    border: "none!important",
    boxShadow: "0px 3px 6px #00000029",
  },
  divFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
}));
