import React from 'react'

import Loading from '../Loading'
import styles from './styles'

const CentralizedLoading = ({
  color = undefined,
  size = undefined,
  text = undefined,
}) => {
  return (
    <div style={styles.centralizedLoading}>
      <Loading
        color={color}
        size={size}
        text={text}
      />
    </div>
  )
}

export default CentralizedLoading