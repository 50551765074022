import axios from "axios";

import { store } from "../store";

const utransferV2API = axios.create({
  baseURL: process.env.REACT_APP_UTRANSFER_V2_API,
});

utransferV2API.interceptors.request.use((config) => {
  const state = store.getState();
  const accessToken = state?.auth?.token?.accessToken;

  if (accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;

  return config;
});

export default utransferV2API;
