import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

h1 {
  margin-top: 35px;
  margin-bottom: 35px;
  margin-left: 27px;
}

section {
  display: flex;
  padding: 10px;
  margin-left: 300px;
}

.btnfilter {
  margin-top: -42px;
  margin-left: 1200px;
}

.table {
  margin-left: 100px;
}

.btnregister {
  margin-top: -55px;
  margin-left: 80px;
}
`;
