export const typesCustomerCategories = {
  CUSTOMER_CATEGORIES: "CUSTOMER_CATEGORIES",
};

const INITIAL_VALUES_CUSTOMER_CATEGORIES = {};

export const customerCategories = (
  state = INITIAL_VALUES_CUSTOMER_CATEGORIES,
  action
) => {
  if (action.type == typesCustomerCategories.CUSTOMER_CATEGORIES) {
    const categories = {};
    action.payload.map((unit) => {
      categories[unit.id] = unit.name;
    });
    return categories;
  } else {
    return { ...state };
  }
};

export const fillCustomerCategoriesAction = (customerCategories) => ({
  type: typesCustomerCategories.CUSTOMER_CATEGORIES,
  payload: customerCategories,
});
