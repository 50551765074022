export const styles = {
  text: {
    fontSize: 8,
  },
  stepText: {
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  colCentralized: {
    alignItems: "center",
    justifyContent: "center",
  },
  colVerticallyCentered: {
    alignItems: "center",
  },
};
