import { subDays, parse as parseDate } from "date-fns";

export const types = {
  CHANGE_TO_IDENTIFIER: "CHANGE_TO_IDENTIFIER",
  CHANGE_TO_UNITY: "CHANGE_TO_UNITY",
  CHANGE_TO_OCCURRENCES: "CHANGE_TO_OCCURRENCES",
  CHANGE_TO_GASES: "CHANGE_TO_GAS",
  CHANGE_TO_EVENT: "CHANGE_TO_EVENT",
  CHANGE_TO_DASHBOARD: "CHANGE_TO_DASHBOARD",
  CHANGE_TO_TELEMETRY_DASHBOARD: "CHANGE_TO_TELEMETRY_DASHBOARD",
  CHANGE_TO_REPORT_DEVICE: "CHANGE_TO_REPORT_DEVICE",
  CLEAN_FIELDS: "CLEAN_FIELDS",
  CHANGE_TO_DATE_FROM: "CHANGE_TO_DATE_FROM",
  CHANGE_TO_DATE_TO: "CHANGE_TO_DATE_TO",
  HANDLE_REQUEST: "HANDLE_REQUEST",
  GENERIC_TYPE: "GENERIC_TYPE",
};

const INITIAL_VALUES = {
  disableIdentifier: true,
  disableUnity: true,
  disableOccurrences: true,
  disableEvent: true,
  disableCustomerUnit: true,
  disableCustomerCategory: true,
  disableCustomerActivity: true,
  disableProduct: true,
  disableReportDevice: true,
  cleanFields: false,
  tabSelected: "",
  fromDate: parseDate("00:00:00", "HH:mm:ss", subDays(new Date(), 7)),
  toDate: parseDate("23:59:59", "HH:mm:ss", new Date()),
  handleRequest: false,
  page: 1,
  perPage: 5,
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.GENERIC_TYPE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.HANDLE_REQUEST:
      return {
        ...state,
        handleRequest: !state.handleRequest,
      };
    case types.CHANGE_TO_IDENTIFIER:
      return {
        ...state,
        disableIdentifier: false,
        disableUnity: true,
        disableOccurrences: true,
        disableEvent: true,
        disableCustomerUnit: true,
        disableCustomerCategory: true,
        disableCustomerActivity: true,
        disableProduct: true,
        tabSelected: "identifierTabSelected",
      };
    case types.CHANGE_TO_REPORT_DEVICE:
      return {
        ...state,
        tabSelected: "reportDevice",
        disableIdentifier: true,
        disableUnity: true,
        disableOccurrences: true,
        disableEvent: true,
        disableCustomerUnit: true,
        disableCustomerCategory: true,
        disableCustomerActivity: true,
        disableProduct: true,
        disableReportDevice: true,
      };
    case types.CHANGE_TO_UNITY:
      return {
        disableIdentifier: true,
        disableUnity: false,
        disableOccurrences: true,
        disableEvent: false,
        disableCustomerUnit: false,
        disableCustomerCategory: false,
        disableCustomerActivity: false,
        disableProduct: false,
        tabSelected: "unityTabSelected",
      };
    case types.CHANGE_TO_OCCURRENCES:
      return {
        ...state,
        disableCustomerUnit: false,
        disableIdentifier: true,
        disableUnity: true,
        disableOccurrences: true,
        disableEvent: true,
        disableCustomerCategory: true,
        disableCustomerActivity: true,
        disableProduct: true,
        disableReportDevice: true,
        tabSelected: "occurenceTabSelected",
      };
    case types.CHANGE_TO_GASES:
      return {
        ...state,
        disableCustomerUnit: true,
        disableIdentifier: true,
        disableUnity: true,
        disableOccurrences: true,
        disableEvent: true,
        disableCustomerCategory: true,
        disableCustomerActivity: true,
        disableProduct: true,
        disableReportDevice: true,
        tabSelected: "gasTabSelected",
      };
    case types.CHANGE_TO_TELEMETRY_DASHBOARD:
      return {
        ...state,
        disableCustomerUnit: true,
        disableIdentifier: true,
        disableUnity: true,
        disableOccurrences: true,
        disableEvent: true,
        disableCustomerCategory: true,
        disableCustomerActivity: true,
        disableProduct: true,
        disableReportDevice: true,
        tabSelected: "telemetryDashboardTabSelected",
      };
    case types.CHANGE_TO_DASHBOARD:
      return {
        ...state,
        disableCustomerUnit: false,
        disableIdentifier: true,
        disableUnity: true,
        disableOccurrences: true,
        disableEvent: true,
        disableCustomerCategory: true,
        disableCustomerActivity: true,
        disableProduct: true,
        disableReportDevice: true,
        tabSelected: "dashboardTabSelected",
      };
    case types.CHANGE_TO_EVENT:
      return {
        disableIdentifier: true,
        disableUnity: true,
        disableOccurrences: false,
        disableEvent: false,
        disableCustomerUnit: false,
        disableCustomerCategory: false,
        disableCustomerActivity: false,
        disableProduct: false,
        tabSelected: "eventReportTabSelected",
      };
    case types.CLEAN_FIELDS:
      return {
        ...state,
        cleanFields: action.payload,
      };
    case types.CHANGE_TO_DATE_FROM:
      return {
        ...state,
        fromDate: action.payload,
      };
    case types.CHANGE_TO_DATE_TO:
      return {
        ...state,
        toDate: action.payload,
      };
    default:
      return { ...state };
  }
};

export const changeToIndentifierTabAction = () => ({
  type: types.CHANGE_TO_IDENTIFIER,
});

export const changeToUnityTabAction = () => ({
  type: types.CHANGE_TO_UNITY,
});

export const changeToOccurrencesTabAction = () => ({
  type: types.CHANGE_TO_OCCURRENCES,
});

export const changeToGasTabAction = () => ({
  type: types.CHANGE_TO_GASES,
});

export const changeToDashboardTabAction = () => ({
  type: types.CHANGE_TO_DASHBOARD,
});

export const changeToTelemetryDashboardTabAction = () => ({
  type: types.CHANGE_TO_TELEMETRY_DASHBOARD,
});

export const changeToReportDeviceTabAction = () => ({
  type: types.CHANGE_TO_REPORT_DEVICE,
});

export const changeDateFrom = (date) => ({
  type: types.CHANGE_TO_DATE_FROM,
  payload: date,
});
export const changeDateTo = (date) => ({
  type: types.CHANGE_TO_DATE_TO,
  payload: date,
});

export const handleRequest = () => ({
  type: types.HANDLE_REQUEST,
});

export const changeToEventTabAction = () => ({
  type: types.CHANGE_TO_EVENT,
});

export const cleanFieldsAction = (value) => ({
  type: types.CLEAN_FIELDS,
  payload: value,
});
