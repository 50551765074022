import React from "react";
import StepperDevices from "./stepperDevicesNew";
import { getTransferStatus } from "./utils/transfers";
import transferConstants from "../../../domain/constants/transfers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTransferId } from "../../../store/ducks/transfer";
import { useState } from "react";
import { useStyles } from "./modalBondNewStyle";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { ButtonPersonIcon, CloseIcon, PersonIcon } from "../stylesNew";

export default function ModalBond({ transferData, onSuccess }) {
  const classes = useStyles();
  const currentTransferId = useSelector(
    (state) => state.transfer.currentTransferId
  );

  const dispatch = useDispatch();
  const openModalBond = useSelector((store) => store.transfer.modalBond);

  const [openModal, setOpenModal] = useState(openModalBond);

  const handleModalClose = () => {
    setOpenModal(false);

    if (onSuccess) {
      onSuccess();
    }

    dispatch(setCurrentTransferId(""));
  };

  useEffect(() => {
    if (currentTransferId === transferData.id) {
      setOpenModal(true);
    }
  }, [currentTransferId, transferData.id]);

  const status = getTransferStatus(transferData?.status);
  const numberAssociatedDevices = transferData?.associated_devices
    ? transferData?.associated_devices?.length
    : transferData?.pre_configured_transfer_settings?.device_ids?.length;

  if (
    [
      transferConstants?.status.CANCELED,
      transferConstants?.status.SCRIPTED,
    ]?.includes(status)
  ) {
    return (
      <>
        <ButtonPersonIcon
          onClick={() => {
            setOpenModal(true);
          }}
          className={classes.button}
          color="primary"
          endIcon={<PersonIcon />}
        >
          <Typography
            style={{
              color: "#000",
              fontWeight: "bold",
            }}
            variant="button"
            display="block"
            gutterBottom
          >
            {numberAssociatedDevices}
          </Typography>
        </ButtonPersonIcon>

        <Dialog
          fullWidth
          maxWidth={"sm"}
          style={{ zIndex: 2 }}
          onClose={handleModalClose}
          open={openModal}
        >
          <DialogTitle id="dialog-devices" className={classes.dialogTitle}>
            <Typography
              fontWeight={700}
              style={{ padding: "12px 0px 0px 12px" }}
            >
              Transfer | <strong>Vínculos</strong> | {transferData.name}
            </Typography>
            <Button onClick={handleModalClose}>
              <CloseIcon fontSize="medium" />
            </Button>
          </DialogTitle>
          <DialogContent style={{ padding: "12px" }}>
            <StepperDevices
              onClose={handleModalClose}
              transferData={transferData}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }

  if (
    numberAssociatedDevices > 0 ||
    [
      transferConstants.status.ONGOING,
      transferConstants.status.ENDED,
      transferConstants.status.NOT_SCRIPTED,
    ].includes(status)
  ) {
    return (
      <ButtonPersonIcon
        disabled
        variant="outlined"
        color="primary"
        endIcon={<PersonIcon />}
      >
        <Typography
          style={{ fontWeight: "bold" }}
          variant="button"
          display="block"
          gutterBottom
        >
          {numberAssociatedDevices}
        </Typography>
      </ButtonPersonIcon>
    );
  }

  return null;
}
