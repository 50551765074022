import { makeStyles } from "@material-ui/styles";
import { Button, styled as styledMUI } from "@mui/material";

export const ButtonPersonIcon = styledMUI(Button)(() => ({
  width: 10,
  borderRadius: 12,
  boxShadow: "0px 3px 6px #00000029",
  border: "none",
}));

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    width: 70,
    height: 36,
    boxShadow: "0px 0px 10px 0px #00000026",
    borderRadius: "22px",
    backgroundColor: "white",
  },
  dialogTitle: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
  },
}));
