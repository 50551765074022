import React, { useEffect, useState } from "react";
import {
  ContainerDescription,
  CustomPaper,
  Div,
  OrganizeButton,
  SidebarTitle,
  Text,
} from "./styles";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DehazeOutlinedIcon from "@mui/icons-material/DehazeOutlined";

import { useDispatch, useSelector } from "react-redux";
import { IconButton, InputAdornment } from "@mui/material";
import {
  ScriptingActiontypes,
  setOpenDetourStopSidebar,
} from "../../../store/ducks/scripting";
import { CardDetourStop } from "./CardDetourStop";
import { PlacesAutocompleteComponent } from "./AutocompletePlaceSidebarDetourStop";

import _isEqual from "lodash/isEqual";
import { genericAction } from "../../../store/ducks/transfer";

export const SidebarDetourStop = () => {
  const dispatch = useDispatch();
  const open = useSelector((store) => store.scripting.openDetourStopSidebar);
  const originalAddressData = useSelector(
    (state) => state.scripting.addressCard
  );
  const [onOrganize, setOnOrganize] = useState(true);
  const [activeDraggable, setActiveDraggable] = useState(false);
  const [address, setAddress] = useState([]);
  const [originalAddress, setOriginalAddress] = useState([]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const newAdress = [...address];

    const storeSourceIndex = source.index;
    const storeDestinationIndex = destination.index;

    const [removedStore] = newAdress.splice(storeSourceIndex, 1);
    newAdress.splice(storeDestinationIndex, 0, removedStore);
    setAddress(newAdress);
  };

  const handleSaveOrganize = () => {
    if (activeDraggable) {
      dispatch(genericAction(ScriptingActiontypes.REORGANIZE_CARD, address));
      setActiveDraggable(false);
      return;
    }
    setActiveDraggable(false);
  };

  useEffect(() => {
    setAddress(originalAddressData);
    setOriginalAddress(address);
  }, [originalAddress, originalAddressData]);

  useEffect(() => {
    if (originalAddressData.length > 1) return setOnOrganize(false);

    return setOnOrganize(true);
  }, [originalAddressData]);

  const backToMainSidebar = () => {
    if (address === originalAddress) {
      return dispatch(setOpenDetourStopSidebar());
    }
    setActiveDraggable(false);

    setAddress(originalAddressData);
    dispatch(setOpenDetourStopSidebar());
  };

  return (
    <Div open={open}>
      <CustomPaper elevation={5}>
        <SidebarTitle>
          <IconButton
            onClick={backToMainSidebar}
            style={{
              color: "black",
              marginLeft: "10px",
            }}
          >
            <ArrowBackIcon
              style={{
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
          <Text>Desvios e Paradas</Text>
        </SidebarTitle>
        <ContainerDescription>
          <Text>Adicionar parada e/ou desvio?</Text>
          <Text customInfo="infoDescription">
            Informe o endereço do local do desvio ou parada, você também pode
            marcar no mapa.
          </Text>
          {!activeDraggable ? (
            <OrganizeButton
              disableRipple
              disabled={onOrganize}
              size="small"
              onClick={() => setActiveDraggable((previous) => !previous)}
              startIcon={
                <DehazeOutlinedIcon style={{ height: "30px", width: "30px" }} />
              }
            >
              Organizar
            </OrganizeButton>
          ) : (
            <OrganizeButton
              disableRipple
              disabled={onOrganize}
              size="small"
              onClick={handleSaveOrganize}
              startIcon={
                <DehazeOutlinedIcon style={{ height: "30px", width: "30px" }} />
              }
            >
              Confirmar
            </OrganizeButton>
          )}
        </ContainerDescription>
        <PlacesAutocompleteComponent />
        {activeDraggable ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="ROOT">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {address.map((address, index) => (
                    <Draggable
                      key={address.id}
                      draggableId={address.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <CardDetourStop
                            orderAlphabet={index + 1}
                            onDraggable={activeDraggable}
                            {...address}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div>
            {address.map((address, index) => {
              return <CardDetourStop orderAlphabet={index + 1} {...address} />;
            })}
          </div>
        )}
      </CustomPaper>
    </Div>
  );
};
