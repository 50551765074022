import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "material-react-toastify";
import {
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
} from "@material-ui/core";
import { ExpandMore, Room, Delete } from "@material-ui/icons";
import { remove } from "lodash";
import {
  setWaypointsAction,
  updateWaypointAction,
  removeWaypointByIdAction,
  startLoadingDirectionsAction,
} from "../../../store/ducks/scripting";
import { collectionLetters } from "../../../commons/utils/map";

export const WaypointsDragAndDrop = ({
  currentWaypoints,
  setCurrentWaypoints,
}) => {
  const { waypoints } = useSelector((state) => state.scripting);
  const dispatch = useDispatch();

  const handleDragEnd = useCallback(
    (result, arrayToBeModified) => {
      if (!result.destination)
        return toast.error(
          "Arraste o ponto de desvio/parada dentro da área de roteirização."
        );
      const selectedWaypoint = remove(
        arrayToBeModified,
        (waypoint) => String(waypoint.location) === String(result.draggableId)
      );
      arrayToBeModified.splice(
        result.destination.index,
        0,
        selectedWaypoint[0]
      );
      dispatch(setWaypointsAction(arrayToBeModified));
      dispatch(startLoadingDirectionsAction());
    },
    [toast, remove, dispatch, waypoints]
  );

  const handleFocusInput = useCallback(
    (waypointId) => {
      const selectedWaypoint = currentWaypoints[waypointId];

      setCurrentWaypoints({
        ...currentWaypoints,
        [waypointId]: { ...selectedWaypoint },
      });
    },
    [currentWaypoints]
  );

  const handleChangeInput = useCallback(
    (waypointId, changedValue, fieldName) => {
      let waypoint = currentWaypoints[waypointId];

      if (waypoint[fieldName] !== undefined) {
        waypoint[fieldName] = changedValue;
      }

      setCurrentWaypoints({
        ...currentWaypoints,
        [waypointId]: waypoint,
      });

      dispatch(updateWaypointAction(waypoint));
    },
    [currentWaypoints]
  );

  const handleDeleteWaypoint = useCallback(
    (waypoint) => {
      dispatch(removeWaypointByIdAction(waypoint.id));
      dispatch(startLoadingDirectionsAction());
    },
    [dispatch]
  );

  const handleStopPointCheckbox = (waypointId, isStopPoint) => {
    let waypoint = currentWaypoints[waypointId];
    waypoint.stopover = isStopPoint;

    if (!isStopPoint) {
      waypoint.duration = null;
      waypoint.title = null;
    }

    setCurrentWaypoints({
      ...currentWaypoints,
      [waypointId]: waypoint,
    });

    dispatch(startLoadingDirectionsAction());
    dispatch(updateWaypointAction(waypoint));
  };

  const duration = (currentWaypoints, waypoint) => {
    if (
      currentWaypoints[waypoint.id] &&
      currentWaypoints[waypoint.id].id === waypoint.id
    ) {
      return (
        currentWaypoints[waypoint.id] && currentWaypoints[waypoint.id].duration
      );
    }
    return waypoint.duration;
  };

  const title = (currentWaypoints, waypoint) => {
    if (
      currentWaypoints[waypoint.id] &&
      currentWaypoints[waypoint.id].id === waypoint.id
    ) {
      return (
        currentWaypoints[waypoint.id] && currentWaypoints[waypoint.id].title
      );
    }
    return waypoint.title;
  };

  const labels = collectionLetters();
  let labelIndex = 1;

  return (
    <DragDropContext
      onDragEnd={(result, provided) => handleDragEnd(result, waypoints)}
    >
      {waypoints.length > 0 && (
        <Droppable droppableId="scripting">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {provided.placeholder}
              {waypoints.map((waypoint, index) => (
                <Draggable
                  key={waypoint.location}
                  draggableId={waypoint.location}
                  index={index}
                >
                  {(provided) => (
                    <ExpansionPanel
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid container direction="column">
                          <Typography
                            style={{ display: "flex" }}
                            variant="body1"
                          >
                            <Room
                              fontSize="small"
                              style={{ paddingRight: 5 }}
                            />
                            <strong style={{ marginRight: "3px" }}>
                              {labels[labelIndex++]}
                            </strong>
                            <strong>
                              {currentWaypoints[waypoint.id] &&
                              currentWaypoints[waypoint.id].stopover
                                ? `- Parada`
                                : `- Desvio`}
                            </strong>
                          </Typography>
                          <Typography variant="body2">
                            {waypoint.location}
                          </Typography>
                        </Grid>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container direction="column">
                          <Grid
                            container
                            direction="row"
                            justify="space-around"
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(event) =>
                                    handleStopPointCheckbox(
                                      waypoint.id,
                                      event.target.checked
                                    )
                                  }
                                  checked={
                                    currentWaypoints[waypoint.id] &&
                                    currentWaypoints[waypoint.id].stopover
                                      ? true
                                      : false
                                  }
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label="Parada"
                            />
                            <IconButton
                              color="secondary"
                              onClick={() => handleDeleteWaypoint(waypoint)}
                            >
                              <Delete />
                            </IconButton>
                          </Grid>
                          {waypoint.stopover && (
                            <Grid container direction="row">
                              <div
                                style={{
                                  width: "40%",
                                  marginRight: 5,
                                  marginLeft: 5,
                                }}
                              >
                                <TextField
                                  label="Tempo"
                                  placeholder="Em minutos"
                                  type="number"
                                  onFocus={() => handleFocusInput(waypoint.id)}
                                  onChange={(e) =>
                                    handleChangeInput(
                                      waypoint.id,
                                      e.target.value,
                                      "duration"
                                    )
                                  }
                                  value={duration(currentWaypoints, waypoint)}
                                />
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  marginRight: 5,
                                  marginLeft: 5,
                                }}
                              >
                                <TextField
                                  label="Titulo"
                                  onFocus={() => handleFocusInput(waypoint.id)}
                                  onChange={(e) =>
                                    handleChangeInput(
                                      waypoint.id,
                                      e.target.value,
                                      "title"
                                    )
                                  }
                                  value={title(currentWaypoints, waypoint)}
                                />
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      )}
    </DragDropContext>
  );
};
