import { fromAreaApi } from "./area.mapper";

export const fromPublicEntityApi = (data) => {
  return {
    id: data.id,
    area: fromAreaApi(data.area),
    areaId: data.areaId,
    createdAt: data.createdAt,
    deletedAt: data.deletedAt,
    updatedAt: data.updatedAt,
    publicEntity: data.publicEntity,
    publicEntityId: data.publicEntityId,
  };
};
