import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CancelButton, SaveButton, Text } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { ScriptingActiontypes } from "../../../../../store/ducks/scripting";
import { genericAction } from "../../../../../store/ducks/transfer";

export const DeletedAddress = ({ isOpen, setIsOPen, id }) => {
  const dispatch = useDispatch();

  const handleDeleteData = () => {
    try {
      dispatch(
        genericAction(ScriptingActiontypes.DELETED_ADDRESS_CARD, {
          id,
        })
      );
    } catch (error) {
      toast.error("Erro ao tentar editar endereço");
    } finally {
      setIsOPen(false);
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen.open}
        onClose={() => setIsOPen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>Excluir card</DialogTitle>
        <DialogContent>
          <Text>
            Por favor, esteja ciente de que ao confirmar, você estará excluindo
            uma Parada/Desvio de sua rota! Certifique-se de que essa é a ação
            que você deseja realizar antes de prosseguir.
          </Text>
        </DialogContent>
        <DialogActions>
          <SaveButton onClick={handleDeleteData}>Confirmar</SaveButton>
          <CancelButton onClick={() => setIsOPen(false)}>Cancelar</CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
