import React, { useEffect } from "react";
import { PDFViewer, Document, Page, Image, View } from "@react-pdf/renderer";

import { Subheader } from "./Subheader";
import { Header } from "./Header";
import { Table } from "./Table";
import { styles } from "./styles";
import { useLocation } from "react-router-dom";
import { letters, secondsToText } from "../../../commons/utils/text";
import { staticMapUrl } from "static-google-map";
import { useGoogleMapsKey } from "../../../hooks/useGoogleMapsKey";

export const ScriptingPDF = () => {
  const googleMapsKey = useGoogleMapsKey();
  const { state } = useLocation();

  const buildMarkers = () => {
    const firstLocation = {
      lat: state.route.overview_path[0].lat,
      lng: state.route.overview_path[0].lng,
    };

    const lastLocation = {
      lat: state.route.overview_path[state.route.overview_path.length - 1].lat,
      lng: state.route.overview_path[state.route.overview_path.length - 1].lng,
    };

    const locations = [firstLocation];

    if (state.waypoints.length > 0) {
      locations.push(
        ...state.waypoints.map((waypoint) => ({
          lat: waypoint.position.latitude,
          lng: waypoint.position.longitude,
        }))
      );
    }

    locations.push(lastLocation);

    return locations.map((location, index) => ({
      location,
      color: "red",
      size: "mid",
      label: letters[index].toUpperCase(),
    }));
  };

  const url = staticMapUrl({
    key: googleMapsKey,
    paths: [
      {
        points: `enc:${state.route.overview_polyline}`,
      },
    ],
    markers: buildMarkers(),
    size: "600x400",
    scale: 2,
    maptype: "roadmap",
    format: "jpg",
    language: "pt",
  });

  const totalSeconds = state.route.legs.reduce(
    (acc, leg) => acc + leg.duration.value,
    0
  );

  const totalKm =
    state.route.legs.reduce((acc, leg) => acc + leg.distance.value, 0) / 1000;

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = null;
    };
  }, []);

  return (
    <PDFViewer size="A4" style={styles.pdfViewer}>
      <Document title={state.event}>
        <Page wrap>
          <Header />
          <View style={styles.main}>
            <Subheader
              event={state.event}
              startAddress={state.startAddress}
              endAddress={state.endAddress}
              distance={`${totalKm.toFixed(1)} Km`.replace(".", ",")}
              duration={secondsToText(totalSeconds)}
            />

            <Image style={{ marginBottom: 16 }} source={url} />

            <Table legs={state.route.legs} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
