import React from "react";

import TablePreConfig from "../../components/TablePreConfigured/index";
import FilterPreConfigured from "../../components/FilterPreConfigured/FilterPreConfigured";
import Sidebar from "../../components/Sidebar/sidebar";
import { Container, Div } from "./styles";

export default function PreConfigured() {
  return (
    <>
      <Div>
        <Sidebar />
        <Container>
          <h1>Transfers Pré-configurados</h1>
          <FilterPreConfigured />
          <TablePreConfig />
        </Container>
      </Div>
    </>
  );
}
