import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  containerOverview: {
    backgroundColor: '#efefef',
    padding: '40px 30px',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  legendColumn: {
    paddingRight: 50,
    position: 'relative',

    '&::after': {
      position: 'absolute',
      top: 0,
      right: 0,
      content: '""',
      width: 1,
      height: 320,
      backgroundColor: '#b8b8b8',
    }
  },
  mainOverview: {
    padding: '0px 50px'
  },
  mainOverviewTitleText: {
    fontWeight: 500,
  },
  mainOverviewHeader: {
    marginBottom: 35,
  },
  mainOverviewIndicators: {
    marginBottom: 25,
  },
  containerUnitReport: {
    backgroundColor: '#f9f9f9',
    padding: '40px 30px',
    borderBottom: '1px solid #b8b8b8',

    '&:last-of-type': {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderBottom: 'unset'
    }
  },
}));

export default useStyles;
