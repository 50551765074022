import styled from "styled-components";

export const Div = styled.div`
  width: 100%;

  .switchers {
    margin-left: 15px;
    margin-top: -50vh;
  }
`;
