import axios from "axios";

export const apiSSO = () =>
  axios.create({ baseURL: process.env.REACT_APP_SSO_URL });
// SSO INTEGRATION
export const setSSOIntegration = async (sso) => {
  localStorage.setItem("@sso_integration", sso);
};

export const getSSOIntegration = async () =>
  localStorage.getItem("@sso_integration");

export const removeSSOIntegration = async () =>
  localStorage.removeItem("@sso_integration");

// SSO EXPIREAT
export const setSSOExpiresAt = async (expires_at) =>
  localStorage.setItem("@sso_expires_at", expires_at);

export const getSSOExpiresAt = async () =>
  localStorage.getItem("@sso_expires_at");

export const removeSSOExpiresAt = async () =>
  localStorage.removeItem("@sso_expires_at");

export const ssoSession = (token) =>
  apiSSO().request({ url: "session", params: { token } });

export const exchangeCodeForAuthorization = async (code) => {
  const response = await apiSSO().request({
    url: `/authorizations/${code}`,
  });

  return response.data;
};

export const ssoRefresh = ({ refresh_token, sso }) =>
  apiSSO().request({
    url: "login/refresh",
    method: "POST",
    data: { refresh_token, sso },
  });
