export default {
  container: {
    padding: "6px 14px",
    marginTop: 3,
    background: "#e8e8e8",
    display: 'flex',
  },
  text: {
    fontSize: '14px',
    marginRight: '15px'
  }
};
