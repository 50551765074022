/**
 * Repository responsible for making requests to the Flespi Service
 * in-house API.
 */

import { flespiServiceApi } from '../../services/flespiServiceApi'
import { createException } from '../../commons/exceptions'

export async function postStartTransferJustification(flespiId, justification) {
  try {
    await postAutoConcludedEvent(flespiId, 'transfer-started-away-from-start-point', justification)
  }
  catch (error) {
    throw createException({
      code: 'failed-to-post-justification'
    })
  }
}

export async function postFinishTransferJustification(flespiId, justification) {
  try {
    await postAutoConcludedEvent(flespiId, 'transfer-finished-away-from-start-point', justification)
  }
  catch (error) {
    throw createException({
      code: 'failed-to-post-justification'
    })
  }
}

export async function postAutoConcludedEvent(flespiId, eventName, description) {
  try {
    await flespiServiceApi.post(
      '/createTreatedEvent',
      {
        flespi_id: flespiId,
        event_name: eventName,
        description
      }
    )
  }
  catch (error) {
    throw createException({
      code: 'failed-to-post-event-error',
    })
  }
}

export const getEventAnalytics = async (customerId, from, to, identifier) => {
  try {
    const response = await flespiServiceApi.get(
      `/events/${customerId}/analytics`,
      {
        params: {
          from,
          to,
          identifier,
        }
      }
    )
    const eventsAnalytics = response.data
    return eventsAnalytics
  }
  catch (error) {
    const errorObject = {
      error,
      code: null,
    }
    
    switch (error?.response?.status) {
      case 401:
        errorObject.code = 'unauthorized'
        break
      case 403:
        errorObject.code = 'forbidden'
        break
      default:
        errorObject.code = 'retrieval-failure'
        break
    }

    throw createException(errorObject)
  }
}