import React from "react";
import { Marker } from "@react-google-maps/api";

export const PublicEntity = ({
  publicEntity,
  onMarkerClick: handleMarkerClick,
}) => {
  if (!publicEntity.area.point?.coordinates) {
    return <></>;
  }

  const [lng, lat] = publicEntity.area.point.coordinates;

  return (
    <Marker
      position={{ lat, lng }}
      icon={publicEntity.publicEntity.icon}
      onClick={() => {
        handleMarkerClick && handleMarkerClick(publicEntity);
      }}
    />
  );
};
