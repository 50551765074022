import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  alertBox: {
    boxShadow: "0px 0px 4px #00000029",
    marginBottom: 10,
  },
  alertItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  alertTime: {
    color: "#909090",
    fontSize: 12,
    marginBottom: 5,
  },
  alertBoxButtons: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "center",
    gridGap: 5,
    listStyle: "none",
    padding: 0,
  },
  alertDetalhes: {
    width: 89,
    height: 32,
    color: "#fff",
    backgroundColor: "#1CB0CF",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 16,
    opacity: 1,
    "&:hover": {
      backgroundColor: "#1CB0CF",
      opacity: 0.8,
    },
  },
  treatLoading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px 20px",
  },
}));
