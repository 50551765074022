import React from "react";
import { flattenDeep } from "lodash";
import { Marker, Polygon } from "@react-google-maps/api";
import decodePolyline from "decode-google-map-polyline";

import { hexToRGB } from "../../../commons/utils/string";
import {
  RISK_ZONE_FILL_COLOR,
  RISK_ZONE_STROKE_COLOR,
} from "../../../commons/colors";

export const RiskArea = ({ area, type, onMarkerClick: handleMarkerClick }) => {
  const fillColor = hexToRGB(type.color ?? RISK_ZONE_FILL_COLOR, 0.1);
  const strokeColor = type.color ?? RISK_ZONE_STROKE_COLOR;

  const renderMarker = () => {
    if (!area.point?.coordinates) {
      return;
    }

    const [lng, lat] = area.point.coordinates;

    return (
      <Marker
        position={{ lat, lng }}
        icon={type.icon}
        onClick={() => handleMarkerClick && handleMarkerClick(area, type)}
      />
    );
  };

  const renderPolygons = () => {
    const allCoordinates = flattenDeep(area.polygon?.coordinates ?? []).map(
      (encodedCoordinate) => decodePolyline(encodedCoordinate)
    );

    return allCoordinates.map((coordinates, index) => (
      <Polygon
        key={`area-${area.id}-polygon-${index}`}
        options={{
          fillColor,
          strokeColor,
        }}
        path={coordinates}
      />
    ));
  };

  return (
    <>
      {renderMarker()}
      {renderPolygons()}
    </>
  );
};
