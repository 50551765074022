export const riskLayers = [
  {
    name: "Áreas de Risco",
    amount: 3,
    itemsAlerts: [
      { id: 185, name: "Comunidade e facções criminosas" },
      { id: 193, name: "Aglomerados Subnormais" },
      { id: 4, name: "Área Urbana de Risco" },
      { id: 1, name: "Unicade de Policia Pacificadora(UPP)" },
    ],
  },
];
