import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

import { CustomChip, CustomComplete, CustomTextField } from "./styles";

export default function ModalSendEmailReport({ isOpen, onClose: handleClose }) {
  const [emailList, setEmailList] = useState([]);

  const handleEmailChange = (event, newEmails) => {
    setEmailList(newEmails);
  };

  const handleChipDelete = (index) => {
    const updatedEmailList = emailList.filter((_, i) => i !== index);
    setEmailList(updatedEmailList);
  };

  const handleKeyPress = (event) => {
    if (
      event.key === "Enter" &&
      emailList.length > 0 &&
      emailList[emailList.length - 1].endsWith(".com")
    ) {
      setEmailList([...emailList, ""]);
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={isOpen}>
        <DialogTitle sx={{ m: 0, p: 2 }} fontWeight={700}>
          Compartilhar relatório por email?
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{ color: " #2A93A7" }} />
        </IconButton>
        <DialogContent dividers>
          <Typography style={{ marginBottom: "5px" }} fontWeight={600}>
            Destinatário(s):
          </Typography>
          <CustomComplete
            multiple
            freeSolo
            autoHighlight
            id="email-list"
            options={emailList}
            value={emailList}
            onChange={handleEmailChange}
            renderTags={(value, getTagProps) =>
              value.map((email, index) => (
                <CustomChip
                  variant="outlined"
                  key={index}
                  label={email}
                  {...getTagProps({ index })}
                  onDelete={() => handleChipDelete(index)}
                />
              ))
            }
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label="Digite o(s) emails(s), separados por virgula"
                variant="outlined"
                onKeyDown={handleKeyPress}
                InputLabelProps={{ style: { textAlign: "center" } }}
              />
            )}
          />

          <Typography fontWeight={600}>Assunto:</Typography>
          <CustomTextField
            label="Informe o assunto do email"
            variant="outlined"
            fullWidth
          />

          <Typography fontWeight={600}>Mensagem:</Typography>
          <CustomTextField
            label="Escreva a mensagem aqui"
            variant="outlined"
            fullWidth
            rows={4}
            multiline
          />
          <Button startIcon={<SendOutlinedIcon style={{ color: "#2A93A7" }} />}>
            <Typography fontSize={12} color={"#2A93A7"}>
              Enviar
            </Typography>
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
