/**
 * Basic dialog that requests some information from the user.
 */

import React, { useState } from "react";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import useStyles from "./styles";
import {
  createDeviceAwayFromStartPointEvent,
  createDeviceAwayFromEndPointEvent,
} from "../../services/eventAnalyticsApi/eventService";
import { toast } from "material-react-toastify";

export default function FormDialog({
  title,
  modalOpen = false,
  content = "",
  textFieldLabel = "label",
  submitText = "Ok",
  closeModal = () => {},
  handleSubmitExternal = async () => {},
  device,
}) {
  const classes = useStyles();

  const [textFieldValue, setTextFieldValue] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    closeModal();
  };

  const handleSubmit = async () => {
    if (textFieldValue.trim() === "") {
      setError(true);
      return;
    }

    await saveJustification();
    handleClose();
  };

  const handleTextFieldChange = (e) => {
    setError(false);
    setTextFieldValue(e.target.value);
  };

  if (!Array.isArray(content)) content = [content];

  const saveJustification = async () => {
    try {
      setLoading(true);

      const eventData = {
        externalId: device.device.external_id,
        deviceTypeId: device.device.device_type_id,
        justification: textFieldValue,
      };

      if (device.transfer?.start_date !== null) {
        eventData.transferId = device.transfer.id;
        await handleSubmitExternal(textFieldValue);
        await createDeviceAwayFromEndPointEvent(eventData);
      } else {
        await handleSubmitExternal(textFieldValue);
        await createDeviceAwayFromStartPointEvent(eventData);
      }
    } catch (error) {
      toast.error("Falha ao iniciar ou finalizar transfer.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          {content.map((line) => (
            <p>{line}</p>
          ))}
        </DialogContentText>
        <TextField
          className={classes.textFieldError}
          autoFocus
          margin="dense"
          id="name"
          label={textFieldLabel}
          type="text"
          multiline
          fullWidth
          error={error}
          helperText={error ? "Campo não pode estar vazio." : null}
          value={textFieldValue}
          onChange={handleTextFieldChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
