import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux'

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import ButtonFilter from './ButtonFilter'
import ButtonRegister from './Register/ButtonRegister'
import DateTime from './dateTime'

import { Container , Div } from './styles';
import { FilterFields } from './FilterFields';

function Filter() {
  const formName = useRef(null)
  const dispatch = useDispatch()
  const [showFilterFields, setShowFilterFields] = useState(false);

  const addEventAct = eventFetch => {
    return { type: 'ADD_EVENT_FETCH', eventFetch }
  }

  const handleChangeSearchTransfersByEvent = e => {
    const value = e.target.value
    dispatch(addEventAct( value ))
  }

  return (
    <>
    <Container>
      <Div>
        <ButtonRegister/>
        <DateTime/>
        <form ref={formName}>
          <div>
            <Grid item>
                <TextField
                onChange={handleChangeSearchTransfersByEvent}
                className="search-transfers"
                style={{ width: 376 }}
                label="Pesquisar por Evento"
                type="search"
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  }} />
            </Grid>
          </div>
        </form>

        <ButtonFilter setShowFilterFields={setShowFilterFields} showFilterFields={showFilterFields} />
      </Div>
      <Div>
        {showFilterFields && <FilterFields />}
      </Div>
    </Container>
    </>
    )
  }

 export default Filter
