import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Paper,
  TableHead,
  Button,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { useStyles } from "./styles";
import { format, parseISO, subHours } from "date-fns";
import CreateDialog from "./createDialog";
import DeleteDialog from "./deleteDialog";
import EditDialog from "./editDialog";
import { handleGetPassenger } from "../../../services/peopleManagement/passenger";
import CentralizedLoading from "../../../components/CentralizedLoading";
import { toast } from "material-react-toastify";

const rows = [
  {
    id: "ID",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "Name",
    numeric: true,
    disablePadding: false,
    label: "Nome do Passageiro",
  },
  {
    id: "Date",
    numeric: true,
    disablePadding: false,
    label: "Data de Nascimento",
  },
  { id: "Address", numeric: true, disablePadding: false, label: "Endereço" },
  { id: "Phone", numeric: true, disablePadding: false, label: "Telefone" },
  { id: "Rfid", numeric: true, disablePadding: false, label: "RFID" },
  { id: "Edit", numeric: true, disablePadding: false, label: "Editar" },
  { id: "Remove", numeric: true, disablePadding: false, label: "Remover" },
];

class EnhancedTableHead extends React.Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                sortDirection=""
                numeric={row.numeric}
                padding={"15px"}
              >
                {row.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
});

let EnhancedTableToolbar = (props) => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.spacer} />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

const PassengerTable = (props) => {
  const [dialogCreate, setDialogCreate] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [dialogEdit, setDialogEdit] = useState(false);
  const [itemSelect, setItemSelect] = useState({});
  const [passenger, setPassenger] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const formatDate = (dateString) => {
    if (dateString === "") {
      return null;
    }
    const dateTz = subHours(parseISO(dateString), 3);

    return format(dateTz, "dd'/'MM'/'yyyy '");
  };

  const { classes } = props;

  const classesB = useStyles();

  const getDataTable = async () => {
    try {
      const response = await handleGetPassenger(currentPage, rowsPerPage);
      setPassenger(response);
    } catch (error) {
      toast.error('Erro ao tentar carregar dados')
    }
  };

  useEffect(() => {
    getDataTable();
  }, [props.tab]);

  useEffect(() => {
    getDataTable();
  }, [currentPage, rowsPerPage]);

  const openCreateDialog = () => {
    setDialogCreate(true);
  };

  const closeCreateDialog = () => {
    setDialogCreate(false);
  };

  const openDialogDelete = (item) => {
    setItemSelect(item);
    setDialogDelete(true);
  };

  const closeDialogDelete = () => {
    setDialogDelete(false);
  };

  const openDialogEdit = (item) => {
    setItemSelect(item);
    setDialogEdit(true);
  };

  const closeDialogEdit = () => {
    setDialogEdit(false);
  };

  return (
    <Paper className={classes.root}>
      <div className={classesB.divButton}>
        <Button
          className={classesB.txtButton}
          variant="contained"
          onClick={openCreateDialog}
        >
          Adicionar
        </Button>
        <CreateDialog
          close={closeCreateDialog}
          open={dialogCreate}
          reload={getDataTable}
        />
        <DeleteDialog
          close={closeDialogDelete}
          open={dialogDelete}
          data={itemSelect}
          reload={getDataTable}
        />
        {dialogEdit && (
          <EditDialog
            close={closeDialogEdit}
            open={dialogEdit}
            data={itemSelect}
            reload={getDataTable}
          />
        )}
      </div>
      <EnhancedTableToolbar />
      <div className={classes.tableWrapper}>
        {passenger.data ? (
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody className={classes.tableBody} padding="15px">
              {passenger.data.map((n) => {
                return (
                  <TableRow hover tabIndex={-1} key={n.id}>
                    <TableCell component="th" scope="row" padding="15px">
                      {n.id}
                    </TableCell>
                    <TableCell >{n.name}</TableCell>
                    <TableCell >
                      {formatDate(n?.birthDate || "")}
                    </TableCell>
                    <TableCell >{n?.address || ""}</TableCell>
                    <TableCell >{n?.phone || ""}</TableCell>
                    <TableCell >{n?.rfid || ""}</TableCell>
                    <TableCell >
                      <IconButton>
                        <EditIcon onClick={() => openDialogEdit(n)} />
                      </IconButton>
                    </TableCell>
                    <TableCell >
                      <IconButton>
                        <DeleteIcon onClick={() => openDialogDelete(n)} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <CentralizedLoading text="Buscando dados..." />
        )}
      </div>
      <TableRow>
        <TablePagination
          colSpan={rows.length}
          rowsPerPageOptions={[5, 10, 20]}
          count={passenger.totalRows}
          rowsPerPage={rowsPerPage}
          page={currentPage - 1}
          onChangePage={(event, newPage) => setCurrentPage(newPage + 1)}
          onChangeRowsPerPage={(event) =>
            setRowsPerPage(parseInt(event.target.value))
          }
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
          labelRowsPerPage={"Linhas por página:"}
        />
      </TableRow>
    </Paper>
  );
};

PassengerTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PassengerTable);
