import { flespiAPI } from "./flespiApi";

/**
 * Realiza a busca das mensagens dos dispositivos com base
 * nos argumentos informados
 *
 * @param {number[] | ["all"]} flespiDeviceIds
 * @param {{
 *  from?: number,
 *  to?: number
 * }} params
 */
export const getFlespiMessages = async (flespiDeviceIds, params) => {
  const response = await flespiAPI.get(`/devices/${flespiDeviceIds}/messages`, {
    params: {
      data: JSON.stringify(params)
    }
  });

  return response.data.result;
};

/**
 * Realiza a busca dos dispositivos cadastrados no Flespi. Se não for informado
 * os ids dos dispositivos todos serão retornados
 *
 * @param {number[] | ["all"]} flespiDeviceIds
 */
export const getDevices = async (flespiDeviceIds = ['all']) => {
  const response = await flespiAPI.get(`/devices/${flespiDeviceIds}`);
  return response.data.result;
};
