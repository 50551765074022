import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { CustomTextField } from "../styles";
import { IconButton, InputAdornment } from "@mui/material";
import { ModeOutlined as ModeOutlinedIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Types, genericAction } from "../../../../store/ducks/transfer";
import { toast } from "material-react-toastify";

export const PlacesAutocompleteComponent = ({
  typeInput,
  currentTransfer,
  from,
}) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState(currentTransfer);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [editForm, setEditForm] = useState(false);

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setCoordinates(latLng);

      if (from) {
        return dispatch(
          genericAction(
            Types.UPDATE_FROM_CURRENT_TRANSFER,
            results[0].formatted_address
          )
        );
      }
      dispatch(
        genericAction(
          Types.UPDATE_TO_CURRENT_TRANSFER,
          results[0].formatted_address
        )
      );
    } catch (error) {
      toast.error("Error fetching geolocation data:", error);
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <CustomTextField
            label={typeInput}
            size="small"
            variant="outlined"
            type="search"
            {...getInputProps({ placeholder: "Digite o endereço" })}
            disabled={!editForm}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ marginRight: "10px" }}>
                  <IconButton
                    onClick={() => {
                      setEditForm((previous) => !previous);
                    }}
                  >
                    <ModeOutlinedIcon
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div>
            {loading && <div>Carregando...</div>}
            {suggestions.map((suggestion, index) => {
              const style = {
                width: "250px",
                lineHeight: 1.5,
                backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                cursor: "pointer",
                position: "absolute",
                zIndex: 10,
              };
              return (
                <div
                  key={index}
                  {...getSuggestionItemProps(suggestion, { style })}
                >
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
