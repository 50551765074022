import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

const DeleteDialog = ({ close, open, deleted, data, reloadTable }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Deseja proseguir com a exclusão?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            deleted(data.id);
            close();
          }}
          color="primary"
        >
          Sim
        </Button>
        <Button onClick={close} color="primary">
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
