import React from 'react';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const LegendBadge = ({classes, color}) => {
  return (
    <span
      className={classes.badge}
      style={{ backgroundColor: color }}
    ></span>
  );
}

export default withStyles(styles)(LegendBadge);
