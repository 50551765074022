import React from 'react'
import { Tooltip } from '@material-ui/core'

import DeviceAlias from '../../componentUtils/DeviceAlias'

const DeviceIdentifierOrAlias = ({
  deviceId,
  identifier,
  alias = null,
  executeAfterUpdate = () => { },
}) => {
  const shouldShowAlias = !!alias

  const aliasComponent = (
    <div>
      <DeviceAlias
        deviceId={deviceId}
        alias={alias}
        aliasWhenEmpty={identifier}
        executeAfterUpdate={executeAfterUpdate}
      />
    </div>
  )

  if (!shouldShowAlias)
    return aliasComponent

  return (
    <Tooltip title={identifier}>
      {aliasComponent}
    </Tooltip>
  )
}

export default DeviceIdentifierOrAlias