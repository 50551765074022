import camelCaseKeys from "camelcase-keys-recursive";

import { store } from "../store";
import passport from "./passport";
import { appId } from "../commons/constants/app";

/**
 * Realiza a autenticação com base nas credenciais informadas
 *
 * @param {{username: string, password: string}} credentials
 */
export const authenticate = async (credentials) => {
  const data = {
    client_id: process.env.REACT_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.REACT_APP_PASSPORT_CLIENT_SECRET,
    grant_type: "password",
    ...credentials,
  };

  const response = await passport.post("/oauth/token", data);

  return camelCaseKeys(response.data);
};

/**
 * Realiza o logout com base no token informado
 *
 * @param {string} token
 */
export const logout = async (token) => {
  const data = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  const response = await passport.delete("/api/oauth/token", data);

  return response.data;
};

export const getUserPermissions = (token, applicationId = appId) => {
  const data = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      application_id: applicationId,
    },
  };

  return passport.get("/api/self/permissions", data);
};

export const getUser = async (token) => {
  const data = {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-application-id": appId,
    },
  };

  const response = await passport.get("/api/self", data);

  return response.data;
};

export const getLoadedPermissions = () => {
  const { auth } = store.getState();
  return auth.permissions?.permissions?.data;
};

export const can = (permission) => {
  const permissions = getLoadedPermissions();

  if (!permissions) {
    return false;
  }

  return permissions.includes(permission);
};

export const isAuthenticated = () => {
  const { auth } = store.getState();
  return auth.token && getLoadedPermissions();
};

export const getLoggedUserMainRoute = () => {
  if (can("can-monitoring-devices")) {
    return "/monitoringmap";
  }

  if (can("can-view-routes")) {
    return "/transfers";
  }

  if (can("can-view-transfer-history")) {
    return "/reports";
  }

  return "/preconfigured";
};

export const setAuthStorage = async (auth) => {
  return localStorage.setItem("@auth", JSON.stringify(auth));
};

export const getAuthStorage = async () => {
  return JSON.parse(localStorage.getItem("@auth"));
};

export const clearStorageAuth = async () => {
  return localStorage.removeItem("@auth");
};
