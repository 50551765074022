import React from "react";
import { Switch, Router, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import LoginSSO from "./pages/Login/sso";
import { PrivateRoute } from "./components/ProtectedRoute/protectedRoute";
import Transfers from "./pages/Transfers";
import TransfersNew from "./pages/TransfersNew";

import DeviceManagement from "./pages/DeviceManagement";
import PeopleManagement from "./pages/PeopleManagement";
import CustomerAreaManagement from "./pages/CustomerAreaManagement";
import Login from "./pages/Login";
import Monitoring from "./pages/Monitoring";
import Preconfigured from "./pages/PreConfigured";
import MonitoringMap from "./pages/MonitoringMap";
import { Scripting } from "./pages/Scripting";
import { ReportPage } from "./pages/Report/index";
import ReportEventDetail from "./pages/ReportEventDetail";
import DeviceTrackMapReport from "./pages/Report/IdentifierReport/components/DeviceTrackMapReport";
import POIManagement from "./pages/POIManagement";
import { ScriptingPDF } from "./pages/Scripting/ScriptingPDF";
import { ScriptingNew } from "./pages/ScriptingNew";
import Report from "./components/ReportTransfer/report";

export const history = createBrowserHistory();

const Routes = () => (
  <Router history={history}>
    <Switch>
      <PrivateRoute
        can="can-view-preconfigured-transfers"
        exact
        path="/preconfigured"
        component={Preconfigured}
      />
      <Route exact path="/login/sso" component={LoginSSO} />
      <PrivateRoute
        can="can-monitoring-devices"
        exact
        path="/monitoringmap"
        component={MonitoringMap}
      />
      <PrivateRoute
        can="can-monitoring-devices"
        exact
        path="/monitoring"
        component={Monitoring}
      />
      <PrivateRoute
        can="can-view-transfer-history"
        exact
        path="/reports"
        component={ReportPage}
      />
      <PrivateRoute
        exact
        path="/reports/:deviceId/:from/:to/track"
        component={DeviceTrackMapReport}
      />
      <PrivateRoute
        exact
        path="/reports/:deviceId/:transferId/:startDate/:end_date"
        component={ReportEventDetail}
      />
      <PrivateRoute
        can="can-view-routes"
        exact
        path="/transfers"
        component={Transfers}
      />
      <PrivateRoute
        can="can-view-routes"
        exact
        path="/transfersNews"
        component={TransfersNew}
      />
      <PrivateRoute
        can="can-view-routes"
        exact
        path="/reportTransfers"
        component={Report}
      />

      <PrivateRoute
        can="can-manage-devices"
        exact
        path="/devicemanagement"
        component={DeviceManagement}
      />
      <PrivateRoute
        can="can-manage-devices"
        exact
        path="/poimanagement"
        component={POIManagement}
      />
      <PrivateRoute
        can="can-scripting-transfer"
        exact
        path="/scripting"
        component={Scripting}
      />
      <PrivateRoute
        can="can-scripting-transfer"
        exact
        path="/scriptingNew"
        component={ScriptingNew}
      />
      <PrivateRoute
        can="can-scripting-transfer"
        exact
        path="/scripting/pdf"
        component={ScriptingPDF}
      />
      <Route path="/" exact component={Login} />
      <PrivateRoute
        can="can-edit-fields"
        exact
        path="/fieldmanagement"
        component={CustomerAreaManagement}
      />
      <PrivateRoute
        can="can-manage-people"
        exact
        path="/managementpeople"
        component={PeopleManagement}
      />
      <PrivateRoute
        path="*"
        component={() => <Redirect to={"/monitoringmap"} />}
      />
    </Switch>
  </Router>
);

export default Routes;
