import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { isEmpty } from "lodash";
import { IdentifierPositionHistoryTable } from "./IdentifierPositionHistoryTable";
import { IdentifierInfoCard } from "./IdentifierInfoCard";
import { useIdentifierReport } from "./useIdentifierReport";
import { getDistanceTraveled } from "../../../commons/utils/flespi";

const Loading = () => {
  const Circular = () => (
    <CircularProgress size={68} style={{ marginTop: 200, color: "#63a9ef" }} />
  );
  return (
    <center>
      <div>
        <Circular />
      </div>
      <span>Carregando dados...</span>
    </center>
  );
};

export const IdentifierReport = ({
  currentTransfer,
  filters,
  currentDevice,
}) => {
  const { flespiReport, isLoading, errorMessage, device } = useIdentifierReport(
    currentTransfer,
    filters,
    currentDevice
  );
  const [identifierName, setIdentifierName] = useState("");
  const [travelledDistance, setTravelledDistance] = useState(0);
  const identifier = currentTransfer?.associated_devices
    .filter((n) => n.id == currentDevice)
    .map((n) => n.alias || n.identifier);

  useEffect(() => {
    if (
      !isEmpty(currentTransfer) &&
      currentTransfer.associated_devices?.length
    ) {
      setIdentifierName(identifier);
    }

    if (filters.identifier) {
      setIdentifierName(filters.identifier);
    }
  }, [currentTransfer, filters]);

  useEffect(() => {
    if (flespiReport.result) {
      const distance = getDistanceTraveled(flespiReport.result, "kilometers");
      setTravelledDistance(distance);
    }
  }, [isLoading]);

  if (isLoading) return <Loading />;
  else if (errorMessage) return <>{errorMessage}</>;
  else
    return (
      <>
        {identifierName && (
          <IdentifierInfoCard
            identifierName={identifierName}
            device={device}
            filters={filters}
            travelledDistance={travelledDistance}
          />
        )}
        <div style={{ height: 50 }} />
        <IdentifierPositionHistoryTable
          identifiers={flespiReport}
          device={device}
        />
      </>
    );
};
