import styled from "styled-components";

import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Typography,
  styled as styledMUI,
  Box,
} from "@material-ui/core";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: linear-gradient(115.08deg, #021d35 34.06%, #3ac4e0 132.98%);
`;

export const FormContainer = styled.div`
  gap: 8px;
  width: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  background-color: white;
  box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  z-index: 1;
`;

export const Vectors = styled.div`
  width: 100%;
  position: fixed;
  bottom: 36px;
`;

export const Footer = styled.footer`
  display: flex;
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 285px;
`;

export const LogoPUmais = styled.img`
  width: 130px;
  height: 100px;
  zindex: 1;
`;

export const FormInformation = styledMUI(Typography)((props) => ({
  fontFamily: "noto-sans-regular",
  fontSize: "1.25rem",
  letterSpacing: "-0.02rem",
  textAlign: "center",
  padding: props.padding || "20px",
}));

export const FormRow = styledMUI(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  margin: props.margin,
}));

export const FormSubmitBtn = styledMUI(Button)(() => ({
  background: "#3AC4E0",
  marginBottom: "20px",
  padding: "10px",
  height: "48px",
  color: "#000",
  textTransform: "none",
  "&:hover": { backgroundColor: "#3AC4E0" },
}));

export const Text = styledMUI(Typography)((props) => ({
  fontFamily: "noto-sans-regular",
  fontSize: "1.07rem",
  fontWeight: 600,
  letterSpacing: "-0.02rem",
  color: props.color,
  cursor: props.cursor,
}));

export const Divider = styledMUI("div")(() => ({
  width: "100%",
  border: `1px solid #B1B9C0`,
}));
