import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		background: '#EBEBEB',
		border: 0,
		borderRadius: 3,
		color: 'white'
	},
	backButton: {
		marginRight: theme.spacing(1)
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	}
}));

function getSteps() {
	return [ 'Cadastro', 'Roteirização', 'Vinculação' ];
}

export default function HorizontalLabelPositionBelowStepper() {
	const classes = useStyles();
	const [ activeStep ] = React.useState(1);
	const steps = getSteps();

	return (
		<div>
			<Stepper
				className={classes.root}
				style={{ backgroundColor: 'none', padding: 10 }}
				activeStep={activeStep}
				alternativeLabel
			>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<div />
		</div>
	);
}
