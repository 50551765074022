import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import useStyles, { theme } from './styles'

const DateTimePicker = ({
  placeholder,
  minDate = undefined,
  maxDate = undefined,
  value = undefined,
  minDateMessage = undefined,
  maxDateMessage = undefined,
  invalidDateMessage = 'Data Inválida',
  onChange = () => { },
}) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider
        locale={ptBR}
        utils={DateFnsUtils}
      >
        <KeyboardDateTimePicker
          invalidDateMessage={invalidDateMessage}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
          ampm={false}
          minDate={minDate}
          maxDate={maxDate}
          autoOk
          InputAdornmentProps={{ position: 'start' }}
          variant='inline'
          inputVariant='outlined'
          format='dd/MM/yyyy HH:mm:ss'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          classes={{
            root: classes.input,
          }}
          InputProps={{
            classes: {
              input: classes.customInputStyles,
              notchedOutline: classes.customNotchedOutlineStyles
            }
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default DateTimePicker