import storage from "redux-persist/lib/storage";

//Types
export const Types = {
  ASYNC_SIGN_IN_REQUEST: "ASYNC_SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_FAILURE: "SIGN_FAILURE",
  ASYNC_LOGOUT: "ASYNC_LOGOUT",
  SUCCESS_LOGOUT: "SUCCESS_LOGOUT",
  ADD_LOGOUT: "ADD_LOGOUT",
  LOAD_PERMISSIONS: "LOAD_PERMISSIONS",
  REQUEST_GET_CUSTOMERS: "REQUEST_GET_CUSTOMERS",
  SUCCESS_GET_CUSTOMERS: "SUCCESS_GET_CUSTOMERS",
  FAILURE_GET_CUSTOMERS: "FAILURE_GET_CUSTOMERS",
  CHANGE_CUSTOMER: "CHANGE_CUSTOMER",
  ASYNC_SSO_AUTHENTICATE: "ASYNC_SSO_AUTHENTICATE",
  AUTHENTICATED: "AUTHENTICATED",
  ASYNC_LOAD_AUTHENTICATE: "ASYNC_LOAD_AUTHENTICATE",
  CUSTOMER_SELECTED: "CUSTOMER_SELECTED",
  // ASYNC_AUTHENTICATE: "ASYNC_AUTHENTICATE",
  SET_USER: "SET_USER",
};

// Reducer
const INITIAL_STATE = {
  token: null,
  loading: false,
  permissions: [],
  customer: -1,
  user: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CUSTOMER_SELECTED:
      return { ...state, customer: action.payload };
    case Types.AUTHENTICATED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        token: {
          ...action.payload,
          accessToken:
            action.payload && action.payload.hasOwnProperty("access_token")
              ? action.payload.access_token
              : null,
        },
      };
    case Types.ASYNC_SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.SIGN_IN_SUCCESS:
      return {
        ...state,
        token: action.payload.tokenData,
        loading: false,
      };
    case Types.SIGN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.ADD_LOGOUT:
      return {
        ...state,
        token: null,
        loading: false,
        user: {},
      };
    case Types.LOAD_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case Types.REQUEST_GET_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_GET_CUSTOMERS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case Types.FAILURE_GET_CUSTOMERS:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_CUSTOMER:
      return { ...state, customer: action.payload };
    case Types.SET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
export const authenticate = (values) => ({
  type: Types.ASYNC_SIGN_IN_REQUEST,
  payload: values,
});

export const loadAuth = () => ({ type: Types.ASYNC_LOAD_AUTHENTICATE });

export const loginSSO = (sso, code) => ({
  type: Types.ASYNC_SSO_AUTHENTICATE,
  payload: { sso, code },
});

export const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["loading"],
  timeout: null,
};

export const setUser = (data) => ({
  type: Types.SET_USER,
  payload: data,
});
