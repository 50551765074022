import { fromRiskZoneApi } from "./risk-zone.mapper";

export const fromAreaApi = (data) => {
  const response = {
    id: data.id,
    name: data.name,
    point: data.point,
    line: data.line,
    polygon: data.polygon,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    deletedAt: data.deletedAt,
    areaInformation: data.areaInformation,
    unitId: data.unit_id,
  };

  if (data.riskZone) {
    response.riskZone = fromRiskZoneApi(data.riskZone);
  }

  return response;
};
