import React from "react";
import { View } from "@react-pdf/renderer";

import { styles } from "./styles";

export const Row = ({ children, last, customcustomStyle = {}, ...rest }) => {
  const rowStyle = last
    ? styles.row
    : { ...styles.row, borderBottom: undefined };

  return (
    <View style={{ ...rowStyle, ...customcustomStyle }} {...rest}>
      {children}
    </View>
  );
};
