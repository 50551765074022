import truckIcon from "./icone_caminhao.png";
import cellphoneIcon from "./icone_mobile.png";
import motoIcon from "./icone_motocicleta.png";
import carIcon from "./icone_carro.png";
import helicopterIcon from "./icone_helicoptero.png";
import portableIcon from "./icone_rastreador.png";

const truckIconRender = () => {
  return truckIcon;
};

const cellphoneIconRender = () => {
  return cellphoneIcon;
};

const motoIconRender = () => {
  return motoIcon;
};

const carIconRender = () => {
  return carIcon;
};
const helicopterIconRender = () => {
  return helicopterIcon;
};
const portableIconRender = () => {
  return portableIcon;
};
export const factoryDeviceIcons = {
  1: cellphoneIconRender,
  2: truckIconRender,
  3: motoIconRender,
  4: carIconRender,
  5: helicopterIconRender,
  6: portableIconRender,
};
