export const positionHistoryHeaderCells = [
  "Data e Hora",
  "Localização",
  "Velocidade",
  "Ignição",
  "Latitude",
  "Longitude",
  "Bateria",
];

export const identifierInfoTableCells = [
  "Placa",
  "Identificador",
  "Dist. Percorrida",
  "Tempo Parado",
  "Velocidade Média",
  "Tempo em Movimento",
];

export const allIdentifiersTable = ["Identificador"];
