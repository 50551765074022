import React from "react";
import styles from "../stylesNew";
import { CustomTextField } from "../../TableTransferNew/style";
import { Autocomplete } from "@mui/material";

const SearchAutoComplete = ({
  placeholder,
  options = [],
  getOptionLabel = (option) => option.name,
  onChange = () => {},
}) => {
  return (
    <Autocomplete
      options={options}
      style={styles.autocompleteField}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
        />
      )}
    />
  );
};

export default SearchAutoComplete;
