export const fromRiskZoneApi = (data) => {
  return {
    id: data.id,
    areaId: data.areaId,
    criminalFactionId: data.criminalFactionId,
    typeId: data.typeId,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    deletedAt: data.deletedAt,
  };
};
