import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  h1 {
    top: 32px;
    left: 97px;
    margin-left: 25px;
    width: 136px;
    height: 38px;
    text-align: center;
    letter-spacing: 0px;
    color: #14162F;
    opacity: 1;
  }
`;

