import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Paper,
  TableHead,
  Button,
  IconButton,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import {
  handleGetUnits,
  handleDeleteUnit,
} from "../../../services/customerAreaManagement/customerUnit";
import { useStyles } from "./styles";
import { format, parseISO, subHours } from "date-fns";
import CentralizedLoading from "../../../components/CentralizedLoading";
import CustomerDialog from "./customerDialog";
import DeleteDialog from "./deleteDialog";
import EditDialog from "./editDialog";
import { useSelector } from "react-redux";

const rows = [
  {
    id: "ID",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "Description",
    numeric: true,
    disablePadding: false,
    label: "Descrição",
  },
  { id: "Date", numeric: true, disablePadding: false, label: "Data" },
  { id: "Edit", numeric: true, disablePadding: false, label: "Editar" },
  { id: "Remove", numeric: true, disablePadding: false, label: "Remover" },
];

class EnhancedTableHead extends React.Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                sortDirection={""}
                numeric={row.numeric}
                padding={"15px"}
              >
                {row.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
});

let EnhancedTableToolbar = (props) => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.spacer} />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

const CustomerUnitTable = (props) => {
  const [data, setData] = useState(false);
  const [dialogCreate, setDialogCreate] = useState(false);
  const [dialogEdit, setDialogEdit] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [itemSelect, setItemSelect] = useState({});
  const customerId = useSelector((store) => store.auth.customer.customer);

  const getDataTable = async () => {
    const response = await handleGetUnits(customerId);
    return setData(response);
  };

  useEffect(() => {
    getDataTable();
  }, [props.tab]);

  const classesB = useStyles();

  const { classes, datas } = props;

  const formatDate = (dateString) => {
    if (dateString === "") {
      return null;
    }
    const dateTz = subHours(parseISO(dateString), 3);

    return format(dateTz, "dd'/'MM'/'yyyy '");
  };

  const handleClickOpen = () => {
    setDialogCreate(true);
  };

  const handleClose = () => {
    setDialogCreate(false);
  };

  const openDialogDelete = (item) => {
    setItemSelect(item);
    setDialogDelete(true);
  };

  const closeDialogDelete = () => {
    setDialogDelete(false);
  };

  const openDialogEdit = (item) => {
    setItemSelect(item);
    setDialogEdit(true);
  };

  const closeDialogEdit = () => {
    setDialogEdit(false);
  };

  const customerDelete = async (customerId, id) => {
    await handleDeleteUnit(customerId, id);
    getDataTable();
  };

  return (
    <Paper className={classes.root}>
      <div className={classesB.divButton}>
        <Button
          className={classesB.txtButton}
          variant="contained"
          onClick={handleClickOpen}
        >
          Adicionar
        </Button>
        <CustomerDialog
          close={handleClose}
          open={dialogCreate}
          reloadTable={getDataTable}
        />
        <DeleteDialog
          close={closeDialogDelete}
          open={dialogDelete}
          data={itemSelect}
          deleted={customerDelete}
          reloadTable={getDataTable}
        />
        <EditDialog
          close={closeDialogEdit}
          open={dialogEdit}
          data={itemSelect}
          reloadTable={getDataTable}
        />
      </div>
      <EnhancedTableToolbar />
      <div className={classes.tableWrapper}>
        {data !== false ? (
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody className={classes.tableBody} padding="15px">
              {data.map((n) => {
                return (
                  <TableRow hover tabIndex={-1} key={n.id}>
                    <TableCell component="th" scope="row" padding="15px">
                      {n.id}
                    </TableCell>
                    <TableCell numeric>{n.name}</TableCell>
                    <TableCell numeric>{formatDate(n.created_at)}</TableCell>
                    <TableCell numeric>
                      <IconButton onClick={() => openDialogEdit(n)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell numeric>
                      <IconButton onClick={() => openDialogDelete(n)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <CentralizedLoading text="Buscando dados..." />
        )}
      </div>
    </Paper>
  );
};

CustomerUnitTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomerUnitTable);
