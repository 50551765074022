import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { IconButton, InputAdornment } from "@mui/material";
import { AddOutlined as AddOutlinedIcon } from "@mui/icons-material";
import { useGoogleMapsKey } from "../../../../hooks/useGoogleMapsKey";
import { useLoadScript } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import {
  addWaypointAction,
  setAddressCard,
  startLoadingDirectionsAction,
} from "../../../../store/ducks/scripting";
import { toast } from "material-react-toastify";
import { CustomTextField } from "./styles";

const libraries = ["places", "drawing", "geometry"];

export const PlacesAutocompleteComponent = () => {
  const waypoints = useSelector((state) => state.scripting.waypoints);
  const typeCard = useSelector((state) => state.scripting.typeCard);
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const googleMapsKey = useGoogleMapsKey();
  const dispatch = useDispatch();

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setCoordinates(latLng);
    } catch (error) {
      toast.error("Error fetching geolocation data:");
    }
  };

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  const saveAddress = async () => {
    if (!address) return toast.error("Adicione um endereço.");

    let formattedAddress = null;
    let position = null;
    try {
      const [locationData] = await geocodeByAddress(address);
      formattedAddress = locationData.formatted_address || address;
      position = {
        latitude: locationData.geometry.location.lat(),
        longitude: locationData.geometry.location.lng(),
      };
    } catch (error) {
      return toast.error("Endereço não existe.");
    }

    for (const waypoint of waypoints) {
      if (
        waypoint.position.latitude === position.latitude &&
        waypoint.position.longitude === position.longitude
      )
        return toast.error("Endereço já cadastrado.");
    }

    const waypointData = {
      id: Math.random().toString(36),
      location: formattedAddress,
      isStop: true,
      title: "",
      duration: "",
      position,
    };

    dispatch(setAddressCard(waypointData));

    dispatch(addWaypointAction(waypointData));
    dispatch(startLoadingDirectionsAction());
    setAddress("");
  };

  if (!isLoaded) {
    return <></>;
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ padding: "1rem" }}>
          <CustomTextField
            label="Informe o endereço"
            variant="outlined"
            size="sm"
            type="search"
            {...getInputProps({ placeholder: "Digite o endereço" })}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ marginRight: "10px" }}>
                  <IconButton onClick={saveAddress}>
                    <AddOutlinedIcon
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div style={{ zIndex: 10 }}>
            {loading && <div style={{ marginLeft: "20px" }}>Carregando...</div>}
            {suggestions.map((suggestion, index) => {
              const style = {
                width: "250px",
                lineHeight: 1.5,
                backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                cursor: "pointer",
              };
              return (
                <div
                  key={index}
                  {...getSuggestionItemProps(suggestion, { style })}
                >
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
