export const typesCustomerActivities = {
  CUSTOMER_ACTIVITIES: "CUSTOMER_ACTIVITIES",
};

const INITIAL_VALUES_CUSTOMER_ACTIVITIES = {};

export const customerActivities = (
  state = INITIAL_VALUES_CUSTOMER_ACTIVITIES,
  action
) => {
  if (action.type == typesCustomerActivities.CUSTOMER_ACTIVITIES) {
    const activities = {};
    action.payload.map((unit) => {
      activities[unit.id] = unit.name;
    });
    return activities;
  } else {
    return { ...state };
  }
};

export const fillCustomerActivitiesAction = (customerActivities) => ({
  type: typesCustomerActivities.CUSTOMER_ACTIVITIES,
  payload: customerActivities,
});
