import React from 'react';
import {
  parseISO,
  formatDistance,
  isValid as checkIsValidDate,
} from 'date-fns';
import { pt } from 'date-fns/locale';

import { capitalizeFirstLetter } from '../../../commons/utils/text'

export default function UpdatedAt({ takenAt = null }) {
  const formatDate = (dateString) =>{
    if (dateString === null)
      return null

    const date = parseISO(dateString)

    if (!checkIsValidDate(date))
      return null

    const dateFormated = formatDistance(
      date,
      new Date(),
      {
        locale: pt,
        addSuffix: true,
      }
    )
    
    return dateFormated
  }

  const takenAtString = (takenAt !== null)
    ? capitalizeFirstLetter(formatDate(takenAt))
    : 'Nunca foi atualizado'

  return (
    <span>{takenAtString}</span>
  )
}