import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import {
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { CustomTextField } from "../stylesNew";

export default function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();

  function addFromaction(from) {
    return { type: "ADD_FORMFROM", from };
  }

  const handleChange = (value) => {
    setAddress(value);
    dispatch(addFromaction(value));
  };

  const handleSelect = async (value) => {
    setAddress(value);
    dispatch(addFromaction(value));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <CustomTextField
              {...getInputProps({ placeholder: "Digite o endereço" })}
              variant="outlined"
              id="origin-auto-complete"
              type="search"
              onClick={handleClick}
              onSelect={handleClick}
              style={{ width: "100%", height: 40 }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Popper
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              style={{
                zIndex: 3,
                width: anchorEl ? anchorEl.clientWidth : 0,
                placement: "left-start",
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
            >
              <List style={{ width: "100%", marginTop: 0, padding: 0 }}>
                {suggestions.slice(0, 3).map((suggestion, index) => {
                  const style = {
                    height: 25,
                    backgroundColor: suggestion.active ? "#06B0CF" : "#fff",
                  };

                  return (
                    <ListItem
                      key={index}
                      {...getSuggestionItemProps(suggestion, { style })}
                    >
                      <ListItemText>
                        <Typography noWrap>{suggestion.description}</Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Popper>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
