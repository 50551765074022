export default {
  root: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 270,
  },

  customInputStyles: {
    padding: '9px 16px',
    height: '100%',
  },

  customNotchedOutlineStyles: {
    borderRadius: 10
  },

  adornmentIcon: {
    color: '#999999'
  }
};
