import React, { useRef, useState } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";

import { useSelector } from "react-redux";
import { mapsOptions } from "../../../pages/ScriptingNew/ScriptingMap/scriptingMapOptions";
import { useGoogleMapsKey } from "../../../hooks/useGoogleMapsKey";
import { ContainerMap } from "./styles";
import { DirectionsReportTransfer } from "./Direction";

const libraries = ["places", "drawing", "geometry"];

export const ReportTransferMap = () => {
  const mapType = useSelector((store) => store.monitoringMap.mapType);
  const [mapCenter, setMapCenter] = useState({
    lat: -22.9122724,
    lng: -43.1770578,
  });
  const options = { ...mapsOptions(mapType) };

  const googleMapsKey = useGoogleMapsKey();
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  if (!isLoaded) {
    return <></>;
  }

  return (
    <ContainerMap>
      <GoogleMap
        id="scripting-map"
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        onLoad={null}
        center={mapCenter}
        zoom={8}
        options={options}
      >
        <DirectionsReportTransfer setMapCenter={setMapCenter} />
      </GoogleMap>
    </ContainerMap>
  );
};
