import { multiPolygon, polygon } from "@turf/helpers";
import centerOfMass from "@turf/center-of-mass";

export const types = {
  SEARCH_BY_DEVICE: "SEARCH_BY_DEVICE",
  ALIAS_FIELD: "ALIAS_FIELD",
  DEVICE_ICON_ID_FIELD: "DEVICE_ICON_ID_FIELD",
  EVENTS_FIELD: "EVENTS_FIELD",
  RESET_VALUES: "RESET_VALUES",
  LOADING_DATA_API: "LOADING_DATA_API",
  SELECT_CAR_ID: "SELECT_CAR_ID",
  ADD_POLYGON_VIRTUAL_FENCE: "ADD_POLYGON_VIRTUAL_FENCE",
  REMOVE_POLYGON_VIRTUAL_FENCE: "REMOVE_POLYGON_VIRTUAL_FENCE",
  TOGGLE_INFO_WINDOW_VISIBILITY: "TOGGLE_INFO_WINDOW_VISIBILITY",
};

const INITIAL_VALUES = {
  identifier: "",
  alias: "",
  deviceIconId: 0,
  events: [],
  deviceSettingsId: null,
  polygons: {},
  carId: null,
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.SEARCH_BY_DEVICE:
      return {
        ...state,
        identifier: action.payload,
      };

    case types.ALIAS_FIELD:
      return {
        ...state,
        alias: action.payload,
      };

    case types.DEVICE_ICON_ID_FIELD:
      return {
        ...state,
        deviceIconId: Number(action.payload),
      };

    case types.EVENTS_FIELD:
      return {
        ...state,
        events: action.payload,
      };

    case types.RESET_VALUES:
      return {
        alias: "",
        deviceIconId: 0,
        events: [],
        polygons: {},
      };

    case types.LOADING_DATA_API:
      const coordinates = action.payload?.virtual_fence?.coordinates || [];
      const polygonsArray = coordinates.map((coordinate, index) => {
        const poly = polygon(coordinate);
        const center = centerOfMass(poly);
        const [lng, lat] = center.geometry.coordinates;

        return {
          id: new Date().getTime() - index,
          infoWindow: {
            visible: false,
            position: {
              lat,
              lng,
            },
          },
          coordinates: coordinate,
        };
      });

      const polygonsObj = polygonsArray.reduce((prev, acc) => {
        prev[acc.id] = acc;
        return prev;
      }, {});

      return {
        ...state,
        alias: action.payload?.device_alias?.alias,
        carId: action.payload?.device_alias?.car_id,
        deviceIconId: action.payload?.device_icon_id,
        events: action.payload?.events_types,
        deviceSettingsId: action.payload?.id,
        polygons: polygonsObj,
      };

    case types.ADD_POLYGON_VIRTUAL_FENCE:
      return {
        ...state,
        polygons: {
          ...state.polygons,
          [action.payload.key]: action.payload.value,
        },
      };

    case types.TOGGLE_INFO_WINDOW_VISIBILITY:
      const poly = state.polygons[action.payload.polygonId];
      poly.infoWindow.visible = !poly.infoWindow.visible;

      return {
        ...state,
        polygons: {
          ...state.polygons,
          [action.payload.polygonId]: {
            ...poly,
          },
        },
      };

    case types.REMOVE_POLYGON_VIRTUAL_FENCE:
      const polygons = state.polygons;
      delete polygons[action.payload.polygonId];

      return {
        ...state,
        polygons: {
          ...polygons,
        },
      };
    case types.SELECT_CAR_ID:
      return { ...state, carId: action.payload.carId };
    default:
      return { ...state };
  }
};

//actions
export const searchBarByDevice = (searchString) => ({
  type: types.SEARCH_BY_DEVICE,
  payload: searchString,
});

export const aliasField = (alias) => ({
  type: types.ALIAS_FIELD,
  payload: alias,
});

export const deviceIconField = (deviceIconId) => ({
  type: types.DEVICE_ICON_ID_FIELD,
  payload: deviceIconId,
});

export const eventsField = (eventFields) => ({
  type: types.EVENTS_FIELD,
  payload: eventFields,
});

export const resetForm = () => ({
  type: types.RESET_VALUES,
});

export const loadingDataApi = (data) => ({
  type: types.LOADING_DATA_API,
  payload: data,
});

export const addPolygonVirtualFence = (key, value) => ({
  type: types.ADD_POLYGON_VIRTUAL_FENCE,
  payload: {
    key,
    value,
  },
});

export const toggleInfoWindowVisibility = (polygonId) => ({
  type: types.TOGGLE_INFO_WINDOW_VISIBILITY,
  payload: {
    polygonId,
  },
});

export const removePolygonVirtualFence = (polygonId) => ({
  type: types.REMOVE_POLYGON_VIRTUAL_FENCE,
  payload: {
    polygonId,
  },
});

export const handleSelectCarId = (carId) => ({
  type: types.SELECT_CAR_ID,
  payload: {
    carId,
  },
});
