import { areasApi } from "..";
import { appId } from "../../../commons/constants/app";
import { fromPOIApi } from "../mappers/poi.mapper";

export const getPOIs = async ({ typeIds, nearby, intersects }) => {
  const response = await areasApi.post("/points-of-interest", {
    application_id: appId,
    pointTypeId: typeIds,
    nearby,
    intersects,
  });

  return response.data.map(fromPOIApi);
};
