export const typesCustomerProducts = {
  CUSTOMER_PRODUCTS: "CUSTOMER_PRODUCTS",
};

const INITIAL_VALUES_CUSTOMER_PRODUCTS = {};

export const customerProducts = (
  state = INITIAL_VALUES_CUSTOMER_PRODUCTS,
  action
) => {
  if (action.type == typesCustomerProducts.CUSTOMER_PRODUCTS) {
    const products = {};
    action.payload.map((product) => {
      products[product.id] = product.name;
    });
    return products;
  } else {
    return { ...state };
  }
};

export const fillCustomerProductsAction = (customerProducts) => ({
  type: typesCustomerProducts.CUSTOMER_PRODUCTS,
  payload: customerProducts,
});
