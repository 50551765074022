import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  paginationButtons: {
    flexShrink: 0,
    marginLeft: 32,
  }
}))

export default useStyles

