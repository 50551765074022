import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import LegendBadge from "./components/LegendBadge";

import styles from "./styles";

const Legend = ({ classes }) => {
  return (
    <div className={classes.container}>
      <Typography className={classes.text}>
        <LegendBadge color="#63a9ef" />
        Rota Prevista
      </Typography>
      <Typography className={classes.text}>
        <LegendBadge color="#0000ff" />
        Rota Percorrido pelo veículo
      </Typography>
      <Typography className={classes.text}>
        <LegendBadge color="#f5e000" />
        Rotas que ocorreram eventos
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Legend);
