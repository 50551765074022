import devicesApi from "../devices";

export const handleGetActivities = async (customerId) => {
  try {
    const response = await devicesApi.get(`/customer/${customerId}/activities`);
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handlePostActivity = async (customerId, name) => {
  try {
    const response = await devicesApi.post(
      `/customer/${customerId}/transfer-activities`,
      {
        name,
      }
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handleDeleteActivity = async (id) => {
  try {
    const response = await devicesApi.delete(`/customer/${id}/activities`);
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handleEditActivity = async (customerId, id, name) => {
  try {
    const response = await devicesApi.put(
      `/customer/${customerId}/activities/${id}`,
      {
        name,
      }
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};
