import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 600,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  divButton: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "15px",
    marginLeft: "20px",
    gap: "20px",
  },
  styleButton: {
    color: "white",
    backgroundColor: "#06b0cf",
    "&:hover": {
      backgroundColor: "#06b0cf",
    },
  },
}));
