import React from 'react';
import { useDispatch } from 'react-redux';

import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import 'date-fns';

function FilterAlerts() {

  const dispatch = useDispatch();

    function addIdentifierAction(identifierSearchNotificationSidebar) {
      return { type: 'IDENTIFIER_SEARCH_NOTIFICATION_SIDEBAR', identifierSearchNotificationSidebar }
    }

    const addIdentifier = event => {
      const value = event.target.value
      dispatch(addIdentifierAction( value ))
    }

  return (
      <>
          <TextField className="search-identifier"
            style={{width: 260}}
            onChange={addIdentifier}
            label="Identificador"
            InputLabelProps={{
              style: {
                marginTop: -3
              }
            }}
            type="search"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                height: 32,
              }
          }} />
      </>
  )
}

export default FilterAlerts;
