import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Geocode from "react-geocode";
import { format, parseISO, subHours } from "date-fns";

import Form from "./Form";
import Processing from "./Processing";
import Success from "./Success";

import * as FlespiService from "../../services/flespiApiService";
import * as TreatmentService from "../../services/eventAnalyticsApi/treatmentService";

import {
  addAlertsFlespiAction,
  addCenterMapAction,
  addMessagesAreas,
  addRenderAction,
  addTransferIdTreatment,
  addValidateAlertPinAction,
  addZoomInAction,
  handleDisabledDetailsButtons,
} from "../../store/ducks/monitoringMap";
import { useGoogleMapsKey } from "../../hooks/useGoogleMapsKey";

const SCREENS = {
  FORM: 1,
  PROCESSING: 2,
  SUCCESS: 3,
};

export default function TreatmentAlert() {
  const dispatch = useDispatch();
  const googleMapsKey = useGoogleMapsKey();

  const [address, setAddress] = useState("");
  const [device, setDevice] = useState({});
  const [eventCoordinate, setEventCoordinate] = useState({});
  const [message, setMessage] = useState("");
  const [screen, setScreen] = useState(SCREENS.FORM);

  const disabledDetailsButtons = useSelector(
    (store) => store.monitoringMap.disabledDetailsButtons
  );
  const event = useSelector((store) => store.monitoringMap.eventDetails);
  const deviceId = useSelector((store) => store.monitoringMap.device_id);
  const trackings = useSelector((store) => store.monitoringMap.devices);
  const customerId = useSelector((store) => store.auth.customer.customer);
  const events = useSelector((store) => store.monitoringMap.alertsFlespi);
  Geocode.setApiKey(googleMapsKey);
  Geocode.setLanguage("pt");

  const addZoomIn = (zoom) => {
    dispatch(addZoomInAction(zoom));
  };

  const addCenterMap = (centerMap) => {
    dispatch(addCenterMapAction(centerMap));
  };

  const addRender = (render) => {
    dispatch(addRenderAction(render));
  };

  const addValidationPin = (validateAlertPin) => {
    dispatch(addValidateAlertPinAction(validateAlertPin));
  };

  const tracking = trackings.find(
    (tracking) => tracking.device.id === deviceId
  );

  addRender(false);

  useEffect(() => {
    const getAddress = async () => {
      const coordinate = event?.coordinates[0];

      if (!coordinate) {
        return;
      }

      const [longitude, latitude] = coordinate.point.coordinates;

      if (event.eventTypeId === 2) {
        const { name } = coordinate.basedOn ?? {};
        dispatch(addMessagesAreas(coordinate.basedOn));
        setMessage(name);
      }

      setEventCoordinate({
        latitude,
        longitude,
      });

      const response = await Geocode.fromLatLng(latitude, longitude);
      const address = response.results[0].formatted_address;

      setAddress(address);
    };

    getAddress();
  }, [event, dispatch]);

  useEffect(() => {
    setScreen(SCREENS.FORM);
  }, [event]);

  const removeFromUnhandledEventsList = (eventId) => {
    const newUnhandledEventsList = events.filter(
      (event) => event.id !== eventId
    );
    dispatch(addAlertsFlespiAction(newUnhandledEventsList));
  };

  const disableDetailsButtons = (arrayIds) => {
    dispatch(handleDisabledDetailsButtons(arrayIds));
  };

  async function handleTreatment({ description }) {
    if (!description) {
      alert("É necessário preencher a descrição");
      return;
    }

    setScreen(SCREENS.PROCESSING);

    try {
      disableDetailsButtons([...disabledDetailsButtons, event.id]);

      await TreatmentService.treatEvent(event.id, customerId, {
        justification: description,
      });

      removeFromUnhandledEventsList(event.id);
      setScreen(SCREENS.SUCCESS);
      addRender(true);
      addValidationPin(false);
    } catch (error) {
      setScreen(SCREENS.FORM);
      alert("Erro ao tratar");
    } finally {
      disableDetailsButtons(
        disabledDetailsButtons.filter((id) => id !== event.id)
      );
    }
  }

  const handlePinAlertAddress = () => {
    if (!eventCoordinate) {
      return;
    }

    addZoomIn(17);

    addCenterMap({
      lat: eventCoordinate.latitude,
      lng: eventCoordinate.longitude,
    });

    if (tracking?.transfer?.id) {
      dispatch(addTransferIdTreatment(tracking.transfer.id));
    }

    addValidationPin(true);
  };

  const formatDate = (dateString) => {
    if (dateString === "") {
      return null;
    }
    const dateTz = subHours(parseISO(dateString), 3);

    return format(dateTz, "'Ocorrido em' dd'/'MM'/'yyyy 'às' HH:mm:ss");
  };
  const screens = {
    [SCREENS.FORM]: (
      <Form
        address={address}
        date={formatDate(event.startedAt)}
        deviceName={
          device.alias && device.alias !== "" ? device.alias : device.identifier
        }
        onClickAlertAddress={handlePinAlertAddress}
        onSubmit={handleTreatment}
        event={event}
        message={message}
      />
    ),
    [SCREENS.PROCESSING]: <Processing />,
    [SCREENS.SUCCESS]: <Success />,
  };

  return screens[screen];
}
