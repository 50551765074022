const styles = {
  loading: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingImage: {
    marginBottom: 12,
  },
}

export default styles