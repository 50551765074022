import React from "react";
import { Polyline } from "@react-google-maps/api";
import { useSelector } from "react-redux";

export default function LastPositionsWay() {
  const lastPositionsHour = useSelector(
    (store) => store.monitoringMap.lastPositions
  );

  const optionsPolyline = {
    strokeColor: "blue",
    strokeOpacity: 1,
    strokeWeight: 4,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  return <Polyline path={lastPositionsHour} options={optionsPolyline} />;
}
