import eventAnalyticsAPI from '../eventAnalytics';

export const getScript = async (customerId) => {
    try {
        const response = await eventAnalyticsAPI.get(`/customers/${customerId}/get-script`);
        return response.data;
    } catch ({ response }) {
        return response;
    }
};

