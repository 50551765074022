import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  dialogContent: {
    width: 700,
    maxWidth: '100%',
  },

  textFieldError: {
    borderColor: '#9e1000'
  },

  dialogTitle: {
    marginVertical: 10,
  }
})

export default useStyles