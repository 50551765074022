import React from 'react'

import {
  Button,
  Tooltip,
} from '@material-ui/core'

import {
  Delete as DeleteIcon,
} from '@material-ui/icons'

import useStyles from './styles'

const DeleteButton = ({
  disabled,
  buttonTooltip,
  handleClickOpen = () => { },
}) => {
  const classes = useStyles()

  const buttonClass = disabled
    ? classes.buttonDisabled
    : classes.button

  const deleteIconClass = disabled
    ? classes.deleteIconDisabled
    : classes.deleteIcon

  const buttonComponent = (
    <Button
      onClick={handleClickOpen}
      variant='contained'
      color='secondary'
      fontSize='inherit'
      TouchRippleProps={{
        classes: {
          child: classes.ripple,
        },
      }}
      classes={{
        root: buttonClass,
      }}
      disabled={disabled}
    >
      <DeleteIcon
        fontSize='small'
        classes={{
          root: deleteIconClass
        }}
      />
    </Button>
  )

  if (disabled)
    return buttonComponent

  return (
    <Tooltip
      title={buttonTooltip}
      placement='top'
      arrow
    >
      {buttonComponent}
    </Tooltip>
  )
}

export default DeleteButton