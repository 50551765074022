import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import axios from "axios";

const getTimestamp = (date) => {
  const timestamp = new Date(date).getTime();
  return Math.floor(timestamp / 1000);
};

export const useIdentifierReport = (
  currentTransfer,
  filters,
  currentDevice
) => {
  const [flespiReport, setFlespiReport] = useState([]);
  const [device, setDevice] = useState(null);
  const { customer } = useSelector((store) => store.auth.customer);
  const { deviceApiResponse } = useSelector((store) => store.reportData);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const currentDeviceTransfer = currentTransfer?.associated_devices
    .filter((n) => n.id == currentDevice)
    .map((n) => n.identifier);

  useEffect(() => {
    const fetchFlespiReport = async () => {
      try {
        setErrorMessage("");
        setLoading(true);
        const url = "https://flespi.io/gw/devices";
        const headers = {
          Authorization: `FlespiToken ${process.env.REACT_APP_API_FLESPI_TOKEN}`,
        };

        let allDevices;

        if (deviceApiResponse !== []) {
          allDevices = deviceApiResponse;
        }

        let foundDeviceByName = {};

        for (const device of allDevices) {
          let deviceName = "";

          if (
            !isEmpty(currentTransfer) &&
            currentTransfer.associated_devices.length
          ) {
            deviceName = currentDeviceTransfer[0];
          }

          if (filters.identifier) {
            deviceName = filters.identifier;
          }

          if (device.identifier === deviceName) {
            foundDeviceByName = device;
            break;
          }
        }

        if (isEmpty(foundDeviceByName)) {
          setLoading(false);
          setErrorMessage(
            <>
              Selecione um identificador e clique em <strong>Buscar.</strong>
            </>
          );
          return null;
        }

        setDevice(foundDeviceByName);

        const from = getTimestamp(filters.from);
        const to = getTimestamp(filters.to);

        const requestUrl = `${url}/${foundDeviceByName.flespi_id}/messages?data={"from":${from},"reverse":true,"to":${to}}`;
        const { data: flespiResult } = await axios.get(requestUrl, { headers });
        setFlespiReport(flespiResult);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchFlespiReport();
  }, [currentTransfer, filters, customer, deviceApiResponse]);

  return { flespiReport, isLoading, errorMessage, device };
};
