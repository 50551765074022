export default {
  paper: {
    backgroundColor: "#fff",
    padding: 10,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  eventInfoContainer: {
    background: "#fff",
    padding: 10
  },
  checkboxCoordContainer: {
    background: "#fff",
    textAlign: "right",
    padding: "2px",
  },
  mapContainer: {
    background: "#fff",
    flex: 1,
    minHeight: "250px",
    marginBottom: "10px",
  },
};
