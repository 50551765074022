import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Button,
  Checkbox,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
  Warning as WarningIcon,
  Room as RoomIcon,
  GroupWork as GroupWorkIcon,
  Create as CreateIcon,
} from "@material-ui/icons";

import { WaypointsSidebarList } from "./WaypointsSidebarList";

import {
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Avatar,
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import {
  setIncidentsNaturesAction,
  setSidebarContentOpen,
} from "../../../store/ducks/scripting";
import { addCustomerPointsOfInterestTypes } from "../../../store/ducks/areas";
import { setActiveRiskLayers } from "../../../store/ducks/scripting";
import OccurrencesOptions from "./OccurrencesOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  rootList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const ExpansionOptions = () => {
  const { incidentsNatures } = useSelector((state) => state.scripting);

  const customerPointOfInterestTypes = useSelector(
    (store) => store.areas.customerPointOfInterestTypes
  );

  const activeRiskLayers = useSelector(
    (store) => store.scripting.activeRiskLayers
  );

  const dispatch = useDispatch();
  const classes = useStyles();
  const handleChangeAlerts = useCallback(
    (isChecked, nature) => {
      const newTypesSelection = nature.types.map((type) => ({
        ...type,
        selected: isChecked,
      }));

      const newIncidentsNatureArray = incidentsNatures.map((incident) => {
        if (incident.id === nature.id)
          return { ...nature, selected: isChecked, types: newTypesSelection };
        else return incident;
      });

      dispatch(setIncidentsNaturesAction(newIncidentsNatureArray));
    },
    [incidentsNatures]
  );

  const handleChangeCustomerPOI = useCallback(
    (isChecked, type) => {
      const newPOITypesArray = customerPointOfInterestTypes.map((POIType) => {
        if (POIType.id === type.id) return { ...POIType, selected: isChecked };
        else return POIType;
      });

      dispatch(addCustomerPointsOfInterestTypes(newPOITypesArray));
    },
    [customerPointOfInterestTypes]
  );

  const handleActiveRiskLayers = (checked, riskLayerId) => {
    if (checked) {
      return dispatch(setActiveRiskLayers([...activeRiskLayers, riskLayerId]));
    }
    const newArrayRiskLayer = activeRiskLayers.filter(
      (id) => id !== riskLayerId
    );
    dispatch(setActiveRiskLayers(newArrayRiskLayer));
  };

  const risksLayers = [
    { id: 185, description: "Comunidades e facções criminosas" },
    { id: 193, description: "Aglomerados Subnormais" },
    { id: 4, description: "Área Urbana de Risco" },
    { id: 1, description: "Unidade de Policia Pacificadora(UPP)" },
  ];

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ display: "flex" }} className={classes.heading}>
            <RoomIcon fontSize="small" style={{ paddingRight: 5 }} />
            Desvios e Paradas
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Button
              style={{ borderRadius: 8, height: 48 }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => dispatch(setSidebarContentOpen("waypoints"))}
            >
              Editar {<CreateIcon style={{ display: "flex" }} />}
            </Button>
            <WaypointsSidebarList />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{ display: "flex" }} className={classes.heading}>
            <WarningIcon fontSize="small" style={{ paddingRight: 5 }} />
            Alertas
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List className={classes.rootList}>
            <Button
              startIcon={<Settings />}
              onClick={() =>
                dispatch(setSidebarContentOpen("naturesAdvancedOptions"))
              }
            >
              Opções avançadas
            </Button>
            {incidentsNatures &&
              incidentsNatures.map((nature) => (
                <ListItem key={nature.id} dense button>
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={nature.selected}
                      onChange={(e) =>
                        handleChangeAlerts(e.target.checked, nature)
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={nature.category} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end">
                      <Avatar src={nature.icon} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography style={{ display: "flex" }} className={classes.heading}>
            {<GroupWorkIcon fontSize="small" style={{ paddingRight: 5 }} />}
            Clipping de Segurança Pública
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <OccurrencesOptions />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography style={{ display: "flex" }} className={classes.heading}>
            <RoomIcon fontSize="small" style={{ paddingRight: 5 }} />
            Pontos de interesse do Cliente
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List className={classes.rootList}>
            {customerPointOfInterestTypes &&
              customerPointOfInterestTypes.map((type) => (
                <ListItem key={type.id} dense button>
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      onClick={(e) =>
                        handleChangeCustomerPOI(e.target.checked, type)
                      }
                      checked={type.selected}
                    />
                  </ListItemIcon>
                  <ListItemText primary={type.type} />
                  <ListItemSecondaryAction>
                    <Avatar src={type.icon} />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography style={{ display: "flex" }} className={classes.heading}>
            <WarningIcon fontSize="small" style={{ paddingRight: 5 }} />
            Áreas de risco
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List className={classes.rootList}>
            {risksLayers.map((item) => (
              <ListItem dense button>
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    onClick={(e) =>
                      handleActiveRiskLayers(e.target.checked, item.id)
                    }
                    checked={activeRiskLayers.includes(item.id)}
                  />
                </ListItemIcon>
                <ListItemText primary={item.description} />
              </ListItem>
            ))}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};
