import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Div, Container, StyledBtn, StyledBtnText } from "./styles";
import MenuSideBar from "../Sidebar/sidebar";
import { StyledTableHead } from "../TableTransferNew/style";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import {
  getReportTransferData,
  getReportTransferPDF,
} from "../../services/utransferApiV2/report-transfer";
import { ReportTransferMap } from "../Map/ReportTransfer";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Link } from "react-router-dom";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import { useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "material-react-toastify";
import * as dateFns from "date-fns";
import ModalSendEmailReport from "../ModalSendEmailReport";

export default function Report() {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const transferId = useSelector((state) => state.transfer.transferId);
  const [transfer, setTransfer] = useState({});
  const transferDate = new Date(transfer?.transferData?.scheduledDate);
  const formattedDate = dateFns.isValid(transferDate)
    ? dateFns.format(transferDate, "dd/MM/yyyy HH:mm:ss")
    : "-";
  const transferDataHeaders = [
    "Data e Hora prevista",
    "Evento",
    "Local de partida",
    "Local de Destino",
  ];
  const vehicleDataHeaders = ["Marca", "Modelo", "Placa", "Apelido/Descrição"];

  const handleClose = () => {
    setOpen((previous) => !previous);
  };

  useEffect(() => {
    const getTransfer = async () => {
      try {
        const transferData = await getReportTransferData(transferId);
        setTransfer(transferData);
      } catch (error) {
        toast.error("Erro ao requisitar dados do Transfer", error);
      }
    };

    const generatePDF = async () => {
      try {
        const response = await getReportTransferPDF(transferId);
        const blob = new Blob([response], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        setPdfUrl(url);
      } catch (error) {
        toast.error("Erro ao gerar PDF");
      }
    };

    generatePDF();
    getTransfer();
  }, []);

  return (
    <Div>
      <MenuSideBar />
      <div style={{ width: "100%" }}>
        <Paper style={{ marginTop: 0, width: "100%" }}>
          <Link to="/transfersNews">
            <StyledBtn>
              <ArrowBackOutlinedIcon style={{ color: "#000000" }} />
              <StyledBtnText>Voltar</StyledBtnText>
            </StyledBtn>
          </Link>

          {pdfUrl ? (
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              <StyledBtn>
                <PrintOutlinedIcon style={{ color: "#000000" }} />
                <StyledBtnText>Gerar PDF</StyledBtnText>
              </StyledBtn>
            </a>
          ) : (
            <StyledBtn disabled>
              <PrintOutlinedIcon style={{ color: "#000000" }} />
              <StyledBtnText>Gerar PDF</StyledBtnText>
            </StyledBtn>
          )}

          <StyledBtn onClick={() => setOpen((previous) => !previous)}>
            <ReplyOutlinedIcon style={{ color: "#000000" }} />
            <StyledBtnText>Encaminhar no email</StyledBtnText>
          </StyledBtn>
        </Paper>
        <Container>
          <Typography
            style={{ marginBottom: "20px" }}
            variant="h4"
            className="pageTitle"
          >
            Relatório
          </Typography>
          <Typography variant="h6" className="pageTitle">
            <strong>Trajeto:</strong>{" "}
            {`${transfer?.transferData?.from} X ${transfer?.transferData?.to}`}
          </Typography>

          <ModalSendEmailReport isOpen={open} onClose={handleClose} />

          <ReportTransferMap />

          <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
            <div style={{ marginLeft: "20px" }}>
              <Typography variant="h6" className="pageTitle">
                <strong>Dados do Transfer</strong>
              </Typography>
            </div>
            <Table>
              <StyledTableHead>
                <TableRow>
                  {transferDataHeaders.map((head) => (
                    <TableCell align="center" key={head}>
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{formattedDate}</TableCell>
                  <TableCell align="center">
                    {transfer?.transferData?.name}
                  </TableCell>
                  <TableCell align="center">
                    {transfer?.transferData?.from}
                  </TableCell>
                  <TableCell align="center">
                    {transfer?.transferData?.to}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper}>
            <div style={{ marginLeft: "20px" }}>
              <Typography variant="h6" className="pageTitle">
                <strong>Dados do Veículo</strong>
              </Typography>
            </div>
            <Table>
              <StyledTableHead>
                <TableRow>
                  {vehicleDataHeaders.map((head) => (
                    <TableCell align="center" key={head}>
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {transfer?.devicesWithCars?.map((device, index) => (
                  <TableRow key={`device-${index}`}>
                    <TableCell align="center">
                      {device.car?.maker.name || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {device.car?.model.name || "-"}
                    </TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">
                      {device.alias || device.identifier}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </div>
    </Div>
  );
}
