import truckIconOff from "./icone_caminhao_vermelho.png";
import cellPhoneIconOff from "./icone_mobile_vermelho.png";
import motoIconOff from "./icone_motocicleta_vermelha.png";
import carIconOff from "./icone_carro_vermelho.png";
import helicopterIconOff from "./icone_helicoptero_vermelho.png";
import portableIconOff from "./icone_rastreador_vermelho.png";

const truckIconOffRender = () => {
  return truckIconOff;
};

const cellPhoneIconOffRender = () => {
  return cellPhoneIconOff;
};

const motoIconOffRender = () => {
  return motoIconOff;
};

const carIconOffRender = () => {
  return carIconOff;
};

const helicopterIconOffRender = () => {
  return helicopterIconOff;
};
const portableIconOffRender = () => {
  return portableIconOff;
};

export const factoryDeviceIconsOff = {
  1: cellPhoneIconOffRender,
  2: truckIconOffRender,
  3: motoIconOffRender,
  4: carIconOffRender,
  5: helicopterIconOffRender,
  6: portableIconOffRender,
};
