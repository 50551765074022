import React, { useState } from "react";
import _ from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";

import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";

import EnhancedTableHead from "./tableHead";
import EnhancedTableBody from "./TableBody";
import { TableToolbar } from "./tableToolbar";
import { useStyles } from "./styles";

const identifiersSelected = _.flattenDeep(
  JSON.parse(localStorage.getItem("@UTransfer/identifiersSelected"))
);

export default function EnhancedTable({ startCall, finishCall }) {
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("taken_at");

  const [stateOfSnackBarWarning, setStateOfSnackBarWarning] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const handleOneClickClose = () => {
    setStateOfSnackBarWarning({ open: false });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          selected={identifiersSelected}
          numSelected={identifiersSelected.length}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleOneClickClose}
          open={stateOfSnackBarWarning.open}
          message="Dê duplo click para iniciar ou finalizar o transfer."
          key={
            stateOfSnackBarWarning.vertical + stateOfSnackBarWarning.horizontal
          }
        />
        <PerfectScrollbar className={classes.box}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <EnhancedTableBody
                order={order}
                orderBy={orderBy}
                setStateOfSnackBarWarning={setStateOfSnackBarWarning}
                identifiersSelected={identifiersSelected}
                startCall={startCall}
                finishCall={finishCall}
              />
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </Paper>
    </div>
  );
}
