import styled from "styled-components";

const styles = {
  autocompleteField: {
    padding: 0,
    flexGrow: 5,
    flexShrink: 0,
  },
};

export const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 3px;
  margin: 2px;
  margin-bottom: 30px;
  margin-left: 50px;
`;
export const Diiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const Container = styled.div`
  margin-top: 10px;
  button {
    border-radius: 12px;
  }
  button.create-transfer {
    background: #06b0cf;
  }
`;

export default styles;
