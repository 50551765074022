import React from "react";

import TableMonitoring from "../../components/TableMonitoringComponents/TableMonitoringFullPage";
import FilterMonitoring from "../../components/FilterMonitoring/searchFilterMonitoring";
import Sidebar from "../../components/Sidebar/sidebar";
import { Div, Container } from "./styles";

export default function Home() {
  return (
    <>
      <Div>
        <Sidebar />
        <Container>
          <main>
            <div className="header">
              <h1>Monitoramento</h1>
              <div className="search">
                <FilterMonitoring />
              </div>
            </div>
            <div className="table">
              <TableMonitoring />
            </div>
          </main>
        </Container>
      </Div>
    </>
  );
}
