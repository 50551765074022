import React, { useState, useEffect } from "react";
import { toast } from "material-react-toastify";
import { useStyles } from "./styles";

import { format, parseISO } from "date-fns";

import { Dialog, IconButton, Divider, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  KeyboardReturn as KeyboardReturnIcon,
  MicNone as MicNoneIcon,
  Mic as MicIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";

import { changeShowModalVoice } from "../../store/ducks/monitoringMap";
import { setRemoveNotification, setSendVoice } from "../../store/ducks/voice";

export const VoiceModal = ({ getPreviousVoices, sendVoices }) => {
  const dispatch = useDispatch();

  const [showDetail, setShowDetail] = useState(false);

  const [media, setMedia] = useState();
  const [record, setRecord] = useState(false);

  const { showModalVoice, call, voices, notifications } = useSelector(
    (state) => state.voice
  );

  const { user } = useSelector((state) => state.auth);
  const customerId = useSelector((state) => state.auth.customer.customer);

  const classes = useStyles();

  const handleRecordAudio = async () => {
    if (record) {
      await media.stop();
      return setRecord(false);
    }

    await media.start();
    setRecord(true);
  };

  useEffect(() => {
    getPreviousVoices({ id: call.call_id, customerId });
    dispatch(
      setRemoveNotification(
        notifications.filter(
          (notification) => notification.callId != call.call_id
        )
      )
    );
  }, []);

  useEffect(() => {
    let mediaRecorder;
    navigator.mediaDevices.getUserMedia({ audio: true }).then(
      (stream) => {
        mediaRecorder = new MediaRecorder(stream);
        const chunks = [];
        mediaRecorder.ondataavailable = ({ data }) => {
          chunks.push(data);
        };

        mediaRecorder.onstop = () => {
          const blob = new Blob(chunks, { type: "audio/ogg; code=opus" });
          const reader = new window.FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            sendVoices({
              attachment: reader.result,
              callId: call.id ? call.id : call.call_id,
              userId: user.id,
              customerId: user.customer.id,
            });

            dispatch(
              setSendVoice({
                sendBy: user.id,
                filename: "",
                url: reader.result,
              })
            );
          };
        };

        setMedia(mediaRecorder);
      },
      (err) => {
        toast.error("É nescessario liberar o microfone do navegador.");
      }
    );
  }, []);

  return (
    <Dialog open={showModalVoice}>
      {!showDetail ? (
        <div className={classes.divModal}>
          <div className={classes.divHeader}>
            <IconButton onClick={() => dispatch(changeShowModalVoice())}>
              <KeyboardReturnIcon />
            </IconButton>

            <div className={classes.divNameHeader}>
              <strong className={classes.strongNameHeader}>
                {call.call_transfer_id
                  ? `Transfer: ${call.call_transfer_id}`
                  : call.user?.name}
              </strong>
              <p>
                {call.call_started_at
                  ? format(parseISO(call.call_started_at), "dd/MM/yyyy")
                  : ""}
              </p>
            </div>

            <IconButton onClick={() => setShowDetail(!showDetail)}>
              <ChevronRightIcon />
            </IconButton>
          </div>

          <Divider className={classes.dividerVoice} />

          <div className={classes.divListVoices}>
            {voices.map((voice, key) => (
              <div
                key={voice.id}
                className={
                  voice.sendBy == user.id
                    ? classes.divItemListSendVoice
                    : classes.divItemListReceivedVoice
                }
              >
                <strong
                  style={{
                    marginLeft: VoiceModal.sendBy == user.id ? "20px" : 0,
                    marginRight: VoiceModal.sendBy != user.id ? "20px" : 0,
                  }}
                >
                  {voice.user?.name}
                </strong>
                <div
                  key={key}
                  className={
                    voice.sendBy
                      ? classes.itemListSendVoice
                      : classes.itemListReceivedVoice
                  }
                >
                  <audio src={voice?.url} controls={true}></audio>
                </div>
              </div>
            ))}
          </div>

          <div className={classes.divVoice}>
            <Divider className={classes.dividerVoice} />

            {!call.call_finish_at ? (
              <div className={classes.divItemsVoice}>
                <Button
                  className={
                    record
                      ? classes.divButtonVoiceRecord
                      : classes.divButtonVoice
                  }
                  onClick={() => handleRecordAudio()}
                ></Button>
                {record ? (
                  <MicIcon style={{ position: "absolute" }} />
                ) : (
                  <MicNoneIcon style={{ position: "absolute" }} />
                )}
              </div>
            ) : (
              <div className={classes.divItemsVoice}>
                <strong>Call Encerrada</strong>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={classes.divModal}>
          <div className={classes.divHeaderContacts}>
            <IconButton onClick={() => setShowDetail(!showDetail)}>
              <KeyboardReturnIcon />
            </IconButton>

            <strong
              style={{ marginRight: "10px" }}
              className={classes.strongNameHeader}
            >
              Contatos
            </strong>
          </div>

          <Divider className={classes.dividerVoice} />

          <div className={classes.divListContacts}>
            {/* <>
              <div className={classes.divItemListContact}>
                <div className={classes.divItemInfoListContact}>
                  <strong>Thomas Domingos</strong>

                  <p>(19) 99999-9999</p>
                </div>

                <div className={classes.divItemIconsListContact}>
                  <IconButton>
                    <ChevronRightIcon />
                  </IconButton>
                </div>
              </div>
              <Divider className={classes.dividerVoice} />
            </> */}
          </div>
        </div>
      )}
    </Dialog>
  );
};
