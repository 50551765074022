import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => {
  return {
    noticeContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 200,
    },
  };
});
export default useStyles;
