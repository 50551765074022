import * as exceptions from "../../commons/exceptions";
import codes from "../../commons/constants/codes";
import utransferV2API from "../../services/utransferV2";

export const getIfOverSpeedHired = async (customerId) => {
  try {
    const data = await utransferV2API.get(`/customers/${customerId}/configs`);

    return data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};
