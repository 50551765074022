const transferConstants = {
  status: {
    ENDED: 'Finalizado',
    ONGOING: 'Em andamento',
    NOT_ENDED: 'Não finalizado',
    CANCELED: 'Cancelado',
    SCRIPTED: 'Roteirizado',
    NOT_SCRIPTED: 'Não roteirizado',
  }
}

export default transferConstants
