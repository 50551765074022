export const styles = {
  subheader: {
    marginBottom: 16,
  },
  label: {
    fontSize: 8,
    marginBottom: 3,
  },
  text: {
    fontSize: 8,
  },
  flexColumn: {
    flexDirection: "column",
  },
};
