import axios from "axios";

import * as middlewares from "./middlewares";

export const meteumApi = axios.create({
  baseURL: process.env.REACT_APP_METEUM_SERVICE,
  headers: {
    "X-Meteum-API-Key": process.env.REACT_APP_METEUM_KEY,
  },
});

middlewares.addUserAuthorization(meteumApi);
