export const allowedRiskZoneTypeIds = String(
  process.env.REACT_APP_ADVANCED_MONITORING_RISK_ZONE_TYPES
)
  .split(",")
  .map((id) => Number(id.trim()))
  .filter((id) => Number.isInteger(id));

export const allowedPublicEntityTypeIds = String(
  process.env.REACT_APP_ADVANCED_MONITORING_PUBLIC_ENTITY_TYPES
)
  .split(",")
  .map((id) => Number(id.trim()))
  .filter((id) => Number.isInteger(id));

export const allowedPoiTypeIds = String(
  process.env.REACT_APP_ADVANCED_MONITORING_POI_TYPES
)
  .split(",")
  .map((id) => Number(id.trim()))
  .filter((id) => Number.isInteger(id));

export const allowedPermissions = [
  "can-list-points-of-interest",
  "can-list-public-entities",
  "can-list-areas",
];

export const searchRadius = Number(
  process.env.REACT_APP_ADVANCED_MONITORING_SEARCH_RADIUS
);

export const maxDistanceToSearch = Number(
  process.env.REACT_APP_ADVANCED_MONITORING_MAX_DISTANCE_TO_SEARCH
);
