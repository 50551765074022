import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  handleOpenModalEditPoi,
  setPoi,
  setPois,
} from "../../../store/ducks/poi";

import { getCustomerPointsOfInterestTypes } from "../../../services/areasService";

import { updatePOI, getPOIs } from "../../../infra/http/poiProvider";

import { toast } from "material-react-toastify";

import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import { MapPoi } from "../Map";
import Timepicker from "../Timepicker";

import {
  Div,
  DivMap,
  DivInfos,
  DivTitle,
  DivInputs,
  DivButtons,
} from "./styles";
import { format } from "date-fns";

export const ModalEditPoi = () => {
  const [poiTypes, setPoiTypes] = useState([]);

  const dispatch = useDispatch();

  const customerId = useSelector((store) => store.auth.customer.customer);

  const { poi, openModalEditPoi } = useSelector((store) => store.poi);

  const tabsClasses = {
    root: "tabsContainer",
    indicator: "tabsBorderBottomColor",
  };

  const handleGetPOITypes = async () => {
    try {
      const response = await getCustomerPointsOfInterestTypes();
      setPoiTypes(response);
    } catch (error) {
      toast.error("Falha ao buscar todos os pontos de interesses.");
    }
  };

  const handleUpdatePoi = async () => {
    if (poi.start_hour > poi.end_hour)
      return toast.warning("Horarios invalidos.");

    if (!poi.start_hour) return toast.warning("Horarios invalidos");

    if (!poi.end_hour) return toast.warning("Horario final invalido.");

    if (!poi.customer_poi_type_id)
      return toast.warning("Selecione um tipo de ponto de interesse.");

    try {
      await updatePOI({
        customerId,
        id: poi.id,
        data: {
          customer_poi_type_id: poi.customer_poi_type_id,
          start_hour: format(new Date(`01-10-1999 ${poi.start_hour}`), "HH:mm"),
          end_hour: format(new Date(`01-10-1999 ${poi.end_hour}`), "HH:mm"),
        },
      });
      const response = await getPOIs({ customerId });

      dispatch(setPois(response));
      dispatch(setPoi({}));
      dispatch(handleOpenModalEditPoi());

      toast.success("Ponto de interesse atualizado com sucesso.");
    } catch (error) {
      toast.error("Falha ao atualizar o ponto de interesse.");
    }
  };

  const handleSetStartHourPoi = (value) => {
    dispatch(setPoi({ ...poi, start_hour: value }));
  };

  const handleSetEndHourPoi = (value) => {
    dispatch(setPoi({ ...poi, end_hour: value }));
  };

  const handleChangeCustomerPoyTypeId = (id) => {
    dispatch(
      setPoi({
        ...poi,
        customer_poi_type_id: poi.customer_poi_type_id != id ? id : undefined,
      })
    );
  };

  useEffect(() => {
    handleGetPOITypes();
  }, []);
  return (
    <Dialog
      style={{ zIndex: 2 }}
      maxWidth={"md"}
      aria-labelledby="customized-dialog-title"
      open={openModalEditPoi}
    >
      <Div>
        <DivMap>
          <MapPoi />
        </DivMap>
        <DivInfos>
          <DivTitle>
            <strong style={{ fontSize: "24px", fontWeigth: "bold" }}>
              Novo Ponto de Interesse
            </strong>
          </DivTitle>
          <Divider />

          <DivInputs>
            <Timepicker
              value={poi.start_hour}
              label={"Horario de Inicio"}
              action={handleSetStartHourPoi}
            />
            <Timepicker
              value={poi.end_hour}
              label={"Horario Final"}
              action={handleSetEndHourPoi}
            />
          </DivInputs>

          <Divider />

          <List
            style={{
              height: "100%",
              overflow: "auto",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {poiTypes.map(({ id, type }) => (
              <ListItem key={id} style={{ padding: "0 24px" }}>
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    checked={id == poi.customer_poi_type_id}
                    onChange={() => handleChangeCustomerPoyTypeId(id)}
                  />
                </ListItemIcon>
                <ListItemText>{type}</ListItemText>
              </ListItem>
            ))}
          </List>

          <Divider />

          <DivButtons>
            <Button
              variant="outlined"
              style={{ width: "120px" }}
              onClick={() => dispatch(handleOpenModalEditPoi())}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "120px" }}
              onClick={() => handleUpdatePoi()}
            >
              Salvar
            </Button>
          </DivButtons>
        </DivInfos>
      </Div>
    </Dialog>
  );
};
