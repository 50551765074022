import React, { useState } from "react";
import { Polygon, Marker, InfoWindow } from "@react-google-maps/api";
import { polylineToCoordinates } from "../../../commons/utils/map";
import _ from "lodash";
import styles from "./style";
import { Box, ListItemText, Paper, makeStyles } from "@material-ui/core";

const CustomerInterestPoints = (props) => {
  const { customerPointOfInterest } = props;
  const classes = makeStyles(styles)();
  const [isOpen, setIsOpen] = useState(false);

  const renderCustomerPointOfInterestPolygons = () => {
    const coordinates =
      customerPointOfInterest?.area?.polygon?.coordinates || [];
    const polylines = _.flattenDeep(coordinates);

    return polylines.map((polyline, index) => (
      <Polygon
        key={`polygonCustomerPOI-${index}`}
        paths={polylineToCoordinates(polyline)}
        options={{
          strokeColor: customerPointOfInterest.type.color || "#00a68d",
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: customerPointOfInterest.type.color || "#00a68d",
          fillOpacity: 0.2,
        }}
      />
    ));
  };

  const toggleVisible = () => {
    setIsOpen((status) => !status);
  };

  const renderAreaInformations = (areaInformations) => {
    if (!areaInformations || areaInformations.length === 0) {
      return null;
    }
    return (
      <ListItemText style={{ marginTop: 10, marginBottom: 5 }}>
        <strong>Informações:</strong>
        {areaInformations.map((areaInformation) => {
          return <p style={{ marginTop: 0 }}>{areaInformation.information}</p>;
        })}
      </ListItemText>
    );
  };

  return (
    <>
      {!customerPointOfInterest?.area?.polygon?.coordinates &&
        customerPointOfInterest.area.point && (
          <Marker
            noRedraw={true}
            title={
              customerPointOfInterest.area.name &&
              customerPointOfInterest.area.name
            }
            key={`customerPOIMarker-${customerPointOfInterest.id}`}
            position={{
              lat: customerPointOfInterest.area.point.coordinates[1],
              lng: customerPointOfInterest.area.point.coordinates[0],
            }}
            icon={{ url: customerPointOfInterest.type.icon, optimized: false }}
            onClick={toggleVisible}
          >
            {isOpen && (
              <InfoWindow
                options={{ maxWidth: 400 }}
                classes={classes.infoWindowStyle}
              >
                <Paper color="primary" className={classes.infoWindowStyle}>
                  <Box component="div" className={classes.infoWindowBox}>
                    <img src={customerPointOfInterest.type.icon} alt="Ícone" />
                  </Box>
                  <Box component="div" className={classes.item}>
                    <ListItemText
                      primary={customerPointOfInterest.area.name}
                      className={classes.incidentTextTitle}
                    />
                    <ListItemText
                      primary={customerPointOfInterest.type.type}
                      className={classes.infoWindowText}
                    />
                    {renderAreaInformations(
                      customerPointOfInterest.area.areaInformation
                    )}
                  </Box>
                </Paper>
              </InfoWindow>
            )}
          </Marker>
        )}
      {renderCustomerPointOfInterestPolygons()}
    </>
  );
};

export default CustomerInterestPoints;
