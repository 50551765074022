import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  Typography,
} from "@material-ui/core";
import VolumeUpOutlinedIcon from "@material-ui/icons/VolumeUpOutlined";
import { green } from "@material-ui/core/colors";
import { format, parseISO, subHours } from "date-fns";

import { useStyles } from "./styles";
import { useSelector } from "react-redux";

export default function AlertItem({ event, device, onDetailsClick }) {
  const classes = useStyles();
  const disabledDetailsButtons = useSelector(
    (store) => store.monitoringMap.disabledDetailsButtons
  );
  const alertsSound = useSelector((store) => store.monitoringMap.alertsSound);
  const deviceName = device.alias || device.identifier;

  const formatDate = (dateString) => {
    if (dateString === "") {
      return null;
    }
    const dateTz = subHours(parseISO(dateString), 3);

    return format(dateTz, "'Ocorrido em' dd'/'MM'/'yyyy 'às' HH:mm:ss");
  };

  return (
    <div className={classes.alertBox}>
      <ListItem role="listitem" className={classes.alertItem}>
        <span>
          <strong>
            {event.eventType.name} | {deviceName}{" "}
            {alertsSound.includes(event.id) && (
              <VolumeUpOutlinedIcon
                style={{ fontSize: "15px", color: green[500] }}
              />
            )}
          </strong>
        </span>
        <span className={classes.alertTime}>{formatDate(event.startedAt)}</span>
        <ul className={classes.alertBoxButtons}>
          <li>
            <Button
              className={classes.alertDetalhes}
              variant="Detalhes"
              onClick={() => onDetailsClick(event, device.id)}
              disabled={disabledDetailsButtons.includes(event.id)}
            >
              Detalhes
            </Button>
          </li>
          {disabledDetailsButtons.includes(event.id) && (
            <li>
              <Box className={classes.treatLoading}>
                <CircularProgress size={"25px"} thickness={6} />
                <Box>
                  <Typography variant="subtitle2">Tratando...</Typography>
                </Box>
              </Box>
            </li>
          )}
        </ul>
      </ListItem>
    </div>
  );
}
