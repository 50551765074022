import React from "react";
import { useSelector } from "react-redux";
import {
  GoogleMap,
  Polyline,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import decodePolyline from "decode-google-map-polyline";
import _ from "lodash";

import To from "../../assets/IconTo06B0CF.png";
import From from "../../assets/IconFrom06B0CF.png";
import riskIcon from "../../assets/Base_Crise.png";
import { useGoogleMapsKey } from "../../hooks/useGoogleMapsKey";

const libraries = ["places", "drawing", "geometry"];

export default function ReportEventDetailMap({ children }) {
  const googleMapsKey = useGoogleMapsKey();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  const deviceWay = useSelector((state) => state.transfer.deviceWay);
  const expectedRoute = useSelector((state) => state.transfer.expectedRoute);
  const stopPoints = useSelector((state) => state.transfer.stopPoints);
  const riskLayers = useSelector((state) => state.transfer.riskLayers);
  const transferEventAnalyticsData = useSelector(
    (store) => store.transfer.transferEventAnalytics
  );

  const showCoordinateMarkers = useSelector(
    (store) => store.transfer.showCoordinateMarkers
  );
  const defaultCenterCoordinates = useSelector(
    (store) => store.transfer.defaultCenterCoordinates
  );

  const eventIdToShowMarkers = useSelector(
    (store) => store.transfer.eventIdToShowMarkers
  );

  const DEFAULT_CENTER =
    defaultCenterCoordinates ||
    (expectedRoute.length > 0 ? expectedRoute[0] : deviceWay[0]);

  const DEFAULT_OPTIONS = {
    zoomControl: true,
    fullscreenControl: false,
    streetViewControl: false,
    draggableCursor: null,
    disableDefaultUI: true,
  };

  const buildMarker = ({ position, label, icon, title }, index) => {
    if (
      typeof position !== "object" ||
      position === null ||
      position.hasOwnProperty("lat") === false ||
      position.hasOwnProperty("lng") === false
    ) {
      return;
    }

    return (
      <li key={index}>
        <Marker title={title} position={position} icon={icon} label={label} />
      </li>
    );
  };

  const renderTraveledPath = () => {
    const realStartCoordinates = deviceWay[0];
    const realEndCoordinates = deviceWay[deviceWay.length - 1];

    const expectedStartCoordinates =
      expectedRoute.length > 0 ? expectedRoute[0] : realStartCoordinates;

    const expectedEndCoordinates =
      expectedRoute.length > 0
        ? expectedRoute[expectedRoute.length - 1]
        : realEndCoordinates;

    const markers = [
      {
        title: "Ponto de partida previsto",
        label: "",
        icon: From,
        position: expectedStartCoordinates,
      },
      {
        title: "Ponto de destino previsto",
        label: "",
        icon: To,
        position: expectedEndCoordinates,
      },
      {
        title: "Ponto de partida real",
        label: "A",
        icon: null,
        position: realStartCoordinates,
      },
      {
        title: "Ponto de destino real",
        label: "B",
        icon: null,
        position: realEndCoordinates,
      },
    ];

    return (
      <>
        <Polyline
          path={expectedRoute.length > 0 ? expectedRoute : deviceWay}
          options={{
            strokeColor: "#63a9ef",
            strokeWeight: 5,
            strokeOpacity: 0.7,
          }}
        />
        <Polyline
          path={deviceWay}
          options={{
            strokeColor: "#0000ff",
            strokeWeight: 5,
            strokeOpacity: 0.7,
          }}
        />

        {markers.map((marker, index) => buildMarker(marker, index))}
      </>
    );
  };

  const renderEventsOccuredMarkers = () => {
    if (
      transferEventAnalyticsData?.length > 0 &&
      Boolean(eventIdToShowMarkers)
    ) {
      const justCoordinates = transferEventAnalyticsData
        .filter((event) => event.id === eventIdToShowMarkers)
        .map((event) => {
          return event?.coordinates.map((element) => {
            return {
              lat: element.payload.tracking.latitude,
              lng: element.payload.tracking.longitude,
            };
          });
        })
        .flat();

      const uniqueCoordinates = _.uniqWith(justCoordinates, _.isEqual);

      return (
        <>
          {uniqueCoordinates.map((coordinates, index) => (
            <Marker
              key={index}
              position={coordinates}
              icon={riskIcon}
              options={{
                clickable: false,
              }}
            />
          ))}
        </>
      );
    }
  };

  const renderEventsOccuredPath = () => {
    if (transferEventAnalyticsData?.length > 0) {
      const justCoordinates = transferEventAnalyticsData
        .filter(
          (transferEventAnalyticsData) =>
            transferEventAnalyticsData.needTreatment
        )
        .map((events) => {
          const coordinates = events?.coordinates?.map((element) => {
            return {
              lat: element.payload.tracking.latitude,
              lng: element.payload.tracking.longitude,
            };
          });

          return {
            coordinates: coordinates,
            id: events.id,
          };
        });

      return (
        <>
          {justCoordinates.map((element) => (
            <Polyline
              path={element.coordinates}
              options={{
                strokeColor: "#f5e000",
                strokeWeight: 5,
                strokeOpacity: 0.7,
                zIndex: 1,
              }}
            />
          ))}
        </>
      );
    }
  };

  const renderTraveledPoints = () => {
    if (!Array.isArray(deviceWay)) return null;

    return deviceWay.map((position, index) => (
      <li key={index}>
        <Marker
          position={position}
          options={{
            clickable: false,
          }}
        />
      </li>
    ));
  };

  const renderStopPoints = () => {
    if (!Array.isArray(stopPoints)) return null;

    return stopPoints
      .filter((stopPoint) => stopPoint.hasOwnProperty("positions"))
      .map(({ positions }, index) => (
        <li key={index}>
          <Marker
            position={{
              lat: positions.coordinates[1],
              lng: positions.coordinates[0],
            }}
            options={{
              clickable: false,
            }}
            label="P"
          />
        </li>
      ));
  };

  const renderRiskLayers = () => {
    if (!Array.isArray(riskLayers)) return null;

    const riskLayersCoordinatesNDimensional = riskLayers
      .filter(
        (riskLayer) =>
          riskLayer.hasOwnProperty("polygon") &&
          riskLayer.polygon.hasOwnProperty("coordinates") &&
          Array.isArray(riskLayer.polygon.coordinates)
      )
      .map((riskLayer) => riskLayer.polygon.coordinates);

    const riskLayersCoordinates = _.flattenDeep(
      riskLayersCoordinatesNDimensional
    ).map((coordinates) => decodePolyline(coordinates));

    return riskLayersCoordinates.map((coordinates, index) => (
      <Polygon
        key={index}
        path={coordinates}
        options={{
          fillColor: "red",
          fillOpacity: 0.2,
          strokeColor: "red",
          strokeOpacity: 1,
          strokeWeight: 2,
          clickable: false,
          draggable: false,
          editable: false,
          geodesic: false,
          zIndex: 1,
        }}
      />
    ));
  };

  if (!isLoaded) {
    return <></>;
  }

  return (
    <GoogleMap
      id="direction"
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
      zoom={12}
      center={DEFAULT_CENTER}
      options={DEFAULT_OPTIONS}
    >
      {renderRiskLayers()}
      {showCoordinateMarkers && renderTraveledPoints()}
      {renderEventsOccuredPath()}
      {renderEventsOccuredMarkers()}
      {renderTraveledPath()}
      {renderStopPoints()}

      {children}
    </GoogleMap>
  );
}
