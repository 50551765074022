import { List, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import styles from "./styles";

const TransferEventList = ({ classes, children }) => {
  return (
    <Paper
      className={classes.list}
      elevation={2}
    >
      <List dense component="div" role="list">
        {children}
      </List>
    </Paper>
  );
};

export default withStyles(styles)(TransferEventList);
