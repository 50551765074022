import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { reducer as formReducer } from "redux-form";
import storage from "redux-persist/lib/storage";
import authReducer, { authPersistConfig, Types as AuthTypes } from "./auth";

import snackbarReducer from "./snackbar";
import monitoringReducer from "./monitoring";
import monitoringMapReducer, { alertSound } from "./monitoringMap";
import advancedMonitoringReducer from "./advancedMonitoring";
import transferReducer, { transferPersistConfig } from "./transfer";
import { scriptingReducer } from "./scripting";
import reportingReducer from "./reporting";
import deviceManagement from "./deviceManagement";
import reportData from "./reportingData";
import { customerUnits } from "./customerUnit";
import { customerActivities } from "./customerActivities";
import { customerCategories } from "./customerCategories";
import { customerProducts } from "./customerProducts";
import areas from "./areas";
import voice, { voicePersistConfig } from "./voice";
import poi from "./poi";

const appReducer = combineReducers({
  form: formReducer,
  areas,
  poi,
  voice: persistReducer(voicePersistConfig, voice),
  auth: persistReducer(authPersistConfig, authReducer),
  snackbar: snackbarReducer,
  monitoring: monitoringReducer,
  monitoringMap: persistReducer(alertSound, monitoringMapReducer),
  advancedMonitoring: advancedMonitoringReducer,
  transfer: persistReducer(transferPersistConfig, transferReducer),
  scripting: scriptingReducer,
  reporting: reportingReducer,
  deviceMangement: deviceManagement,
  customerUnits,
  customerCategories,
  customerActivities,
  customerProducts,
  reportData: reportData,
});

const rootReducer = (state, action) => {
  if (action.type === AuthTypes.SUCCESS_LOGOUT) {
    storage.removeItem("persist:auth");

    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
