import React, { useState } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import DoneIcon from "@material-ui/icons/Done";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import HelpIcon from "@material-ui/icons/Help";
import FormStatus from "./formStatusNew";
import transferConstants from "../../../domain/constants/transfers";
import { getTransferStatus } from "./utils/transfers";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ButtonCustom } from "../stylesNew";

export default function CustomizedDialogs({ transferData }) {
  const [open, setOpen] = useState(false);

  const transferStatus = getTransferStatus(transferData.status);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={transferStatus} placement="top" arrow>
        <ButtonCustom onClick={handleClickOpen} fontSize="inherit">
          <ButtonContent transferStatus={transferStatus} />
        </ButtonCustom>
      </Tooltip>
      <Dialog maxWidth={"sm"} onClose={handleClose} open={open}>
        <header
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ fontWeight: 700, paddingBottom: 0 }}
          >
            Transfer | <strong>Edição</strong>
          </DialogTitle>
          <IconButton size="small" aria-label="fechar" onClick={handleClose}>
            <CloseOutlinedIcon style={{ color: "#06B0CF", marginRight: 10 }} />
          </IconButton>
        </header>
        <DialogContent style={{ padding: "12px" }}>
          <FormStatus transferData={transferData} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const ButtonContent = ({ transferStatus }) => {
  const buttonPossibleContents = {
    [transferConstants.status.CANCELED]: () => (
      <HighlightOffIcon style={{ color: "#EA6060" }} />
    ),
    [transferConstants.status.ENDED]: () => (
      <>
        <DoneIcon fontSize="small" style={{ color: "#06B0CF" }} />
        <DirectionsCarIcon fontSize="small" style={{ color: "#06B0CF" }} />
      </>
    ),
    [transferConstants.status.NOT_ENDED]: () => (
      <>
        <HighlightOffIcon style={{ color: "#EA6060" }} />
        <DirectionsCarIcon fontSize="small" style={{ color: "#06B0CF" }} />
      </>
    ),
    [transferConstants.status.NOT_SCRIPTED]: () => (
      <>
        <HelpIcon style={{ color: "#06B0CF" }} />
        <CreateOutlinedIcon fontSize="small" style={{ color: "#333333" }} />
      </>
    ),
    [transferConstants.status.ONGOING]: () => (
      <>
        <HourglassEmptyIcon fontSize="small" style={{ color: "#EA6060" }} />
        <DirectionsCarIcon fontSize="small" style={{ color: "#EA6060" }} />
      </>
    ),
    [transferConstants.status.SCRIPTED]: () => (
      <>
        <DoneIcon fontSize="small" style={{ color: "#06B0CF" }} />
        <CreateOutlinedIcon fontSize="small" style={{ color: "#333333" }} />
      </>
    ),
  };

  const ButtonChosen =
    buttonPossibleContents[transferStatus] ??
    buttonPossibleContents[transferConstants.status.ENDED];

  return <ButtonChosen />;
};
