import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  DialogActions,
  Dialog,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { useStyles } from "./style";
import {
  formatDateReadable,
  formatDateEUA,
} from "../../../../domain/format/date";
import { getEventsDetailsModal } from "../../../../services/eventAnalyticsApi/eventService";
import { format as formatDate } from "date-fns";
import { toast } from "material-react-toastify";

export const ModalEventReport = (props) => {
  const customerId = useSelector((store) => store.auth.customer.customer);
  const { open, close, eventName, eventTypeId, device_id } = props;
  const dateFrom = useSelector((state) => state.reporting.fromDate);
  const dateTo = useSelector((state) => state.reporting.toDate);
  const [eventDetails, setEventDetails] = useState();
  const [loading, setLoading] = useState(false);

  const dateFromFormat = formatDateEUA(dateFrom);
  const dateToFormat = formatDateEUA(dateTo);

  const tableHeaders = [
    "Período do Ocorrido",
    "Coordenada Inicial",
    "Endereço",
    "Coordenada Final",
    "Endereço",
    "Data do Tratamento",
    "Usuário que tratou",
    "Tratativa",
  ];

  const getDataEvent = async () => {
    try {
      setLoading(true);
      const dataEvent = await getEventsDetailsModal(
        customerId,
        dateFromFormat,
        dateToFormat,
        eventTypeId
      );

      const dataEventFilter = dataEvent.filter(
        ({ deviceId }) => deviceId == device_id
      );

      if (dataEventFilter) {
        setEventDetails(dataEventFilter);
      }
    } catch (error) {
      toast.error("Não foi possivel carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!eventTypeId) return;
    getDataEvent();
  }, [eventTypeId]);

  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"lg"}
      style={{ marginLeft: "50px" }}
    >
      <Paper style={{ width: "100%" }}>
        <TableRow
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TableCell>
            {`Dispositivo: UTRACK01 ${formatDateReadable(
              dateFrom
            )} - ${formatDateReadable(dateTo)}`}
          </TableCell>
          <TableCell>{`Evento: ${eventName}`}</TableCell>
        </TableRow>
        <Table aria-labelledby="tableTitle" size="medium">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell align="center">{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!loading &&
            eventDetails?.map((detail) => (
              <TableBody>
                <TableRow>
                  <TableCell align="center" component="th" scope="row">
                    {`${formatDateReadable(
                      detail.startedAt
                    )} até ${formatDateReadable(detail.endedAt)}`}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {`${detail.firsCoordinate.lat || ""} - ${
                      detail.firsCoordinate.lng || ""
                    }`}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {detail.firstAddres}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {`${detail.lastCoordinate.lat || ""} - ${
                      detail.lastCoordinate.lng || ""
                    }`}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {detail.lastAddres}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {formatDateReadable(detail.treatment.justifiedAt)}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {detail.treatment.justifiedByUser}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {detail.treatment.justification}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
        </Table>
        {loading && <LinearProgress color="primary" variant="query" />}
      </Paper>
      <DialogActions>
        <div>
          <Button
            className={classes.styleButton}
            onClick={close}
            color="primary"
          >
            Sair
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
