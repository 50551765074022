import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { handleDeleteDriver } from "../../../services/peopleManagement/driver";
import { toast } from "material-react-toastify";
import { useState } from "react";

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

const DeleteDialog = ({ close, open, data, reload }) => {
  const [disableButton, setDisableButton] = useState(false);
  const deleteDriver = async (id) => {
    setDisableButton((previous) => !previous);
    try {
      await handleDeleteDriver(id);
      await reload();
      setDisableButton((previous) => previous);
      close();
    } catch (err) {
      toast.error("Não foi possível remover motorista.");
    } finally {
      setDisableButton((previous) => !previous);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Deseja proseguir com a exclusão?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disableButton}
          onClick={() => deleteDriver(data.id)}
          color="primary"
        >
          Sim
        </Button>
        <Button disabled={disableButton} onClick={close} color="primary">
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
