import axios from "axios";

import * as middlewares from "./middlewares";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_UTRANSFER_URL,
});

middlewares.addUserAuthorization(api);

export const transfers = async (data, token) => {
  try {
    const response = await api.request({
      url: "/transfers",
      method: "post",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.id;
  } catch (err) {
    throw new Error("Erro ao cadastrar");
  }
};

export const updateTransfers = async (data, token, transferId) => {
  try {
    await api.request({
      url: `/transfers/${transferId}`,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error("Erro ao Editar");
  }
};

export default api;
