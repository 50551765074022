import { isValid as checkIsValidDate, format as formatDate } from "date-fns";

import * as exceptions from "../../commons/exceptions";
import codes from "../../commons/constants/codes";

export const formatDateReadable = (dateString) => {
  const date = new Date(dateString);
  if (!checkIsValidDate(date))
    throw exceptions.createException({
      code: codes.error.VALUE_ERROR,
      message: "dateString must be a valid date",
    });

  return formatDate(date, "dd/MM/yyyy HH:mm");
};

export const formatDateEUA = (dateString) => {
  const date = new Date(dateString);
  if (!checkIsValidDate(date))
    throw exceptions.createException({
      code: codes.error.VALUE_ERROR,
      message: "dateString must be a valid date",
    });

  return formatDate(date, "yyyy-MM-dd");
};
