import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Collapse,
  IconButton,
  Switch,
  Menu,
  MenuItem,
} from "@mui/material";
import { red, blue } from "@mui/material/colors";
import {
  ExpandMore as ExpandMoreIcon,
  MoreVert as MoreVertIcon,
  ModeEditOutline as ModeEditOutlineIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  DehazeOutlined as DehazeOutlinedIcon,
} from "@mui/icons-material";
import {
  CustomTextField,
  AddPassengerButton,
  CustomCard,
  CustomPaper,
  CustomCardHeader,
  CustomTypography,
  CustomFormControlLabel,
  ContainerFieldCardStop,
  CustomCardContent,
} from "./styles";
import { EditAddress } from "./ModalEdit";
import { DeletedAddress } from "./ModalDelete";
import { alphabetArr } from "../../../../commons/utils/alphabetArr";
import {
  ScriptingActiontypes,
  setTypeCard,
  updateWaypointAction,
} from "../../../../store/ducks/scripting";
import { genericAction } from "../../../../store/ducks/transfer";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const CardDetourStop = ({
  location,
  id,
  onDraggable,
  orderAlphabet,
}) => {
  const dispatch = useDispatch();
  const waypoints = useSelector((state) => state.scripting.waypoints);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalEdit, setOpenModalEdit] = useState({
    open: false,
  });
  const [openModalDelete, setOpenModalDelete] = useState({
    open: false,
  });
  const [isChecked, setIsChecked] = useState(false);
  const detourOrStop = isChecked ? "Detour" : "isStop";

  const orderCardAlphabet = alphabetArr[orderAlphabet];

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionMenu = (typeModal) => {
    setAnchorEl(null);

    if (typeModal === "Edit") {
      setOpenModalEdit({
        open: true,
        typeModal: typeModal,
      });
      return;
    } else if (typeModal === "Delete") {
      setOpenModalDelete({
        open: true,
        typeModal: typeModal,
      });
      return;
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    dispatch(setTypeCard(detourOrStop));
    dispatch(
      genericAction(ScriptingActiontypes.UPDATE_TYPE_CARD, {
        id: id,
        shouldSwitch: isChecked,
      })
    );
  }, [isChecked]);

  return (
    <CustomCard elevation={3}>
      <CustomPaper>
        {onDraggable && (
          <DehazeOutlinedIcon
            style={{
              height: "16px",
              width: "16px",
              color: "#B1B9C0",
              marginLeft: "10px",
            }}
          />
        )}

        <CustomCardHeader
          avatar={
            <Avatar
              sx={{
                bgcolor: isChecked ? red[500] : blue[500],
                height: "25px",
                width: "25px",
                fontSize: "15px",
              }}
            >
              {orderCardAlphabet}
            </Avatar>
          }
        />
        <CustomTypography>{isChecked ? `Parada` : `Desvio`}</CustomTypography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleActionMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => handleActionMenu("Edit")}
            style={{ display: "flex", gap: "10px" }}
          >
            <ModeEditOutlineIcon style={{ width: "16px", height: "16px" }} />
            <CustomTypography typeLabel="menuItem">Editar</CustomTypography>
          </MenuItem>
          <MenuItem
            onClick={() => handleActionMenu("Delete")}
            style={{ display: "flex", gap: "10px" }}
          >
            <DeleteOutlineOutlinedIcon
              style={{ width: "16px", height: "16px" }}
            />
            <CustomTypography typeLabel="menuItem">Deletar</CustomTypography>
          </MenuItem>
        </Menu>
        <EditAddress
          id={id}
          isOpen={openModalEdit}
          setIsOPen={setOpenModalEdit}
          address={location}
        />
        <DeletedAddress
          id={id}
          isOpen={openModalDelete}
          setIsOPen={setOpenModalDelete}
          address={location}
        />
      </CustomPaper>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CustomCardContent>
          <CustomTypography typeLabel="address">{location}</CustomTypography>
          <Collapse in={isChecked} timeout="auto" unmountOnExit>
            <ContainerFieldCardStop>
              <CustomTextField
                label="Tempo (min)"
                size="small"
                variant="outlined"
                type="search"
              />
              <CustomTextField
                label="Motivo da Parada"
                size="small"
                variant="outlined"
                type="text"
              />
              <AddPassengerButton
                fullWidth
                startIcon={
                  <AddCircleOutlineIcon style={{ marginLeft: "-10px" }} />
                }
              >
                Adicionar passageiros
              </AddPassengerButton>
            </ContainerFieldCardStop>
          </Collapse>
          <CustomFormControlLabel
            disableTypography
            control={
              <Switch
                checked={isChecked}
                onChange={handleChange}
                size="small"
              />
            }
            label="Definir como parada"
          />
        </CustomCardContent>
      </Collapse>
    </CustomCard>
  );
};
