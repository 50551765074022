export default {
  badge: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    float: "left",
    marginTop: 5,
    marginRight: 10,
  },
};
