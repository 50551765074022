import {
  Button,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { handlePostCategorie } from "../../../services/customerAreaManagement/category";
import { toast } from "material-react-toastify";
import { useStyles } from "./styles";

const CreateDialog = (props) => {
  const [categoryName, setCategoryName] = useState();
  const customerId = useSelector((store) => store.auth.customer.customer);
  const classes = useStyles();

  const handleSetUnitName = (name) => {
    setCategoryName(name);
  };

  const handleCreateCategorie = async () => {
    if (!categoryName) {
      return toast.error("Digite uma Categoria");
    }

    await handlePostCategorie(customerId, categoryName);
    await props.reloadTable();
    props.close();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Categoria</DialogTitle>
      <DialogContent>
        <TextField
          onChange={(e) => handleSetUnitName(e.target.value)}
          required
          autoFocus
          margin="dense"
          id="name"
          label="Nome da Categoria"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions className={classes.divFlex}>
        <Button
          onClick={props.close}
          color="primary"
          autoFocus
          className={classes.cancelButtonCreateDialog}
          variant="outlined"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleCreateCategorie}
          color="primary"
          className={classes.saveButtonCreateDialog}
          autoFocus
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDialog;
