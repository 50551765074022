import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { ModalEventReport } from "./ModalEventReport";

export default function Row(props) {
  const { row, devices } = props;
  const [open, setOpen] = useState(false);
  const [eventName, setEventName] = useState();
  const [eventTypeId, setEventTypeId] = useState();
  const [openEventModal, setOpenEventModal] = useState(false);
  const deviceId = Number(row.id);
  const device = devices?.find(({ id }) => id == deviceId);
  const handleEventTypeId = (name) => {
    const eventId = Object.keys(row.countedEventsByTypes).find(
      (key) => row.countedEventsByTypes[key].name == name
    );

    setEventTypeId(Number(eventId));
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.id}
        </TableCell>
        <TableCell align="center">
          {device?.alias || device?.identifier}
        </TableCell>
        <TableCell align="center">{row.countedEventsTotal.treated}</TableCell>
        <TableCell align="center">
          {row.countedEventsTotal.notTreated}
        </TableCell>
        <TableCell align="center">
          {row.countedEventsTotal.notTreated + row.countedEventsTotal.treated}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Histórico de Ocorrências
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Tipo de Ocorrência</TableCell>
                    <TableCell align="center">Tratados</TableCell>
                    <TableCell align="center">Não Tratados</TableCell>
                    <TableCell align="center">Total de Ocorrências</TableCell>
                    <TableCell align="center">Relatório do Evento</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(row.countedEventsByTypes).map(
                    (currentEvent, index) => (
                      <TableRow key={index}>
                        <TableCell align="center" component="th" scope="row">
                          {currentEvent.name}
                        </TableCell>
                        <TableCell align="center">
                          {currentEvent.treated}
                        </TableCell>
                        <TableCell align="center">
                          {currentEvent.notTreated}
                        </TableCell>
                        <TableCell align="center">
                          {currentEvent.notTreated + currentEvent.treated}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            disabled={Boolean(currentEvent.treated == 0)}
                            onClick={() => (
                              setOpenEventModal(!openEventModal),
                              setEventName(currentEvent.name),
                              handleEventTypeId(currentEvent.name)
                            )}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                  <ModalEventReport
                    device_id={deviceId}
                    eventTypeId={eventTypeId}
                    eventName={eventName}
                    open={openEventModal}
                    close={() => setOpenEventModal(!openEventModal)}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
