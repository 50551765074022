export default {
  alertItem: {
    cursor: 'pointer',
    marginBottom: 5,

    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
}
