import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "material-react-toastify";

import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import api from "../../../services/transferApi";
import * as deviceApiService from "../../../services/devicesService";
import { parseISO, format } from "date-fns";

import FormInputImportRoutesRegister from "./formInputImportRoutesRegister";
import FormInputRegister from "./formInputRegister";
import FormInputToRegister from "./formInputToRegister";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { handleGetSettings } from "../../../services/customerAreaManagement/setting";
import SearchAutoComplete from "./SearchAutoComplete";

export default function RegisterForm() {
  const now = format(new Date(), "yyyy-MM-dd'T'HH:mm");
  const [unity, setUnity] = useState("");
  const [name, setName] = useState("");
  const [customerActivities, setCustomerActivities] = useState([]);
  const [customerActivitySelected, setCustomerActivitySelected] = useState("");
  const [customerCategories, setCustomerCategories] = useState([]);
  const [customerCategorySelected, setCustomerCategorySelected] = useState("");
  const [customerUnits, setCustomerUnits] = useState([]);
  const [customerUnitSelected, setCustomerUnitSelected] = useState("");
  const [customerProducts, setCustomerProducts] = useState([]);
  const [customerProductSelected, setCustomerProductSelected] = useState("");
  const [responsible, setResponsible] = useState("");
  const [scheduled_date, setScheduledDate] = useState(now);
  const [checked, setChecked] = useState(false);
  const [useImportedRoutes, setUseImportedRoutes] = useState(false);
  const [autoStartChecked, setAutoStartChecked] = useState(false);
  const [autoEndChecked, setAutoEndChecked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const from = useSelector((state) => state.transfer.from);
  const to = useSelector((state) => state.transfer.to);
  const token = useSelector((store) => store.auth.token.accessToken);
  const customerId = useSelector((store) => store.auth.customer.customer);
  const history = useHistory();
  const dispatch = useDispatch();
  const [configurations, setConfigurations] = useState([]);

  let aux = 0;

  useEffect(() => {
    const config = async () => {
      const response = await handleGetSettings(customerId);

      setConfigurations(response);
    };

    config();
  }, []);

  useEffect(() => {
    const loadFormData = async () => {
      setLoadingData(true);

      const fields = configurations.map((n) => n.field);

      if (fields.includes(1)) {
        setCustomerUnits(await deviceApiService.getCustomerUnits(customerId));
      }

      if (fields.includes(2)) {
        setCustomerCategories(
          await deviceApiService.getCustomerCategories(customerId)
        );
      }

      if (fields.includes(3)) {
        setCustomerProducts(
          await deviceApiService.getCustomerProducts(customerId)
        );
      }

      if (fields.includes(4)) {
        setCustomerActivities(
          await deviceApiService.getCustomerActivities(customerId)
        );
      }

      setLoadingData(false);
    };

    loadFormData();
  }, [configurations]);

  const handleScripting = (e) => {
    aux = 1;
    handleRegisterTransfer(e, aux);
  };

  function addIdToScriptingaction(id) {
    return { type: "ADD_IDTOSCRIPTING", id };
  }

  const fieldRequired = (field) => {
    const configuration = configurations.find((n) => n.field == field);

    if (!configuration) {
      return false;
    }

    return configuration.required;
  };

  async function handleRegisterTransfer(e, aux) {
    e.preventDefault();

    if (!from) return toast.error("Selecione um Local de Partida sugerido.");
    if (!to && !checked)
      return toast.error("Selecione um Local de Destino sugerido.");

    if (!customerUnitSelected && fieldRequired(1))
      return toast.error("Selecione uma Unidade sugerida");
    if (!customerCategorySelected && fieldRequired(2))
      return toast.error("Selecione uma Categoria sugerida");
    if (!customerProductSelected && fieldRequired(3))
      return toast.error("Selecione um Produto sugerido");
    if (!customerActivitySelected && fieldRequired(4))
      return toast.error("Selecione uma Atividade sugerida");

    setSuccess(true);

    const data = {
      unity,
      name,
      responsible,
      scheduled_date: format(parseISO(scheduled_date), "yyyy-MM-dd HH:mm", {
        timeZone: "America/Sao_Paulo",
      }),
      from,
      to: checked ? from : to,
      automatic_beginning: autoStartChecked,
      automatic_end: autoEndChecked,
      transfer_product_id: customerProductSelected || null,
      transfer_activity_id: customerActivitySelected || null,
      transfer_category_id: customerCategorySelected || null,
      customer_unit_id: customerUnitSelected || null,
    };
    try {
      const response = await api.request({
        url: "/transfers",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(addIdToScriptingaction(response.data.id));
      // alert("Cadastrado com suce!")
      if (aux === 1) {
        aux = 0;
        history.push("/scripting");
      } else {
        window.location.reload(true);
      }
    } catch (err) {
      setSuccess(false);
      alert("Erro ao cadastrar");
    }
  }

  const handleUnityChange = (event, unit) => {
    if (!unit) {
      setCustomerUnitSelected("");
      return;
    }
    setCustomerUnitSelected(unit.id);
  };

  const handleProductChange = (event, product) => {
    if (!product) {
      setCustomerProductSelected("");
      return;
    }
    setCustomerProductSelected(product.id);
  };

  const handleActiviteChange = (event, activite) => {
    if (!activite) {
      setCustomerActivitySelected("");
      return;
    }
    setCustomerActivitySelected(activite.id);
  };

  const handleCategoryChange = (event, category) => {
    if (!category) {
      setCustomerCategorySelected("");
      return;
    }
    setCustomerCategorySelected(category.id);
  };

  return (
    <form onSubmit={handleRegisterTransfer}>
      {success === false && loadingData === false ? (
        <>
          <Grid container spacing={3} style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                style={{ marginLeft: 0, marginRight: 10 }}
                value="from-to"
                control={
                  <Switch
                    onClick={() => setChecked(!checked)}
                    size="small"
                    color="primary"
                  />
                }
                label="Ida e Volta"
                labelPlacement="start"
              />
              <FormControlLabel
                style={{ marginLeft: 0, marginRight: 10 }}
                control={
                  <Switch
                    onClick={() => setUseImportedRoutes(!useImportedRoutes)}
                    size="small"
                    color="primary"
                  />
                }
                label="Importar Rotas"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                style={{ marginLeft: 0, marginRight: 10 }}
                control={
                  <Switch
                    onClick={() => setAutoStartChecked(!autoStartChecked)}
                    size="small"
                    color="primary"
                  />
                }
                label="Inicio Automático"
                labelPlacement="start"
              />
              <FormControlLabel
                style={{ marginLeft: 0, marginRight: 10 }}
                control={
                  <Switch
                    onClick={() => setAutoEndChecked(!autoEndChecked)}
                    size="small"
                    color="primary"
                  />
                }
                label="Fim Automático"
                labelPlacement="start"
              />
            </Grid>

            {configurations.map((n) => n.field).includes(1) && (
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: "100%" }} size="small">
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="subtitle2"
                    gutterBottom
                  >
                    Unidade de Negócios
                  </Typography>
                  <SearchAutoComplete
                    options={customerUnits}
                    onChange={handleUnityChange}
                    placeholder="Unidade"
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Evento
              </Typography>
              <TextField
                variant="outlined"
                id="name"
                type="search"
                placeholder="Digite nome do Evento"
                style={{ width: "100%", height: 40 }}
                size="small"
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Responsável
              </Typography>
              <TextField
                variant="outlined"
                id="responsible"
                type="search"
                placeholder="Digite nome do Responsável"
                style={{ width: "100%", height: 40 }}
                size="small"
                onChange={(e) => setResponsible(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Data e Hora
              </Typography>
              <TextField
                variant="outlined"
                id="scheduled_date"
                type="datetime-local"
                defaultValue={scheduled_date}
                style={{ width: "100%", height: 40 }}
                size="small"
                onChange={(e) => setScheduledDate(e.target.value)}
              />
            </Grid>

            {useImportedRoutes ? (
              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Importar Rotas
                </Typography>
                <FormInputImportRoutesRegister />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  {checked
                    ? "Local de Partida e Destino (ida e volta)"
                    : "Local de Partida"}
                </Typography>
                <FormInputRegister />
              </Grid>
            )}

            {!useImportedRoutes && (
              <Grid
                item
                xs={12}
                sm={6}
                onClick={() => {
                  if (checked)
                    return toast.warning(
                      'Para editar o endereço de destino, desmarque a opção de "ida e volta"'
                    );
                }}
              >
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Local de Destino
                </Typography>
                <FormInputToRegister checked={checked} />
              </Grid>
            )}

            <>
              {configurations.map((n) => n.field).includes(4) && (
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }} size="small">
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      Atividade
                    </Typography>
                    <SearchAutoComplete
                      options={customerActivities}
                      onChange={handleActiviteChange}
                      placeholder="Atividade"
                    />
                  </FormControl>
                </Grid>
              )}

              {configurations.map((n) => n.field).includes(2) && (
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }} size="small">
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      Categorias
                    </Typography>
                    <SearchAutoComplete
                      options={customerCategories}
                      onChange={handleCategoryChange}
                      placeholder="Categorias"
                    />
                  </FormControl>
                </Grid>
              )}

              {configurations.map((n) => n.field).includes(3) && (
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }} size="small">
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      Produto
                    </Typography>
                    <SearchAutoComplete
                      options={customerProducts}
                      onChange={handleProductChange}
                      placeholder="Produto"
                    />
                  </FormControl>
                </Grid>
              )}
            </>
          </Grid>

          <center>
            <Button
              type="submit"
              autoFocus
              style={{
                margin: "0 15px",
                color: "#06B0CF",
                borderRadius: 8,
                border: "none",
                boxShadow: "0px 3px 6px #00000029",
              }}
              variant="outlined"
              color="primary"
            >
              Salvar
            </Button>
            <Button
              onClick={(e) => handleScripting(e)}
              style={{
                backgroundColor: "#06B0CF",
                borderRadius: 8,
                boxShadow: "0px 3px 6px #00000029",
              }}
              variant="contained"
              autoFocus
              color="primary"
            >
              Salvar e Roteirizar
            </Button>
          </center>
        </>
      ) : (
        <>
          <center>
            <div>
              <CircularProgress
                size={68}
                style={{ marginTop: 0, color: "#63a9ef" }}
              ></CircularProgress>
            </div>
            <span>{loadingData ? "Carregando dados" : "Criando transfer"}</span>
          </center>
        </>
      )}
    </form>
  );
}
