import devicesAPI from "../../services/devices";
import { store } from "../../store";
import * as exceptions from "../../commons/exceptions";
import codes from "../../commons/constants/codes";
import { formatDateTimeISO } from "../../commons/utils/date";

export const getTransfers = async ({
  page = null,
  perPage = null,
  scheduledDate = null,
  identifier = null,
  from = null,
  to = null,
  unity = null,
  responsible = null,
}) => {
  const customerId = store.getState()?.auth?.customer?.customer ?? null;

  if (customerId === null)
    exceptions.createException({
      code: "invalid-state",
      message: "No customer ID for current user.",
    });

  const params = {
    page,
    per_page: perPage,
    scheduled_date_before_equal_date: scheduledDate,
    scheduled_date_after_equal_date: scheduledDate,
    identifier,
    from,
    to,
    unity,
    responsible,
  };

  try {
    const {
      data: { transfers_data: transfersData, total_rows: totalRows },
    } = await devicesAPI.get(`/customer/${customerId}/transfers/`, { params });

    return {
      transfersData,
      totalRows,
    };
  } catch (error) {
    throw exceptions.createException({
      code: "api-request-failed",
      error,
    });
  }
};

export const getTransfersWithDevices = async ({
  page = null,
  perPage = null,
  scheduledDate = null,
  scheduledDateBeforeEqualDate = null,
  scheduledDateAfterEqualDate = null,
  identifier = null,
  deviceIdentifier = null,
  from = null,
  to = null,
  unity = null,
  product = null,
  responsible = null,
  monitored = false,
  customerActivityIds = null,
  customerCategoryIds = null,
  customerUnitIds = null,
}) => {
  const customerId = store.getState()?.auth?.customer?.customer ?? null;

  if (customerId === null)
    exceptions.createException({
      code: "invalid-state",
      message: "No customer ID for current user.",
    });

  const params = {
    page,
    per_page: perPage,
    scheduled_date_before_equal_date:
      scheduledDate ?? scheduledDateBeforeEqualDate,
    scheduled_date_after_equal_date:
      scheduledDate ?? scheduledDateAfterEqualDate,
    identifier,
    device_identifier: deviceIdentifier,
    from,
    to,
    unity,
    responsible,
    monitored,
    customerUnitIds: customerUnitIds || null,
    activity: customerActivityIds || null,
    category: customerCategoryIds || null,
    product: product || null,
  };

  try {
    const {
      data: { transfers_data: transfersData, total_rows: totalRows },
    } = await devicesAPI.get(`/customer/${customerId}/transfers/devices`, {
      params,
    });

    return {
      transfersData,
      totalRows,
    };
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const getTransfersWithDevicesUsingSaga = async ({
  page = null,
  perPage = null,
  scheduledDate = null,
  scheduledDateBeforeEqualDate = null,
  scheduledDateAfterEqualDate = null,
  identifier = null,
  deviceIdentifier = null,
  from = null,
  to = null,
  unity = null,
  event = null,
  product = null,
  responsible = null,
  monitored = false,
  customerActivityIds = null,
  customerCategoryIds = null,
  customerUnitIds = null,
}) => {
  const customerId = store.getState()?.auth?.customer?.customer ?? null;

  const toDateString = formatDateTimeISO(to);
  const fromDateString = formatDateTimeISO(from);

  if (customerId === null)
    exceptions.createException({
      code: "invalid-state",
      message: "No customer ID for current user.",
    });

  const params = {
    page,
    per_page: perPage,
    scheduled_date_before_equal_date: toDateString,
    scheduled_date_after_equal_date: fromDateString,
    identifier: identifier || event,
    device_identifier: deviceIdentifier,
    monitored,
    customerUnitIds: customerUnitIds,
    activity: customerActivityIds,
    category: customerCategoryIds,
    product: product,
  };

  try {
    return await devicesAPI.get(`/customer/${customerId}/transfers/devices`, {
      params,
    });
  } catch ({ response }) {
    return response;
  }
};

export const getPreConfiguredTransfers = async ({
  page = null,
  perPage = null,
  scheduledDate = null,
  scheduledDateBeforeEqualDate = null,
  scheduledDateAfterEqualDate = null,
  identifier = null,
  from = null,
  to = null,
  unity = null,
  responsible = null,
  customerUnitId = null,
  active = null,
}) => {
  const customerId = store.getState()?.auth?.customer?.customer ?? null;

  if (customerId === null)
    exceptions.createException({
      code: "invalid-state",
      message: "No customer ID for current user.",
    });

  const params = {
    page,
    per_page: perPage,
    scheduled_date_before_equal_date:
      scheduledDate ?? scheduledDateBeforeEqualDate,
    scheduled_date_after_equal_date:
      scheduledDate ?? scheduledDateAfterEqualDate,
    identifier,
    from,
    to,
    unity,
    responsible,
    customer_unit_id: customerUnitId || null,
    active,
  };

  try {
    const {
      data: { transfers_data: transfersData, total_rows: totalRows },
    } = await devicesAPI.get(
      `/customer/${customerId}/transfers/pre-configured`,
      { params }
    );

    return {
      transfersData,
      totalRows,
    };
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const removePreConfiguredTransfers = async (
  transferIds,
  transfer_setting_id,
  customerId
) => {
  try {
    const data = {
      transferIds: [transferIds],
      transfer_setting_id: transfer_setting_id,
    };
    const response = await devicesAPI.delete(
      `/customer/${customerId}/transfers/pre-configured/`,
      {
        data,
      }
    );

    const transfersDeleted = response.transfers_deleted;

    return transfersDeleted;
  } catch (error) {
    let code = "";
    switch (error?.response?.status) {
      case 400:
        code = "wrong-body-format";
        break;
      case 401:
        code = "unauthorized";
        break;
      case 403:
        code = "forbidden";
        break;
      case 422:
        code = "no-transfer-ids-provided";
        break;
      default:
        code = "internal-server-error";
        break;
    }

    throw exceptions.createException({
      code,
      error,
    });
  }
};

export const getStepsRisks = async ({ transferId }) => {
  try {
    const { data: stepRisksData } = await devicesAPI.get(
      `/transfer/${transferId}/steps-risks`
    );
    return stepRisksData;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};
