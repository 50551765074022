import { point, distance } from "@turf/turf";
import { differenceInSeconds } from "date-fns";

export const validate = (initialPosition, lastPosition) => {
  let timer = 0;
  let distances = 0;
  let metersSecond = 0;

  if (initialPosition.latitude) {
    const from = point([initialPosition?.longitude, initialPosition?.latitude]);

    const to = point([lastPosition?.longitude, lastPosition?.latitude]);

    distances = distance(from, to, { units: "meters" });

    timer = differenceInSeconds(
      new Date(lastPosition?.taken_at),
      new Date(initialPosition?.taken_at)
    );

    if (distances > 0 && timer > 0) {
      metersSecond = distances / timer;
    }
  }

  return metersSecond < 42;
};
