import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { format, parseISO, subHours } from "date-fns";
import Button from "@material-ui/core/Button";

import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import ListItem from "@material-ui/core/ListItem";

import NotificationsIcon from "@material-ui/icons/Notifications";
import { useStyles } from "./styles";
import FilterNotifications from "../FilterNotificationsSidebar/searchFilterNotifications";
import * as FlespiService from "../../services/flespiApiService";
import * as telemetryUtils from "../../domain/devices/telemetryUtils";

import {
  addCenterMapAction,
  addValidateAlertPinAction,
  addZoomInAction,
} from "../../store/ducks/monitoringMap";

export default function NotificationAlerts() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const alerts = useSelector((store) => store.monitoringMap.alertsFlespi);
  const trackings = useSelector((store) => store.monitoringMap.devices);
  const [indexedDevices, setIndexedDevices] = useState({});
  const [open, setOpen] = React.useState(false);
  const [eventCoordinate, setEventCoordinate] = useState({});
  const [ignoredAlertIds, setIgnoredAlertIds] = useState(() => {
    const ignoredAlertIdsJson = localStorage.getItem("ignoredAlertIds");
    return ignoredAlertIdsJson !== null ? JSON.parse(ignoredAlertIdsJson) : [];
  });

  useEffect(() => {
    localStorage.setItem("ignoredAlertIds", JSON.stringify(ignoredAlertIds));
  }, [ignoredAlertIds]);

  const formatDate = (dateString) => {
    if (dateString === "") {
      return null;
    }
    const dateTz = subHours(parseISO(dateString), 3);

    return format(dateTz, "'Ocorrido em' dd'/'MM'/'yyyy 'às' HH:mm:ss");
  };

  const addZoomIn = (zoom) => {
    dispatch(addZoomInAction(zoom));
  };

  const addCenterMap = (centerMap) => {
    dispatch(addCenterMapAction(centerMap));
  };

  const addValidationPin = (validateAlertPin) => {
    dispatch(addValidateAlertPinAction(validateAlertPin));
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleRemove = (id) => {
    setIgnoredAlertIds((currentValue) => [...currentValue, id]);
  };

  const handleRemoveAll = () => {
    setIgnoredAlertIds([...alerts.map((alert) => alert.id)]);
  };

  function numberNotifications() {
    return alerts.filter(
      (alert) =>
        alert.needTreatment == false && !ignoredAlertIds.includes(alert.id)
    ).length;
  }

  useEffect(() => {
    const devices = trackings.reduce((acc, tracking) => {
      acc[tracking.device.id] = tracking.device;
      return acc;
    }, {});

    setIndexedDevices(devices);
  }, [trackings]);

  function deviceName(event) {
    const deviceNameId = indexedDevices[event];

    if (!deviceNameId) {
      return;
    }

    return deviceNameId.alias || deviceNameId.identifier;
  }

  async function getCoordinate(deviceId, startedAt) {
    const tracking = trackings.find(
      (tracking) => tracking.device.id === deviceId
    );
    const trackingDevice = tracking?.device;

    if (!trackingDevice) {
      return;
    }

    const messages = await FlespiService.getFlespiMessages(
      [trackingDevice.flespi_id],
      {
        count: 1,
        from: Math.floor(new Date(startedAt) / 1000),
      }
    );

    if (!messages[0]) {
      return;
    }

    const { latitude, longitude } = messages[0].last_status;

    addZoomIn(17);

    addCenterMap({
      lat: latitude,
      lng: longitude,
    });

    addValidationPin(true);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div className={classes.title} />
          <div className={classes.hide}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <Badge badgeContent={numberNotifications()} color="primary">
                <NotificationsIcon style={{ color: "#fff", fontSize: 32 }} />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </div>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.box}>
          <div>
            <div className={classes.drawerHeader}>
              <FilterNotifications />
            </div>
            <div>
              <Button
                className={classes.ignoreAllNotifications}
                onClick={handleRemoveAll}
              >
                IGNORAR TODOS
              </Button>
            </div>

            {alerts
              .filter(
                (alert) =>
                  alert.needTreatment == false &&
                  !ignoredAlertIds.includes(alert.id)
              )
              .map((alert, index) => {
                return (
                  <List key={alert.id}>
                    <div className={classes.alertBox}>
                      <ListItem className={classes.alertItem}>
                        <span>
                          {alert.eventType.name} | {deviceName(alert.deviceId)}
                        </span>
                        <span className={classes.alertTime}>
                          {formatDate(alert.startedAt)}
                        </span>
                        <div className={classes.actionContainer}>
                          <Button
                            className={classes.notificationIgnore}
                            onClick={() => handleRemove(alert.id)}
                          >
                            IGNORAR
                          </Button>
                          <Button
                            className={classes.notificationIgnore}
                            onClick={() =>
                              getCoordinate(alert.deviceId, alert.startedAt)
                            }
                          >
                            VISUALIZAR
                          </Button>
                        </div>
                      </ListItem>
                    </div>
                  </List>
                );
              })}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
