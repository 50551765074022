import devicesApi from "../devices";

export const handleGetCategories = async (customerId) => {
  try {
    const response = await devicesApi.get(`/customer/${customerId}/categories`);
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handlePostCategorie = async (customerId, name) => {
  try {
    const response = await devicesApi.post(
      `/customer/${customerId}/categories`,
      {
        name,
      }
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handleDeleteCategorie = async (customerId, id) => {
  try {
    const response = await devicesApi.delete(
      `/customer/${customerId}/categories/${id}`
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handleEditCategorie = async (customerId, id, name) => {
  try {
    const response = await devicesApi.put(
      `/customer/${customerId}/categories/${id}`,
      {
        name,
      }
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};
