import {
  styled as styledMUI,
  Typography,
  TextField,
  Button,
  Container,
  Box,
  Paper,
} from "@mui/material";

export const Div = styledMUI(Box)(({ open }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  maxHeight: "100vh",
  height: "100%",
  width: "300px",
  transform: open ? `translateX(-305px)` : `300px`,
  transition: "all .3s",
}));

export const CustomPaper = styledMUI(Paper)(() => ({
  width: "100%",
  maxHeight: "100vh",
  overflowX: "scroll",
  height: "100%",
  zIndex: 1,
}));

export const Title = styledMUI(Typography)(() => ({
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "16.34px",
}));

export const CustomTextField = styledMUI(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "5px",
    },
  },
  input: {
    fontSize: "16px",
    fontWeight: 400,
    height: "30px",
  },
  backgroundColor: "#F8F8F8",
  margin: "5px 0px 5px 0px",
}));

export const ContainerButton = styledMUI(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "15px",
}));

export const UpdateDataButton = styledMUI(Button)(() => ({
  "&:hover": {
    backgroundColor: "#EBF9FC",
  },
  backgroundColor: "#EBF9FC",
  border: "1px solid #2A93A7",
  color: "#2A93A7",
  marginBottom: "10px",
  textTransform: "none",
  fontSize: "15px",
  width: "100%",
}));

export const SaveButton = styledMUI(Button)(() => ({
  "&:hover": {
    backgroundColor: "#2A93A7",
  },
  backgroundColor: "#2A93A7",
  color: "#FFFFFF",
  textTransform: "none",
  fontSize: "15px",
}));

export const CancelButton = styledMUI(Button)(() => ({
  "&:hover": {
    backgroundColor: "#F8F8F8",
  },
  backgroundColor: "#F8F8F8",
  color: "#2A93A7",
  textTransform: "none",
  fontSize: "15px",
}));

export const DetoursStopsButton = styledMUI(Button)(() => ({
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
  backgroundColor: "#FFFFFF",
  color: "#2A93A7",
  marginTop: "5px",
  textTransform: "none",
  fontSize: "16px",
}));
