import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  divChatItem: {
    height: "64px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 10,
  },
  infosChatItem: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
  },
  pushChatItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "18px",
    height: "18px",
    borderRadius: 100,
    backgroundColor: "#3AC4E0",
    marginLeft: 5,
    fontSize: "14px",
  },
}));
