import React, { useState } from "react";
import {
  parseISO,
  differenceInSeconds as calculateDifferenceInSeconds,
  isValid as checkIsValidDate,
} from "date-fns";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { Link, Map } from "@material-ui/icons";

import { formatDateReadable } from "../../../domain/format/date";
import { formatDateTimeISO } from "../../../commons/utils/date";
import * as exceptions from "../../../commons/exceptions";

export const formatEventReportRows = (
  fireDispatchDeviceApi,
  eventsReport,
  handleSetTransferAndDevice,
  setReportOnScreen,
  customerUnit,
  customerActivity,
  customerCategory,
  customerProducts
) => {
  if (!Array.isArray(eventsReport))
    throw exceptions.createException({
      code: "invalid-format",
    });

  const data = [];

  eventsReport.forEach((event) => {
    const row = {
      id: event.id,
      date: getFormattedDate(event),
      customerUnit: customerUnit[event.customer_unit_id]
        ? customerUnit[event.customer_unit_id]
        : "",
      customerActivity: customerActivity[event.transfer_activity_id]
        ? customerActivity[event.transfer_activity_id]
        : "",
      customerCategory: customerCategory[event.transfer_category_id]
        ? customerCategory[event.transfer_category_id]
        : "",
      product: customerProducts[event.transfer_product_id]
        ? customerProducts[event.transfer_product_id]
        : "",
      name: event.name,
      from: event.from,
      to: event.to,
    };
    if (event.associated_devices.length) {
      event.associated_devices.forEach((device) => {
        data.push({
          ...row,
          bond: renderBonds(
            device.id,
            event,
            handleSetTransferAndDevice,
            setReportOnScreen,
            fireDispatchDeviceApi
          ),
          route: getPopupButton(device.id, event),
        });
      });
    } else {
      data.push({
        ...row,
        bond: <></>,
        route: getPopupButton(null, event),
      });
    }
  });

  return data;
};

const renderBonds = (
  device_id,
  event,
  handleSetTransferAndDevice,
  setReportOnScreen,
  fireDispatchDeviceApi
) => {
  const associatedDevices = event?.associated_devices;
  const nameDevice = associatedDevices
    .filter((n) => n.id == device_id)
    .map((n) => n.alias || n.identifier);
  const hasBonds =
    Array.isArray(event?.associated_devices) &&
    event?.associated_devices?.length > 0 &&
    typeof event.associated_devices[0]?.identifier === "string";

  if (!hasBonds)
    return (
      <Tooltip title="Sem vínculos" arrow>
        <Typography variant="h6" style={{ fontSize: "10px" }}>
          Sem device associado
        </Typography>
      </Tooltip>
    );

  return (
    <Tooltip title="Ver detalhes" arrow>
      <IconButton
        variant="outlined"
        onClick={() => {
          fireDispatchDeviceApi();
          handleSetTransferAndDevice(event, device_id);
          setReportOnScreen("identifier");
        }}
        style={{ display: "flex", marginLeft: "-30px" }}
      >
        <Typography
          variant="h6"
          style={{ fontSize: "10px", color: "black", width: "100px" }}
        >
          {nameDevice}
        </Typography>
      </IconButton>
    </Tooltip>
  );
};

const getFormattedDate = (event) => {
  if (!event?.scheduled_date) return "-";

  try {
    return formatDateReadable(event.scheduled_date);
  } catch (error) {
    return "-";
  }
};

const getPopupButton = (device_id, event) => {
  const openCurrentEventRoute = () => {
    if (!device_id) return;
    openRoutes(
      device_id,
      event.id,
      formatDateTimeISO(new Date(event.start_date)),
      formatDateTimeISO(new Date(event.end_date))
    );
  };

  const isMapDisabled =
    !device_id ||
    !event?.associated_devices ||
    event.associated_devices.length === 0 ||
    event.end_date === null;

  return (
    <Tooltip title="Ver no Mapa" aria-label="map" arrow>
      <IconButton
        disabled={isMapDisabled}
        variant="outlined"
        onClick={openCurrentEventRoute}
      >
        <Map />
      </IconButton>
    </Tooltip>
  );
};

function openRoutes(deviceId, tranferId, startDate, end_date) {
  const url = `${window.location.origin}/reports/${deviceId}/${tranferId}/${startDate}/${end_date}`;
  window.open(
    url,
    "_blank",
    "toolbar=0,location=0,menubar=0,width=800,height=650"
  );
}
