import { Button, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getScript } from "../../../services/eventAnalyticsApi/scriptService";

import CircularProgress from "@material-ui/core/CircularProgress";

import { useStyles } from "./style";

export default function Form({
  date,
  deviceName,
  address,
  onSubmit,
  onClickAlertAddress,
  event,
  message,
}) {
  const classes = useStyles();

  const [scriptList, setScriptList] = useState([]);

  const [description, setDescription] = useState("");
  const customerId = useSelector((state) => state.auth.user.customer.id);
  useEffect(() => {
    const loadScripts = async () => {
      const scripts = await getScript(customerId);
      setScriptList(scripts);
    };
    loadScripts();
  }, [customerId]);

  const handleSubmit = () => {
    onSubmit({ description });
  };

  return (
    <>
      <div className={classes.alertsDescriptions}>
        <div>
          <p>
            <span>
              <strong>Horário</strong>
            </span>
            <br />
            <span>{date}</span>
          </p>
          <p>
            <span>
              <strong>Identificador</strong>
            </span>
            <br />
            <span>{deviceName}</span>
          </p>
          {event.eventType.id == 2 && (
            <p>
              <span>
                <strong>Área de risco</strong>
              </span>
              <br />
              <span>{message}</span>
            </p>
          )}
          <p>
            <span>
              <strong>Local do Ocorrido</strong>
            </span>
            <br />
            <Button onClick={onClickAlertAddress}>
              <span style={{ justifySelf: "center" }}>{address}</span>
            </Button>
          </p>
        </div>
        <TextField
          className={classes.treatmentText}
          inputProps={{
            className: classes.treatmentTextInput,
          }}
          label="Escreva o tratamento"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          variant="outlined"
        />
        <Button className={classes.alertTratar} onClick={handleSubmit}>
          Tratar
        </Button>
      </div>

      {event.eventType.id == 6 &&
        (scriptList.length > 0 ? (
          <div>
            <span>
              <strong>Script de ação</strong>
            </span>
            {scriptList.map(({ question }) => (
              <span>
                <p> - {question} </p>
              </span>
            ))}
          </div>
        ) : (
          <center>
            <div>
              <CircularProgress
                size={50}
                style={{ marginTop: 15, color: "#63a9ef" }}
              />
            </div>
          </center>
        ))}
    </>
  );
}
