import { makeStyles } from '@material-ui/core/styles'

import colors from '../../../commons/colors'

const styles = {}

styles.dialogActions = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 12,
}

styles.ripple = {
  backgroundColor: colors.fullWhite,
}

styles.button = {
  padding: '12px 20px',
  width: 120,
  height: 52,
}

styles.buttonLabel = {
  color: colors.fullWhite,
  boxSizing: 'border-box',
}

styles.cancelButtonLabel = {
  color: props => (props.cancelColor || colors.black)
}

styles.editButtonLabel = {
  color: props => (props.editColor || colors.fullWhite),
}
styles.editButton = {
  ...styles.button,
  backgroundColor: props => (props.editColor || colors.lightBlue),
  '&:hover': {
    backgroundColor: props => (props.editColor || colors.lightBlue),
  },
}

styles.confirmButton = {
  ...styles.button,
  backgroundColor: props => (props.confirmBackgroundColor || colors.lightBlue),
  '&:hover': {
    backgroundColor: props => (props.confirmBackgroundColor || colors.lightBlue),
  },
}

styles.cancelButton = {
  ...styles.button,
}

styles.buttonText = {
  color: colors.fullWhite,
}

const useStyles = makeStyles(styles)

export default useStyles