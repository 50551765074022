import * as exceptions from "../../commons/exceptions";
import codes from "../../commons/constants/codes";
import utransferV2API from "../../services/utransferV2";

export const getActiveIncidents = async () => {
  try {
    const { data } = await utransferV2API.get(`/active-incidents`);

    return data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};
