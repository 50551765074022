import { Box, Typography } from "@material-ui/core";
import React from "react"
import styles from "./styles";

const Indicator = ({ number, label }) => {
  return (
    <Box style={styles.indicator}>
      <Typography component="div" style={styles.number}>{number}</Typography>
      <Typography component="div" style={styles.label}>{label}</Typography>
    </Box>
  );
};

export default Indicator;
