import React, { useEffect, useState } from "react";
import { format, isValid } from "date-fns";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { toast } from "material-react-toastify";

import { ReportLoader } from "../loader";
import { getBusinessUnityReportData } from "../../../services/utransferApiV2/unit-report";
import { useStyles } from "../UnityReport/styles";
import { useCallback } from "react";

export const UnityReport = ({ filters }) => {
  const { tableCell, tableHeaderCell } = useStyles();

  const [unities, setUnities] = useState({
    data: [],
    page: 1,
    perPage: 5,
    totalPages: 0,
    totalRows: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const loadReportData = useCallback(
    async (page) => {
      if (!isValid(filters.from) || !isValid(filters.to)) {
        return;
      }

      setIsLoading(true);

      try {
        const params = {
          from: format(filters.from, "yyyy-MM-dd HH:mm:ss"),
          to: format(filters.to, "yyyy-MM-dd HH:mm:ss"),
          customerUnitIds: filters.customerUnitIds ?? [],
          customerActivityIds: filters.customerActivityIds ?? [],
          customerCategoryIds: filters.customerCategoryIds ?? [],
          customerProductIds: filters.customerProductIds ?? [],
          transferName: String(filters.event).trim() || undefined,
          page,
          perPage: unities.perPage,
        };

        setUnities(await getBusinessUnityReportData(params));
      } catch (e) {
        toast.error(
          "Falha ao tentar obter os dados do relatório de unidade de negócios."
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      filters.from,
      filters.to,
      filters.customerUnitIds,
      filters.customerActivityIds,
      filters.customerCategoryIds,
      filters.customerProductIds,
      filters.event,
      unities.perPage,
    ]
  );

  useEffect(() => {
    loadReportData(1);
  }, [loadReportData]);

  const handlePageChange = (_, page) => {
    loadReportData(++page);
  };

  if (isLoading) {
    return <ReportLoader />;
  }

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={170}
              className={`${tableCell} ${tableHeaderCell}`}
            >
              Unidade de negócio
            </TableCell>
            <TableCell className={`${tableCell} ${tableHeaderCell}`}>
              Nome do transfer
            </TableCell>
            <TableCell
              align="center"
              width={170}
              className={`${tableCell} ${tableHeaderCell}`}
            >
              Total de transfers
            </TableCell>
            <TableCell
              align="center"
              width={170}
              className={`${tableCell} ${tableHeaderCell}`}
            >
              Total de eventos
            </TableCell>
            <TableCell
              align="center"
              width={240}
              className={`${tableCell} ${tableHeaderCell}`}
            >
              Média de eventos por transfer
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {unities.data.map((unity, index) => {
            return (
              <TableRow key={index}>
                <TableCell align="center">{unity.customerUnitName}</TableCell>
                <TableCell>{unity.transferName}</TableCell>
                <TableCell align="center">{unity.totalTransfers}</TableCell>
                <TableCell align="center">{unity.totalEvents}</TableCell>
                <TableCell align="center">
                  {unity.mediaOfEventsByTransfer}
                </TableCell>
              </TableRow>
            );
          })}
          {unities.data.length === 0 && (
            <TableRow>
              <TableCell align="center" colSpan={5}>
                Não há dados
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={5}
              rowsPerPageOptions={[5, 10, 20]}
              count={unities.totalRows}
              rowsPerPage={unities.perPage}
              page={unities.page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={(event) => {
                setUnities((previous) => ({
                  ...previous,
                  perPage: event.target.value,
                }));
              }}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
              labelRowsPerPage={"Linhas por página:"}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};
