import devicesApi from "../devices";

export const handleGetProducts = async (customerId) => {
  try {
    const response = await devicesApi.get(`/customer/${customerId}/products`);
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handlePostProduct = async (customerId, name) => {
  try {
    const response = await devicesApi.post(`/customer/${customerId}/products`, {
      name,
    });
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handleDeleteProduct = async (customerId, id) => {
  try {
    const response = await devicesApi.delete(
      `/customer/${customerId}/products/${id}`
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};

export const handleEditProduct = async (customerId, id, name) => {
  try {
    const response = await devicesApi.put(
      `/customer/${customerId}/products/${id}`,
      {
        name,
      }
    );
    return response.data;
  } catch ({ response }) {
    return response;
  }
};
