import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import colors from "../../commons/colors";
import devicesAPI from "../../services/devices";

import StepperButton from "../../components/StepperModalButton/stepperModalButton";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { can } from "../../services/auth";
import { Types, genericAction } from "../../store/ducks/transfer";

const styles = (theme) => ({
  root: {
    width: 600,
    fullWidth: true,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "#06B0CF" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs() {
  const customerId = useSelector((store) => store.auth.customer.customer);
  const dispatch = useDispatch();
  const modalNavigationCount = useSelector(
    (store) => store.transfer.modalNavigationCount
  );
  const [open, setOpen] = useState(false);
  const [openUpdateTransferDialog, setOpenUpdateTransferDialog] =
    useState(false);

  const handleClickOpenUpdateDialog = () => {
    setOpenUpdateTransferDialog(true);
  };

  const handleClickCloseUpdateDialog = () => {
    setOpenUpdateTransferDialog(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(genericAction(Types.FREQUENCY, {}));
  };

  const handleUpdatePreConfigTransfers = async () => {
    try {
      await devicesAPI.patch(
        `/customer/${customerId}/transfers/pre-configured`
      );
      setOpenUpdateTransferDialog(false);
      toast.success(`Transfers atualizados com sucesso.`);
    } catch (error) {
      setOpenUpdateTransferDialog(false);
      if (error.response.status == 404) {
        return toast.warning(`Nenhum transfer para ser atualizado.`);
      }
      toast.error(`Não foi possível atualizar os transfers.`);
    }
  };

  const handleBack = () => {
    if (modalNavigationCount === 0) return;
    dispatch(genericAction(Types.SET_MODAL_NAVIGATION_PREVIOUS));
  };

  return (
    <div id="modal-screen">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          style={{
            width: 213,
            height: 48,
            backgroundColor: "#06B0CF",
            borderRadius: 10,
            opacity: 1,
          }}
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          startIcon={<AddIcon />}
        >
          Criar Transfers
        </Button>
        {can("can-recreate-transfers") && (
          <Button
            style={{
              width: 213,
              height: 48,
              backgroundColor: "#06B0CF",
              borderRadius: 10,
              opacity: 1,
              marginTop: 5,
              fontSize: 13,
              flexDirection: "column",
            }}
            variant="contained"
            color="primary"
            onClick={handleClickOpenUpdateDialog}
            startIcon={<RefreshIcon />}
          >
            ATUALIZAR TRANSFERS
          </Button>
        )}
      </div>
      <Dialog
        style={{ zIndex: 2 }}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {modalNavigationCount !== 0 && (
            <Button onClick={handleBack}>
              {" "}
              <ChevronLeftIcon
                fontSize="large"
                style={{ color: "#06B0CF" }}
              />{" "}
            </Button>
          )}
          Novo Transfer | <strong>Cadastro</strong>
        </DialogTitle>
        <DialogContent dividers>
          <div id="Redux-Forms">
            <StepperButton />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        style={{ zIndex: 2 }}
        maxWidth={"lg"}
        onClose={handleClickCloseUpdateDialog}
        aria-labelledby="customized-dialog-title"
        open={openUpdateTransferDialog}
      >
        <DialogContent
          dividers
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <center>
            <div>
              <strong>Deseja atualizar os transfers já criados?</strong>
            </div>
            <Button
              style={{
                width: 68,
                height: 32,
                backgroundColor: "#06B0CF",
                borderRadius: 10,
                opacity: 1,
                marginTop: 5,
                marginBottom: -7,
              }}
              variant="contained"
              color="primary"
              onClick={handleUpdatePreConfigTransfers}
            >
              SIM
            </Button>
            <Button
              onClick={handleClickCloseUpdateDialog}
              style={{
                width: 68,
                height: 32,
                marginLeft: 50,
                marginTop: 5,
                marginBottom: -7,
                backgroundColor: colors.darkGray,
                opacity: 1,

                borderRadius: 10,
                border: "none",
              }}
              variant="contained"
              color="primary"
            >
              NÃO
            </Button>
          </center>
        </DialogContent>
      </Dialog>
    </div>
  );
}
