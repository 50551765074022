import styled from "styled-components";

export const Div = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  .header {
    display: flex;
    flex-direction: row;
    width: 94vw;
  }
  h1 {
    flex: 10;
    padding: 10px;
    margin-left: 10px;
  }
  .search {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-top: 20px;
  }
  .btnfilter {
    flex: 1;
    display: flex;
    padding: 10px;
    margin-top: 20px;
  }
  .table {
    padding: 10px;
  }
`;
