import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress'

import useStyles from './styles';
import { transferStates } from '../constants';

const LinearProgressUnderBar = ({ device, transferState }) => {
  const classes = useStyles()

  switch (transferState) {
    case transferStates.ONGOING:
    case transferStates.ONGOING_MISSING_DATA:
      return (
        <LinearProgress
          className={classes.linearProgressBar}
          variant='determinate'
          value={device.transfer?.route_progress}
        />
      )
    default:
      return null
  }
}

export default LinearProgressUnderBar