import * as AreaInformationType from "../enums/areaInformationType";

const map = {
  [AreaInformationType.DESCRIPTION]: {
    copy: false,
    type: "text",
  },
  [AreaInformationType.RESPONSIBLE]: {
    copy: false,
    type: "info",
  },
  [AreaInformationType.FUNCITON]: {
    copy: false,
    type: "info",
  },
  [AreaInformationType.EMAIL]: {
    copy: true,
    type: "email",
  },
  [AreaInformationType.TELEPHONE]: {
    copy: true,
    type: "phone",
  },
  [AreaInformationType.CELLPHONE]: {
    copy: true,
    type: "phone",
  },
  [AreaInformationType.ADDRESS]: {
    copy: true,
    type: "location",
  },
  [AreaInformationType.WORKING_TIME]: {
    copy: false,
    type: "info",
  },
  [AreaInformationType.POPULATION]: {
    copy: false,
    type: "text",
  },
  [AreaInformationType.POLICE]: {
    copy: false,
    type: "text",
  },
  [AreaInformationType.FIREFIGHTERS]: {
    copy: false,
    type: "text",
  },
  [AreaInformationType.CIVIL_POLICE]: {
    copy: false,
    type: "text",
  },
  [AreaInformationType.PRECINCT]: {
    copy: false,
    type: "text",
  },
};

export const mapFromAreaInformationType = (areaInformationType) => {
  return map[areaInformationType] ?? map[AreaInformationType.DESCRIPTION];
};
