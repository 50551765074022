import axios from 'axios'

import { store } from '../store'

const eventAnalyticsAPI = axios.create({
  baseURL: process.env.REACT_APP_EVENT_ANALYTICS_API,
})

eventAnalyticsAPI.interceptors.request.use((config) => {
  const state = store.getState()
  const accessToken = state?.auth?.token?.accessToken

  if (accessToken)
    config.headers['Authorization'] = `Bearer ${accessToken}`

  return config
})

export default eventAnalyticsAPI
