import axios from 'axios';

export const flespiAPI = axios.create({
  baseURL: process.env.REACT_APP_API_FLESPI_URL,
  headers: {
    Authorization: `FlespiToken ${process.env.REACT_APP_API_FLESPI_TOKEN}`
  }
});

export const flespiAPI2 = axios.create({
  baseURL: process.env.REACT_APP_API_FLESPI_URL2,
  headers: {
    Authorization: `FlespiToken ${process.env.REACT_APP_API_FLESPI_TOKEN}`
  }
});

export default flespiAPI;
