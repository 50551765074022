import React, { useEffect } from "react";
import Login from "./";

import { loginSSO } from "../../store/ducks/auth";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function LoginSSO(props) {
  useEffect(() => {
    const { location, history, loginSSO } = props;

    const query = new URLSearchParams(location.search);
    if (!query.has("sso")) {
      history.push("/");
    } else if (query.has("sso") && query.has("code")) {
      loginSSO(query.get("sso"), query.get("code"));
    } else if (query.has("sso")) {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port =
        window.location.port !== "" ? `:${window.location.port}` : "";
      const redirectUrl = `${protocol}//${hostname}${port}/login/sso`;
      window.location.href = `${
        process.env.REACT_APP_SSO_URL
      }/login?sso=${query.get("sso")}&redirect=${encodeURIComponent(
        redirectUrl
      )}`;
    }
  }, []);

  return <Login sso={true} />;
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ loginSSO }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginSSO);
