import { store } from '../store'

export const addUserAuthorization = (axiosAPI) => {
  axiosAPI.interceptors.request.use((config) => {
    const state = store.getState()
    const accessToken = state?.auth?.token?.accessToken
  
    if (accessToken)
      config.headers['Authorization'] = `Bearer ${accessToken}`
    
    return config
  })
}