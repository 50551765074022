import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Drawer,
  Toolbar,
  CssBaseline,
  List,
  IconButton,
  Badge,
  Paper,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import AlertsIcon from "../../assets/alert.png";
import { useStyles } from "./styles";

import {
  addAlertSound,
  addAlertsAction,
  addAlertsFlespiAction,
  addDeviceIdAction,
  addEventDetailsAction,
  addIdentifierSearchAction,
  addOpenAlertsSidebarAction,
  addValidateAlertPinAction,
  rmvAlertSound,
} from "../../store/ducks/monitoringMap";

import * as eventService from "../../services/eventAnalyticsApi/eventService";

import TreatmentAlert from "./treatmentAlert";
import AlertItem from "./AlertItem";
import _ from "lodash";
import moment from "moment";
import AudioLoop from "../Sound";

export default function AlertSidebar() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openAlert, setOpenAlert] = useState(false);

  const alerts = useSelector((store) => store.monitoringMap.alertsFlespi);

  const openAlertsSidebar = useSelector(
    (store) => store.monitoringMap.openAlertsSidebar
  );

  const alertsSound = useSelector((store) => store.monitoringMap.alertsSound);

  const trackings = useSelector((store) => store.monitoringMap.devices);
  const indexedDevices = trackings.reduce((acc, tracking) => {
    acc[tracking.device.id] = tracking.device;
    return acc;
  }, {});

  const removeAlertSound = (eventId) => {
    dispatch(rmvAlertSound(eventId));
  };

  const customerId = useSelector((store) => store.auth.customer.customer);
  const handleDrawerAlertsOpen = (event, device_id) => {
    setOpenAlert(true);
    removeAlertSound(event.id);
    dispatch(addEventDetailsAction(event));
    dispatch(addAlertsAction(alerts));
    dispatch(addValidateAlertPinAction(false));
    dispatch(addDeviceIdAction(device_id));
  };

  const addOpenAlertsSidebar = (openAlertsSidebar) => {
    const open = !openAlertsSidebar;

    if (!open) {
      dispatch(addValidateAlertPinAction(open));
    }

    dispatch(addOpenAlertsSidebarAction(open));
  };

  useEffect(() => {
    const getUntreatedEvents = async () => {
      const transferIds = trackings
        .filter((tracking) => tracking.transfer)
        .map((tracking) => tracking.transfer.id);

      const devicesWithoutTransfers = trackings
        .filter((tracking) => !tracking.transfer)
        .map((tracking) => tracking.device.id);

      let untreatedEvents = [];

      if (transferIds.length > 0) {
        untreatedEvents = await eventService.getEvents(customerId, {
          treated: false,
          transferIds,
        });
      }

      let eventsWithoutTransfer = [];
      if (devicesWithoutTransfers.length > 0) {
        const yesterday = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss");

        eventsWithoutTransfer = await eventService.getEvents(customerId, {
          treated: false,
          deviceIds: devicesWithoutTransfers,
          startedAt: yesterday,
        });
      }

      const allEvents = [...eventsWithoutTransfer, ...untreatedEvents];
      const setEvents = new Set();
      const filteredEvents = allEvents.filter((event) => {
        const duplicatedEvent = setEvents.has(event.id);
        setEvents.add(event.id);
        return !duplicatedEvent;
      });

      const alertsIds = alerts.map((event) => event.id);
      const newAlert = filteredEvents.filter(
        (alert) => !alertsIds.includes(alert.id)
      );

      for (let alert of newAlert) {
        const device = indexedDevices[alert.deviceId];

        if (!device?.device_setting.events_types) {
          continue;
        }

        const eventType = device.device_setting.events_types.find(
          (eventType) => {
            return eventType.event_type_id === alert.eventTypeId;
          }
        );

        if (eventType?.alert_sound && !alertsSound.includes(alert.id)) {
          dispatch(addAlertSound(alert.id));
        }
      }

      dispatch(addAlertsFlespiAction(filteredEvents));
    };

    getUntreatedEvents();
  }, [trackings]);

  const renderAlert = (event) => {
    const device = indexedDevices[event.deviceId];

    if (!device) {
      return;
    }

    return (
      <AlertItem
        key={event.id}
        event={event}
        device={device}
        onDetailsClick={handleDrawerAlertsOpen}
      />
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {alertsSound.length > 0 && <AudioLoop />}
      <div
        className={clsx(
          classes.appBar,
          {
            [classes.appBarShift]: openAlertsSidebar || openAlert,
          },
          {
            [classes.appBarAlert]: openAlertsSidebar && openAlert,
          }
        )}
      >
        <Toolbar style={{ marginTop: "-115px" }}>
          <div className={classes.title} />
          <div className={classes.hide}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="center"
              onClick={() => {
                addOpenAlertsSidebar(openAlertsSidebar);
                setOpenAlert(false);
                dispatch(addIdentifierSearchAction(""));
              }}
            >
              <Badge
                badgeContent={
                  alerts.filter((alert) => alert.needTreatment == true).length >
                  0
                    ? "!"
                    : null
                }
                color="primary"
              >
                <img src={AlertsIcon} alt="Alerts Icon" />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </div>
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerAlert]: openAlert,
        })}
        variant="persistent"
        anchor="right"
        open={openAlertsSidebar}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerPaperAlert]: openAlert,
          }),
        }}
      >
        <div className={classes.box}>
          <div>{openAlert ? <TreatmentAlert /> : null}</div>
          <Paper className={classes.eventListContainer} elevation={2}>
            <List dense component="div" role="list">
              {alerts
                .filter((alert) => alert.needTreatment == true)
                .map(renderAlert)}
            </List>
          </Paper>
        </div>
      </Drawer>
    </div>
  );
}
