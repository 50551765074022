import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "material-react-toastify";

import api from "../../../services/transferApi";
import { parseISO, format } from "date-fns";

import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import * as deviceApiService from "../../../services/devicesService";
import { GLOBO_CUSTOMER_ID } from "../../../commons/constants/customers";
import { handleGetSettings } from "../../../services/customerAreaManagement/setting";

function getSteps() {
  return ["Configuração", "Cadastro", "Roteirização", "Vinculação"];
}

export default function EditForm({ transfer }) {
  const now = format(parseISO(transfer.scheduled_date), "yyyy-MM-dd'T'HH:mm");
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const steps = getSteps();
  const [unity, setUnity] = useState("");
  const [name, setName] = useState("");
  const [customerActivities, setCustomerActivities] = useState([]);
  const [customerActivitySelected, setCustomerActivitySelected] = useState("");
  const [customerCategories, setCustomerCategories] = useState([]);
  const [customerCategorySelected, setCustomerCategorySelected] = useState("");
  const [customerUnits, setCustomerUnits] = useState([]);
  const [customerUnitSelected, setCustomerUnitSelected] = useState("");
  const [responsible, setResponsible] = useState("");
  const [scheduled_date, setScheduledDate] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [customerProductSelected, setCustomerProductSelected] = useState("");
  const [customerProduct, setCustomerProduct] = useState("");
  const [success, setSuccess] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [autoStartChecked, setAutoStartChecked] = useState(false);
  const [autoEndChecked, setAutoEndChecked] = useState(false);
  const [configurations, setConfigurations] = useState([]);
  const customerId = useSelector((store) => store.auth.customer.customer);
  const dispatch = useDispatch();
  const history = useHistory();

  let aux = 0;

  useEffect(() => {
    setUnity(transfer.unity);
    setName(transfer.name);
    setResponsible(transfer.responsible);
    setScheduledDate(transfer.scheduled_date);
    setFrom(transfer.from);
    setTo(transfer.to);
    setAutoStartChecked(transfer.automatic_beginning);
    setAutoEndChecked(transfer.automatic_end);
    setCustomerProductSelected(transfer.transfer_product_id);

    const config = async () => {
      const response = await handleGetSettings(customerId);

      setConfigurations(response);
    };

    const loadFormData = async () => {
      setLoadingData(true);
      setCustomerActivities(
        await deviceApiService.getCustomerActivities(customerId)
      );
      setCustomerCategories(
        await deviceApiService.getCustomerCategories(customerId)
      );
      setCustomerProduct(
        await deviceApiService.getCustomerProducts(customerId)
      );
      setCustomerUnits(await deviceApiService.getCustomerUnits(customerId));

      setCustomerActivitySelected(transfer.transfer_activity_id || "");
      setCustomerCategorySelected(transfer.transfer_category_id || "");
      setCustomerUnitSelected(transfer.customer_unit_id || "");
      setCustomerProductSelected(transfer.transfer_product_id || "");

      setLoadingData(false);
    };

    config();
    loadFormData();
  }, []);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const token = useSelector((store) => store.auth.token.accessToken);

  function addIdToScriptingaction(id) {
    return { type: "ADD_IDTOSCRIPTING", id };
  }

  const handleScripting = (e) => {
    aux = 1;
    handleEditTransfer(e, aux);
  };

  const fieldRequired = (field) => {
    const configuration = configurations.find((n) => n.field == field);

    if (!configuration) {
      return false;
    }

    return configuration.required;
  };

  async function handleEditTransfer(e, aux) {
    e.preventDefault();

    if (!customerUnitSelected && fieldRequired(1))
      return toast.error("Selecione uma Unidade sugerida");
    if (!customerCategorySelected && fieldRequired(2))
      return toast.error("Selecione uma Categoria sugerida");
    if (!customerProductSelected && fieldRequired(3))
      return toast.error("Selecione um Produto sugerido");
    if (!customerActivitySelected && fieldRequired(4))
      return toast.error("Selecione uma Atividade sugerida");

    setSuccess(true);
    const data = {
      unity,
      name,
      responsible,
      scheduled_date: format(parseISO(scheduled_date), "yyyy-MM-dd HH:mm", {
        timeZone: "America/Sao_Paulo",
      }),
      from,
      to,
      automatic_beginning: autoStartChecked,
      automatic_end: autoEndChecked,
      transfer_activity_id: customerActivitySelected || null,
      transfer_category_id: customerCategorySelected || null,
      customer_unit_id: customerUnitSelected || null,
      transfer_product_id: customerProductSelected.toString() || null,
    };

    try {
      await api.request({
        url: `/transfers/${transfer.id}`,
        method: "PATCH",
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(addIdToScriptingaction(transfer.id));

      if (aux === 1) {
        aux = 0;
        history.push("/scripting");
      } else {
        window.location.reload(true);
      }
    } catch (err) {
      alert("Erro ao Editar");
      setSuccess(false);
      console.log(err);
    }
  }

  return (
    <>
      <div id="stepper">
        <Stepper nonLinear activeStep={activeStep + 1}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                disabled
                completed={completed[index]}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <br />
      </div>

      <form onSubmit={handleEditTransfer}>
        {success === false && loadingData === false ? (
          <>
            <Grid container spacing={3} style={{ marginBottom: 12 }}>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  style={{ marginLeft: 0, marginRight: 10 }}
                  control={
                    <Switch
                      checked={autoStartChecked}
                      onClick={() => setAutoStartChecked(!autoStartChecked)}
                      size="small"
                      color="primary"
                    />
                  }
                  label="Inicio Automático"
                  labelPlacement="start"
                />
                <FormControlLabel
                  style={{ marginLeft: 0, marginRight: 10 }}
                  control={
                    <Switch
                      checked={autoEndChecked}
                      onClick={() => setAutoEndChecked(!autoEndChecked)}
                      size="small"
                      color="primary"
                    />
                  }
                  label="Fim Automático"
                  labelPlacement="start"
                />
              </Grid>

              {configurations.map((n) => n.field).includes(1) && (
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }} size="small">
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      Unidade de Negócios
                    </Typography>
                    <Select
                      variant="outlined"
                      value={customerUnitSelected}
                      displayEmpty
                      onChange={(event) => {
                        setCustomerUnitSelected(event.target.value);
                      }}
                    >
                      <MenuItem key={"customerUnit"} value="">
                        <em>Selecione uma unidade</em>
                      </MenuItem>
                      {customerUnits.map((customerUnit, index) => (
                        <MenuItem
                          key={"customerUnit-" + customerUnit.id}
                          value={customerUnit.id}
                        >
                          {customerUnit.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Evento
                </Typography>
                <TextField
                  variant="outlined"
                  id="name"
                  type="search"
                  placeholder="Digite nome do Evento"
                  defaultValue={transfer.name}
                  style={{ width: "100%", height: 40 }}
                  size="small"
                  onChange={(e) => setName(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Responsável
                </Typography>
                <TextField
                  variant="outlined"
                  id="responsible"
                  type="search"
                  placeholder="Digite nome do Responsável"
                  defaultValue={transfer.responsible}
                  style={{ width: "100%", height: 40 }}
                  size="small"
                  onChange={(e) => setResponsible(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                onClick={() =>
                  toast.warning(
                    'Para editar o endereço de partida, clique em "salvar e roteirizar".'
                  )
                }
              >
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Local de Partida
                </Typography>
                <TextField
                  disabled
                  variant="outlined"
                  id="from"
                  type="search"
                  placeholder="Digite local de Partida"
                  defaultValue={transfer.from}
                  style={{ width: "100%", height: 40 }}
                  size="small"
                  onChange={(e) => setFrom(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                onClick={() =>
                  toast.warning(
                    'Para editar o endereço de destino, clique em "salvar e roteirizar".'
                  )
                }
              >
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Local de Destino
                </Typography>
                <TextField
                  disabled
                  variant="outlined"
                  id="to"
                  placeholder="Digite local de Destino"
                  defaultValue={transfer.to}
                  type="search"
                  style={{ width: "100%", height: 40 }}
                  size="small"
                  onChange={(e) => setTo(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <>
                {configurations.map((n) => n.field).includes(4) && (
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }} size="small">
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                        gutterBottom
                      >
                        Atividade
                      </Typography>
                      <Select
                        variant="outlined"
                        value={customerActivitySelected}
                        displayEmpty
                        onChange={(event) => {
                          setCustomerActivitySelected(event.target.value);
                        }}
                      >
                        <MenuItem key={"activity"} value="">
                          <em>Selecione uma atividade</em>
                        </MenuItem>
                        {customerActivities.map((activity, index) => (
                          <MenuItem
                            key={"activity-" + index}
                            value={activity.id}
                          >
                            {activity.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {configurations.map((n) => n.field).includes(2) && (
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }} size="small">
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                        gutterBottom
                      >
                        Categorias
                      </Typography>
                      <Select
                        variant="outlined"
                        value={customerCategorySelected}
                        displayEmpty
                        onChange={(event) => {
                          setCustomerCategorySelected(event.target.value);
                        }}
                      >
                        <MenuItem key={"category"} value="">
                          <em>Selecione uma categoria</em>
                        </MenuItem>
                        {customerCategories.map((category, index) => (
                          <MenuItem
                            key={"category-" + index}
                            value={category.id}
                          >
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {configurations.map((n) => n.field).includes(3) && (
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }} size="small">
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                        gutterBottom
                      >
                        Produto
                      </Typography>
                      <Select
                        variant="outlined"
                        value={customerProductSelected}
                        displayEmpty
                        onChange={(event) => {
                          setCustomerProductSelected(event.target.value);
                        }}
                      >
                        <MenuItem key={"product"} value="">
                          <em>Selecione um produto</em>
                        </MenuItem>
                        {customerProduct.map((product, index) => (
                          <MenuItem key={"product-" + index} value={product.id}>
                            {product.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </>
            </Grid>

            <center>
              <Button
                type="submit"
                autoFocus
                style={{
                  margin: "0 15px",
                  color: "#06B0CF",
                  borderRadius: 8,
                  border: "none",
                  boxShadow: "0px 3px 6px #00000029",
                }}
                variant="outlined"
                color="primary"
              >
                Salvar
              </Button>
              <Button
                onClick={(e) => handleScripting(e)}
                style={{
                  backgroundColor: "#06B0CF",
                  borderRadius: 8,
                  boxShadow: "0px 3px 6px #00000029",
                }}
                variant="contained"
                autoFocus
                color="primary"
              >
                Salvar e Roteirizar
              </Button>
            </center>
          </>
        ) : (
          <>
            <center>
              <div>
                <CircularProgress
                  size={68}
                  style={{ marginTop: 0, color: "#63a9ef" }}
                ></CircularProgress>
              </div>
              <span>{loadingData ? "Carregando dados" : "Salvando"}</span>
            </center>
          </>
        )}
      </form>
    </>
  );
}
