import styled from "styled-components";

export const Footer = styled.div`
  height: 70px;
  background-color: #06b0cf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .tabsBorderBottomColor {
    background-color: #06b0cf;
  }
`;
