import styled from "styled-components";
import ListItemIcon from "@material-ui/core/ListItemIcon";

export const Div = styled.div`
  z-index: 2000;
  padding: 1px;
  width: 73px;
  min-height: 100vh;
  background: #15172f;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  margin: 0px;
  svg {
    color: #23546c;
    transition: 0.7s;
  }
  svg:hover {
    color: #36b0c9;
  }

  svg:active {
    color: #36b0c9;
  }
`;

export const Bg = styled.div`
  padding: 10px;
  background: #1e1f2e;
  margin-bottom: 20px;
  margin-left: -7px;
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  svg {
    color: ${(props) => (props.active ? "#36b0c9" : "#23546c")};
  }
`;
