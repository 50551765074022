import React, { useState, useRef, useEffect } from "react";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Checkbox,
} from "@mui/material";
import {
  SimCardDownload as SimCardDownloadIcon,
  ViewColumnOutlined as ViewColumnOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { toast } from "material-react-toastify";
import * as transfersProvider from "../../infra/http/transfersProvider";
import { useDispatch, useSelector } from "react-redux";
import { formatDateReadable } from "../../domain/format/date";
import {
  ButtonTableHead,
  IconButtonTable,
  PaperStyled,
  StyledTableCell,
  StyledTableHead,
} from "./style";
import { saveAs } from "file-saver";
import ModalBond from "../Filter/Register/modalBondNew";
import ModalStatus from "../Filter/Register/modalStatusNew";
import ModalRemoveTransfer from "../Filter/Register/ModalRemoveTransfer";
import { Link } from "react-router-dom";
import { Types, genericAction } from "../../store/ducks/transfer";

const CustomCheckbox = ({ checked, onChange }) => {
  return <Checkbox checked={checked} onChange={onChange} color="primary" />;
};

export default function CustomizedTables() {
  const searchByUnity = useSelector((store) => store.transfer.unityFetch);
  const searchByEvent = useSelector((store) => store.transfer.eventFetch);
  const searchByFrom = useSelector((store) => store.transfer.fromFetch);
  const searchByTo = useSelector((store) => store.transfer.toFetch);
  const searchByResponsible = useSelector(
    (store) => store.transfer.responsibleFetch
  );
  const searchByScheduledDate = useSelector(
    (store) => store.transfer.scheduledDateFetch
  );
  const searchByCustomerUnitId = useSelector(
    (store) => store.transfer.customerUnitIdFetch
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(true);
  const [visibleColumns, setVisibleColumns] = useState({
    ID: true,
    "Data e Hora": true,
    Evento: true,
    "Local de Partida": true,
    "Local Destino": true,
    Responsável: true,
    Vínculo: true,
    Status: true,
    Relatório: true,
    Remover: true,
  });
  const [showColumnsMenu, setShowColumnsMenu] = useState(false);

  const menuRef = useRef();
  const dispatch = useDispatch();

  const handleToggleColumnsMenu = () => {
    setShowColumnsMenu((prev) => !prev);
  };

  const handleOutsideClick = (event) => {
    if (
      showColumnsMenu &&
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      setShowColumnsMenu(false);
    }
  };

  useEffect(() => {
    if (showColumnsMenu) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showColumnsMenu]);

  const handleToggleColumn = (columnName) => {
    if (["Vínculo", "Status", "Relatório", "Remover"].includes(columnName)) {
      return;
    }

    setVisibleColumns((prevState) => ({
      ...prevState,
      [columnName]: !prevState[columnName],
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchListData();
  }, [
    rowsPerPage,
    page,
    searchByScheduledDate,
    searchByEvent,
    searchByUnity,
    searchByFrom,
    searchByTo,
    searchByResponsible,
    searchByCustomerUnitId,
  ]);

  const formatScheduledDate = (scheduledDate) => {
    try {
      return formatDateReadable(scheduledDate);
    } catch (error) {
      return "-";
    }
  };

  const [data, setData] = useState([]);
  const [rows, setRows] = useState();

  const fetchListData = async () => {
    try {
      setLoading(true);

      const { transfersData, totalRows } =
        await transfersProvider.getTransfersWithDevices({
          page: page,
          perPage: rowsPerPage,
          scheduledDate: `${searchByScheduledDate}`,
          identifier: `${searchByEvent}`,
          from: `${searchByFrom}`,
          to: `${searchByTo}`,
          unity: `${searchByUnity}`,
          responsible: `${searchByResponsible}`,
          customerUnitId: searchByCustomerUnitId,
        });

      setData(transfersData);
      setRows(totalRows);
      setHasData(transfersData.length > 0);
      setLoading(false);
    } catch {
      setLoading(false);
      setHasData(false);
      toast.error("Erro na requisição da API");
    }
  };

  const headers = [
    "ID",
    "Data e Hora",
    "Evento",
    "Local de Partida",
    "Local Destino",
    "Responsável",
    "Vínculo",
    "Status",
    "Relatório",
    "Remover",
  ];

  const filteredHeaders = Object.keys(visibleColumns).filter(
    (column) => visibleColumns[column]
  );

  const excludedColumns = ["Vínculo", "Status", "Relatório", "Remover"];

  const columnProvider = (transfer) => ({
    ID: (transfer) => transfer.id,
    "Data e Hora": (transfer) => formatScheduledDate(transfer.scheduled_date),
    Evento: (transfer) => transfer.name,
    "Local de Partida": (transfer) => transfer.from,
    "Local Destino": (transfer) => transfer.to,
    Responsável: (transfer) => transfer.responsible,
    Vínculo: () => (
      <ModalBond transferData={transfer} onSuccess={fetchListData} />
    ),
    Status: () => <ModalStatus transferData={transfer} />,
    Relatório: () => (
      <Link to="/reportTransfers">
        <IconButtonTable
          onClick={() => {
            dispatch(
              genericAction(Types.SELECT_TRANSFER_ID_REPORT, transfer.id)
            );
          }}
        >
          <SimCardDownloadIcon
            style={{
              color: "black",
              width: "16px",
              height: "16px",
            }}
          />
        </IconButtonTable>
      </Link>
    ),
    Remover: () => (
      <ModalRemoveTransfer transferData={transfer} onSuccess={fetchListData} />
    ),
  });

  const exportTableToCSV = () => {
    const headers = filteredHeaders.filter(
      (column) => !excludedColumns.includes(column)
    );

    const rows = data.map((transfer) =>
      headers.map(
        (columnName) =>
          columnProvider[columnName] && columnProvider[columnName](transfer)
      )
    );

    const csvContent =
      headers.join(",") + "\n" + rows.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "tableTransfer.csv");
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", margin: "20px" }}>
          <CircularProgress />
        </div>
      ) : hasData ? (
        <TableContainer component={Paper}>
          <div style={{ display: "flex", gap: "30px", marginLeft: "20px" }}>
            <ButtonTableHead
              startIcon={<ViewColumnOutlinedIcon />}
              size="medium"
              onClick={handleToggleColumnsMenu}
            >
              Colunas
            </ButtonTableHead>
            <ButtonTableHead
              startIcon={<FileDownloadOutlinedIcon />}
              size="medium"
              onClick={exportTableToCSV}
              disabled={
                filteredHeaders.length === 0 ||
                filteredHeaders.every((col) => excludedColumns.includes(col))
              }
            >
              Exportar
            </ButtonTableHead>
          </div>
          {showColumnsMenu && (
            <PaperStyled elevation={3} ref={menuRef}>
              {headers
                .filter((column) => !excludedColumns.includes(column))
                .map((column) => (
                  <div key={column}>
                    <CustomCheckbox
                      checked={visibleColumns[column]}
                      onChange={() => handleToggleColumn(column)}
                    />
                    {column}
                  </div>
                ))}
            </PaperStyled>
          )}
          <Table>
            <StyledTableHead>
              <TableRow>
                {filteredHeaders.map((head) => (
                  <TableCell align="center" key={head}>
                    {head}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {data.map((transfer) => (
                <TableRow key={transfer.id}>
                  {filteredHeaders.map((columnName) => (
                    <StyledTableCell align="center" key={columnName}>
                      {columnProvider()[columnName] &&
                        columnProvider(transfer)[columnName](transfer)}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            labelRowsPerPage="Linhas por página:"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <div style={{ textAlign: "center", margin: "20px" }}>
          <p>Não há dados a serem exibidos.</p>
        </div>
      )}
    </>
  );
}
