import utransferV2API from "../utransferV2";

export const getReportTransferData = async (transferId) => {
  const response = await utransferV2API.get(`/transfer/${transferId}`);

  return response.data;
};

export const getReportTransferPDF = async (transferId) => {
  const { data } = await utransferV2API.get(
    `/reports/transfer/${transferId}/document`,
    { responseType: "arraybuffer" }
  );

  return data;
};
