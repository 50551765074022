export default(() => ({
  fieldAndButtonContainer: {
    display: 'flex',
    alignItems: 'center'
  },

  textFieldContainer: {
    minWidth: 230
  },

  addButton: {
    marginLeft: 10
  }
}));
