export const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: 16,
    borderBottom: "1px solid #000",
    marginBottom: 16,
  },
  logo: {
    height: 50,
  },
};
