import React from 'react'
import {
  FiberManualRecord as FiberManualRecordIcon
} from "@material-ui/icons";

const DeviceStatus = ({ active }) => {
  if (active)
    return (
      <FiberManualRecordIcon
        style={{ color: "green" }}
        fontSize="small"
      />
    )

  return (
    <FiberManualRecordIcon
      style={{ color: "red" }}
      fontSize="small"
    />
  )
}

export default DeviceStatus