import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { formatDateTimeISO } from "../../commons/utils/date";
import { factoryDeviceIcons } from "../../assets/MapIcons-DevicePosition/factoryIcon";
import { factoryDeviceIconsOff } from "../../assets/MapIcons-DevicePosition/factoryiconsoff";
import { InfoWindow, Marker } from "@react-google-maps/api";
import { useStyles } from "../../components/Map/styles";

import {
  changeVehicleModal,
  addDeviceInfoModal,
  changeVehicleCam,
} from "../../store/ducks/monitoringMap";
import { Paper } from "@material-ui/core";
import Iframe from "react-iframe";

const DeviceMarker = ({ device }) => {
  const deviceInfo = useSelector(
    (store) => store.monitoringMap.deviceInfoModalVehicle
  );

  const deviceIdsWithCamEnabled = useSelector(
    (store) => store.monitoringMap.deviceIdsWithCamEnabled
  );

  const messagesFlespi = useSelector(
    (store) => store.monitoringMap.messagesFlespi
  );

  const [activeLabelIndex, setActiveLabelIndex] = useState("");
  const [currentDevicePositions, setCurrentDevicePositions] = useState({
    lat: 0,
    lng: 0,
  });
  const [lastTwoDevicePositions, setLastTwoDevicePositions] = useState([]);
  const [framesDevicePositions, setFramesDevicePositions] = useState([]);
  const [indexFrameDevicePositions, setIndexFrameDevicePositions] =
    useState(-1);

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    const devicePositions = _.unionBy(messagesFlespi[device.id], "taken_at");

    if (
      !devicePositions[devicePositions.length - 1]?.latitude ||
      !devicePositions[devicePositions.length - 1]?.longitude
    ) {
      return;
    }

    if (
      framesDevicePositions.length > 0 &&
      currentDevicePositions.lat !== lastTwoDevicePositions[1].lat &&
      currentDevicePositions.lng !== lastTwoDevicePositions[1].lng &&
      lastTwoDevicePositions[1].lat !==
        devicePositions[devicePositions.length - 1]?.latitude &&
      lastTwoDevicePositions[1].lng !==
        devicePositions[devicePositions.length - 1]?.longitude
    ) {
      setCurrentDevicePositions(lastTwoDevicePositions[1]);
      setFramesDevicePositions([]);
      setIndexFrameDevicePositions(-1);
    }

    setLastTwoDevicePositions([
      {
        lat: devicePositions[devicePositions.length - 2]?.latitude,
        lng: devicePositions[devicePositions.length - 2]?.longitude,
      },
      {
        lat: devicePositions[devicePositions.length - 1]?.latitude,
        lng: devicePositions[devicePositions.length - 1]?.longitude,
      },
    ]);
  }, [messagesFlespi]);

  useEffect(() => {
    if (lastTwoDevicePositions.length !== 2) {
      return;
    }

    if (
      lastTwoDevicePositions[0].lat === undefined ||
      lastTwoDevicePositions[0].lng === undefined ||
      currentDevicePositions.lat === 0 ||
      currentDevicePositions.lng === 0
    ) {
      setCurrentDevicePositions(lastTwoDevicePositions[1]);
    }

    if (
      lastTwoDevicePositions[0] &&
      currentDevicePositions &&
      currentDevicePositions.lat === lastTwoDevicePositions[0].lat &&
      currentDevicePositions.lng === lastTwoDevicePositions[0].lng &&
      currentDevicePositions.lat !== lastTwoDevicePositions[1].lat &&
      currentDevicePositions.lng !== lastTwoDevicePositions[1].lng
    ) {
      setFramesDevicePositions(() => {
        const spd = 3;
        const frames = [lastTwoDevicePositions[0]];

        for (var percent = 0; percent < 1; percent += 0.0025 * spd) {
          const curLat =
            lastTwoDevicePositions[0].lat +
            percent *
              (lastTwoDevicePositions[1].lat - lastTwoDevicePositions[0].lat);
          const curLng =
            lastTwoDevicePositions[0].lng +
            percent *
              (lastTwoDevicePositions[1].lng - lastTwoDevicePositions[0].lng);
          frames.push({ lat: curLat, lng: curLng });
        }

        frames.push(lastTwoDevicePositions[1]);

        return frames;
      });
    }
  }, [lastTwoDevicePositions]);

  useEffect(() => {
    if (framesDevicePositions.length <= 0) {
      return;
    }

    const interval = setInterval(() => {
      const finishAnimation =
        indexFrameDevicePositions == framesDevicePositions.length - 1;

      if (finishAnimation) {
        setIndexFrameDevicePositions(-1);
        setFramesDevicePositions([]);
      } else {
        setIndexFrameDevicePositions((prev) => ++prev);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [indexFrameDevicePositions, framesDevicePositions]);

  useEffect(() => {
    if (framesDevicePositions.length <= 0 || indexFrameDevicePositions === -1) {
      return;
    }

    setCurrentDevicePositions(framesDevicePositions[indexFrameDevicePositions]);
  }, [framesDevicePositions, indexFrameDevicePositions]);

  const renderIcon = (deviceIconId, taken_at) => {
    const now = new Date();
    const past = new Date(taken_at);
    const diff = Math.abs(now.getTime() - past.getTime());
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    if (days >= 4) {
      return factoryDeviceIconsOff[deviceIconId]
        ? factoryDeviceIconsOff[deviceIconId]()
        : factoryDeviceIconsOff[4]();
    }
    return factoryDeviceIcons[deviceIconId]
      ? factoryDeviceIcons[deviceIconId]()
      : factoryDeviceIcons[4]();
  };

  const showDeviceInfoModal = (deviceInfo) => {
    dispatch(changeVehicleModal());
    dispatch(addDeviceInfoModal(deviceInfo));
  };

  const onToggleClose = (id) => {
    dispatch(
      changeVehicleCam(
        deviceIdsWithCamEnabled.filter((deviceId) => deviceId !== id)
      )
    );
  };

  return (
    <Marker
      opacity={activeLabelIndex === device.id ? 2 : 0.9}
      onMouseOver={() => setActiveLabelIndex(device.id)}
      onMouseOut={() => setActiveLabelIndex("")}
      position={currentDevicePositions}
      icon={renderIcon(device.device_setting?.device_icon_id, device.taken_at)}
      title={`Última posição recebida em ${formatDateTimeISO(
        new Date(device.taken_at)
      )}`}
      label={{
        text: device.alias || device.identifier,
        className:
          activeLabelIndex === device.id
            ? classes.monitoringLabelActive
            : classes.monitoringLabelDefault,
      }}
      onClick={() => showDeviceInfoModal(device)}
    >
      {deviceIdsWithCamEnabled.includes(device.id) && (
        <InfoWindow
          key={`InfoWindow-${device.id}`}
          onCloseClick={() => onToggleClose(device.id)}
          options={{ maxWidth: 500, maxHeigth: 500 }}
        >
          <Paper color="primary">
            {
              <Iframe
                url={deviceInfo.metadata}
                width="450px"
                height="450px"
                position="relative"
              />
            }
          </Paper>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default DeviceMarker;
