/**
 * Basic dialog that asks for confirmation before a submission.
 */

import React, { useState } from 'react'

import { Dialog, DialogTitle } from '@material-ui/core'

import useStyles from './styles'
import * as utils from '../../commons/utils'
import CustomDialogActions from './CustomDialogActions'
import CustomDialogContent from './CustomDialogContent'

export default function ConfirmDialog({
  title = 'title',
  modalOpen = false,
  content = [],

  confirmBackgroundColor = null,
  confirmText = 'Confirm',

  cancelColor = null,
  cancelText = 'Cancel',

  closeModal = () => { },
  handleSubmit: handleSubmitExternal = () => { },
  handleSubmitError: handleSubmitErrorExternal = () => { },
}) {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  const handleClose = () => {
    closeModal()
  }

  const handleSubmit = async () => {
    if (loading)
      return
      
    setLoading(true)
    try {
      await utils.callAsyncOrNot(handleSubmitExternal)
    }
    catch (error) {
      handleSubmitErrorExternal()
    }
    setLoading(false)
  }

  if (!Array.isArray(content))
    content = [content]

  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitle
        }}
      >
        {title}
      </DialogTitle>
      <CustomDialogContent
        content={content}
      />
      <CustomDialogActions
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        confirmBackgroundColor={confirmBackgroundColor}
        confirmText={confirmText}
        cancelColor={cancelColor}
        cancelText={cancelText}
        loading={loading}
      />
    </Dialog>
  )
}