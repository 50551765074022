import { mapFromAreaInformationType } from "./dynamic-drawer-content-type";

const getLocation = (point) => {
  const [lng, lat] = point.coordinates;

  return {
    lat,
    lng,
  };
};

const mapAreaInformationToDynamicDrawerContent = (
  area,
  areaInformationTypesIndexed
) => {
  return ({ informationTypeId, information }) => {
    const { type, copy } = mapFromAreaInformationType(informationTypeId);

    const metadata = {};

    if (type === "location" && area.point) {
      metadata.coordinate = getLocation(area.point);
    }

    return {
      label:
        areaInformationTypesIndexed[informationTypeId]?.informationType ?? "-",
      value: information,
      type,
      copy,
      metadata,
    };
  };
};

export const riskAreaToDynamicDrawer = (
  riskArea,
  riskZoneType,
  areaInformationTypesIndexed
) => {
  const contents = riskArea.areaInformation.map(
    mapAreaInformationToDynamicDrawerContent(
      riskArea,
      areaInformationTypesIndexed
    )
  );

  return {
    id: `risk-area-${riskArea.id}`,
    header: {
      iconUrl: riskZoneType.icon,
      title: riskArea.name,
    },
    contents,
  };
};

export const publicEntityToDynamicDrawer = (
  publicEntity,
  areaInformationTypesIndexed
) => {
  const contents = publicEntity.area.areaInformation.map(
    mapAreaInformationToDynamicDrawerContent(
      publicEntity.area,
      areaInformationTypesIndexed
    )
  );

  return {
    id: `public-entity-${publicEntity.id}`,
    header: {
      iconUrl: publicEntity.publicEntity.icon,
      title: publicEntity.area.name,
    },
    contents,
  };
};

export const POIToDynamicDrawer = (poi, areaInformationTypesIndexed) => {
  const contents = poi.area.areaInformation.map(
    mapAreaInformationToDynamicDrawerContent(
      poi.area,
      areaInformationTypesIndexed
    )
  );

  if (poi.metadata?.link) {
    contents.push({
      label: "",
      value: "",
      type: "live",
      metadata: {
        liveUrl: poi.metadata.link,
      },
    });
  }

  return {
    id: `poi-${poi.id}`,
    header: {
      iconUrl: poi.type.icon,
      title: poi.area.name,
    },
    contents,
  };
};

export const customerPOIToDynamicDrawer = (
  customerPoi,
  areaInformationTypesIndexed
) => {
  const contents = customerPoi.area.areaInformation.map(
    mapAreaInformationToDynamicDrawerContent(
      customerPoi.area,
      areaInformationTypesIndexed
    )
  );

  return {
    id: `customer-poi-${customerPoi.id}`,
    header: {
      iconUrl: customerPoi.type.icon,
      title: customerPoi.area.name,
    },
    contents,
  };
};
