import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  Polyline,
  Marker,
  useJsApiLoader,
  Polygon,
  InfoWindow,
} from "@react-google-maps/api";
import { useGoogleMapsKey } from "../../hooks/useGoogleMapsKey";
import { useRiskZonesPolygon } from "../../pages/Scripting/ScriptingMap/hooks/useRiskZonesPolygon";
import { isEmpty } from "lodash";

const libraries = ["geometry", "places", "visualization"];

const DEFAULT_OPTIONS = {
  zoomControl: true,
  fullscreenControl: false,
  streetViewControl: false,
  draggableCursor: null,
  disableDefaultUI: true,
};

const ReportMap = ({ markers, polylines, polygons, defaultCenter, onLoad }) => {
  const googleMapsKey = useGoogleMapsKey();
  const {
    handleMouseOver,
    handleMouseOut,
    infoWindow,
    handleCloseClick,
    handleMouseMove,
  } = useRiskZonesPolygon();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  useEffect(() => {
    if (isLoaded) {
      onLoad();
    }
  }, [isLoaded]);

  const makeMarker = ({ coordinate, label, icon, title, key }) => {
    if (
      typeof coordinate !== "object" ||
      coordinate === null ||
      coordinate.hasOwnProperty("lat") === false ||
      coordinate.hasOwnProperty("lng") === false
    ) {
      return;
    }

    return (
      <Marker
        key={key}
        title={title}
        position={coordinate}
        icon={icon}
        label={label}
      />
    );
  };

  const makePolyline = ({ coordinates, options, key }) => {
    return <Polyline key={key} path={coordinates} options={options} />;
  };

  const makePolygon = ({ key, path, options, area }) => {
    return (
      <Polygon
        key={key}
        path={path}
        options={options}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseMove={(event) => handleMouseMove(event, area)}
      />
    );
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
      zoom={12}
      center={defaultCenter}
      options={DEFAULT_OPTIONS}
    >
      {markers && markers.length > 0 && markers.map(makeMarker)}

      {polylines && polylines.length > 0 && polylines.map(makePolyline)}

      {polygons && polygons.length > 0 && polygons.map(makePolygon)}

      {!isEmpty(infoWindow) && (
        <InfoWindow
          onCloseClick={handleCloseClick}
          position={{
            lat: Number(infoWindow.lat),
            lng: Number(infoWindow.lng),
          }}
        >
          <div>{infoWindow.body}</div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

ReportMap.propTypes = {
  markers: PropTypes.array,
  polylines: PropTypes.array,
  polygons: PropTypes.array,
  defaultCenter: PropTypes.object,
};

export default ReportMap;
