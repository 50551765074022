import React from 'react'
import { useSelector } from 'react-redux'
import * as dateFns from 'date-fns'
import { toast } from 'material-react-toastify'

import * as exceptions from '../../../commons/exceptions'
import * as transferApiService from '../../../infra/http/transferApiService'
import DeleteDialog from '../../../components/DeleteDialog'

const ModalRemoveTransfer = ({
  transferData,
  onSuccess = () => {}
}) => {
  const { customer: customerId } = useSelector(state => state.auth.customer)

  const hasTransferEverStarted = (transferData.start_date !== null)
  const canDeleteTransfer = !hasTransferEverStarted

  const relevantTransferData = formatRelevantTransferData(transferData)

  const title = (
    <span>Transfers | <strong>Remover</strong> | {transferData.name}</span>
  )

  const handleRemoveTransfer = async () => {
    try {
      await transferApiService.removeTransfers([transferData.id], customerId)
      toast.success(`Transfer #${transferData.id} removido com sucesso.`)
      onSuccess()
    }
    catch (error) {
      if (!transferData?.id)
        toast.error('Não foi possível remover transfer.')
      else
        toast.error(`Não foi possível remover transfer #${transferData.id}.`)

      throw exceptions.createException({ code: 'failed-to-remove-transfer' })
    }
  }

  return (
    <DeleteDialog
      buttonTooltip='Remover Transfer'
      cancelText='Cancelar'
      confirmText='Remover'

      title={title}
      content={relevantTransferData}
      disabled={!canDeleteTransfer}
      
      deleteHandler={handleRemoveTransfer}
    />
  )
}

const formatRelevantTransferData = (transferData) => {
  const createdAtDate = new Date(transferData.created_at)
  const formattedDate = dateFns.isValid(createdAtDate)
    ? dateFns.format(createdAtDate, 'dd/MM/yyyy HH:mm:ss')
    : '-'

  return [
    {
      title: 'ID',
      text: `#${transferData.id}`,
    },
    {
      title: 'Evento',
      text: transferData.name || '-',
    },
    {
      title: 'Unidade de Negócios',
      text: transferData.unity || '-',
    },
    {
      title: 'Responsável',
      text: transferData.responsible || '-',
    },
    {
      title: 'Data de Criação',
      text: formattedDate,
    },
    {
      title: 'Local Partida',
      text: transferData.from || '-',
    },
    {
      title: 'Local Destino',
      text: transferData.to || '-',
    },
  ]
}

export default ModalRemoveTransfer