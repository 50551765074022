import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    zIndex: 1,
  },
  box: {
    height: "33vh",
    overflow: "auto",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  iconButton: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    marginLeft: 10,
  },
  iconButtonAlert: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    marginLeft: 13,
    width: 25,
  },
  status: {
    paddingLeft: 13,
  },
  progress: {
    display: "flex",
    flexDirection: "row",
    minWidth: 235,
    width: "100%",
  },
  iconCheckAlert: {
    marginLeft: 13,
    color: "green",
  },
  iconStop: {
    marginLeft: 10,
  },
  iconArrow: {
    backgroundColor: "#F7F7F7",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #F1F1F1",
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 50,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  collapse: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F7F7F7",
    boxShadow: "0px 3px 6px #00000029",
    margin: 10,
    borderRadius: 10,
    padding: 10,
    border: "1px solid #F1F1F1",
    opacity: 1,
  },
  collapseHeader: {
    display: "flex",
    flexDirection: "row",
  },
  collapseHeaderItem: {
    flex: 1,
    border: "1px solid #00000029",
    padding: "5px 10px 5px 10px",
    margin: 5,
    borderRadius: 10,
  },
}));

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F7F7F7",
    boxShadow: "0px 3px 6px #00000029",
    padding: "0 24px 0 24px",
    height: "7vh",
  },
  filter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  pois: {
    display: "flex",
    flexDirection: "row",
  },
  maxMin: {
    display: "flex",
  },
  return: {
    marginRight: 10,
    color: "#06B0CF",
    fontSize: 18,
    borderRadius: 8,
  },
  iconButton: {
    marginLeft: 10,
  },
  Numbericon: {
    color: "#ff0000",
  },
  button: {
    marginLeft: 10,
  },
  info: {
    flex: 1.25,
    display: "flex",
    flexDirection: "row",
  },
  alerts: {
    size: 14,
    borderLeft: "1px solid #00000029",
    padding: "0 10px 0 10px",
  },
  events: {
    size: 14,
    borderLeft: "1px solid #00000029",
    padding: "0 10px 0 10px",
  },
  active: {
    size: 14,
    borderLeft: "1px solid #00000029",
    borderRight: "1px solid #00000029",
    padding: "0 10px 0 10px",
  },
  switch: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 10px 0 10px",
  },
  switchLabel: {
    size: 14,
    paddingRight: 10,
  },
}));
