import React, { memo } from "react";
import CustomerInterestPoints from "./customerInterestPoints";
import { Fragment } from "react";
import { areEqualArray } from "../../../commons/utils";

const CustomerPOIS = ({ customerPointsOfInterest }) => {
  const customerPoints = customerPointsOfInterest || [];
  return customerPoints.map((point) => (
    <Fragment key={`customerPOI-${point.id}`}>
      <CustomerInterestPoints customerPointOfInterest={point} />
    </Fragment>
  ));
};

export default memo(CustomerPOIS, (prevProps, nextProps) => {
  const prevIds = prevProps.customerPointsOfInterest.map(({ id }) => id);
  const nextIds = nextProps.customerPointsOfInterest.map(({ id }) => id);
  return areEqualArray(prevIds, nextIds);
});
