import { fromAreaApi } from "./area.mapper";

export const fromPOIApi = (data) => {
  return {
    id: data.id,
    areaId: data.areaId,
    area: fromAreaApi(data.area),
    metadata: data.metadata,
    type: data.type,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    deletedAt: data.deletedAt,
  };
};
