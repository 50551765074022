import React, { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useGoogleMapsKey } from "../../../hooks/useGoogleMapsKey";

import { Typography } from "@mui/material";
import {
  NotificationImportantOutlined as NotificationImportantOutlinedIcon,
  FeedOutlined as FeedOutlinedIcon,
  FmdBadOutlined as FmdBadOutlinedIcon,
  LayersOutlined as LayersOutlinedIcon,
  PrintOutlined as PrintOutlinedIcon,
} from "@mui/icons-material";
import {
  CardClippings,
  CardFloat,
  CardIncidents,
  CardRiskArea,
  ContainerHeader,
  ContainerMapHeader,
  DivOrganizeHeader,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { mapsOptions } from "./scriptingMapOptions";
import ModalFloatCamada from "./ModalFloatCamadas";
import { Directions } from "./Direction";
import { RiskZonesPolygon } from "../../Scripting/ScriptingMap/RiskZonesPolygon";
import { SecurityAlerts } from "../../Scripting/ScriptingMap/SecurityAlerts";
import { RightClickInfoWindow } from "../../Scripting/ScriptingMap/RightClickInfoWindow";
import { useMapClick } from "../../Scripting/ScriptingMap/hooks/useMapClick";
import {
  getCustomerPointsOfInterest,
  getCustomerPointsOfInterestTypes,
} from "../../../services/areasService";
import {
  addCustomerPointsOfInterest,
  addCustomerPointsOfInterestTypes,
} from "../../../store/ducks/areas";
import CustomerInterestPoints from "../../MonitoringMap/CustomerInterestPoints/customerInterestPoints";
import { POIs } from "../../../components/Map/POIs";
import { useClippingOccurrences } from "./Occurrence";
import * as occurrencesService from "../../../services/occurrences";

const libraries = ["places", "drawing", "geometry"];

export const ScriptingMap = () => {
  const customerId = useSelector((store) => store.auth.customer.customer);
  const directions = useSelector((state) => state.scripting.directions);
  const poiTypesSelected = useSelector(
    (state) => state.scripting.poiTypesSelected
  );
  const currentTransferEditableInfo = useSelector(
    (state) => state.scripting.currentTransferEditableInfo
  );
  const riskLayers = useSelector((state) => state.scripting.riskLayers);
  const activeRiskLayers = useSelector(
    (store) => store.scripting.activeRiskLayers
  );
  const securityAlerts = useSelector((state) => state.scripting.securityAlerts);
  const incidentsNatures = useSelector(
    (state) => state.scripting.incidentsNatures
  );
  const dispatch = useDispatch();
  const { renderOccurrences } = useClippingOccurrences();
  const mapType = useSelector((store) => store.monitoringMap.mapType);
  const customerPointOfInterest = useSelector(
    (store) => store.areas.customerPointsOfInterest
  );
  const customerPointOfInterestTypes = useSelector(
    (store) => store.areas.customerPointOfInterestTypes
  );
  const mapClickAddStopOrDetour = useSelector(
    (store) => store.scripting.mapClickAddStopOrDetour
  );
  const countClippingSecurity = useSelector(
    (store) => store.scripting.countClippingSecurity
  );
  const currentTransfer = useSelector(
    (state) => state.transfer.currentTransfer
  );
  const occurrencesCategories = useSelector(
    (state) => state.scripting.occurrencesCategories
  );

  const [isOpen, setIsOpen] = useState(false);
  const [fiteredSecurityAlerts, setFilteredSecurityAlerts] = useState([]);
  const [clippingsAccount, setClippingsAccount] = useState(0);
  const [registeredOccurrence, setRegisteredOccurrence] = useState([]);
  const incidentsAccount = fiteredSecurityAlerts.length;
  const riskAreaAccount = riskLayers.filter((item) =>
    activeRiskLayers.includes(item.riskZone.typeId)
  ).length;
  const {
    currentInfoWindow,
    setCurrentInfoWindow,
    handleMapClick,
    handleMapRightClick,
  } = useMapClick();

  const draggableCursor =
    mapClickAddStopOrDetour !== "" ? "crosshair" : "pointer";

  const options = { ...mapsOptions(mapType), draggableCursor };

  const googleMapsKey = useGoogleMapsKey();
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  const countRegisteredOccurrence = () => {
    const selectedTypesIds = occurrencesCategories
      ?.filter((category) => category.selected === true)
      ?.flatMap((category) => category.types.map((type) => type.id));

    const filteredOccurrences = registeredOccurrence?.filter((occ) =>
      selectedTypesIds?.includes(occ.clippingTypeId)
    );

    setClippingsAccount(filteredOccurrences?.length);
    return filteredOccurrences;
  };

  useEffect(() => {
    const alertsArray = [];

    const securityAlertsObj = {};
    securityAlerts.forEach((alert) => {
      securityAlertsObj[alert.type.categoryId] = alert;
    });

    incidentsNatures
      .filter(({ id }) => securityAlertsObj[id])
      .forEach((category) => {
        if (category) {
          alertsArray.push(alert);
        } else {
          category.types.forEach((type) => {
            if (type.id === alert.type.externalId && type.selected) {
              alertsArray.push(alert);
            }
          });
        }
      });

    countRegisteredOccurrence();
    setFilteredSecurityAlerts(alertsArray);
  }, [
    securityAlerts,
    incidentsNatures,
    directions,
    occurrencesCategories,
    registeredOccurrence,
  ]);

  useEffect(() => {
    const searchCustomerPointsOfInterest = async () => {
      const customerPointsOfInterest = await getCustomerPointsOfInterest(
        customerId
      );
      dispatch(addCustomerPointsOfInterest(customerPointsOfInterest));
    };
    const searchCustomerPointsOfInterestTypes = async () => {
      const customerPointsOfInterestTypes =
        await getCustomerPointsOfInterestTypes();
      dispatch(addCustomerPointsOfInterestTypes(customerPointsOfInterestTypes));
    };

    const countClippingOccurrence = async () => {
      const registeredOccurrences = await occurrencesService.getOccurrences({
        after_date: "2023-07-13",
        from: currentTransfer?.from,
        to: currentTransfer?.to,
        radius: 500,
        linestring: directions?.routes[0].overview_polyline,
      });

      setRegisteredOccurrence(registeredOccurrences);
    };

    countClippingOccurrence();
    searchCustomerPointsOfInterestTypes();
    searchCustomerPointsOfInterest();
  }, [dispatch, directions, occurrencesCategories, registeredOccurrence]);

  if (!isLoaded) {
    return <></>;
  }

  return (
    <ContainerMapHeader>
      <ContainerHeader>
        <DivOrganizeHeader elevation={3}>
          <CardIncidents>
            <NotificationImportantOutlinedIcon />
            {incidentsAccount}
            <Typography style={{ fontSize: "15px", fontWeight: 400 }}>
              Incidentes
            </Typography>
          </CardIncidents>
          <CardClippings>
            <FeedOutlinedIcon />
            {clippingsAccount}
            <Typography style={{ fontSize: "15px", fontWeight: 400 }}>
              Clippings de segurança
            </Typography>
          </CardClippings>
          <CardRiskArea>
            <FmdBadOutlinedIcon />
            {riskAreaAccount}
            <Typography style={{ fontSize: "15px", fontWeight: 400 }}>
              Áreas de risco
            </Typography>
          </CardRiskArea>
        </DivOrganizeHeader>
        <div>
          <CardFloat
            id="camadas"
            onClick={() => setIsOpen((previous) => !previous)}
          >
            <LayersOutlinedIcon style={{ width: "1.8rem", height: "1.8rem" }} />
          </CardFloat>
          {isOpen && <ModalFloatCamada isOpen={isOpen} setIsOpen={setIsOpen} />}
        </div>
        <CardFloat id="export-pdf">
          <PrintOutlinedIcon style={{ width: "1.8rem", height: "1.8rem" }} />
        </CardFloat>
      </ContainerHeader>

      <GoogleMap
        id="scripting-map"
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        onLoad={null}
        center={{ lat: -22.9122724, lng: -43.1770578 }}
        zoom={12}
        options={options}
      >
        <RiskZonesPolygon />
        <SecurityAlerts />
        {renderOccurrences()}
        <Directions />

        <RightClickInfoWindow
          currentInfoWindow={currentInfoWindow}
          setCurrentInfoWindow={setCurrentInfoWindow}
        />

        {poiTypesSelected.length > 0 && (
          <POIs
            POITypes={poiTypesSelected}
            radius={currentTransferEditableInfo.radius || 500}
            linestring={directions?.routes[0]?.overview_polyline}
          />
        )}

        {customerPointOfInterest.map((point) =>
          customerPointOfInterestTypes.map(
            (type) =>
              type.selected &&
              type.id == point.type.id && (
                <CustomerInterestPoints customerPointOfInterest={point} />
              )
          )
        )}
      </GoogleMap>
    </ContainerMapHeader>
  );
};
