import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function SwitchesSize() {
  const [checked, setChecked] = useState(true);

  const dispatch = useDispatch();

  function addSeeInactiveAction(seeInactive) {
    return { type: 'ADD_SEE_INACTIVE', seeInactive }
  }

  const addSeeInactive = prev => {
    setChecked(prev => !prev)
    dispatch(addSeeInactiveAction( !checked ))
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch size="small" checked={checked} onChange={addSeeInactive}
        color="primary" />}
        label="Ver Inativos"
      />
    </FormGroup>
  );
}
