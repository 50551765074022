import React, { useState } from "react";
import {
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  CloseOutlined as CloseOutlinedIcon,
  AddOutlined as AddOutlinedIcon,
} from "@mui/icons-material";
import FormRegister from "./formRegisterNew";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

function getSteps() {
  return ["Cadastro", "Roteirização", "Vinculação"];
}

export default function CustomizedDialogs() {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const steps = getSteps();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div id="modal-screen">
      <Button
        style={{
          width: 188,
          height: 44,
          backgroundColor: "#3AC4E0",
          borderRadius: 5,
          opacity: 1,
        }}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<AddOutlinedIcon />}
      >
        Criar Transfer
      </Button>
      <Dialog
        style={{ zIndex: 2 }}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <header
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ fontWeight: 700, paddingBottom: 0 }}
          >
            Cadastrar Transfer
          </DialogTitle>
          <IconButton size="small" aria-label="close" onClick={handleClose}>
            <CloseOutlinedIcon style={{ color: "#06B0CF", marginRight: 10 }} />
          </IconButton>
        </header>
        <DialogContent style={{ padding: "0px 10px 10px 10px" }}>
          <div id="stepper">
            <Divider style={{ marginTop: 0 }} />
            <Stepper
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5px",
                marginBottom: "5px",
              }}
              activeStep={activeStep}
              connector={
                <KeyboardArrowRightOutlinedIcon style={{ color: "#C2C2C2" }} />
              }
            >
              {steps.map((label, index) => (
                <Step style={{ color: "#2D9DB2" }} key={label}>
                  <StepLabel completed={completed[index]}>
                    <Typography style={{ fontWeight: 700 }}>{label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Divider />
            <br />
          </div>

          <div id="Redux-Forms">
            <FormRegister />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
