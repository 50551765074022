import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import styles from './styles';

const ReportTextField = ({ name, type, value, onChange, onBlur, placeholder, classes, disable }) => {
  return (
    <TextField
      disabled={disable}
      fullWidth
      variant="outlined"
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      classes={{
        root: classes.root,
      }}
      InputProps={{
        endAdornment: type === 'text' ? (<SearchIcon className={classes.adornmentIcon} />) : '',
        classes: {
          input: classes.customInputStyles,
          notchedOutline: classes.customNotchedOutlineStyles
        }
      }}
    />
  )
}



export default withStyles(styles)(ReportTextField);
