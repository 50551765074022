export const capitalizeFirstLetter = (text) => {
  if (text.length === 0) return "";

  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const secondsToText = (seconds) => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds - days * 86400) / 3600);
  const minutes = Math.ceil((seconds - days * 86400 - hours * 3600) / 60);

  let text = "";

  if (days > 0) {
    const plural = days > 1;
    text += `${days} dia${plural ? "s" : ""}`;
  }

  if (hours > 0) {
    const plural = hours > 1;
    text += ` ${hours} hora${plural ? "s" : ""}`;
  }

  if (minutes > 0) {
    if (text.length > 0) {
      text += " e ";
    }

    const plural = minutes > 1;
    text += `${minutes} minuto${plural ? "s" : ""}`;
  }

  return text;
};

export const letters = "abcdefghijklmnopqrstuvwxyz".split("");
