import devices from "./devices.json";

export const Types = {
  SEARCH_BY_ALIAS: "SEARCH_BY_ALIAS",
  ADD_SEE_INACTIVE: "ADD_SEE_INACTIVE",
};

const INITIAL_VALUES = {
  showModalVoice: false,
  voices: [],
  devices: devices,
  identifierSearch: "",
  seeInactive: true,
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.SEARCH_BY_ALIAS:
      return { ...state, identifierSearch: action.aliasSearch };

    case Types.ADD_SEE_INACTIVE:
      return { ...state, seeInactive: action.seeInactive };

    default:
      return state;
  }
};

export const searchByAliasAction = (aliasSearch) => {
  return { type: "SEARCH_BY_ALIAS", aliasSearch };
};
