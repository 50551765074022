import React, { useEffect } from "react";

import Table from "../../components/TableTransfer";
import Filter from "../../components/Filter/filter";
import Sidebar from "../../components/Sidebar/sidebar";
import { Div, Container } from "./styles";
import { useDispatch } from "react-redux";
import { clearScriptingData } from "../../store/ducks/scripting";

export default function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearScriptingData());
  }, []);

  return (
    <Div>
      <Sidebar />
      <Container>
        <h1>Transfers</h1>
        <Filter />
        <Table />
      </Container>
    </Div>
  );
}
