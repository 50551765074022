/**
 * Module responsible for providing custom logs to the application.
 */

import { formatDateTimeISO } from "./date";

class Logger {
  constructor(...loggerNames) {
    this.loggerNames = loggerNames;
  }

  createLogger(newLoggerName) {
    return new Logger(...this.loggerNames, newLoggerName);
  }

  log(...messages) {
    if (messages.length === 0) return;

    this._baseLog(console.log, "log", ...messages);
  }

  error(...messages) {
    if (messages.length === 0) return;

    this._baseLog(console.error, "error", ...messages);
  }

  warn(...messages) {
    if (messages.length === 0) return;

    this._baseLog(console.warn, "warn", ...messages);
  }

  _baseLog(logCallback, type, ...messages) {
    if (messages.length === 0) return;

    const formattedLoggerType = `[${type.toUpperCase()}]`;
    const formattedLoggerNames = this.loggerNames.map(
      (loggerName) => `[${loggerName}]`
    );
    const currentDate = formatDateTimeISO();
    logCallback(
      `[${currentDate}]`,
      formattedLoggerType,
      ...formattedLoggerNames,
      ...messages
    );
  }

  static createInitialLogger(loggerName) {
    return new Logger(loggerName);
  }
}

export default Logger;
