import React from 'react'
import { CircularProgress } from "@material-ui/core"

import colors from '../../commons/colors'
import styles from './styles'

const Loading = ({
  color = colors.blue,
  size = 68,
  text = null,
}) => {
  const textSpan = text
    ? <span>{text}</span>
    : null

  return (
    <div style={styles.loading}>
      <div>
        <CircularProgress
          size={size}
          style={{
            ...styles.loadingImage,
            color,
          }}
        />
      </div>
      {textSpan}
    </div>
  )
}

export default Loading