import { ListItem } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/styles";
import styles from "./styles";
import moment from "moment";

const TransferEventItem = ({
  index,
  transferEvent,
  handleClickSidebarAlert,
  classes,
}) => {
  const renderAlertOrEvent = () => {
    if (transferEvent?.eventTypeId) {
      return (
        <span>
          <strong>
            {index + 1} - {transferEvent?.eventType?.name}
          </strong>
          <br></br>
          <span style={{ marginBottom: 2 }}>
            {transferEvent?.startedAt !== undefined
              ? moment
                  .utc(transferEvent?.startedAt)
                  .local()
                  .subtract(3, "hours")
                  .format("DD/MM/YYYY [às]  HH:mm:ss")
              : ""}
          </span>
          <br></br>
          <span style={{ color: "red" }}>
            {transferEvent?.treatment !== null
              ? `Tratamento: ${transferEvent?.treatment?.justification}`
              : "Ocorrência não tratada"}
          </span>
        </span>
      );
    }
  };

  return (
    <ListItem
      role="listitem"
      className={classes.alertItem}
      onClick={() => handleClickSidebarAlert(transferEvent)}
    >
      {renderAlertOrEvent()}
    </ListItem>
  );
};

export default withStyles(styles)(TransferEventItem);
