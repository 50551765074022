import utransferV2API from "../utransferV2";

export const handleGetPassenger = async (page, perPage) => {
    const response = await utransferV2API.get(`/passengers`, {
      params: { page, perPage },
    });
    return response.data;
};

export const handlePostPassenger = async (data) => {
    const response = await utransferV2API.post(`/passengers`, data);
    return response.data;
};

export const handleDeletePassenger = async (id) => {
    await utransferV2API.delete(`/passengers/${id}`);
};

export const handleUpdatePassenger = async (id, data) => {
    const response = await utransferV2API.put(`/passengers/${id}`, data);
    return response.data;
};
