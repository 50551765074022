import React, { useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Switch, Button, IconButton } from "@material-ui/core/";

import FilterMonitoring from "../../FilterMonitoringMap/searchFilterMonitoring";
import { useToolbarStyles } from "./styles";
import {
  clearAlertSound,
  openMapStyleDrawerAction,
  showActiveIncidents,
} from "../../../store/ducks/monitoringMap";
import { maxOpenMapMonitoringAction } from "../../../store/ducks/monitoringMap";

export const TableToolbar = (props) => {
  const [switchState, setSwitchState] = useState(false);
  const [trafficLayer, setTrafficLayer] = useState(false);
  const maxOpenMapMonitoring = useSelector(
    (state) => state.monitoringMap.maxOpenMapMonitoring
  );
  const activeIncidentsVisible = useSelector(
    (state) => state.monitoringMap.activeIncidentsVisible
  );

  const classes = useToolbarStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  function addPOIsAction(POIs) {
    return { type: "ADD_POIS", POIs };
  }

  const addPOIs = (POIs) => {
    dispatch(addPOIsAction(POIs));
  };

  function addTrafficLayerAction(trafficLayer) {
    return { type: "ADD_TRAFFIC_LAYER", trafficLayer };
  }

  const addTrafficLayer = (trafficLayer) => {
    dispatch(addTrafficLayerAction(trafficLayer));
  };

  const handleOpenMapType = () => {
    dispatch(openMapStyleDrawerAction());
  };

  const handleMaximizeMap = () => {
    dispatch(maxOpenMapMonitoringAction());
  };

  const handleChangePOIs = (event) => {
    setSwitchState(event.target.checked);
    addPOIs(!switchState === true ? "on" : "off");
  };

  const handleChangeTraficLayer = (event) => {
    setTrafficLayer(event.target.checked);
    addTrafficLayer(event.target.checked);
  };

  const toggleActiveIncidentsVisibility = (e) => {
    dispatch(showActiveIncidents(e.target.checked));
  };

  const handleReturn = () => {
    dispatch(clearAlertSound());
    history.push("/monitoring");
  };

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.filter}>
        <IconButton
          onClick={handleReturn}
          size="small"
          className={classes.return}
        >
          <ArrowBackIosIcon />
          {"Selecionar Dispositivos"}
        </IconButton>

        <FilterMonitoring />
      </div>

      <div className={classes.maxMin}>
        <IconButton
          onClick={handleMaximizeMap}
          size="small"
          className={classes.return}
        >
          {!maxOpenMapMonitoring ? (
            <ArrowDropDownIcon style={{ fontSize: 60 }} />
          ) : (
            <ArrowDropUpIcon style={{ fontSize: 60 }} />
          )}
        </IconButton>
      </div>
      <div className={classes.pois}>
        <FormControlLabel
          className="switchers"
          control={
            <Switch
              size="small"
              checked={activeIncidentsVisible}
              color="primary"
              onChange={toggleActiveIncidentsVisibility}
            />
          }
          label="Exibição incidentes"
        />
        <FormControlLabel
          className="switchers"
          control={
            <Switch
              size="small"
              checked={switchState}
              color="primary"
              onChange={handleChangePOIs}
            />
          }
          label="Pontos de Interesse"
        />
        <FormControlLabel
          className="switchers"
          control={
            <Switch
              size="small"
              checked={trafficLayer}
              color="primary"
              onChange={handleChangeTraficLayer}
            />
          }
          label="Trânsito"
        />
        <Button variant="outlined" size="small" onClick={handleOpenMapType}>
          Alterar mapa
        </Button>
      </div>
    </div>
  );
};
