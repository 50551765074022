import storage from "redux-persist/lib/storage";

export const types = {
  ADD_IDENTIFIER_SEARCH: "ADD_IDENTIFIER_SEARCH",
  ADD_ZOOM_IN: "ADD_ZOOM_IN",
  ADD_CENTER_MAP: "ADD_CENTER_MAP",
  ADD_VISIBILITY_ROUTE: "ADD_VISIBILITY_ROUTE",
  RMV_VISIBILITY_ROUTE: "RMV_VISIBILITY_ROUTE",
  CHANGE_DEVICES_VIRTUAL_FENCE_VISIBILITY:
    "CHANGE_DEVICES_VIRTUAL_FENCE_VISIBILITY",
  ADD_VISIBILITY_SNACKBAR: "ADD_VISIBILITY_SNACKBAR",
  ADD_MESSAGES_FLESPI: "ADD_MESSAGES_FLESPI",
  ADD_ALERTS_FLESPI: "ADD_ALERTS_FLESPI",
  OPEN_ALERTS_SIDEBAR: "OPEN_ALERTS_SIDEBAR",
  IDENTIFIER_SEARCH_SIDEBAR: "IDENTIFIER_SEARCH_SIDEBAR",
  IDENTIFIER_SEARCH_NOTIFICATION_SIDEBAR:
    "IDENTIFIER_SEARCH_NOTIFICATION_SIDEBAR",
  ADD_TRANSFER_ACTIVE: "ADD_TRANSFER_ACTIVE",
  RMV_TRANSFER_ACTIVE: "RMV_TRANSFER_ACTIVE",
  ADD_DEVICES: "ADD_DEVICES",
  ADD_POINTS_DEVICES_ACTIVES: "ADD_POINTS_DEVICES_ACTIVES",
  ADD_IDS_DEVICES_ACTIVES: "ADD_IDS_DEVICES_ACTIVES",
  ADD_POIS: "ADD_POIS",
  ADD_TRAFFIC_LAYER: "ADD_TRAFFIC_LAYER",
  ADD_EVENT_DETAILS: "ADD_EVENT_DETAILS",
  ADD_DEVICE_ID: "ADD_DEVICE_ID",
  ADD_ALERTS: "ADD_ALERTS",
  ADD_RENDER: "ADD_RENDER",
  ADD_DEVICES_IDS_SELECTED: "ADD_DEVICES_IDS_SELECTED",
  ADD_STEP_ROUTE: "ADD_STEP_ROUTE",
  ADD_STOP_POINT: "ADD_STOP_POINT",
  ADD_TESTE: "ADD_TESTE",
  ADD_IDS_SELECTED: "ADD_IDS_SELECTED",
  ADD_VALIDATE_ALERT_PIN: "ADD_VALIDATE_ALERT_PIN",
  ADD_ALERT_TEXT: "ADD_ALERT_TEXT",
  OPEN_MAP_STYLE_DRAWER: "OPEN_MAP_STYLE_DRAWER",
  MAX_OPEN_MAP_MONITORING: "MAX_OPEN_MAP_MONITORING",
  CHANGE_MAP_STYLES: "CHANGE_MAP_STYLES",
  CHANGE_SHOW_MODAL_VOICE: "CHANGE_SHOW_MODAL_VOICE",
  CHANGE_SHOW_VOICE_SIDEBAR: "CHANGE_SHOW_VOICE_SIDEBAR",
  ADD_LATEST_POSITIONS: "ADD_LATEST_POSITIONS",
  OPEN_VEHICLE_MODAL: "OPEN_VEHICLE_MODAL",
  ADD_DEVICE_INFO_MODAL: "ADD_DEVICE_INFO_MODAL",
  OPEN_MODAL_INCIDENTS_ACTIVE: "OPEN_MODAL_INCIDENTS_ACTIVE",
  ADD_INCIDENTS_ACTIVE_ID: "ADD_INCIDENTS_ACTIVE_ID",
  OPEN_REASONING_TEXT: "OPEN_REASONING_TEXT",
  ADD_INCIDENT_MARKER: "ADD_INCIDENT_MARKER",
  ADD_TRANSFER_ID_TREATMENT: "ADD_TRANSFER_ID_TREATMENT",
  ADD_MESSAGES_AREAS: "ADD_MESSAGES_AREAS",
  OPEN_VEHICLE_CAM: "OPEN_VECHICLE_CAM",
  ADD_DISABLED_DETAILS_BUTTONS: "ADD_DISABLED_DETAILS_BUTTONS",
  SHOW_ACTIVE_INCIDENTS: "SHOW_ACTIVE_INCIDENTS",
  TOGGLE_INCIDENT_ID_NOT_DISPLAY_POLYGON:
    "TOGGLE_INCIDENT_ID_NOT_DISPLAY_POLYGON",
  ADD_ALERTS_SOUND: "ADD_ALERTS_SOUND",
  RMV_ALERTS_SOUND: "RMV_ALERTS_SOUND",
  CLEAR_ALERTS_SOUND: "CLEAR_ALERTS_SOUND",
  TOGGLE_DYNAMIC_DRAWER: "TOGGLE_DYNAMIC_DRAWER",
  CLOSE_DYNAMIC_DRAWER: "CLOSE_DYNAMIC_DRAWER",
};

const INITIAL_VALUES = {
  alertsFlespi: [],
  alertText: "",
  allAlerts: [],
  centerMap: {
    lat: -22.7945729,
    lng: -43.2988361,
  },
  devices: [],
  devicesIdsSelected: [],
  deviceIdsWithCamEnabled: [],
  deviceInfoModalVehicle: {},
  device_id: 0,
  disabledDetailsButtons: [],
  eventDetails: {},
  identifierSearch: "",
  identifierSearchSidebar: "",
  identifierSearchNotificationSidebar: "",
  idsDevicesActives: [],
  incidentMarker: [],
  incidentsActiveId: 0,
  lastPositions: [],
  mapType: "roadmap",
  maxOpenMapMonitoring: false,
  messagesAreas: [],
  messagesFlespi: {},
  openAlertsSidebar: false,
  openMapStyleDrawer: false,
  pointsDevicesActives: [],
  POIs: "off",
  render: false,
  showModalVoice: false,
  showModalIncidentsActive: false,
  showVehicleModal: false,
  showVoiceSidebar: false,
  showReasoningText: false,
  stepRoute: "",
  stopPoint: [],
  trafficLayer: false,
  transferActive: [],
  transferIdTreatment: 0,
  validateAlertPin: false,
  virtualFenceVisibility: [],
  visibilitySnackbar: false,
  visibilityRoute: [],
  zoom: 10,
  activeIncidentsVisible: true,
  incidentIdsNotDisplayPolygon: [],
  alertsSound: [],
  dynamicDrawer: null,
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.ADD_IDENTIFIER_SEARCH:
      return { ...state, identifierSearch: action.identifierSearch };
    case types.IDENTIFIER_SEARCH_SIDEBAR:
      return {
        ...state,
        identifierSearchSidebar: action.identifierSearchSidebar,
      };
    case types.IDENTIFIER_SEARCH_NOTIFICATION_SIDEBAR:
      return {
        ...state,
        identifierSearchNotificationSidebar:
          action.identifierSearchNotificationSidebar,
      };
    case types.ADD_EVENT_DETAILS:
      return { ...state, eventDetails: action.eventDetails };
    case types.ADD_ZOOM_IN:
      return { ...state, zoom: action.zoom };
    case types.ADD_POIS:
      return { ...state, POIs: action.POIs };
    case types.ADD_TRAFFIC_LAYER:
      return { ...state, trafficLayer: action.trafficLayer };
    case types.ADD_CENTER_MAP:
      return { ...state, centerMap: action.centerMap };
    case types.ADD_DEVICES:
      return { ...state, devices: action.devices };
    case types.ADD_VISIBILITY_ROUTE:
      return {
        ...state,
        visibilityRoute: [...state.visibilityRoute, action.visibilityRoute],
      };
    case types.RMV_VISIBILITY_ROUTE:
      return { ...state, visibilityRoute: action.visibilityRoute };
    case types.CHANGE_DEVICES_VIRTUAL_FENCE_VISIBILITY:
      return { ...state, virtualFenceVisibility: [...action.devices] };
    case types.ADD_VISIBILITY_SNACKBAR:
      return { ...state, visibilitySnackbar: action.visibilitySnackbar };
    case types.ADD_MESSAGES_FLESPI:
      return {
        ...state,
        messagesFlespi: state.messagesFlespi[action.newMessages.device_id]
          ? {
              ...state.messagesFlespi,
              [action.newMessages.device_id]: [
                ...state.messagesFlespi[action.newMessages.device_id],
                action.newMessages,
              ].sort((a, b) => {
                return new Date(a.taken_at) - new Date(b.taken_at);
              }),
            }
          : {
              ...state.messagesFlespi,
              [action.newMessages.device_id]: [action.newMessages],
            },
      };
    case types.ADD_ALERTS_FLESPI:
      return { ...state, alertsFlespi: action.alertsFlespi };
    case types.OPEN_ALERTS_SIDEBAR:
      return { ...state, openAlertsSidebar: action.openAlertsSidebar };
    case types.ADD_TRANSFER_ACTIVE:
      return {
        ...state,
        transferActive: [...state.transferActive, action.transferActive],
      };
    case types.RMV_TRANSFER_ACTIVE:
      return { ...state, transferActive: action.transferActive };
    case types.ADD_POINTS_DEVICES_ACTIVES:
      return { ...state, pointsDevicesActives: action.pointsDevicesActives };
    case types.ADD_IDS_DEVICES_ACTIVES:
      return { ...state, idsDevicesActives: action.idsDevicesActives };
    case types.ADD_DEVICE_ID:
      return { ...state, device_id: action.device_id };
    case types.ADD_ALERTS:
      return { ...state, allAlerts: action.allAlerts };
    case types.ADD_RENDER:
      return { ...state, render: action.render };
    case types.ADD_DEVICES_IDS_SELECTED:
      return { ...state, devicesIdsSelected: action.devicesIdsSelected };
    case types.ADD_STEP_ROUTE:
      return { ...state, stepRoute: action.stepRoute };
    case types.ADD_STOP_POINT:
      return { ...state, stopPoint: action.stopPoint };
    case types.ADD_VALIDATE_ALERT_PIN:
      return { ...state, validateAlertPin: action.validateAlertPin };
    case types.ADD_ALERT_TEXT:
      return { ...state, alertText: action.alertText };
    case types.OPEN_MAP_STYLE_DRAWER:
      return { ...state, openMapStyleDrawer: !state.openMapStyleDrawer };
    case types.MAX_OPEN_MAP_MONITORING:
      return { ...state, maxOpenMapMonitoring: !state.maxOpenMapMonitoring };
    case types.CHANGE_MAP_STYLES:
      return { ...state, mapType: action.mapType };
    case types.CHANGE_SHOW_MODAL_VOICE:
      return { ...state, showModalVoice: !state.showModalVoice };
    case types.CHANGE_SHOW_VOICE_SIDEBAR:
      return { ...state, showVoiceSidebar: !state.showVoiceSidebar };
    case types.ADD_LATEST_POSITIONS:
      return { ...state, lastPositions: action.lastPositions };
    case types.OPEN_VEHICLE_MODAL:
      return { ...state, showVehicleModal: !state.showVehicleModal };
    case types.OPEN_VEHICLE_CAM:
      return { ...state, deviceIdsWithCamEnabled: action.payload };
    case types.ADD_DEVICE_INFO_MODAL:
      return {
        ...state,
        deviceInfoModalVehicle: action.deviceInfoModalVehicle,
      };
    case types.OPEN_MODAL_INCIDENTS_ACTIVE:
      return {
        ...state,
        showModalIncidentsActive: !state.showModalIncidentsActive,
      };
    case types.ADD_INCIDENTS_ACTIVE_ID:
      return {
        ...state,
        incidentsActiveId: action.incidentsActiveId,
      };
    case types.OPEN_REASONING_TEXT:
      return {
        ...state,
        showReasoningText: !state.showReasoningText,
      };
    case types.ADD_INCIDENT_MARKER:
      return {
        ...state,
        incidentMarker: action.incidentMarker,
      };
    case types.ADD_TRANSFER_ID_TREATMENT:
      return {
        ...state,
        transferIdTreatment: action.transferIdTreatment,
      };
    case types.ADD_MESSAGES_AREAS:
      return {
        ...state,
        messagesAreas: action.messagesAreas,
      };
    case types.ADD_DISABLED_DETAILS_BUTTONS:
      return {
        ...state,
        disabledDetailsButtons: action.disabledDetailsButtons,
      };
    case types.SHOW_ACTIVE_INCIDENTS:
      return {
        ...state,
        activeIncidentsVisible: action.payload,
      };
    case types.ADD_ALERTS_SOUND:
      return {
        ...state,
        alertsSound: [...state.alertsSound, action.alertsSound],
      };
    case types.RMV_ALERTS_SOUND:
      return {
        ...state,
        alertsSound: state.alertsSound.filter(
          (alert) => alert !== action.alertsSound
        ),
      };
    case types.CLEAR_ALERTS_SOUND:
      return {
        ...state,
        alertsSound: [],
      };
    case types.TOGGLE_INCIDENT_ID_NOT_DISPLAY_POLYGON:
      const exists = state.incidentIdsNotDisplayPolygon.includes(
        action.payload
      );

      if (exists) {
        return {
          ...state,
          incidentIdsNotDisplayPolygon:
            state.incidentIdsNotDisplayPolygon.filter(
              (incidentId) => incidentId !== action.payload
            ),
        };
      }

      return {
        ...state,
        incidentIdsNotDisplayPolygon: [
          ...state.incidentIdsNotDisplayPolygon,
          action.payload,
        ],
      };

    case types.TOGGLE_DYNAMIC_DRAWER:
      if (action.payload.id === state.dynamicDrawer?.id) {
        return {
          ...state,
          dynamicDrawer: null,
        };
      }

      return {
        ...state,
        dynamicDrawer: action.payload,
      };

    case types.CLOSE_DYNAMIC_DRAWER:
      return {
        ...state,
        dynamicDrawer: null,
      };

    default:
      return state;
  }
};

export const changeMapStylesAction = (mapType) => ({
  type: types.CHANGE_MAP_STYLES,
  mapType,
});

export const openMapStyleDrawerAction = () => ({
  type: types.OPEN_MAP_STYLE_DRAWER,
});

export const maxOpenMapMonitoringAction = () => ({
  type: types.MAX_OPEN_MAP_MONITORING,
});

export const addTransferActiveAction = (transferActive) => ({
  type: types.ADD_TRANSFER_ACTIVE,
  transferActive,
});

export const addRenderAction = (render) => ({
  type: types.ADD_RENDER,
  render,
});

export const removeTransferActiveAction = (transferActive) => ({
  type: types.RMV_TRANSFER_ACTIVE,
  transferActive,
});

export const addDevicesAction = (devices) => ({
  type: types.ADD_DEVICES,
  devices,
});

export const addDevicesIdsSelectedAction = (devicesIdsSelected) => ({
  type: types.ADD_DEVICES_IDS_SELECTED,
  devicesIdsSelected,
});

export const addAlertsFlespiAction = (alertsFlespi) => {
  return { type: types.ADD_ALERTS_FLESPI, alertsFlespi };
};

export const addStepRouteAction = (stepRoute) => ({
  type: types.ADD_STEP_ROUTE,
  stepRoute,
});

export const addStopPointAction = (stopPoint) => ({
  type: types.ADD_STOP_POINT,
  stopPoint,
});

export const addZoomInAction = (zoom) => ({
  type: types.ADD_ZOOM_IN,
  zoom,
});

export const addCenterMapAction = (centerMap) => ({
  type: types.ADD_CENTER_MAP,
  centerMap,
});

export const addValidateAlertPinAction = (validateAlertPin) => ({
  type: types.ADD_VALIDATE_ALERT_PIN,
  validateAlertPin,
});

export const addEventDetailsAction = (eventDetails) => ({
  type: types.ADD_EVENT_DETAILS,
  eventDetails,
});

export const addAlertsAction = (allAlerts) => ({
  type: types.ADD_ALERTS,
  allAlerts,
});

export const addDeviceIdAction = (device_id) => ({
  type: types.ADD_DEVICE_ID,
  device_id,
});

export const addOpenAlertsSidebarAction = (openAlertsSidebar) => ({
  type: types.OPEN_ALERTS_SIDEBAR,
  openAlertsSidebar,
});

export const addIdentifierSearchAction = (identifierSearchSidebar) => ({
  type: types.IDENTIFIER_SEARCH_SIDEBAR,
  identifierSearchSidebar,
});

export const changeDevicesVirtualFenceVisibility = (devices) => ({
  type: types.CHANGE_DEVICES_VIRTUAL_FENCE_VISIBILITY,
  devices,
});

export const changeShowModalVoice = () => ({
  type: types.CHANGE_SHOW_MODAL_VOICE,
});

export const changeShowVoiceSidebar = () => ({
  type: types.CHANGE_SHOW_VOICE_SIDEBAR,
});

export const addLastPositons = (lastPositions) => ({
  type: types.ADD_LATEST_POSITIONS,
  lastPositions,
});

export const changeVehicleModal = () => ({
  type: types.OPEN_VEHICLE_MODAL,
});

export const changeVehicleCam = (payload) => ({
  type: types.OPEN_VEHICLE_CAM,
  payload,
});

export const addDeviceInfoModal = (deviceInfoModalVehicle) => ({
  type: types.ADD_DEVICE_INFO_MODAL,
  deviceInfoModalVehicle,
});

export const openModalIncidentsActive = () => ({
  type: types.OPEN_MODAL_INCIDENTS_ACTIVE,
});

export const addIncidentsActiveId = (incidentsActiveId) => ({
  type: types.ADD_INCIDENTS_ACTIVE_ID,
  incidentsActiveId,
});

export const openReasoningText = () => ({
  type: types.OPEN_REASONING_TEXT,
});

export const addIncidentMarker = (incidentMarker) => ({
  type: types.ADD_INCIDENT_MARKER,
  incidentMarker,
});

export const addTransferIdTreatment = (transferIdTreatment) => ({
  type: types.ADD_TRANSFER_ID_TREATMENT,
  transferIdTreatment,
});

export const addMessagesAreas = (messagesAreas) => ({
  type: types.ADD_MESSAGES_AREAS,
  messagesAreas,
});

export const handleDisabledDetailsButtons = (idsToDisable) => ({
  type: types.ADD_DISABLED_DETAILS_BUTTONS,
  disabledDetailsButtons: idsToDisable,
});

export const showActiveIncidents = (value) => ({
  type: types.SHOW_ACTIVE_INCIDENTS,
  payload: value,
});

export const toggleIncidentIdNotDisplayPloygon = (incidentId) => ({
  type: types.TOGGLE_INCIDENT_ID_NOT_DISPLAY_POLYGON,
  payload: incidentId,
});

export const addAlertSound = (alertsSound) => ({
  type: types.ADD_ALERTS_SOUND,
  alertsSound,
});

export const rmvAlertSound = (alertsSound) => ({
  type: types.RMV_ALERTS_SOUND,
  alertsSound,
});

export const clearAlertSound = () => ({
  type: types.CLEAR_ALERTS_SOUND,
});

export const toggleDynamicDrawer = ({ id, header, contents }) => ({
  type: types.TOGGLE_DYNAMIC_DRAWER,
  payload: {
    id,
    header,
    contents,
  },
});

export const closeDynamicDrawer = () => ({
  type: types.CLOSE_DYNAMIC_DRAWER,
});

export const alertSound = {
  key: "alertSound",
  storage,
  whitelist: ["alertsSound"],
  timeout: null,
};
