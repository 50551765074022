export const types = {
  SEARCH_CUSTOMER_POINTS_OF_INTEREST: "SEARCH_CUSTOMER_POINTS_OF_INTEREST",
  SEARCH_CUSTOMER_POINTS_OF_INTEREST_TYPES:
    "SEARCH_CUSTOMER_POINTS_OF_INTEREST_TYPES",
};

const INITIAL_VALUES = {
  customerPointsOfInterest: [],
  customerPointOfInterestTypes: [],
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.SEARCH_CUSTOMER_POINTS_OF_INTEREST:
      return {
        ...state,
        customerPointsOfInterest: action.payload,
      };
    case types.SEARCH_CUSTOMER_POINTS_OF_INTEREST_TYPES:
      return {
        ...state,
        customerPointOfInterestTypes: action.payload,
      };
    default:
      return { ...state };
  }
};

export const addCustomerPointsOfInterest = (data) => ({
  type: types.SEARCH_CUSTOMER_POINTS_OF_INTEREST,
  payload: data,
});

export const addCustomerPointsOfInterestTypes = (data) => ({
  type: types.SEARCH_CUSTOMER_POINTS_OF_INTEREST_TYPES,
  payload: data,
});
