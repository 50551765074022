import React from 'react';
import _ from 'lodash';
import { Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';

import styles from './styles';
import NumberField from './NumberField';

let MonthDays = ({ classes, customDates, change }) => {
  return (
    <Grid
      item
      xs={12}
    >
      <Field
        name="custom_dates"
        component={NumberField}
        normalize={(value, previousValue) => _.uniq([...previousValue, parseInt(value)])}
      />

      {customDates.length > 0 && (
        <Grid
          container
          spacing={8}
          className={classes.selectedDatesContainer}
        >
            {customDates.map(customDate => (
              <Grid
                item
                xs={3}
                key={customDate}
              >
                <div className={classes.selectedDateContainer}>
                  <span className={classes.selectedDateLabel}>Dia {customDate}</span>

                  <div
                    className={classes.removeIconContainer}
                    onClick={() => {
                      const newValues = customDates.filter(date => date !== customDate);
                      change('preConfiguredTransferForm', 'custom_dates', newValues);
                    }}
                  >
                    <CancelIcon
                      fontSize="small"
                      className={classes.removeIcon}
                    />
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
      )}
    </Grid>
  )
};

MonthDays = withStyles(styles)(MonthDays);

export const mapStateToProps = state => {

  const selector = formValueSelector('preConfiguredTransferForm');

  return { customDates: selector(state, 'custom_dates') };
}

const mapDispatchToProps = { change };

export default connect(mapStateToProps, mapDispatchToProps)(MonthDays);

