export const styles = {
  cancelButton: {
    margin: "0 15px",
    color: "#06B0CF",
    borderRadius: 8,
    border: "none",
    boxShadow: "0px 3px 6px #00000029",
    width: "100%",
  },
  proceedButton: {
    backgroundColor: "#06B0CF",
    borderRadius: 8,
    boxShadow: "0px 3px 6px #00000029",
    width: "100%",
  },
  dialogActions: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  dialog: {
    height: "600px",
  },
  dialogContentText: {
    color: "black",
  },
};
