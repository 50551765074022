import React, { useState, useEffect } from "react";
import { Marker } from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import * as occurrencesService from "../../../../services/occurrences";
import { setCountClippingSecurity } from "../../../../store/ducks/scripting";

export const useClippingOccurrences = () => {
  const [occurrenceTypeId, setOccurrenceTypeId] = useState([]);
  const [registeredOccurrence, setRegisteredOccurrence] = useState([]);
  const currentTransfer = useSelector(
    (state) => state.transfer.currentTransfer
  );
  const occurrencesCategories = useSelector(
    (state) => state.scripting.occurrencesCategories
  );
  const directions = useSelector((state) => state.scripting.directions);
  const dispatch = useDispatch();

  const getOccurrenceCategoriesIds = () => {
    const selectedTypesIds = occurrencesCategories
      ?.filter((category) => category.selected === true)
      ?.flatMap((category) => category.types.map((type) => type.id));
    setOccurrenceTypeId(selectedTypesIds);
  };

  const countRegisteredOccurrence = () => {
    const selectedTypesIds = occurrencesCategories
      ?.filter((category) => category.selected === true)
      ?.flatMap((category) => category.types.map((type) => type.id));

    const filteredOccurrences = registeredOccurrence.filter((occ) =>
      selectedTypesIds?.includes(occ.clippingTypeId)
    );

    setCountClippingSecurity(filteredOccurrences.length);
    return filteredOccurrences;
  };

  useEffect(() => {
    const execute = async () => {
      try {
        const registeredOccurrences = await occurrencesService.getOccurrences({
          after_date: "2023-07-13",
          from: currentTransfer.from,
          to: currentTransfer.to,
          radius: 500,
          linestring: directions.routes[0].overview_polyline,
        });

        setRegisteredOccurrence(registeredOccurrences);
      } catch (error) {
        throw error;
      }
    };

    countRegisteredOccurrence();
    getOccurrenceCategoriesIds();
    execute().catch(console.error);
  }, [directions, occurrencesCategories, registeredOccurrence]);

  const renderOccurrences = () => {
    return (
      <>
        {registeredOccurrence
          ?.filter(({ clippingTypeId }) =>
            occurrenceTypeId?.includes(clippingTypeId)
          )
          .map((occ) => (
            <Marker
              key={occ.id}
              position={{
                lat: occ?.location.lat,
                lng: occ?.location.lng,
              }}
              icon={occ?.type?.icon}
            />
          ))}
      </>
    );
  };

  return { renderOccurrences };
};
