import { Close, Person } from "@mui/icons-material";
import {
  Button,
  Grid,
  Stepper,
  styled as styledMUI,
  TextField,
} from "@mui/material";
import styled from "styled-components";

const colors = {
  gray: "#767676",
  darkBlue: "#092034",
};

const fontStyles = {
  bold: 700,
  regular: 400,
};

export const StepperFormStatus = styledMUI(Stepper)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "5px",
  marginBottom: "5px",
}));

export const CustomGrid = styledMUI(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

export const PersonIcon = styledMUI(Person)(() => ({
  color: "black",
  width: "16px",
  height: "16px",
}));

export const CloseIcon = styledMUI(Close)(() => ({
  color: "#06B0CF",
  display: "flex",
  paddingTopTop: 0,
}));

export const ButtonPersonIcon = styledMUI(Button)(() => ({
  width: 10,
  borderRadius: 12,
  boxShadow: "0px 3px 6px #00000029",
  border: "none",
}));

export const ButtonCustom = styledMUI(Button)(() => ({
  width: 5,
  borderRadius: 10,
  boxShadow: "0px 0px 10px 0px #00000026",
  backgroundColor: "#fff",
  height: "36px",
  border: "none",
}));

const styles = {
  dialogTitle: {
    fontWeight: fontStyles.bold,
    marginBottom: "20px",
    color: colors.gray,
  },
  autocompleteField: {
    padding: 0,
    flexGrow: 5,
    flexShrink: 0,
  },
  automatic: {
    marginLeft: "5px",
    fontSize: "0.8rem",
    color: colors.darkBlue,
    fontWeight: fontStyles.bold,
  },
  roundTrip: {
    fontSize: "0.8rem",
    color: colors.darkBlue,
    fontWeight: fontStyles.bold,
    marginLeft: "9px",
  },
  importedRoutes: {
    fontSize: "0.8rem",
    marginLeft: "5px",
    color: colors.darkBlue,
    fontWeight: fontStyles.bold,
  },
  otherInformation: {
    fontWeight: fontStyles.bold,
    color: colors.gray,
    marginLeft: 0,
    marginBottom: "5px",
    marginTop: "15px",
  },
  saveAndScript: {
    fontWeight: fontStyles.bold,
    lineHeight: "17px",
    textAlign: "left",
  },
};

export const CustomTextField = styledMUI(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "5px",
    },
  },
  input: {
    fontSize: "15px",
    fontWeight: 400,
  },
  backgroundColor: "#F8F8F8",
  margin: "5px 0px 5px 0px",
  minWidth: "200px",
  flex: 1,
}));

export const CustomButtonSaveAndScript = styledMUI(Button)(() => ({
  backgroundColor: "#3AC4E0",
  borderRadius: 3,
  boxShadow: "0px 3px 6px #00000029",
  marginLeft: "5px",
  width: "129px",
  height: "37px",
  padding: "10px",
  gap: "7px",
}));

export const CustomButtonSave = styledMUI(Button)(() => ({
  margin: "0",
  color: "#06B0CF",
  borderRadius: 3,
  border: "none",
  boxShadow: "0px 3px 6px #00000029",
  width: "100px",
  height: "37px",
}));

export const Div = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
`;
export const Diiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const Container = styled.div`
  margin-top: 10px;
  button {
    border-radius: 12px;
  }
  button.create-transfer {
    background: #06b0cf;
  }
`;

export default styles;
