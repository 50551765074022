import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Drawer,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  addCenterMapAction,
  addZoomInAction,
  openReasoningText,
  toggleIncidentIdNotDisplayPloygon,
  openModalIncidentsActive as openModalIncidentsActiveAction,
} from "../../store/ducks/monitoringMap";
import StarRatings from "react-star-ratings";

export default function ModalIncidentsActive() {
  const [date, setDate] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const openModalIncidentsActive = useSelector(
    (store) => store.monitoringMap.showModalIncidentsActive
  );
  const incidentIdsNotDisplayPolygon = useSelector(
    (state) => state.monitoringMap.incidentIdsNotDisplayPolygon
  );
  const incidentsActiveId = useSelector(
    (store) => store.monitoringMap.incidentsActiveId
  );
  const limitClamp = useSelector(
    (store) => store.monitoringMap.showReasoningText
  );
  const incidents = useSelector((store) => store.monitoringMap.incidentMarker);

  const zoomPattern = useSelector((store) => store.monitoringMap.zoom);

  const incident = incidents.find(
    (incident) => incident.id == incidentsActiveId
  );

  const options = { hour: "2-digit", minute: "2-digit" };

  const renderDate = () => {
    if (incident?.active && incident?.endedAt) {
      setDate(incident?.startedAt);
    }
    const date = new Date(incident?.startedAt);
    date.setHours(date.getHours() + 3);
    const formattedDate = date.toLocaleDateString("pt-BR", options);

    setDate(formattedDate);
  };

  const showReasoningText = () => {
    dispatch(openReasoningText());
  };

  const zoomIncidents = (zoom) => {
    dispatch(addZoomInAction(zoom));
    dispatch(
      addCenterMapAction({
        lat: Number(incident?.location.lat),
        lng: Number(incident?.location.lng),
      })
    );
  };

  const renderZoomBotton = () => {
    const zoomLevel = zoomPattern == 10 ? 17 : 10;
    const label = zoomPattern == 10 ? "Aproximar" : "Afastar";

    return (
      <div className={classes.Button} onClick={() => zoomIncidents(zoomLevel)}>
        {label}
      </div>
    );
  };

  const toggleDisplayPolygon = (incidentId) => {
    return () => {
      dispatch(toggleIncidentIdNotDisplayPloygon(incidentId));
    };
  };

  const closeSidebar = () => {
    dispatch(openModalIncidentsActiveAction());
  };

  useEffect(() => {
    renderDate();
  }, [openModalIncidentsActive]);

  return (
    <div className={classes.root}>
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerVehicle]: false,
        })}
        variant="persistent"
        anchor="right"
        open={openModalIncidentsActive}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerPaperVehicle]: false,
          }),
        }}
      >
        <div className={classes.box}>
          <Paper className={classes.eventListContainer}>
            <div className={classes.boxHeader}>
              <img
                src={incident?.type?.icon}
                className={classes.incidentIcon}
                alt=""
              ></img>
              <div className={classes.IncidentName}>{incident?.title}</div>
              <IconButton onClick={closeSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.Body}>
              <div className={classes.BodyInfo}>
                <b>Evento: </b>
                {incident?.description}
              </div>
              <div className={classes.BodyInfo}>
                <b>Data de início: </b>
                {date}
              </div>
              <div className={classes.BodyInfo}>
                <b>Tipo de evento: </b>
                {incident?.type?.type}
              </div>
              {incident?.polygon && (
                <div className={classes.CentralizedBodyInformation}>
                  <b>Exibir área: </b>
                  <Switch
                    size="small"
                    checked={
                      !incidentIdsNotDisplayPolygon.includes(incident?.id)
                    }
                    onChange={toggleDisplayPolygon(incident?.id)}
                    color="primary"
                  />
                </div>
              )}
            </div>
            <div className={classes.BodyCredibility}>
              <label className={classes.TextCredibility}>Credibilidade: </label>
              <StarRatings
                rating={incident?.credibility}
                starDimension="15px"
                starSpacing="1px"
                starRatedColor="Gold"
              />
            </div>
            <div className={classes.Footer}>
              <div className={classes.ReasoningTitle}>Fundamentação</div>

              {incident?.grounding.length > 80 ? (
                <div
                  className={
                    !limitClamp
                      ? classes.ReasoningLineClamp
                      : classes.ReasoningTextHidden
                  }
                  onClick={() => showReasoningText()}
                >
                  {incident?.grounding.replace(/<br\s*\/?>/gm, "")}
                </div>
              ) : (
                <div className={classes.ReasoningText}>
                  {incident?.grounding.replace(/<br\s*\/?>/gm, "")}
                </div>
              )}
              <div className={classes.Flex}>
                {incident?.links.length > 0 && (
                  <div className={classes.Button}>
                    <a
                      className={classes.Link}
                      href={incident?.links[0].url || ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </div>
                )}

                {renderZoomBotton()}
              </div>
            </div>
          </Paper>
        </div>
      </Drawer>
    </div>
  );
}
