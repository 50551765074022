import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, Button, Grid, makeStyles, Avatar } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import { setIncidentsNaturesAction, setSidebarContentOpen } from '../../../store/ducks/scripting';

const useStyles = makeStyles(() => ({
  button: {
    margin: 'auto',
    width: 150
  }
}));

export const NaturesAdvancedOptionsSidebarContent = () => {
  const { incidentsNatures } = useSelector(state => state.scripting);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChangeCategoryChecked = useCallback((isChecked, category) => {
    const modifiedTypesSelection = category.types.map(type => ({ ...type, selected: isChecked }));

    const newIncidentsArray = incidentsNatures.map(incident => {
      if (incident.id === category.id) return { ...category, selected: isChecked, types: modifiedTypesSelection }
      else return incident;
    });

    dispatch(setIncidentsNaturesAction(newIncidentsArray));
  }, [incidentsNatures]);

  const handleChangeTypeChecked = useCallback((isChecked, selectedType, category) => {
    let everyTypesAreChecked = true;

    const newTypesArray = category.types.map(type => {
      if (type.id === selectedType.id) {
        //if (type.selected === false) everyTypesAreChecked = false;
        return { ...type, selected: isChecked }
      } else {
        if (type.selected === false) everyTypesAreChecked = false;
        return type;
      }
    });

    const newIncidentsArray = incidentsNatures.map(incident => {
      if (incident.id === category.id) {
        if (isChecked === false) {
          return { ...category, selected: isChecked, types: newTypesArray }
        } else {
          return { ...category, selected: everyTypesAreChecked, types: newTypesArray }
        }
      } else {
        return incident;
      }
    });
    
    dispatch(setIncidentsNaturesAction(newIncidentsArray));
  }, [incidentsNatures]);

  const handleBackToDefaultSidebar = useCallback(() => {
    return dispatch(setSidebarContentOpen('default'));
  }, [dispatch]);

  return (
    <Grid container direction="column">
      <Button onClick={() => handleBackToDefaultSidebar()}
        variant="contained"
        color="primary"
        startIcon={<KeyboardBackspace />}
        className={classes.button}
        style={{ marginTop: 10 }}
      >
        Voltar
      </Button>

      {incidentsNatures && incidentsNatures.map(category => (
        <>
          <Grid container style={{ marginLeft: 10 }}>
            <FormControlLabel
              label={category.category}
              control={
                <Checkbox
                  color="primary"
                  checked={category.selected}
                  onChange={(e) => handleChangeCategoryChecked(e.target.checked, category)}
                />
              }
            />
          </Grid>
          {category.types.map(type => (
            <Grid container direction="column" style={{ marginLeft: 40 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 250 }}>
                <FormControlLabel
                  label={type.type}
                  control={
                    <Checkbox
                      color="primary"
                      checked={type.selected}
                      onChange={(e) => handleChangeTypeChecked(e.target.checked, type, category)}
                    />
                  }
                />
                <Avatar src={type.icon} />
              </div>
            </Grid>
          ))}

          <hr />
        </>
      ))}
    </Grid>
  );
}