export const DESCRIPTION = 1;
export const RESPONSIBLE = 2;

// Função no trabalho
export const FUNCITON = 3;

export const EMAIL = 4;
export const TELEPHONE = 5;
export const CELLPHONE = 6;
export const ADDRESS = 7;
export const WORKING_TIME = 8;
export const POPULATION = 9;

// Policia Militar
export const POLICE = 10;

// Bombeiros
export const FIREFIGHTERS = 11;

export const CIVIL_POLICE = 12;

//Delegacia
export const PRECINCT = 13;
