import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "material-react-toastify";
import { transfers } from "../../../services/transferApi";
import * as deviceApiService from "../../../services/devicesService";
import { parseISO, format } from "date-fns";
import FormInputImportRoutesRegister from "./formInputImportRoutesRegisterNew";
import FormInputRegister from "./formInputRegisterNew";
import FormInputToRegister from "./formInputToRegisterNew";
import { handleGetSettings } from "../../../services/customerAreaManagement/setting";
import SearchAutoComplete from "./SearchAutoCompleteNew";
import * as Yup from "yup";

import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import styles, {
  CustomButtonSave,
  CustomButtonSaveAndScript,
  CustomTextField,
} from "../stylesNew";
import { Form, Formik } from "formik";
import { useGoogleMapsKey } from "../../../hooks/useGoogleMapsKey";
import { useLoadScript } from "@react-google-maps/api";

const libraries = ["places", "drawing", "geometry"];

export default function RegisterForm() {
  const [customerActivities, setCustomerActivities] = useState([]);
  const [customerCategories, setCustomerCategories] = useState([]);
  const [customerUnits, setCustomerUnits] = useState([]);
  const [customerProducts, setCustomerProducts] = useState([]);
  const [checked, setChecked] = useState(false);
  const [useImportedRoutes, setUseImportedRoutes] = useState(false);
  const [autoEndChecked, setAutoEndChecked] = useState(false);
  const [autoStartChecked, setAutoStartChecked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const from = useSelector((state) => state.transfer.from);
  const to = useSelector((state) => state.transfer.to);
  const token = useSelector((store) => store.auth.token.accessToken);
  const customerId = useSelector((store) => store.auth.customer.customer);
  const history = useHistory();
  const dispatch = useDispatch();
  const [configurations, setConfigurations] = useState([]);
  const googleMapsKey = useGoogleMapsKey();

  const getInitialFormValues = {
    unity: "",
    name: "",
    customerActivitySelected: "",
    customerCategorySelected: "",
    customerUnitSelected: "",
    customerProductSelected: "",
    responsible: "",
    scheduled_date: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("O nome é obrigatório"),
  });

  let aux = 0;

  useEffect(() => {
    const config = async () => {
      const response = await handleGetSettings(customerId);

      setConfigurations(response);
    };

    config();
  }, []);

  useEffect(() => {
    const loadFormData = async () => {
      setLoadingData(true);

      const fields = configurations.map((n) => n.field);

      const requests = [];

      try {
        requests.push(
          fields.includes(1)
            ? deviceApiService.getCustomerUnits(customerId)
            : null
        );
        requests.push(
          fields.includes(2)
            ? deviceApiService.getCustomerCategories(customerId)
            : null
        );
        requests.push(
          fields.includes(3)
            ? deviceApiService.getCustomerProducts(customerId)
            : null
        );
        requests.push(
          fields.includes(4)
            ? deviceApiService.getCustomerActivities(customerId)
            : null
        );

        const filteredRequests = requests.filter((request) => request !== null);
        const [
          customerUnitsResponse,
          customerCategoriesResponse,
          customerProductsResponse,
          customerActivitiesResponse,
        ] = await Promise.all(filteredRequests);

        setCustomerUnits(customerUnitsResponse);
        setCustomerCategories(customerCategoriesResponse);
        setCustomerProducts(customerProductsResponse);
        setCustomerActivities(customerActivitiesResponse);
      } catch {
        toast.error("Error");
      } finally {
        setLoadingData(false);
      }
    };

    loadFormData();
  }, [configurations]);

  const handleScripting = (e) => {
    aux = 1;
    handleRegisterTransfer(e, aux);
  };

  function addIdToScriptingaction(id) {
    return { type: "ADD_IDTOSCRIPTING", id };
  }

  const fieldRequired = (field) => {
    const configuration = configurations.find((n) => n.field == field);

    if (!configuration) {
      return false;
    }

    return configuration.required;
  };

  async function handleRegisterTransfer(e, aux) {
    if (!from) return toast.error("Selecione um Local de Partida sugerido.");
    if (!to && !checked)
      return toast.error("Selecione um Local de Destino sugerido.");

    if (!e.customerUnitSelected && fieldRequired(1))
      return toast.error("Selecione uma Unidade sugerida");
    if (!e.customerCategorySelected && fieldRequired(2))
      return toast.error("Selecione uma Categoria sugerida");
    if (!e.customerProductSelected && fieldRequired(3))
      return toast.error("Selecione um Produto sugerido");
    if (!e.customerActivitySelected && fieldRequired(4))
      return toast.error("Selecione uma Atividade sugerida");

    setSuccess(true);
    const data = {
      unity: e.unity,
      name: e.name,
      responsible: e.responsible,
      scheduled_date: format(parseISO(e.scheduled_date), "yyyy-MM-dd HH:mm", {
        timeZone: "America/Sao_Paulo",
      }),
      from,
      to: checked ? from : to,
      automatic_beginning: autoStartChecked,
      automatic_end: autoEndChecked,
      transfer_product_id: e.customerProductSelected || null,
      transfer_activity_id: e.customerActivitySelected || null,
      transfer_category_id: e.customerCategorySelected || null,
      customer_unit_id: e.customerUnitSelected || null,
    };
    try {
      const responseId = await transfers(data, token);
      dispatch(addIdToScriptingaction(responseId));
      if (aux === 1) {
        aux = 0;
        history.push("/scripting");
      } else {
        window.location.reload(true);
      }
    } catch (err) {
      setSuccess(false);
      alert("Erro ao cadastrar");
    }
  }

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  if (!isLoaded) {
    return <></>;
  }

  return (
    <Formik
      initialValues={getInitialFormValues}
      validationSchema={validationSchema}
      onSubmit={(e) => handleScripting(e)}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          {!success && !loadingData ? (
            <>
              <Typography style={styles.dialogTitle}>
                Dados do Transfer
              </Typography>
              <Grid container spacing={1} style={{ marginBottom: "5px" }}>
                <Grid
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    style={{ marginLeft: 0, display: "flex" }}
                    value="from-to"
                    control={
                      <Switch
                        onClick={() => setChecked((previous) => !previous)}
                        size="small"
                        color="primary"
                      />
                    }
                    label={
                      <Typography style={styles.roundTrip}>
                        Ida e volta
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    style={{ marginLeft: 0, marginRight: 10 }}
                    control={
                      <Switch
                        onClick={() =>
                          setUseImportedRoutes((previous) => !previous)
                        }
                        size="small"
                        color="primary"
                      />
                    }
                    label={
                      <Typography style={styles.importedRoutes}>
                        Importar Rotas
                      </Typography>
                    }
                    labelPlacement="start"
                  />

                  <FormControlLabel
                    style={{ marginLeft: 0, marginRight: 10 }}
                    control={
                      <Switch
                        onClick={() =>
                          setAutoStartChecked((previous) => !previous)
                        }
                        size="small"
                        color="primary"
                      />
                    }
                    label={
                      <Typography style={styles.automatic}>
                        Inicio Automático
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    style={{ marginLeft: 0, marginRight: 10 }}
                    control={
                      <Switch
                        onClick={() =>
                          setAutoEndChecked((previous) => !previous)
                        }
                        size="small"
                        color="primary"
                      />
                    }
                    label={
                      <Typography style={styles.automatic}>
                        Fim Automático
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="subtitle2"
                    gutterBottom
                  >
                    Evento:
                  </Typography>
                  <CustomTextField
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    error={Boolean(errors.name && touched.name)}
                    variant="outlined"
                    name="name"
                    value={values.name}
                    type="search"
                    placeholder="Qual será o Evento?"
                    style={{
                      width: "100%",
                      height: 40,
                    }}
                    size="small"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="subtitle2"
                    gutterBottom
                  >
                    Responsável:
                  </Typography>
                  <CustomTextField
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    error={Boolean(errors.responsible && touched.responsible)}
                    variant="outlined"
                    name="responsible"
                    value={values.responsible}
                    type="search"
                    placeholder="Informe o Responsável"
                    style={{ width: "100%", height: 40 }}
                    size="small"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="subtitle2"
                    gutterBottom
                  >
                    Data e Hora:
                  </Typography>
                  <CustomTextField
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    error={Boolean(
                      errors.scheduled_date && touched.scheduled_date
                    )}
                    value={values.scheduled_date}
                    variant="outlined"
                    name="scheduled_date"
                    type="datetime-local"
                    style={{ width: "100%", height: 40 }}
                    size="small"
                    onChange={handleChange}
                  />
                </Grid>

                {useImportedRoutes ? (
                  <Grid item xs={12} sm={6}>
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      Importar Rotas:
                    </Typography>
                    <FormInputImportRoutesRegister />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}>
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      {checked
                        ? "Local de Partida e Destino (ida e volta)"
                        : "Local de Partida:"}
                    </Typography>
                    <FormInputRegister />
                  </Grid>
                )}

                {!useImportedRoutes && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    onClick={() => {
                      if (checked)
                        return toast.warning(
                          'Para editar o endereço de destino, desmarque a opção de "ida e volta"'
                        );
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      Local de Destino:
                    </Typography>
                    <FormInputToRegister checked={checked} />
                  </Grid>
                )}
              </Grid>
              <section style={{ display: "flex", flexDirection: "column" }}>
                {configurations.length > 0 && (
                  <Typography style={styles.otherInformation}>
                    Outras Informações
                  </Typography>
                )}
                <Grid container spacing={1}>
                  {configurations.map((n) => n.field).includes(1) && (
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }} size="small">
                        <Typography
                          style={{ fontWeight: "bold" }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          Unidade:
                        </Typography>
                        <SearchAutoComplete
                          options={customerUnits}
                          onChange={(event, unit) =>
                            setFieldValue("customerUnitSelected", unit?.id)
                          }
                          placeholder="Unidade"
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {configurations.map((n) => n.field).includes(4) && (
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }} size="small">
                        <Typography
                          style={{ fontWeight: "bold" }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          Atividade:
                        </Typography>
                        <SearchAutoComplete
                          options={customerActivities}
                          onChange={(event, activite) =>
                            setFieldValue(
                              "customerActivitySelected",
                              activite?.id
                            )
                          }
                          placeholder="Atividade"
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {configurations.map((n) => n.field).includes(2) && (
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }} size="small">
                        <Typography
                          style={{ fontWeight: "bold" }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          Categorias:
                        </Typography>
                        <SearchAutoComplete
                          options={customerCategories}
                          onChange={(event, category) =>
                            setFieldValue(
                              "customerCategorySelected",
                              category?.id
                            )
                          }
                          placeholder="Categorias"
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {configurations.map((n) => n.field).includes(3) && (
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: "100%" }} size="small">
                        <Typography
                          style={{ fontWeight: "bold" }}
                          variant="subtitle2"
                          gutterBottom
                        >
                          Produto:
                        </Typography>
                        <SearchAutoComplete
                          options={customerProducts}
                          onChange={(event, product) =>
                            setFieldValue(
                              "customerProductSelected",
                              product?.id
                            )
                          }
                          placeholder="Produto"
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </section>
              <CustomButtonSave
                type="submit"
                autoFocus
                variant="outlined"
                color="primary"
              >
                <Typography fontSize={10}>Salvar</Typography>
              </CustomButtonSave>
              <CustomButtonSaveAndScript
                type="submit"
                variant="contained"
                autoFocus
                color="primary"
              >
                <Typography fontSize={10} style={styles.saveAndScript}>
                  Salvar e Roteirizar
                </Typography>
              </CustomButtonSaveAndScript>
            </>
          ) : (
            <>
              <center>
                <div>
                  <CircularProgress
                    size={68}
                    style={{ marginTop: 0, color: "#63a9ef" }}
                  ></CircularProgress>
                </div>
                <span>
                  {loadingData ? "Carregando dados" : "Criando transfer"}
                </span>
              </center>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}
