import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  div: {
    display: "flex",
    alignItems: "center",
  },
});
