import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  divButton: {
    display: "flex",
    marginTop: "15px",
    marginLeft: "20px",
    gap: "15px",
  },
  txtButton: {
    width: "150px",
    height: "45px",
    fontSize: "0.875rem",
    textTransform: "none",
    color: "white",
    backgroundColor: "#06b0cf",
    "&:hover": {
      backgroundColor: "#06b0cf",
    },
  },
}));
