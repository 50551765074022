import React, { useState, useEffect } from 'react';
import { Marker, InfoWindow } from "@react-google-maps/api";
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { OccurrencesAlertDialog } from '../OccurrencesDialog';
import { setRetrievedFilteredOccurrencesAction } from '../../../../store/ducks/scripting';
import * as occurrencesService from '../../../../services/occurrences';

export const useOccurrences = () => {
  const [currentOccurrence, setCurrentOccurrence] = useState({ id: '' });
  const currentTransfer = useSelector(state => state.transfer.currentTransfer);
  const directions = useSelector(state => state.scripting.directions);
  const displayedOccurrences = useSelector(state => state.scripting.displayedOccurrences);
  const currentTransferEditableInfo = useSelector(state => state.scripting.currentTransferEditableInfo);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const retrievedFilteredOccurrences = useSelector(state => state.scripting.retrievedFilteredOccurrences);
  const dispatch = useDispatch();

  const boolZeroDistanceRoute = (
    directions
    && directions.geocoded_waypoints.length === 2
    && directions.geocoded_waypoints[0].place_id === directions.geocoded_waypoints[1].place_id
  )

  useEffect(() => {
    const execute = async () => {
      if (boolZeroDistanceRoute || !directions)
        return

      if (!directions?.routes[0]?.overview_polyline)
        throw new Error('getAndSetOccurrences: overview_polyline not available...')
      if (!currentTransferEditableInfo.afterDate || !currentTransferEditableInfo.radius)
        throw new Error('getAndSetOccurrences: afterDate and/or radius not available...')
      if (displayedOccurrences === null)
        throw new Error('getAndSetOccurrences: displayedOccurrences not available...')
  
      let registeredOccurrences = null
      try {
        registeredOccurrences = await occurrencesService.getOccurrences({
          after_date: currentTransferEditableInfo.afterDate,
          from: currentTransfer.from,
          to: currentTransfer.to,
          radius: currentTransferEditableInfo.radius,
          linestring: directions.routes[0].overview_polyline
        })
      }
      catch (error) {
        throw error
      }
  
      const filteredRegisteredOccurrences = registeredOccurrences
        .filter(registeredOccurrence =>
          displayedOccurrences.subCategories[registeredOccurrence.clippingTypeId]
        )
  
      dispatch(setRetrievedFilteredOccurrencesAction(filteredRegisteredOccurrences))
    }

    execute()
      .catch(console.error)
  }, [directions]);

  const renderOccurrences = () => {
    if (!retrievedFilteredOccurrences) return null;
    return (
      <>
        {currentOccurrence.id && (
          <OccurrencesAlertDialog isOpen={isDialogOpen} setIsOpen={setDialogOpen} currentOccurrence={currentOccurrence} />
        )}

        {retrievedFilteredOccurrences.map(occurrence => {
          return (
            currentOccurrence.id === occurrence.id
              ? (
                <Marker
                  key={occurrence.id}
                  position={{ lat: currentOccurrence?.location.lat, lng: currentOccurrence?.location.lng }}
                  icon={currentOccurrence?.type?.icon}
                  onClick={() => setCurrentOccurrence(currentOccurrence)}
                >
                  <InfoWindow
                    position={{ lat: currentOccurrence?.location.lat, lng: currentOccurrence?.location.lng }}
                    onCloseClick={() => setCurrentOccurrence({ id: '' })}
                  >
                    <div>
                      <strong>Clipping de segurança pública: </strong> {currentOccurrence.title}
                      <br />
                      <Button onClick={() => setDialogOpen(true)} size="small" color="primary">
                        Clique aqui para mais informações
						          </Button>
                    </div>
                  </InfoWindow>
                </Marker>
              )
              : (
                <Marker
                  key={occurrence.id}
                  position={{ lat: occurrence?.location.lat, lng: occurrence?.location.lng }}
                  icon={occurrence?.type?.icon}
                  onClick={() => setCurrentOccurrence(occurrence)}
                />
              )
          )
        })}
      </>
    );
  }

  return { renderOccurrences }
}