import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '@material-ui/core'

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';

export default function DateAndTimePickers() {
  const dispatch = useDispatch();
  const scheduledDateFetch = useSelector(state => state.transfer.scheduledDateFetch)

  function addScheduledDateAct(scheduledDateFetch) {
    return { type: 'ADD_SCHEDULED_DATE_FETCH', scheduledDateFetch }
  }

  const handleChangeSearchTransfersByScheduledDate = e => {
    const value = e.target.value
    dispatch(addScheduledDateAct(value))
  }

  return (
    <Grid item>
      <TextField
        className="datetimer"
        onChange={handleChangeSearchTransfersByScheduledDate}
        style={{ width: 225, height: 40 }}
        defaultValue={scheduledDateFetch}
        type="date"
        variant="outlined"
        size="small"
        InputProps={{
          shrink: true,
          endAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
}
