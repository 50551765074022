import {
  styled as styledMUI,
  Typography,
  TextField,
  Button,
  Box,
  Card,
  CardHeader,
  CardContent,
  Paper,
  FormControlLabel,
} from "@mui/material";

export const CustomTextField = styledMUI(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "5px",
    },
  },
  input: {
    fontSize: "15px",
    fontWeight: 400,
  },
  backgroundColor: "#F8F8F8",
  margin: "5px 0px 5px 0px",
}));

export const AddPassengerButton = styledMUI(Button)(() => ({
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
  backgroundColor: "#FFFFFF",
  color: "#2A93A7",
  textTransform: "none",
  fontSize: "13px",
}));

export const CustomCard = styledMUI(Card)(() => ({
  width: "215px",
  marginLeft: "20px",
  marginTop: "15px",
}));

export const CustomCardHeader = styledMUI(CardHeader)(() => ({
  height: "40px",
  width: "50px",
}));

export const CustomCardContent = styledMUI(CardContent)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "5px",
}));

export const CustomPaper = styledMUI(Paper)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
}));

export const CustomFormControlLabel = styledMUI(FormControlLabel)(() => ({
  fontSize: "12px",
  fontWeight: 400,
  marginLeft: "-20px",
}));

export const CustomTypography = styledMUI(Typography)(({ typeLabel }) => {
  let fontSize;
  let fontWeight;

  if (typeLabel === "menuItem") {
    fontSize = "12px";
    fontWeight = 500;
  } else if (typeLabel === "address") {
    fontSize = "14px";
    fontWeight = 400;
  } else {
    fontSize = "15px";
    fontWeight = 700;
  }
  return {
    fontSize,
    fontWeight,
  };
});

export const ContainerFieldCardStop = styledMUI(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "5px",
}));
