import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'material-react-toastify'
import { Grid, Button, makeStyles, ButtonGroup } from '@material-ui/core'
import { Add, KeyboardBackspace } from '@material-ui/icons'
import { isEmpty } from 'lodash'
import { geocodeByAddress } from 'react-places-autocomplete'

import { AutoCompleteInput } from './AutocompleteInput'
import { WaypointsDragAndDrop } from './WaypointsDragAndDrop'
import {
  addWaypointAction,
  setMapClickAddDetourAction,
  setMapClickAddStopAction,
  setSidebarContentOpen,
  setWaypointsAction,
  startLoadingDirectionsAction
} from '../../../store/ducks/scripting';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  button: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const blueButtonStyles = {
  borderRadius: 8,
  color: '#06B0CF',
  backgroundColor: '#FFF',
  display: 'flex',
  alignItems: 'center'
};

export const WaypointsSidebarContent = () => {
  const classes = useStyles();
  const { mapClickAddStopOrDetour, waypoints } = useSelector(state => state.scripting);
  const [currentWaypoints, setCurrentWaypoints] = useState({});
  const [autocompleteLocation, setAutocompleteLocation] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    waypoints.forEach((waypoint, index) => {
      const newWaypointsObject = Object.assign(currentWaypoints, { [waypoint.id]: waypoint });
      setCurrentWaypoints(newWaypointsObject);
    });
  }, [waypoints]);

  const handleBackToDefaultSidebar = useCallback(() => {
    const newWaypointsArray = [];
    const errorMessages = [];

    if (isEmpty(currentWaypoints)) {
      return dispatch(setSidebarContentOpen('default'));
    }

    waypoints.forEach(waypoint => {
      const currentWaypoint = currentWaypoints[waypoint.id];
      
      if (currentWaypoint.stopover && !currentWaypoint.duration) {
        errorMessages.push(`Parada ${waypoint.location.split(',')[0]} não possui tempo de duração.`);
      }

      newWaypointsArray.push({
        ...currentWaypoint,
        title: currentWaypoint.title || currentWaypoint.location
      });
    });

    if (errorMessages.length > 0) return errorMessages.forEach(message => toast.error(message));
    if (newWaypointsArray.length > 0) dispatch(setWaypointsAction(newWaypointsArray));

    return dispatch(setSidebarContentOpen('default'));
  }, [dispatch, waypoints, toast, currentWaypoints, dispatch]);

  const handleAddWaypointByAutocompleteLocation = useCallback(async (e) => {
    e.preventDefault();

    if (!autocompleteLocation)
      return toast.error('Adicione um endereço.')
    
    let formattedAddress = null
    let position = null
    try {
      const [ locationData, ] = await geocodeByAddress(autocompleteLocation)
      formattedAddress = locationData.formatted_address || autocompleteLocation
      position = {
        latitude: locationData.geometry.location.lat(),
        longitude: locationData.geometry.location.lng(),
      }      
    }
    catch (error) {
      return toast.error('Endereço não existe.')
    }
    
    for (const waypoint of waypoints) {
      if (
        waypoint.position.latitude === position.latitude
        && waypoint.position.longitude === position.longitude
      )
        return toast.error('Endereço já cadastrado.')
    }

    let isStop;
    if (mapClickAddStopOrDetour === 'stop')
      isStop = true;
    else if (mapClickAddStopOrDetour === 'detour')
      isStop = false;
    else
      return toast.error('Selecione um modo: Desvio ou Parada.')

    const waypointData = {
      id: Math.random().toString(36),
      location: formattedAddress,
      stopover: isStop,
      title: '',
      duration: '',
      position,
    }

    dispatch(addWaypointAction(waypointData));
    dispatch(startLoadingDirectionsAction());
    setAutocompleteLocation('');
  }, [dispatch, autocompleteLocation, waypoints, mapClickAddStopOrDetour, toast]);

  return (
    <>
      <Grid style={{ padding: 5, overflowY: 'scroll', height:'100%' }} container direction="row" justify="space-around" alignItems="center">
        <Button variant="contained" color="primary" className={classes.button} startIcon={<KeyboardBackspace />} onClick={() => handleBackToDefaultSidebar()}>
          Voltar
        </Button>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <AutoCompleteInput value={autocompleteLocation} setValue={setAutocompleteLocation} />

          <Button style={blueButtonStyles} type="button" size="small" onClick={handleAddWaypointByAutocompleteLocation}>
            <Add />
          </Button>
        </div>

        <ButtonGroup size="small" variant="contained" fullWidth style={{ fontSize: 10, marginTop: 15 }}>
          <Button
            onClick={() => dispatch(setMapClickAddDetourAction())}
            color={mapClickAddStopOrDetour === 'detour' ? 'primary' : ''}
          >
            <Add /> Desvio
            </Button>
          <Button
            onClick={() => dispatch(setMapClickAddStopAction())}
            color={mapClickAddStopOrDetour === 'stop' ? 'primary' : ''}
          >
            <Add /> Parada
            </Button>
        </ButtonGroup>

        <div className={classes.root} style={{ marginTop: 10 }}>
          <WaypointsDragAndDrop
            currentWaypoints={currentWaypoints}
            setCurrentWaypoints={setCurrentWaypoints}
          />
        </div>
      </Grid>
    </>
  );
}