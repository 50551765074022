import storage from "redux-persist/lib/storage";

export const types = {
  CHANGE_SHOW_MODAL_VOICE: "CHANGE_SHOW_MODAL_VOICE",
  CHANGE_SHOW_VOICE_SIDEBAR: "CHANGE_SHOW_VOICE_SIDEBAR",
  SET_CALLS: "SET_CALLS",
  SET_CALL: "SET_CALL",
  SET_VOICES: "SET_VOICES",
  SET_VOICE: "SET_VOICE",
  SET_NEW_NOTIFICATION: "SET_NEW_NOTIFICATION",
  SET_REMOVE_NOTIFICATION: "SET_REMOVE_NOTIFICATION",
  SET_SEND_VOICE: "SET_SEND_VOICE",
};

const INITIAL_VALUES = {
  showModalVoice: false,
  showVoiceSidebar: false,
  calls: [],
  call: {},
  voices: [],
  voice: {},
  notifications: [],
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.CHANGE_SHOW_MODAL_VOICE:
      return { ...state, showModalVoice: !state.showModalVoice };
    case types.CHANGE_SHOW_VOICE_SIDEBAR:
      return { ...state, showVoiceSidebar: !state.showVoiceSidebar };
    case types.SET_CALLS:
      return { ...state, calls: action.calls };
    case types.SET_CALL:
      return { ...state, call: action.call };
    case types.SET_VOICES:
      return { ...state, voices: action.voices };
    case types.SET_VOICE:
      return { ...state, voice: action.voice };
    case types.SET_NEW_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.notifications],
      };
    case types.SET_REMOVE_NOTIFICATION:
      return { ...state, notifications: action.notifications };
    case types.SET_SEND_VOICE:
      return {
        ...state,
        voices: [
          ...state.voices,
          { ...action.voices, id: state.voices.at(-1)?.id + 1 },
        ],
      };
    default:
      return state;
  }
};

export const changeShowModalVoice = () => ({
  type: types.CHANGE_SHOW_MODAL_VOICE,
});

export const changeShowVoiceSidebar = () => ({
  type: types.CHANGE_SHOW_VOICE_SIDEBAR,
});

export const setCalls = (data) => ({
  type: types.SET_CALLS,
  calls: data,
});

export const setCall = (data) => ({
  type: types.SET_CALL,
  call: data,
});

export const setVoices = (data) => ({
  type: types.SET_VOICES,
  voices: data,
});

export const setVoice = (data) => ({
  type: types.SET_VOICE,
  voice: data,
});

export const setNewNotification = (data) => ({
  type: types.SET_NEW_NOTIFICATION,
  notifications: data,
});

export const setRemoveNotification = (data) => ({
  type: types.SET_REMOVE_NOTIFICATION,
  notifications: data,
});

export const setSendVoice = ({ sendBy, filename, url }) => ({
  type: types.SET_SEND_VOICE,
  voices: {
    sendBy,
    filename,
    url,
  },
});

//Persist Config
export const voicePersistConfig = {
  key: "voice",
  storage,
  //debug: true,
  whitelist: ["notifications"],
  timeout: null,
};
