import React, {useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import { Polygon } from "@react-google-maps/api";

const optionsPolygon = {
  fillColor: "yellow",
  fillOpacity: 0.2,
  strokeColor: "yellow",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const extractPolygonsFromVirtualFence = (virtualFenceGeojson) => {
  const polygons = [];

  switch(virtualFenceGeojson.type) {
    case 'Polygon':
      polygons.push(...virtualFenceGeojson.coordinates);
    break;

    case 'MultiPolygon':
      virtualFenceGeojson.coordinates.forEach(coordinates => {
        polygons.push(...coordinates);
      });
    break;

    default:
      polygons = [];
    break;
  }

  return polygons;
}

const VirtualFence = () => {
  const [deviceVitualFences, setDeviceVirtualFences] = useState([]);
  const deviceTransfers = useSelector((store) => store.monitoringMap.devices);
  const devicesToEnableVirtualFence = useSelector(store => store.monitoringMap.virtualFenceVisibility);

  const checkDeviceShouldShowVirtualFence = ({device}) => {
    return (
      device.device_setting !== null
      && device.device_setting.virtual_fence !== null
      && devicesToEnableVirtualFence.some(({id}) => id === device.id)
    );
  }

  const mapDeviceTransferToDeviceVitualFence = ({device}) => {
    let polygons = extractPolygonsFromVirtualFence(device.device_setting.virtual_fence);
    polygons = polygons.map(polygon => {
      return polygon.map(([lng, lat]) => ({ lat, lng }));
    });

    return {
      id: device.id,
      polygons
    }
  };

  useEffect(() => {
    const deviceVirtualFencesTmp = deviceTransfers
      .filter(checkDeviceShouldShowVirtualFence)
      .map(mapDeviceTransferToDeviceVitualFence);

    setDeviceVirtualFences(deviceVirtualFencesTmp);

  }, [deviceTransfers, devicesToEnableVirtualFence]);

  if (deviceVitualFences.length === 0) {
    return <></>;
  }

  return (
    <>
      {deviceVitualFences.map(deviceVitualFence => {
        return deviceVitualFence.polygons.map((coordinates, index) => (
          <Polygon
            key={`virtual-fence-${deviceVitualFence.id}-polygon-${index}`}
            paths={coordinates}
            options={optionsPolygon}
          />
        ))
      })}
    </>
  );
};

export default VirtualFence;
