import { put, call, all, takeLatest } from "redux-saga/effects";
import { getCustomerDevices } from "../../services/devices";
import { getOccurenceReport } from "../../services/eventAnalyticsApi/eventService";
import { Types as snackTypes } from "../ducks/snackbar";
import { types } from "../ducks/reportingData";
import { getTransfersWithDevicesUsingSaga } from "../../infra/http/transfersProvider";

function* getOccurenceReportData({ payload: filters }) {
  const { data, status } = yield call(getOccurenceReport, filters);

  if (status === 200) {
    yield put({ type: types.GET_OCCURENCES_REPORT_SUCCESS, payload: data });
  } else {
    yield all([
      put({
        type: snackTypes.OPEN_ERROR_SNACKBAR,
        payload: { message: "A requisição falhou, tente novamente" },
      }),
      put({
        type: types.GET_OCCURENCES_REPORT_FAILURE,
      }),
    ]);
  }
}

function* getDevicesApiData({ payload: params }) {
  const { data, status } = yield call(getCustomerDevices, params);
  if (status === 200) {
    yield put({
      type: types.GET_DEVICE_API_RESULT_SUCCESS,
      payload: data,
    });
  } else {
    yield all([
      put({
        type: snackTypes.OPEN_ERROR_SNACKBAR,
        payload: { message: "A requisição falhou, tente novamente" },
      }),
      put({
        type: types.GET_DEVICE_API_RESULT_FAILURE,
      }),
    ]);
  }
}

function* getTransfersWithDevicesData({ payload: params }) {
  const { data, status } = yield call(getTransfersWithDevicesUsingSaga, params);
  if (status === 200) {
    yield put({
      type: types.GET_TRANSFERS_WITH_DEVICES_SUCCESS,
      payload: data,
    });
  } else {
    yield all([
      put({
        type: snackTypes.OPEN_ERROR_SNACKBAR,
        payload: { message: "A requisição falhou, tente novamente" },
      }),
      put({
        type: types.GET_TRANSFERS_WITH_DEVICES_FAILURE,
      }),
    ]);
  }
}

export default function* reportData() {
  yield all([
    takeLatest(types.GET_OCCURENCES_REPORT, getOccurenceReportData),
    takeLatest(types.GET_DEVICE_API_RESULT, getDevicesApiData),
    takeLatest(types.GET_TRANSFERS_WITH_DEVICES, getTransfersWithDevicesData),
  ]);
}
