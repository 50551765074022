import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import * as devicesProvider from "../../../infra/http/devicesProvider";
import { toast } from "material-react-toastify";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Paper,
  Typography,
  Stepper,
  Step,
  InputAdornment,
  CircularProgress,
  StepLabel,
} from "@material-ui/core";
import {
  KeyboardDoubleArrowRightOutlined as DoubleArrowRightIcon,
  KeyboardDoubleArrowLeftOutlined as DoubleArrowLeftIcon,
  KeyboardArrowRightOutlined as ArrowRightIcon,
  KeyboardArrowLeftOutlined as ArrowLeftIcon,
  Search as SearchIcon,
  PermPhoneMsg as PhoneMsgIcon,
} from "@mui/icons-material";

import { CustomButtonSave, CustomTextField } from "../stylesNew";
import { Divider } from "@mui/material";
import {
  GridCustom,
  GridMain,
  StepperCustom,
  useStyles,
} from "./stepperStylesNew";
import { intersection, not } from "../../../commons/helperFunc";

function getSteps() {
  return ["Cadastro", "Roteirização", "Vinculação"];
}

export default function TransferList({ transferData, onClose }) {
  const customerData = useSelector((store) => store.auth.customer);
  const [activeStep] = useState(2);
  const steps = getSteps();
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  let leftChecked = intersection(checked, left);
  let rightChecked = intersection(checked, right);
  const [deviceFiltered, setDeviceFiltered] = useState("");
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [stateDevices, setStateDevices] = useState([]);

  useEffect(() => {
    async function printDevices() {
      const devices = await devicesProvider.getDevices({
        customerId: customerData?.customer,
      });

      const data = devices.map((device) => {
        if (device.contact === null || device.contact === "") {
          device.contact = "00000000000";

          return device;
        }
        return device;
      });

      setLeft(data);

      const associatedDevices = transferData.associated_devices
        ? transferData.associated_devices.map((device) => device.id)
        : transferData.pre_configured_transfer_settings.device_ids;

      const initialRight = associatedDevices?.map((deviceId) => {
        const data = devices.map((event) => {
          if (event.id === deviceId) {
            return event;
          } else return null;
        });

        return _.compact(data);
      });

      setStateDevices(associatedDevices);

      leftChecked = _.flattenDeep(initialRight);
      handleCheckedRightt(devices);
      setLoading(false);
    }
    printDevices().catch((error) =>
      console.log(`[stepperDevices] printDevices: '${error.message}'`)
    );
  }, []);

  const handleBond = () => {
    setSuccess(true);
    setDevicesToTransfer();
  };

  async function setDevicesToTransfer() {
    try {
      const devicesIds = [];
      const devicesContacts = [];

      if (stateDevices.length > 0) {
        if (right.length > 0) {
          left.forEach((device) => {
            for (let i = 0; i < stateDevices.length; i++) {
              if (device.id === stateDevices[i].id) {
                devicesIds[i] = device.id;
                if (device.contact === null || device.contact === "") {
                  devicesContacts[i] = "00000000000";
                } else {
                  devicesContacts[i] = device.contact;
                }
              }
            }
          });
        }
      }

      const dataToCreate = [];
      const dataIdPreConfigured = [];
      const dataToRemove = [];
      right.forEach((device) => {
        dataToCreate.push({
          device_id: device.id,
          transfer_id: transferData.id,
          transfer_route_id: transferData.route_active_id,
          device_type_id: device.device_type_id,
        });

        if (transferData.pre_configured_transfer_settings) {
          dataIdPreConfigured.push({
            pre_configured_transfer_settings_id:
              transferData.pre_configured_transfer_settings.id,
          });
        }

        dataToRemove.push({
          transfer_id: transferData.id,
          device_id: device.id,
        });
      });

      if (transferData.associated_devices) {
        try {
          await devicesProvider.deleteDevices(transferData.id, dataToRemove);
        } catch (err) {
          toast.error("Erro ao editar vínculos");
        }

        if (dataToCreate.length > 0) {
          try {
            await devicesProvider.createDevices(dataToCreate);
          } catch (err) {
            toast.error("Erro ao vincular dispositivo");
          }
        }
      } else {
        try {
          await devicesProvider.deleteDevicesPreConfigured(
            transferData.pre_configured_transfer_settings.id,
            transferData.pre_configured_transfer_settings.device_ids
          );
        } catch (err) {
          toast.error("Erro ao editar vínculos");
        }

        if (dataIdPreConfigured.length > 0) {
          try {
            const devices = right.map((device) => ({
              device_id: device.id,
              transfer_id: transferData.id,
              transfer_route_id: transferData.route_active_id,
              device_type_id: device.device_type_id,
            }));
            await devicesProvider.createDevicesPreConfigured(
              transferData.pre_configured_transfer_settings.id,
              devices
            );
          } catch (err) {
            toast.error("Erro ao vincular dispositivo");
          }
        }
      }
    } catch (err) {
      toast.error("Erro ao vincular!");
    } finally {
      setSuccess(false);
      if (onClose) {
        onClose();
      }
    }
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  const handleCheckedRightt = (e) => {
    setRight(right.concat(leftChecked));

    setLeft(not(e, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  function formatContact(contact) {
    let text = contact;
    const ddd = text.slice(0, 2);
    const parte1 = text.slice(2, 7);
    const parte2 = text.slice(7, 11);
    text = `(${ddd})${parte1}-${parte2}`;
    return text;
  }

  const customList = (items) => (
    <List component="div" role="list">
      {items
        .filter((device) => {
          const deviceFilteredLowerCase = deviceFiltered.toLowerCase();

          const deviceIdentifierChecking = device.identifier
            .toLowerCase()
            .includes(deviceFilteredLowerCase);
          if (device.alias !== null) {
            const deviceAliasChecking = device.alias
              .toLowerCase()
              .includes(deviceFilteredLowerCase);
            return deviceAliasChecking || deviceIdentifierChecking;
          }

          return deviceIdentifierChecking;
        })
        .map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
              style={{ padding: 0 }}
            >
              <ListItemIcon>
                <Checkbox
                  style={{ color: "#2A93A7" }}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>

              <ListItemText
                style={{ width: "100%" }}
                id={labelId}
                primary={
                  <div style={{ fontSize: 11 }}>
                    {value.alias || value.identifier}
                  </div>
                }
                secondary={
                  <div style={{ fontSize: 10 }}>
                    <PhoneMsgIcon style={{ color: "#333333", width: 12 }} />
                    {formatContact(value.contact)}
                  </div>
                }
              ></ListItemText>
            </ListItem>
          );
        })}
      <ListItem />
    </List>
  );

  return (
    <>
      <div id="stepper">
        <Divider style={{ margin: 0 }} />
        <StepperCustom
          activeStep={activeStep}
          connector={<ArrowRightIcon style={{ color: "#C2C2C2" }} />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                <Typography
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </StepperCustom>

        <Divider />

        <div style={{ margin: "5px 0px 5px 0px" }}>
          <CustomTextField
            style={{ marginTop: 0 }}
            className="search-devices"
            fullWidth
            onChange={(e) => setDeviceFiltered(e.target.value)}
            label="Buscar Vínculo"
            type="search"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      {!loading && !success ? (
        <>
          <Grid style={{ display: "flex" }}>
            <Paper elevation={2} className={classes.paper}>
              <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
                Disponível
              </Typography>{" "}
              {customList(left)}
            </Paper>
            <GridMain item>
              <GridCustom container>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label="Mover todos para direita"
                >
                  <DoubleArrowRightIcon />
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="mover selecionado para direita"
                >
                  <ArrowRightIcon />
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="mover selecionado para esquerda"
                >
                  <ArrowLeftIcon />
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label="Mover todos para esquerda"
                >
                  <DoubleArrowLeftIcon />
                </Button>
              </GridCustom>
            </GridMain>

            <Paper elevation={2} className={classes.paper}>
              <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
                Vinculado
              </Typography>
              {customList(right)}
            </Paper>
          </Grid>
        </>
      ) : success === false && loading === true ? (
        <>
          <center>
            <div>
              <CircularProgress
                size={68}
                style={{ marginTop: 0, color: "#63a9ef" }}
              ></CircularProgress>
            </div>
            <span>Carregando dados</span>
          </center>
        </>
      ) : (
        <>
          <center>
            <div>
              <CircularProgress
                size={68}
                style={{ marginTop: 0, color: "#63a9ef" }}
              ></CircularProgress>
            </div>
            <span>Vinculando dispositivo</span>
          </center>
        </>
      )}
      <CustomButtonSave
        style={{ marginTop: "5px" }}
        autoFocus
        onClick={handleBond}
        variant="outlined"
        color="primary"
      >
        Salvar
      </CustomButtonSave>
    </>
  );
}
