export const typesCustomerUnits = {
  CUSTOMER_UNITS: "CUSTOMER_UNITS",
};

const INITIAL_VALUES_CUSTOMER_UNITS = {};

export const customerUnits = (
  state = INITIAL_VALUES_CUSTOMER_UNITS,
  action
) => {
  if (action.type == typesCustomerUnits.CUSTOMER_UNITS) {
    const units = {};
    action.payload.map((unit) => {
      units[unit.id] = unit.name;
    });
    return units;
  } else {
    return { ...state };
  }
};

//actions
export const fillCustomerUnitsAction = (customerUnits) => ({
  type: typesCustomerUnits.CUSTOMER_UNITS,
  payload: customerUnits,
});
