import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { ListItem, Divider } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

import { useStyles } from "./styles";
import { format, parseISO } from "date-fns";

export default function ChatItem({ call, action }) {
  const classes = useStyles();

  const notifications = useSelector((store) => store.voice.notifications);

  return (
    <div className={classes.divChatItem} onClick={action}>
      <ListItem
        role="listitem"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <div className={classes.infosChatItem}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <strong>
              {call.call_transfer_id
                ? `Transfer: ${call.call_transfer_id}`
                : `${call.user?.name}`}
            </strong>
            <div className={classes.pushChatItem}>
              <strong>
                {
                  notifications.filter(
                    (notification) => notification.callId == call.call_id
                  ).length
                }
              </strong>
            </div>
          </div>
          <p>
            {call.call_started_at
              ? format(parseISO(call.call_started_at), "dd/MM/yyyy")
              : ""}
          </p>
        </div>

        <ChevronRight />
      </ListItem>
      <Divider />
    </div>
  );
}
