import React, { useState } from 'react'

import colors from '../../commons/colors'
import ConfirmDialog from '../ConfirmDialog'
import DeleteButton from './DeleteButton'
import * as utils from '../../commons/utils'

const DeleteDialog = ({
  buttonTooltip = 'Delete Item',
  title = 'Delete Item',
  confirmText = 'Delete',
  cancelText = 'Cancel',
  disabled = false,
  content = [],
  deleteHandler: deleteHandlerExternal = () => { },
  deleteErrorHandler: deleteErrorHandlerExternal = () => { },
}) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  const deleteHandler = async () => {
    try {
      await utils.callAsyncOrNot(deleteHandlerExternal, handleDialogClose)
      handleDialogClose()
    }
    catch (error) {
      deleteErrorHandlerExternal()
    }
  }

  return (
    <div>
      <DeleteButton
        disabled={disabled}
        buttonTooltip={buttonTooltip}
        handleClickOpen={handleClickOpen}
      />
      <ConfirmDialog
        title={title}
        modalOpen={open}
        closeModal={handleDialogClose}
        handleSubmit={deleteHandler}

        confirmBackgroundColor={colors.red}
        confirmText={confirmText}
        cancelText={cancelText}

        content={content}
      />
    </div>
  )
}

export default DeleteDialog