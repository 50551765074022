import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DirectionsService, Marker, Polyline } from "@react-google-maps/api";
import decodePolyline from "decode-google-map-polyline";

import Icon_Azul from "../../../../assets/newScripting/Icon_Azul.svg";
import Icon_Azul2 from "../../../../assets/newScripting/Icon_Azul2.svg";
import Icon_Vermelho from "../../../../assets/newScripting/Icon_Vermelho.svg";

import {
  finishLoadingDirectionsAction,
  setDirectionsAction,
  startLoadingDirectionsAction,
} from "../../../../store/ducks/scripting";
import { letters } from "../../../../commons/utils/text";
import { toast } from "material-react-toastify";

export const Directions = () => {
  const currentTransfer = useSelector(
    (state) => state.transfer.currentTransfer
  );
  const waypoints = useSelector((state) => state.scripting.waypoints);
  const directions = useSelector((state) => state.scripting.directions);
  const canRenderDirectionsService = useSelector(
    (state) => state.scripting.canRenderDirectionsService
  );

  const [allCoordinates, setAllCoordinates] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startLoadingDirectionsAction());

    return () => {
      dispatch(setDirectionsAction(null));
    };
  }, [waypoints, currentTransfer]);

  useEffect(() => {
    const decodedCoordinates = decodePolyline(
      directions?.routes[0]?.overview_polyline ?? []
    );

    setAllCoordinates(decodedCoordinates);
  }, [directions]);

  const directionsServiceCallback = useCallback(
    (result, status) => {
      if (!canRenderDirectionsService) return;

      if (status === "OK") {
        dispatch(finishLoadingDirectionsAction());
        dispatch(setDirectionsAction(result));
      } else {
        toast.error(`Erro: ${result}`);
      }
    },
    [canRenderDirectionsService]
  );

  const orderLastCoord = (order) => {
    const customLabel = {
      text: order,
      color: "#ffffff",
      fontSize: "16px",
      fontWeight: "lighter",
    };

    if (waypoints.length > 0) {
      const orderer = letters[waypoints.length + 1];
      customLabel.text = orderer.toUpperCase();
    }

    return customLabel;
  };

  const orderWaypoint = (index) => {
    const customLabel = {
      text: letters[index + 1].toUpperCase(),
      color: "#ffffff",
      fontSize: "16px",
      fontWeight: "lighter",
    };

    return customLabel;
  };

  const renderMarker = (coord) => {
    const firstCoord = coord[0];
    const lastCoord = coord[coord.length - 1];

    return (
      <>
        <Marker
          position={{
            lat: firstCoord?.lat,
            lng: firstCoord?.lng,
          }}
          icon={{
            url: Icon_Azul2,
            scaledSize: new window.google.maps.Size(28, 35),
            labelOrigin: { x: 14, y: 16 },
          }}
          label={{
            text: "A",
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: "lighter",
          }}
        />
        <Marker
          position={{
            lat: lastCoord?.lat,
            lng: lastCoord?.lng,
          }}
          icon={{
            url: Icon_Azul2,
            scaledSize: new window.google.maps.Size(28, 35),
            labelOrigin: { x: 14, y: 16 },
          }}
          label={orderLastCoord("B")}
        />
      </>
    );
  };

  return (
    <>
      <DirectionsService
        callback={(result, status) => directionsServiceCallback(result, status)}
        options={{
          origin: currentTransfer.from,
          destination: currentTransfer.to,
          travelMode: "DRIVING",
          waypoints: waypoints.map((waypoint) => ({
            location: waypoint.location,
          })),
        }}
      />

      {renderMarker(allCoordinates)}

      {waypoints.map((waypoint, index) => (
        <Marker
          key={index}
          position={{
            lat: waypoint.position.latitude,
            lng: waypoint.position.longitude,
          }}
          icon={{
            url: waypoint.isStop ? Icon_Azul : Icon_Vermelho,
            scaledSize: new window.google.maps.Size(28, 35),
            labelOrigin: { x: 14, y: 16 },
          }}
          label={orderWaypoint(index)}
        />
      ))}

      <Polyline
        path={allCoordinates}
        options={{ strokeWeight: 4, strokeColor: "#06B0CF" }}
      />
    </>
  );
};
