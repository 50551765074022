import React, { useCallback, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import {
  Grid,
  TextField,
  Divider,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import * as dateFns from "date-fns";

import { ExpansionOptions } from "../ExpansionOptions";
import { EditFromOrToModal } from "../EditFromOrToModal";
import {
  setCurrentTransferEditableInfo,
  setRetrievedFilteredOccurrencesAction,
  setCreatedTransfer,
  setCanBindDevice,
} from "../../../../store/ducks/scripting";
import * as occurrencesService from "../../../../services/occurrences";
import * as scriptingService from "../../../../services/scripting";
import * as utilsCommons from "../../../../commons/utils";
import { useHistory } from "react-router-dom";
import { setWaypointsAction } from "../../../../store/ducks/scripting";
import styles from "./styles";
import { setCurrentTransferId } from "../../../../store/ducks/transfer";

export const DefaultSidebarContent = () => {
  const currentTransfer = useSelector(
    (state) => state.transfer.currentTransfer
  );
  const preConfiguredId = useSelector(
    (state) => state.transfer.transferPreConfiguredId
  );
  const directions = useSelector((state) => state.scripting.directions);
  const transfer = useSelector((state) => state.transfer.currentTransfer);
  const currentTransferEditableInfo = useSelector(
    (state) => state.scripting.currentTransferEditableInfo
  );

  const incidentsNatures = useSelector(
    (state) => state.scripting.incidentsNatures
  );
  const incidentsRetrievedAndChecked = useSelector(
    (state) => state.scripting.securityAlerts
  );
  const displayedOccurrences = useSelector(
    (state) => state.scripting.displayedOccurrences
  );
  const retrievedFilteredOccurrences = useSelector(
    (state) => state.scripting.retrievedFilteredOccurrences
  );
  const waypoints = useSelector((state) => state.scripting.waypoints);
  const riskLayers = useSelector((state) => state.scripting.riskLayers);
  const activeRiskLayers = useSelector(
    (store) => store.scripting.activeRiskLayers
  );
  const [unity, setUnity] = useState(
    (currentTransfer && currentTransfer.unity) || ""
  );
  const [responsible, setResponsible] = useState(
    (currentTransfer && currentTransfer.responsible) || ""
  );
  const [name, setName] = useState(
    (currentTransfer && currentTransfer.name) || ""
  );
  const [scheduledDate, setScheduledDate] = useState(
    (currentTransfer && currentTransfer.scheduled_date) || ""
  );
  const [updateLocationModalIsOpen, setUpdateLocationModalIsOpen] =
    useState(false);
  const [locationValue, setLocationValue] = useState("");
  const [locationType, setLocationType] = useState("");
  const [canSubmitScripting, setCanSubmitScripting] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const firstAlertsSearchDone = useRef(false);

  const today = new Date();
  const twoMonthsAgo = today.setMonth(today.getMonth() - 2);

  const defaultAfterValueRef = useRef(
    dateFns.format(twoMonthsAgo, "yyyy-MM-dd")
  );
  const DEFAULT_RADIUS = 500;

  const boolZeroDistanceRoute =
    directions &&
    directions.geocoded_waypoints.length === 2 &&
    directions.geocoded_waypoints[0].place_id ===
      directions.geocoded_waypoints[1].place_id;

  const [radius, setRadius] = useState(DEFAULT_RADIUS);
  const [afterDate, setAfterDate] = useState(defaultAfterValueRef.current);

  const dispatchRadius = () => {
    dispatch(
      setCurrentTransferEditableInfo({
        ...currentTransferEditableInfo,
        radius,
      })
    );
  };

  const dispatchAfterDate = () => {
    if (!afterDate) return;
    dispatch(
      setCurrentTransferEditableInfo({
        ...currentTransferEditableInfo,
        afterDate,
      })
    );
  };

  useEffect(() => {
    if (!currentTransferEditableInfo.afterDate)
      currentTransferEditableInfo.afterDate = defaultAfterValueRef.current;

    if (!currentTransferEditableInfo.radius)
      currentTransferEditableInfo.radius = DEFAULT_RADIUS;

    dispatch(setCurrentTransferEditableInfo(currentTransferEditableInfo));
  }, []);

  useEffect(() => {
    if (firstAlertsSearchDone.current) return;
    if (displayedOccurrences === null || directions === null) return;
    if (Object.keys(currentTransferEditableInfo).length === 0) return;

    getSecurityAlerts();
    firstAlertsSearchDone.current = true;
  }, [displayedOccurrences, directions, currentTransferEditableInfo]);

  useEffect(() => {
    if (boolZeroDistanceRoute) {
      setCanSubmitScripting("disabled");
      return;
    }

    const checkers = {
      displayedOccurrences: () =>
        displayedOccurrences !== null
          ? true
          : "Submit Scripting button: displayedOccurrences is null...",
      retrievedFilteredOccurrences: () =>
        retrievedFilteredOccurrences !== null
          ? true
          : "Submit Scripting button: retrievedFilteredOccurrences is null...",
      directions: () =>
        directions !== null
          ? true
          : "Submit Scripting button: directions is null...",
      currentTransfer: () =>
        currentTransfer !== null
          ? true
          : "Submit Scripting button: currentTransfer is null...",
      afterDate: () =>
        currentTransferEditableInfo.hasOwnProperty("afterDate")
          ? true
          : "Submit Scripting button: afterDate is not defined...",
      radius: () =>
        currentTransferEditableInfo.hasOwnProperty("radius")
          ? true
          : "Submit Scripting button: radius is not defined...",
    };
    let hasErrors = false;
    for (const checkerFunction of Object.values(checkers)) {
      const errorOrTrue = checkerFunction();
      if (errorOrTrue === true) continue;
      hasErrors = true;
    }

    if (!hasErrors) setCanSubmitScripting("enabled");
    else setCanSubmitScripting("loading");
  }, [
    displayedOccurrences,
    retrievedFilteredOccurrences,
    directions,
    currentTransfer,
    currentTransferEditableInfo,
  ]);

  const getSecurityAlerts = async () => {
    if (boolZeroDistanceRoute) return;

    try {
      setCanSubmitScripting("loading");
      await getAndSetOccurrences(
        directions,
        currentTransferEditableInfo.radius,
        currentTransferEditableInfo.afterDate
      );
      setCanSubmitScripting("enabled");
    } catch (err) {
      setCanSubmitScripting("enabled");
      console.log("getSecurityAlerts", err);
      if (err.response) {
        return toast.error(err.response.data.message);
      } else {
        return toast.error("Houve algum erro inesperado.");
      }
    }
  };

  const handleGetSecurityAlerts = async (e) => {
    e.preventDefault();
    getSecurityAlerts();
    dispatchAfterDate();
    dispatchRadius();
  };

  const getAndSetOccurrences = useCallback(
    async (directions, radius, afterDate) => {
      if (!directions?.routes[0]?.overview_polyline)
        throw new Error(
          "getAndSetOccurrences: overview_polyline not available..."
        );
      if (!afterDate || !radius)
        throw new Error(
          "getAndSetOccurrences: afterDate and/or radius not available..."
        );
      if (displayedOccurrences === null)
        throw new Error(
          "getAndSetOccurrences: displayedOccurrences not available..."
        );

      let registeredOccurrences = null;
      try {
        registeredOccurrences = await occurrencesService.getOccurrences({
          after_date: afterDate,
          from: currentTransfer.from,
          to: currentTransfer.to,
          radius: radius,
          linestring: directions.routes[0].overview_polyline,
        });
      } catch (error) {
        throw error;
      }

      const filteredRegisteredOccurrences = registeredOccurrences.filter(
        (registeredOccurrence) =>
          displayedOccurrences.subCategories[
            registeredOccurrence.clippingTypeId
          ]
      );

      dispatch(
        setRetrievedFilteredOccurrencesAction(filteredRegisteredOccurrences)
      );
    }
  );

  const handleFinishScripting = async () => {
    setCanSubmitScripting("loading");
    const incidentCategoriesChecked = {};
    for (const incidentsNature of incidentsNatures)
      incidentCategoriesChecked[incidentsNature.id] = incidentsNature.selected;

    const incidentSubCategoriesChecked = {};
    for (const incidentNature of incidentsNatures) {
      for (const incidentType of incidentNature.types)
        incidentSubCategoriesChecked[incidentType?.id] = incidentType?.selected;
    }

    const occurrencesCategoriesChecked = {};
    for (const [occurrenceId, occurrenceInfo] of Object.entries(
      displayedOccurrences.categories
    ))
      occurrencesCategoriesChecked[occurrenceId] =
        occurrenceInfo?.allItemsSelected === "checked";

    const occurrencesSubCategorories = displayedOccurrences?.subCategories;

    const estimatedTime = getTotalStepsDuration(directions);

    const waypointsFormatted = waypoints.map((waypoint, index) => {
      const {
        location,
        duration,
        stopover,
        title,
        position,
        ...waypointsExtrasRemoved
      } = waypoint;

      return {
        ...waypointsExtrasRemoved,
        order: index + 1,
        address: location,
        stopping_time: utilsCommons.representsInteger(duration)
          ? Number(duration)
          : null,
        type: stopover ? "STOP_POINT" : "DETOUR",
        name: title,
        position: {
          lat: position.latitude,
          lng: position.longitude,
        },
      };
    });

    const transfer = {
      transferId: currentTransfer.id,
      transferSettingId: currentTransfer.transfer_setting_id,
      incidents: incidentsRetrievedAndChecked,
      occurrences: retrievedFilteredOccurrences,
      riskLayers: riskLayers.filter((item) =>
        activeRiskLayers.includes(item.riskZone.typeId)
      ),
      to: currentTransfer.to,
      from: currentTransfer.from,
      steps: directions,
      estimatedTime: estimatedTime,
      filters: {
        dateFrom: currentTransferEditableInfo.afterDate,
        radius: currentTransferEditableInfo.radius,
        incidentsNature: incidentCategoriesChecked,
        incidentsTypes: incidentSubCategoriesChecked,
        occurrencesNature: occurrencesCategoriesChecked,
        occurrencesTypes: occurrencesSubCategorories,
      },
      order: 1,
      waypoints: waypointsFormatted,
    };

    try {
      await scriptingService.submitScripting(transfer);
      dispatch(setCreatedTransfer(transfer));
      dispatch(setWaypointsAction([]));
      dispatch(setCanBindDevice(true));
      setCanSubmitScripting("enabled");
      dispatch(setCurrentTransferId(transfer.transferId));
    } catch (error) {
      console.log("Could not submit scripting...", error);
      setCanSubmitScripting("enabled");
      return;
    }

    const transferIsConfigured = preConfiguredId.some(
      (idCurrent) => idCurrent === currentTransfer.id
    );

    if (transferIsConfigured) {
      history.push("/preconfigured");
    } else {
      history.push("/transfers");
    }
  };

  const handlePrint = () => {
    try {
      const route = JSON.parse(JSON.stringify(directions.routes[0]));

      history.push("/scripting/pdf", {
        endAddress: transfer.to,
        startAddress: transfer.from,
        event: transfer.name,
        route,
        waypoints,
      });
    } catch (error) {
      alert("Erro ao gerar PDF");
    }
  };

  return (
    <div>
      <EditFromOrToModal
        isOpen={updateLocationModalIsOpen}
        setIsOpen={setUpdateLocationModalIsOpen}
        locationValue={locationValue}
        setLocationValue={setLocationValue}
        locationType={locationType}
        setLocationType={setLocationType}
      />

      <Grid
        style={{ padding: 5 }}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <TextField
          margin="dense"
          id="outlined-basic"
          type="search"
          label="Unidade"
          variant="outlined"
          style={{ margin: 7 }}
          value={unity || "Sem unidade"}
          onChange={(e) => setUnity(e.target.value)}
          fullWidth
        />

        <TextField
          margin="dense"
          id="outlined-basic"
          type="search"
          label="Responsável"
          variant="outlined"
          style={{ margin: 7 }}
          value={responsible || "Sem responsável"}
          onChange={(e) => setResponsible(e.target.value)}
          fullWidth
        />

        <TextField
          margin="dense"
          id="outlined-basic"
          type="search"
          label="Evento"
          variant="outlined"
          style={{ margin: 7 }}
          value={name}
          fullWidth
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          margin="dense"
          variant="outlined"
          id="datetime-scripting"
          label="Data e Hora"
          type="datetime-local"
          style={{ margin: 7 }}
          InputLabelProps={{ shrink: true }}
          value={scheduledDate}
          fullWidth
          onChange={(e) => setScheduledDate(e.target.valueAsDate)}
        />

        <div style={{ display: "flex", width: "100%" }}>
          <TextField
            margin="dense"
            id="outlined-basic"
            type="search"
            label="Local de Origem"
            variant="outlined"
            style={{ margin: 7 }}
            value={(currentTransfer && currentTransfer.from) || ""}
            disabled
            fullWidth
          />
          <IconButton
            size="small"
            onClick={() => {
              setLocationValue(currentTransfer.from);
              setLocationType("from");
              setUpdateLocationModalIsOpen(true);
            }}
          >
            <EditOutlined />
          </IconButton>
        </div>

        <div style={{ display: "flex", width: "100%" }}>
          <TextField
            margin="dense"
            id="outlined-basic"
            type="search"
            label="Local de Destino"
            variant="outlined"
            style={{ margin: 7 }}
            value={(currentTransfer && currentTransfer.to) || ""}
            disabled
            fullWidth
          />
          <IconButton
            size="small"
            onClick={() => {
              setLocationValue(currentTransfer.to);
              setLocationType("to");
              setUpdateLocationModalIsOpen(true);
            }}
          >
            <EditOutlined />
          </IconButton>
        </div>

        <TextField
          margin="dense"
          placeholder="KM"
          label="Raio de Busca"
          id="outlined-basic"
          type="search"
          variant="outlined"
          style={{ margin: 7 }}
          fullWidth
          value={radius}
          onChange={(event) => setRadius(event.target.value)}
        />

        <TextField
          margin="dense"
          variant="outlined"
          id="datetime-scripting"
          label="A partir de"
          type="date"
          style={{ margin: 7 }}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setAfterDate(e.target.value)}
          defaultValue={afterDate}
          fullWidth
        />

        <Button
          style={styles.buttonStyle}
          color="primary"
          fullWidth
          onClick={handleGetSecurityAlerts}
        >
          {canSubmitScripting !== "loading" ? (
            "Atualizar dados"
          ) : (
            <CircularProgress size={24} color="white" />
          )}
        </Button>

        <Button
          style={
            canSubmitScripting === "enabled"
              ? styles.buttonStyle
              : styles.disabledButtonStyle
          }
          color="primary"
          fullWidth
          onClick={handlePrint}
          disabled={canSubmitScripting !== "enabled"}
        >
          {canSubmitScripting !== "loading" ? (
            "Gerar relatório"
          ) : (
            <CircularProgress size={24} color="white" />
          )}
        </Button>

        <Button
          style={
            canSubmitScripting === "enabled"
              ? styles.buttonStyle
              : styles.disabledButtonStyle
          }
          color="primary"
          fullWidth
          onClick={handleFinishScripting}
          disabled={canSubmitScripting !== "enabled"}
        >
          {canSubmitScripting !== "loading" ? (
            "Finalizar roteirização"
          ) : (
            <CircularProgress size={24} color="white" />
          )}
        </Button>
      </Grid>

      <Divider />

      <ExpansionOptions />
    </div>
  );
};

export const getTotalStepsDuration = (steps) => {
  let seconds = 0;

  for (const leg of steps.routes[0].legs) {
    for (const step of leg.steps) {
      seconds += step?.duration?.value || 0;
    }
  }

  return seconds;
};
