import React from "react";

import { Autocomplete } from "@mui/material";
import { CustomTextFieldDrivers } from "./styles";

export const InputSelectorDriver = ({
  placeholder,
  options = [],
  defaultValue = "",
  getOptionLabel = (option) => option?.name,
  onChange = () => {},
  disable,
}) => {
  return (
    <Autocomplete
      disabled={disable}
      options={options}
      inputValue={defaultValue}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      renderInput={(params) => (
        <CustomTextFieldDrivers
          {...params}
          fullWidth
          label="Motorista"
          size="small"
          type="text"
          placeholder={placeholder}
          variant="outlined"
        />
      )}
    />
  );
};
