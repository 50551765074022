import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { mapsOptions, mapStyle } from "./constants/mapsOptions";
import { useOccurrences } from "./hooks/useOccurrences";
import { useMapClick } from "./hooks/useMapClick";
import { RightClickInfoWindow } from "./RightClickInfoWindow";
import { RiskZonesPolygon } from "./RiskZonesPolygon";
import { SecurityAlerts } from "./SecurityAlerts";
import { Directions } from "./Directions";
import ChangeMapType from "../../../components/Map/changeMapType";
import {
  openMapStyleDrawerAction,
  changeMapStylesAction,
} from "../../../store/ducks/scripting";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import {
  getCustomerPointsOfInterest,
  getCustomerPointsOfInterestTypes,
} from "../../../services/areasService";
import {
  addCustomerPointsOfInterest,
  addCustomerPointsOfInterestTypes,
} from "../../../store/ducks/areas";
import CustomerInterestPoints from "../../MonitoringMap/CustomerInterestPoints/customerInterestPoints";
import { useGoogleMapsKey } from "../../../hooks/useGoogleMapsKey";

const libraries = ["places", "drawing", "geometry"];

export const ScriptingMap = () => {
  const googleMapsKey = useGoogleMapsKey();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries,
  });

  const dispatch = useDispatch();
  const [center] = useState({ lat: -22.912048, lng: -43.173955 });
  const mapClickAddStopOrDetour = useSelector(
    (store) => store.scripting.mapClickAddStopOrDetour
  );
  const mapType = useSelector((store) => store.scripting.mapType);
  const customerId = useSelector((store) => store.auth.customer.customer);
  const customerPointOfInterest = useSelector(
    (store) => store.areas.customerPointsOfInterest
  );
  const customerPointOfInterestTypes = useSelector(
    (store) => store.areas.customerPointOfInterestTypes
  );

  const {
    currentInfoWindow,
    setCurrentInfoWindow,
    handleMapClick,
    handleMapRightClick,
  } = useMapClick();
  const { renderOccurrences } = useOccurrences();

  useEffect(() => {
    const searchCustomerPointsOfInterest = async () => {
      const customerPointsOfInterest = await getCustomerPointsOfInterest(
        customerId
      );
      dispatch(addCustomerPointsOfInterest(customerPointsOfInterest));
    };

    const searchCustomerPointsOfInterestTypes = async () => {
      const customerPointsOfInterestTypes =
        await getCustomerPointsOfInterestTypes();
      dispatch(addCustomerPointsOfInterestTypes(customerPointsOfInterestTypes));
    };

    searchCustomerPointsOfInterestTypes();
    searchCustomerPointsOfInterest();
  }, []);

  const handleOpenMapType = () => {
    dispatch(openMapStyleDrawerAction());
  };

  const handleClickChange = (mapType) => {
    dispatch(changeMapStylesAction(mapType));
    dispatch(openMapStyleDrawerAction());
  };

  const draggableCursor =
    mapClickAddStopOrDetour !== "" ? "crosshair" : "pointer";
  const options = { ...mapsOptions(mapType), draggableCursor };

  if (!isLoaded) {
    return <></>;
  }

  return (
    <GoogleMap
      id="scripting-map"
      zoom={12}
      center={center}
      mapContainerStyle={mapStyle}
      options={options}
      onClick={handleMapClick}
      onRightClick={handleMapRightClick}
    >
      <Directions />
      <RiskZonesPolygon />
      {renderOccurrences()}
      <ChangeMapType onMapTypeChange={handleClickChange} />
      <Button
        style={{
          height: "25px",
          position: "absolute",
          right: "0px",
          bottom: "100px",
          fontSize: "9px",
          backgroundColor: "#ffffff",
        }}
        variant="outlined"
        size="small"
        onClick={handleOpenMapType}
      >
        <strong>Alterar mapa</strong>
      </Button>

      <RightClickInfoWindow
        currentInfoWindow={currentInfoWindow}
        setCurrentInfoWindow={setCurrentInfoWindow}
      />
      <SecurityAlerts />
      {customerPointOfInterest.map((point) =>
        customerPointOfInterestTypes.map(
          (type) =>
            type.selected &&
            type.id == point.type.id && (
              <CustomerInterestPoints customerPointOfInterest={point} />
            )
        )
      )}
    </GoogleMap>
  );
};
