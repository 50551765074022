export default (() => ({
  selectedDatesContainer: {
    marginTop: 0,
  },

  selectedDateContainer: {
    width: 130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 10,
    borderRadius: 10,
    backgroundColor: '#E0E0E0'
  },

  selectedDateLabel: {
    marginRight: 10,
  },

  removeIconContainer: {
    cursor: 'pointer',
    borderRadius: '100%',
    display: 'flex',
    alingItems: 'center',
    padding: 5,
    '&:hover': {
      backgroundColor: '#CCCCCC'
    }
  },

  removeIcon: {
    color: '#FF0000'
  }
}));
