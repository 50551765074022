import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { handleEditProduct } from "../../../services/customerAreaManagement/product";

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

const EditDialog = ({ open, close, reloadTable, data }) => {
  const [unitName, setUnitName] = useState("");

  const handleSetUnitName = (name) => {
    setUnitName(name);
  };

  const EditProduct = async () => {
    await handleEditProduct(data.customer_id, data.id, unitName);
    await reloadTable();
    close();
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      close={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Editar Produto</DialogTitle>
      <DialogContent>
        <TextField
          onChange={(e) => handleSetUnitName(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={EditProduct} color="primary">
          Editar
        </Button>
        <Button onClick={close} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
