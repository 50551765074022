import styled from "styled-components";

export const Div = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .header {
    padding: 24px 40px 0;
    box-shadow: 0px 3px 6px #0000001a;
  }
  .pageTitle {
    font-weight: 600;
    color: #15172f;
  }
  .Mui-selected {
    font-weight: bolder;
  }
  .tabsContainer {
    margin-top: 50;
  }
  .tabsBorderBottomColor {
    background-color: #06b0cf;
  }
  .tabTitle {
    text-transform: none;
  }
  .reportTableContainer {
    padding: 40px;
    flex: 1;
  }
  .report-form {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px;
  }
  .padding-tabs {
    padding: 20px;
  }
  .iconConfig {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 200;
    color: #15172f;
  }
`;
