import { lineString } from "@turf/helpers";
import length from "@turf/length";

export const mapFlespiMessageToTurfCoordinates = (flespiMessage) => {
  return [
    flespiMessage.last_status.longitude,
    flespiMessage.last_status.latitude,
  ];
}

/**
 * @param {*} flespiMessages
 * @param {"meters" | "millimeters" | "centimeters" | "kilometers" | "acres" | "miles" | "nauticalmiles" | "inches" | "yards" | "feet" | "radians" | "degrees" | "hectares"} unitOfMeasurement unidades de medidas que o turf oferece
 * @returns {number} distancia percorrida com base na unidade de medida informada
 */
export const getDistanceTraveled = (flespiMessages, unitOfMeasurement) => {
  const coordinates = flespiMessages.map(mapFlespiMessageToTurfCoordinates);

  if (coordinates.length > 1) {
    const devicePath = lineString(coordinates);
    return length(devicePath, {
      units: unitOfMeasurement
    });
  }

  return 0;
}
