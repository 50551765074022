export default () => ({
  infoWindowStyle: {
    backgroundColor: "white",
    color: "#212121",
    display: "flex",
    flexDirection: "column",
  },
  infoWindowBox: { alignSelf: "center", marginRight: "10px" },
  infoWindowItem: { alignSelf: "normal" },
  infoWindowTextTitle: {
    "& span": {
      fontSize: "14px",
      fontWeight: "600",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "black",
    },
  },
  infoWindowText: {
    "& span": {
      fontSize: "12px",
      fontWeight: "400",
    },
  },
});
