import React, { useEffect, useState } from "react";
import { MainContainer } from "./styles";
import { Select, Typography, TextField, MenuItem } from "@material-ui/core/";
import { getDeviceIcons } from "../../../../../../infra/http/devicesProvider";
import {
  aliasField,
  deviceIconField,
} from "../../../../../../store/ducks/deviceManagement";
import { useDispatch, useSelector } from "react-redux";

const EditDeviceTab = () => {
  const alias = useSelector((store) => store.deviceMangement.alias);
  const selectedDeviceIcon = useSelector(
    (store) => store.deviceMangement.deviceIconId
  );
  const [deviceIconDefined, setDeviceIconDefined] = useState(0);
  const [aliasDefined, setAliasDefined] = useState();
  const [deviceIcons, setDeviceIcons] = useState([]);
  const dispatch = useDispatch();

  const handleAlias = (e) => {
    dispatch(aliasField(e.target.value));
  };

  const handleDeviceIconId = (value) => {
    dispatch(deviceIconField(value));
  };

  useEffect(() => {
    const getAllIcons = async () => {
      let apiResponse = await getDeviceIcons();
      setDeviceIcons(apiResponse);
    };
    getAllIcons();
  }, []);

  useEffect(() => {
    setAliasDefined(alias);
    setDeviceIconDefined(selectedDeviceIcon);
  }, [alias, selectedDeviceIcon]);

  return (
    <MainContainer>
      <Typography style={{ fontWeight: "bold" }}>Apelido:</Typography>
      <TextField
        variant="outlined"
        id="alias"
        type="text"
        value={aliasDefined}
        style={{ width: "50%", height: 40 }}
        onChange={(e) => handleAlias(e)}
      />
      <Typography style={{ fontWeight: "bold" }}>
        Tipo de dispositivo
      </Typography>

      <Select
        variant="outlined"
        style={{ width: "50%", height: 60 }}
        value={deviceIconDefined}
        onChange={(e) => handleDeviceIconId(e.target.value)}
      >
        {deviceIcons.map((deviceIcon) => (
          <MenuItem key={`deviceIcon-${deviceIcon.id}`} value={deviceIcon.id}>
            <img
              src={deviceIcon.image}
              width="30"
              height="30"
              style={{ marginRight: 20, alignContent: "center" }}
            />
            {deviceIcon.name}
          </MenuItem>
        ))}
      </Select>
    </MainContainer>
  );
};

export default EditDeviceTab;
