export const eventNames = {
  "enter-route": "Entrada na rota",
  "leave-route": "Desvio de Rota",
  "transfer-finished": "Fim de evento",
  "at-the-stop-point": "Ponto de Parada",
  "move-away-from-incident": "Afastamento de incidente",
  "exit-from-the-stop-point": "Saída de ponto de parada",
  "move-away-from-risky-area": "Afastamento de área de risco",
  "device-tracking-timeout-expired": "Falha no recebimento de posições",

  "approximate-from-incident": "Incidente Próximo",
  "approximate-from-risky-area": "Aproximação de área de risco",
  "leave-risky-area": "Saida de Área de Risco",
  "enter-risky-area": "Entrada em área de risco",

  "approach-incident": "Incidente Próximo",
  "approach-risk-zone": "Área de Risco Próxima",
  "near-risk-zone": "Área de Risco Próxima",
  "enter-risk-zone": "Entrada em Área de Risco",
  "enter-risky-layer": "Entrada em Área de Risco",
  "enter-incident": "Incidente no Local",
};

export const eventNameObject = {
  1: "Saída de Rota",
  2: "Entrada em área de risco",
  3: "Aproximação de área de risco",
  4: "Aproximação de incidente",
  5: "Saída de cerca virtual",
  6: "Alerta de Pânico",
  7: "Chegada no ponto de parada",
  8: "Saída do ponto de parada",
  9: "Movendo-se no ponto de parada",
  10: "Tempo excedido em ponto de parada",
  11: "Rastreamento ativado",
  12: "Rastreamento desativado",
  13: "Regresso de rota",
  14: "Entrada em área de risco personalizada",
  15: "Veiculo parado por muito tempo",
  16: "Aceleração Brusca",
  17: "Frenagem Brusca",
  18: "Curva Acentuada",
  19: "Transfer Iniciado Pelo Utrack",
  20: "Excesso de velocidade",
  21: "Dispositivo andando sem vinculo de motorista",
  22: "Dispositivo distante do ponto de início",
  23: "Dispositivo distante do ponto de término",
};
