import { Box, IconButton, List, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: 56,
  padding: theme.spacing(0, 2),
  boxShadow: "rgba(0, 0, 0, 0.2) 2px 2px 3px 0px",
}));

export const HeaderIcon = styled("img")(({ theme }) => ({
  width: 34,
  height: 34,
  marginRight: theme.spacing(3),
}));

export const HeaderCloseIcon = styled(IconButton)(() => ({
  marginLeft: "auto",
}));

export const HeaderTitle = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const ContentList = styled(List)(() => ({
  width: "100%",
  overflowY: "auto",
}));

export const EmptyContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(3),
}));
