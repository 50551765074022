import React, { useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";

import { useStyles } from "../../../styles";
import devicesAPI from "../../../../../../services/devices";
import {
  addTransferActiveAction,
  addRenderAction,
  removeTransferActiveAction,
} from "../../../../../../store/ducks/monitoringMap";
import FormDialog from "../../../../../FormDialog";
import * as flespiServiceRepository from "../../../../../../infra/http/flespiServiceRepository";
import { transferStates } from "../constants";

export default function TransferStateButton({
  device,
  setStateOfSnackBarWarning,
  changeTransferStateHandler,
  finishCall,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((store) => store.auth.token.accessToken);
  const transfersActive = useSelector(
    (store) => store.monitoringMap.transferActive
  );
  const { calls } = useSelector((store) => store.voice);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [modalText, setModalText] = useState(false);
  const [modalSubmitCallback, setModalSubmitCallback] = useState(false);

  const addTransferActive = (transferActive) => {
    dispatch(addTransferActiveAction(transferActive));
  };

  const addRender = (render) => {
    dispatch(addRenderAction(render));
  };

  const removeTransferActive = (transferActive) => {
    const visibility = _.filter(
      transfersActive,
      (o) => o.id !== transferActive
    );
    dispatch(removeTransferActiveAction(visibility));
  };

  const openModal = (modalTitle, modalText, modalSubmitCallback) => {
    setModalTitle(modalTitle);
    setModalText(modalText);
    setModalSubmitCallback(() => modalSubmitCallback);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOneClick = () => {
    setStateOfSnackBarWarning({
      open: true,
      vertical: "top",
      horizontal: "center",
    });
    setTimeout(() => setStateOfSnackBarWarning({ open: false }), 3000);
  };

  const handleDoubleClick = () => {
    if (device.transfer?.start_date !== null) {
      finishTransfer(
        device.device.id,
        device.transfer.id,
        0,
        device.transfer?.start_date,
        device,
        device.device.device_type_id
      );
    } else
      startTransfer(
        device.device.id,
        device.transfer.id,
        device.device.device_type_id
      );
  };

  const handleFinishCall = async (transferId) => {
    const call = calls.find((item) => item.call_transfer_id == transferId);

    if (!call) {
      return;
    }

    finishCall(call.call_id);
  };

  const startTransfer = async (deviceId, transferId, deviceTypeId) => {
    let transferNearStartLocation = null;
    try {
      const response = await devicesAPI.post(
        "/are-transfers-near-start-location",
        [
          {
            device_id: deviceId,
            transfer_id: transferId,
          },
        ],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      transferNearStartLocation = response.data[0] || null;
    } catch (err) {
      console.log(
        "Erro ao checar a distância do transfer ao ponto de início.",
        err
      );
      alert("Ocorreu um erro ao tentar iniciar um transfer");
      return;
    }

    if (transferNearStartLocation === null) {
      alert(
        "Ocorreu um erro ao tentar iniciar um transfer, o dispositivo não está disponível para rastreamento"
      );
      return;
    }

    const concludeStartTransfer = async () => {
      changeTransferStateHandler(transferStates.STARTING);

      try {
        await devicesAPI.patch(
          "/transfer-start",
          [
            {
              device_id: deviceId,
              transfer_id: transferId,
              device_type_id: deviceTypeId,
            },
          ],
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (err) {
        alert("Ocorreu um erro ao tentar iniciar um transfer");
        changeTransferStateHandler(transferStates.NOT_STARTED);
        return;
      }

      addTransferActive({ id: transferId, deviceId: deviceId });
      changeTransferStateHandler(transferStates.ONGOING_MISSING_DATA);
    };

    if (transferNearStartLocation.within_distance) {
      await concludeStartTransfer();
      return;
    }

    const modalTitle = "Dispositivo distante do ponto de início";
    const modalText = [
      "O dispositivo não está perto suficiente do ponto de início do transfer.",
      "Por favor, adicione uma justificativa para seu início.",
    ];
    const modalSubmitCallback = async (justification) => {
      await Promise.all([
        flespiServiceRepository.postStartTransferJustification(
          device?.device?.flespi_id,
          justification
        ),
        concludeStartTransfer(),
      ]);
    };

    openModal(modalTitle, modalText, modalSubmitCallback);
  };

  const finishTransfer = async (
    deviceId,
    transferId,
    alertIds,
    transfer,
    device,
    deviceTypeId
  ) => {
    let transferNearStopLocation = null;
    try {
      const response = await devicesAPI.post(
        "/are-transfers-near-stop-location",
        [
          {
            device_id: deviceId,
            transfer_id: transferId,
          },
        ],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      transferNearStopLocation = response.data[0] || null;
    } catch (err) {
      console.log(
        "Erro ao checar a distância do transfer ao ponto de término.",
        err
      );
      alert("Ocorreu um erro ao tentar terminar um transfer");
      return;
    }

    if (transferNearStopLocation === null) {
      alert("Ocorreu um erro ao tentar terminar um transfer.");
      return;
    }

    const concludeFinishTransfer = async () => {
      changeTransferStateHandler(transferStates.ENDING);
      try {
        await devicesAPI.request({
          url: `/transfer-stop`,
          method: "PATCH",
          data: [
            {
              device_id: deviceId,
              transfer_id: transferId,
              device_type_id: deviceTypeId,
            },
          ],
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        handleFinishCall(device.transfer.id);
      } catch (response) {
        alert("Ocorreu um erro ao tentar terminar um transfer");
        changeTransferStateHandler(transferStates.ONGOING);
        return;
      }

      removeTransferActive(transferId);
      changeTransferStateHandler(transferStates.ENDED);
      addRender(true);
    };

    if (transferNearStopLocation.within_distance) {
      await concludeFinishTransfer();
      return;
    }

    const modalTitle = "Dispositivo distante do ponto de término";
    const modalText = [
      "O dispositivo não está perto suficiente do ponto de término do transfer.",
      "Por favor, adicione uma justificativa para seu término.",
    ];
    const modalSubmitCallback = (justification) => {
      flespiServiceRepository.postFinishTransferJustification(
        device?.device?.flespi_id,
        justification
      );
      concludeFinishTransfer();
    };

    openModal(modalTitle, modalText, modalSubmitCallback);
  };

  return (
    <>
      <IconButton
        size="small"
        onDoubleClick={handleDoubleClick}
        className={
          device.transfer?.start_date !== null
            ? classes.iconStop
            : classes.iconButton
        }
        onMouseOver={handleOneClick}
      >
        {device.transfer?.start_date !== null ? (
          <StopIcon fontSize="small" color="primary" />
        ) : (
          <PlayArrowIcon fontSize="small" color="primary" />
        )}
      </IconButton>
      <FormDialog
        title={modalTitle}
        content={modalText}
        textFieldLabel="Justificativa"
        modalOpen={modalOpen}
        closeModal={closeModal}
        handleSubmitExternal={modalSubmitCallback}
        device={device}
      />
    </>
  );
}
