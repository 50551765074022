export const types = {
  SET_POI: "SET_POI",
  SET_POIS: "SET_POIS",
  SET_SEARCH: "SET_SEARCH",
  OPEN_MODAL_REGISTER_POI: "OPEN_MODAL_REGISTER_POI",
  OPEN_MODAL_EDIT_POI: "OPEN_MODAL_EDIT_POI",
  OPEN_MODAL_DELETE_POI: "OPEN_MODAL_DELETE_POI",
};

const INITIAL_VALUES = {
  zoom: 10,
  centerMap: {
    lat: -22.7945729,
    lng: -43.2988361,
  },
  mapType: "roadmap",
  poi: {},
  pois: [],
  search: "",
  openModalRegisterPoi: false,
  openModalEditPoi: false,
  openModalDeletePoi: false,
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.OPEN_MODAL_REGISTER_POI:
      return {
        ...state,
        poi: {},
        openModalRegisterPoi: !state.openModalRegisterPoi,
      };
    case types.OPEN_MODAL_EDIT_POI:
      return {
        ...state,
        openModalEditPoi: !state.openModalEditPoi,
      };
    case types.OPEN_MODAL_DELETE_POI:
      return {
        ...state,
        openModalDeletePoi: !state.openModalDeletePoi,
      };
    case types.SET_POI:
      return { ...state, poi: action.poi };
    case types.SET_POIS:
      return { ...state, pois: action.pois };
    case types.SET_SEARCH:
      return { ...state, search: action.search };
    default:
      return state;
  }
};

export const handleOpenModalRegisterPoi = () => ({
  type: types.OPEN_MODAL_REGISTER_POI,
});

export const handleOpenModalEditPoi = () => ({
  type: types.OPEN_MODAL_EDIT_POI,
});

export const handleOpenModalDeletePoi = () => ({
  type: types.OPEN_MODAL_DELETE_POI,
});

export const setPoi = (poi) => ({
  type: types.SET_POI,
  poi,
});

export const setPois = (pois) => ({
  type: types.SET_POIS,
  pois,
});

export const setSearch = (search) => ({
  type: types.SET_SEARCH,
  search,
});
