import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import _ from "lodash";
import { format, parseISO } from "date-fns";
import decodePolyline from "decode-google-map-polyline";
import html2canvas from "html2canvas";
import {
  Grid,
  Paper,
  Divider,
  Checkbox,
  CircularProgress,
  Typography,
  withStyles,
  IconButton,
} from "@material-ui/core";
import { PrintOutlined } from "@material-ui/icons";

import TransferEventList from "./components/TransferEventList";
import TransferEventItem from "./components/TransferEventItem";
import Legend from "./components/Legend";
import ReportEventDetailMap from "../../components/ReportEventDetailMap";
import styles from "./styles";

import api from "../../services/devices";
import transferApi from "../../services/transferApi";
import {
  addDeviceWayaction,
  addExpectedRoutection,
  addStopPoints,
  addRiskLayers,
  addTransferEvents,
  changeDefaultCenterCoordinates,
  changeShowCoordinateMarkers,
  addTransferEventAnalytics,
  setEventIdToShowMarkers,
} from "../../store/ducks/transfer";
import { getEventsWithCoordinates } from "../../services/eventAnalyticsApi/eventService";
import { point } from "@turf/helpers";
import midpoint from "@turf/midpoint";
import { getDevices } from "../../infra/http/devicesProvider";

const ReportEventDetail = ({ classes, match }) => {
  const [device, setDevice] = useState([]);
  const token = useSelector((store) => store.auth.token.accessToken);
  const transferEvents = useSelector((store) => store.transfer.transferEvents);
  const showCoordinateMarkers = useSelector(
    (store) => store.transfer.showCoordinateMarkers
  );
  const customerId = useSelector((store) => store.auth.customer.customer);

  const formatDatetime = (date) => {
    return format(parseISO(date), "dd'/'MM'/'yyyy 'às' HH:mm:ss");
  };

  const start = new Date(match.params.startDate);
  const end = new Date(match.params.end_date);
  start.setHours(start.getHours() + 3);
  end.setHours(end.getHours() + 3);
  const startDate = Math.floor(start / 1000);
  const end_date = Math.floor(end / 1000);
  const deviceId = parseInt(match.params.deviceId);
  const transferId = parseInt(match.params.transferId);

  const [success, setSuccess] = useState(false);
  const [buildingPDF, setBuildingPDF] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    function updateHeaderHeight() {
      if (success) {
        let header = document.getElementById("header");
        const newHeaderHeight = header.clientHeight + 45;

        if (newHeaderHeight !== headerHeight) {
          setHeaderHeight(newHeaderHeight);
        }
      }
    }

    updateHeaderHeight();

    window.addEventListener("resize", updateHeaderHeight);
    return () => window.removeEventListener("resize", updateHeaderHeight);
  }, [success, headerHeight]);

  const eventAnalyticsParams = {
    customerId,
    startedAt: match.params.startDate,
    endedAt: match.params.end_date,
    transferId,
    deviceId,
  };

  const deviceIdAsArray = [];
  deviceIdAsArray.push(deviceId.toString());

  useEffect(() => {
    async function getEventDetails() {
      try {
        const [
          reportTreatment,
          stepRisks,
          eventAnalyticsResponse,
          deviceResponse,
        ] = await Promise.all([
          axios.request({
            url: `${process.env.REACT_APP_FLESPI_SERVICE}/reportTreatment`,
            method: "post",
            data: {
              device_id: deviceId,
              startDate: startDate,
              end_date: end_date,
            },
          }),
          api.request({
            url: `/transfer/${transferId}/steps-risks`,
            method: "get",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          getEventsWithCoordinates(eventAnalyticsParams),
          getDevices({
            customerId,
            deviceIds: deviceIdAsArray,
          }),
        ]);

        setSuccess(true);

        const responseDeviceWay = await _.flattenDeep(
          reportTreatment.data.deviceWay
        );
        const responseExpectedRoute = await _.flattenDeep(
          decodePolyline(stepRisks.data.steps_polyline)
        );
        const responseStopPoints = await _.flattenDeep(
          stepRisks.data.stop_points
        );
        const responseRiskLayers = await _.flattenDeep(
          stepRisks.data.risk_layers
        );

        setDevice(deviceResponse);
        dispatch(addDeviceWayaction(responseDeviceWay));
        dispatch(addExpectedRoutection(responseExpectedRoute));
        dispatch(addStopPoints(responseStopPoints));
        dispatch(addRiskLayers(responseRiskLayers));
        dispatch(addTransferEvents(_.flattenDeep([eventAnalyticsResponse])));
        dispatch(addTransferEventAnalytics(eventAnalyticsResponse));
      } catch (e) {
        console.log("erro", e);
      }
    }
    getEventDetails();
  }, []);

  const handleShowCoordinateMarkersCheckbox = (event) => {
    dispatch(changeShowCoordinateMarkers(event.target.checked));
  };

  const formatCoordinates = (coordinates) => {
    return [
      coordinates.payload.tracking.longitude,
      coordinates.payload.tracking.latitude,
    ];
  };

  const handleClickSidebarAlert = (transferEvent) => {
    if (transferEvent?.coordinates?.length == 0) return;

    const formatedCoordinates =
      transferEvent.coordinates.map(formatCoordinates);

    const startPoint = point(formatedCoordinates[0]);
    const endPoint = point(formatedCoordinates[formatedCoordinates.length - 1]);

    const midpointCoordinate = midpoint(startPoint, endPoint);

    const position = {
      lat: midpointCoordinate.geometry.coordinates[1],
      lng: midpointCoordinate.geometry.coordinates[0],
    };

    dispatch(changeDefaultCenterCoordinates(position));
    dispatch(setEventIdToShowMarkers(transferEvent.id));
  };

  const handleReportPrint = async () => {
    const map = document.getElementById("report-map");

    setBuildingPDF(true);

    try {
      const canvas = await html2canvas(map, {
        scale: 3,
        allowTaint: false,
        useCORS: true,
      });

      const data = {
        deviceId: deviceId,
        start: match.params.startDate,
        end: match.params.end_date,
        map: canvas.toDataURL("image/jpeg", 1.0),
      };

      const response = await transferApi.post("event-treatment-report", data);

      const a = document.createElement("a");
      a.setAttribute("download", "download");
      a.setAttribute("href", response.data.file);
      a.click();
    } catch (error) {
      console.log(error);
    } finally {
      setBuildingPDF(false);
    }
  };

  const renderDeviceOrAlias = () => {
    if (device[0].alias !== null) {
      return device[0].alias;
    } else {
      return device[0].identifier;
    }
  };

  return (
    <div className={classes.paper}>
      {success ? (
        <>
          <header id="header">
            <div>
              <Typography variant="h6" component="h6">
                Detalhes | Evento
                <IconButton
                  disabled={buildingPDF}
                  onClick={handleReportPrint}
                  style={{ marginLeft: "10px" }}
                >
                  {buildingPDF ? (
                    <CircularProgress size={20} />
                  ) : (
                    <PrintOutlined />
                  )}
                </IconButton>
              </Typography>
            </div>
            <Grid container style={{ marginTop: 10 }} spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper className={classes.eventInfoContainer} elevation={2}>
                  <Typography>
                    <strong>Dispositivo: </strong>{" "}
                    {device.length > 0 && renderDeviceOrAlias()}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.eventInfoContainer} elevation={2}>
                  <Typography>
                    <strong>Periodo: </strong>
                    {formatDatetime(match.params.startDate)}
                    <strong> até </strong>
                    {formatDatetime(match.params.end_date)}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </header>

          <Divider style={{ marginTop: 16, marginBottom: 16 }} />

          <Grid container style={{ flex: 1 }} spacing={2}>
            <Grid
              item
              sm={7}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: `calc(100vh - ${headerHeight}px)`,
              }}
            >
              <Paper
                className={classes.mapContainer}
                elevation={2}
                id="report-map"
              >
                <ReportEventDetailMap />
              </Paper>
              <Paper>
                <Legend />
                <div className={classes.checkboxCoordContainer}>
                  Ver coordenadas da rota percorrida
                  <Checkbox
                    checked={showCoordinateMarkers}
                    onChange={handleShowCoordinateMarkersCheckbox}
                    color="primary"
                  />
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              sm={5}
              xs={12}
              style={{ maxHeight: `calc(100vh - ${headerHeight}px)` }}
            >
              <TransferEventList>
                {transferEvents
                  .filter((event) => event.needTreatment)
                  .map((event, index) => (
                    <TransferEventItem
                      key={event.id}
                      index={index}
                      transferEvent={event}
                      handleClickSidebarAlert={handleClickSidebarAlert}
                    />
                  ))}
              </TransferEventList>
            </Grid>
          </Grid>
        </>
      ) : (
        <center>
          <div>
            <CircularProgress
              size={68}
              style={{ marginTop: 200, color: "#63a9ef" }}
            ></CircularProgress>
          </div>
          <span>Carregando dados</span>
        </center>
      )}
    </div>
  );
};

export default withStyles(styles)(ReportEventDetail);
