import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import styles from "../styles";

const SearchAutoCompleteDevices = ({
  placeholder,
  options = [],
  getOptionLabel = (option) => option.alias || option.identifier,
  onChange = () => {},
}) => {
  return (
    <Autocomplete
      options={options}
      style={styles.autocompleteField}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} variant="outlined" />
      )}
    />
  );
};

export default SearchAutoCompleteDevices;
