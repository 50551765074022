import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";

export default function Processing() {
  return (
    <div style={{ width:490 }}>
      <center>
        <div>
          <CircularProgress size={68} style={{ marginTop:200, color: "#63a9ef"}}></CircularProgress>
        </div>
        <span >Fazendo Tratamento</span>
      </center>
    </div>
  );
}
