export const styles = {
  pdfViewer: {
    height: "100vh",
    width: "100%",
    border: "none",
  },
  main: {
    paddingHorizontal: 16,
  },
};
