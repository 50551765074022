import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ModalBond from "../Filter/Register/modalBond";
import ModalStatus from "../Filter/Register/modalStatus";
import ModalRemoveTransfer from "../Filter/Register/ModalRemoveTransfer";
import SharedTable from "../SharedTable";
import { formatDateReadable } from "../../domain/format/date";

import * as transfersProvider from "../../infra/http/transfersProvider";
import constants from "../../commons/constants";

export default function EnhancedTable() {
  const sharedTableRef = useRef(null);
  const isBeforeFirstFetchEffectRef = useRef(true);

  const history = useHistory();

  const searchByUnity = useSelector((store) => store.transfer.unityFetch);
  const searchByEvent = useSelector((store) => store.transfer.eventFetch);
  const searchByFrom = useSelector((store) => store.transfer.fromFetch);
  const searchByTo = useSelector((store) => store.transfer.toFetch);
  const searchByResponsible = useSelector(
    (store) => store.transfer.responsibleFetch
  );
  const searchByScheduledDate = useSelector(
    (store) => store.transfer.scheduledDateFetch
  );
  const searchByCustomerUnitId = useSelector(
    (store) => store.transfer.customerUnitIdFetch
  );
  const token = useSelector((store) => store.auth.token.accessToken);
  const permissions = useSelector((store) => store.auth.permissions);

  useEffect(() => {
    if (isBeforeFirstFetchEffectRef.current) {
      isBeforeFirstFetchEffectRef.current = false;
      return;
    }

    if (sharedTableRef?.current)
      sharedTableRef.current.fetchRowsData({
        waitTimeForAnotherCall: 1000,
        shouldGoBackToFirstPage: true,
      });
  }, [
    token,
    searchByScheduledDate,
    searchByEvent,
    searchByUnity,
    searchByFrom,
    searchByTo,
    searchByResponsible,
    searchByCustomerUnitId,
  ]);

  if (permissions.permissions === undefined) {
    history.push("/");
    return null;
  }

  const fetchListData = async ({ currentPage, rowsPerPage }) => {
    const { transfersData, totalRows } =
      await transfersProvider.getTransfersWithDevices({
        page: currentPage,
        perPage: rowsPerPage,
        scheduledDate: `${searchByScheduledDate}`,
        identifier: `${searchByEvent}`,
        from: `${searchByFrom}`,
        to: `${searchByTo}`,
        unity: `${searchByUnity}`,
        responsible: `${searchByResponsible}`,
        customerUnitId: searchByCustomerUnitId,
      });

    const rowsData = transfersData.map((transfer) => [
      transfer.id,
      transfer.id,
      formatScheduledDate(transfer.scheduled_date),
      transfer.name,
      transfer.from,
      transfer.to,
      transfer.unity,
      transfer.responsible,
      <ModalBond
        onSuccess={sharedTableRef?.current?.fetchRowsData}
        transferData={transfer}
      />,
      <ModalStatus transferData={transfer} />,
      <ModalRemoveTransfer
        transferData={transfer}
        onSuccess={sharedTableRef?.current?.fetchRowsData}
      />,
    ]);

    return {
      rowsData,
      totalRows,
    };
  };

  const formatScheduledDate = (scheduledDate) => {
    try {
      return formatDateReadable(scheduledDate);
    } catch (error) {
      return "-";
    }
  };

  const boolCanViewRoutes =
    permissions?.permissions?.data.includes("can-view-routes");

  if (!boolCanViewRoutes) {
    alert("Você não possui permissão para acessar esses dados");
    history.push("/monitoringmap");

    return (
      <center>
        <span>Você não possui permissão para acessar esses dados.</span>
      </center>
    );
  }

  const tableHeaders = [
    "ID",
    "Data e Hora",
    "Evento",
    "Local de Partida",
    "Local de Destino",
    "Unidade",
    "Responsável",
    "Vínculo",
    "Status",
    "Remover",
  ];

  return (
    <SharedTable
      cellHeaders={tableHeaders}
      noRowsDataMessage={constants.tables.DEFAULT_NO_ROWS_MESSAGE}
      withPagination={true}
      rowsDataFetcher={fetchListData}
      ref={sharedTableRef}
    />
  );
}
