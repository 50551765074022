import transferApi from "./transferApi";

/**
 * @param {string} polyline
 * @param {string} afterDate A partir de quando as areas de riscos serão obtidas
 * @param {number} radios Raio de busca ao longo do caminho informado na polyline
 * @returns {Promise<Array>}
 */
export const getRiskZonesAlongTheWay = async (
  polyline,
  afterDate,
  radios = 500
) => {
  const response = await transferApi.post("/risk-zones", {
    linestring: polyline,
    after_date: afterDate,
    radios,
  });

  return response.data;
};
