import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import DoneIcon from "@material-ui/icons/Done";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import HelpIcon from "@material-ui/icons/Help";

import FormStatus from "./formStatus";
import transferConstants from "../../../domain/constants/transfers";
import { getTransferStatus } from "./utils/transfers";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "#06B0CF" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({ transferData }) {
  const [open, setOpen] = useState(false);

  const transferStatus = getTransferStatus(transferData.status);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={transferStatus} placement="top" arrow>
        <Button
          onClick={handleClickOpen}
          variant="outlined"
          color="secondary"
          fontSize="inherit"
          style={{
            width: 5,
            borderRadius: 12,
            boxShadow: "0px 3px 6px #00000029",
            backgroundColor: "#fff",
            border: "none",
          }}
        >
          <ButtonContent transferStatus={transferStatus} />
        </Button>
      </Tooltip>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Button onClick={handleClose}>
            <ChevronLeftIcon fontSize="large" style={{ color: "#06B0CF" }} />{" "}
          </Button>{" "}
          Transfer | <strong>Edição</strong>
        </DialogTitle>
        <DialogContent dividers>
          <FormStatus transferData={transferData} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

const ButtonContent = ({ transferStatus }) => {
  const buttonPossibleContents = {
    [transferConstants.status.CANCELED]: () => (
      <HighlightOffIcon style={{ color: "#EA6060" }} />
    ),
    [transferConstants.status.ENDED]: () => (
      <>
        <DoneIcon fontSize="small" style={{ color: "#06B0CF" }} />
        <DirectionsCarIcon fontSize="small" style={{ color: "#06B0CF" }} />
      </>
    ),
    [transferConstants.status.NOT_ENDED]: () => (
      <>
        <HighlightOffIcon style={{ color: "#EA6060" }} />
        <DirectionsCarIcon fontSize="small" style={{ color: "#06B0CF" }} />
      </>
    ),
    [transferConstants.status.NOT_SCRIPTED]: () => (
      <>
        <HelpIcon style={{ color: "#06B0CF" }} />
        <CreateOutlinedIcon fontSize="small" style={{ color: "#333333" }} />
      </>
    ),
    [transferConstants.status.ONGOING]: () => (
      <>
        <HourglassEmptyIcon fontSize="small" style={{ color: "#EA6060" }} />
        <DirectionsCarIcon fontSize="small" style={{ color: "#EA6060" }} />
      </>
    ),
    [transferConstants.status.SCRIPTED]: () => (
      <>
        <DoneIcon fontSize="small" style={{ color: "#06B0CF" }} />
        <CreateOutlinedIcon fontSize="small" style={{ color: "#333333" }} />
      </>
    ),
  };

  const ButtonChosen =
    buttonPossibleContents[transferStatus] ??
    buttonPossibleContents[transferConstants.status.ENDED];

  return <ButtonChosen />;
};
