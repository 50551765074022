import React, { useState, useEffect } from "react";
import { Polyline } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import lodash from "lodash";

import { convertArrayOfObjectsToHashtable } from "../../commons/utils/dataStructures";

export default function DeviceWay() {
  const [devicePosition, setDevicePosition] = useState([]);
  const devicesIdsSelected = useSelector(
    (store) => store.monitoringMap.devicesIdsSelected
  );
  const transfersActive = useSelector(
    (store) => store.monitoringMap.transferActive
  );
  const visibilityRoutes = useSelector(
    (store) => store.monitoringMap.visibilityRoute
  );
  const devices = useSelector((store) => store.monitoringMap.devices);
  const pointsDevicesActives = useSelector(
    (store) => store.monitoringMap.pointsDevicesActives
  );

  const optionsPolyline = {
    strokeColor: "blue",
    strokeOpacity: 1,
    strokeWeight: 4,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  useEffect(() => {
    const fetchPatch = () => {
      const transferActiveDeviceIdsSet = new Set(
        transfersActive.map((transferActive) => String(transferActive.deviceId))
      );
      const visibleRoutesDeviceIdsSet = new Set(
        visibilityRoutes.map((device) => String(device.id))
      );

      const devicesIdsSelectedSet = new Set(
        devicesIdsSelected.map((deviceId) => String(deviceId))
      );

      const deviceTransfersHashtable = convertArrayOfObjectsToHashtable({
        objects: devices.filter(
          (deviceTransfer) =>
            devicesIdsSelectedSet.has(String(deviceTransfer.device.id)) &&
            visibleRoutesDeviceIdsSet.has(String(deviceTransfer.device.id)) &&
            transferActiveDeviceIdsSet.has(String(deviceTransfer.device.id))
        ),
        keyGetter: (deviceTransfer) =>
          String(deviceTransfer?.device?.id ?? null),
      });

      const positions = {};
      pointsDevicesActives.forEach((flespiDeviceData) => {
        if (
          !deviceTransfersHashtable.hasOwnProperty(
            flespiDeviceData.last_status.device_id
          )
        )
          return;

        const deviceTransferData =
          deviceTransfersHashtable[flespiDeviceData.last_status.device_id];
        const startDate = deviceTransferData.transfer?.start_date;
        const startDateMilliseconds = Math.floor(new Date(startDate) / 1000);

        const rightTime = Math.floor(flespiDeviceData.timestamp - 10800);

        if (rightTime > startDateMilliseconds) {
          if (!positions.hasOwnProperty(deviceTransferData.device.id)) {
            positions[deviceTransferData.device.id] = [];
          }

          const { latitude, longitude } = flespiDeviceData.last_status;

          positions[deviceTransferData.device.id].push({
            lat: latitude,
            lng: longitude,
          });
        }
      });

      setDevicePosition(Object.values(positions));
    };
    fetchPatch();
  }, [
    pointsDevicesActives,
    transfersActive,
    visibilityRoutes,
    devices,
    devicesIdsSelected,
  ]);

  return (
    <>
      {lodash.compact(devicePosition).map((path, index) => {
        return path !== undefined ? (
          <li key={index}>
            <Polyline path={lodash.compact(path)} options={optionsPolyline} />
          </li>
        ) : null;
      })}
    </>
  );
}
