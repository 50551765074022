import devicesAPI from "../../services/devices";
import * as exceptions from "../../commons/exceptions";
import codes from "../../commons/constants/codes";

import { store } from "../../store";

export const getDevices = async ({
  customerId,
  identifier = null,
  deviceIds = null,
  externalIds = null,
  page = null,
  perPage = null,
}) => {
  const params = {};
  if (identifier !== null) params.identifier = identifier;
  if (deviceIds !== null) params.device_ids = deviceIds;
  if (externalIds !== null) params.external_ids = externalIds;

  if (page !== null && perPage !== null) params.perPage = perPage;
  params.page = page;

  try {
    const { data: devicesData } = await devicesAPI.get(
      `/customer/${customerId}/devices`,
      {
        params,
      }
    );
    return devicesData;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const updateAlias = async ({ id, alias }) => {
  const customerId = store.getState()?.auth?.customer?.customer ?? null;

  if (customerId === null)
    throw exceptions.createException({
      code: codes.error.APP_STATE_ERROR,
      message: "Customer ID not available",
    });

  try {
    await devicesAPI.put(`/customer/${customerId}/device/alias`, { id, alias });
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const getDeviceTransfers = async (deviceIds) => {
  const customerId = store.getState()?.auth?.customer?.customer ?? null;

  try {
    const { data: deviceTransfers } = await devicesAPI.post(
      `/customer/${customerId}/device-transfers`,
      deviceIds
    );

    return deviceTransfers;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};
export const createDevices = async (data) => {
  try {
    await devicesAPI.post(`/device-links`, data);
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const deleteDevices = async (transfer_id, data) => {
  try {
    await devicesAPI.delete(`/transfer/${transfer_id}/device-links`, { data });
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const createDevicesPreConfigured = async (
  transfer_setting_id,
  devices
) => {
  try {
    await devicesAPI.post(`/device-preconfigured-link`, {
      transfer_setting_id,
      devices,
    });
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const deleteDevicesPreConfigured = async (
  transfer_setting_id,
  devices
) => {
  try {
    await devicesAPI.delete(
      `/transfer/${transfer_setting_id}/device-preconfigured-link`,
      { data: [{ transfer_setting_id, device_ids: devices }] }
    );
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const getEventAnalysis = async ({
  from,
  to,
  customerUnitIds,
  customerId,
}) => {
  try {
    const { data: eventsAnalysis } = await devicesAPI.get(
      `/customers/${customerId}/event-analysis`,
      {
        params: {
          to,
          from,
          customerUnitIds,
        },
      }
    );

    return eventsAnalysis;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const getDeviceIcons = async () => {
  try {
    const { data } = await devicesAPI.get("/device-icons");
    return data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const postDeviceSettings = async (data, deviceId) => {
  try {
    const response = await devicesAPI.post(
      `/devices/${deviceId}/settings`,
      data
    );
    return response.data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const getEventTypes = async () => {
  try {
    const data = await devicesAPI.get("/event-types");
    return data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const getDeviceSettingsConfiguration = async (deviceId) => {
  try {
    const response = await devicesAPI.get(
      `/devices/settings/${deviceId}/settings-configuration`
    );
    return response.data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};
