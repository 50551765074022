import centerOfMass from "@turf/center-of-mass";
import { multiPolygon } from "@turf/helpers";

export const convertGeoJsonToLatLng = (coordinates) => {
  if (!coordinates || coordinates.length == 0) {
    return [];
  }
  const onlyCoordinates = coordinates[0];
  const formatedCoodinates = [];
  for (let item of onlyCoordinates) {
    const [lng, lat] = item;
    formatedCoodinates.push({ lat, lng });
  }

  return formatedCoodinates;
};

export const getCenterOfCoordinates = (coordinates) => {
  const poly = multiPolygon(coordinates);
  const center = centerOfMass(poly);

  const position = {
    lat: center.geometry.coordinates[1],
    lng: center.geometry.coordinates[0],
  };

  return position;
};
