import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CancelButton, CustomTextField, SaveButton } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { ScriptingActiontypes } from "../../../../../store/ducks/scripting";
import { genericAction } from "../../../../../store/ducks/transfer";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export const EditAddress = ({ isOpen, setIsOPen, address, id }) => {
  const [name, setName] = useState(address);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const dispatch = useDispatch();
  const addressData = useSelector((state) => state.scripting.addressCard);
  const waypoints = useSelector((state) => state.scripting.waypoints);

  const handleEditData = () => {
    try {
      const currAddressData = addressData.find((item) => {
        return item.id === id;
      });

      dispatch(
        genericAction(ScriptingActiontypes.UPDATE_ADDRESS_CARD, {
          ...currAddressData,
          location: name,
          position: {
            latitude: coordinates.lat,
            longitude: coordinates.lng,
          },
        })
      );
    } catch (error) {
      toast.error("Erro ao tentar editar endereço");
    } finally {
      setIsOPen(false);
    }
  };

  const handleSelect = async (selectedAddress) => {
    setName(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setCoordinates(latLng);
    } catch (error) {
      toast.error("Error fetching geolocation data:");
    }
  };

  const handleEditEndereco = (item) => {
    setName(item);
  };

  return (
    <div>
      <Dialog
        open={isOpen.open}
        onClose={() => setIsOPen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>Editar endereço</DialogTitle>
        <DialogContent>
          <PlacesAutocomplete
            value={name}
            onChange={setName}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <CustomTextField
                  defaultValue={name}
                  label="Endereço"
                  size="small"
                  variant="outlined"
                  type="text"
                  onChange={(e) => handleEditEndereco(e.target.value)}
                  {...getInputProps({ placeholder: "Digite o endereço" })}
                  fullWidth
                />
                <div>
                  {loading && (
                    <div style={{ marginLeft: "20px" }}>Carregando...</div>
                  )}
                  {suggestions.map((suggestion, index) => {
                    const style = {
                      width: "400px",
                      lineHeight: 1.5,
                      backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                      cursor: "pointer",
                    };
                    return (
                      <div
                        key={index}
                        {...getSuggestionItemProps(suggestion, { style })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </DialogContent>
        <DialogActions>
          <SaveButton onClick={handleEditData}>Confirmar</SaveButton>
          <CancelButton onClick={() => setIsOPen(false)}>Cancelar</CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
