import React, { useEffect, useState } from "react";

import { formatEventReportRows } from "./utils";
import { Switch, FormControlLabel, Box, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  getDeviceApiData,
  getIsMonitored,
} from "../../../store/ducks/reportingData";
import useStyles from "../EventAnaliticsDashboard/styles";
import { ReportLoader } from "../loader";
import { EventReportTable } from "./eventReportTable";

export const EventReport = ({
  filters,
  handleSetTransferAndDevice,
  setReportOnScreen,
}) => {
  const [data, setData] = useState(null);

  const dispatch = useDispatch();
  const classes = useStyles();

  const customerUnit = useSelector((store) => store.customerUnits);
  const customerCategory = useSelector((store) => store.customerCategories);
  const customerProducts = useSelector((store) => store.customerProducts);
  const customerId = useSelector((store) => store.auth.customer.customer);
  const customerActivity = useSelector((store) => store.customerActivities);
  const { transfersWithDevicesResponse, transfersWithDevicesResponseLoading } =
    useSelector((store) => store.reportData);

  const eventReportFormatter = (data) => {
    const { transfers_data } = data;

    const fireDispatchDeviceApi = () => {
      dispatch(getDeviceApiData(customerId));
    };

    const formattedEventReportRows = formatEventReportRows(
      fireDispatchDeviceApi,
      transfers_data,
      handleSetTransferAndDevice,
      setReportOnScreen,
      customerUnit,
      customerActivity,
      customerCategory,
      customerProducts
    );

    return formattedEventReportRows;
  };

  useEffect(() => {
    if (Object.keys(transfersWithDevicesResponse).length !== 0) {
      const formatting = eventReportFormatter(transfersWithDevicesResponse);
      setData(formatting);
    } else {
      return;
    }
  }, [transfersWithDevicesResponse]);

  const renderEventReport = () => {
    if (Object.keys(transfersWithDevicesResponse).length == 0) {
      return (
        <Box className={classes.noticeContainer}>
          <Typography> Selecione os filtros e aperte buscar </Typography>
        </Box>
      );
    } else {
      return <EventReportTable events={data} />;
    }
  };

  const handleMonitoringButton = (value) => {
    dispatch(getIsMonitored(value));
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            onChange={(e) => handleMonitoringButton(e.target.checked)}
            color="primary"
          />
        }
        label="Mostrar apenas eventos monitorados"
      />
      {transfersWithDevicesResponseLoading ? (
        <ReportLoader />
      ) : (
        renderEventReport()
      )}
    </>
  );
};
