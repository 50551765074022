import {
  styled as styledMUI,
  TextField,
  Button,
  Typography,
} from "@mui/material";

export const CustomTextField = styledMUI(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "5px",
    },
  },
  input: {
    fontSize: "15px",
    fontWeight: 400,
  },
  backgroundColor: "#F8F8F8",
  margin: "5px 0px 5px 0px",
}));

export const SaveButton = styledMUI(Button)(() => ({
  "&:hover": {
    backgroundColor: "#2A93A7",
  },
  backgroundColor: "#2A93A7",
  color: "#FFFFFF",
  textTransform: "none",
  fontSize: "15px",
}));

export const CancelButton = styledMUI(Button)(() => ({
  "&:hover": {
    backgroundColor: "#F8F8F8",
  },
  backgroundColor: "#F8F8F8",
  color: "#2A93A7",
  textTransform: "none",
  fontSize: "15px",
}));

export const Text = styledMUI(Typography)(() => ({
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: 1.5,
}));
