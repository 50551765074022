import { makeStyles } from "@material-ui/core";

const stylesCallback = (theme) => {
  const styles = {
    dialog: {
      position: 'absolute',
      right: 10,
      width: 450
    },
    large: {
      width: 45,
      height: 45,
    },
    closeButton: {
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    rowStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      justifyContent: 'space-between',
      paddingLeft: 20,
      paddingRight: 20,
    },
    datesRowItem: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      '&:not(:first-child)': {
        marginLeft: 12,
      }
    },
    dialogContent: {
      color: '#333',
    },
    description: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 20,
      paddingRight: 20,
    },
    header: {
      marginBottom: 24,
      display: 'flex',
      alignItems: 'start',
    },
    title: {
      flex: 1,
    }
  }

  styles.datesRow = {
    ...styles.rowStyle,
    marginTop: 10,
    marginBottom: 10,
    justifyContent: 'space-between',
  }

  return styles
}

const useStyles = makeStyles(stylesCallback);

export default useStyles