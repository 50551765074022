import React from "react";
import Collapse from "@material-ui/core/Collapse";

import { useStyles } from "../styles";

export default function MoreRowData({ device, expanded }) {
  const classes = useStyles();

  return (
    <Collapse
      className={classes.collapse}
      in={expanded}
      timeout="auto"
      unmountOnExit
    >
      <div className={classes.collapseHeader}>
        <div className={classes.collapseHeaderItem}>
          <span>
            <strong>Partida | </strong>
          </span>
          <span>{device.transfer?.from}</span>
        </div>
        <div className={classes.collapseHeaderItem}>
          <span>
            <strong>Destino | </strong>
          </span>
          <span>{device.transfer?.to}</span>
        </div>
      </div>
      <div className={classes.collapseHeader}>
        <div className={classes.collapseHeaderItem}>
          <span>
            <strong>Unidade | </strong>
          </span>
          <span>{device.transfer?.unity || device.transfer?.customer_unit_name}</span>
        </div>
        <div className={classes.collapseHeaderItem}>
          <span>
            <strong>Evento | </strong>
          </span>
          <span>{device.transfer?.name}</span>
        </div>
        <div className={classes.collapseHeaderItem}>
          <span>
            <strong>Responsável | </strong>
          </span>
          <span>{device.transfer?.responsible}</span>
        </div>
      </div>
    </Collapse>
  );
}
