import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { format } from "date-fns";
import { handleUpdateDriver } from "../../../services/peopleManagement/driver";
import { toast } from "material-react-toastify";
import SearchAutoCompleteDevicesEdit from "../../../components/Filter/Register/SerchAutoCompleteDevicesEdit";
import UnbindDialog from "./ModalDeviceUnbind";

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

const EditDialog = ({
  open,
  close,
  data,
  reload,
  allDevicesUtrack,
  devices,
}) => {
  const [name, setName] = useState(data.name);
  const [expirationDateCnh, setExpirationDateCnh] = useState();
  const [cnh, setCnh] = useState();
  const [phone, setPhone] = useState();
  const [rfid, setRfid] = useState();
  const [selected, setSelected] = useState({ id: null, currentUtrack: "" });
  const [unbindToggle, setUnbindToggle] = useState(false);
  const devicesUtrack = allDevicesUtrack.map((item) => item.dataValues);
  const birthDateCurrent =
    data?.expirationDateCnh == null
      ? ""
      : format(new Date(data?.expirationDateCnh), "yyyy-MM-dd");

  const handleSetName = (name) => {
    setName(name);
  };

  const handleSetExpirationDateCnh = (date) => {
    setExpirationDateCnh(date);
  };

  const handleDriverLicense = (cnh) => {
    setCnh(cnh);
  };

  const handleSetPhone = (phone) => {
    setPhone(phone);
  };

  const handleSetRfid = (rfid) => {
    setRfid(rfid);
  };

  const editDriver = async (id) => {
    const data = {
      name,
      expirationDateCnh,
      cnh,
      phone,
      rfid,
      userId: selected.id,
    };

    if (!name) {
      return toast.error("Digite o nome do passageiro");
    }

    try {
      await handleUpdateDriver(id, data);
      await reload();
      close();
    } catch (error) {
      toast.error("Erro ao tentar editar");
    }
  };

  const handleSelected = (event, devicesUtrack) => {
    if (!devicesUtrack) {
      setSelected({ id: null, currentUtrack: "" });
      return;
    }
    setSelected({
      id: devicesUtrack.id,
      currentUtrack: devicesUtrack?.alias || devicesUtrack?.identifier,
    });
  };

  const unbindTogleOpen = () => {
    setUnbindToggle(true);
  };

  const unbindTogleClose = () => {
    setUnbindToggle(false);
  };

  const handleUnbindDevice = async () => {
    setSelected({ id: null, currentUtrack: "" });
    setUnbindToggle(false);
  };

  useEffect(() => {
    const currentUtrackDevice = devicesUtrack.find(
      (current) => current.id == data.userId
    );

    setSelected({
      id: currentUtrackDevice?.id,
      currentUtrack:
        currentUtrackDevice?.alias || currentUtrackDevice?.identifier,
    });
  }, [allDevicesUtrack]);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      close={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Editar Cadastro de Passageiro
      </DialogTitle>

      <UnbindDialog
        open={unbindToggle}
        onClose={unbindTogleClose}
        onUnbind={handleUnbindDevice}
      />

      <DialogContent>
        <Grid container spacing={3} style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: "100%" }} size="small">
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Nome do Motorista
              </Typography>
              <TextField
                onChange={(e) => handleSetName(e.target.value)}
                defaultValue={data.name}
                variant="outlined"
                id="nome"
                type="text"
                placeholder="Digite nome do Passageiro"
                style={{ width: "100%", height: 40 }}
                size="small"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              style={{ fontWeight: "bold" }}
              variant="subtitle2"
              gutterBottom
            >
              CNH (Carteira Nacional de Habilitação)
            </Typography>
            <TextField
              onChange={(e) => handleDriverLicense(e.target.value)}
              defaultValue={data.cnh}
              variant="outlined"
              id="cnh"
              type="text"
              placeholder="Digite os números da CNH"
              style={{ width: "100%", height: 40 }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              style={{ fontWeight: "bold" }}
              variant="subtitle2"
              gutterBottom
            >
              Vencimento da CNH
            </Typography>
            <TextField
              onChange={(e) => handleSetExpirationDateCnh(e.target.value)}
              defaultValue={birthDateCurrent}
              variant="outlined"
              id="scheduled_date"
              type="date"
              style={{ width: "100%", height: 40 }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: "100%" }} size="small">
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Telefone
              </Typography>
              <TextField
                onChange={(e) => handleSetPhone(e.target.value)}
                defaultValue={data.phone}
                variant="outlined"
                id="tel"
                type="tel"
                placeholder="Digite um número de telefone"
                style={{ width: "100%", height: 40 }}
                size="small"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: "100%" }} size="small">
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                RFID
              </Typography>
              <TextField
                onChange={(e) => handleSetRfid(e.target.value)}
                defaultValue={data.rfid}
                variant="outlined"
                id="tel"
                type="text"
                placeholder="Digite o RFID"
                style={{ width: "100%", height: 40 }}
                size="small"
              />
            </FormControl>
          </Grid>

          {devices.map((n) => n.deviceTypeId).includes(1) && (
            <Grid item xs={10} sm={6}>
              <FormControl style={{ width: "100%" }} size="small">
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Devices
                </Typography>
                <SearchAutoCompleteDevicesEdit
                  defaultValue={selected?.currentUtrack}
                  options={devicesUtrack}
                  onChange={handleSelected}
                  placeholder="Devices"
                  disable={Boolean(selected?.currentUtrack)}
                  unbindDialog={unbindTogleOpen}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => editDriver(data.id)}>
          Editar
        </Button>
        <Button onClick={close} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
