import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 430;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontSize: 18,
    [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
      fontSize: 14,
    },
  },
  box: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    height: "100%",
  },
  boxHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
    padding: "10px 0px",
    margin: "10px",
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: 10,
    height: "60vh",
  },
  drawerVehicle: {
    width: 790,
  },
  drawerPaperVehicle: {
    width: 790,
    padding: 10,
    height: "60vh",
  },
  eventListContainer: {
    width: 400,
    overflow: "auto",
    paddingLeft: 10,
    paddingRight: 10,
  },
  VehicleName: {
    color: "#000000",
    fontSize: "18px",
    [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
      fontSize: "15px",
    },
    fontWeight: "bold",
    width: "300px",
  },
  DivActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "50px",
    borderRadius: "50px",
    background: "#228b22",
  },
  DeviceOn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
      width: "150px",
    },
    height: "50px",
    borderRadius: "50px",
    background: "#228b22",
    color: "#ffffff",
    fontsize: "15px",
    fontWeight: "bold",
    textAlign: "center",
  },
  DeviceOff: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
      width: "150px",
    },
    height: "50px",
    borderRadius: "50px",
    background: "#ff0000",
    color: "#ffffff",
    fontsize: "15px",
    fontWeight: "bold",
    textAlign: "center",
  },
  Body: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
    padding: "10px 0px",
    margin: "10px",
  },
  BodyInfo: {
    color: "#000000",
    fontSize: "18px",
    [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
      fontSize: "15px",
    },
    fontWeight: "400",
    marginLeft: "0px",
    overflowWrap: "break-word",
    display: "flex",
    hyphens: "manual",
    gap: "5px",
  },
  Footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    height: "150px",
    gap: "4px",
  },
  Button: {
    display: "flex",
    gap: "4px",
    justifyContent: "start",
    alignItems: "center",
    borderRadius: "5px",
    border: "none",
    width: "350px",
    height: "36px",
    backgroundColor: "#EBF9FC",
    fontSize: "15px",
    color: "black",
    cursor: "pointer",
    fontWeight: "bold",
    [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
      width: "350px",
      fontSize: "12px",
    },
  },
  ButtonOff: {
    display: "flex",
    gap: "4px",
    justifyContent: "start",
    alignItems: "center",
    borderRadius: "5px",
    border: "none",
    width: "350px",
    height: "36px",
    backgroundColor: "#F4F4F4",
    fontSize: "15px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "#000000",
    [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
      width: "350px",
      fontSize: "12px",
    },
  },
  DivManage: {
    border: "none",
    width: "150px",
    height: "50px",
  },

  Standard: {
    fontSize: "20px",
    marginLeft: "5px",
  },
}));
