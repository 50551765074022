import * as exceptions from "../../commons/exceptions";
import codes from "../../commons/constants/codes";
import utransferV2API from "../../services/utransferV2";

export const getDataEmissionCo2 = async ({ customerId, to, from }) => {
  try {
    const { data } = await utransferV2API.get(
      `/reports/customers/${customerId}/count-emissions-co2`,
      {
        params: {
          from: Math.floor(from.getTime() / 1000),
          to: Math.floor(to.getTime() / 1000),
        },
      }
    );

    return data;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};
