import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import logo from "../../assets/logoUtransfer.svg";

import {
  FormSubmitBtn,
  Text,
  FormRow,
  Divider,
  Logo,
  FormInformation,
} from "./styles";
import LoginField from "../Login/LoginField/index";
import { authenticate } from "../../store/ducks/auth";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const LoginForm = ({ handleSubmit, openForgotPasswordForm }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const onSubmitCallback = (values) => {
    const payload = {
      formData: values,
    };

    dispatch(authenticate(payload));
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((previous) => !previous);
  };

  return (
    <>
      <Logo src={logo} alt="Logo Utransfer" />
      <FormInformation>
        Insira suas credenciais para acessar o sistema de roteirização.
      </FormInformation>
      <form
        onSubmit={handleSubmit(onSubmitCallback)}
        style={{ width: "370px" }}
      >
        <Field
          name="username"
          className="Form-Field"
          component={LoginField}
          props={{ type: "email", label: "Digite aqui o seu e-mail" }}
        />
        <Field
          name="password"
          className="Form-Field"
          component={LoginField}
          props={{
            showPassword,
            handleClickShowPassword,
            label: "Digite aqui a sua senha",
            type: showPassword ? "text" : "password",
          }}
          label="Senha"
        />

        <FormSubmitBtn
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress color="inherit" size={22} />
          ) : (
            <Text color={"#000"}>Entrar</Text>
          )}
        </FormSubmitBtn>

        <FormRow margin={"15px 0px"}>
          <Text
            onClick={openForgotPasswordForm}
            color={"#2a93a7"}
            cursor={"pointer"}
          >
            Esqueceu a senha?
          </Text>
        </FormRow>

        <Divider />

        <FormRow margin={"15px 0px 0px"}>
          {/* <Link style={{ textDecoration: "none" }}> */}
          <Text cursor={"auto"} color={"#14454E"}>
            Política de Privacidade
          </Text>
          {/* </Link> */}
        </FormRow>
      </form>
    </>
  );
};

export default reduxForm({
  form: "login",
})(LoginForm);
