import React from "react";
import { useSelector } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";

import { useStyles } from "../styles";

export default function FlespiAlerts({ device }) {
  const classes = useStyles();

  const alertsFlespi = useSelector((store) => store.monitoringMap.alertsFlespi);

  return alertsFlespi.filter(
    (alert) => alert.needTreatment && alert.deviceId === device.device.id
  ).length <= 0 ? (
    <CheckIcon className={classes.iconCheckAlert} fontSize="small" />
  ) : (
    <IconButton size="small" className={classes.iconButtonAlert}>
      <span className={classes.Numbericon}>!</span>
    </IconButton>
  );
}
