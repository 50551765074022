import React, { useEffect, useState } from "react";
import { Marker, Polygon } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import Geocode from "react-geocode";

import alert from "../../assets/Base_Crise.png";
import { useGoogleMapsKey } from "../../hooks/useGoogleMapsKey";

import * as transfersProvider from "../../infra/http/transfersProvider";
import lodash from "lodash";
import decodePolyline from "decode-google-map-polyline";

export default function AlertPin() {
  const googleMapsKey = useGoogleMapsKey();
  const [, setValidation] = useState(false);
  const [riskZones, setRiskZones] = useState([]);

  const position = useSelector((store) => store.monitoringMap.centerMap);
  const messages = useSelector((store) => store.monitoringMap.messagesAreas);

  const validateRender = useSelector(
    (store) => store.monitoringMap.validateAlertPin
  );

  const [alertText, setAlertText] = useState("");

  const transferId = useSelector(
    (store) => store.monitoringMap.transferIdTreatment
  );

  const optionsRiskZone = {
    fillColor: "red",
    fillOpacity: 0.2,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };

  Geocode.setApiKey(googleMapsKey);
  Geocode.setLanguage("pt");

  useEffect(() => {
    const polygonRiskAre = async () => {
      if (!transferId) {
        return [];
      }

      const stepRisksData = await transfersProvider.getStepsRisks({
        transferId,
      });

      const enterRiskZone = messages;
      const { id } = enterRiskZone;

      const stepRisksDataFilter = stepRisksData.risk_layers.filter(
        (risk_layers) => risk_layers.id == id
      );

      const newArrayRiskZone = lodash.flattenDeep(stepRisksDataFilter);
      const polygonCoordinates = newArrayRiskZone.map(
        (riskZone) => riskZone.polygon.coordinates
      );

      setRiskZones(lodash.flattenDeep(polygonCoordinates));
    };
    polygonRiskAre();
  }, [validateRender]);

  useEffect(() => {
    Geocode.fromLatLng(position.lat, position.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAlertText(address);
      },
      (erro) => {
        console.log("erro: ", erro);
      }
    );
    setValidation(validateRender);
  }, [position]);

  return (
    <>
      {validateRender
        ? lodash.flattenDeep(riskZones).map((riskZones, index) => {
            return (
              <li key={index}>
                <Marker
                  title={alertText}
                  position={position}
                  icon={alert}
                  scale
                />
                <Polygon
                  paths={decodePolyline(riskZones)}
                  options={optionsRiskZone}
                />
              </li>
            );
          })
        : null}
    </>
  );
}
