import React, { useEffect, useState } from "react";
import { toast } from "material-react-toastify";
import { useDispatch } from "react-redux";
import { Polygon } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import decodePolyline from "decode-google-map-polyline";
import lodash from "lodash";

import * as transfersProvider from "../../infra/http/transfersProvider";
import Logger from "../../commons/utils/logger";
import {
  addStepRouteAction,
  addStopPointAction,
} from "../../store/ducks/monitoringMap";

const moduleLogger = Logger.createInitialLogger("RiskZones");

export default function RiskZones() {
  const [riskZones, setRiskZones] = useState([]);
  const devices = useSelector((store) => store.monitoringMap.devices);
  const transfersActive = useSelector(
    (store) => store.monitoringMap.transferActive
  );
  const visibilityRoutes = useSelector(
    (store) => store.monitoringMap.visibilityRoute
  );
  const dispatch = useDispatch();
  const optionsRiskZone = {
    fillColor: "red",
    fillOpacity: 0.2,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };

  useEffect(() => {
    const fetchStepRisksData = async () => {
      const transfers = [];
      devices.forEach((device) => {
        const transferValidation = lodash.findIndex(
          transfersActive,
          (transfer) => transfer.id === device.transfer?.id
        );
        const visibilityValidation = lodash.findIndex(
          visibilityRoutes,
          (transfer) => transfer.id === device.device.id
        );
        if (
          device.transfer?.id !== null &&
          visibilityValidation >= 0 &&
          transferValidation >= 0
        ) {
          transfers.push(device.transfer?.id);
        }
      });

      if (transfers.length === 0) {
        setRiskZones([]);
        dispatch(addStopPointAction([]));
        dispatch(addStepRouteAction(""));
        return;
      }

      const stopPoints = [];
      const stepRoute = [];
      const riskZones = [];

      for (const transferId of transfers) {
        let stepRisksData = null;
        try {
          stepRisksData = await transfersProvider.getStepsRisks({ transferId });
        } catch (error) {
          moduleLogger(stepRisksData);
          toast.error("Falha na busca por dados de rota...");
          return;
        }

        const newStepRoute = stepRisksData.steps_polyline;
        const newStopPoints = stepRisksData.stop_points;

        const newRiskZones = lodash.flattenDeep(stepRisksData.risk_layers);
        const newRiskZonesPolygonCoordinates = newRiskZones.map(
          (riskZone) => riskZone.polygon.coordinates
        );

        stopPoints.push(newStopPoints);
        stepRoute.push(newStepRoute);
        riskZones.push(newRiskZonesPolygonCoordinates);
      }

      dispatch(addStepRouteAction(lodash.flattenDeep(stepRoute)));
      dispatch(addStopPointAction(lodash.flattenDeep(stopPoints)));
      setRiskZones(lodash.flattenDeep(riskZones));
    };

    fetchStepRisksData();
  }, [transfersActive, visibilityRoutes, devices, dispatch]);

  return (
    <>
      {riskZones.length > 0
        ? lodash.flattenDeep(riskZones).map((riskZones, index) => {
            return (
              <li key={index}>
                <Polygon
                  paths={decodePolyline(riskZones)}
                  options={optionsRiskZone}
                />
              </li>
            );
          })
        : null}
    </>
  );
}
