import { useState, useEffect, useRef } from "react"

export const useEffectOptionalDependencies = (
  callback,
  dependencies = [],
  optionalDependencies = {},
) => {
  const optionalDependenciesRef = useRef(optionalDependencies)
  optionalDependenciesRef.current = optionalDependencies

  useEffect(() => {
    return callback(optionalDependenciesRef.current)
    // eslint-disable-next-line
  }, dependencies)
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth ?? null,
        height: window.innerHeight ?? null,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize);
  }, [])

  return windowSize;
}