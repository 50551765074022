import React, { Fragment } from "react";
import { Text } from "@react-pdf/renderer";

import { Row } from "../Row";
import { Col } from "../Col";
import { styles } from "./styles";
import { letters } from "../../../../commons/utils/text";

export const Table = ({ legs }) => {
  const hasWaypoints = legs.length > 1;

  return (
    <>
      <Row fixed>
        {hasWaypoints && <Col width={30}></Col>}
        <Col>
          <Text style={styles.text}>Instrução</Text>
        </Col>
        <Col width={50}>
          <Text style={styles.text}>Distância</Text>
        </Col>
        <Col width={50} last>
          <Text style={styles.text}>Duração</Text>
        </Col>
      </Row>

      {legs.map((leg, legIndex) => {
        return (
          <Fragment key={`leg-${legIndex}`}>
            {hasWaypoints && (
              <Row
                wrap={false}
                customcustomStyle={{ backgroundColor: "#e3e3e3" }}
              >
                <Col width={30} customStyle={styles.colCentralized}>
                  <Text style={styles.stepText}>
                    {letters[legIndex].toUpperCase()}
                  </Text>
                </Col>
                <Col customStyle={styles.colVerticallyCentered}>
                  <Text style={styles.text}>
                    {`${leg.start_address} X ${leg.end_address}`}
                  </Text>
                </Col>
                <Col width={50} customStyle={styles.colVerticallyCentered}>
                  <Text style={styles.text}>{leg.distance.text}</Text>
                </Col>
                <Col width={50} customStyle={styles.colVerticallyCentered} last>
                  <Text style={styles.text}>{leg.duration.text}</Text>
                </Col>
              </Row>
            )}
            {leg.steps.map((step, stepIndex) => (
              <Row
                key={`leg-${legIndex}-step-${stepIndex}`}
                last={
                  leg.steps.length - 1 === stepIndex &&
                  legs.length - 1 === legIndex
                }
                wrap={false}
              >
                {hasWaypoints && <Col width={30}></Col>}
                <Col customStyle={styles.colVerticallyCentered}>
                  <Text style={styles.text}>
                    {step.instructions.replace(/<\/?[^>]+(>|$)/g, "")}
                  </Text>
                </Col>
                <Col width={50} customStyle={styles.colVerticallyCentered}>
                  <Text style={styles.text}>{step.distance.text}</Text>
                </Col>
                <Col width={50} customStyle={styles.colVerticallyCentered} last>
                  <Text style={styles.text}>{step.duration.text}</Text>
                </Col>
              </Row>
            ))}
          </Fragment>
        );
      })}
    </>
  );
};
