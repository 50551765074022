import React from 'react'
import {
  Edit as EditIcon,
} from '@material-ui/icons'

import useStyles from './styles'

const InlineContent = ({
  content,
  isHovering,
  editButtonClickHandler,
  editButtonBackgroundColor = null,
}) => {
  const classes = useStyles({
    visible: isHovering,
    editButtonBackgroundColor,
  })

  return (
    <div className={classes.contentContainer}>
      <span className={classes.contentField}>
        {content}
      </span>
      <EditIcon
        onClick={editButtonClickHandler}
        classes={{
          root: classes.editButton,
        }}
      />
    </div>
  )
}

export default InlineContent