export const Types = {
  OPEN_SUCCESS_SNACKBAR: "OPEN_SUCCESS_SNACKBAR",
  OPEN_ERROR_SNACKBAR: "OPEN_ERROR_SNACKBAR",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
  CLEAR_SNACKBAR_STATE: "CLEAR_SNACKBAR_STATE",
};

const INITIAL_VALUES = {
  open: false,
  type: "",
  message: "",
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.OPEN_SUCCESS_SNACKBAR:
      return {
        ...state,
        open: true,
        type: "success",
        message: action.payload.message,
      };
    case Types.OPEN_ERROR_SNACKBAR:
      return {
        ...state,
        open: true,
        type: "error",
        message: action.payload.message,
      };
    case Types.CLOSE_SNACKBAR:
      return { ...state, open: false, type: "" };

    case Types.CLEAR_SNACKBAR_STATE:
      return { ...state, open: false, type: "", message: "" };

    default:
      return state;
  }
};

export const cleanSnackBarAction = () => ({
  type: Types.CLEAR_SNACKBAR_STATE,
});
