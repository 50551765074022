/**
 * @param {Array<{lat: number, lng: number}>} coordinates
 * @returns {string}
 */
export const coordinatesToPolyline = (coordinates) => {
  const toEncode = coordinates.map(
    ({ lat, lng }) => new window.google.maps.LatLng(lat, lng)
  );
  return window.google.maps.geometry.encoding.encodePath(toEncode);
};

/**
 * @param {string} polyline
 * @returns {Array<{lat: number, lng: number}>}
 */
export const polylineToCoordinates = (polyline) => {
  const coordinates = window.google.maps.geometry.encoding.decodePath(polyline);
  return coordinates.map(({ lat, lng }) => ({
    lat: lat(),
    lng: lng(),
  }));
};

export function nextCharacter(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1);
}

export const collectionLetters = () => {
  let letters = ["", "A"];
  const collection = [];

  while (collection.length < 50) {
    collection.push(letters.join(""));
    if (letters[1] === "Z") {
      letters[0] = letters[0] === "" ? "A" : nextCharacter(letters[0]);
    }
    letters[1] = letters[1] === "Z" ? "A" : nextCharacter(letters[1]);
  }

  return collection;
};
