import { useSelector } from "react-redux";
import { isProduction } from "../commons/utils/app";

export const useGoogleMapsKey = () => {
  const customerGoogleMapsKey = useSelector(
    (store) => store.auth?.user?.customer?.google_maps_key
  );

  if (!isProduction()) {
    return process.env.REACT_APP_MAPS_KEY;
  }

  return customerGoogleMapsKey || process.env.REACT_APP_MAPS_KEY;
};
