import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

export default function DeviceActive({ device }) {
  return device.device.active ? (
    <FiberManualRecordIcon
      style={{ color: "green", marginLeft: "15px" }}
      fontSize="small"
    />
  ) : (
    <FiberManualRecordIcon
      style={{ color: "red", marginLeft: "15px" }}
      fontSize="small"
    />
  );
}
