export const fromRiskZoneTypeApi = (data) => {
  return {
    id: data.id,
    name: data.name,
    icon: data.icon,
    color: data.color,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    deletedAt: data.deleted_at,
  };
};
