import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import styles from "./styles";

const Identifier = ({
  placeholder,
  value = undefined,
  options = [],
  getOptionLabel = (option) => option?.identifier ?? "",
  onChange = () => {},
}) => {
  const showInformation = useSelector(
    (store) => store.reporting.disableIdentifier
  );

  return (
    <Autocomplete
      disabled={showInformation}
      options={options}
      style={styles.autocompleteField}
      getOptionLabel={getOptionLabel}
      value={{ identifier: value }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} variant="outlined" />
      )}
    />
  );
};

export default Identifier;
