import React, { useCallback, useState } from "react";

export const useRiskZonesPolygon = () => {
  const [mouveIsInside, setMouseIsInside] = useState(false);
  const [infoWindow, setCurrentInfoWindow] = useState({});

  const handleMouseOver = useCallback(
    () => setMouseIsInside(true),
    [setMouseIsInside]
  );

  const handleCloseClick = useCallback(() => {
    setMouseIsInside(false);
    setCurrentInfoWindow({});
  }, [setCurrentInfoWindow, setMouseIsInside]);

  const handleMouseOut = useCallback(() => {
    setCurrentInfoWindow({});
    setMouseIsInside(false);
  }, [setCurrentInfoWindow]);

  const handleMouseMove = useCallback(
    (event, polygon) => {
      if (mouveIsInside) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const body = <>{polygon.name}</>;

        setCurrentInfoWindow({ lat, lng, body });
      }
    },
    [mouveIsInside]
  );

  return {
    handleMouseOver,
    handleMouseOut,
    infoWindow,
    handleCloseClick,
    handleMouseMove,
  };
};
