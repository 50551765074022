import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  tableCell: {
    color: "#000",
    backgroundColor: "#F4F4F4",
  },
  tableHeaderCell: {
    borderBottom: "none",
    fontWeight: 700,
  },
}));
