import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Row } from "../Row";
import { Col } from "../Col";

import { styles } from "./styles";

export const Subheader = ({
  event,
  startAddress,
  endAddress,
  distance,
  duration,
}) => {
  return (
    <View style={styles.subheader}>
      <Row>
        <Col last customStyle={styles.flexColumn}>
          <Text style={styles.label}>Evento:</Text>
          <Text style={styles.text}>{event}</Text>
        </Col>
      </Row>
      <Row>
        <Col customStyle={styles.flexColumn}>
          <Text style={styles.label}>Local de Partida:</Text>
          <Text style={styles.text}>{startAddress}</Text>
        </Col>
        <Col last customStyle={styles.flexColumn}>
          <Text style={styles.label}>Local de Destino:</Text>
          <Text style={styles.text}>{endAddress}</Text>
        </Col>
      </Row>
      <Row last>
        <Col customStyle={styles.flexColumn}>
          <Text style={styles.label}>Distancia:</Text>
          <Text style={styles.text}>{distance}</Text>
        </Col>
        <Col last customStyle={styles.flexColumn}>
          <Text style={styles.label}>Duração:</Text>
          <Text style={styles.text}>{duration}</Text>
        </Col>
      </Row>
    </View>
  );
};
