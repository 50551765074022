import styled from "styled-components";

export const Div = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DivMap = styled.div`
  heigth: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivInfos = styled.div`
  width: 100%;
  heigth: 100%;

  display: flex;
  flex-direction: column;
`;

export const DivTitle = styled.div`
  padding: 18px;
`;

export const DivInputs = styled.div`
  width: 100%;
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DivButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 24px;
  gap: 0 20px;
`;
