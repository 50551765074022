import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DirectionsRenderer, DirectionsService } from "@react-google-maps/api";
import {
  finishLoadingDirectionsAction,
  setDirectionsAction,
  startLoadingDirectionsAction,
} from "../../../store/ducks/scripting";

import iconWhenFromAndToAreTheSame from "../../../assets/IconGoogleMaps.png";

export const Directions = () => {
  const currentTransfer = useSelector(
    (state) => state.transfer.currentTransfer
  );
  const waypoints = useSelector((state) => state.scripting.waypoints);
  const directions = useSelector((state) => state.scripting.directions);
  const canRenderDirectionsService = useSelector(
    (state) => state.scripting.canRenderDirectionsService
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startLoadingDirectionsAction());

    return () => {
      dispatch(setDirectionsAction(null));
    };
  }, []);

  const directionsServiceCallback = useCallback(
    (result, status) => {
      if (!canRenderDirectionsService) return;

      if (status === "OK") {
        dispatch(finishLoadingDirectionsAction());
        dispatch(setDirectionsAction(result));
      } else {
        console.log(`Erro: ${result}`);
      }
    },
    [canRenderDirectionsService]
  );

  return (
    <>
      <DirectionsService
        callback={(result, status) => directionsServiceCallback(result, status)}
        options={{
          origin: currentTransfer.from,
          destination: currentTransfer.to,
          travelMode: "DRIVING",
          waypoints: waypoints.map((waypoint) => ({
            location: waypoint.location,
          })),
        }}
      />

      <DirectionsRenderer
        directions={directions}
        options={{
          markerOptions: {
            animation: 2,
            draggable: false,
            icon:
              currentTransfer.from === currentTransfer.to
                ? iconWhenFromAndToAreTheSame
                : undefined,
          },
        }}
      />
    </>
  );
};
