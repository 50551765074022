import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";

import { Content } from "./ButtonRegisterStyles";
import FormRegister from "./formRegister";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Cadastro", "Roteirização", "Vinculação"];
}

const styles = (theme) => ({
  root: {
    width: 600,
    fullWidth: true,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "#06B0CF" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs() {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const steps = getSteps();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div id="modal-screen">
      <Button
        style={{
          width: 213,
          height: 48,
          backgroundColor: "#06B0CF",
          borderRadius: 10,
          opacity: 1,
        }}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        Criar Transfers
      </Button>
      <Dialog
        style={{ zIndex: 2 }}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Button onClick={handleClose}>
            {" "}
            <ChevronLeftIcon
              fontSize="large"
              style={{ color: "#06B0CF" }}
            />{" "}
          </Button>{" "}
          Novo Transfer | <strong>Cadastro</strong>
        </DialogTitle>
        <DialogContent dividers>
          <div id="stepper" className={classes.root}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton
                    onClick={handleStep(index)}
                    disabled
                    completed={completed[index]}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
          <br />

          <div id="Redux-Forms">
            <FormRegister />
          </div>
        </DialogContent>
        <Content>
          <DialogActions>
            {/* <Button
        autoFocus onClick={handleClose}
        style={{color: "#06B0CF", borderRadius: 12, border:"none", boxShadow: '0px 3px 6px #00000029'}}
        variant="outlined"
        color="primary">
            Somente Salvar
          </Button>
          <Button
          style={{backgroundColor: "#06B0CF", borderRadius: 12, boxShadow: '0px 3px 6px #00000029'}}
          variant="contained"
          autoFocus onClick={handleClose}
          color="primary"
          href="/scripting">
            Roteirizar
          </Button> */}
            <br />
            <br />
          </DialogActions>
        </Content>
      </Dialog>
    </div>
  );
}
