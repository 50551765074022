import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getGeoAnalyticsRoutes } from "../../../services/geoanalyticsApi";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { addWaypointAction } from "../../../store/ducks/scripting";
import { addFromaction, addToaction } from "../../../store/ducks/transfer";
import { toast } from "material-react-toastify";

export default function App() {
  const [geoanalyticsRoutes, setGeoanalyticsRoutes] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchGeoanalyticsRoutes = async () => {
      try {
        setGeoanalyticsRoutes(await getGeoAnalyticsRoutes());
      } catch {
        toast.error(`Falha ao obter as rotas importadas`);
      }
    };
    fetchGeoanalyticsRoutes();
  }, []);

  const handleChange = (value) => {
    if (value.metadata.waypoints.length > 0) {
      value.metadata.waypoints.map((waypoint) => {
        const waypointData = {
          id: Math.random().toString(36),
          location: waypoint.location,
          stopover: false,
          title: waypoint.title,
          duration: "",
          position: waypoint.position,
        };

        dispatch(addWaypointAction(waypointData));
      });
    }

    dispatch(addFromaction(value.metadata.information.start_address));
    dispatch(addToaction(value.metadata.information.end_address));
  };

  return (
    <Autocomplete
      onChange={(e, value) => handleChange(value)}
      options={geoanalyticsRoutes}
      getOptionLabel={(option) => option.name}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ width: "100%" }}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
      )}
    />
  );
}
