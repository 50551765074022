import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import ModalConfigureTransfer from "../Filter/Register/ModalConfigurationTransfer";

import ModalStatus from "../Filter/Register/modalStatusPreConfigured";
import ModalBond from "../Filter/Register/modalBond";
import * as transfersProvider from "../../infra/http/transfersProvider";
import SharedTable from "../SharedTable";
import constants from "../../commons/constants";
import { genericAction } from "../../store/ducks/transfer";
import { Types } from "../../store/ducks/transfer";

export default function CollapsibleTable() {
  const classes = useStyles();
  const sharedTableRef = useRef(null);
  const isBeforeFirstFetchEffectRef = useRef(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const unity = useSelector((store) => store.transfer.unityFetch);
  const event = useSelector((store) => store.transfer.eventFetch);
  const from = useSelector((store) => store.transfer.fromFetch);
  const to = useSelector((store) => store.transfer.toFetch);
  const responsible = useSelector((store) => store.transfer.responsibleFetch);
  const customerUnitIds = useSelector(
    (store) => store.transfer.customerUnitIdFetch
  );
  const active = useSelector((store) => store.transfer.active);
  const token = useSelector((store) => store.auth.token.accessToken);
  const permissions = useSelector((store) => store.auth.permissions);
  useEffect(() => {
    if (isBeforeFirstFetchEffectRef.current) {
      isBeforeFirstFetchEffectRef.current = false;
      return;
    }
    if (sharedTableRef?.current)
      sharedTableRef.current.fetchRowsData({
        waitTimeForAnotherCall: 1000,
        shouldGoBackToFirstPage: true,
      });
  }, [token, event, unity, from, to, responsible, customerUnitIds, active]);

  if (permissions.permissions === undefined) {
    history.push("/");
    return null;
  }

  const fetchListData = async ({ currentPage, rowsPerPage }) => {
    const { transfersData, totalRows } =
      await transfersProvider.getPreConfiguredTransfers({
        page: currentPage,
        perPage: rowsPerPage,
        identifier: event,
        from,
        to,
        unity,
        responsible,
        customerUnitId: customerUnitIds,
        active,
      });

    const transfersIds = transfersData.map(
      (currentTransfer) => currentTransfer.id
    );
    dispatch(
      genericAction(Types.HANDLE_TRANSFER_PRE_CONFIGURED_ID, transfersIds)
    );

    const rowsData = transfersData.map((row) => [
      row.id,
      row.id,
      new Date(
        row.pre_configured_transfer_settings?.from_date
      ).toLocaleDateString("pt-br", { timeZone: "UTC" }),
      new Date(
        row.pre_configured_transfer_settings?.to_date
      ).toLocaleDateString("pt-br", { timeZone: "UTC" }),
      row.name,
      row.from,
      row.to,
      row.unity,
      row.responsible,
      <ModalBond
        transferData={row}
        onSuccess={sharedTableRef?.current?.fetchRowsData}
      />,
      <ModalStatus transferData={row} />,
      <ModalConfigureTransfer
        data={row}
        onSuccess={sharedTableRef?.current?.fetchRowsData}
      />,
    ]);
    return {
      rowsData,
      totalRows,
    };
  };

  const canViewRoutes =
    permissions?.permissions?.data.includes("can-view-routes");

  if (!canViewRoutes) {
    alert("Você não possui permissão para acessar esses dados");
    history.push("/monitoringmap");
    return (
      <center>
        <span>Você não possui permissão para acessar esses dados.</span>
      </center>
    );
  }

  const tableHeaders = [
    "ID",
    "Data de início",
    "Data de fim",
    "Evento",
    "Local de Partida",
    "Local de Destino",
    "Unidade",
    "Responsável",
    "Vínculo",
    "Status",
    "Configuração",
  ];

  return (
    <div className={classes.root}>
      <SharedTable
        cellHeaders={tableHeaders}
        noRowsDataMessage={constants.tables.DEFAULT_NO_ROWS_MESSAGE}
        withPagination={true}
        rowsDataFetcher={fetchListData}
        ref={sharedTableRef}
      />
    </div>
  );
}
