import {
  styled as styledMUI,
  Typography,
  TextField,
  Button,
  Container,
  Box,
  Paper,
  CardContent,
  Fab,
} from "@mui/material";

export const CustomPaper = styledMUI(Paper)(() => ({
  marginTop: "8px",
  maxWidth: "300px",
  padding: "0.75rem 0.75rem 0.62rem 0.75rem",
  borderRadius: "0.625rem",
  height: "37rem",
  overflowX: "hidden",
  overflowY: "scroll",
  position: "absolute",
  zIndex: 5,
  maxHeight: "37rem",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#3AC4E0",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#2A93A7",
  },
}));

export const DivMapStandard = styledMUI(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  padding: "0.625rem 1rem 0 0",
  alignItems: "center",
}));

export const DivMapSatellite = styledMUI(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  padding: "0.625rem 1rem 0.625rem 0",
  alignItems: "center",
}));

export const CustomDivAmounte = styledMUI(Box)(() => ({
  display: "flex",
  height: "1.5rem",
  width: "1.5rem",
  padding: "0.625rem",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#3AC4E0",
  borderRadius: "0.3125rem",
}));
