import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import * as deviceApiService from "../../services/devicesService";
import { GLOBO_CUSTOMER_ID } from "../../commons/constants/customers";
import {
  addCustomerUnitAct,
  addFromAct,
  addResponsibleAct,
  addToAct,
  addUnityAct,
} from "../../store/ducks/transfer";
import { CustomTextField } from "../TableTransferNew/style";

export const FilterFields = () => {
  const customerId = useSelector((store) => store.auth.customer.customer);
  const [customerUnits, setCustomerUnits] = useState([]);

  useEffect(() => {
    const loadFormData = async () => {
      setCustomerUnits(await deviceApiService.getCustomerUnits(customerId));
    };

    if (customerId === GLOBO_CUSTOMER_ID) {
      loadFormData();
    }
  }, []);

  const dispatch = useDispatch();

  const handleChangeSearchTransfersByUnity = (e) => {
    const value = e.target.value;
    dispatch(addUnityAct(value));
  };

  const handleChangeSearchTransfersByFrom = (e) => {
    const value = e.target.value;
    dispatch(addFromAct(value));
  };

  const handleChangeSearchTransfersByTo = (e) => {
    const value = e.target.value;
    dispatch(addToAct(value));
  };

  const handleChangeSearchTransfersByResponsible = (e) => {
    const value = e.target.value;
    dispatch(addResponsibleAct(value));
  };

  const handleChangeSearchTransfersByCustomerUnit = (e) => {
    const value = e.target.value;
    dispatch(addCustomerUnitAct(value));
  };

  return (
    <>
      {customerId !== GLOBO_CUSTOMER_ID ? (
        <CustomTextField
          onChange={handleChangeSearchTransfersByUnity}
          name="searchTransfersByEvent"
          id="filled-product"
          label="Unidade"
          type="search"
          size="small"
          variant="outlined"
          style={{ marginRight: 10 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <FormControl style={{ marginRight: 10 }} size="small">
          <Select
            variant="outlined"
            displayEmpty
            defaultValue={""}
            onChange={handleChangeSearchTransfersByCustomerUnit}
          >
            <MenuItem key={"customerUnit"} value="">
              <em>Selecione uma unidade</em>
            </MenuItem>
            {customerUnits.map((customerUnit, index) => (
              <MenuItem
                key={"customerUnit-" + customerUnit.id}
                value={customerUnit.id}
              >
                {customerUnit.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <CustomTextField
        onChange={handleChangeSearchTransfersByFrom}
        name="searchTransfersByFrom"
        id="filled-unity"
        label="Local de Partida"
        type="search"
        size="small"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <CustomTextField
        onChange={handleChangeSearchTransfersByTo}
        id="filled-unity"
        label="Local de Destino"
        type="search"
        size="small"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <CustomTextField
        onChange={handleChangeSearchTransfersByResponsible}
        id="filled-responsible"
        label="Responsável"
        type="search"
        size="small"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
