import React from 'react';
import {
  parseISO,
  formatDistance,
  isValid as checkIsValidDate
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz'
import { pt } from 'date-fns/locale'

import { transferStates } from './constants';

export default function ProgressMessage({
  transferState,
  device,
}) {

  const formatDateFromNow = dateString => {
    if (dateString === null) 
      return null

    const date = parseISO(dateString)

    if (!checkIsValidDate(date))
      return null

    const now = zonedTimeToUtc(new Date(), 'America/Sao_Paulo')
    const dateFormated = formatDistance(date, now, { locale: pt })
    
    return dateFormated
  }

  switch (transferState) {
    case transferStates.STARTING:
      return <span>Iniciando Transfer</span>
    
    case transferStates.ENDING:
      return <span>Finalizando Transfer</span>
    
    case transferStates.ONGOING:
      const startedSince = formatDateFromNow(device.transfer?.start_date)
      return <span>Iniciado há {startedSince}</span>
    
    case transferStates.ONGOING_MISSING_DATA:
      return <span>Iniciado há instantes...</span>
    
    case transferStates.NOT_STARTED:
      return <span>Início em {formatDateFromNow(device.transfer?.scheduled_date)}</span>
    
    case transferStates.LATE_TO_START:
      return <span>Atrasado há {formatDateFromNow(device.transfer?.scheduled_date)}</span> 
    
    case transferStates.ENDED:
      return <span>Transfer encerrado</span>
    
    case transferStates.NO_TRANSFER:
    default:
      return null
  }
}