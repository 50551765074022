import { areasApi } from "..";
import { appId } from "../../../commons/constants/app";
import { fromPublicEntityTypeApi } from "../mappers/public-entity-type.mapper";

export const getPublicEntityTypes = async ({ groupIds }) => {
  const response = await areasApi.get("/public-entities", {
    params: {
      application_id: appId,
      groupIds,
    },
  });

  return response.data.map(fromPublicEntityTypeApi);
};
