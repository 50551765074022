import devicesAPI from "../../services/devices";
import * as exceptions from "../../commons/exceptions";
import codes from "../../commons/constants/codes";

export const getPOIs = async ({ customerId, page = null, perPage = null }) => {
  const params = {};

  if (page !== null && perPage !== null) params.perPage = perPage;
  params.page = page;

  try {
    const { data: poisData } = await devicesAPI.get(
      `/customer/${customerId}/poi`,
      {
        params,
      }
    );

    return poisData;
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const createPOI = async ({ customerId, data }) => {
  try {
    await devicesAPI.post(`customer/${customerId}/poi`, data);
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const updatePOI = async ({ customerId, id, data }) => {
  try {
    await devicesAPI.put(`customer/${customerId}/poi/${id}`, data);
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};

export const deletePOI = async ({ customerId, id }) => {
  try {
    await devicesAPI.delete(`customer/${customerId}/poi/${id}`);
  } catch (error) {
    throw exceptions.createException({
      code: codes.error.API_REQUEST_FAILED,
      error,
    });
  }
};
