import React from 'react';
import { Field } from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

import styles from './styles';
import CheckboxInput from './CheckboxInput';

const CheckboxFields = ({
  fields,
  legend,
  labels,
  classes,
  meta: { error }
}) => (
  <FormControl
    component="fieldset"
    className={classes.customDaysContainer}
  >
    <FormLabel component="legend">{legend}</FormLabel>
    <Grid container>
      {fields.map((field, index) => (
        <Field
          key={field}
          name={field}
          component={CheckboxInput}
          normalize={value => ({
            value: fields.get(index).value,
            checked: value
          })}
          props={{
            label: labels[index]
          }}
        />
      ))}
    </Grid>
    
    {error && (
      <FormHelperText error>
        {error}
      </FormHelperText>
    )}
  </FormControl>
);

export default withStyles(styles)(CheckboxFields);
