const styles = {
  indicator: {
    display: 'flex',
    border: '1px solid #b8b8b8',
    borderRadius: 10,
    padding: '10px 20px',
  },
  number: {
    fontSize: 35,
    marginRight: 15,
    fontWeight: 'bold',
  },
  label: {
    fontSize: 16,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  }
};

export default styles;
