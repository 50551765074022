import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  Table,
  TableContainer,
  TablePagination,
  Paper,
} from "@material-ui/core";

import TableBody from "./TableBody";
import { TableToolbar } from "./tableToolbar";
import EnhancedTableHead from "./tableHead";
import * as devicesProvider from "../../../infra/http/devicesProvider";
import { useStyles } from "./styles";

const identifiersSelected = _.flattenDeep(
  JSON.parse(localStorage.getItem("@UTransfer/identifiersSelected"))
);

export default function EnhancedTable() {
  const classes = useStyles();
  const customer = useSelector((store) => store.auth.customer);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("update");
  const [selected, setSelected] = useState(identifiersSelected);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const devicesData = await devicesProvider.getDevices({
          customerId: customer.customer,
        });

        setDevices(devicesData);
      } catch (error) {}
    };
    fetchDevices();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => {
        return n.id;
      });

      setSelected(newSelecteds);
      return;
    }
    localStorage.removeItem("@UTransfer/identifiersSelected");

    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar selected={selected} numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={devices.length}
            />
            <TableBody
              page={page}
              order={order}
              orderBy={orderBy}
              devices={devices}
              selected={selected}
              rowsPerPage={rowsPerPage}
              setSelected={setSelected}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={devices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
