import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { Phone as PhoneIcon } from "@mui/icons-material";

import { CopyIconButton } from "../../CopyIconButton";
import { isValidPhone, onlyNumbers } from "../../../commons/utils/string";

export const PhoneItem = ({ label, value, copy }) => {
  if (isValidPhone(value)) {
    return (
      <ListItem
        sx={{ py: 0 }}
        disablePadding
        href={`tel:${onlyNumbers(value)}`}
        button
        component="a"
      >
        <ListItemIcon>
          <PhoneIcon />
        </ListItemIcon>
        <ListItemText
          primary={label}
          secondary={value}
          secondaryTypographyProps={{
            sx: {
              wordWrap: "break-word",
            },
          }}
        />
        {copy && (
          <ListItemSecondaryAction>
            <CopyIconButton text={value} edge="end" />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }

  return (
    <ListItem sx={{ py: 0 }}>
      <ListItemIcon>
        <PhoneIcon />
      </ListItemIcon>
      <ListItemText
        primary={label}
        secondary={value}
        secondaryTypographyProps={{
          sx: {
            wordWrap: "break-word",
          },
        }}
      />
      {copy && (
        <ListItemSecondaryAction>
          <CopyIconButton text={value} edge="end" />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
