import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import _ from "lodash";
import * as devicesProvider from "../../../infra/http/devicesProvider";
import { toast } from "material-react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import StepButton from "@material-ui/core/StepButton";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Container, Div } from "./stepperDevicesStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: 400,
    height: 230,
    overflow: "auto",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  papers: {
    borderRadius: 5,
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getSteps() {
  return ["Configuração", "Cadastro", "Roteirização", "Vinculação"];
}

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList({ transferData, onClose }) {
  const customerData = useSelector((store) => store.auth.customer);

  const [activeStep, setActiveStep] = useState(2);
  const [completed] = useState({});
  const steps = getSteps();

  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  let leftChecked = intersection(checked, left);
  let rightChecked = intersection(checked, right);
  const [open, setOpen] = React.useState(false);
  const [deviceFiltered, setDeviceFiltered] = useState("");
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [stateDevices, setStateDevices] = useState([]);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    async function printDevices() {
      const devices = await devicesProvider.getDevices({
        customerId: customerData?.customer,
      });

      const data = devices.map((device) => {
        if (device.contact === null || device.contact === "") {
          device.contact = "00000000000";

          return device;
        }
        return device;
      });

      setLeft(data);

      const associatedDevices = transferData.associated_devices
        ? transferData.associated_devices.map((device) => device.id)
        : transferData.pre_configured_transfer_settings.device_ids;

      const initialRight = associatedDevices?.map((deviceId) => {
        const data = devices.map((event) => {
          if (event.id === deviceId) {
            return event;
          } else return null;
        });

        return _.compact(data);
      });

      setStateDevices(associatedDevices);

      leftChecked = _.flattenDeep(initialRight);
      handleCheckedRightt(devices);
      setLoading(false);
    }
    printDevices().catch((error) =>
      console.log(`[stepperDevices] printDevices: '${error.message}'`)
    );
  }, []);

  const handleBond = () => {
    setSuccess(true);
    setDevicesToTransfer();
  };

  async function setDevicesToTransfer() {
    //debugger;
    try {
      const devicesIds = [];
      const devicesContacts = [];

      if (stateDevices.length > 0) {
        if (right.length > 0) {
          left.forEach((device) => {
            let j = 0;

            for (let i = 0; i < stateDevices.length; i++) {
              if (device.id === stateDevices[j].id) {
                devicesIds[j] = device.id;
                if (device.contact === null || device.contact === "") {
                  devicesContacts[j] = "00000000000";
                } else {
                  devicesContacts[j] = device.contact;
                }

                j++;
              }
            }
          });
        }
      }

      let dataToCreate = [];
      let dataIdPreConfigured = [];
      let dataToRemove = [];
      right.forEach((device) => {
        dataToCreate = [
          ...dataToCreate,
          {
            device_id: device.id,
            transfer_id: transferData.id,
            transfer_route_id: transferData.route_active_id,
            device_type_id: device.device_type_id,
          },
        ];
        if (transferData.pre_configured_transfer_settings) {
          dataIdPreConfigured = [
            ...dataIdPreConfigured,
            {
              pre_configured_transfer_settings_id:
                transferData.pre_configured_transfer_settings.id,
            },
          ];
        }
        dataToRemove = [
          ...dataToRemove,
          {
            transfer_id: transferData.id,
            device_id: device.id,
          },
        ];
      });
      if (transferData.associated_devices) {
        try {
          await devicesProvider.deleteDevices(transferData.id, dataToRemove);
        } catch (err) {
          toast.error("Erro ao editar vínculos");
        }

        if (dataToCreate.length > 0) {
          try {
            await devicesProvider.createDevices(dataToCreate);
          } catch (err) {
            toast.error("Erro ao vincular dispositivo");
          }
        }
      } else {
        try {
          await devicesProvider.deleteDevicesPreConfigured(
            transferData.pre_configured_transfer_settings.id,
            transferData.pre_configured_transfer_settings.device_ids
          );
        } catch (err) {
          toast.error("Erro ao editar vínculos");
        }

        if (dataIdPreConfigured.length > 0) {
          try {
            const devices = right.map((device) => ({
              device_id: device.id,
              transfer_id: transferData.id,
              transfer_route_id: transferData.route_active_id,
              device_type_id: device.device_type_id,
            }));
            await devicesProvider.createDevicesPreConfigured(
              transferData.pre_configured_transfer_settings.id,
              devices
            );
          } catch (err) {
            toast.error("Erro ao vincular dispositivo");
          }
        }
      }
    } catch (err) {
      toast.error("Erro ao vincular!");
    } finally {
      setSuccess(false);
      if (onClose) {
        onClose();
      }
    }
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <center>
      <div style={{ marginTop: "40vh" }} className={classes.papers}>
        <div>
          <Typography
            style={{ fontWeight: "bold" }}
            variant="subtitle2"
            gutterBottom
          >
            Device:{" "}
            {leftChecked.length > 0
              ? leftChecked[0].identifier
              : rightChecked.length > 0
              ? rightChecked[0].identifier
              : ""}
          </Typography>
        </div>
        <Typography
          style={{ fontWeight: "bold", marginTop: 15 }}
          variant="subtitle2"
          gutterBottom
        >
          Telefone:
        </Typography>
        <InputMask
          mask="99999999999"
          onChange={(e) =>
            leftChecked.length > 0
              ? (leftChecked[0].contact = e.target.value)
              : rightChecked.length > 0
              ? (rightChecked[0].contact = e.target.value)
              : ""
          }
        ></InputMask>
        <div>
          <Button
            style={{
              marginTop: 15,
              height: 30,
              widht: 50,
              backgroundColor: "#06B0CF",
              borderRadius: 8,
              boxShadow: "0px 3px 6px #00000029",
              fontSize: 11,
            }}
            variant="contained"
            autoFocus
            color="primary"
            onClick={handleClose}
          >
            Salvar edição
          </Button>
          <Button
            style={{
              marginTop: 15,
              marginLeft: 6,
              height: 30,
              color: "#06B0CF",
              backgroundColor: "#fff",
              borderRadius: 8,
              boxShadow: "0px 3px 6px #00000029",
              fontSize: 11,
            }}
            variant="contained"
            autoFocus
            color="primary"
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </center>
  );

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  const handleCheckedRightt = (e) => {
    setRight(right.concat(leftChecked));

    setLeft(not(e, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  function formatContact(contact) {
    let text = contact;
    const ddd = text.slice(0, 2);
    const parte1 = text.slice(2, 7);
    const parte2 = text.slice(7, 11);
    text = `(${ddd})${parte1}-${parte2}`;
    return text;
  }

  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items
          .filter((device) => {
            const deviceFilteredLowerCase = deviceFiltered.toLowerCase();

            const deviceIdentifierChecking = device.identifier
              .toLowerCase()
              .includes(deviceFilteredLowerCase);
            if (device.alias !== null) {
              const deviceAliasChecking = device.alias
                .toLowerCase()
                .includes(deviceFilteredLowerCase);
              return deviceAliasChecking || deviceIdentifierChecking;
            }

            return deviceIdentifierChecking;
          })
          .map((value) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>

                <ListItemText
                  id={labelId}
                  primary={
                    <div style={{ display: "flex" }}>
                      {value.alias || value.identifier}
                    </div>
                  }
                  secondary={
                    <>
                      <PermPhoneMsgIcon
                        style={{ color: "#333333", width: 15 }}
                      />{" "}
                      {formatContact(value.contact)}
                    </>
                  }
                ></ListItemText>

                <Button
                  onClick={handleOpen}
                  disabled={leftChecked.length >= 1 ? true : false}
                  variant="outlined"
                  color="secondary"
                  style={{
                    width: 0.5,
                    borderRadius: 50,
                    boxShadow: "0px 3px 6px #00000029",
                    backgroundColor: "#fff",
                    border: "none",
                  }}
                >
                  <CreateOutlinedIcon
                    fontSize="small"
                    style={{ color: "#333333" }}
                  />
                </Button>
              </ListItem>
            );
          })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <>
      <div id="stepper">
        <Modal
          style={{ zIndex: 3 }}
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>

        <Stepper nonLinear activeStep={activeStep + 1}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                disabled
                completed={completed[index]}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <br />

        <div>
          <Container>
            <Typography
              style={{ marginRight: 450 }}
              variant="subtitle1"
              gutterBottom
            >
              <strong>Disponíveis</strong>
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              <strong>Vinculados</strong>
            </Typography>
          </Container>
          <Div>
            <Grid item>
              <TextField
                className="search-devices"
                style={{ marginLeft: 137, width: 220 }}
                onChange={(e) => setDeviceFiltered(e.target.value)}
                label="Pesquisar Vínculo"
                type="search"
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Div>
          <br />
        </div>
      </div>
      {loading === false && success === false ? (
        <>
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            className={classes.root}
          >
            <Grid item>{customList(left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label="move all right"
                >
                  ≫
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label="move all left"
                >
                  ≪
                </Button>
              </Grid>
            </Grid>

            <Grid item>{customList(right)}</Grid>
          </Grid>
        </>
      ) : success === false && loading === true ? (
        <>
          <center>
            <div>
              <CircularProgress
                size={68}
                style={{ marginTop: 0, color: "#63a9ef" }}
              ></CircularProgress>
            </div>
            <span>Carregando dados</span>
          </center>
        </>
      ) : (
        <>
          <center>
            <div>
              <CircularProgress
                size={68}
                style={{ marginTop: 0, color: "#63a9ef" }}
              ></CircularProgress>
            </div>
            <span>Vinculando dispositivo</span>
          </center>
        </>
      )}
      <br />
      <center>
        <Button
          //type="submit"
          autoFocus
          onClick={handleBond}
          style={{
            marginRight: 19,
            width: 150,
            backgroundColor: "#06B0CF",
            color: "#fff",
            borderRadius: 8,
            border: "none",
            boxShadow: "0px 3px 6px #00000029",
          }}
          variant="outlined"
          color="primary"
        >
          Salvar
        </Button>
      </center>
    </>
  );
}
