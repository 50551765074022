import React, { useState, useRef, useEffect } from "react";

import { toast } from "material-react-toastify";
import * as devicesProvider from "../../../../infra/http/devicesProvider";
import InlineEditableContent from "../../../InlineEditableContent";

const DeviceAlias = ({
  deviceId,
  alias: defaultAlias,
  aliasWhenEmpty = "-",
  executeAfterUpdate = () => {},
}) => {
  const editableContentRef = useRef(null);
  const [alias, setAlias] = useState();

  useEffect(() => {
    setAlias(defaultAlias);
  }, [defaultAlias]);

  const editHandler = async (newAlias) => {
    try {
      await devicesProvider.updateAlias({
        id: deviceId,
        alias: newAlias,
      });

      executeAfterUpdate(newAlias);
      setAlias(newAlias);
    } catch (error) {
      toast.error("Atualização de apelido falhou");
    } finally {
      return true;
    }
  };

  return (
    <InlineEditableContent
      content={alias}
      ref={editableContentRef}
      editHandler={editHandler}
      editorPlaceholder="Apelido"
      submittingText="Enviando..."
      contentWhenEmpty={aliasWhenEmpty}
    />
  );
};

export default DeviceAlias;
