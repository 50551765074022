import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { Room as RoomIcon } from "@material-ui/icons";
import { CopyIconButton } from "../../CopyIconButton";

export const LocationTextItem = ({ label, value, copy }) => {
  return (
    <ListItem sx={{ py: 0 }}>
      <ListItemIcon>
        <RoomIcon style={{ color: "red" }} />
      </ListItemIcon>
      <ListItemText
        primary={label}
        secondary={value}
        secondaryTypographyProps={{
          sx: {
            wordWrap: "break-word",
          },
        }}
      />
      {copy && (
        <ListItemSecondaryAction>
          <CopyIconButton text={value} edge="end" />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
