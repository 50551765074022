import axios from "axios";

import { store } from "../store";

const devicesAPI = axios.create({
  baseURL: process.env.REACT_APP_DEVICES_API,
});

devicesAPI.interceptors.request.use((config) => {
  const state = store.getState();
  const accessToken = state?.auth?.token?.accessToken;

  if (accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;

  return config;
});

export const getCustomerDevices = async (customer) => {
  try {
    return await devicesAPI.get(`/customer/${customer}/devices`);
  } catch ({ response }) {
    return response;
  }
};

export default devicesAPI;
