import React from "react";
import { IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "../../components/Map/styles";
import { useSelector } from "react-redux";
import clsx from "clsx";
import Satellite from "../../assets/mapStyleSatellite.png";
import Roadmap from "../../assets/mapaStyleRoadmap.png";
import { useDispatch } from "react-redux";
import { openMapStyleDrawerAction } from "../../store/ducks/monitoringMap";

export default function ChangeMapType({ onMapTypeChange }) {
  const drawerOpen = useSelector(
    (state) => state.monitoringMap.openMapStyleDrawer
  );
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClickChange = (mapType) => {
    return () => {
      onMapTypeChange(mapType);
    };
  };

  const list = () => (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <strong>
        Tipo de Mapa{" "}
        <IconButton
          style={{ height: "5px", width: "5px", right: "-10px" }}
          onClick={() => dispatch(openMapStyleDrawerAction())}
        >
          <CloseIcon
            aria-label="close"
            fontSize="small"
            style={{ height: "20px" }}
          />
        </IconButton>
      </strong>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <img
            style={{
              cursor: "pointer",
              height: "40px",
              width: "40px",
              borderRadius: "5px",
            }}
            src={Satellite}
            size="small"
            onClick={handleClickChange("satellite")}
          />
          <strong>Satélite</strong>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              cursor: "pointer",
              height: "40px",
              width: "40px",
              borderRadius: "5px",
            }}
            src={Roadmap}
            size="small"
            onClick={handleClickChange("roadmap")}
          />
          <strong>Padrão</strong>
        </div>
      </div>
    </div>
  );
  return (
    <div
      variant="permanent"
      className={clsx(classes.drawer, !drawerOpen && classes.drawerDown)}
    >
      {list()}
    </div>
  );
}
