import React from "react";
import { useSelector } from "react-redux";
import {
  TableBody as TableBodyMaterialUI,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import { Check as CheckIcon } from "@material-ui/icons";

import DeviceStatus from "./DeviceStatus";
import UpdatedAt from "../../componentUtils/UpdatedAt";
import { getComparator, stableSort } from "../utils";
import DeviceBattery from "../../componentUtils/DeviceBattery";
import DeviceAlias from "../../componentUtils/DeviceAlias";

const TableBody = ({
  page,
  order,
  orderBy,
  devices,
  selected,
  setSelected,
  rowsPerPage,
}) => {
  const seeInactive = useSelector((store) => store.monitoring.seeInactive);
  const identifierSearch = useSelector(
    (store) => store.monitoring.identifierSearch
  );

  const seeInactivefilter = (device) => {
    if (seeInactive) return true;

    return device.active;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    if (selectedIndex === -1) setSelected([...selected, name]);
    else
      setSelected([
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ]);
  };

  const checkIsSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const numberEmptyRows =
    rowsPerPage - Math.min(rowsPerPage, devices.length - page * rowsPerPage);

  const devicesSortedAndPaginated = stableSort(
    devices
      .filter((device) => {
        const identifierLowerCase = identifierSearch.toLowerCase();

        const deviceIdentifierChecking = device.identifier
          .toLowerCase()
          .includes(identifierLowerCase);
        if (device.alias !== null) {
          const deviceAliasChecking = device.alias
            .toLowerCase()
            .includes(identifierLowerCase);
          return deviceAliasChecking || deviceIdentifierChecking;
        }

        return deviceIdentifierChecking;
      })
      .filter(seeInactivefilter),
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <TableBodyMaterialUI>
      {devicesSortedAndPaginated.map((device, index) => {
        const isItemSelected = checkIsSelected(device.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            onClick={(event) => handleClick(event, device.id)}
            role="checkbox"
            color="primary"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={device.id}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{ "aria-labelledby": labelId }}
              />
            </TableCell>
            <TableCell
              align="right"
              color="primary"
              id={labelId}
              scope="dispositivo"
              padding="none"
            >
              <DeviceStatus active={device.active} />
            </TableCell>
            <TableCell align="right">
              <CheckIcon style={{ color: "green" }} fontSize="small" />
            </TableCell>
            <TableCell align="left">{device.identifier}</TableCell>
            <TableCell align="left">
              <DeviceAlias deviceId={device?.id} alias={device?.alias} />
            </TableCell>
            <TableCell align="right">
              <DeviceBattery battery={device?.battery} />
            </TableCell>
            <TableCell align="left">
              <UpdatedAt takenAt={device.taken_at} />
            </TableCell>
          </TableRow>
        );
      })}
      {numberEmptyRows > 0 && (
        <TableRow style={{ height: 33 * numberEmptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBodyMaterialUI>
  );
};

export default TableBody;
