import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import {
  lighten,
  makeStyles,
  Toolbar,
  Typography,
  Tooltip,
  Button,
} from "@material-ui/core";

import Switcher from "../../FilterMonitoring/switcher";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
          flex: "1 1 100%",
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
          flex: "1 1 100%",
        },
  switch: {
    marginLeft: "72vw",
  },
}));

export const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, selected } = props;
  const history = useHistory();

  function handleSubmit(identifiersSelected) {
    localStorage.setItem(
      "@UTransfer/identifiersSelected",
      JSON.stringify(identifiersSelected)
    );

    history.push("/monitoringmap");
    window.location.reload(true);
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Tooltip title="Selecionar">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(selected)}
          >
            Selecionar
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Selecionar">
          <Button variant="contained" color="primary" disabled>
            Selecionar
          </Button>
        </Tooltip>
      )}

      {numSelected > 0 ? (
        <Typography
          className={classes.switch}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selecionados
        </Typography>
      ) : (
        <Typography
          className={classes.switch}
          variant="h7"
          id="tableTitle"
          component="div"
        >
          <Switcher />
        </Typography>
      )}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
