import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  styledTableCell: {
    color: "#000",
    fontSize: ".9rem",
    backgroundColor: "#fff",
    textAlign: "center",
  },

  styledTableHeaderCell: {
    color: "#000",
    fontSize: ".9rem",
    borderBottom: "none",
    fontWeight: "bold",
    backgroundColor: "#fafafa",
    paddingLeft: 40,
    textAlign: "center",
  },

  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
