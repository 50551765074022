import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Marker, Polygon } from "@react-google-maps/api";
import { toast } from "material-react-toastify";

import {
  openModalIncidentsActive,
  addIncidentsActiveId,
  addIncidentMarker,
} from "../../store/ducks/monitoringMap";
import { flattenDeep } from "lodash";
import decodePolyline from "decode-google-map-polyline";
import { getActiveIncidents } from "../../infra/http/activeIncidents";

export default function ActiveIncidents() {
  const [incidents, setIncidents] = useState([]);
  const dispatch = useDispatch();

  const activeIncidentsVisible = useSelector(
    (state) => state.monitoringMap.activeIncidentsVisible
  );
  const incidentIdsNotDisplayPolygon = useSelector(
    (state) => state.monitoringMap.incidentIdsNotDisplayPolygon
  );

  const fetchIncidents = async () => {
    try {
      const activeIncidents = await getActiveIncidents();
      setIncidents(activeIncidents);
      dispatch(addIncidentMarker(activeIncidents));
    } catch {
      toast.error("Falha ao tentar obter os incidentes ativos");
    }
  };

  useEffect(() => {
    fetchIncidents();

    const interval = setInterval(fetchIncidents, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const onMarkerClick = (incidentId) => {
    dispatch(addIncidentsActiveId(incidentId));
    dispatch(openModalIncidentsActive());
  };

  const renderPolygons = (incident) => {
    if (incidentIdsNotDisplayPolygon.includes(incident.id)) {
      return;
    }

    const allCoordinates = flattenDeep(incident.polygon?.coordinates ?? []).map(
      (encodedCoordinate) => decodePolyline(encodedCoordinate)
    );

    return allCoordinates.map((coordinates, index) => (
      <Polygon
        key={`incident-${incident.id}-polygon-${index}`}
        options={{
          fillColor: "#6554c0",
          fillOpacity: 0.2,
          strokeColor: "#6554c0",
          strokeOpacity: 1,
          strokeWeight: 2,
          clickable: false,
          draggable: false,
          editable: false,
          geodesic: false,
          zIndex: 1,
        }}
        paths={coordinates}
      />
    ));
  };

  const renderMarker = (incident) => {
    return (
      <Marker
        title={incident.title}
        position={incident.location}
        icon={incident.type.icon}
        onClick={() => onMarkerClick(incident.id)}
      />
    );
  };

  if (!activeIncidentsVisible) {
    return <></>;
  }

  return (
    <>
      {incidents.map((incident) => {
        return (
          <Fragment key={incident.id}>
            {renderMarker(incident)}
            {renderPolygons(incident)}
          </Fragment>
        );
      })}
    </>
  );
}
