import React, { useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import {
  ContentCopy as ContentCopyIcon,
  CheckCircleOutline as CheckCircleIcon,
} from "@mui/icons-material";

export const CopyIconButton = ({ text, ...rest }) => {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef(null);

  const copy = async (e) => {
    e.stopPropagation();

    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);

      timeoutRef.current = setTimeout(() => {
        setCopied(false);
      }, 1500);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  };

  return (
    <IconButton onClick={copy} {...rest}>
      {copied ? <CheckCircleIcon color="success" /> : <ContentCopyIcon />}
    </IconButton>
  );
};
