import { TextField, styled } from "@material-ui/core";

export const Item = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: "#F4F4F4",
    fontSize: "15px",
  },
}));

export default Item;
