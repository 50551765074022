import React from 'react';
import { useSelector } from 'react-redux';
import { Room } from '@material-ui/icons';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core';

export const WaypointsSidebarList = () => {
  const { waypoints } = useSelector(state => state.scripting);

  return (
    <List dense>
      {waypoints.length > 0 && waypoints.map(waypoint =>
          <ListItem>
            <ListItemAvatar>
              <Avatar><Room /></Avatar>
            </ListItemAvatar>
            {waypoint.stopover
              ? (
                <ListItemText
                  primary={`Parada: ${waypoint.location}`}
                  secondary={`Duração: ${waypoint.duration} | Título: ${waypoint.title}`}
                />
              )
              : (
                <ListItemText
                  primary={`Desvio: ${waypoint.location}`}
                />
              )
            }
          </ListItem>
      )}
    </List>
  );
}