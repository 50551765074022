import { styled as styledMUI, Box, Paper, Fab } from "@mui/material";

export const ContainerMapHeader = styledMUI(Box)(() => ({
  width: "100%",
  height: "100vh",
}));

export const ContainerHeader = styledMUI(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "10px",
  width: "auto",
  position: "absolute",
  zIndex: 1,
  gap: "10px",
}));

export const DivOrganizeHeader = styledMUI(Paper)(() => ({
  display: "flex",
  justifyContent: "center",
  gap: "15px",
  width: "auto",
  padding: "10px",
  flexWrap: "wrap",
  borderRadius: "10px",
}));

export const CardIncidents = styledMUI(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  padding: "10px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.1)",
}));

export const CardClippings = styledMUI(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  padding: "10px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.1)",
}));

export const CardRiskArea = styledMUI(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  padding: "10px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.1)",
}));

export const CardFloat = styledMUI(Fab)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "5px",
  padding: "10px",
  borderRadius: "10px",
  height: "4.1rem",
  width: "4.1rem",
  backgroundColor: "white",
}));
