import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "material-react-toastify";

import { prepareSettingValues } from "./utils/prepareTransferSettingValue";

import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import api from "../../../services/devices";
import * as deviceApiService from "../../../services/devicesService";
import { parseISO, format } from "date-fns";
import FormInputRegister from "./formInputRegister";
import FormInputToRegister from "./formInputToRegister";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { GLOBO_CUSTOMER_ID } from "../../../commons/constants/customers";
import FormInputImportRoutesRegister from "./formInputImportRoutesRegister";
import { handleGetSettings } from "../../../services/customerAreaManagement/setting";
import { Types, genericAction } from "../../../store/ducks/transfer";

export default function RegisterForm() {
  const [unity, setUnity] = useState("");
  const [name, setName] = useState("");

  const [customerActivities, setCustomerActivities] = useState([]);
  const [customerActivitySelected, setCustomerActivitySelected] = useState("");
  const [customerCategories, setCustomerCategories] = useState([]);
  const [customerCategorySelected, setCustomerCategorySelected] = useState("");
  const [customerUnits, setCustomerUnits] = useState([]);
  const [customerUnitSelected, setCustomerUnitSelected] = useState("");
  const [customerProduct, setCustomerProduct] = useState([]);
  const [customerProductSelected, setCustomerProductSelected] = useState("");
  const [responsible, setResponsible] = useState("");

  const [checked, setChecked] = useState(false);
  const [useImportedRoutes, setUseImportedRoutes] = useState(false);
  const [autoStartChecked, setAutoStartChecked] = useState(false);
  const [autoEndChecked, setAutoEndChecked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [configurations, setConfigurations] = useState([]);
  const from = useSelector((state) => state.transfer.from);
  const to = useSelector((state) => state.transfer.to);
  const token = useSelector((store) => store.auth.token.accessToken);
  const customerId = useSelector((store) => store.auth.customer.customer);
  const history = useHistory();
  const dispatch = useDispatch();
  const fromDate = useSelector((store) => store.transfer.fromDate);
  const toDate = useSelector((store) => store.transfer.toDate);
  const scheduleTime = useSelector((store) => store.transfer.scheduleTime);
  const frequency = useSelector((store) => store.transfer.frequency);
  const customDates = useSelector(
    (store) => store.form.preConfiguredTransferForm.values.custom_dates
  );
  const customDays = useSelector(
    (store) => store.form.preConfiguredTransferForm.values.custom_days
  )
    .filter((customDays) => customDays.checked)
    .map((customDays) => customDays.value);

  let aux = 0;

  useEffect(() => {
    const config = async () => {
      const response = await handleGetSettings(customerId);

      setConfigurations(response);
    };

    config();
  }, []);

  useEffect(() => {
    const loadFormData = async () => {
      setLoadingData(true);

      const fields = configurations.map((n) => n.field);

      if (fields.includes(1)) {
        setCustomerUnits(await deviceApiService.getCustomerUnits(customerId));
      }

      if (fields.includes(2)) {
        setCustomerCategories(
          await deviceApiService.getCustomerCategories(customerId)
        );
      }

      if (fields.includes(3)) {
        setCustomerProduct(
          await deviceApiService.getCustomerProducts(customerId)
        );
      }

      if (fields.includes(4)) {
        setCustomerActivities(
          await deviceApiService.getCustomerActivities(customerId)
        );
      }

      setLoadingData(false);
    };
    loadFormData();
  }, [configurations]);

  const handleScripting = (e) => {
    aux = 1;
    handleRegisterTransfer(e, aux);
  };

  function addIdToScriptingaction(id) {
    return { type: "ADD_IDTOSCRIPTING", id };
  }

  const fieldRequired = (field) => {
    const configuration = configurations.find((n) => n.field == field);

    if (!configuration) {
      return false;
    }

    return configuration.required;
  };

  async function handleRegisterTransfer(e, aux) {
    e.preventDefault();

    if (!from) return toast.error("Selecione um Local de Partida sugerido.");
    if (!to && !checked)
      return toast.error("Selecione um Local de Destino sugerido.");

    if (!customerUnitSelected && fieldRequired(1))
      return toast.error("Selecione uma Unidade sugerida");
    if (!customerCategorySelected && fieldRequired(2))
      return toast.error("Selecione uma Categoria sugerida");
    if (!customerProductSelected && fieldRequired(3))
      return toast.error("Selecione um Produto sugerido");
    if (!customerActivitySelected && fieldRequired(4))
      return toast.error("Selecione uma Atividade sugerida");
    setSuccess(true);

    try {
      const setting = prepareSettingValues(customDays, customDates, frequency);
      const frequencySetting = setting.frequency;
      const customDaySetting = setting.custom_days;
      const customDateSetting = setting.custom_dates;

      const data = {
        frequency: frequencySetting,
        custom_days: customDaySetting,
        custom_dates: customDateSetting,
        scheduled_time: scheduleTime,
        from_date: fromDate,
        to_date: toDate,
        device_ids: "",
        dataTransfer: [
          {
            responsible,
            unity,
            name,
            from,
            to,
            scheduled_date: parseISO(
              format(
                new Date(`${fromDate}T${scheduleTime}`),
                "yyyy-MM-dd'T'HH:mm"
              )
            ),
            autoStartChecked: autoStartChecked,
            autoEndChecked: autoEndChecked,
            checked: checked,
            transfer_activity_id: customerActivitySelected || null,
            transfer_category_id: customerCategorySelected || null,
            customer_unit_id: customerUnitSelected || null,
            transfer_product_id: customerProductSelected || null,
          },
        ],
      };

      const response = await api.request({
        url: `/customer/${customerId}/transfers/pre-configured/create`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        genericAction(Types.HANDLE_TRANSFER_PRE_CONFIGURED_ID, [
          response.data.transfer_id,
        ])
      );
      dispatch(addIdToScriptingaction(response.data.transfer_id));

      if (aux === 1) {
        aux = 0;
        history.push("/scripting");
      } else {
        window.location.reload(true);
      }
    } catch (err) {
      console.log(err);
      setSuccess(false);
      alert("Erro ao cadastrar");
    }
  }
  return (
    <form onSubmit={handleRegisterTransfer}>
      {success === false && loadingData === false ? (
        <>
          <Grid container spacing={3} style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                style={{ marginLeft: 0, marginRight: 10 }}
                value="from-to"
                control={
                  <Switch
                    onClick={() => setChecked(!checked)}
                    size="small"
                    color="primary"
                  />
                }
                label="Ida e Volta"
                labelPlacement="start"
              />
              <FormControlLabel
                style={{ marginLeft: 0, marginRight: 10 }}
                control={
                  <Switch
                    onClick={() => setUseImportedRoutes(!useImportedRoutes)}
                    size="small"
                    color="primary"
                  />
                }
                label="Importar Rotas"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                style={{ marginLeft: 0, marginRight: 10 }}
                control={
                  <Switch
                    onClick={() => setAutoStartChecked(!autoStartChecked)}
                    size="small"
                    color="primary"
                  />
                }
                label="Inicio Automático"
                labelPlacement="start"
              />
              <FormControlLabel
                style={{ marginLeft: 0, marginRight: 10 }}
                control={
                  <Switch
                    onClick={() => setAutoEndChecked(!autoEndChecked)}
                    size="small"
                    color="primary"
                  />
                }
                label="Fim Automático"
                labelPlacement="start"
              />
            </Grid>

            {configurations.map((n) => n.field).includes(1) && (
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: "100%" }} size="small">
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="subtitle2"
                    gutterBottom
                  >
                    Unidade de Negócios
                  </Typography>
                  <Select
                    variant="outlined"
                    value={customerUnitSelected}
                    displayEmpty
                    onChange={(event) => {
                      setCustomerUnitSelected(event.target.value);
                    }}
                  >
                    <MenuItem key={"customerUnit"} value="">
                      <em>Selecione uma unidade</em>
                    </MenuItem>
                    {customerUnits.map((customerUnit, index) => (
                      <MenuItem
                        key={"customerUnit-" + customerUnit.id}
                        value={customerUnit.id}
                      >
                        {customerUnit.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Evento
              </Typography>
              <TextField
                variant="outlined"
                id="name"
                type="search"
                placeholder="Digite nome do Evento"
                style={{ width: "100%", height: 40 }}
                size="small"
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Responsável
              </Typography>
              <TextField
                variant="outlined"
                id="responsible"
                type="search"
                placeholder="Digite nome do Responsável"
                style={{ width: "100%", height: 40 }}
                size="small"
                onChange={(e) => setResponsible(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {useImportedRoutes ? (
              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Importar Rotas
                </Typography>
                <FormInputImportRoutesRegister />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  {checked
                    ? "Local de Partida e Destino (ida e volta)"
                    : "Local de Partida"}
                </Typography>
                <FormInputRegister />
              </Grid>
            )}

            {!useImportedRoutes && (
              <Grid
                item
                xs={12}
                sm={6}
                onClick={() => {
                  if (checked)
                    return toast.warning(
                      'Para editar o endereço de destino, desmarque a opção de "ida e volta"'
                    );
                }}
              >
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Local de Destino
                </Typography>
                <FormInputToRegister checked={checked} />
              </Grid>
            )}

            <>
              {configurations.map((n) => n.field).includes(4) && (
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }} size="small">
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      Atividade
                    </Typography>
                    <Select
                      variant="outlined"
                      value={customerActivitySelected}
                      displayEmpty
                      onChange={(event) => {
                        setCustomerActivitySelected(event.target.value);
                      }}
                    >
                      <MenuItem key={"activity"} value="">
                        <em>Selecione uma atividade</em>
                      </MenuItem>
                      {customerActivities.map((activity, index) => (
                        <MenuItem key={"activity-" + index} value={activity.id}>
                          {activity.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {configurations.map((n) => n.field).includes(2) && (
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }} size="small">
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      Categorias
                    </Typography>
                    <Select
                      variant="outlined"
                      value={customerCategorySelected}
                      displayEmpty
                      onChange={(event) => {
                        setCustomerCategorySelected(event.target.value);
                      }}
                    >
                      <MenuItem key={"category"} value="">
                        <em>Selecione uma categoria</em>
                      </MenuItem>
                      {customerCategories.map((category, index) => (
                        <MenuItem key={"category-" + index} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {configurations.map((n) => n.field).includes(3) && (
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }} size="small">
                    <Typography
                      style={{ fontWeight: "bold" }}
                      variant="subtitle2"
                      gutterBottom
                    >
                      Produto
                    </Typography>
                    <Select
                      variant="outlined"
                      value={customerProductSelected}
                      displayEmpty
                      onChange={(event) => {
                        setCustomerProductSelected(event.target.value);
                      }}
                    >
                      <MenuItem key={"activity"} value="">
                        <em>Selecione um produto</em>
                      </MenuItem>
                      {customerProduct.map((activity, index) => (
                        <MenuItem key={"activity-" + index} value={activity.id}>
                          {activity.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </>
          </Grid>

          <center>
            <Button
              type="submit"
              autoFocus
              style={{
                margin: "0 15px",
                color: "#06B0CF",
                borderRadius: 8,
                border: "none",
                boxShadow: "0px 3px 6px #00000029",
              }}
              variant="outlined"
              color="primary"
            >
              Salvar
            </Button>
            <Button
              onClick={(e) => handleScripting(e)}
              style={{
                backgroundColor: "#06B0CF",
                borderRadius: 8,
                boxShadow: "0px 3px 6px #00000029",
              }}
              variant="contained"
              autoFocus
              color="primary"
            >
              Salvar e Roteirizar
            </Button>
          </center>
        </>
      ) : (
        <>
          <center>
            <div>
              <CircularProgress
                size={68}
                style={{ marginTop: 0, color: "#63a9ef" }}
              ></CircularProgress>
            </div>
            <span>{loadingData ? "Carregando dados" : "Criando transfer"}</span>
          </center>
        </>
      )}
    </form>
  );
}
