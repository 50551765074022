import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { format } from "date-fns";
import { handleUpdatePassenger } from "../../../services/peopleManagement/passenger";
import { toast } from "material-react-toastify";

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

const EditDialog = ({ open, close, data, reload }) => {
  const [name, setName] = useState(data.name);
  const [birthDate, setBirthDate] = useState();
  const [address, setAdress] = useState();
  const [phone, setPhone] = useState();
  const [rfid, setRfid] = useState();
  const birthDateCurrent =
    data?.birthDate == null
      ? ""
      : format(new Date(data?.birthDate), "yyyy-MM-dd");

  const handleSetName = (name) => {
    setName(name);
  };

  const handleSetBirthDate = (birthdate) => {
    setBirthDate(birthdate);
  };

  const handleSetAddress = (address) => {
    setAdress(address);
  };

  const handleSetPhone = (phone) => {
    setPhone(phone);
  };

  const handleSetRfid = (rfid) => {
    setRfid(rfid);
  };

  const editPassenger = async (id) => {
    const data = {
      name,
      birthDate,
      address,
      phone,
      rfid,
    };

    if (!name) {
      return toast.error("Digite o nome do passageiro");
    }

    try {
      await handleUpdatePassenger(id, data);
      await reload();
      close();
    } catch (error) {
      toast.error('Erro ao tentar editar')
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      close={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Editar Cadastro de Passageiro
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: "100%" }} size="small">
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Nome do Passageiro
              </Typography>
              <TextField
                onChange={(e) => handleSetName(e.target.value)}
                defaultValue={data.name}
                variant="outlined"
                id="nome"
                type="text"
                placeholder="Digite nome do Passageiro"
                style={{ width: "100%", height: 40 }}
                size="small"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              style={{ fontWeight: "bold" }}
              variant="subtitle2"
              gutterBottom
            >
              Data de Nascimento
            </Typography>
            <TextField
              onChange={(e) => handleSetBirthDate(e.target.value)}
              defaultValue={birthDateCurrent}
              variant="outlined"
              id="scheduled_date"
              type="date"
              style={{ width: "100%", height: 40 }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              style={{ fontWeight: "bold" }}
              variant="subtitle2"
              gutterBottom
            >
              Endereço
            </Typography>
            <TextField
              onChange={(e) => handleSetAddress(e.target.value)}
              defaultValue={data.address}
              variant="outlined"
              id="address"
              type="text"
              placeholder="Digite o endereço"
              style={{ width: "100%", height: 40 }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: "100%" }} size="small">
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                Telefone
              </Typography>
              <TextField
                onChange={(e) => handleSetPhone(e.target.value)}
                defaultValue={data.phone}
                variant="outlined"
                id="tel"
                type="tel"
                placeholder="Digite um número de telefone"
                style={{ width: "100%", height: 40 }}
                size="small"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: "100%" }} size="small">
              <Typography
                style={{ fontWeight: "bold" }}
                variant="subtitle2"
                gutterBottom
              >
                RFID
              </Typography>
              <TextField
                onChange={(e) => handleSetRfid(e.target.value)}
                defaultValue={data.rfid}
                variant="outlined"
                id="tel"
                type="text"
                placeholder="Digite o RFID"
                style={{ width: "100%", height: 40 }}
                size="small"
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => editPassenger(data.id)}>
          Editar
        </Button>
        <Button onClick={close} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
