import React, { useState } from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function Success() {
  return (
    <div style={{width:490}}>
      <center>
        <div>
          <CheckCircleIcon style={{ marginTop:200, fontSize:68, color: "#63a9ef" }}/>
        </div>
        <span>Tratamento realizado</span>
      </center>
    </div>
  );
}
