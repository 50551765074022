import colors from '../../../../commons/colors'

const styles = {}

styles.buttonStyle = {
  borderRadius: 8,
  color: colors.fullWhite,
  backgroundColor: colors.lightBlue,
  fontSize: 16,
  textTransform: 'capitalize',
  boxShadow: `0px 0px 6px ${colors.lightBlue}`,
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 10,
  paddingRight: 10,
  margin: 7
}

styles.disabledButtonStyle = {
  ...styles.buttonStyle,
  backgroundColor: colors.lightGray,
  color: colors.darkGray,
  boxShadow: 'none',
}

export default styles