import React, { useState, useEffect, useCallback } from "react";

import { MainContainer } from "./styles";

import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Switch,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";

import { getEventTypes } from "../../../../../../infra/http/devicesProvider";
import { toast } from "material-react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { eventsField } from "../../../../../../store/ducks/deviceManagement";
import { getIfOverSpeedHired } from "../../../../../../services/overSpeed/overSpeedHired";
import { OVER_SPEED } from "../../../../../../commons/enums/event-types";

function AlertsDeviceTab() {
  const selectedEvents = useSelector((store) => store.deviceMangement.events);
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventFields, setSelectedEventFields] =
    useState(selectedEvents);
  const dispatch = useDispatch();
  const [overSpeedHired, setOverSpeedHired] = useState(false);

  const customerId = useSelector((store) => store.auth.customer.customer);

  useEffect(() => {
    const response = async () => {
      const responseApi = await getEventTypes();

      if (responseApi.status == 200) {
        setEventTypes(responseApi.data);
      } else {
        toast.error("Falha na consulta de eventos");
      }
    };

    const overSpeed = async () => {
      const response = await getIfOverSpeedHired(customerId);

      if (response.status == 200) {
        setOverSpeedHired(response.data[0]?.overspeedHired);
      } else {
        toast.error("Falha ao buscar evento contratado");
      }
    };

    overSpeed();
    response();
  }, []);

  useEffect(() => {
    dispatch(eventsField(selectedEventFields));
  }, [selectedEventFields]);

  const handleEventType = (e) => {
    const value = Number(e.target.value);

    const newValue = {
      event_type_id: value,
      enable: true,
      treatment: false,
      alert_sound: false,
    };

    const exists = selectedEventFields.some(
      (element) => element.event_type_id === value
    );

    if (exists === true) {
      setSelectedEventFields(
        selectedEventFields.filter(
          ({ event_type_id }) => event_type_id !== value
        )
      );
    } else {
      setSelectedEventFields([...selectedEventFields, newValue]);
    }
  };

  const handleOverSpeedHired = (id) => {
    if (id == OVER_SPEED && !overSpeedHired) {
      return toast.warning("É necessário contratar este serviço!");
    }
  };

  const handleAlertsAndTreatment = (e) => {
    const newArray = selectedEventFields.map((element) => {
      if (element.event_type_id == e.target.id) {
        element[e.target.value] = e.target.checked;
      }
      return element;
    });
    setSelectedEventFields(newArray);
  };

  const handleAlertSound = (e) => {
    const newArray = selectedEventFields.map((element) => {
      if (element.event_type_id == e.target.id) {
        element[e.target.value] = e.target.checked;
      }
      return element;
    });
    setSelectedEventFields(newArray);
  };

  return (
    <MainContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tipos de alertas</TableCell>
            <TableCell>Notificar</TableCell>
            <TableCell>Tratar</TableCell>
            <TableCell>Aviso sonoro</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventTypes.map((eventType) => (
            <TableRow key={eventType.id}>
              <TableCell>{eventType.name}</TableCell>
              <TableCell>
                <FormControlLabel
                  onClick={() => handleOverSpeedHired(eventType.id)}
                  control={
                    <Switch
                      disabled={eventType.id == OVER_SPEED && !overSpeedHired}
                      checked={selectedEventFields.some(
                        (element) => element.event_type_id == eventType.id
                      )}
                      id="event_type_id"
                      color="primary"
                      value={eventType.id}
                      onClick={(e) => handleEventType(e)}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <FormControlLabel
                  onClick={() => handleOverSpeedHired(eventType.id)}
                  control={
                    <Switch
                      disabled={eventType.id == OVER_SPEED && !overSpeedHired}
                      checked={selectedEventFields.some(
                        (element) =>
                          element.event_type_id == eventType.id &&
                          element.treatment
                      )}
                      color="primary"
                      id={eventType.id}
                      value={"treatment"}
                      onClick={(e) => handleAlertsAndTreatment(e)}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <FormControlLabel
                  onClick={() => handleOverSpeedHired(eventType.id)}
                  control={
                    <Switch
                      disabled={eventType.id == OVER_SPEED && !overSpeedHired}
                      checked={selectedEventFields.some(
                        (element) =>
                          element.event_type_id == eventType.id &&
                          element.alert_sound
                      )}
                      color="primary"
                      id={eventType.id}
                      value={"alert_sound"}
                      onClick={(e) => handleAlertSound(e)}
                    />
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </MainContainer>
  );
}

export default AlertsDeviceTab;
