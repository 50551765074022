import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const Div = styled.div`
  width: 100%;

  .switchers {
    margin-left: 15px;
    margin-top: -50vh;
  }
`;

const drawerWidth = 150;

export const useStyles = makeStyles((theme) => ({
  monitoringLabelDefault: {
    fontSize: "10px!important",
    zIndex: 1000000,
    padding: 3,
    marginBottom: 55,
    borderRadius: 5,
    border: "1px gray solid",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.3)",
    backgroundColor: "white",
  },
  monitoringLabelActive: {
    fontSize: "10px!important",
    zIndex: 1000000,
    padding: 3,
    marginBottom: 55,
    borderRadius: 5,
    border: "1px gray solid",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.3)",
    backgroundColor: "rgb(20, 20, 80) !important",
    color: "white !important",
    cursor: "default !important",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 1,
    position: "absolute",
    backgroundColor: "#fff",
    borderRadius: 10,
    height: "100px",
    right: "0px",
    bottom: "0px",
    transition: "transform .5s ease-in-out",
  },
  drawerDown: {
    transform: "translateY(100px)",
  },
  list: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  listDrawer: {
    marginLeft: "-5px",
    backgroundColor: "#fff",
    marginTop: "-65px",
    border: "1px solid",
    borderRadius: "10px",
  },
  drawerNone: {
    display: "none",
  },
}));
