import { makeStyles, createMuiTheme } from "@material-ui/core"

import colors from '../../../commons/colors'

export const theme = createMuiTheme({
	palette: {
    primary: {
      main: colors.lightBlue,
			contrastText: colors.fullWhite,
			light: colors.black,
    },
  },
	overrides: {
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: colors.lightBlue,
			},
		},
		MuiPickersDay: {
			day: {
				color: colors.black,
			},
			daySelected: {
				backgroundColor: colors.lightBlue,
			},
			dayDisabled: {
				color: colors.lightGray,
			},
			current: {
				color: colors.lightBlue
			}
		},
		MuiPickerDTTabs: {
			tabs: {
				color: colors.fullWhite,
			}
		}
	}
});

const useStyles = makeStyles({
	input: {
		flexGrow: 1,
    flexShrink: 0,
    flexBasis: 270,
	},

  customInputStyles: {
    padding: '9px 16px 9px 0',
  },

  customNotchedOutlineStyles: {
    borderRadius: 10
  }
})

export default useStyles