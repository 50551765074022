import React, { useRef, useEffect, useState } from "react";
import MainModalDeviceManagement from "./ModalDeviceManagement";
import SharedTable from "../../../components/SharedTable";
import { useSelector } from "react-redux";
import { getDevices } from "../../../infra/http/devicesProvider";
import {
  LocalShipping,
  TwoWheeler,
  PhoneIphone,
  DirectionsCar,
} from "@material-ui/icons";

import helicopterIcon from "../../../assets/MapIcons-DevicePosition/icone_helicoptero.png"
import portableIcon from "../../../assets/MapIcons-DevicePosition/icone_rastreador.png"

import constants from "../../../commons/constants";

const LinkedDevices = () => {
  const customerId = useSelector((store) => store.auth.customer.customer);
  const [updateTable, setUpdateTable] = useState(false);
  const searchedDevice = useSelector(
    (store) => store.deviceMangement.identifier
  );

  const sharedTableRef = useRef(null);

  const handleUpdateTable = () => {
    setUpdateTable(!updateTable);
  };

  useEffect(() => {
    if (sharedTableRef?.current)
      sharedTableRef.current.fetchRowsData({
        waitTimeForAnotherCall: 1000,
        shouldGoBackToFirstPage: true,
      });
  }, [searchedDevice, updateTable]);

  const truck = () => {
    return <LocalShipping />;
  };

  const moto = () => {
    return <TwoWheeler />;
  };

  const cellphone = () => {
    return <PhoneIphone />;
  };

  const car = () => {
    return <DirectionsCar />;
  };
  const helicopter = () => {
    return <img src={helicopterIcon} />;
  };
  const portable = () => {
    return <img src={portableIcon} />;
  };

  const factoryIcon = {
    1: cellphone,
    2: truck,
    3: moto,
    4: car,
    5: helicopter,
    6: portable,
  };

  const fetchListData = async ({ currentPage, rowsPerPage }) => {
    const { deviceData, totalRows } = await getDevices({
      customerId: customerId,
      perPage: rowsPerPage,
      page: currentPage,
      identifier: searchedDevice,
    });

    const rowsData = deviceData.map((row) => {
      return [
        row.id,
        row.identifier,
        row.alias,
        factoryIcon[row?.device_setting?.device_icon_id]
          ? factoryIcon[row?.device_setting?.device_icon_id]()
          : cellphone(),
        <MainModalDeviceManagement
          iconTheme="limits"
          transferDataId={row?.device_setting?.id}
          deviceId={row.id}
          screenClick={"deviceLimits"}
          updateTable={handleUpdateTable}
        />,
        <MainModalDeviceManagement
          iconTheme="edit"
          transferDataId={row?.device_setting?.id}
          deviceId={row.id}
          screenClick={"deviceData"}
          updateTable={handleUpdateTable}
        />,
      ];
    });

    return {
      rowsData,
      totalRows,
    };
  };

  const tableHeaders = [
    "Identificador",
    "Apelido",
    "Tipo",
    "Limites",
    "Editar",
  ];

  return (
    <SharedTable
      cellHeaders={tableHeaders}
      rowsDataFetcher={fetchListData}
      withPagination={true}
      ref={sharedTableRef}
      noRowsDataMessage={constants.tables.DEFAULT_NO_ROWS_MESSAGE}
    />
  );
};

export default LinkedDevices;
