import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import { setWaypointsAction } from "../../store/ducks/scripting";
import { setCurrentTransferAction } from "../../store/ducks/transfer";
import Sidebar from "./Sidebar";
import { dataTransfer } from "../../infra/http/transferApiService";
import { toast } from "material-react-toastify";
import { SidebarDetourStop } from "./SidebarDetourStop";
import { ScriptingMap } from "./ScriptingMap";

export const ScriptingNew = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: transferId } = useSelector((state) => state.transfer);
  const { token } = useSelector((state) => state.auth);
  const scriptingWaypoints = useSelector((state) => state.scripting.waypoints);

  const mapToGoogleMapsWaypoint = (waypoint) => ({
    id: waypoint.id,
    location: waypoint.address,
    stopover: waypoint.type === "STOP_POINT",
    title: waypoint.name,
    duration: waypoint.stopping_time,
    position: {
      latitude: waypoint.position.lat,
      longitude: waypoint.position.lng,
    },
  });

  useEffect(() => {
    const fetchTransferWaypoints = async () => {
      if (!transferId) {
        history.push("/transfers");
        return;
      }

      try {
        const response = await dataTransfer(transferId, token);
        const formattedScheduledDate = response.scheduled_date
          .trim()
          .replace(/\s/g, "T");
        const transferData = {
          ...response,
          scheduled_date: formattedScheduledDate,
        };

        dispatch(setCurrentTransferAction(transferData));

        if (scriptingWaypoints.length === 0) {
          const [route] = transferData.routes;
          let waypoints = [];

          if (route) {
            waypoints = route.waypoints_backup
              .sort((a, b) => a.order - b.order)
              .map(mapToGoogleMapsWaypoint);
          }
          dispatch(setWaypointsAction(waypoints));
        }
      } catch (error) {
        toast.error("Erro na busca de dados");
        history.push("/transfers");
      } finally {
        setLoading(false);
      }
    };

    fetchTransferWaypoints();
  }, [transferId, token]);

  if (loading) return <h1>Carregando...</h1>;

  return (
    <Container>
      <Sidebar />
      <SidebarDetourStop />
      <ScriptingMap />
    </Container>
  );
};
