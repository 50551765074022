export const styles = {
  row: {
    display: "flex",
    flexDirection: "row",

    borderTop: "1px solid #000",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
  },
};
