import { styled as styledMUI, TextField } from "@mui/material";

export const CustomTextField = styledMUI(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "5px",
    },
  },
  input: {
    fontSize: "15px",
    fontWeight: 400,
    height: "20px",
  },
  backgroundColor: "#F8F8F8",
  width: "100%",
  marginTop: "30px",
}));
