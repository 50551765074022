import { areasApi } from "..";
import { appId } from "../../../commons/constants/app";
import { fromPOITypeApi } from "../mappers/poi-type.mapper";

export const getPOITypes = async ({ groupIds }) => {
  const response = await areasApi.get("/points-of-interest-types", {
    params: {
      application_id: appId,
      groupIds,
    },
  });

  return response.data.map(fromPOITypeApi);
};
