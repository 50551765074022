import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import styles from "./styles";

const ReportSelector = ({
  placeholder,
  value = undefined,
  options = [],
  getOptionLabel = (option) => option?.name ?? "",
  onChange = () => {},
  disable,
}) => {
  return (
    <Autocomplete
      disabled={disable}
      multiple
      options={options}
      style={styles.autocompleteField}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          value={options.id}
        />
      )}
    />
  );
};

export default ReportSelector;
