import React, {
  useMemo,
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'

import useStyles from './styles'
import InlineInput from './InlineInput'
import InlineContent from './InlineContent'
import { callAsyncOrNot } from '../../commons/utils'

const InlineEditableContent = forwardRef((
  {
    content,
    contentWhenEmpty = '-',
    editorPlaceholder = 'edit text',
    editHandler: externalEditHandler = () => true,
    editButtonBackgroundColor = null,
    submittingText = 'Submitting...',
  },
  ref
) => {
  const [isHovering, setIsHovering] = useState(false)
  const [isInEditionMode, setIsInEditionMode] = useState(false)

  const classes = useStyles({ visible: isHovering })

  const turnEditionModeOn = useCallback(
    () => setIsInEditionMode(true),
    [setIsInEditionMode]
  )
  const turnEditionModeOff = useCallback(
    () => setIsInEditionMode(false),
    [setIsInEditionMode]
  )

  useImperativeHandle(ref, () => ({
    turnEditionModeOn,
    turnEditionModeOff,
  }))

  const onMouseEnterHandler = () => {
    setIsHovering(true)
  }

  const onMouseLeaveHandler = () => {
    setIsHovering(false)
  }

  const editButtonClickHandler = (event) => {
    event.stopPropagation()
    setIsInEditionMode(true)
  }

  const closeButtonClickHandler = (event) => {
    event.stopPropagation()
    setIsInEditionMode(false)
  }

  const editHandler = useCallback(async (newValue) => {
    const shouldEndEdition = await callAsyncOrNot(externalEditHandler, newValue)
    if (shouldEndEdition)
      turnEditionModeOff()
  }, [turnEditionModeOff, externalEditHandler])

  const finalContent = useMemo(() => {
    if (!isInEditionMode)
      return (
        <InlineContent
          content={content || contentWhenEmpty}
          isHovering={isHovering}
          editButtonClickHandler={editButtonClickHandler}
          editButtonBackgroundColor={editButtonBackgroundColor}
        />
      )

    return (
      <InlineInput
        defaultValue={content}
        isHovering={isHovering}
        submitHandler={editHandler}
        placeholder={editorPlaceholder}
        submittingText={submittingText}
        turnEditionModeOff={turnEditionModeOff}
        closeButtonClickHandler={closeButtonClickHandler}
        editButtonBackgroundColor={editButtonBackgroundColor}
      />
    )
  }, [
    editButtonBackgroundColor,
    turnEditionModeOff,
    editorPlaceholder,
    contentWhenEmpty,
    isInEditionMode,
    submittingText,
    editHandler,
    isHovering,
    content,
  ])

  return (
    <div
      className={classes.container}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      {finalContent}
    </div>
  )
})

export default InlineEditableContent