import React, { useState, useEffect, useCallback } from "react";

import { MainContainer } from "./styles";

import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Switch,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Select,
  MenuItem,
} from "@material-ui/core";

import { getEventTypes } from "../../../../../../infra/http/devicesProvider";
import { toast } from "material-react-toastify";
import { useDispatch, useSelector } from "react-redux";

import {
  eventsField,
  handleSelectCarId,
} from "../../../../../../store/ducks/deviceManagement";
import {
  getAllCarMakersByCustomerId,
  getAllCarModelsByCustomerId,
  getAllCarFuelsByCustomerId,
  getAllCarsByCustomerId,
} from "../../../../../../infra/http/carProvider";

function CarDeviceTab() {
  const customerId = useSelector((store) => store.auth.customer.customer);
  const carId = useSelector((store) => store.deviceMangement.carId);
  const [cars, setCars] = useState([]);
  const [carIdSelect, setCarIdSelect] = useState(carId);
  const [makerId, setMakerId] = useState(null);
  const [makers, setMakers] = useState([]);
  const [modelId, setModelId] = useState(null);
  const [models, setModels] = useState([]);
  const [fuelId, setFuelId] = useState(null);
  const [fuels, setFuels] = useState([]);

  const dispatch = useDispatch();

  const handleGetAllCarMakers = async (customerId) => {
    const response = await getAllCarMakersByCustomerId({ customerId });

    setMakers(response);
  };

  const handleGetAllCarModels = async (customerId) => {
    const response = await getAllCarModelsByCustomerId({ customerId });

    setModels(response);
  };

  const handleGetAllCarFuels = async (customerId) => {
    const response = await getAllCarFuelsByCustomerId({ customerId });

    setFuels(response);
  };

  const handleGetAllCars = async (customerId) => {
    const response = await getAllCarsByCustomerId({ customerId });

    setCars(response);
  };

  const handleSelectCar = (id) => {
    setCarIdSelect(id);
  };

  const handleSelectMaker = (value) => {
    setMakerId(value);
    setModelId(null);
  };

  const handleFilterCar = (item) => {
    if (makerId) {
      if (item.makerId !== makerId) {
        return;
      }

      if (modelId) {
        if (item.modelId !== modelId) {
          return;
        }
      }
    }

    if (fuelId) {
      if (item.fuelId !== fuelId) {
        return;
      }
    }

    return item;
  };

  useEffect(() => {
    handleGetAllCars(customerId);
    handleGetAllCarMakers(customerId);
    handleGetAllCarModels(customerId);
    handleGetAllCarFuels(customerId);
  }, []);

  useEffect(() => {
    dispatch(handleSelectCarId(carIdSelect));
  }, [carIdSelect]);

  return (
    <MainContainer>
      <Box style={{ display: "flex", gap: "20px" }}>
        <Select
          variant="outlined"
          displayEmpty
          defaultValue={""}
          onChange={(e) => handleSelectMaker(e.target.value)}
          style={{ width: "250px" }}
        >
          <MenuItem key={"maker"} value="">
            <em>Selecione um fabricante</em>
          </MenuItem>
          {makers.map((maker, index) => (
            <MenuItem key={"maker-" + maker.id} value={maker.id}>
              {maker.name}
            </MenuItem>
          ))}
        </Select>

        <Select
          variant="outlined"
          displayEmpty
          defaultValue={""}
          onChange={(e) => setModelId(e.target.value)}
          style={{ width: "250px" }}
        >
          <MenuItem key={"maker"} value="">
            <em>Selecione um modelo</em>
          </MenuItem>
          {models
            .filter((item) => makerId == null || item.makerId == makerId)
            .map((model, index) => (
              <MenuItem key={"model-" + model.id} value={model.id}>
                {model.name}
              </MenuItem>
            ))}
        </Select>

        <Select
          variant="outlined"
          displayEmpty
          defaultValue={""}
          onChange={(e) => setFuelId(e.target.value)}
          style={{ width: "250px" }}
        >
          <MenuItem key={"fuel"} value="">
            <em>Selecione um combustivel</em>
          </MenuItem>
          {fuels.map((fuel, index) => (
            <MenuItem key={"fuel-" + fuel.id} value={fuel.id}>
              {fuel.name}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Table
        style={{ overflowX: "hidden", overflowY: "auto", marginTop: "20px" }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Marca</TableCell>
            <TableCell>Modelo</TableCell>
            <TableCell>Ano</TableCell>
            <TableCell>Combustivel</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cars
            .filter((item) => handleFilterCar(item))
            .map((car) => (
              <TableRow key={car.id}>
                <TableCell>{car.maker ? car.maker.name : ""}</TableCell>
                <TableCell>{car.model ? car.model.name : ""}</TableCell>
                <TableCell>{car.year}</TableCell>
                <TableCell>{car.fuel ? car.fuel.name : ""}</TableCell>

                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={carIdSelect == car.id ? true : false}
                        id="event_type_id"
                        color="primary"
                        value={car.makerId}
                        onClick={() => handleSelectCar(car.id)}
                      />
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </MainContainer>
  );
}

export default CarDeviceTab;
