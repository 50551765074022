import React, { useCallback } from 'react';
import { Typography, Card, CardContent, makeStyles, IconButton } from '@material-ui/core';
import { Map } from '@material-ui/icons';
import { formatDateTimeISO } from '../../../commons/utils/date';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export const IdentifierInfoCard = ({ device, filters, identifierName, travelledDistance }) => {
  const classes = useStyles();

  const openDeviceTrackMapReport = useCallback(() => {
    const from = formatDateTimeISO(new Date(filters.from));
    const to = formatDateTimeISO(new Date(filters.to));
    const url = `${window.location.origin}/reports/${device.id}/${from}/${to}/track`
    window.open(
      url,
      '_blank',
      'toolbar=0,location=0,menubar=0,width=800,height=650'
    );
  }, [device, filters])

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div className={classes.div}>
          <Typography variant="h6" component="h2">
            Identificador: {identifierName} <br />
            Distância percorrida: <strong>{travelledDistance.toFixed(2) + ' Km'}</strong>
          </Typography>
          <IconButton onClick={openDeviceTrackMapReport} style={{ marginLeft: "10px" }}>
            <Map />
          </IconButton>
        </div>
      </CardContent>
    </Card>
  );
}
