import moment from "moment";
import codes from "../../commons/constants/codes";
import { createException } from "../../commons/exceptions";
import eventAnalyticsAPI from "../eventAnalytics";

export const getEvents = async (
  customerId,
  { transferIds, treated, deviceIds, startedAt }
) => {
  const response = await eventAnalyticsAPI.get(
    `/customers/${customerId}/events`,
    {
      params: {
        transferIds,
        treated,
        deviceIds,
        startedAt,
      },
    }
  );

  return response.data || [];
};

export const getEventsReportDevice = async (data) => {
  try {
    const { customerId, startedAt, endedAt, page, perPage } = data;
    const response = await eventAnalyticsAPI.get(
      `/customers/${customerId}/occurence-report/devices`,
      {
        params: {
          startedAt,
          endedAt,
          page,
          perPage,
        },
      }
    );

    return response.data;
  } catch (error) {
    createException({
      code: codes.error.API_REQUEST_FAILED,

      error,
    });
  }
};

export const getEventsWithCoordinates = async (data) => {
  try {
    const { customerId, startedAt, endedAt, deviceId, transferId } = data;
    const startedAtIncreased = moment(startedAt)
      .add(3, "h")
      .format("YYYY-MM-DD HH:mm:ss");
    const endedAtIncreased = moment(endedAt)
      .add(3, "h")
      .format("YYYY-MM-DD HH:mm:ss");

    const response = await eventAnalyticsAPI.get(
      `/customers/${customerId}/events-with-tracking`,
      {
        params: {
          startedAt: startedAtIncreased,
          endedAt: endedAtIncreased,
          deviceId,
          transferId,
        },
      }
    );

    return response.data;
  } catch (error) {
    createException({
      code: codes.error.API_REQUEST_FAILED,

      error,
    });
  }
};

export const getOccurenceReport = async (params) => {
  try {
    const { customerId, customerUnitIds, to, from } = params;
    return await eventAnalyticsAPI.get(
      `/customers/${customerId}/occurence-report`,
      {
        params: {
          startedAt: from,
          endedAt: to,
          customerUnitIds,
        },
      }
    );
  } catch ({ response }) {
    return response;
  }
};

export const getDashboardReport = async ({
  customerId,
  startedAt,
  endedAt,
  customerUnitIds,
}) => {
  try {
    const response = await eventAnalyticsAPI.get(
      `/customers/${customerId}/dashboard-report`,
      {
        params: {
          startedAt,
          endedAt,
          customerUnitIds,
        },
      }
    );

    return response.data;
  } catch (error) {
    createException({
      code: codes.error.API_REQUEST_FAILED,

      error,
    });
  }
};

export const getDashboardTelemetryData = async ({
  customerId,
  startedAt,
  endedAt,
  driver,
}) => {
  const response = await eventAnalyticsAPI.get(
    `/customers/${customerId}/dashboard-telemetry`,
    {
      params: {
        startedAt,
        endedAt,
        driver,
      },
    }
  );

  return response.data;
};

export const getEventsRanking = async ({
  customerId,
  startedAt,
  endedAt,
  page,
  perPage,
  driver,
}) => {
  const response = await eventAnalyticsAPI.get(
    `/customers/${customerId}/events-ranking`,
    {
      params: {
        startedAt,
        endedAt,
        page,
        perPage,
        driver,
      },
    }
  );

  return response.data;
};

export const createDeviceAwayFromStartPointEvent = async ({
  externalId,
  deviceTypeId,
  justification,
}) => {
  const { data } = await eventAnalyticsAPI.post(
    `/customers/device-away-from-start-point`,
    {
      externalId,
      deviceTypeId,
      justification,
    }
  );

  return data;
};

export const createDeviceAwayFromEndPointEvent = async ({
  externalId,
  deviceTypeId,
  justification,
  transferId,
}) => {
  const { data } = await eventAnalyticsAPI.post(
    `/customers/device-away-from-end-point`,
    {
      externalId,
      deviceTypeId,
      justification,
      transferId,
    }
  );

  return data;
};

export const getEventsDetailsModal = async (
  customerId,
  startedAt,
  endedAt,
  eventTypeId
) => {
  const response = await eventAnalyticsAPI.get(
    `/customers/${customerId}/occurence-report/devices/events-statistics`,
    {
      params: {
        startedAt,
        endedAt,
        eventTypeId,
      },
    }
  );

  return response.data;
};
