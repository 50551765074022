import React from "react";
import { View } from "@react-pdf/renderer";

import { styles } from "./styles";

export const Col = ({ children, last, width, customStyle = {} }) => {
  let colStyle = last ? { ...styles.col, borderRight: undefined } : styles.col;
  colStyle = width ? { ...colStyle, width, flex: undefined } : colStyle;

  return <View style={{ ...colStyle, ...customStyle }}>{children}</View>;
};
