import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ReportTextField from "../../components/ReportForm/ReportTextField";
import { Typography } from "@material-ui/core";
import { Div, Container } from "./styles";
import MenuSideBar from "../../components/Sidebar/sidebar";
import LinkedDevices from "./LinkedDevices/index";
import { searchBarByDevice } from "../../store/ducks/deviceManagement";

export default function Home() {
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    dispatch(searchBarByDevice(e.target.value));
  };

  return (
    <Div>
      <MenuSideBar />
      <Container>
        <div className="report-form">
          <Typography variant="h4" className="pageTitle">
            Gestão de dispositivos
          </Typography>
        </div>

        <div className="devicesSearchBar">
          <ReportTextField
            name="event"
            placeholder="Buscar por dispositivos"
            type="text"
            onChange={handleSearch}
          />
        </div>

        <LinkedDevices />
      </Container>
    </Div>
  );
}
