import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InfoWindow, Marker } from "@react-google-maps/api";
import { isEmpty } from "lodash";
import { Button } from "@material-ui/core";
import { SecurityAlertDialog } from "./SecurityAlertDialog";
import transferApi from "../../../services/transferApi";
import { setSecurityAlertsAction } from "../../../store/ducks/scripting";

export const SecurityAlerts = () => {
  const securityAlerts = useSelector((state) => state.scripting.securityAlerts);
  const incidentsNatures = useSelector(
    (state) => state.scripting.incidentsNatures
  );
  const currentTransferEditableInfo = useSelector(
    (state) => state.scripting.currentTransferEditableInfo
  );
  const directions = useSelector((state) => state.scripting.directions);
  const { accessToken } = useSelector((store) => store.auth.token);

  const [currentAlert, setCurrentAlert] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fiteredSecurityAlerts, setFilteredSecurityAlerts] = useState([]);

  const dispatch = useDispatch();

  const boolZeroDistanceRoute =
    directions &&
    directions.geocoded_waypoints.length === 2 &&
    directions.geocoded_waypoints[0].place_id ===
      directions.geocoded_waypoints[1].place_id;

  useEffect(() => {
    const getAndSetIncidents = async () => {
      if (boolZeroDistanceRoute || !directions) return;

      try {
        const request = {
          body: {
            after_date: currentTransferEditableInfo.afterDate,
            linestring: directions.routes[0].overview_polyline,
          },
          config: {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        };

        const response = await transferApi.post(
          "/incidents",
          request.body,
          request.config
        );

        return dispatch(setSecurityAlertsAction(response.data));
      } catch (err) {
        throw err;
      }
    };

    getAndSetIncidents();
  }, [directions, accessToken, currentTransferEditableInfo]);

  useEffect(() => {
    const alertsArray = [];

    // Função que filtra todos os securistyAlerts vindos da API (que estão no redux) de acordo com os tipos de incidentes marcados no checkbox;
    securityAlerts.forEach((alert) => {
      return incidentsNatures.forEach((category) => {
        if (category.id === alert.type.categoryId) {
          if (category.selected) {
            return alertsArray.push(alert);
          } else {
            return category.types.forEach((type) => {
              if (type.id === alert.type.externalId && type.selected)
                return alertsArray.push(alert);
            });
          }
        }
      });
    });

    setFilteredSecurityAlerts(alertsArray);
  }, [securityAlerts, incidentsNatures]);
  return (
    <>
      {fiteredSecurityAlerts &&
        fiteredSecurityAlerts.map((alert) => {
          return (
            <Marker
              onClick={() => {
                setCurrentAlert(alert);
              }}
              title={alert.title}
              key={alert.id}
              icon={alert.type.icon}
              animation={alert.id === currentAlert.id ? 1 : null}
              position={{
                lat: alert.location.lat,
                lng: alert.location.lng,
              }}
            />
          );
        })}

      {!isEmpty(currentAlert) && (
        <InfoWindow
          onCloseClick={() => setCurrentAlert({})}
          position={{
            lat: currentAlert.location.lat,
            lng: currentAlert.location.lng,
          }}
        >
          <div>
            <strong>Alerta: </strong> {currentAlert.title}
            <br />
            <Button
              onClick={() => setDialogOpen(true)}
              size="small"
              color="primary"
            >
              Clique aqui para mais informações
            </Button>
          </div>
        </InfoWindow>
      )}

      {dialogOpen && !isEmpty(currentAlert) && (
        <SecurityAlertDialog
          isOpen={dialogOpen}
          setIsOpen={setDialogOpen}
          currentAlert={currentAlert}
        />
      )}
    </>
  );
};
