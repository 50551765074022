import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";

import useStyles from "./styles";
import * as commonsUtils from "../../commons/utils";
import constants from "../../commons/constants";
import CentralizedLoading from "../CentralizedLoading";
import TablePaginationActionsButtons from "./TablePaginationActionButtons";
import { useEffectOptionalDependencies } from "../../commons/utils/hooks";

const SharedTable = forwardRef(
  (
    {
      dataFromRedux,
      cellHeaders,
      noRowsDataMessage = constants.tables.DEFAULT_NO_ROWS_MESSAGE,
      withPagination = false,
      rowsDataFetcher = async () => null,
      defaultRowsPerPage = constants.tables.DEFAULT_ROWS_PER_PAGE,
    },
    ref
  ) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [rowsData, setRowsData] = useState(null);
    const [totalRows, setTotalRows] = useState(null);
    const [fetchingRowsDataError, setFetchingRowsDataError] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [currentPage, setCurrentPage] = useState(constants.tables.FIRST_PAGE);
    const [headDirection, setHeadDirection] = useState("desc");
    const [orderBy, setOrderBy] = useState("ID");

    const delayFetchRowsDataCounter = useRef(0);

    const fetchRowsData = async ({
      waitTimeForAnotherCall = null,
      shouldGoBackToFirstPage = false,
    } = {}) => {
      if (!loading) setLoading(true);

      if (waitTimeForAnotherCall !== null) {
        const currentCallCounter = ++delayFetchRowsDataCounter.current;
        await commonsUtils.sleep(waitTimeForAnotherCall);
        if (currentCallCounter !== delayFetchRowsDataCounter.current) return;
      } else {
        delayFetchRowsDataCounter.current = 0;
      }

      let pageToLoad = currentPage;
      if (shouldGoBackToFirstPage) {
        setCurrentPage(0);
        pageToLoad = 0;
      }

      try {
        if (dataFromRedux) {
          setRowsData(dataFromRedux);
          if (dataFromRedux.totalRows) {
            setTotalRows(dataFromRedux.totalRows);
          } else {
            setTotalRows(null);
          }
        } else {
          const { rowsData, totalRows = null } = await rowsDataFetcher({
            currentPage: pageToLoad,
            rowsPerPage,
          });
          setRowsData(rowsData);
          setTotalRows(totalRows);
        }
      } catch (error) {
        setFetchingRowsDataError("Busca por transfers falhou.");
      } finally {
        setLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      fetchRowsData,
    }));

    useEffectOptionalDependencies(
      ({ fetchRowsData }) => {
        fetchRowsData();
      },
      [currentPage, rowsPerPage],
      {
        fetchRowsData,
      }
    );

    const handleTableSort = (e) => {
      let direction = headDirection === "asc" ? "desc" : "asc";
      setHeadDirection(direction);
      setOrderBy(e);
    };

    useEffect(() => {
      const index = cellHeaders.indexOf(orderBy) + 1;
      if (rowsData) {
        if (headDirection === "desc") {
          rowsData.sort((a, b) => {
            if (a[index]) {
              if (b[index].toString() > a[index].toString()) return -1;
              if (b[index].toString() < a[index].toString()) return 1;
              return 0;
            }
          });
        } else {
          rowsData.sort((a, b) => {
            if (a[index]) {
              if (a[index].toString() > b[index].toString()) return -1;
              if (a[index].toString() < b[index].toString()) return 1;
              return 0;
            }
          });
        }
      }
    }, [orderBy, headDirection, rowsData]);

    const RenderHeaders = () =>
      cellHeaders.map((header) => (
        <TableCell key={header} className={classes.styledTableHeaderCell}>
          {cellHeaders.indexOf(header) < 7 ? (
            <TableSortLabel
              active={header === orderBy}
              direction={headDirection}
              onClick={() => handleTableSort(header)}
            >
              {header}
            </TableSortLabel>
          ) : (
            header
          )}
        </TableCell>
      ));

    const RowsData = () => {
      if (!Array.isArray(rowsData) || rowsData.length === 0)
        return (
          <NoDataRow
            message={noRowsDataMessage}
            numberOfColumns={cellHeaders.length}
          />
        );
      return rowsData.map((rowData) => {
        const [id, actualRowsData] = [rowData[0], rowData.slice(1)];
        return (
          <TableRow key={id}>
            {Object.entries(actualRowsData).map(([key, value]) => (
              <TableCell className={classes.styledTableCell} key={key}>
                {[null, undefined, ""].includes(value) ? "-" : value}
              </TableCell>
            ))}
          </TableRow>
        );
      });
    };

    const handleChangePage = (event, newPage) => {
      setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      const newRowsPerPage = commonsUtils.representsInteger(event.target.value)
        ? parseInt(event.target.value)
        : constants.tables.DEFAULT_ROWS_PER_PAGE;

      setRowsPerPage(newRowsPerPage);
      setCurrentPage(constants.tables.FIRST_PAGE);
    };

    const handleLabelDisplayedRows = ({ from, to, count }) => {
      if (loading) return "Carregando...";

      if (count === 0 || count === null) return `0-0 de 0`;

      return `${from}-${to} de ${count}`;
    };

    return (
      <Table component={Paper} stickyHeader>
        <TableHead>
          <TableRow>
            <RenderHeaders />
          </TableRow>
        </TableHead>

        <TableBody>
          <TableBodyContent
            loading={loading}
            numberOfColumns={cellHeaders.length}
            message={fetchingRowsDataError}
            RowsData={RowsData}
          />
        </TableBody>

        <TableFooter>
          <TableRow>
            {withPagination && (
              <TablePagination
                colSpan={cellHeaders.length}
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Linhas por página:"
                labelDisplayedRows={handleLabelDisplayedRows}
                ActionsComponent={TablePaginationActionsButtons}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    );
  }
);

const TableBodyContent = ({
  RowsData,
  numberOfColumns,
  loading = false,
  message = null,
}) => {
  if (message !== null)
    return <NoDataRow numberOfColumns={numberOfColumns} message={message} />;

  if (loading) return <NoDataRow numberOfColumns={numberOfColumns} loading />;

  return <RowsData />;
};

const NoDataRow = ({
  numberOfColumns,
  loading = false,
  message = constants.tables.DEFAULT_NO_ROWS_MESSAGE,
}) => {
  const rowContent = loading ? (
    <CentralizedLoading text="Buscando dados..." />
  ) : (
    <center>{message}</center>
  );

  return (
    <TableRow>
      <TableCell colSpan={numberOfColumns}>{rowContent}</TableCell>
    </TableRow>
  );
};

export default SharedTable;
