import { makeStyles } from '@material-ui/styles'

import colors from '../../commons/colors'

const styles = {}

styles.button = {
  width: 5,
  borderRadius: 12,
  boxShadow: '0px 3px 6px #00000029',
  backgroundColor: colors.fullWhite,
  border: 'none',
  outline: 'none',

  '&:hover': {
    backgroundColor: colors.fullWhite,
  },
}

styles.buttonDisabled = {
  ...styles.button,
  backgroundColor: colors.lighterGray,
  boxShadow: 'none',
}

styles.ripple = {
  backgroundColor: colors.lighterGray
}

styles.deleteIcon = {
  color: colors.black,
}

styles.deleteIconDisabled = {
  color: colors.gray,
}

const useStyles = makeStyles(styles)

export default useStyles