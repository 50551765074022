import {
  all,
  call,
  put,
  select,
  takeLatest,
  take,
  delay,
  fork,
} from "redux-saga/effects";
import { Types } from "../ducks/auth";
import moment from "moment";
import { startConfiguration } from "pusher-redux";

import { Types as AuthTypes } from "../ducks/auth";
import { Types as SnackTypes } from "../ducks/snackbar";

import {
  authenticate,
  logout,
  getUserPermissions,
  getUser,
  setAuthStorage,
  clearStorageAuth,
  getAuthStorage,
} from "../../services/auth";
import { history } from "../../routes";
import * as ssoService from "../../services/sso";
import { isBefore } from "date-fns";

function* asyncAuthenticate({ payload: { formData } }) {
  try {
    const tokenData = yield call(authenticate, formData);

    const permissions = yield call(getUserPermissions, tokenData.accessToken);
    const user = yield call(getUser, tokenData.accessToken);

    if (
      user.customer.poc &&
      isBefore(new Date(user.customer.expiration_date), new Date())
    ) {
      yield call(logout, tokenData.accessToken);
      yield all([
        put({ type: AuthTypes.SIGN_FAILURE }),
        put({
          type: SnackTypes.OPEN_ERROR_SNACKBAR,
          payload: {
            message:
              "Verificamos que o seu período de testes terminou, favor entrar em contato com: comercial@grupounicad.com.br",
          },
        }),
      ]);
      return;
    }

    const permissionsValidation = permissions.data;
    const customer = parseInt(user.customer_id);
    yield all([
      put({ type: AuthTypes.SIGN_IN_SUCCESS, payload: { tokenData } }),
      put({ type: AuthTypes.CHANGE_CUSTOMER, payload: { customer } }),
      put({ type: AuthTypes.SET_USER, payload: user }),
      put({ type: AuthTypes.LOAD_PERMISSIONS, payload: { permissions } }),
      permissionsValidation.includes("can-monitoring-devices")
        ? call(history.push, "/monitoringmap")
        : permissionsValidation.includes("can-view-routes")
        ? call(history.push, "/transfers")
        : permissionsValidation.includes("can-view-preconfigured-transfers")
        ? call(history.push, "/preconfigured")
        : call(history.push, "/reports"),
    ]);
  } catch (err) {
    yield all([
      put({ type: AuthTypes.SIGN_FAILURE }),
      put({
        type: SnackTypes.OPEN_ERROR_SNACKBAR,
        payload: { message: "Login ou senha inválidos" },
      }),
    ]);
  }
}

function* asyncLogout() {
  try {
    const accessToken = yield select((state) => state.auth.token.accessToken);

    yield call(logout, accessToken);

    yield all([
      put({ type: AuthTypes.SUCCESS_LOGOUT }),
      call(history.push, "/"),
      call(ssoService.removeSSOIntegration),
      call(ssoService.removeSSOExpiresAt),
    ]);
  } catch {}
}

function* ssoAuthenticate({ payload: { code, sso } }) {
  yield call(ssoService.setSSOIntegration, sso);

  try {
    const authorization = yield call(
      ssoService.exchangeCodeForAuthorization,
      code
    );

    var user = yield call(getUser, authorization.access_token);

    var data = {
      ...authorization,
      user,
    };

    yield call(setAuthStorage, data);

    yield call(ssoService.setSSOExpiresAt, data.expires_at);
    yield put({ type: Types.AUTHENTICATED, payload: data });
    yield call(autoSelectClient);
  } catch (err) {
    yield call(clearStorageAuth);
    yield call(ssoService.removeSSOIntegration);
    yield call(ssoService.removeSSOExpiresAt);
    return;
  }

  data = {
    ...data,
    accessToken:
      data && data.hasOwnProperty("access_token") ? data.access_token : null,
  };

  try {
    const permissions = yield call(getUserPermissions, data.accessToken);
    const user = yield call(getUser, data.accessToken);

    const permissionsValidation = permissions.data;
    const customer = parseInt(user.customer_id);
    yield all([
      put({ type: AuthTypes.SIGN_IN_SUCCESS, payload: { tokenData: data } }),
      put({ type: AuthTypes.CHANGE_CUSTOMER, payload: { customer } }),
      put({ type: AuthTypes.SET_USER, payload: user }),
      put({ type: AuthTypes.LOAD_PERMISSIONS, payload: { permissions } }),
      permissionsValidation.includes("can-monitoring-devices")
        ? call(history.push, "/monitoringmap")
        : permissionsValidation.includes("can-view-routes")
        ? call(history.push, "/transfers")
        : permissionsValidation.includes("can-view-preconfigured-transfers")
        ? call(history.push, "/preconfigured")
        : call(history.push, "/reports"),
    ]);
  } catch (err) {
    yield all([
      put({ type: AuthTypes.SIGN_FAILURE }),
      put({
        type: SnackTypes.OPEN_ERROR_SNACKBAR,
        payload: { message: "Login ou senha inválidos" },
      }),
    ]);
  }
}

function* autoSelectClient() {
  const auth = yield select((state) => state.auth.data);

  if (auth) {
    if (auth.user.customer.childs.length === 0) {
      yield put({
        type: Types.CUSTOMER_SELECTED,
        payload: auth.user.customer_id,
      });
    }
  }
}

function* fetchLoadAuth() {
  try {
    const data = yield call(getAuthStorage);
    yield put({ type: Types.AUTHENTICATED, payload: data });

    if (data !== null) {
      startConfiguration({
        auth: {
          headers: { Authorization: `Bearer ${data.tokenData.accessToken}` },
        },
      });

      yield call(autoSelectClient);
    }
  } catch (err) {
    yield put({ type: Types.AUTHENTICATED, payload: null });
  }
}

function* refreshSSOAuth() {
  const sso = yield call(ssoService.getSSOIntegration);
  if (sso) {
    while (true) {
      try {
        const expiresAt = yield call(ssoService.getSSOExpiresAt);

        const auth = yield call(getAuthStorage);

        if (!auth) {
          window.location.href = `${process.env.REACT_APP_SSO_URL}/login?sso=${sso}&redirect=${process.env.REACT_APP_API_UTRANSFER_URL}/login/sso`;
          yield take("FOREVER");
        } else if (expiresAt) {
          const { refresh_token } = yield call(getAuthStorage);

          const diffToExpires = moment(expiresAt).diff(moment(), "minutes");

          if (diffToExpires <= 5) {
            try {
              const { data } = yield call(ssoService.ssoRefresh, {
                sso,
                refresh_token,
              });

              yield call(ssoService.setSSOExpiresAt, data.expires_at);

              yield all([
                put({ type: Types.AUTHENTICATED, payload: data }),
                call(setAuthStorage, data),
              ]);
            } catch (err) {
              window.location.href = `${process.env.REACT_APP_SSO_URL}/login?sso=${sso}&redirect=${process.env.REACT_APP_API_UTRANSFER_URL}/login/sso`;
              yield take("FOREVER");
            }
          }
        }
      } catch {}

      yield delay(60000);
    }
  }
}

export default function* authSaga() {
  yield call(fetchLoadAuth);
  yield all([
    takeLatest(Types.ASYNC_SIGN_IN_REQUEST, asyncAuthenticate),
    takeLatest(Types.ASYNC_LOAD_AUTHENTICATE, fetchLoadAuth),
    takeLatest(Types.ASYNC_SSO_AUTHENTICATE, ssoAuthenticate),
    takeLatest(AuthTypes.ASYNC_LOGOUT, asyncLogout),
    fork(refreshSSOAuth),
  ]);
}
