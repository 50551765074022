import styled from "styled-components";
import { styled as styledMUI, IconButton, Typography } from "@mui/material";

export const Div = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  overflow-y: auto;
  justify-content: space-evenly;

  .report-form {
    padding: 20px;
  }

  .pageTitle {
    font-weight: 600;
    color: #15172f;
  }

  .tabTitle {
    text-transform: none;
  }

  .devicesTabs {
    padding: 12px;
  }

  .devicesSearchBar {
    width: 30%;
    padding: 18px;
  }

  .tabsBorderBottomColor {
    background-color: #06b0cf;
  }
`;

export const StyledBtn = styledMUI(IconButton)(() => ({
  borderRadius: "12px",
  marginLeft: "20px",
  gap: "5px",
}));

export const StyledBtnText = styledMUI(Typography)(() => ({
  fontWeight: 500,
  fontSize: "20px",
  color: "#000000",
}));
