import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "material-react-toastify";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { useStyles } from "../styles";
import { getComparator, stableSort } from "../utils";
import {
  addTransferActiveAction,
  addDevicesAction,
  addDevicesIdsSelectedAction,
} from "../../../../store/ducks/monitoringMap";

import * as devicesProvider from "../../../../infra/http/devicesProvider";

import IconSelectors from "./IconSelectors";
import DeviceActive from "./DeviceActive";
import FlespiAlerts from "./FlespiAlerts";
import UpdatedAt from "../../componentUtils/UpdatedAt";
import MoreRowData from "./MoreRowData";
import DeviceBattery from "../../componentUtils/DeviceBattery";
import ProgressInfoButton from "./ProgressInfoButton";
import DeviceIdentifierOrAlias from "./DeviceIdentifierOrAlias";

const DEVICE_TRANSFERS_FETCH_INTERVAL_MS = 20000;

export default function EnhancedTableBody({
  order,
  orderBy,
  setStateOfSnackBarWarning,
  identifiersSelected,
  startCall,
  finishCall,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const deviceTransfers = useSelector((store) => store.monitoringMap.devices);
  const identifierSearch = useSelector(
    (store) => store.monitoringMap.identifierSearch
  );
  const transfersActive = useSelector(
    (store) => store.monitoringMap.transferActive
  );

  const dispatch = useDispatch();

  const addTransferActive = (transferActive) => {
    dispatch(addTransferActiveAction(transferActive));
  };

  useEffect(() => {
    fetchDeviceTransfers();

    const interval = setInterval(async () => {
      fetchDeviceTransfers();
    }, DEVICE_TRANSFERS_FETCH_INTERVAL_MS);

    return () => clearInterval(interval);
  }, []);

  const fetchDeviceTransfers = async () => {
    dispatch(addDevicesIdsSelectedAction(identifiersSelected));
    const deviceIds = identifiersSelected.map((id) => ({ id }));

    try {
      const deviceTransfers = await devicesProvider.getDeviceTransfers(
        deviceIds
      );
      dispatch(addDevicesAction(deviceTransfers));
    } catch (error) {
      const timeUntilNextTry = DEVICE_TRANSFERS_FETCH_INTERVAL_MS / 1000;
      toast.error(
        "Atualização de dispositivos falhou." +
          `Tentando novamente em ${timeUntilNextTry} segundos.`
      );
    }
  };

  const updateDeviceAliasRedux = ({ id, alias }) => {
    const foundDeviceTransfer = deviceTransfers.find((deviceTransfer) => {
      return deviceTransfer?.device?.id === id;
    });

    if (foundDeviceTransfer !== undefined) {
      foundDeviceTransfer.device.alias = alias;
      dispatch(addDevicesAction(deviceTransfers));
    }
  };

  const handleChange = (event) => {
    if (event !== expanded) {
      setExpanded(event);
    } else {
      setExpanded(false);
    }
  };

  const orderByDevicesWithTransfersFirst = (a, b) => {
    const aHasTransfer = !!a.transfer;
    const bHasTransfer = !!b.transfer;

    if ((aHasTransfer && bHasTransfer) || (!aHasTransfer && !bHasTransfer))
      return 0;

    if (aHasTransfer) return -1;
    else return 1;
  };

  const devicesSorted = deviceTransfers.sort(orderByDevicesWithTransfersFirst);

  const devicesStableSorted = stableSort(
    devicesSorted.filter((device) => {
      const identifierLowerCase = identifierSearch.toLowerCase();

      const deviceIdentifierChecking = device.device.identifier
        .toLowerCase()
        .includes(identifierLowerCase);

      if (device.device.alias !== null) {
        const deviceAliasChecking = device.device.alias
          .toLowerCase()
          .includes(identifierLowerCase);
        return deviceAliasChecking || deviceIdentifierChecking;
      }

      return deviceIdentifierChecking;
    }),
    getComparator(order, orderBy),
    orderBy
  );

  return (
    <TableBody>
      {devicesStableSorted.map((deviceTransfer, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;

        const transferValidation = _.findIndex(
          transfersActive,
          (transfer) => transfer.id === deviceTransfer.transfer?.id
        );

        if (deviceTransfer.transfer?.id && transferValidation < 0) {
          addTransferActive({
            id: deviceTransfer.transfer.id,
            deviceId: deviceTransfer.device.id,
          });
        }

        const updateDeviceAliasReduxRow = (alias) => {
          updateDeviceAliasRedux({
            id: deviceTransfer.device.id,
            alias,
          });
        };

        return (
          <Fragment key={`Table-${deviceTransfer.device.id}`}>
            <TableRow hover role="checkbox" color="primary" tabIndex={-1}>
              <TableCell align="center">
                <IconSelectors device={deviceTransfer} startCall={startCall} />
              </TableCell>

              <TableCell align="left">
                <DeviceIdentifierOrAlias
                  deviceId={deviceTransfer.device.id}
                  identifier={deviceTransfer.device.identifier}
                  alias={deviceTransfer.device.alias ?? null}
                  executeAfterUpdate={updateDeviceAliasReduxRow}
                />
              </TableCell>

              <TableCell
                align="left"
                className={classes.status}
                color="primary"
                id={labelId}
                scope="device"
                padding="none"
              >
                <DeviceActive device={deviceTransfer} />
              </TableCell>

              <TableCell align="left">
                <FlespiAlerts device={deviceTransfer} />
              </TableCell>

              <TableCell align="left">
                <DeviceBattery battery={deviceTransfer?.device?.battery} />
              </TableCell>

              <TableCell align="left">
                <ProgressInfoButton
                  deviceTransfer={deviceTransfer}
                  setStateOfSnackBarWarning={setStateOfSnackBarWarning}
                  finishCall={finishCall}
                />
              </TableCell>
              <TableCell align="left">
                <UpdatedAt takenAt={deviceTransfer?.device?.taken_at} />
              </TableCell>
              <TableCell align="center">
                <IconButton
                  size="small"
                  className={classes.iconArrow}
                  aria-label="expand row"
                  onClick={() => handleChange(deviceTransfer.device.id)}
                >
                  {expanded === deviceTransfer.device.id ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={8}
              >
                <MoreRowData
                  device={deviceTransfer}
                  expanded={expanded === deviceTransfer.device.id}
                />
              </TableCell>
            </TableRow>
          </Fragment>
        );
      })}
    </TableBody>
  );
}
