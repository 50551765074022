import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import FormHelperText from '@material-ui/core/FormHelperText';

import styles from './styles';

const NumberField = ({ classes, input: { name, onChange }, meta: { error } }) => {
  const [selectedDate, setDate] = useState(1);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Definir dias do mês</FormLabel>

        <div className={classes.fieldAndButtonContainer}>
          <TextField
            name={name}
            value={selectedDate}
            type="number"
            variant="outlined"
            margin="dense"
            className={classes.textFieldContainer}
            onChange={e => setDate(e.target.value)}
            InputProps={{
              inputProps: {
                min: 1,
                max: 31
              }
            }}
          />

          <Tooltip
            title="Adicionar"
            placement="right"
          >
            <IconButton
              color="primary"
              className={classes.addButton}
              onClick={() => onChange(selectedDate)}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
      </div>

      {error && (
        <FormHelperText error>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default withStyles(styles)(NumberField);