import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { useStyles } from "../UnityReport/styles";
import { handleGetSettings } from "../../../services/customerAreaManagement/setting";
import {
  Activities,
  Categories,
  Products,
  Units,
} from "../../../commons/enums/managementPeople";

const StyledTableCell = ({ children }) => {
  const { tableCell, tableHeaderCell } = useStyles();

  return (
    <TableCell className={`${tableCell} ${tableHeaderCell}`}>
      {children}
    </TableCell>
  );
};

export const EventReportTable = ({ events }) => {
  const customerId = useSelector((store) => store.auth.customer.customer);

  const [fields, setFields] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const currentUnitsRows =
    events &&
    events.slice(
      currentPage * rowsPerPage,
      currentPage * rowsPerPage + rowsPerPage
    );

  useEffect(() => {
    const config = async () => {
      const response = await handleGetSettings(customerId);
      const fields = response.map((n) => n.field);

      setFields(fields);
    };

    config();
  }, []);

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Data</StyledTableCell>
            {fields.includes(Units) && (
              <StyledTableCell>Unidade de Negócios</StyledTableCell>
            )}
            {fields.includes(Activities) && (
              <StyledTableCell>Atividade</StyledTableCell>
            )}
            {fields.includes(Categories) && (
              <StyledTableCell>Categoria</StyledTableCell>
            )}
            {fields.includes(Products) && (
              <StyledTableCell>Produto</StyledTableCell>
            )}
            <StyledTableCell>Dispositivo</StyledTableCell>
            <StyledTableCell>Evento</StyledTableCell>
            <StyledTableCell>Origem</StyledTableCell>
            <StyledTableCell>Destino</StyledTableCell>
            <StyledTableCell>Rotas</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {currentUnitsRows &&
            currentUnitsRows.map((eventRow, index) => {
              return (
                <TableRow key={eventRow.id}>
                  <TableCell>{eventRow.date}</TableCell>

                  {fields.includes(Units) && (
                    <TableCell>{eventRow.customerUnit}</TableCell>
                  )}

                  {fields.includes(Activities) && (
                    <TableCell>{eventRow.customerActivity}</TableCell>
                  )}

                  {fields.includes(Categories) && (
                    <TableCell>{eventRow.customerCategory}</TableCell>
                  )}

                  {fields.includes(Products) && (
                    <TableCell>{eventRow.product}</TableCell>
                  )}

                  <TableCell>{eventRow.bond}</TableCell>
                  <TableCell>{eventRow.name}</TableCell>
                  <TableCell>{eventRow.from}</TableCell>
                  <TableCell>{eventRow.to}</TableCell>
                  <TableCell>{eventRow.route}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              count={events ? events.length : 0}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={(event, newPage) => setCurrentPage(newPage)}
              onChangeRowsPerPage={(event) =>
                setRowsPerPage(parseInt(event.target.value))
              }
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
              labelRowsPerPage={"Linhas por página:"}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};
