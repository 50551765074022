import React, { useState } from "react";
import clsx from "clsx";
import {
  Drawer,
  Toolbar,
  CssBaseline,
  List,
  IconButton,
  Badge,
  Paper,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import {
  Mic as MicIcon,
  DirectionsCar as DirectionsCarIcon,
  History as HistoryIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import { useStyles } from "./styles";

import {
  changeShowModalVoice,
  changeShowVoiceSidebar,
  setCall,
} from "../../store/ducks/voice";

import _ from "lodash";

import ChatItem from "./ChatItem";

export default function VoiceSidebar() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [typeList, setTypeList] = useState(1);

  const showModalVoice = useSelector((store) => store.voice.showModalVoice);

  const showVoiceSidebar = useSelector((store) => store.voice.showVoiceSidebar);

  const notifications = useSelector((store) => store.voice.notifications);

  const calls = useSelector((store) => store.voice.calls);

  const handleChangeShowSidebarVoice = () => {
    dispatch(changeShowVoiceSidebar());
  };

  const handleChangeShowModalVoice = (data) => {
    dispatch(setCall(data));
    dispatch(changeShowModalVoice());
  };

  const handleShowVoice = (typeList, call) => {
    if (typeList == 1 && call.call_user_id) {
      return (
        <ChatItem call={call} action={() => handleChangeShowModalVoice(call)} />
      );
    }

    if (typeList == 2 && call.call_transfer_id) {
      return (
        <ChatItem call={call} action={() => handleChangeShowModalVoice(call)} />
      );
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div
        className={clsx(classes.appBar, {
          [classes.appBarShift]: showVoiceSidebar,
        })}
      >
        <Toolbar style={{ marginTop: "-115px" }}>
          <div className={classes.title} />
          <div className={classes.hide}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="center"
              onClick={() => handleChangeShowSidebarVoice()}
            >
              <Badge badgeContent={notifications.length} color="primary">
                <MicIcon
                  style={{
                    color: "#fff",
                    fontSize: "larger",
                    marginTop: "5px",
                  }}
                />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </div>
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerVoice]: false,
        })}
        variant="persistent"
        anchor="right"
        open={showVoiceSidebar}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerPaperVoice]: false,
          }),
        }}
      >
        <div className={classes.box}>
          <Paper className={classes.eventListContainer} elevation={2}>
            <div className={classes.boxHeader}>
              <div
                className={
                  typeList == 1
                    ? classes.itemBoxHeaderSelect
                    : classes.itemBoxHeader
                }
                onClick={() => setTypeList(1)}
              >
                <MicIcon />
                <strong>Chamadas Ativas</strong>
              </div>

              <div
                className={
                  typeList == 2
                    ? classes.itemBoxHeaderSelect
                    : classes.itemBoxHeader
                }
                onClick={() => setTypeList(2)}
              >
                <DirectionsCarIcon />
                <strong>Transfers</strong>
              </div>

              <div
                className={
                  typeList == 3
                    ? classes.itemBoxHeaderSelect
                    : classes.itemBoxHeader
                }
                onClick={() => setTypeList(3)}
              >
                <HistoryIcon />
                <strong>Historico</strong>
              </div>
            </div>

            <TextField
              style={{ marginTop: "20px", width: "100%" }}
              InputProps={{
                style: {
                  backgroundColor: "#EBF9FC",
                  borderRadius: 4,
                  border: "1px solid #EBF9FC",
                  fontSize: 16,
                  padding: "0px 10px",
                  position: "relative",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            >
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            </TextField>

            <List dense component="div" role="list">
              {calls.map((call) => handleShowVoice(typeList, call))}
            </List>
          </Paper>
        </div>
      </Drawer>
    </div>
  );
}
