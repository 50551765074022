import { areasApi } from "..";
import { appId } from "../../../commons/constants/app";
import { fromRiskZoneTypeApi } from "../mappers/risk-zone-type.mapper";

export const getRistAreaTypes = async ({ groupIds }) => {
  const response = await areasApi.get("/risk-zone-types", {
    params: {
      application_id: appId,
      groupIds,
    },
  });

  return response.data.map(fromRiskZoneTypeApi);
};
