import React from "react";
import Sidebar from "../../components/Sidebar/sidebar";
import { Container, Div } from "./style";
import Table from "../../components/TableTransferNew";
import Filter from "../../components/Filter/FilterNew";

export default function HomeNew() {
  return (
    <Div>
      <Sidebar />
      <Container>
        <h1>Transfers</h1>
        <Filter />
        <Table />
      </Container>
    </Div>
  );
}
