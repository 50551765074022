import { styled as styledMUI, TextField } from "@mui/material";

export const CustomTextFieldDrivers = styledMUI(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "5px",
    },
  },
  input: {
    fontSize: "16px",
    fontWeight: 400,
    height: "40px",
  },
  backgroundColor: "#F8F8F8",
  margin: "5px 0px 5px 0px",
  textAlign: "center",
}));
