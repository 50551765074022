import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import _ from "lodash";

import ReportMap from "../../../../../components/ReportMap";
import { getFlespiMessages } from "../../../../../services/flespiApiService";
import { getRiskZonesAlongTheWay } from "../../../../../services/transferService";
import * as deviceApiService from "../../../../../services/devicesService";
import {
  coordinatesToPolyline,
  polylineToCoordinates,
} from "../../../../../commons/utils/map";

import IconTo from "../../../../../assets/IconToRed.png";
import IconFrom from "../../../../../assets/IconFromRed.png";
import {
  Box,
  Grid,
  Paper,
  styled,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { getDistanceTraveled } from "../../../../../commons/utils/flespi";
import { useStyles } from "./styles";

const formatDatetime = (date) => {
  return format(parseISO(date), "dd'/'MM'/'yyyy 'às' HH:mm:ss");
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DeviceTrackMapReport = ({ match }) => {
  const { customer: customerId } = useSelector((store) => store.auth.customer);
  const deviceId = parseInt(match.params.deviceId);
  const styles = useStyles();
  const [trackCoordinates, setTrackCoordinates] = useState([]);
  const [riskZonesAlongTheWay, setRiskZonesAlongTheWay] = useState([]);
  const [travelledDistance, setTravelledDistance] = useState(0);
  const [deviceName, setDeviceName] = useState("");
  const [communitiesCriminalFactions, setCommunitiesCriminalFactions] =
    useState(false);
  const [subnormalClusters, setSubnormalClusters] = useState(false);
  const [riskUrbanArea, setRiskUrbanArea] = useState(false);

  const deviceTrackPolylineOptions = {
    strokeColor: "#0a21cc",
    strokeWeight: 5,
    strokeOpacity: 0.7,
  };

  const getTimestamp = (date) => {
    const timestamp = new Date(date).getTime();
    return Math.floor(timestamp / 1000);
  };

  useEffect(() => {
    const getDeviceTrack = async () => {
      try {
        const customerDevices = await deviceApiService.getOneCustomerDevice(
          customerId,
          deviceId
        );

        if (!customerDevices) {
          return;
        }

        const from = getTimestamp(match.params.from);
        const to = getTimestamp(match.params.to);
        const flespiMessages = await getFlespiMessages(
          customerDevices.flespi_id,
          {
            from,
            to,
          }
        );

        flespiMessages.sort((a, b) => {
          return new Date(a.timestamp) - new Date(b.timestamp);
        });

        const deviceTravelledDistance = getDistanceTraveled(
          flespiMessages,
          "kilometers"
        );

        const coordinates = flespiMessages
          .filter(
            (message) =>
              message["position.latitude"] && message["position.longitude"]
          )
          .map((message) => ({
            lat: message["position.latitude"],
            lng: message["position.longitude"],
          }));

        setDeviceName(customerDevices.alias || customerDevices.identifier);
        setTravelledDistance(deviceTravelledDistance);
        setTrackCoordinates(coordinates);
      } catch (error) {
        console.log(error);
      }
    };

    getDeviceTrack();
  }, []);

  const loadRiskZonesAlongTheWay = useCallback(async () => {
    const polyline = coordinatesToPolyline(trackCoordinates);
    const riskZones = await getRiskZonesAlongTheWay(
      polyline,
      match.params.from
    );

    setRiskZonesAlongTheWay(riskZones);
  }, [trackCoordinates]);

  const makeMarkers = () => {
    const markers = [];
    const initialCoordinate = trackCoordinates[0];
    const finalCoordinate = trackCoordinates[trackCoordinates.length - 1];

    if (initialCoordinate) {
      markers.push({
        key: "initial-coordinate-of-device-track",
        coordinate: initialCoordinate,
        icon: IconFrom,
      });
    }

    if (trackCoordinates.length > 1 && finalCoordinate) {
      markers.push({
        key: "final-coordinate-of-device-track",
        coordinate: finalCoordinate,
        icon: IconTo,
      });
    }

    return markers;
  };

  const risksAreas = [
    {
      id: 185,
      boolean: communitiesCriminalFactions,
    },
    { id: 193, boolean: subnormalClusters },
    { id: 4, boolean: riskUrbanArea },
  ];

  const makePolygons = () => {
    const riskZonesIds = risksAreas
      .filter((checker) => checker.boolean)
      .map((riskZoneId) => riskZoneId.id);

    if (riskZonesIds.length < 0) {
      return [];
    }

    const filteredRiskArea = riskZonesAlongTheWay.filter((getRiskZone) =>
      riskZonesIds.includes(getRiskZone.riskZone.typeId)
    );

    const polygonsData = filteredRiskArea
      .filter(
        (riskZone) =>
          riskZone.hasOwnProperty("polygon") &&
          riskZone.polygon.hasOwnProperty("coordinates") &&
          Array.isArray(riskZone.polygon.coordinates)
      )
      .map((riskZone) => {
        const riskZonePolygons = _.flattenDeep(
          riskZone.polygon.coordinates
        ).map(polylineToCoordinates);

        return riskZonePolygons.map((riskZonePolygon, index) => {
          return {
            key: `${riskZone.id}-${index}`,
            path: riskZonePolygon,
            options: {
              fillColor: "red",
              fillOpacity: 0.2,
              strokeColor: "red",
              strokeOpacity: 1,
              strokeWeight: 2,
              draggable: false,
              editable: false,
              geodesic: false,
              zIndex: 1,
            },
            area: riskZone,
          };
        });
      });

    return _.flattenDeep(polygonsData);
  };

  const addCommunitiesCriminalFactions = () => {
    setCommunitiesCriminalFactions((previousValue) => !previousValue);
  };

  const addSubnormalCluster = () => {
    setSubnormalClusters((previousValue) => !previousValue);
  };

  const addRiskUrbanArea = () => {
    setRiskUrbanArea((previousValue) => !previousValue);
  };

  if (trackCoordinates.length === 0) {
    return <></>;
  }

  return (
    <Box p={4}>
      <Grid container spacing={5}>
        <Grid container spacing={2} component={"header"}>
          <Grid item xs={6}>
            <Item>
              <Typography align={"left"}>
                <strong>Dispositivo: </strong> {deviceName}
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography align={"left"}>
                <strong>Distância percorrida: </strong>{" "}
                {travelledDistance.toFixed(2) + " Km"}
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <Typography align={"left"}>
                <strong>Periodo: </strong>
                {formatDatetime(match.params.from)}
                <strong> até </strong>
                {formatDatetime(match.params.to)}
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <div className={styles.divBox}>
                <Typography align={"left"}>
                  <strong>Áreas de risco:</strong>
                </Typography>
                <Typography align={"left"}>
                  <Checkbox
                    checked={communitiesCriminalFactions}
                    color={"primary"}
                    onClick={addCommunitiesCriminalFactions}
                  />
                  <strong>Comunidades e Facções Criminosas</strong>
                </Typography>
                <Typography align={"left"}>
                  <Checkbox
                    checked={subnormalClusters}
                    color={"primary"}
                    onClick={addSubnormalCluster}
                  />
                  <strong> Aglomerados Subnormais</strong>
                </Typography>
                <Typography align={"left"}>
                  <Checkbox
                    checked={riskUrbanArea}
                    color={"primary"}
                    onClick={addRiskUrbanArea}
                  />
                  <strong>Área Urbana de Risco</strong>
                </Typography>
              </div>
            </Item>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ padding: 0, marginTop: 12 }}
          component={"main"}
        >
          <Item
            style={{ width: "100%", height: "calc(100vh - 140px)", padding: 0 }}
          >
            <ReportMap
              defaultCenter={trackCoordinates[0]}
              onLoad={loadRiskZonesAlongTheWay}
              polylines={[
                {
                  key: "device-track",
                  coordinates: trackCoordinates,
                  options: deviceTrackPolylineOptions,
                },
              ]}
              markers={makeMarkers()}
              polygons={makePolygons()}
            />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeviceTrackMapReport;
