import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { handleOpenModalDeletePoi, setPois } from "../../../store/ducks/poi";
import { deletePOI, getPOIs } from "../../../infra/http/poiProvider";

import { Div, DivButtons, DivInfos, DivTitle } from "./styles";

import { Button, Dialog, Divider, Typography } from "@material-ui/core";

import { toast } from "material-react-toastify";

export const ModalDeletePoi = () => {
  const dispatch = useDispatch();

  const customerId = useSelector((store) => store.auth.customer.customer);
  const { poi, openModalDeletePoi } = useSelector((state) => state.poi);

  const handleDeletePoi = async () => {
    try {
      await deletePOI({ customerId, id: poi.id });
      const response = await getPOIs({ customerId });
      dispatch(setPois(response));
      dispatch(handleOpenModalDeletePoi());
      toast.success("Ponto de interesse deletado com sucesso.");
    } catch (error) {
      toast.error("Falha ao tentar excluir o ponto de interesse.");
    }
  };

  return (
    <Dialog
      style={{ zIndex: 2 }}
      maxWidth={"md"}
      aria-labelledby="customized-dialog-title"
      open={openModalDeletePoi}
    >
      <Div>
        <DivTitle>
          <h1>Alerta</h1>
        </DivTitle>
        <Divider />
        <DivInfos>
          <Typography>
            Tem certeza que deseja excluir este ponto de interesse? Esta ação é
            irreversivel.
          </Typography>
        </DivInfos>

        <DivButtons>
          <Button
            variant="outlined"
            style={{ width: "120px" }}
            onClick={() => dispatch(handleOpenModalDeletePoi())}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "120px" }}
            onClick={() => handleDeletePoi()}
          >
            Salvar
          </Button>
        </DivButtons>
      </Div>
    </Dialog>
  );
};
