import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormRegisterPreConfigured from "../Filter/Register/formRegisterPreConfigured";
import StepperDevices from "../Filter/Register/stepperDevices";
import { reduxForm } from "redux-form";
import WeekDays from "./WeekDays";
import { connect } from "react-redux";
import MonthDays from "./MonthDays";
import { formValueSelector } from "redux-form";

import { getCurrentDateString } from "../Filter/utils";
import { Div } from "./styles";
import { Types, genericAction } from "../../store/ducks/transfer";
import { toast } from "material-react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {},
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Configuração", "Cadastro", "Roteirização", "Vinculação"];
}

function getStepContent(
  stepIndex,
  setFromDate,
  setToDate,
  setScheduleTime,
  setFrequency,
  currentFrequency,
  defaultValues
) {
  switch (stepIndex) {
    case 0:
      return (
        <>
          <Div>
            <form noValidate>
              <TextField
                id="initial-date"
                variant="outlined"
                label="Início do Período"
                type="date"
                style={{ width: 260 }}
                defaultValue={defaultValues.fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>

            <form noValidate>
              <TextField
                id="final-date"
                variant="outlined"
                label="Final do Período"
                type="date"
                style={{ width: 260 }}
                defaultValue={defaultValues.toDate}
                onChange={(e) => setToDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Div>

          <br />
          <Div>
            <form noValidate>
              <TextField
                id="time"
                variant="outlined"
                label="Horário do Transfer"
                type="time"
                defaultValue={defaultValues.scheduleTime}
                style={{ width: 260 }}
                onChange={(e) => setScheduleTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
              />
            </form>
            <Autocomplete
              id="combo-box-frequency"
              options={frequency}
              defaultValue={currentFrequency}
              getOptionLabel={(option) => option.mask}
              style={{ width: 260 }}
              onChange={(e, value) => setFrequency(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: 260 }}
                  InputLabelProps={{ shrink: true }}
                  label="Frequência"
                  variant="outlined"
                />
              )}
            />
          </Div>
        </>
      );
    case 1:
      return <FormRegisterPreConfigured />;
    default:
      return <StepperDevices />;
  }
}

export let PreConfiguredTransferForm = () => {
  const [fromDate, setFromDate] = useState(getCurrentDateString());
  const [toDate, setToDate] = useState(getCurrentDateString());
  const [scheduleTime, setScheduleTime] = useState("");
  const stepCount = useSelector((state) => state.transfer.modalNavigationCount);
  const currentFrequency = useSelector(
    (state) => state.transfer.currentFrequency
  );
  const customDates = useSelector(
    (state) => state.form.preConfiguredTransferForm.values.custom_dates
  );
  const customDays = useSelector(
    (state) => state.form.preConfiguredTransferForm.values.custom_days
  );

  const setFrequency = (value) => {
    dispatch(genericAction(Types.FREQUENCY, value));
  };

  const defaultValues = {
    fromDate,
    toDate,
    scheduleTime,
    frequency,
  };

  const classes = useStyles();
  const steps = getSteps();
  const dispatch = useDispatch();

  const transferConfigurationAction = (
    fromDate,
    toDate,
    scheduleTime,
    frequency
  ) => {
    dispatch({
      type: "ADD_TRANSFER_CONFIGURATION",
      fromDate,
      toDate,
      scheduleTime,
      frequency,
    });
  };

  const handleNext = () => {
    let checkedFields;

    if (currentFrequency?.title === "custom_days") {
      checkedFields = customDays.some((day) => day.checked);
    } else if (currentFrequency?.title === "custom_dates") {
      checkedFields = customDates.length;
    } else {
      checkedFields = currentFrequency?.title;
    }

    if (
      !checkedFields ||
      currentFrequency?.length === 0 ||
      scheduleTime.length === 0
    ) {
      toast.warning("Preencha todos os campos");
      return;
    }

    dispatch(genericAction(Types.SET_MODAL_NAVIGATION_NEXT));

    if (stepCount === 0) {
      transferConfigurationAction(
        fromDate,
        toDate,
        scheduleTime,
        currentFrequency.title
      );
    }
  };

  const handleReset = () => {
    dispatch(genericAction(Types.SET_MODAL_NAVIGATION_RESET));
  };

  const [toDay] = new Date().toISOString().split("T");

  return (
    <div className={classes.root}>
      <Stepper activeStep={stepCount} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {stepCount === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              Todos os passos completos, clique em Salvar
            </Typography>
            <Button onClick={handleReset}>Restaurar</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(
                stepCount,
                setFromDate,
                setToDate,
                setScheduleTime,
                setFrequency,
                currentFrequency,
                defaultValues
              )}
              {stepCount == 0 ? (
                <div>
                  <form className={classes.instructions}>
                    {currentFrequency?.title === "custom_days" && <WeekDays />}
                    {currentFrequency?.title === "custom_dates" && (
                      <center>
                        <MonthDays />
                      </center>
                    )}
                  </form>
                </div>
              ) : (
                ""
              )}
            </Typography>
            <div>
              {stepCount === 0 && (
                <Button
                  disabled={
                    fromDate < toDay ||
                    fromDate > toDate ||
                    !fromDate ||
                    !toDate
                  }
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Próximo
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PreConfiguredTransferForm = reduxForm({
  form: "preConfiguredTransferForm",
  destroyOnUnmount: false,
  initialValues: {
    custom_dates: [],
    custom_days: [
      { value: "sundays", checked: false },
      { value: "mondays", checked: false },
      { value: "tuesdays", checked: false },
      { value: "wednesdays", checked: false },
      { value: "thursdays", checked: false },
      { value: "fridays", checked: false },
      { value: "saturdays", checked: false },
    ],
  },
})(PreConfiguredTransferForm);

const frequency = [
  { title: "daily", mask: "Diária" },
  { title: "weekday", mask: "Segunda a Sexta" },
  { title: "custom_days", mask: "Definir dias da semana" },
  { title: "custom_dates", mask: "Definir dias do mês" },
];

export const mapStateToProps = (state) => {
  const selector = formValueSelector("preConfiguredTransferForm");

  return { customDays: selector(state, "custom_days") };
};

export default connect(mapStateToProps)(PreConfiguredTransferForm);
