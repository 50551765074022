export const WEATHER_ICON = {
  CLEAR: "https://yastatic.net/weather/i/icons/funky/dark/skc_d.svg",
  PARTLY_CLOUDY: "https://yastatic.net/weather/i/icons/funky/dark/bkn_d.svg",
  CLOUDY: "https://yastatic.net/weather/i/icons/funky/dark/ovc.svg",
  OVERCAST: "https://yastatic.net/weather/i/icons/funky/dark/ovc.svg",
  LIGHT_RAIN: "https://yastatic.net/weather/i/icons/funky/dark/ovc_-ra.svg",
  RAIN: "https://yastatic.net/weather/i/icons/funky/dark/ovc_ra.svg",
  HEAVY_RAIN: "https://yastatic.net/weather/i/icons/funky/dark/ovc_+ra.svg",
  SHOWERS: "https://yastatic.net/weather/i/icons/funky/dark/ovc_-ra.svg",
  SLEET: "https://yastatic.net/weather/i/icons/funky/dark/ovc_ra_sn.svg",
  LIGHT_SNOW: "https://yastatic.net/weather/i/icons/funky/dark/ovc_-sn.svg",
  SNOW: "https://yastatic.net/weather/i/icons/funky/dark/ovc_sn.svg",
  SNOWFALL: "https://yastatic.net/weather/i/icons/funky/dark/ovc_sn.svg",
  HAIL: "https://yastatic.net/weather/i/icons/funky/dark/ovc_ra_sn.svg",
  THUNDERSTORM: "https://yastatic.net/weather/i/icons/funky/dark/ovc_+ra.svg",
  THUNDERSTORM_WITH_RAIN:
    "https://yastatic.net/weather/i/icons/funky/dark/ovc_+ra.svg",
};
