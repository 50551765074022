import React from "react";
import { CircularProgress } from "@material-ui/core";

export const ReportLoader = () => {
  return (
    <center>
      <div>
        <CircularProgress
          size={68}
          style={{ marginTop: 200, color: "#63a9ef" }}
        />
      </div>
      <span>Carregando...</span>
    </center>
  );
};
