import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InfoWindow, Polygon } from "@react-google-maps/api";
import decode from "decode-google-map-polyline";
import { isEmpty } from "lodash";
import transferApi from "../../../services/transferApi";
import { setRiskLayersAction } from "../../../store/ducks/scripting";
import { polygonOptions } from "./constants/polygonOptions";
import { useRiskZonesPolygon } from "./hooks/useRiskZonesPolygon";

export const RiskZonesPolygon = () => {
  const riskLayers = useSelector((state) => state.scripting.riskLayers);
  const activeRiskLayers = useSelector(
    (store) => store.scripting.activeRiskLayers
  );

  const directions = useSelector((state) => state.scripting.directions);
  const currentTransferEditableInfo = useSelector(
    (state) => state.scripting.currentTransferEditableInfo
  );
  const accessToken = useSelector((store) => store.auth.token.accessToken);
  const dispatch = useDispatch();
  const {
    handleMouseOver,
    handleMouseOut,
    infoWindow,
    handleCloseClick,
    handleMouseMove,
  } = useRiskZonesPolygon();

  useEffect(() => {
    if (!directions) return;

    const generatePaths = (polygon) =>
      Object.assign(polygon, {
        paths: decode(polygon.polygon.coordinates[0]),
      });

    const request = {
      body: {
        radius: currentTransferEditableInfo.radius,
        linestring: directions.routes[0].overview_polyline,
      },
      config: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    };
    const getRiskZones = async () => {
      try {
        const { data } = await transferApi.post(
          "/risk-zones",
          request.body,
          request.config
        );
        const newRiskLayers = data.map((polygon) => {
          return polygon.polygon.type === "Polygon"
            ? generatePaths(polygon)
            : polygon;
        });
        return dispatch(setRiskLayersAction(newRiskLayers));
      } catch (error) {
        console.error(error);
      }
    };
    getRiskZones();
  }, [currentTransferEditableInfo.radius, directions, accessToken]);

  if (!riskLayers) return null;
  return (
    <>
      {riskLayers
        .filter((item) => activeRiskLayers.includes(item.riskZone.typeId))
        .map((polygon, index) => {
          return polygon.paths ? (
            <Polygon
              key={index}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onMouseMove={(event) => handleMouseMove(event, polygon)}
              paths={polygon.paths}
              options={polygonOptions}
            />
          ) : (
            polygon.polygon.coordinates.map((p, i) => {
              try {
                const paths = decode(p[0]);

                if (!paths) return null;

                return (
                  <Polygon
                    key={`${index}-${i}`}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onMouseMove={(event) => handleMouseMove(event, polygon)}
                    paths={paths}
                    options={polygonOptions}
                  />
                );
              } catch (error) {
                console.error(error);
                return null;
              }
            })
          );
        })}

      {!isEmpty(infoWindow) && (
        <InfoWindow
          onCloseClick={handleCloseClick}
          position={{
            lat: Number(infoWindow.lat),
            lng: Number(infoWindow.lng),
          }}
        >
          <div>{infoWindow.body}</div>
        </InfoWindow>
      )}
    </>
  );
};
