import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { subDays, parse as parseDate } from "date-fns";
import { toast } from "material-react-toastify";

import ReportSelector from "./ReportSelector";
import ReportTextField from "./ReportTextField";
import DateTimePicker from "./DateTimePicker";
import Identifier from "./Identifier";
import useStyles from "./styles";
import * as devicesProvider from "../../infra/http/devicesProvider";
import * as deviceApiService from "../../services/devicesService";
import Logger from "../../commons/utils/logger";
import "./styles.css";
import {
  changeDateFrom,
  changeDateTo,
  cleanFieldsAction,
  handleRequest,
} from "../../store/ducks/reporting";
import { fillCustomerUnitsAction } from "../../store/ducks/customerUnit";
import { fillCustomerActivitiesAction } from "../../store/ducks/customerActivities";
import { fillCustomerCategoriesAction } from "../../store/ducks/customerCategories";
import { fillCustomerProductsAction } from "../../store/ducks/customerProducts";
import {
  getOccurenceData,
  getDeviceApiData,
  getTransfersWithDevicesData,
  getDataEmissionsCo2,
  activeLoadingGasesReport,
} from "../../store/ducks/reportingData";
import { useLocation } from "react-router-dom";
import { handleGetSettings } from "../../services/customerAreaManagement/setting";
import { getDataEmissionCo2 } from "../../services/reportEmissionsCo2/totalEmissionCo2";

const MAX_RANGE_FROM_TO_DATE_DAYS = 90;

const moduleLogger = Logger.createInitialLogger("ReportForm");

const ReportForm = ({ doFilter, values = {} }) => {
  const classes = useStyles();
  const location = useLocation();
  const { state } = location;

  const initialValues = useMemo(
    () => ({
      identifier: "",
      customerUnit: "",
      fromDate: parseDate("00:00:00", "HH:mm:ss", subDays(new Date(), 7)),
      toDate: parseDate("23:59:59", "HH:mm:ss", new Date()),
      unity: "",
      event: "",
      responsible: "",
      customerUnitIds: [],
      customerCategoryIds: [],
      customerActivityIds: [],
      customerProductIds: [],
      ...values,
    }),
    [new Date().getDate(), values]
  );

  const [identifier, setIdentifier] = useState(initialValues.identifier);
  const [fromDate, setFromDate] = useState(initialValues.fromDate);
  const [toDate, setToDate] = useState(initialValues.toDate);
  const [unity, setUnity] = useState(initialValues.unity);
  const [event, setEvent] = useState(initialValues.event);
  const [responsible, setResponsible] = useState(initialValues.responsible);
  const [customerUnitIds, setCustomerUnitIds] = useState(
    initialValues.customerUnitIds
  );
  const [customerCategoryIds, setCustomerCategoryIds] = useState(
    initialValues.customerCategoryIds
  );
  const [customerActivityIds, setCustomerActivityIds] = useState(
    initialValues.customerActivityIds
  );
  const [customerProductIds, setCustomerProductIds] = useState(
    initialValues.customerProductIds
  );
  const [identifiersList, setIdentifiersList] = useState([]);
  const [customerUnit, setCustomerUnit] = useState([]);
  const [customerCategories, setCustomerCategories] = useState([]);
  const [customerActivities, setCustomerActivities] = useState([]);
  const [customerProducts, setCustomerProducts] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const dispatch = useDispatch();

  const { customer } = useSelector((store) => store.auth.customer);
  const customerId = useSelector((store) => store.auth.customer.customer);
  const tabSelected = useSelector((state) => state.reporting.tabSelected);
  const monitored = useSelector(
    (state) => state.reportData.transfersWithDevicesMonitored
  );

  const fromDateMinDate = useMemo(() => {
    if (toDate === null) return null;

    return parseDate(
      "00:00:00",
      "HH:mm:ss",
      subDays(toDate, MAX_RANGE_FROM_TO_DATE_DAYS)
    );
  }, [toDate]);
  const fromDateMaxDate = toDate;
  const toDateMinDate = fromDate;
  const toDateMaxDate = initialValues.toDate;

  const canSubmit =
    fromDateMinDate.getTime() <= fromDate.getTime() &&
    fromDate.getTime() <= fromDateMaxDate.getTime() &&
    toDateMinDate.getTime() <= toDate.getTime() &&
    toDate.getTime() <= toDateMaxDate.getTime();

  useEffect(() => {
    handleFilterCallback();
    const config = async () => {
      const response = await handleGetSettings(customerId);

      setConfigurations(response);
    };

    config();
  }, []);

  useEffect(() => {
    const execute = async () => {
      try {
        const allDevices = await devicesProvider.getDevices({
          customerId: customer,
        });
        setIdentifiersList(allDevices);

        const customerUnits = await deviceApiService.getCustomerUnits(customer);
        setCustomerUnit(customerUnits);
        dispatch(fillCustomerUnitsAction(customerUnits));

        const customerCategories = await deviceApiService.getCustomerCategories(
          customer
        );
        setCustomerCategories(customerCategories);
        dispatch(fillCustomerCategoriesAction(customerCategories));

        const customerActivities = await deviceApiService.getCustomerActivities(
          customer
        );
        setCustomerActivities(customerActivities);
        dispatch(fillCustomerActivitiesAction(customerActivities));

        const customerProducts = await deviceApiService.getCustomerProducts(
          customer
        );

        setCustomerProducts(customerProducts);
        dispatch(fillCustomerProductsAction(customerProducts));
      } catch (error) {
        moduleLogger.error("Failed to retrieve device identifiers.", error);
      }
    };
    execute();
  }, [customer]);

  const optionsFilters = {
    customerId: customer,
    identifier,
    from: fromDate,
    to: toDate,
    unity,
    event,
    responsible,
    customerUnitIds,
    customerCategoryIds,
    customerActivityIds,
    customerProductIds,
  };
  const occurenceTab = ({ to, from, customerUnitIds, customerId }) => {
    const filters = {
      from,
      to,
      customerUnitIds,
      customerId,
    };
    dispatch(getOccurenceData(filters));
  };

  const reportTab = ({ to, from }) => {
    dispatch(changeDateFrom(from));
    dispatch(changeDateTo(to));
  };

  const gasTab = async ({ from, to, customerId }) => {
    const filters = {
      from,
      to,
      customerId,
    };

    dispatch(changeDateFrom(from));
    dispatch(changeDateTo(to));

    try {
      dispatch(activeLoadingGasesReport(true));
      const data = await getDataEmissionCo2(filters);
      dispatch(getDataEmissionsCo2(data));
    } catch (error) {
      toast.error("Falha na requisição.");
    } finally {
      dispatch(activeLoadingGasesReport(false));
    }
  };

  const identifierTab = ({ customerId }) => {
    dispatch(getDeviceApiData(customerId));
  };

  const eventReportTab = (filters) => {
    const params = {
      ...filters,
      monitored,
    };

    dispatch(getTransfersWithDevicesData(params));
  };

  const filterProvider = {
    occurenceTabSelected: occurenceTab,
    gasTabSelected: gasTab,
    identifierTabSelected: identifierTab,
    eventReportTabSelected: eventReportTab,
    reportDevice: reportTab,
  };

  const handleFilterCallback = useCallback(() => {
    doFilter({
      identifier,
      from: fromDate,
      to: toDate,
      unity,
      event,
      responsible,
      customerUnitIds,
      customerCategoryIds,
      customerActivityIds,
      customerProductIds,
    });
  }, [
    identifier,
    fromDate,
    toDate,
    unity,
    event,
    responsible,
    customerUnitIds,
    customerCategoryIds,
    customerActivityIds,
    customerProductIds,
  ]);

  const handleFilterMain = () => {
    filterProvider[tabSelected] && filterProvider[tabSelected](optionsFilters);
    handleFilterCallback();
    dispatch(handleRequest());
  };

  function handleResetForm() {
    setIdentifier(initialValues.identifier);
    setFromDate(initialValues.fromDate);
    setToDate(initialValues.toDate);
    setUnity(initialValues.unity);
    setEvent(initialValues.event);
    setResponsible(initialValues.responsible);
    setCustomerUnitIds(initialValues.customerUnitIds);
    setCustomerActivityIds(initialValues.customerActivityIds);
    setCustomerCategoryIds(initialValues.customerCategoryIds);
    setCustomerProductIds(initialValues.customerProductIds);

    handleFilterCallback();
  }

  const disableEvent = useSelector((store) => store.reporting.disableEvent);
  const disableCustomerUnit = useSelector(
    (store) => store.reporting.disableCustomerUnit
  );
  const disableCustomerActivity = useSelector(
    (store) => store.reporting.disableCustomerActivity
  );
  const disableCustomerCategory = useSelector(
    (store) => store.reporting.disableCustomerCategory
  );
  const disableCustomerProduct = useSelector(
    (store) => store.reporting.disableProduct
  );

  const cleanFields = useSelector((store) => store.reporting.cleanFields);

  useEffect(() => {
    if (cleanFields) {
      handleResetForm();
      dispatch(cleanFieldsAction(false));
    }
  }, [cleanFields]);

  const handleCustomerUnits = (event, value) => {
    const customerUnitIds = value.map((units) => units.id);
    setCustomerUnitIds(customerUnitIds);
  };

  const handleCustomerCategory = (event, value) => {
    const customerCategoryIds = value.map((category) => category.id);
    setCustomerCategoryIds(customerCategoryIds);
  };

  const handleCustomerActivity = (event, value) => {
    const customerActivityIds = value.map((activity) => activity.id);
    setCustomerActivityIds(customerActivityIds);
  };

  const handleCustomerProduct = (event, value) => {
    const customerProductIds = value.map((activity) => activity.id);
    setCustomerProductIds(customerProductIds);
  };

  const handleIdentifierChange = (event, deviceData) => {
    if (deviceData) setIdentifier(deviceData.identifier);
  };

  const handleFromDateChange = (date) => {
    if (date) {
      dispatch(changeDateFrom(date));
      setFromDate(date);
    } else {
      toast.error("Data inválida!");
    }
  };

  const handleToDateChange = (date) => {
    if (date) {
      dispatch(changeDateTo(date));
      setToDate(date);
    } else {
      toast.error("Data inválida!");
    }
  };

  const handleEventChange = (event) => {
    setEvent(event.target.value);
    setUnity("");
  };

  useEffect(() => {
    if (state?.tab) {
      handleFilterMain();
    }
  }, []);

  const fields = {
    1: {
      options: customerUnit,
      placeholder: "Unidades",
      value: customerUnitIds,
      onChange: handleCustomerUnits,
      disable: disableCustomerUnit,
    },
    2: {
      options: customerCategories,
      placeholder: "Categorias",
      value: customerCategoryIds,
      onChange: handleCustomerCategory,
      disable: disableCustomerCategory,
    },
    3: {
      options: customerProducts,
      placeholder: "Produto",
      value: customerProductIds,
      onChange: handleCustomerProduct,
      disable: disableCustomerProduct,
    },
    4: {
      options: customerActivities,
      placeholder: "Atividades",
      value: customerActivityIds,
      onChange: handleCustomerActivity,
      disable: disableCustomerActivity,
    },
  };

  return (
    <div className={classes.reportFormComponentRoot}>
      <div className={classes.searchButtonsColumn}>
        <div className={classes.searchFieldsRow}>
          <Identifier
            options={identifiersList}
            onChange={handleIdentifierChange}
            placeholder="Identificador"
            value={identifier}
          />
          <DateTimePicker
            minDateMessage="Data de início deve ser, no máximo, 90 dias antes da data final."
            maxDateMessage="Data de início não pode ser maior que a final"
            minDate={fromDateMinDate}
            maxDate={fromDateMaxDate}
            placeholder="Início"
            onChange={handleFromDateChange}
            value={fromDate}
          />
          <DateTimePicker
            minDateMessage="Data final não pode ser menor que a inicial."
            maxDateMessage="Data final não pode ser superior a atual."
            minDate={toDateMinDate}
            maxDate={toDateMaxDate}
            placeholder="Fim"
            onChange={handleToDateChange}
            value={toDate}
          />
        </div>
        <div className={classes.searchFieldsRow}>
          {configurations
            .filter(({ field }) => fields[field])
            .map(({ field }) => {
              const { options, placeholder, value, onChange, disable } =
                fields[field];

              return (
                <ReportSelector
                  key={field}
                  options={options}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  disable={disable}
                />
              );
            })}
          <ReportTextField
            name="event"
            placeholder="Evento"
            type="text"
            value={event}
            onChange={handleEventChange}
            disable={disableEvent}
          />
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          fullWidth
          variant="outlined"
          className={classes.filterButton}
          onClick={handleResetForm}
        >
          Limpar Filtros
        </Button>
        <Button
          disabled={!canSubmit || !tabSelected}
          fullWidth
          variant="contained"
          className={classes.submitButton}
          onClick={handleFilterMain}
        >
          Buscar
        </Button>
      </div>
    </div>
  );
};

export default ReportForm;
