import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";

import Indicator from "./components/Indicator";
import Square from "./components/Square";
import CustomerUnitSection from "./components/CustomerUnitSection";
import useStyles from "./styles";

import colors from "../../../commons/dashboardColors";
import Loading from "../../../components/Loading";
import { getDashboardReport } from "../../../services/eventAnalyticsApi/eventService";

const eventTypesSubtitles = {
  "leave-route": {
    indicator: "Desvios de Rotas",
    graphic: "Desvios de Rotas",
    negative: "Rotas sem desvios",
  },
  "enter-risk-zone": {
    indicator: "Entrada em Áreas de Risco",
    graphic: "Entrada em Áreas de Risco",
    negative: "Não entraram em área de risco",
  },
  "leave-virtual-fence": {
    indicator: "Saída de cerca virtual",
    graphic: "Saída de cerca virtual",
    negative: "Não sairam da cerca virtual",
  },
  "panic-alert": {
    indicator: "Acionamento de botão de pânico",
    graphic: "Acionamento de botão de pânico",
    negative: "Não acionaram botão de pânico",
  },
  "timeout-at-stop-point": {
    indicator: "Tempo excedido em ponto de parada",
    graphic: "Tempo excedido em ponto de parada",
    negative: "Não tiveram o tempo excedido em ponto de parada",
  },
};

const EventAnalyticsDashboard = ({ filters }) => {
  const classes = useStyles();
  const customerId = useSelector((store) => store.auth.customer.customer);

  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    legends: [],
    summary: {
      indicators: [],
      eventGraphics: [],
    },
    customerUnits: [],
  });

  const filterEvents = (events, type, customerUnitId) => {
    if (customerUnitId) {
      return events.filter((event) => {
        return event.type === type && event.customerUnitId === customerUnitId;
      });
    }

    return events.filter((event) => event.type === type);
  };

  const filterTransfers = (transfers, customerUnitId = null) => {
    if (customerUnitId) {
      return transfers.filter(
        (transfer) => transfer.customerUnitId === customerUnitId
      );
    }

    return transfers;
  };

  const getLegends = (customerUnits) => {
    return customerUnits.map((customerUnit, index) => ({
      color: colors[index],
      label: customerUnit.name,
      id: customerUnit.id,
    }));
  };

  const getIndicators = (events, transfers, customerUnitId = null) => {
    const indicators = [
      {
        id: customerUnitId
          ? `customer-unit-${customerUnitId}-total-transfers`
          : `total-transfers`,
        label: "Transfers Cadastrados",
        value: filterTransfers(transfers, customerUnitId).length,
      },
    ];

    for (const eventType in eventTypesSubtitles) {
      const eventsOftype = filterEvents(events, eventType, customerUnitId);
      const id = customerUnitId
        ? `customer-unit-${customerUnitId}-indicator-${eventType}`
        : `indicator-${eventType}`;
      indicators.push({
        id,
        label: eventTypesSubtitles[eventType].indicator,
        value: eventsOftype.length,
      });
    }

    return indicators;
  };

  const getCustomerUnitsEvents = (events, customerUnits, transfers) => {
    const customerUnitsEvents = [];

    for (let i = 0; i < customerUnits.length; i++) {
      const customerUnit = customerUnits[i];
      const customerUnitEvent = {
        id: `customer-unit-${customerUnit.id}-section`,
        label: customerUnit.name,
        indicators: getIndicators(events, transfers, customerUnit.id),
        graphics: [],
      };
      const customerUnitTransfers = transfers.filter(
        (transfer) => transfer.customerUnitId == customerUnit.id
      );

      for (const eventType in eventTypesSubtitles) {
        const eventsOftype = filterEvents(events, eventType, customerUnit.id);
        const transferIds = eventsOftype.map((event) => event.transferId);
        const uniqueTransferIds = [...new Set(transferIds)];

        customerUnitEvent.graphics.push({
          id: `customer-unit-${customerUnit.id}-graphic-${eventType}`,
          label: eventTypesSubtitles[eventType].indicator,
          data: [
            {
              name: eventTypesSubtitles[eventType].graphic,
              value: uniqueTransferIds.length,
            },
            {
              name: eventTypesSubtitles[eventType].negative,
              value: customerUnitTransfers.length - uniqueTransferIds.length,
            },
          ],
        });
      }

      customerUnitsEvents.push(customerUnitEvent);
    }

    return customerUnitsEvents;
  };

  const getEventGraphicsComparingCustomerUnits = (events, customerUnits) => {
    const eventGraphics = [];

    for (const eventType in eventTypesSubtitles) {
      const eventGraphic = {
        id: `summary-graphic-${eventType}`,
        label: eventTypesSubtitles[eventType].indicator,
        data: [],
      };

      let hasData = false;

      for (let i = 0; i < customerUnits.length; i++) {
        const customerUnit = customerUnits[i];
        const eventsOftypeInCustomerUnit = filterEvents(
          events,
          eventType,
          customerUnit.id
        );

        if (eventsOftypeInCustomerUnit.length > 0) {
          hasData = true;
        }

        eventGraphic.data.push({
          label: customerUnit.name,
          value: eventsOftypeInCustomerUnit.length,
        });
      }

      if (!hasData) {
        eventGraphic.data = [];
      }

      eventGraphics.push(eventGraphic);
    }

    return eventGraphics;
  };

  const customerUnitsWereSelect = () => {
    return (
      filters.customerUnitIds &&
      Array.isArray(filters.customerUnitIds) &&
      filters.customerUnitIds.length > 0
    );
  };

  useEffect(() => {
    const getDashboardData = async () => {
      setLoading(true);
      const { from, to, customerUnitIds } = filters;

      const data = await getDashboardReport({
        endedAt: to,
        startedAt: from,
        customerId,
        customerUnitIds,
      });

      const summaryIndicators = getIndicators(data.events, data.meta.transfers);
      const eventGraphics = getEventGraphicsComparingCustomerUnits(
        data.events,
        data.meta.customerUnits
      );
      const customerUnitsEvents = getCustomerUnitsEvents(
        data.events,
        data.meta.customerUnits,
        data.meta.transfers
      );

      setDashboardData({
        legends: getLegends(data.meta.customerUnits),
        summary: {
          indicators: summaryIndicators,
          eventGraphics,
        },
        customerUnits: customerUnitsEvents,
      });

      setLoading(false);
    };

    if (customerUnitsWereSelect()) {
      getDashboardData();
    }
  }, [filters, customerId]);

  const getGroupedEvents = () => {
    const events = dashboardData.events.reduce((acc, event) => {
      if (!acc[event.type]) {
        acc[event.type] = {
          label: eventTypesSubtitles[acc.type],
          total: 0,
        };
      }

      acc[event.type].total++;

      return acc;
    }, {});

    return events;
  };

  if (!customerUnitsWereSelect()) {
    return (
      <Box className={classes.noticeContainer}>
        <Typography>Por favor selecione uma unidade</Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <div className={classes.noticeContainer}>
        <Loading size={68} color="#63a9ef" text="Carregando dados..." />
      </div>
    );
  }

  return (
    <Grid container>
      <Grid container className={classes.containerOverview}>
        {dashboardData.legends.length > 0 && (
          <Grid item xs={"auto"} className={classes.legendColumn}>
            Legendas:
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {dashboardData.legends.map(({ id, color, label }) => (
                <ListItem key={id}>
                  <Square color={color} size={25} />
                  <ListItemText primary={label} />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
        <Grid item xs={12} md className={classes.mainOverview}>
          <div className={classes.mainOverviewHeader}>
            <Typography variant="h5" className={classes.mainOverviewTitleText}>
              Visão Geral
            </Typography>
          </div>
          <Grid
            container
            spacing={2}
            className={classes.mainOverviewIndicators}
          >
            {dashboardData.summary.indicators.map(({ id, label, value }) => (
              <Grid key={id} item xs={12} md={6} lg={4} xl={3}>
                <Indicator number={value} label={label} />
              </Grid>
            ))}
          </Grid>
          <div className={classes.mainOverviewHeader}>
            <Typography variant="h5" className={classes.mainOverviewTitleText}>
              Ocorrências
            </Typography>
            <Typography>
              Estatísticas de desvio de rotas, aproximação e entrada em áreas de
              riscos
            </Typography>
          </div>
          <Grid
            container
            spacing={2}
            className={classes.mainOverviewIndicators}
          >
            {dashboardData.summary.eventGraphics.map(({ id, label, data }) => (
              <Grid
                key={id}
                item
                xs={12}
                md={6}
                lg={4}
                xl={3}
                style={{ height: 280 }}
              >
                <Typography align="center">{label}</Typography>
                {data.length === 0 ? (
                  <Box marginTop={3}>
                    <Typography align="center">
                      Não houveram registros
                    </Typography>
                  </Box>
                ) : (
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={85}
                        innerRadius={55}
                        label
                      >
                        {colors.map((color) => (
                          <Cell key={`${id}-${color}`} fill={color} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {dashboardData.customerUnits.map(
        ({ id, label, indicators, graphics }) => (
          <CustomerUnitSection
            key={id}
            id={id}
            label={label}
            indicators={indicators}
            graphics={graphics}
          />
        )
      )}
    </Grid>
  );
};

export default EventAnalyticsDashboard;
