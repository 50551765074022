import utransferV2API from "./utransferV2";

export const exportExcel = async (data) => {
  const response = await utransferV2API.get(`/excel-reports/indetifier`, {
    params: data,
    responseType: "blob",
  });

  return response.data;
};
