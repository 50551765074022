import React from "react";
import { InfoWindow } from "@react-google-maps/api";
import { isEmpty } from "lodash";

export const RightClickInfoWindow = ({ currentInfoWindow, setCurrentInfoWindow }) => {
  if (isEmpty(currentInfoWindow)) return <></>;
  else return  (
    <InfoWindow
      onCloseClick={() => setCurrentInfoWindow({})}
      position={{
        lat: currentInfoWindow.lat,
        lng: currentInfoWindow.lng
      }}
    >
      <div>{currentInfoWindow.body}</div>
    </InfoWindow>
  );
}