import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
} from "recharts";
import { useSelector } from "react-redux";
import useStyles, { LegendColor } from "./styles";
import {
  getDashboardTelemetryData,
  getEventsRanking,
} from "../../../services/eventAnalyticsApi/eventService";
import {
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@material-ui/icons";
import { colorsTelemetryDashboard } from "../../../commons/dashboardColors";
import Indicator from "./components/Indicator";
import CentralizedLoading from "../../../components/CentralizedLoading";
import { toast } from "material-react-toastify";

export const eventTypesArray = [
  { id: 1, name: "Saída de rota" },
  { id: 2, name: "Entrada em área de risco" },
  { id: 5, name: "Saída de cerca virtual" },
  { id: 6, name: "Alerta de Pânico" },
  { id: 10, name: "Tempo excedido em ponto de parada" },
  { id: 14, name: "Entrada em área de risco personalizada" },
  { id: 16, name: "Aceleração Brusca" },
  { id: 17, name: "Frenagem Brusca" },
  { id: 18, name: "Curva Acentuada" },
  { id: 20, name: "Excesso de velocidade" },
  { id: 21, name: "Dispositivo andando sem vínculo de motorista" },
];

const TelemetryDashboard = ({ filters }) => {
  const classes = useStyles();
  const customerId = useSelector((store) => store.auth.customer.customer);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);

  const [mode, setMode] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [eventRanking, setEventRanking] = useState([]);
  const [eventRankingDetails, setEventRankingDetails] = useState([]);
  const [detailOpened, setDetailOpened] = useState(false);

  const getDashboardData = async () => {
    try {
      setLoading(true);
      setLoadingTable(true);
      const dashboardData = await getDashboardTelemetryData({
        customerId,
        startedAt: filters.from,
        endedAt: filters.to,
        driver: mode === 1 ? undefined : true,
      });
      const eventRankingData = await getEventsRanking({
        customerId,
        startedAt: filters.from,
        endedAt: filters.to,
        page: 1,
        perPage: rowsPerPage,
        driver: mode === 1 ? undefined : true,
      });

      setDashboardData(dashboardData);
      setEventRanking(eventRankingData?.generatedEventRanking?.data);
      setTotalRows(eventRankingData?.generatedEventRanking?.totalRows);
      setEventRankingDetails(
        eventRankingData.deviceWithEvents || eventRankingData.driverWithEvents
      );
    } catch (e) {
      if (e.response.status == 404) {
        setMode(1);
        toast.warning("Nenhum motorista encontrado");
      } else {
        toast.error("Ocorreu algum erro ao buscar dados do dashboard");
      }
    } finally {
      setLoading(false);
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [mode]);

  useEffect(() => {
    if (!filters.from) return;
    getDashboardData();
  }, [filters]);

  const handleChangePage = async (_, newPage) => {
    if (!customerId) {
      return;
    }
    try {
      setPage(newPage);
      setLoadingTable(true);
      const response = await getEventsRanking({
        customerId,
        startedAt: filters.from,
        endedAt: filters.to,
        page: newPage + 1,
        perPage: rowsPerPage,
      });
      setEventRanking(response.generatedEventRanking.data);
      setTotalRows(response.generatedEventRanking.totalRows);
    } catch (error) {
      toast.error("Ocorreu algum erro ao buscar dados do ranking");
    } finally {
      setLoadingTable(false);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    if (!customerId) {
      return;
    }
    try {
      setLoadingTable(true);
      setRowsPerPage(+event.target.value);
      const response = await getEventsRanking({
        customerId,
        startedAt: filters.from,
        endedAt: filters.to,
        page: 1,
        perPage: +event.target.value,
      });
      setEventRanking(response.generatedEventRanking.data);
      setTotalRows(response.generatedEventRanking.totalRows);
    } catch (error) {
      toast.error("Ocorreu algum erro ao buscar dados do ranking");
    } finally {
      setPage(0);
      setLoadingTable(false);
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload?.length) {
      const { deviceName, driverName, count } = payload[0].payload;
      return (
        <div className={classes.customTooltip}>
          <p>{`${deviceName || driverName}: ${count}`}</p>
        </div>
      );
    }

    return null;
  };

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <div className={classes.legendDiv}>
        {payload.map((entry) => (
          <div className={classes.agroupLegend}>
            <LegendColor color={entry.color} />
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  const handlePieChart = (eventTypeId, eventName) => {
    if (
      Object.keys(dashboardData?.eventCountedByIds).includes(`${eventTypeId}`)
    ) {
      return (
        <div className={classes.divPieChart}>
          <p>{eventName}</p>
          <ResponsiveContainer width="100%">
            <PieChart width={400} height={400}>
              <Pie
                dataKey="count"
                isAnimationActive={false}
                data={dashboardData?.eventCountedByIds[`${eventTypeId}`]}
                cx="50%"
                cy="50%"
                outerRadius={100}
                innerRadius={60}
              >
                {dashboardData?.eventCountedByIds[`${eventTypeId}`].map(
                  (_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        colorsTelemetryDashboard[
                          index % colorsTelemetryDashboard.length
                        ]
                      }
                    />
                  )
                )}
              </Pie>
              <Legend
                layout="vertical"
                verticalAlign="bottom"
                align="center"
                wrapperStyle={{
                  height: "40%",
                  overflow: "auto",
                }}
                iconSize={18}
                content={renderLegend}
                payload={dashboardData?.eventCountedByIds[`${eventTypeId}`].map(
                  (item, index) => ({
                    id: item.name,
                    type: "square",
                    value: `${item.deviceName || item.driverName}`,
                    color:
                      colorsTelemetryDashboard[
                        index % colorsTelemetryDashboard.length
                      ],
                  })
                )}
              />

              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      );
    }
  };

  const getTotalOccurrences = () => {
    let totalOccurrences = 0;

    dashboardData.eventsCounted.forEach(
      ({ count }) => (totalOccurrences = totalOccurrences + Number(count))
    );

    return totalOccurrences;
  };

  const handleSetDetailOpened = (deviceId) => {
    if (detailOpened == deviceId) {
      return setDetailOpened(false);
    }
    setDetailOpened(deviceId);
  };

  return loading ? (
    <div className={classes.loadDiv}>
      <CentralizedLoading color="#3CB3D2" size={200} text={"Buscando dados"} />
    </div>
  ) : (
    <Grid container>
      <Grid container className={classes.containerOverview}>
        <Grid item xs={12} md className={classes.mainOverview}>
          <div className={classes.mainOverviewHeader}>
            <Typography variant="h4" className={classes.mainOverviewTitleText}>
              Visão Geral
            </Typography>

            <Select
              variant="outlined"
              value={mode}
              defaultValue={"Dispositivo"}
              onChange={(event) => {
                setMode(event.target.value);
              }}
            >
              <MenuItem key={1} value={1}>
                Dispositivo
              </MenuItem>

              <MenuItem key={2} value={2}>
                Motorista
              </MenuItem>
            </Select>
          </div>
          {dashboardData?.eventsCounted?.length > 0 && (
            <Grid className={classes.barChartGrid}>
              <Typography variant="h5" className={classes.barChartTitle}>
                Total de ocorrências: {getTotalOccurrences()}
              </Typography>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={dashboardData.eventsCounted}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={"name"} />
                  <YAxis />
                  <Tooltip />
                  <Bar
                    barSize={30}
                    name="Ocorridos"
                    dataKey="count"
                    fill="#3CB3D2"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          )}
          <Grid
            container
            spacing={2}
            className={classes.mainOverviewIndicators}
          >
            {dashboardData?.eventsCounted?.map(
              ({ count, name, eventTypeId }) => (
                <Grid key={eventTypeId} item xs={12} md={6} lg={4} xl={3}>
                  <Indicator number={count} label={name} />
                </Grid>
              )
            )}
          </Grid>

          <Typography variant="h4" className={classes.occurrencesTitleText}>
            Ocorrências
          </Typography>
          {dashboardData?.eventCountedByIds && (
            <Grid className={classes.pieChartGrid}>
              {eventTypesArray.map(({ id, name }) => handlePieChart(id, name))}
            </Grid>
          )}

          <Paper>
            <TableContainer component={Paper}>
              {loadingTable ? (
                <div className={classes.loadDiv}>
                  <CentralizedLoading
                    color="#3CB3D2"
                    size={100}
                    text={"Buscando dados"}
                  />
                </div>
              ) : (
                <Table aria-label="custom pagination table">
                  <TableHead className={classes.styledTableHead}>
                    <TableRow>
                      <TableCell
                        className={classes.tableCellStyled}
                        key="date"
                        align="center"
                      >
                        Ranking
                      </TableCell>

                      <TableCell
                        className={classes.tableCellStyled}
                        key="user"
                        align="center"
                      >
                        Nome
                      </TableCell>

                      <TableCell
                        className={classes.tableCellStyled}
                        key="center"
                        align="center"
                      >
                        Quantidade de ocorrências
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyled}
                        key="center"
                        align="center"
                      >
                        Período
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyled}
                        key="center"
                        align="center"
                      >
                        Detalhes
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {eventRanking?.map((item, index) => {
                      return (
                        <>
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={`TableRow - ${item.deviceId}`}
                          >
                            <TableCell
                              className={classes.bodyTableCell}
                              key={`bodyTable - pos - ${item.deviceId}`}
                              align="center"
                            >
                              {`${index + 1}°`}
                            </TableCell>

                            <TableCell
                              className={classes.bodyTableCell}
                              key={`bodyTable - deviceName - ${item.deviceId}`}
                              align="center"
                            >
                              {item.deviceName || item.driverName}
                            </TableCell>

                            <TableCell
                              className={classes.bodyTableCell}
                              key={`bodyTable - count - ${item.deviceId}`}
                              align="center"
                            >
                              {item.count}
                            </TableCell>

                            <TableCell
                              className={classes.bodyTableCell}
                              key={`bodyTable - per - ${item.deviceId}`}
                              align="center"
                            >
                              {`${filters?.from?.toLocaleDateString()} - ${filters?.to?.toLocaleDateString()}`}
                            </TableCell>
                            <TableCell
                              className={classes.bodyTableCell}
                              key={`bodyTable - details - ${item.deviceId}`}
                              align="center"
                            >
                              <IconButton
                                onClick={() =>
                                  handleSetDetailOpened(
                                    item.deviceId || item.driverId
                                  )
                                }
                              >
                                {detailOpened ==
                                (item.deviceId || item.driverId) ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              key={`bodyTable - ddd - ${
                                item.deviceId || item.driverId
                              }`}
                              align="center"
                              style={{ padding: 0 }}
                              colSpan={5}
                            >
                              <Collapse
                                in={
                                  detailOpened == item.deviceId ||
                                  detailOpened == item.driverId
                                }
                                timeout="auto"
                                unmountOnExit
                              >
                                {eventRankingDetails.map(
                                  (deviceOrDriverWithEvents) =>
                                    deviceOrDriverWithEvents.id ==
                                      (item.deviceId || item.driverId) && (
                                      <Grid
                                        className={classes.barChartRankingGrid}
                                      >
                                        <Typography
                                          variant="h4"
                                          className={classes.collapseTitleText}
                                        >
                                          Detalhamento de Ocorrências
                                        </Typography>
                                        <ResponsiveContainer
                                          width="100%"
                                          height="80%"
                                        >
                                          <BarChart
                                            data={
                                              deviceOrDriverWithEvents.events
                                            }
                                            margin={{
                                              top: 5,
                                              right: 30,
                                              left: 20,
                                              bottom: 5,
                                            }}
                                          >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey={"name"} />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar
                                              barSize={30}
                                              name="Ocorridos"
                                              dataKey="count"
                                              fill="#3CB3D2"
                                            />
                                          </BarChart>
                                        </ResponsiveContainer>
                                      </Grid>
                                    )
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Linhas por página"
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TelemetryDashboard;
