const colors = {
  blue: "#63a9ef",
  lightBlue: "#06B0CF",

  fullWhite: "#FFF",

  lighterGray: "#ddd",
  lightGray: "#ccc",
  gray: "#989898",
  darkGray: "#666",
  darkerGray: "#3C3C3C",

  darkRed: "#820112",
  red: "#b52121",

  black: "#111",
};

export const RISK_ZONE_FILL_COLOR = "#e84118";
export const RISK_ZONE_STROKE_COLOR = "#c23616";

export default colors;
