import path from 'path'

import { store } from '../store'
import transferApi from './transferApi'


export const submitScripting = async (scriptingData) => {
  const state = store.getState()
  const accessToken = state.auth?.token?.accessToken

  const scriptingUri = path.join(
    'transfers',
    String(scriptingData.transferId),
    'scripting'
  )
  try {
    await transferApi.patch(
      scriptingUri,
      {
        incidents: scriptingData.incidents,
        occurrences: scriptingData.occurrences,
        risk_layers: scriptingData.riskLayers,
        to: scriptingData.to,
        from: scriptingData.from,
        steps: scriptingData.steps,
        estimated_time: scriptingData.estimatedTime,
        filters: {
          dateFrom: scriptingData.filters.dateFrom,
          radius: scriptingData.filters.radius,
          incidents_nature: scriptingData.filters.incidentsNature,
          incidents_types: scriptingData.filters.incidentsTypes,
          occurrences_nature: scriptingData.filters.occurrencesNature,
          occurrences_types: scriptingData.filters.occurrencesTypes
        },
        order: 1,
        waypoints: scriptingData.waypoints,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }
    )
  }
  catch (error) {
    throw error
  }
}