import { makeStyles } from '@material-ui/core/styles'

const styles = {}

styles.dialog = {
  maxHeight: 600,
  maxWidth: 700,
  width: '100%', 
}

styles.dialogTitle = {
  paddingTop: 24,
  paddingBottom: 8,
}

const useStyles = makeStyles(styles)

export default useStyles