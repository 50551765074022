import React, { useState, useCallback, useEffect } from "react";
import { Typography, Tabs, Tab } from "@material-ui/core";
import Sidebar from "../../components/Sidebar/sidebar";
import ReportForm from "../../components/ReportForm";
import { EventReport } from "./EventReport";
import CollapsibleTable from "./DeviceReport/index";
import { IdentifierReport } from "./IdentifierReport";
import { UnityReport } from "./UnityReport";
import EventAnalytics from "./EventAnalytics";
import { Div, Container, IconDiv, StyledEcoIcon } from "./styles";
import { useDispatch } from "react-redux";
import {
  changeToEventTabAction,
  changeToIndentifierTabAction,
  changeToOccurrencesTabAction,
  changeToGasTabAction,
  changeToUnityTabAction,
  cleanFieldsAction,
  changeToDashboardTabAction,
  changeToTelemetryDashboardTabAction,
  changeToReportDeviceTabAction,
} from "../../store/ducks/reporting";
import { useSelector } from "react-redux";
import EventAnalyticsDashboard from "./EventAnaliticsDashboard";
import { toast } from "material-react-toastify";
import { cleanSnackBarAction } from "../../store/ducks/snackbar";
import { useLocation } from "react-router-dom";
import GasEmission from "./GasEmission";
import TelemetryDashboard from "./TelemetryDashboard";
import { can } from "../../services/auth";
import EcoIcon from "@material-ui/icons/Eco";

const tabsClasses = {
  root: "tabsContainer",
  indicator: "tabsBorderBottomColor",
};

export const ReportPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [reportOnScreen, setReportOnScreen] = useState("");
  const [currentTransfer, setCurrentTransfer] = useState();
  const [currentDevice, setCurrentDevice] = useState();
  const [filters, setFilters] = useState({});
  const { open, message } = useSelector((store) => store.snackbar);

  const handleSetTransferAndDevice = (transfer, deviceId) => {
    setCurrentTransfer(transfer);
    setCurrentDevice(deviceId);
  };

  const doFilter = useCallback((event) => {
    setFilters(event);
  }, []);

  const onChangeTabs = useCallback(
    (_, newValue) => {
      setReportOnScreen(newValue);
    },
    [setReportOnScreen]
  );

  useEffect(() => {
    const tabActions = {
      unity: handleUnityTabClick,
      event: handleEventTabClick,
      identifier: handleIndentifierTabClick,
      report: handleReportDeviceTabClick,
      "event-analytics": handleOccurrencesTabClick,
      "gas-emission": handleOccurrencesTabClick,
      "event-analytics-dashboard": handleDashboardTabClick,
      "dashboard-telemetry": handleTelemetryDashboardTabClick,
    };
    if (state?.tab) {
      onChangeTabs(null, state.tab);
      tabActions[state.tab] && tabActions[state.tab]();
    }
  }, []);

  const handleUnityTabClick = () => {
    dispatch(changeToUnityTabAction());
    dispatch(cleanFieldsAction(true));
  };

  const handleEventTabClick = () => {
    dispatch(changeToEventTabAction());
    dispatch(cleanFieldsAction(true));
  };

  const handleIndentifierTabClick = () => {
    dispatch(changeToIndentifierTabAction());
    dispatch(cleanFieldsAction(true));
  };

  const handleOccurrencesTabClick = () => {
    dispatch(changeToOccurrencesTabAction());
    dispatch(cleanFieldsAction(true));
  };

  const handleGasTabClick = () => {
    dispatch(changeToGasTabAction());
    dispatch(cleanFieldsAction(true));
  };

  const handleDashboardTabClick = () => {
    dispatch(changeToDashboardTabAction());
    dispatch(cleanFieldsAction(true));
  };

  const handleTelemetryDashboardTabClick = () => {
    dispatch(changeToTelemetryDashboardTabAction());
    dispatch(cleanFieldsAction(true));
  };

  const handleReportDeviceTabClick = () => {
    dispatch(changeToReportDeviceTabAction());
    dispatch(cleanFieldsAction(true));
  };

  const reportTabs = [
    {
      label: "Unidade de Negócios",
      value: "unity",
      onClick: handleUnityTabClick,
      permission: "can-access-unity-reports",
    },
    {
      label: "Relatório de Dispositivos",
      value: "report",
      onClick: handleReportDeviceTabClick,
      permission: "can-access-basic-reports",
    },
    {
      label: "Análise de Ocorrências",
      value: "event-analytics",
      onClick: handleOccurrencesTabClick,
      permission: "can-access-unity-reports",
    },
    {
      label: "Dashboard Análise de Ocorrências",
      value: "event-analytics-dashboard",
      onClick: handleDashboardTabClick,
      permission: "can-access-unity-reports",
    },
    {
      label: "Evento",
      value: "event",
      onClick: handleEventTabClick,
      permission: "can-access-basic-reports",
    },
    {
      label: "Identificador",
      value: "identifier",
      onClick: handleIndentifierTabClick,
      permission: "can-access-basic-reports",
    },

    {
      label: (
        <IconDiv>
          <StyledEcoIcon /> <span>Emissão de Gases</span>
        </IconDiv>
      ),
      value: "gas-emission",
      onClick: handleGasTabClick,
      permission: "can-access-co2-report",
    },

    {
      label: "Dashboard de telemetria",
      value: "dashboard-telemetry",
      onClick: handleTelemetryDashboardTabClick,
      permission: "can-access-telemetries-report",
    },
  ];

  const visibleTabs = reportTabs.filter((tab) => can(tab.permission));

  if (open) {
    toast.error(message);
    dispatch(cleanSnackBarAction());
  }

  return (
    <Div>
      <Sidebar />
      <Container>
        <div className="report-form">
          <Typography gutterBottom variant="h4" className="pageTitle">
            Relatórios
          </Typography>
          <ReportForm
            values={{ identifier: state?.identifier }}
            doFilter={doFilter}
          />
        </div>

        <div className="padding-tabs">
          <Tabs
            value={reportOnScreen}
            onChange={onChangeTabs}
            classes={tabsClasses}
          >
            {visibleTabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                className="tabTitle"
                onClick={tab.onClick}
              />
            ))}
          </Tabs>
        </div>

        <div className="reportTableContainer">
          {reportOnScreen === "unity" && <UnityReport filters={filters} />}

          {reportOnScreen === "report" && (
            <CollapsibleTable filters={filters} />
          )}

          {reportOnScreen === "event" && (
            <EventReport
              filters={filters}
              handleSetTransferAndDevice={handleSetTransferAndDevice}
              setReportOnScreen={setReportOnScreen}
            />
          )}

          {reportOnScreen === "identifier" && (
            <IdentifierReport
              filters={filters}
              currentTransfer={currentTransfer}
              currentDevice={currentDevice}
            />
          )}

          {reportOnScreen === "event-analytics" && (
            <EventAnalytics filters={filters} />
          )}

          {reportOnScreen === "gas-emission" && (
            <GasEmission filters={filters} />
          )}

          {reportOnScreen === "event-analytics-dashboard" && (
            <EventAnalyticsDashboard filters={filters} />
          )}

          {reportOnScreen === "dashboard-telemetry" && (
            <TelemetryDashboard filters={filters} />
          )}
        </div>
      </Container>
    </Div>
  );
};
