import React from "react";
import StepperDevices from "./stepperDevices";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { getTransferStatus } from "./utils/transfers";

import transferConstants from "../../../domain/constants/transfers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTransferId } from "../../../store/ducks/transfer";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "#06B0CF" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalBond({ transferData, onSuccess }) {
  const currentTransferId = useSelector(
    (state) => state.transfer.currentTransferId
  );

  const dispatch = useDispatch();

  const [openModal, setOpenModal] = React.useState(false);

  const handleModalClose = () => {
    setOpenModal(false);

    if (onSuccess) {
      onSuccess();
    }

    dispatch(setCurrentTransferId(""));
  };

  useEffect(() => {
    if (currentTransferId === transferData.id) {
      setOpenModal(true);
    }
  }, [currentTransferId, transferData.id]);

  const status = getTransferStatus(transferData?.status);
  const numberAssociatedDevices = transferData?.associated_devices
    ? transferData?.associated_devices?.length
    : transferData?.pre_configured_transfer_settings?.device_ids?.length;

  if (
    [
      transferConstants?.status.CANCELED,
      transferConstants?.status.SCRIPTED,
    ]?.includes(status)
  ) {
    return (
      <Badge
        color={numberAssociatedDevices > 0 ? "" : "error"}
        badgeContent={
          transferData.status === "O"
            ? "!"
            : numberAssociatedDevices > 0
            ? ""
            : "!"
        }
      >
        <Button
          onClick={() => {
            setOpenModal(true);
          }}
          style={{
            width: 10,
            color: "#06B0CF",
            borderRadius: 12,
            boxShadow: "0px 3px 6px #00000029",
            backgroundColor: "#fff",
            border: "none",
          }}
          variant="outlined"
          color="primary"
          endIcon={<PersonAddIcon />}
        >
          <Typography
            style={{
              color: "#000",
              fontWeight: "bold",
            }}
            variant="button"
            display="block"
            gutterBottom
          >
            {numberAssociatedDevices}
          </Typography>
        </Button>

        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          style={{ zIndex: 2 }}
          onClose={handleModalClose}
          aria-labelledby="dialog-devices"
          open={openModal}
        >
          <DialogTitle id="dialog-devices">
            <Button onClick={handleModalClose}>
              <ChevronLeftIcon fontSize="large" style={{ color: "#06B0CF" }} />
            </Button>
            Transfer | <strong>Vínculos</strong> | {transferData.name}
          </DialogTitle>
          <DialogContent dividers>
            <StepperDevices
              onClose={handleModalClose}
              transferData={transferData}
            />
          </DialogContent>
          <DialogActions>
            <br />
          </DialogActions>
        </Dialog>
      </Badge>
    );
  }

  if (
    numberAssociatedDevices > 0 ||
    [
      transferConstants.status.ONGOING,
      transferConstants.status.ENDED,
      transferConstants.status.NOT_SCRIPTED,
    ].includes(status)
  ) {
    return (
      <Button
        disabled
        variant="outlined"
        style={{
          width: 10,
          borderRadius: 12,
          boxShadow: "0px 3px 6px #00000029",
          border: "none",
        }}
        color="primary"
        endIcon={<PersonIcon />}
      >
        <Typography
          style={{ fontWeight: "bold" }}
          variant="button"
          display="block"
          gutterBottom
        >
          {numberAssociatedDevices}
        </Typography>
      </Button>
    );
  }

  return null;
}
