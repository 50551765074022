import React, { useState, useCallback, useMemo } from "react";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardMedia,
  Divider,
  Typography,
  Switch,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Roadmap from "../../../../assets/mapaStyleRoadmap.png";
import SatelliteMap from "../../../../assets/mapStyleSatellite.png";
import {
  changeMapStylesAction,
  openMapStyleDrawerAction,
} from "../../../../store/ducks/monitoringMap";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomDivAmounte,
  CustomPaper,
  DivMapSatellite,
  DivMapStandard,
} from "./styles";
import { useEffect } from "react";
import { riskLayers } from "./titleRisksLayers";
import {
  addPoiType,
  removePoiType,
  setActiveRiskLayers,
  setIncidentsNaturesAction,
  setOccurrencesCategoriesAction,
} from "../../../../store/ducks/scripting";
import { addCustomerPointsOfInterestTypes } from "../../../../store/ducks/areas";
import { getPOITypes } from "../../../../services/areasApi/poi/get-poi-types";

export default function ModalFloatCamada({ isOpen, setIsOpen }) {
  const occurrencesCategories = useSelector(
    (state) => state.scripting.occurrencesCategories
  );
  const activeRiskLayers = useSelector(
    (store) => store.scripting.activeRiskLayers
  );
  const customerPointOfInterestTypes = useSelector(
    (store) => store.areas.customerPointOfInterestTypes
  );
  const userGroups = useSelector((store) => store.auth.user?.groups_users);
  const poiTypesSelected = useSelector(
    (state) => state.scripting.poiTypesSelected
  );
  const { incidentsNatures } = useSelector((state) => state.scripting);
  const [currWidth, setCurrWidth] = useState(window.innerWidth);
  const [poiTypes, setPOITypes] = useState([]);
  const [switchCountAlert, setSwitchCountAlert] = useState(0);
  const [switchCountClippingSecurity, setSwitchCountClippingSecurity] =
    useState(0);
  const [switchCountCustomerPoi, setSwitchCountCustomerPoi] = useState(0);
  const dispatch = useDispatch();
  const groupIds = useMemo(() => {
    return userGroups?.map(({ id }) => id) ?? [];
  }, [userGroups]);

  const handleClickChange = (mapType) => {
    dispatch(changeMapStylesAction(mapType));
    dispatch(openMapStyleDrawerAction());
  };

  const updateScreen = () => {
    setCurrWidth(window.innerWidth);
  };

  const countActiveSwitchesAlerts = () => {
    const count = incidentsNatures.reduce((accu, curr) => {
      if (curr.selected) {
        return accu + 1;
      }

      return accu;
    }, 0);

    setSwitchCountAlert(count);
  };

  const countActiveSwitchesClippingsSecurity = () => {
    const count = occurrencesCategories.reduce((accu, curr) => {
      if (curr.selected) {
        return accu + 1;
      }

      return accu;
    }, 0);

    setSwitchCountClippingSecurity(count);
  };

  const countActiveSwitchesPoi = () => {
    const count = customerPointOfInterestTypes.reduce((accu, curr) => {
      if (curr.selected) {
        return accu + 1;
      }

      return accu;
    }, 0);

    setSwitchCountCustomerPoi(count);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreen);

    return () => {
      window.removeEventListener("resize", updateScreen);
    };
  }, []);

  const getPoiTypes = async () => {
    if (groupIds.length === 0) {
      return [];
    }

    const poiTypes = await getPOITypes({ groupIds });

    setPOITypes(poiTypes);
  };

  useEffect(() => {
    countActiveSwitchesClippingsSecurity();
    countActiveSwitchesAlerts();
    countActiveSwitchesPoi();
  }, [incidentsNatures, customerPointOfInterestTypes, occurrencesCategories]);

  useEffect(() => {
    getPoiTypes();
  }, [userGroups, groupIds]);

  const handleActiveRiskLayers = (checked, riskLayerId) => {
    if (checked) {
      return dispatch(setActiveRiskLayers([...activeRiskLayers, riskLayerId]));
    }

    const newArrayRiskLayer = activeRiskLayers.filter(
      (id) => id !== riskLayerId
    );

    dispatch(setActiveRiskLayers(newArrayRiskLayer));
  };

  const handleActiveOccurrence = (isChecked, nature) => {
    const newOccurrenceSelection = nature.types.map((type) => ({
      ...type,
      selected: isChecked,
    }));

    const newArrayOccurence = occurrencesCategories.map((occ) => {
      if (occ.id === nature.id)
        return {
          ...nature,
          selected: isChecked,
          types: newOccurrenceSelection,
        };
      else return occ;
    });
    dispatch(setOccurrencesCategoriesAction(newArrayOccurence));
  };

  const handleChangeAlerts = useCallback(
    (isChecked, nature) => {
      const newTypesSelection = nature.types.map((type) => ({
        ...type,
        selected: isChecked,
      }));

      const newIncidentsNatureArray = incidentsNatures.map((incident) => {
        if (incident.id === nature.id)
          return { ...nature, selected: isChecked, types: newTypesSelection };
        else return incident;
      });
      dispatch(setIncidentsNaturesAction(newIncidentsNatureArray));
    },
    [incidentsNatures]
  );

  const handleChangeCustomerPOI = useCallback(
    (isChecked, type) => {
      const newPOITypesArray = customerPointOfInterestTypes.map((POIType) => {
        if (POIType.id === type.id) return { ...POIType, shared: isChecked };
        else return POIType;
      });

      dispatch(addCustomerPointsOfInterestTypes(newPOITypesArray));
    },
    [customerPointOfInterestTypes]
  );

  const handleSwitchChange = (type) => {
    if (poiTypesSelected.some((t) => t.id === type.id)) {
      dispatch(removePoiType(type.id));
    } else {
      dispatch(addPoiType(type));
    }
  };

  return (
    <Fade timeout={700} in={isOpen}>
      <CustomPaper elevation={1}>
        <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
          Modo de visualização
        </Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
          Selecione aqui o modo de sua preferência
        </Typography>
        <div style={{ display: "flex", width: "100%" }}>
          <DivMapStandard>
            <CardMedia
              component="img"
              image={Roadmap}
              onClick={() => handleClickChange("roadmap")}
              sx={{
                height: "4rem",
                width: "4rem",
                borderRadius: "1rem",
                cursor: "pointer",
              }}
            />
            <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
              Padrão
            </Typography>
          </DivMapStandard>
          <DivMapSatellite>
            <CardMedia
              component="img"
              image={SatelliteMap}
              onClick={() => handleClickChange("satellite")}
              sx={{
                height: "4rem",
                width: "4rem",
                borderRadius: "1rem",
                cursor: "pointer",
              }}
            />
            <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
              Satélite
            </Typography>
          </DivMapSatellite>
        </div>
        <Divider />
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 600,
            padding: "0.625rem 0 0 0",
          }}
        >
          Detalhes do mapa
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            padding: "0.625rem 0 0.625rem 0",
          }}
        >
          Selecione os tipos de incidentes e outras camadas de informações que
          serão exibidas no mapa
        </Typography>
        <div>
          {riskLayers.map(({ name, itemsAlerts }) => (
            <Accordion key={name}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ width: currWidth < 1385 ? null : "280px" }}
              >
                <div style={{ display: "flex", gap: "15px" }}>
                  <CustomDivAmounte>{activeRiskLayers.length}</CustomDivAmounte>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                  >
                    {name}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {itemsAlerts.map(({ id, name }) => {
                  return (
                    <div
                      key={id}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "2rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.750rem",
                            fontWeight: 300,
                            padding: "0.625rem 0 0.625rem 0",
                            width: "100%",
                          }}
                        >
                          {name}
                        </Typography>
                        <Switch
                          onClick={(e) =>
                            handleActiveRiskLayers(e.target.checked, id)
                          }
                          checked={activeRiskLayers.includes(id)}
                        />
                      </div>
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))}

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ width: currWidth < 1385 ? null : "280px" }}
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <CustomDivAmounte>{switchCountAlert}</CustomDivAmounte>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                  }}
                >
                  Alertas
                </Typography>
              </div>
            </AccordionSummary>
            {incidentsNatures.map((nature) => {
              return (
                <AccordionDetails>
                  <div
                    key={nature.id}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "2rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.750rem",
                          fontWeight: 300,
                          padding: "0.625rem 0 0.625rem 0",
                          width: "100%",
                        }}
                      >
                        {nature.category}
                      </Typography>
                      <Switch
                        onChange={(e) =>
                          handleChangeAlerts(e.target.checked, nature)
                        }
                        checked={nature.selected}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              );
            })}
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ width: currWidth < 1385 ? null : "280px" }}
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <CustomDivAmounte>
                  {switchCountClippingSecurity}
                </CustomDivAmounte>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                  }}
                >
                  Clipping de Segurança Pública
                </Typography>
              </div>
            </AccordionSummary>
            {occurrencesCategories.map((occ) => {
              return (
                <AccordionDetails>
                  <div
                    key={occ.id}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "2rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.750rem",
                          fontWeight: 300,
                          padding: "0.625rem 0 0.625rem 0",
                          width: "100%",
                        }}
                      >
                        {occ.category}
                      </Typography>
                      <Switch
                        onChange={(e) =>
                          handleActiveOccurrence(e.target.checked, occ)
                        }
                        checked={occ.selected}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              );
            })}
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ width: currWidth < 1385 ? null : "280px" }}
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <CustomDivAmounte>{switchCountCustomerPoi}</CustomDivAmounte>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                  }}
                >
                  Pontos de Interesse Exclusivo
                </Typography>
              </div>
            </AccordionSummary>
            {customerPointOfInterestTypes &&
              customerPointOfInterestTypes.map((type) => {
                return (
                  <AccordionDetails>
                    <div
                      key={type.id}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "2rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.750rem",
                            fontWeight: 300,
                            padding: "0.625rem 0 0.625rem 0",
                            width: "100%",
                          }}
                        >
                          {type.type}
                        </Typography>
                        <Switch
                          onClick={(e) =>
                            handleChangeCustomerPOI(e.target.checked, type)
                          }
                          checked={type.shared}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                );
              })}
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ width: currWidth < 1385 ? null : "280px" }}
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <CustomDivAmounte>{poiTypesSelected.length}</CustomDivAmounte>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                  }}
                >
                  Pontos de Interesse Coletivo
                </Typography>
              </div>
            </AccordionSummary>
            {poiTypes &&
              poiTypes.map((type) => {
                return (
                  <AccordionDetails>
                    <div
                      key={type.id}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "2rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.750rem",
                            fontWeight: 300,
                            padding: "0.625rem 0 0.625rem 0",
                            width: "100%",
                          }}
                        >
                          {type.type}
                        </Typography>
                        <Switch
                          checked={poiTypesSelected?.some(
                            (t) => t.id === type.id
                          )}
                          onChange={() => handleSwitchChange(type)}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                );
              })}
          </Accordion>
        </div>
      </CustomPaper>
    </Fade>
  );
}
