import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  Switch,
  IconButton,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { isEmpty } from "lodash";
import { positionHistoryHeaderCells } from "./constants";
import { useStyles } from "./styles";
import { subHours, format } from "date-fns";
import { useGoogleMapsKey } from "../../../hooks/useGoogleMapsKey";
import Geocode from "react-geocode";
import ExcelDialog from "./components/DialogExcel";
import { useSelector } from "react-redux";

const LocationButton = ({ latitude, longitude }) => {
  const styles = useStyles();
  const [address, setAddress] = useState("Carregando...");

  const getAddressFromCoordinates = async () => {
    try {
      const response = await Geocode.fromLatLng(latitude, longitude);
      const data = response?.results[0]?.formatted_address;
      setAddress(data || "Endereço não encontrado");
    } catch (error) {
      setAddress("Erro ao buscar endereço");
    }
  };

  useEffect(() => {
    getAddressFromCoordinates();
  }, [latitude, longitude]);

  return <span className={styles.address}>{address}</span>;
};

const StyledTableCell = ({ children }) => {
  const { tableCell, tableHeaderCell } = useStyles();
  return (
    <TableCell className={`${tableCell} ${tableHeaderCell}`}>
      {children}
    </TableCell>
  );
};

export const IdentifierPositionHistoryTable = ({ identifiers, device }) => {
  const permissions = useSelector((store) => store.auth.permissions);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialogExcel, setOpenDialogExcel] = useState(false);
  const [showOnlyIgnitionOn, setShowOnlyIgnitionOn] = useState(false);
  const googleMapsKey = useGoogleMapsKey();
  Geocode.setApiKey(googleMapsKey);
  Geocode.setLanguage("pt");

  const handleOpenDialogExcel = () => {
    setOpenDialogExcel(true);
  };

  const handleCloseDialogExcel = () => {
    setOpenDialogExcel(false);
  };

  const makePagination = (array) =>
    array.slice(
      currentPage * rowsPerPage,
      currentPage * rowsPerPage + rowsPerPage
    );
  const currentIdentifiersRows =
    identifiers.result &&
    makePagination(
      identifiers.result.filter(
        (identifier) =>
          !showOnlyIgnitionOn ||
          (identifier.last_status.status == "ligado" &&
            identifier.last_status.latitude &&
            identifier.last_status.longitude)
      )
    );

  if (isEmpty(currentIdentifiersRows)) return null;
  return (
    <Paper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <span style={{ marginRight: 16 }}>
          Somente posições com ignição ligada
        </span>
        <Switch
          color="primary"
          checked={showOnlyIgnitionOn}
          onChange={(event) => setShowOnlyIgnitionOn(event.target.checked)}
        />
        {permissions?.permissions?.data.includes(
          "can-export-identifier-report"
        ) && (
          <>
            <span style={{ marginLeft: 50 }}>Exportar para excel</span>
            <IconButton
              onClick={handleOpenDialogExcel}
              style={{ width: "40px", height: "40px", marginLeft: 10 }}
            >
              <GetAppIcon />
            </IconButton>
            <ExcelDialog
              open={openDialogExcel}
              close={handleCloseDialogExcel}
              flespiId={device.flespi_id}
              deviceName={device.alias || device.identifier}
            />
          </>
        )}
      </div>

      <Table>
        <TableHead>
          <TableRow>
            {positionHistoryHeaderCells.map((headerText) => (
              <StyledTableCell key={headerText}>{headerText}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>

        {!currentIdentifiersRows.length && <div>Nenhum dado neste vínculo</div>}
        {currentIdentifiersRows.length && (
          <>
            <TableBody>
              {currentIdentifiersRows.map((identifier, index) => {
                const latitude = identifier?.last_status?.latitude;
                const longitude = identifier?.last_status?.longitude;

                return (
                  <TableRow key={index}>
                    <TableCell>
                      {
                        <TableCell>
                          {format(
                            subHours(
                              new Date(identifier.last_status.taken_at),
                              3
                            ),
                            "dd/MM/yyyy' às 'HH:mm"
                          )}
                        </TableCell>
                      }
                    </TableCell>
                    <TableCell>
                      <LocationButton
                        latitude={latitude}
                        longitude={longitude}
                      />
                    </TableCell>
                    <TableCell>{identifier.last_status.speed}</TableCell>
                    <TableCell>{identifier.last_status.status}</TableCell>
                    <TableCell>{identifier.last_status.latitude}</TableCell>
                    <TableCell>{identifier.last_status.longitude}</TableCell>
                    <TableCell>{identifier.last_status.battery}%</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={positionHistoryHeaderCells.length}
                  rowsPerPageOptions={[5, 10, 20]}
                  count={identifiers.result.length}
                  rowsPerPage={rowsPerPage}
                  page={currentPage}
                  onChangePage={(event, newPage) => setCurrentPage(newPage)}
                  onChangeRowsPerPage={(event) =>
                    setRowsPerPage(parseInt(event.target.value))
                  }
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count}`
                  }
                  labelRowsPerPage={"Linhas por página:"}
                />
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
    </Paper>
  );
};
