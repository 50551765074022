import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  divModal: {
    width: "350px",
    height: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  divHeader: {
    padding: "24px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  divHeaderContacts: {
    padding: "24px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  divNameHeader: {
    display: "flex",
    flexDirection: "column",
  },

  dividerHeader: {
    width: "100%",
  },

  strongNameHeader: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#000000",
  },
  divVoice: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    bottom: 10,
  },
  divItemsVoice: {
    height: "90px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  divButtonVoice: {
    padding: "24px",
    width: "64px",
    height: "64px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#3AC4E0",
    borderRadius: "50%",
  },
  divButtonVoiceRecord: {
    animation: "spin 1s infinite",
    position: "relative",
    padding: "24px",
    width: "64px",
    height: "64px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "6px solid #3AC4E0",
    borderRadius: "50%",
    borderTopColor: "#de350b",
    borderBottomColor: "#de350b",
  },
  divListVoices: {
    width: "100%",
    padding: "0 12px 12px 12px",
    overflow: "scroll",
    overflowY: "auto",
    overflowX: "hidden",
  },
  divListContacts: {
    width: "100%",
    height: "100%",
    padding: "12px",
  },
  divItemListContact: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  divItemInfoListContact: {
    display: "flex",
    flexDirection: "column",
  },
  divItemIconsListContact: {
    display: "flex",
    alignItems: "center",
  },
  divItemListSendVoice: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "30px",
  },

  itemListSendVoice: {
    width: "75%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "50px",
  },

  divItemListReceivedVoice: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "30px",
  },

  itemListReceivedVoice: {
    width: "75%",

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#E6E8EB",
    borderRadius: "50px",
  },

  dividerVoice: {
    width: "100%",
    border: "1px solid #B1B9C0",
  },
}));
