export default {
  list: {
    background: "#fff",
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 15,
    height: '100%',
    overflow: "auto",
  }
};
